import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        width: 80
    },
    {
        title: '行政区划代码',
        dataIndex: 'administrativeDivisionCode',
        align: 'center',
        width: 160,
        customRender: defaultCustomRender
    },
    {
        title: '地区',
        dataIndex: 'mapDistrictName',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '省市区',
        dataIndex: 'mapDistrictLevelName',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: 'DISTRICT_ID',
        dataIndex: 'districtId',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: 'DISTRICT_NAME',
        dataIndex: 'districtName',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: 'DISTRICT_PARENT',
        dataIndex: 'districtParent',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: 'DISTRICT_LEVEL',
        dataIndex: 'districtLevel',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 120,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
