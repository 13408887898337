import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import store from '@/store';
// import preferences from './preferences';

// export default create({
//     search(query, page, pageSize) {
//         return services.search({
//             ...query,
//             page,
//             page_size: pageSize
//         });
//     },
// })
//     download(query) {
//         return services.downloadXls(deleteEmpty({
//             ...query
//         }));
//     },
//     columns
// },
store.registerModule(
    'shortMessageSearchPage',
    create(
        {
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    page,
                    page_size: pageSize
                });
            },

            download(query) {
                return services.downloadXls(
                    deleteEmpty({
                        ...query
                    })
                );
            },
            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRowKeys', []);
            }
        },
        {
            state: {
                updatingStatus: [],
                openingStatus: [],
                joiningStatus: [],
                updateStatusError: null,
                openStatusError: null,
                joinStatusError: null,
                selectedRowKeys: [],
                sortFields: [],
            },
            mutations: {
                setSelectedRowKeys(state: any, selectedRowKeys: any[]) {
                    state.selectedRowKeys = selectedRowKeys;
                },
                
                updateStatusRequested(state: any, id: any) {
                    state.updatingStatus = [...state.updatingStatus, id];
                },
               
                updateStatusFailed(state: any, error: any) {
                    state.updateStatusError = error;
                },

                updateStatusFinally(state: any, id: any) {
                    const idx = state.updatingStatus.indexOf(id);
                    if (idx >= 0) {
                        state.updatingStatus.splice(idx, 1);
                        state.updatingStatus = [...state.updatingStatus];
                    }
                },
                sortFields(state: any, sortArr: any) {
                    state.sortFields = sortArr;
                },
                
                setFields(state: any, {key, checked}: any) {
                    state.fields = {
                        ...state.fields,
                        [key]: checked,
                    };
                }
            },
            actions: {
                async updateStatus(context: any, { ids, status }: any) {
                    context.commit('updateStatusRequested', ids);
                    try {
                        await services.updateStatus({
                            batch_id: ids,
                            status: status
                        });
                        // context.commit('updateOneStatus', { ids, status });
                        // 状态更新成功后，重新获取列表
                        context.dispatch('search');
                    } catch (e) {
                        context.commit('updateStatusFailed', e);
                    } finally {
                        context.commit('updateStatusFinally', ids);
                    }
                },

            
            }
        }
    )
);
