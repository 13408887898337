import {
    defaultCustomRender
} from '@/utils';
import moment from 'moment';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '昵称',
        dataIndex: 'nickname',
        width: 320,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '用户名',
        dataIndex: 'username',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 300,
        align: 'center',
        customRender: (status: number) => {
            return status == 0 ? '正常' : '禁用'
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        width: 300,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        align: 'center',
        width: 200,
        customRender: (time: number) => time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
        title: '更新人',
        dataIndex: 'modifiedBy',
        width: 300,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '更新时间',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: (time: number) => time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
