
    import Canvas from './ImageCrop/Canvas.vue';
    import {Component, Prop, Watch,Vue} from 'vue-property-decorator';
    import config from '@/config';
    import {yx_normFile} from '@/utils';
    import qs from 'querystringify';
    import {mapState} from 'vuex';
    import {Modal} from 'ant-design-vue';
    import Move from './ImageCrop/Anchors/Move.vue';
    import Resize from './ImageCrop/Anchors/Resize.vue';
    import Rotate from './ImageCrop/Anchors/Rotate.vue';

    @Component<ImageCrop>({
        components: {
            Canvas,
            Move,
            Resize,
            Rotate
        },
        computed: {
            hasImage() {
                return this.value && this.value.length > 0;
            },
            ...mapState({
                token: (state: any) => state.login.session.token
            }),
            displayAngle() {
                const angle = this.angle;
                return angle > 180 ? angle - 360 : angle;
            },
            
        }
    })
    class ImageCrop extends Vue {
        @Prop()
        public value!: any;

        @Prop()
        public noCrop!: any;

        @Prop()
        public imageWidth!: any;
        
        @Prop()
        public imageHeight!: any;
        
        @Prop()
        public imageMaxSize!:any;

        @Prop()
        public noPreview!: any;

        @Prop()
        public disabled!: any;

        private angle = 0;                        
        public oriImageUrl = '';   
        private cropping = false;
        public init() {
            this.angle = 0;
            this.oriImageUrl = '';
            this.cropping = false;
        }
        @Watch('value')
        private changeValue(){
            console.log(this.value);
            let data = this.value[0];
            let response = data.response;
            console.log(response);
            console.log(response.status.code);
            if(response && response.status && response.status.code == 0){
                this.oriImageUrl = response.result;
            }else{
                this.oriImageUrl = this.value[0].url;
            }
        }
        public data() {
            return {
                config,
                borderImageSource:
                // tslint:disable-next-line:max-line-length
                    'url(\"data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==\")'
            };
        }
        public async handleImageChange(e: any) {

            if (e.fileList.length === 0) {
                this.init();
            }
            this.$emit('change', yx_normFile(e));
            let response = e.file.response;
            if(response && response.status && response.status.code == '0'){
                this.oriImageUrl = response.result;
            }
        }
        public async beforeUpload(file: any) {
            if (['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].indexOf(file.type) === -1) {
                this.$message.error('只支持jpg,gif,jpeg,png格式');
                throw new Error('只支持jpg,gif,jpeg,png格式');
            }
            
            if (!await this.checkImageWidthAndHeight(file)) {
                this.$message.error(`图片尺寸必须是${this.imageWidth}px*${this.imageHeight}px`);
                throw new Error(`图片尺寸必须是${this.imageWidth}px*${this.imageHeight}px`);
            }
        }
        public checkImageWidthAndHeight(fileObject: any) {
            
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    if (typeof reader.result === 'string') {
                        const img = new Image();
                        img.onload = () => {
                            if ((this.imageWidth && img.naturalWidth != this.imageWidth) || (this.imageHeight && img.naturalHeight != this.imageHeight)) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        };
                        img.src = reader.result;
                    }
                });
                reader.readAsDataURL(fileObject);
            });
        }

        public handleRemoveClick(e: any) {
            if (this.disabled) {
                return false;
            }
            return new Promise((resolve, reject) => {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认删除?',
                    onOk: () => {
                        resolve();
                        // 强制重置fileList, 用于修复当图片无权访问时，删除无效问题
                        this.$emit('change', []);
                    },
                    onCancel() {
                        resolve(false);
                    }
                });
            });
        }

        public created() {
            if(this.value[0] && this.value[0].url){
                this.oriImageUrl = this.value[0].url;
            }
            
        }
        public destroyed() {
        }

    }

    export default ImageCrop;
