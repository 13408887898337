var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "welcome" }, [
      _c("div", { staticClass: "logo-welcome" }, [
        _c("img", {
          staticClass: "logo-img",
          attrs: { src: require("@/assets/logo.png"), alt: "" },
        }),
        _c("img", {
          staticClass: "logo-txt",
          attrs: { src: require("@/assets/huijingcai.png"), alt: "" },
        }),
        _c("p", { staticClass: "txt" }, [_vm._v("平台运营中心")]),
      ]),
      _c("div", { staticClass: "foot" }, [
        _c("img", {
          attrs: { src: require("../../assets/lkl-logo.png"), alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }