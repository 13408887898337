import * as services from './services';
import {getConflictDefaultAdPlan} from '../services';
import {Modal} from 'ant-design-vue';
import {flatAndUniqueMaterialsTerminals} from '@/utils';

export default {
    namespaced: true,
    state: {
        loading: false,
        data: null,
        error: null,
        updated: null
    },
    mutations: {
        initDataRequested(state: any) {
            state.loading = true;
            state.data = null;
            state.updated = null;
        },
        initDataSuccessed(state: any, data: any) {
            state.loading = false;
            state.data = data;
        },
        initDataFailed(state: any, error: any) {
            state.loading = false;
            state.error = error;
        },

        updateRequested(state: any) {
            state.loading = true;
        },
        updateSuccessed(state: any, data: any) {
            state.updated = new Date();
            state.loading = false;
        },
        updateFailed(state: any, error: any) {
            state.loading = false;
            state.error = error;
        },
    },
    actions: {
        async initData(context: any, planId: any) {
            context.commit('initDataRequested');
            try {
                const data = await services.getPlanData(planId);
                context.commit('initDataSuccessed', data);
            } catch (e) {
                context.commit('initDataFailed', e);
            }
        },

        async update(context: any, payload: any) {
            context.commit('updateRequested');
            if (payload.is_default) {
                const conflicts: any[] = await getConflictDefaultAdPlan({
                    plan_id: payload.id,
                    terminals: flatAndUniqueMaterialsTerminals(payload.materials_terminals),
                    position: payload.position
                });
                if (conflicts.length > 0) {
                    Modal.error({
                        title: '冲突提示',
                        content: '当前广告位与其他默认广告存在冲突，请先下架或删除冲突广告。'
                    });
                    context.commit('updateFailed');
                    return;
                }
            }
            try {
                const data = await services.update(payload);
                context.commit('updateSuccessed', data);
            } catch (e) {
                context.commit('updateFailed', e);
            }
        }
    },
    getters: {

    }
};
