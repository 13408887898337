import { request_yx, downloadRequestPOST_yx } from '@/utils';
import { message } from 'ant-design-vue';

export async function search(params: { query: any }) {
    try {
        return await request_yx({
            api: '/console/activity/merGroup/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        throw error;
    }
}

export async function createSharedQuota(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/merGroup/insert',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function editSharedQuota(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/merGroup/update',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function updateStatus(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/merGroup/changeStatus',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function list(params: any): Promise<{ list: any; total: any }> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: {
                type: 'act_mer',
                activityId: sessionStorage.getItem('temp_activity_id')
            }
        });
    } catch (e) {
        throw e;
    }
}
export async function refresh(id: any): Promise<{ list: any; total: any }> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: {
                taskId: id,
                type: 'act_mer',
                activityId: sessionStorage.getItem('temp_activity_id')
            }
        });
    } catch (e) {
        throw e;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequestPOST_yx({
        data: params,
        prefix: '共享额度',
        api: '/console/activity/merGroup/file/export'
    });
}

export async function bindMer(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/merGroup/bindMer',
            data: params
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
