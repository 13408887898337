import {request} from '@/utils';

export interface SaveParams {
    mall_id: string;
    app_id: string;
    private_key: string;
    public_key: string;
    mall_name: string;
    mall_belong: string;
    logo: string;
    agreement_title: string;
    agreement_url: string;
    province: string;
    city: string;
    district: string;
    address: string;
}

export async function save(params: SaveParams) {
    try {
        return await request({
            method: 'POST',
            api: '/console/mall/update',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function detail(id: string) {
    try {
        return await request({
            api: '/console/mall/info',
            data: {mall_id: id}
        });
    } catch (e) {
        const error: any = new Error('获取商场信息失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
