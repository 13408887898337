
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import SearchTable from '@/components/SearchTable.vue';
import { mapState } from 'vuex';
import { defaultCustomRender } from '@/utils';
@Component({
    components: {
        SearchTable
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            total() {
                return this.state().total;
            },
            page() {
                return this.state().page;
            },
            pageSize() {
                return this.state().pageSize;
            },
            loading() {
                return this.state().loading;
            }
        })
    }
})
class ConfigSearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    @Prop()
    public id!: string;

    public data() {
        return {
            visible: false,
            changeType: '',
            beforInfoData: {},
            afterInfoData: {},
            columns: [
                {
                    title: '额度组ID',
                    dataIndex: 'merGroupId',
                    customRender: defaultCustomRender
                },
                {
                    title: '修改人',
                    dataIndex: 'createBy',
                    customRender: defaultCustomRender
                },
                {
                    title: '修改时间',
                    dataIndex: 'gmtCreate',
                    customRender: defaultCustomRender
                },
                {
                    title: '修改类型',
                    dataIndex: 'changeType',
                    customRender: (changeType: string) => {
                        const changeTypeMap = {
                            time: '活动名单周期变更',
                            status: '状态变更',
                            other: '其他变更',
                            normal: '常规变更',
                            addMer: '添加名单',
                            removeMer: '移除名单'
                        }
                        return changeTypeMap[changeType]
                    }
                },
                {
                    title: '商户号记录',
                    dataIndex: 'merNos',
                    customRender: (text: any, record: any,) => {
                        if (record.changeType === 'addMer' || record.changeType === 'removeMer') {
                            const afterInfoData = record.afterJson && JSON.parse(record.afterJson);
                            return (afterInfoData || []).map((item: any) => item.merNo).join(',');
                        }
                        return '-'
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'op',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    customRender: (text: any, record: any, idx: any) => {
                        return this.$createElement(
                            'a-button',
                            {
                                props: {
                                    size: 'small'
                                },
                                on: {
                                    click: () => this.handlePreviewInfo(record)
                                }
                            },
                            '详情'
                        );
                    }
                }
            ]
        };
    }

    public async handlePreviewInfo(record: any) {
       this.beforInfoData = record.beforJson && JSON.parse(record.beforJson);
       this.afterInfoData = record.afterJson && JSON.parse(record.afterJson);
       this.changeType = record.changeType;
       this.visible = true;
    }

    private handleInfoCancel () {
        this.visible = false;
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(pageSize: number) {
        this.dispatch('pageSizeChange', pageSize);
    }
}

export default ConfigSearchTable;
