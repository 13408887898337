import { AdContainerSelector } from '@/components/custom-form-components';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        groupId?: any;
        value?: any;
        onChange?: any;
        allowContainers?: any;

        disabled?: any;
    }
}

interface AdMaterialContentTerminalList {
    normalizedValue: any;
}

@Component<AdMaterialContentTerminalList>({
    components: {
        AdContainerSelector
    },
    computed: {
        normalizedValue() {
            return (this.value || []).map(this.normalize);
        }
    }
})
class AdMaterialContentTerminalList extends Vue {
    public static inc = 0;

    @Prop()
    public value!: any;

    @Prop()
    public tplClass!: any;

    @Prop()
    public disabled!: any;

    @Prop()
    public max!: number;

    public groupId = Symbol('groupId');

    public normalize(item: any) {
        if (!item.key) {
            return {
                key: 'key_' + AdMaterialContentTerminalList.inc++,
                ...item
            };
        } else {
            return item;
        }
    }

    public renderList() {

        return this.normalizedValue.map((item: any, idx: any) => {
            return (this.tplClass ?
                <div key={item.key} style="border: solid 1px #eee;padding: 10px;margin-bottom: 10px;">
                    <a-button disabled={this.disabled} onClick={() => {
                        this.normalizedValue.splice(idx, 1);
                        this.$emit('change', [...this.normalizedValue]);
                    }} size="small" type="default" icon="close" style="font-size:10px;float:right;" />
                    <div>
                        <this.tplClass disabled={this.disabled} data={item.content} onChange={(value: any) => {
                            item.content = value;
                            this.$emit('change', [...this.normalizedValue]);
                        }} />
                    </div>
                    载体:
                    <AdContainerSelector
                        disabled={this.disabled}
                        allowContainers={this.tplClass.allowContainers}
                        groupId={this.groupId}
                        value={item.terminals}
                        onChange={(event: any) => this.containerChangeHandler(idx, event)}
                    />
                </div> : null
            );
        });
    }

    public render() {

        const addButtonDisabled = this.normalizedValue.length >= this.max;

        return (
            <div class="custom-form-item-wrapper">
                {this.renderList()}
                <a-button disabled={this.disabled || addButtonDisabled} onClick={this.addOne}>
                    + 添加
                </a-button>
            </div>
        );
    }

    public containerChangeHandler(idx: any, checkedValues: any) {
        if (this.normalizedValue[idx]) {
            this.normalizedValue[idx].terminals = Array.from(
                new Set([...checkedValues, ...this.tplClass.defaultTerminals]));
            this.$emit('change', [...this.normalizedValue]);
        }
    }


    @Watch('tplClass')
    public tplClassChanged(tplClass: any) {
        if (tplClass) {
            this.$emit('change', [{
                key: 'key_' + AdMaterialContentTerminalList.inc++,
                content: {}, terminals: [...this.tplClass.defaultTerminals]
            }]);
        } else {
            this.$emit('change', []);
        }
    }

    private addOne() {
        this.$emit('change', [...(this.normalizedValue || []), {
            key: 'key_' + AdMaterialContentTerminalList.inc++,
            content: {}, terminals: [...this.tplClass.defaultTerminals]
        }]);
    }
}
export default AdMaterialContentTerminalList;
