import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: 'ID',
        dataIndex: 'id',
        width: 200,
        align: 'center'
    },
    {
        title: '角色名称',
        dataIndex: 'name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '是否配置权限',
        dataIndex: 'is_active',
        align: 'center',
        width: 200,
        customRender: (isActive: boolean) => isActive ? '是' : '否'
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
