var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("SearchFormWrapper", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.collapsed,
            expression: "!collapsed",
          },
        ],
        attrs: {
          buttonSpan: 16,
          format: _vm.format,
          loading: _vm.loading,
          consts: ["mall_id"],
        },
        on: { search: _vm.handleSearch },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function () {
              return [
                _c(
                  "a-col",
                  { attrs: { span: "8" } },
                  [
                    _c(
                      "a-form-item",
                      { attrs: { label: "商场ID" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "mall_id",
                                { initialValue: _vm.formData.mall_id },
                              ],
                              expression:
                                "['mall_id', {initialValue: formData.mall_id}]",
                            },
                          ],
                          attrs: { disabled: true },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }