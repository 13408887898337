
    import {AutoComplete} from 'ant-design-vue';
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {request} from '@/utils';

    interface BizType {
        dataSource: string[];
    }

    @Component({
        components: {
            [AutoComplete.name]: AutoComplete
        }
    })
    class BizType extends Vue {

        @Prop()
        public value!: string;

        public async created() {

            try {
                this.dataSource = (await request({
                    method: 'GET',
                    api: '/console/client/biz/type'
                })).map((i: any) => i.business_type_name);
            } catch (e) {
                if (!e.is403) {
                    this.$message.error('获取行业列表失败, 请联系技术人员!');
                }
            }
        }

        public data() {
            return {
                dataSource: []
            };
        }

        public handleChange(value: string) {
            this.$emit('change', value);
        }

        public filterOption(input: string, option: any) {
            return (
                option.componentOptions.children[0].text
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
            );
        }
    }

    export default BizType;
