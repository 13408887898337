import blankCell from '@/common/blankCell';
import {
    defaultCustomRender,shtBindCustomRender,officialAccountCustomRender,shtStatusCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        width: 80
    },
    {
        title: 'openId',
        dataIndex: 'openId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商户号',
        dataIndex: 'shopNo',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '手机号',
        dataIndex: 'remark',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '系统',
        dataIndex: 'officialAccount',
        width: 200,
        align: 'center',
        customRender: officialAccountCustomRender
    },
    {
        title: '交易推送状态',
        dataIndex: 'tradeNotify',
        width: 200,
        align: 'center',
        customRender: shtStatusCustomRender
    },
    
    {
        title: '账单推送状态',
        dataIndex: 'billNotify',
        width: 200,
        align: 'center',
        customRender: shtStatusCustomRender
    },
    {
        title: '绑定状态',
        dataIndex: 'status',
        width: 200,
        align: 'center',
        customRender: shtBindCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '修改时间',
        dataIndex: 'modifyTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 150,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
