
    import EditForm from './EditForm.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';

    @Component({
        components: {
            EditForm
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.mallEditPage.loading
            })
        }
    })
    export default class EditPage extends Vue {
        public created() {
            this.$store.dispatch('mallEditPage/initData', this.$route.params.id);
        }

        @Watch('$store.state.mallEditPage.lastSaveTime')
        public lastSaveTimeListener(lastSaveTime: string) {
            this.$message.success('提交成功!');
            const queryString = this.$store.getters['mallSearchPage/queryString']();
            this.$router.push({
                path: '/console/mall/search' + queryString
            });
        }

        @Watch('$store.state.mallEditPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }
    }
