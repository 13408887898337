import * as DICTS from '@/common/dicts';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '商圈ID',
        dataIndex: 'id',
        align: 'center',
        fixed: 'left',
        width: 200
    },
    {
        title: '商圈名称',
        dataIndex: 'name',
        width: 300,
        align: 'center'
    },
    {
        title: '商圈归属',
        dataIndex: 'belong',
        align: 'center',
        width: 300,
        customRender: (belong: string) => {
            return DICTS.BIZ_DISTRICT_BELONG[belong] || '-';
        }
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 250,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
