var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-row",
    { staticStyle: { "text-align": "left" } },
    [
      _c(
        "a-col",
        { attrs: { span: 8 } },
        [
          _c(
            "croppa",
            {
              attrs: {
                id: "cropa",
                "prevent-white-space": true,
                placeholder: "点击选择图片",
                width: 120,
                height: 120,
                "canvas-color": "#f5f5f5",
                "show-remove-button": false,
                disabled: _vm.uploading,
                accept: "image/*",
                quality: 2,
              },
              on: { "file-choose": _vm.handleFileChoose },
              model: {
                value: _vm.cropaInstance,
                callback: function ($$v) {
                  _vm.cropaInstance = $$v
                },
                expression: "cropaInstance",
              },
            },
            [
              _c("img", {
                attrs: {
                  slot: "initial",
                  crossorigin: "anonymous",
                  src: _vm.value || "",
                },
                slot: "initial",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _vm.hasImage
            ? _c(
                "a-button",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    "font-size": "12px",
                  },
                  attrs: { loading: _vm.uploading, size: "small" },
                  on: { click: _vm.handleUpload },
                },
                [_vm._v("确认上传\n        ")]
              )
            : _vm._e(),
          _vm.hasImage
            ? _c(
                "a-button",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "33px",
                    left: "0px",
                    "font-size": "12px",
                  },
                  attrs: { size: "small" },
                  on: { click: _vm.handleRemove },
                },
                [_vm._v("重新选择\n        ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                "line-height": "15px",
                color: "#f5222d",
                position: "absolute",
                top: "63px",
                left: "0px",
                "font-size": "12px",
              },
            },
            [_vm._v("\n            " + _vm._s(_vm.errorMsg) + "\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }