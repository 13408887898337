import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import store from '@/store';
// import preferences from './preferences';

store.registerModule(
    'activityMerBatchSearchPage',
    create(
        {   
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    pageIndex: page,
                    pageSize: pageSize
                });
            },

            download() {
                const params = JSON.parse(sessionStorage.getItem("activityBatchDownloadParams") || '{}');
                return services.downloadXls(
                    deleteEmpty({
                        ...params
                    })
                );
            },
            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRows', []);
            }
        },
        {
            state: {
                selectedRows: [],
                error: '',
            },
            mutations: {
                setSelectedRows(state: any, rows: any) {
                    state.selectedRows = rows;
                },
                initDataRequested(state: any) {
                    state.loading = true;
                    state.data = null;
                    state.updated = null;
                },
                initDataSuccessed(state: any, data: any) {
                    state.loading = false;
                    state.data = data;
                },
                initDataFailed(state: any, error: any) {
                    state.loading = false;
                    state.error = error;
                },
                setError(state: any, error: any) {
                    state.error = error;
                },
            },
            actions: {
                
            }
        }
    )
);
