var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "GrayUpload",
        [
          _c(
            "template",
            { slot: "trigger" },
            [
              _c("a-button", { attrs: { disabled: _vm.loading } }, [
                _vm._v("上传"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.GRAY.UPDATE_STATUS] },
        on: { pass: _vm.handleOpenClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("开启\n            ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.GRAY.UPDATE_STATUS] },
        on: { pass: _vm.handleCloseClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("关闭\n            ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.GRAY.DELETE_STATUS] },
        on: { pass: _vm.handleDeleteClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("删除\n            ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }