var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _vm.formData
                ? _c(
                    "a-form",
                    {
                      staticClass: "ad-create-form",
                      attrs: { form: _vm.baseForm },
                      on: { submit: _vm.handleNext },
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "活动名称" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "name",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        max: 64,
                                        message: "长度超限，请检查后重新输入!",
                                      },
                                    ],
                                    initialValue: _vm.formData.activityName,
                                  },
                                ],
                                expression:
                                  "[\n                            'name',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 64, message: '长度超限，请检查后重新输入!'}\n                                ],\n                                initialValue: formData.activityName\n                            }\n                        ]",
                              },
                            ],
                            attrs: {
                              placeholder: "请填写活动名称（64个字以内）",
                              maxLength: 64,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "主题活动ID" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "gwActivityId",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        max: 32,
                                        message: "长度超限，请检查后重新输入!",
                                      },
                                    ],
                                    initialValue: _vm.formData.gwActivityId,
                                  },
                                ],
                                expression:
                                  "[\n                            'gwActivityId',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 32, message: '长度超限，请检查后重新输入!'}\n                                ],\n                                initialValue: formData.gwActivityId\n                            }\n                        ]",
                              },
                            ],
                            attrs: {
                              type: "number",
                              maxLength: 32,
                              placeholder: "请输入主题活动ID",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "批次号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "batchNo",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern: /^[^\s]*$/,
                                        message: "不允许出现空格",
                                      },
                                    ],
                                    initialValue: _vm.formData.platActivity,
                                  },
                                ],
                                expression:
                                  "[\n                            'batchNo',\n                            {rules: [{required: true, message: '请填写必填项'},{pattern: /^[^\\s]*$/, message: '不允许出现空格'}\n                            ],initialValue: formData.platActivity}\n                        ]",
                              },
                            ],
                            attrs: { placeholder: "请输入" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "优惠方式" } },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "discountWay",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请填写必填项",
                                        },
                                      ],
                                      initialValue: 1,
                                    },
                                  ],
                                  expression:
                                    "['discountWay',{rules: [{required: true, message: '请填写必填项'}],initialValue: 1}]",
                                },
                              ],
                              attrs: { checked: true },
                            },
                            [
                              _vm._v(
                                "\n                        定额立减\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "减价金额" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "reduceAmount",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern:
                                          /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                        message: "请输入正确的金额,必须大于1元",
                                      },
                                    ],
                                    initialValue:
                                      _vm.formData.discountAmount / 100,
                                  },
                                ],
                                expression:
                                  "[\n                            'reduceAmount',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '请输入正确的金额,必须大于1元'}\n                                ],\n                                initialValue: formData.discountAmount/100\n                            }\n                        ]",
                              },
                            ],
                            staticStyle: { width: "95%" },
                            attrs: { placeholder: "请输入" },
                          }),
                          _vm._v("  元\n                "),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "使用门槛" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "useSill",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern:
                                          /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                        message: "请输入正确的金额",
                                      },
                                    ],
                                    initialValue: _vm.formData.payLine / 100,
                                  },
                                ],
                                expression:
                                  "[\n                            'useSill',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '请输入正确的金额'}\n                                ],\n                                initialValue: formData.payLine/100\n                            }\n                        ]",
                              },
                            ],
                            staticStyle: { width: "68%" },
                            attrs: { placeholder: "请输入" },
                          }),
                          _vm._v("  元及以上订单金额可用\n                "),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "可用时间" } },
                        [
                          _c("DisableDatePicker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "range_valiable_time",
                                  {
                                    initialValue: _vm.formData.activityTime,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['range_valiable_time',{initialValue: formData.activityTime,rules: [{required: true, message: '请填写必填项'}]}]",
                              },
                            ],
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "timeRule",
                                    {
                                      initialValue:
                                        _vm.formData.availableTimeType,
                                    },
                                  ],
                                  expression:
                                    "['timeRule', {initialValue: formData.availableTimeType}]",
                                },
                              ],
                              on: { change: _vm.timeRuleChange },
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "0" },
                                },
                                [_vm._v("有效期内，任意时段可用")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "1" },
                                },
                                [_vm._v("有效期内，规则日期可用")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "2" },
                                },
                                [_vm._v("有效期内，不规则日期可用")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-modal",
                            {
                              attrs: {
                                maskClosable: false,
                                destroyOnClose: "",
                                visible: _vm.timeRulevisible,
                              },
                              on: {
                                ok: _vm.handleTimeRuleHide,
                                cancel: _vm.handleTimeRuleHideCancel,
                              },
                            },
                            [
                              _c("ScheduleTimePicker", {
                                attrs: { value: _vm.timeRuleData },
                                on: { change: _vm.initTimeRuleData },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-modal",
                            {
                              attrs: {
                                maskClosable: false,
                                destroyOnClose: "",
                                visible: _vm.noTimeRulevisible,
                              },
                              on: {
                                ok: _vm.handleNoTimeRuleHide,
                                cancel: _vm.handleNoTimeRuleHideCancel,
                              },
                            },
                            [
                              _c("IrregularScheduleTimePicker", {
                                attrs: { value: _vm.noTimeRuleData },
                                on: { change: _vm.initNoTimeRuleData },
                              }),
                            ],
                            1
                          ),
                          this.activityDateType === "1"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "125px" } },
                                [
                                  _c(
                                    "a-checkbox-group",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "weekdays",
                                            {
                                              initialValue:
                                                this.timeRuleData.days,
                                            },
                                          ],
                                          expression:
                                            "['weekdays', {initialValue: this.timeRuleData.days}]",
                                        },
                                      ],
                                      staticStyle: { width: "80%" },
                                      attrs: { disabled: "" },
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "1" } },
                                                [_vm._v("周一")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "2" } },
                                                [_vm._v("周二")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "3" } },
                                                [_vm._v("周三")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "4" } },
                                                [_vm._v("周四")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "5" } },
                                                [_vm._v("周五")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        {
                                          staticStyle: { "margin-top": "6px" },
                                        },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "6" } },
                                                [_vm._v("周六")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "7" } },
                                                [_vm._v("周日")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                        时间区间："
                                  ),
                                  _vm._l(
                                    this.timeArrayTemp,
                                    function (item, index) {
                                      return _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(item) +
                                            "\n                         "
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          this.activityDateType === "2"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "125px" } },
                                [
                                  _vm._v("\n                       日期区间："),
                                  _vm._l(
                                    this.dateArrayTemp,
                                    function (item, index) {
                                      return _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(item) +
                                            "\n                        "
                                        ),
                                      ])
                                    }
                                  ),
                                  _c("br"),
                                  _vm._v("\n                       时间区间："),
                                  _vm._l(
                                    this.timeArrayTemp,
                                    function (item, index) {
                                      return _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(item) +
                                            "\n                         "
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "订单优惠标记" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "discountFlag",
                                  {
                                    initialValue: _vm.formData.goodsTag,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern: /^[^\s]*$/,
                                        message: "不允许出现空格",
                                      },
                                      {
                                        max: 32,
                                        message: "长度超限，请检查后重新输入!",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'discountFlag',\n                            {initialValue: formData.goodsTag,rules: [{required: true, message: '请填写必填项'},{pattern: /^[^\\s]*$/, message: '不允许出现空格'},{max: 32, message: '长度超限，请检查后重新输入!'}]}\n                        ]",
                              },
                            ],
                            attrs: { placeholder: "请输入" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "出资方" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "capital",
                                  {
                                    initialValue: _vm.formData.sponsorName,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        max: 32,
                                        message: "长度超限，请检查后重新输入!",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'capital',\n                            {initialValue: formData.sponsorName,rules: [{required: true, message: '请填写必填项'},{max: 32, message: '长度超限，请检查后重新输入!'}]}\n                        ]",
                              },
                            ],
                            attrs: { placeholder: "请输入" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "预警阈值" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "warnRatio",
                                  {
                                    initialValue: _vm.formData.warnRatio,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern: /^[1-9]\d*$/,
                                        message: "预警阈值必须是大于0的整数",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'warnRatio',\n                            {   \n                                initialValue: formData.warnRatio,\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^[1-9]\\d*$/, message: '预警阈值必须是大于0的整数'}\n                                    \n                                ]\n                            }\n                        ]",
                              },
                            ],
                            staticStyle: { width: "95%" },
                            attrs: { placeholder: "请输入" },
                          }),
                          _vm._v("  %\n                "),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "短信推送号码" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "warnMobile",
                                  {
                                    initialValue: _vm.formData.warnMobile,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                      {
                                        pattern: /^1\d{10}$/,
                                        message: "请填写正确的手机号",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'warnMobile',\n                            {   \n                                initialValue: formData.warnMobile,\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^1\\d{10}$/, message: '请填写正确的手机号'}\n                                ]\n                            }\n                        ]",
                              },
                            ],
                            attrs: { placeholder: "请输入" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              style: { textAlign: "left" },
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            下一步\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  style: { marginLeft: "8px" },
                                  on: { click: _vm.handleCancel },
                                },
                                [
                                  _vm._v(
                                    "\n                            取消\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "其他规则" } },
            [
              _vm.formData
                ? _c(
                    "a-form",
                    {
                      staticClass: "ad-create-form",
                      attrs: { form: _vm.otherForm },
                      on: { submit: _vm.handleCreate },
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "参与次数限制" } },
                        [
                          _vm._v(
                            "\n                    活动期间每个用户可参与\n                    "
                          ),
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "timesLimit",
                                  {
                                    initialValue: _vm.formData.countLimit,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'timesLimit',\n                            {initialValue: formData.countLimit,rules: [{required: true, message: '请填写必填项'}]}\n                        ]",
                              },
                            ],
                            staticStyle: { width: "30%" },
                            attrs: { placeholder: "请输入" },
                          }),
                          _vm._v(
                            "\n                    次活动\n                "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "discountRule",
                                    { initialValue: _vm.discountRuleCheck },
                                  ],
                                  expression:
                                    "['discountRule', {initialValue: discountRuleCheck}]",
                                },
                              ],
                              on: { change: _vm.discountRuleChange },
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "1" },
                                },
                                [_vm._v("不限制用户每天优惠次数")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "2" },
                                },
                                [
                                  _vm._v(
                                    "\n                            每天每个用户最多可获得\n                            "
                                  ),
                                  _vm.discountRuleCheck === "1"
                                    ? _c("a-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { disabled: "" },
                                      })
                                    : _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "discountRuleInput",
                                              {
                                                initialValue:
                                                  _vm.formData.dailyCountLimit,
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请填写必填项",
                                                  },
                                                ],
                                              },
                                            ],
                                            expression:
                                              "['discountRuleInput',{initialValue: formData.dailyCountLimit,rules: [{required: true, message: '请填写必填项'}]}]",
                                          },
                                        ],
                                        staticStyle: { width: "100px" },
                                      }),
                                  _vm._v(
                                    "\n                            次优惠\n                        "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "用户类型" } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "allUser",
                                    {
                                      valuePropName: "checked",
                                      initialValue: _vm.formData.allUser,
                                    },
                                  ],
                                  expression:
                                    "[\n                            'allUser',\n                             {\n                                valuePropName: 'checked',\n                                initialValue: formData.allUser,\n                             }\n                        ]",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                "\n                        所有人\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "活动店铺", required: "" } },
                        [
                          _c("CreateModal", {
                            on: {
                              change: function ($event) {
                                return _vm.changeShopData($event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function (_) {
                                    return [
                                      _c(
                                        "a-button",
                                        { attrs: { type: "primary" } },
                                        [
                                          _vm._v(
                                            "点击添加商户号和对应线下终端号"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              619975609
                            ),
                          }),
                          _c(
                            "ShopUpload",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.changeShopData($event)
                                },
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "trigger" },
                                [
                                  _c(
                                    "a-button",
                                    { staticStyle: { "margin-left": "20px" } },
                                    [_vm._v("批量上传")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.shopData.length > 0
                        ? _c(
                            "a-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("a-table", {
                                attrs: {
                                  columns: _vm.columns,
                                  dataSource: _vm.shopData,
                                  size: "small",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "action",
                                      fn: function (text, record, index) {
                                        return _c(
                                          "a",
                                          {
                                            attrs: { href: "javascript:;" },
                                            on: {
                                              click: () =>
                                                _vm.deleteShopData(record),
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  895350068
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-item",
                        { attrs: { label: "活动预算" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "budget",
                                  {
                                    initialValue:
                                      _vm.formData.budgetAmount / 100,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请填写必填项",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'budget',\n                            {initialValue: formData.budgetAmount/100,rules: [{required: true, message: '请填写必填项'}]}\n                        ]",
                              },
                            ],
                            staticStyle: { width: "95%" },
                            attrs: {
                              readonly:
                                _vm.formData.accountId === ""
                                  ? false
                                  : "readonly",
                              placeholder: "请输入",
                            },
                          }),
                          _vm._v("  元\n                "),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "budgetLimit",
                                    { initialValue: _vm.formData.budgetLimit },
                                  ],
                                  expression:
                                    "['budgetLimit', {initialValue: formData.budgetLimit}]",
                                },
                              ],
                              staticStyle: {
                                "margin-left": "120px",
                                width: "40%",
                              },
                              on: { change: _vm.budgetLimitChange },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("设置当天预算消耗上限"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("不设置当天预算消耗上限"),
                              ]),
                            ],
                            1
                          ),
                          _vm.budgetLimitCheck === "1"
                            ? _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "budgetLimitInput",
                                      {
                                        initialValue:
                                          _vm.formData.dailyAmountLimit / 100,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['budgetLimitInput',{initialValue: formData.dailyAmountLimit/100,rules: [{required: true, message: '请填写必填项'}]}]",
                                  },
                                ],
                                staticStyle: {
                                  "margin-left": "20px",
                                  width: "32.5%",
                                },
                                attrs: { placeholder: "请输入" },
                              })
                            : _vm._e(),
                          _vm.budgetLimitCheck === "1"
                            ? _c("span", [_vm._v(" 元")])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "付款方式" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "payway",
                                    {
                                      initialValue: _vm.formData.paywayCheck,
                                      rules: [
                                        {
                                          required: true,
                                          message: "请填写必填项",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "['payway',{initialValue: formData.paywayCheck,rules: [{required: true, message: '请填写必填项'}]}]",
                                },
                              ],
                              on: { change: _vm.paywayChange },
                            },
                            [
                              _c("a-radio", { attrs: { value: "0" } }, [
                                _vm._v("不限定付款方式"),
                              ]),
                              _c("a-radio", { attrs: { value: "1" } }, [
                                _vm._v("指定付款方式"),
                              ]),
                            ],
                            1
                          ),
                          _c("br"),
                          _vm.paywayCheck === "1"
                            ? _c("span", [_vm._v("指定银行卡")])
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "银行名称" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "bankName",
                                          {
                                            initialValue: _vm.formData.bankName,
                                            rules: [
                                              {
                                                required: true,
                                                message: "请填写必填项",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "['bankName',{initialValue: formData.bankName,rules: [{required: true, message: '请填写必填项'}]}]",
                                      },
                                    ],
                                    attrs: { placeholder: "请输入" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "卡类型" } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "cardType",
                                            {
                                              initialValue:
                                                _vm.formData.cardType,
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请填写必填项",
                                                },
                                              ],
                                            },
                                          ],
                                          expression:
                                            "['cardType',{initialValue: formData.cardType,rules: [{required: true, message: '请填写必填项'}]}]",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "-" } },
                                        [_vm._v("请选择")]
                                      ),
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "DEBIT" } },
                                        [_vm._v("储蓄卡")]
                                      ),
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "CREDIT" } },
                                        [_vm._v("信用卡")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "卡bin" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "cardBin",
                                          {
                                            initialValue: _vm.formData.cardBin,
                                          },
                                        ],
                                        expression:
                                          "['cardBin',{initialValue: formData.cardBin}]",
                                      },
                                    ],
                                    attrs: { placeholder: "请输入" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "remark",
                                  {
                                    initialValue: _vm.formData.prompt,
                                    rules: [
                                      {
                                        required: false,
                                        message: "请填写必填项!",
                                      },
                                      {
                                        pattern: /^.{0,50}$/,
                                        message: "非必填，限制50字",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            'remark',\n                            {initialValue: formData.prompt,rules: [{required: false, message: '请填写必填项!'},{pattern: /^.{0,50}$/, message: '非必填，限制50字'}]}\n                        ]",
                              },
                            ],
                            staticStyle: { width: "95%" },
                            attrs: {
                              placeholder: "非必填，限制50字",
                              autosize: { minRows: 6, maxRows: 6 },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              style: { textAlign: "left" },
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            提交\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  style: { marginLeft: "8px" },
                                  on: { click: _vm.handleBack },
                                },
                                [
                                  _vm._v(
                                    "\n                            返回\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }