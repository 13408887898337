import {create} from '@/common/storeFactory';
import * as services from './services';
import * as commonServices from '@/common/services';
import columns from './columns';
import {deleteEmpty} from '@/utils';

export default create({
    search(query, page, pageSize) {
        return commonServices.marketingDataSearch({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns
});
