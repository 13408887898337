
import { Modal } from 'ant-design-vue';
import { Component, Watch, Vue, Inject } from 'vue-property-decorator';
import { mapState } from 'vuex';
import PRIVILEGE from '@/common/privileges';
import { Click } from '@/components/Auth';
import CreateModal from './CreateModal.vue';

interface Actions {
    selectedRowKeys: any;
    hideMessage: any;
}
@Component({
    components: {
        Click,
        CreateModal,
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.shopWhiteListSearchPage.loading,
            selectedRowKeys: (state: any) =>
                state.shopWhiteListSearchPage.selectedRowKeys,
        }),
    },
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            hideMessage: null,
            PRIVILEGE,
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.dispatch('search');
    }
    public handleDeleteClick() {
        Modal.confirm({
            title: '删除',
            content: '确认从白名单移除所选数据吗?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.dispatch('deleteMembers', {
                    ids: this.selectedRowKeys.join(',')
                });
            },
        });
    }
}

export default Actions;
