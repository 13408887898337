var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        placeholder: "请选择广告位",
        allowClear: true,
        value: _vm.value,
      },
      on: { change: _vm.changeHandler },
    },
    [
      _c(
        "a-select-option",
        {
          attrs: {
            disabled: _vm.disabled || _vm.isDisabledOption("B_HOME_BOTTOM"),
            value: "B_HOME_BOTTOM",
          },
        },
        [_vm._v("B端首页下方广告位")]
      ),
      _c(
        "a-select-option",
        {
          attrs: {
            disabled: _vm.disabled || _vm.isDisabledOption("C_HOME_BOTTOM"),
            value: "C_HOME_BOTTOM",
          },
        },
        [_vm._v("C端首页下方广告位")]
      ),
      _c(
        "a-select-option",
        {
          attrs: {
            disabled: _vm.disabled || _vm.isDisabledOption("BEFORE_PAY"),
            value: "BEFORE_PAY",
          },
        },
        [_vm._v("支付前页面广告位")]
      ),
      _c(
        "a-select-option",
        {
          attrs: {
            disabled: _vm.disabled || _vm.isDisabledOption("AFTER_PAY"),
            value: "AFTER_PAY",
          },
        },
        [_vm._v("支付后页面广告位")]
      ),
      _c(
        "a-select-option",
        {
          attrs: {
            disabled: _vm.disabled || _vm.isDisabledOption("ORDER_DETAIL"),
            value: "ORDER_DETAIL",
          },
        },
        [_vm._v("订单详情页广告位")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }