import {
    defaultCustomRender,bankTemplateType,timeCustomRender,bankActivityStatus
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center',
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        width: 100,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '名称',
        dataIndex: 'name',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '开始时间',
        dataIndex: 'startTimeStr',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '结束时间',
        dataIndex: 'endTimeStr',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '政策ID',
        dataIndex: 'templetId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '类型',
        dataIndex: 'templetType',
        width: 200,
        align: 'center',
        customRender: (time: any) => {
            return bankTemplateType(time);
        }
    },
    {
        title: '业主方',
        dataIndex: 'ownerId',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '头寸账户',
        dataIndex: 'expenseAccount',
        align: 'center',
        width: 200,
        customRender:defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'activityStatus',
        align: 'center',
        width: 100,
        customRender: (time: any) => {
            return bankActivityStatus(time);
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreateStr',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '修改人',
        dataIndex: 'updateBy',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModifiedStr',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 200,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
