
import { mapState } from 'vuex';
import { Component, Watch, Vue } from 'vue-property-decorator';

interface Actions {
    hideMessage: any;
}

@Component({
    components: {},
    computed: {
        ...mapState({
            loading: (state: any) => state.companyQueryConfSearchPage.loading
        })
    }
})
class Actions extends Vue {
    public data() {
        return {
            hideMessage: null
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.$router.push({
            path:
                '/console/companyQueryConf/search' +
                this.$store.getters['companyQueryConfSearchPage/queryString']()
        });
    }
}

export default Actions;
