
    import {Component, Prop, Vue} from 'vue-property-decorator';

    interface Drawer {
        visible: boolean;
    }

    @Component({
        components: {},
        computed: {}
    })
    class Drawer extends Vue {

        @Prop()
        public title!: string | number;

        @Prop()
        public width!: number;

        public data() {
            return {
                visible: false
            };
        }

        public onClose() {
            this.visible = false;
            this.$emit('close');
        }

        public onClick() {
            this.visible = true;
            this.$emit('open');
        }
    }

    export default Drawer;
