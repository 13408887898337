import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-q-whitelist-source-selector',
    options: [{
        label: '自助绑定(主扫)',
        value: 'SINGLE_IMPORT'
    }, {
        label: '机具扫码激活(被扫)',
        value: 'POS_IMPORT'
    }, {
        label: '运营平台批量导入',
        value: 'BULK_IMPORT'
    }]
});
