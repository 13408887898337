
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import DownloadForm from './DownloadForm.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {queryToHumanReadable} from '@/utils';
    import './store';
    import CardItem from '@/components/CardItem.vue';
	import { message ,Modal} from 'ant-design-vue';
    

    @Component({
        components: {
            DownloadForm,
            SearchCommonLayout,
            Show,
            FieldsFilter,
        },
        computed: {
            ...mapState({
            })
        }
    })
    export default class boothCode extends Vue {
        public storeName = 'boothCodeSearchPage';
        public PRIVILEGE = PRIVILEGE;
        public fixed = false;

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        public created() {
            
        }

        @Watch('$store.state.boothCodeSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.boothCodeSearchPage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            this.dispatch('initQuery', this.$route.query);
        }

        public handleFixed() {
            this.fixed = true;
        }
        private handleFieldChange(e: any) {
            this.$store.commit('boothCodeSearchPage/setFields', e);
        }

        // private handleFieldSort(e: any) {
        //     preferences.setSortFields(e);
        //     this.$store.commit('boothCodeSearchPage/sortFields', e);
        // }
    }
