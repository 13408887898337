import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
        align: 'center',
        fixed: 'left',
        width: 200,
        // customRender: defaultCustomRender
        scopedSlots: {customRender: 'mobile'},
    },
    {
        title: '姓名',
        dataIndex: 'first_name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '角色',
        dataIndex: 'auth_group',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'date_joined',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '最后一次登录',
        dataIndex: 'last_login',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 180,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
