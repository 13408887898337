import {RouteConfig} from 'vue-router';
import Editor from './Editor.vue';
import Create from './CreatePage/index.vue';
import Edit from './EditPage/index.vue';
import Search from './SearchPage/index.vue';

const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'create',
        name: '新建',
        component: Create,
    },
    {
        path: ':id',
        name: '编辑',
        component: Edit,
    }
];

export default route;
