import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-pay-channel',
    options: [{
        label: '微信',
        value: 'WECHAT'
    }, {
        label: '支付宝',
        value: 'ALIPAY'
    }, {
        label: '云闪付',
        value: 'UQRCODEPAY'
    }, {
        label: '微信H5',
        value: 'WECHATH5'
    }]
});
