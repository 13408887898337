import {zeroCustomRender} from '@/utils';

export default [
    {
        dataIndex: 'pay_type',
        title: '支付方式',
        align: 'center',
        width: 80,
        customRender(value:any,row:any,index:any) {
            const obj = {
                children: value,
                attrs: row
            }
            if(index == 0) {
                obj.attrs.rowSpan = 2
            }
            if(index == 1) {
                obj.attrs.rowSpan = 0
            }
            if(index == 2) {
                obj.attrs.rowSpan = 2
            }
            if(index == 3) {
                obj.attrs.rowSpan = 0
            } 
            return obj

        }
    },
    {
        dataIndex: 'carrier',
        title: '载体',
        align: 'center',
        width: 80
    },
    {
        dataIndex: 'today_use_coupons_count',
        title: '今日用券笔数',
        align: 'right',
        width: 110,
        customRender: zeroCustomRender,
    },
    {
        dataIndex: 'yesterday_use_coupons_count',
        title: '昨日用券笔数',
        align: 'right',
        width: 110,
        customRender: zeroCustomRender,
    },
    {
        dataIndex: 'seven_days_use_coupons_count',
        title: '最近7天用券笔数',
        align: 'right',
        width: 130,
        customRender: zeroCustomRender,
    },
    {
        dataIndex: 'thirty_days_use_coupons_count',
        title: '最近30天用券笔数',
        align: 'right',
        width: 140,
        customRender: zeroCustomRender,
    },
];
