var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-tabs",
    {
      staticStyle: { overflow: "visible" },
      attrs: { activeKey: _vm.activeKey, animated: false },
      on: { change: _vm.handleChange },
    },
    [
      _c(
        "a-tab-pane",
        {
          key: "wechat",
          staticStyle: { padding: "20px" },
          attrs: { tab: "微信" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.HEXIAO.CONFIGURE] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("SearchPage", {
                      attrs: { active: _vm.activeKey === "wechat" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "union",
          staticStyle: { padding: "20px" },
          attrs: { tab: "银联" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.UNION_HEXIAO.CONFIGURE] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("SearchUnionPage", {
                      attrs: { active: _vm.activeKey === "union" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }