
    import { Modal } from 'ant-design-vue';
    import {mapState} from 'vuex';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import DateDrift from 'date-drift';
    import moment from 'moment';

    function todayTime() {
        const d = new Date();
        return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`);
    }

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.operateLogSearchPage.loading,
                downloading: (state: any) => state.operateLogSearchPage.downloading,
                selectedRows: (state: any) => state.operateLogSearchPage.selectedRows,
                deleting: (state: any) => state.operateLogSearchPage.deleting
            })
        }
    })
    class Actions extends Vue {
        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public handleRefresh() {
            this.$router.push({
                path:
                    '/console/operate-log/search' +
                    this.$store.getters['operateLogSearchPage/queryString']()
            });
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        @Watch('$store.state.operateLogSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.operateLogSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.operateLogSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('operateLogSearchPage/download');
        }

        public handleDelete() {
            Modal.confirm({
                title: '删除日志',
                content: '确定删除已选中日志吗?',
                onOk: () => {
                    this.$store.dispatch('operateLogSearchPage/deleteLog');
                },
                onCancel() {
                    // do nothing
                }
            });
        }

        public handleDelete30Days() {
            Modal.confirm({
                title: '清空30天前日志',
                content: '确定清空30天前的日志吗?',
                onOk: () => {
                    const time = moment(new DateDrift(todayTime()).driftDays(-29).getDate()).format('YYYY-MM-DD 00:00:00');
                    this.$store.dispatch('operateLogSearchPage/deleteLog', time);
                },
                onCancel() {
                    // do nothing
                }
            });
        }
    }

    export default Actions;
