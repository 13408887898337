
import AddForm from './AddForm.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        AddForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public activityInfo!: any;
    @Prop()
    public paytype!: string;
    public created() {}

    @Watch('activityInfo')
    public initQueryData(error: any) {
        if (!this.activityInfo) {
            return;
        }
        this.$store.dispatch('sharedQuotaConfSearchPage/initData', {
            ...this.activityInfo
        });
    }

    public onClose() {
        this.$emit('close');
    }
}
