import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import store from '@/store';


store.registerModule(
    'boothCodeSearchPage',
    create(
        {
            search(query, page, pageSize) {
                return services.search({
                    ...query
                });
            },

            download(query) {
                query = {
                    start_time : sessionStorage.getItem("boothDownloadParamsStartTime"),
                    end_time : sessionStorage.getItem("boothDownloadParamsEndTime")
                }
                console.log(query)
                return services.downloadXls(
                    deleteEmpty({
                        ...query
                    })
                );
            },
            beforeSearch(context: any) {
                context.commit('setSelectedRowKeys', []);
            }
        },
        {
            state: {
                openStatusError: null,
                joinStatusError: null,
                selectedRowKeys: [],
                sortFields: [],
            },
            mutations: {
                setSelectedRowKeys(state: any, selectedRowKeys: any[]) {
                    state.selectedRowKeys = selectedRowKeys;
                },
                sortFields(state: any, sortArr: any) {
                    state.sortFields = sortArr;
                },
                
                setFields(state: any, {key, checked}: any) {
                    state.fields = {
                        ...state.fields,
                        [key]: checked,
                    };
                }
            }
        }
    ),
    
);
