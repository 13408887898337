
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as validators from '../../validators';
import {
    DatePicker,
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
interface DetailForm {
    baseForm: any;
    discountForm: any;
    merchantForm: any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.activityEditPageUnion.data &&
                    this.formatFormData(state.activityEditPageUnion.data)
                );
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public discountForm;
    public merchantForm;
    public activeTab: any;

    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public discountType: any;

    public data() {
        return {
            activeTab: '1',
            discountType: '0',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            discountForm: this.$form.createForm(this),

            //第三页数据,
            merchantForm: this.$form.createForm(this),
            shopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                }
            ]
        };
    }
    public created() {}

    // public initTimeRuleData(data:any){
    //     this.timeRuleData = data;
    // }

    // public handleTimeRuleHide(){

    //     this.timeRulevisible = false;
    // }

    // public initNoTimeRuleData(data:any){
    //     this.noTimeRuleData = data;
    // }

    // public handleNoTimeRuleHide(){
    //     console.log(this.noTimeRuleData)

    //     this.noTimeRulevisible = false;
    // }

    public tabChange(key: any) {
        this.activeTab = key;
    }

    // public handleNext(e: any) {
    //    this.activeTab = "2";
    // }

    // public handleCreate(e: any) {
    //     e.preventDefault();
    //     const queryString = this.$store.getters[
    //         'activitiesDraftSearchPage/queryString'
    //     ]();
    //     this.$router.push({
    //         path: '/console/activity/open/search' + queryString
    //     });
    // }

    // public async handleCancel() {
    //     const queryString = this.$store.getters[
    //         'activitiesDraftSearchPage/queryString'
    //     ]();
    //     this.$router.push({
    //         path: '/console/activity/open/search' + queryString
    //     });
    // }
    private formatFormData(rawData: any) {
        console.info('detail rawdata rulePredicate', rawData.rulePredicate);
        console.info('detail rawdata rulePredicate', rawData.discountConfig);
        var discountConfig = rawData.discountConfig.split(',');
        switch (rawData.rulePredicate) {
            case 'fix':
                this.discountType = '0';
                break;
            case 'discount':
                this.discountType = '1';
                break;
            case 'post-discount':
                this.discountType = '3';
                break;
            case 'rand':
                this.discountType = '2';
                break;
             case 'single':
                this.discountType = '4';
                break;
        }
        var firstValue = discountConfig[0];
        if (discountConfig.length > 1) {
            var secValue = discountConfig[1];
        }

        return {
            ...rawData,
            firstValue,
            secValue,
           
        };
    }
    // public handleBack() {
    //     this.activeTab = "1";
    // }
}

export default DetailForm;
