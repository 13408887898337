import {request} from '@/utils';

export async function save(params: any) {

    try {
        return await request({
            method: 'POST',
            api: '/console/store/update',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function detail(id: string) {
    try {
        return await request({
            api: '/console/store/info',
            data: {store_id: id}
        });
    } catch (e) {
        const error: any = new Error('获取商户信息失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
