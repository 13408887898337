import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns
}, {
    state: {
        sortFields: preferences.sortFields
    }
});
