import {deleteEmpty} from '@/utils';
import qs from 'querystringify';

export interface Options {
    columns?: Array<{
        dataIndex: string,
        fixed?: string,
        filterable?: false,
    }>;
    beforeSearch?: any;
    download?: (query: any) => void;

    search(query: any, page: number, pageSize: number): Promise<{ list: any[], total: number }> | never;
}

interface State {
    query: any;
    list: any[];
    total: number;
    page: number;
    pageSize: number;
    fields: any;
    sortFields: any[];
    loading: boolean;
    error: Error | null;
    // 是否正在导出excel
    downloading: boolean;
    lastDownloadTime: string | null;
    downloadError: Error | null;
}

interface Mutations {
    [propName: string]: (state: State, payload: any) => void;
}

interface Actions {
    [propName: string]: (context: { dispatch: any; commit: any; state: State }, payload: any) => void;
}

interface Getters {
    [propName: string]: (state: State) => any;
}

function merge(src: any, target: any) {
    return {
        ...src,
        ...target,
        state: {
            ...src.state,
            ...target.state
        },
        mutations: {
            ...src.mutations,
            ...target.mutations
        },
        actions: {
            ...src.actions,
            ...target.actions
        },
        getters: {
            ...src.getters,
            ...target.getters
        }
    };
}

export function create(options: Options, custom: any = {}) {

    const defaultState: {
        namespaced: boolean;
        state: State;
        mutations: Mutations;
        actions: Actions;
        getters: Getters;
    } = {
        namespaced: true,
        state: {
            query: {},
            list: [],
            total: 0,
            page: 1,
            pageSize: 20,
            fields: options.columns
                && options.columns.reduce((fields, column) => ({...fields, [column.dataIndex]: true}), {}),
            sortFields: [],
            loading: false,
            error: null,
            // 是否正在导出excel
            downloading: false,
            lastDownloadTime: null,
            downloadError: null,
        },
        mutations: {
            setPage(state, page: number) {
                state.page = page;
            },
            setPageSize(state, size: number) {
                state.pageSize = size;
            },
            setLoading(state,loading:boolean){
                console.info("loading")
                state.loading = loading
            },

            setPagination(state, {page, pageSize}: any) {
                state.page = page || state.page;
                state.pageSize = pageSize || state.pageSize;
            },
            setQuery(state, query: any) {
                state.query = {...state.query, ...query};
            },
            resetQuery(state, query: any) {
                state.query = {...query};
            },
            clearQuery(state) {
                state.query = {};
            },
            clearList(state) {
                state.list = [];
                state.total = 0;
            },
            setFields(state, {key, checked}: any) {
                const newFields = {
                    ...state.fields
                };
                newFields[key] = checked;
                state.fields = newFields;
            },
            resetFields(state) {
                state.fields = options.columns && options.columns.reduce((fields, column) => ({...fields, [column.dataIndex]: true}), {})
            
            },
            sortFields(state, sortArr: any) {
                state.sortFields = sortArr;
            },
            requested(state) {
                state.loading = true;
                state.list = [];
                state.total = 0;
            },
            clear(state) {
                state.loading = false;
                state.list = [];
                state.total = 0;
            },
            successed(state, payload: any) {
                if (state.query.id !== payload.id) {
                    return;
                }
                state.list = payload.list;
                state.total = payload.total;
                state.page = payload.page;
                state.loading = false;
            },
            failed(state, payload: any) {
                if (state.query.id !== payload.id) {
                    return;
                }
                state.loading = false;
                state.error = payload.error;
            },
            downloadRequested(state) {
                state.downloading = true;
            },
            downloadSuccessed(state) {
                state.downloading = false;
                state.lastDownloadTime = new Date().toString();
            },
            downloadFailed(state, error: any) {
                state.downloading = false;
                state.downloadError = error;
            },
        },
        actions: {
            async initQuery(context, query: any) {
                const {
                    page,
                    page_size,
                    ...rest
                } = query;
                context.commit('resetQuery', rest);
                const pagination: any = {};
                if (page) {
                    pagination.page = parseInt(page, 10);
                }
                if (page_size) {
                    pagination.pageSize = parseInt(page_size, 10);
                }
                context.commit('setPagination', pagination);
                await context.dispatch('search');
            },
            async search(context) {
                if (options.beforeSearch) {
                    options.beforeSearch(context);
                }
                context.commit('requested');
                const id = context.state.query.id;
                const page = context.state.page;
                const pageSize = context.state.pageSize;
                try {
                    const ret = await options.search(deleteEmpty({
                        ...context.state.query,
                    }), page, pageSize);
                    context.commit('successed', {
                        id,
                        list: ret.list, total: ret.total, page
                    });
                } catch (e) {
                    context.commit('failed', {
                        id,
                        error: e
                    });
                }
            },
            async download(context) {
                if (!options.download) {
                    return;
                }
                context.commit('downloadRequested');
                try {
                    await options.download((deleteEmpty({
                        ...context.state.query
                    })));
                    context.commit('downloadSuccessed');
                } catch (e) {
                    context.commit('downloadFailed', e);
                }
            },
            pageChange(context, page: number) {
                context.commit('setPagination', {page});
                context.dispatch('search');
            },
            pageSizeChange(context, size: number) {
                context.commit('setPagination', {pageSize: size, page: 1});
                context.dispatch('search');
            },
            queryChange(context, query: any) {
                context.commit('setQuery', query);
                context.dispatch('pageChange', 1);
            },
            resetQuery(context, query: any) {
                context.commit('resetQuery', query);
                context.dispatch('pageChange', 1);
            }
        },
        getters: {
            queryString: (state) => () => {
                const q = {
                    ...state.query,
                    page: state.page,
                    page_size: state.pageSize,
                    r: Math.random(),
                };
                return qs.stringify(deleteEmpty(q), true);
            }
        },
    };

    return merge(defaultState, custom);
}
