import { request_yx } from '@/utils';

export async function search(
    params: any
): Promise<{ list: any[]; total: number }> | never {
    try {
        return await request_yx({
            api: '/console/activity/merGroup/queryChangeLog',
            method: 'POST',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取日志列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
