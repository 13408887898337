import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import store from '@/store';

store.registerModule(
    'districtCodeSearchPage',
    create(
        {
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    pageIndex: page,
                    pageSize: pageSize
                });
            },

            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRows', []);
            }
        },
        {
            state: {
                updatingStatus: [],
                openingStatus: [],
                joiningStatus: [],
                updateStatusError: null,
                openStatusError: null,
                joinStatusError: null,
                selectedRows: [],
                data: null,
            },
            mutations: {
                setSelectedRows(state: any, rows: any) {
                    state.selectedRows = rows;
                },
                initDataRequested(state: any) {
                    state.loading = true;
                    state.data = null;
                    state.updated = null;
                },
                initDataSuccessed(state: any, data: any) {
                    state.loading = false;
                    state.data = data;
                },
                initDataFailed(state: any, error: any) {
                    state.loading = false;
                    state.error = error;
                },
        
                updateRequested(state: any) {
                    state.loading = true;
                },
                updateSuccessed(state: any, data: any) {
                    state.updated = new Date();
                    state.loading = false;
                },
                updateFailed(state: any, error: any) {
                    state.loading = false;
                    state.error = error;
                },
            },
            actions: {
                
                async initData(context: any, id: any) {
                    context.commit('initDataRequested');
                    try {
                        const data = await services.getInfo(id);
                        context.commit('initDataSuccessed', data);
                    } catch (e) {
                        context.commit('initDataFailed', e);
                    }
                },
        
                async edit(context: any, payload: any) {
                    context.commit('updateRequested');
                    try {
                        const data = await services.update(payload);
                        context.commit('updateSuccessed', data);
                    } catch (e) {
                        context.commit('updateFailed', e);
                    }
                }
            }
        }
    )
);
