var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _c(
        "a-row",
        {
          staticStyle: {
            "margin-left": "-8px",
            "margin-right": "0px",
            "margin-top": "6px",
          },
        },
        [_vm._t("result")],
        2
      ),
      _c(
        "a-row",
        { staticStyle: { margin: "13px -10px" } },
        [
          _c(
            "a-col",
            {
              staticClass: "search-common-layout-actions",
              attrs: { span: "22" },
            },
            [_vm._t("actions")],
            2
          ),
          _c(
            "a-col",
            {
              staticClass: "search-common-layout-fields-filter-btn",
              attrs: { span: "2" },
            },
            [_vm._t("fields-filter-btn")],
            2
          ),
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: {
            "margin-left": "-24px",
            "margin-right": "-24px",
            "margin-bottom": "-24px",
          },
        },
        [_vm._t("table")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }