import {downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'other',
        api: '/console/marketing/data/hami/excel'
    });
}
