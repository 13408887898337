import {RouteConfig} from 'vue-router';
import Search from './SearchPage/AuthWrapper.vue';
import Edit from './EditPage/AuthWrapper.vue';
import Create from './CreatePage/AuthWrapper.vue';
// import Enter from './EnterPage/index.vue';
// import Export from './ExportPage/index.vue';


const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'create',
        name: '新增商场',
        component: Create,
    },
    {
        path: ':id',
        name: '编辑商场',
        component: Edit,
    },
    // {
    //   path: ':id/enter/:page',
    //   name: '入驻列表',
    //   component: Enter,
    // },
    // {
    //   path: 'export/:page',
    //   name: '已导出数据',
    //   component: Export,
    // },
];

export default route;
