var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CreateModal", {
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _c("a-button", { attrs: { disabled: _vm.loading } }, [
                  _vm._v("添加白名单"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SHOP_WHITE_LIST.DELETE] },
        on: { pass: _vm.handleDeleteClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("\n                从白名单移除\n            ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }