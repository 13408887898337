import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import store from '@/store';
store.registerModule(
    'shopWhiteListSearchPage',
    create(
        {
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    page,
                    pageSize
                });
            },
            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRowKeys', []);
            }
        },
        {
            state: {
                deleteStatus: [],
                openingStatus: [],
                deleteError: null,
                selectedRowKeys: [],
                sortFields: [],
            },
            mutations: {
                setSelectedRowKeys(state: any, selectedRowKeys: any[]) {
                    state.selectedRowKeys = selectedRowKeys;
                },
                deleteRequested(state: any, id: any) {
                    state.deleteStatus = [...state.deleteStatus, id];
                },
                deleteFailed(state: any, error: any) {
                    state.deleteError = error;
                },

                deleteFinally(state: any, id: any) {
                    const idx = state.deleteStatus.indexOf(id);
                    if (idx >= 0) {
                        state.deleteStatus.splice(idx, 1);
                        state.deleteStatus = [...state.deleteStatus];
                    }
                },
                sortFields(state: any, sortArr: any) {
                    state.sortFields = sortArr;
                },
                setFields(state: any, {key, checked}: any) {
                    state.fields = {
                        ...state.fields,
                        [key]: checked,
                    };
                }
            },
            actions: {
                async deleteMembers(context: any, { ids, status }: any) {
                    context.commit('deleteRequested', ids);
                    try {
                        await services.deleteMembers({
                            ids,
                        });
                        // context.commit('updateOneStatus', { ids, status });
                        // 状态更新成功后，重新获取列表
                        context.dispatch('search');
                    } catch (e) {
                        context.commit('deleteFailed', e);
                    } finally {
                        context.commit('deleteFinally', ids);
                    }
                },
            }
        }
    )
);
