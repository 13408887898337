var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Upload",
        [
          _c(
            "template",
            { slot: "trigger" },
            [
              _c("a-button", { attrs: { disabled: _vm.loading } }, [
                _vm._v("上传"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }