
    import {Component, Prop, Vue, Inject, Provide} from 'vue-property-decorator';
    import Drawer from '@/components/Drawer.vue';
    import SearchTable from './SearchTable.vue';
    import SearchForm from './SearchForm.vue';
    import store from './store';
    import {getValueByPath} from '@/utils';
    import {Show, check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Drawer,
            SearchTable,
            SearchForm,
            Show
        }
    })
    class Store extends Vue {
        @Prop()
        // tslint:disable-next-line:variable-name
        public district_id!: string;

        @Prop()
        public title!: string | number;

        @Inject()
        public privileges!: any;

        public PRIVILEGE = PRIVILEGE;

        @Provide()
        public state() {
            return getValueByPath(this.$store.state, [
                'bizDistrictSearchPage',
                this.district_id + '@store'
            ]);
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'bizDistrictSearchPage/' + this.district_id + '@store/' + action,
                payload
            );
        }

        @Provide()
        public commit(action: string, payload?: any) {
            this.$store.commit(
                'bizDistrictSearchPage/' + this.district_id + '@store/' + action,
                payload
            );
        }

        public created() {
            if (!this.state()) {
                this.$store.registerModule(
                    ['bizDistrictSearchPage', this.district_id + '@store'],
                    store()
                );
                this.commit('setQuery', {district_id: this.district_id});
                this.$watch(function() {
                    return this.$store.state.bizDistrictSearchPage[this.district_id + '@store']
                        && this.$store.state.bizDistrictSearchPage[this.district_id + '@store'].error;
                }, this.errorListener);

            }
        }

        // destroyed() {
        //     this.$store.unregisterModule([
        //         'bizDistrictSearchPage',
        //         this.district_id + '@store'
        //     ]);
        // }

        public errorListener(error: any) {
            if (error) {
                this.$message.error(error.message);
            }
        }

        public handleDrawerOpen() {
            if (check([this.PRIVILEGE.BIZ_DISTRICT.STORE], this.privileges).length === 0) {
                this.dispatch('resetQuery', {district_id: this.district_id});
            }
            // if (this.state().list.length === 0) {
            //     this.dispatch('search');
            // }
        }
    }

    export default Store;
