export default {
    grid: {
        left: '5%',
        right: '5%',
        top: 115,
        width: '90%'
    },
    legend: {
        data: [
            '汇总订单金额',
            '汇总实收金额',
            '汇总收款笔数',
            '汇总退款笔数',
            '支付宝小程序实收',
            '支付宝小程序笔数',
            '支付宝小程序退款笔数',
            '支付宝H5实收',
            '支付宝H5笔数',
            '支付宝H5退款笔数',
            '微信小程序实收',
            '微信小程序笔数',
            '微信小程序退款笔数',
            '微信H5实收',
            '微信H5笔数',
            '微信H5退款笔数',
            '云闪付H5实收',
            '云闪付H5笔数',
            '云闪付H5退款笔数',
            '华为钱包H5实收',
            '华为钱包H5笔数',
            '华为钱包H5退款笔数'
        ],
        // type: 'scroll',
        width: '68%',
        left: 10,
        itemWidth: 10,
        itemHeight: 10,
        selectedMode: 'single'
    },
    // toolbox: {
    //     top: 5,
    //     right: 10,
    //     feature: {
    //         saveAsImage: {
    //             name: '金额笔数统计',
    //             type: 'jpeg',
    //             title: '导出图片',
    //             pixelRatio: 2,
    //             excludeComponents: ['toolbox', 'dataZoom']
    //         }
    //     }
    // },
    // dataZoom: {
    //     type: 'slider',
    //     filterMode: 'filter'
    // },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            axis: 'x',
            snap: true
        },
        backgroundColor: 'rgba(245, 245, 245, 0.8)',
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        textStyle: {
            color: '#000'
        },
        extraCssText:  'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' 
    },
    xAxis: {
        type: 'category',
        axisTick: {
            alignWithLabel: true
        },
        axisLabel: {
            rotate: 45
        },
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: '汇总订单金额',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '汇总实收金额',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '汇总收款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '汇总退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '支付宝小程序实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '支付宝小程序笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '支付宝小程序退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
                {
            name: '支付宝H5实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '支付宝H5笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '支付宝H5退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
         {
            name: '微信小程序实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '微信小程序笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '微信小程序退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '微信H5实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '微信H5笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '微信H5退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '云闪付H5实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '云闪付H5笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '云闪付H5退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },

        {
            name: '华为钱包H5实收',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '华为钱包H5笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '华为钱包H5退款笔数',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        }
    ],
    animationEasing: 'elasticOut',
    animationDelayUpdate(idx: number) {
        return idx * 5;
    }
};
