
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import preferences from './preferences';
    import * as DICTS from '@/common/dicts';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        mall_id: '商场ID',
        mall_name: '商场名称',
        mall_belong: {
            name: '商场归属',
            values: DICTS.SERVICE_PLATFORM
        },
        province: '省',
        city: '市',
        district: '区',
        address: '地址关键字'
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            FieldsFilter,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.mallSearchPage.fields,
                sortArr: (state: any) => state.mallSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.mallSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class MallPage extends Vue {
        public data() {
            return {
                columns
            };
        }

        public created() {
            // this.$store.dispatch('mallSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.mallSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener() {
            this.$store.dispatch('mallSearchPage/initQuery', this.$route.query);
            // const {
            //     page,
            //     page_size,
            //     ...rest
            // }: any =  this.$route.query;

            // this.$store.commit('mallSearchPage/setPagination', {
            //     page: (page && parseInt(page, 10)) || 1,
            //     pageSize: (page_size && parseInt(page_size, 10)) || 20
            // });

            // this.$store.commit(
            //     'mallSearchPage/resetQuery',
            //     rest
            // );
            // this.$store.dispatch('mallSearchPage/search');
        }

        private handleFieldChange(e: any) {
            this.$store.commit('mallSearchPage/setFields', e);
        }

        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('mallSearchPage/sortFields', e);
        }
    }
