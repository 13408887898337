
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import DatePicker from '@/components/custom-form-components/DatePicker.vue';
    import Owner from '@/components/custom-form-components/Owner.vue';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import formRules from '@/common/formRules';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        activityId: string;
        merNo: string;
        status: string,
        startTime: string;
        endTime: string;
        referral:string;
        templetId:string;
        ownerId:string;
    }

    export interface FormData {
        activityId: string;
        merNo: string;
        status: string,
        referral:string,
        templetId:string,
        ownerId:string,
        created_time: any[]
    }

    @Component({
        components: {
            DatePicker,
            Owner
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.actShenheSearchPage.query);
                },
                loading: (state: any) => state.actShenheSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this),
                formRules
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/actShenhe/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            // this.form.resetFields();
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                merNo: values.merNo,
                activityId: values.activityId?values.activityId.trim():'',
                status: values.status,
                ownerId:values.ownerId,
                templetId:values.templetId?values.templetId.trim():'',
                referral:values.referral,
                startTime: values.created_time &&
                    values.created_time[0] &&
                    values.created_time[0].format('YYYYMMDDHHmmss'),
                endTime: values.created_time &&
                    values.created_time[1] &&
                    values.created_time[1].format('YYYYMMDDHHmmss')
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                merNo: query.merNo,
                ownerId:query.ownerId,
                activityId: query.activityId,
                status: query.status,
                templetId:query.templetId,
                referral:query.referral,
                created_time: [
                    query.startTime && moment(query.startTime),
                    query.endTime && moment(query.endTime)
                ]
            };
        }
    }

    export default SearchForm;
