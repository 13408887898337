import * as commonServices from '@/common/services';

export default {
    namespaced: true,
    state: {
        auditStatus: null,
        auditing: false,
        error: null
    },
    mutations: {
        setAuditing(state: any, auditing: boolean) {
            state.auditing = auditing;
        },

        setAuditStatus(state: any, status: any) {
            state.auditStatus = status;
        },

        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        /**
         * 审核图片
         */
        async checkPicture(context: any, url: string) {
            context.commit('setAuditing', true);

            try {
                const ret = await commonServices.checkPicture(url);
                context.commit('setAuditStatus', ret);
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setAuditing', false);
            }
        },
    },

};
