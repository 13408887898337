
    import {mapState} from 'vuex';
    import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
    import {check, Show, Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {Modal} from 'ant-design-vue';
    import config from '@/config';
    import Upload from './DistrictCodeUpload.vue';

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click,
            Upload
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.districtCodeSearchPage.loading,
                downloading: (state: any) => state.districtCodeSearchPage.downloading,
                selectedRows: (state: any) => state.districtCodeSearchPage.selectedRows,
                token: (state: any) => {
                    return state.login.session && state.login.session.token;
                },
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public dispatch!: any;

        public merchants: string[] = [];

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE,
                config,
                merchants:[],
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
            this.$router.push({
                path: '/console/districtCode/search' + this.$store.getters['districtCodeSearchPage/queryString']()
            });
        }
        
    }

    export default Actions;
