var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.baseForm },
                  on: { submit: _vm.handleNext },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "主题活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "budgetActivity",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动Id不能为空",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'budgetActivity',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '活动Id不能为空',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { placeholder: "请输入主题活动ID" },
                      }),
                      _vm._v(" \n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动名称不能为空",
                                  },
                                  {
                                    max: 64,
                                    message: "长度超限，请检查后重新输入!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'name',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '活动名称不能为空',\n                                    },\n                                    {\n                                        max: 64,\n                                        message:\n                                            '长度超限，请检查后重新输入!',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        attrs: {
                          maxLength: "64",
                          placeholder: "请输入64个字以内",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动有效期" } },
                    [
                      _c("DisableDatePicker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "range_valiable_time",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "开始时间或结束时间不能为空",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'range_valiable_time',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message:\n                                            '开始时间或结束时间不能为空',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "撤销后券状态" } },
                    [
                      _vm._v("\n                        "),
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "refundedStatus",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择必填项",
                                    },
                                  ],
                                  initialValue: _vm.refundedStatus,
                                },
                              ],
                              expression:
                                "[\n                            'refundedStatus',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请选择必填项',\n                                    },\n                                ],\n                                initialValue: refundedStatus,\n                            },\n                        ]",
                            },
                          ],
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v(" 生效 "),
                          ]),
                          _c(
                            "a-radio",
                            { attrs: { value: "2", checked: true } },
                            [_vm._v(" 失效 ")]
                          ),
                          _c("a-radio", { attrs: { value: "3" } }, [
                            _vm._v(" 已删除 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("下一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginLeft: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "优惠规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.discountForm },
                  on: { submit: _vm.handleNext2 },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "订单最小金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orderMinAmt",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入订单最小金额",
                                  },
                                  {
                                    pattern:
                                      /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                                    message: "只能输入大于0的数字，精确到分",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'orderMinAmt',\n                            { \n                                rules: [ \n                                    { required: true, message: '请输入订单最小金额' },\n                                    { pattern: /^(([1-9][0-9]*)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/, message: '只能输入大于0的数字，精确到分' },\n                                ] \n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { placeholder: "请输入订单最小金额" },
                      }),
                      _vm._v(" 元\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "优惠类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "promoType",
                                {
                                  initialValue: _vm.promoType,
                                },
                              ],
                              expression:
                                "[\n                            'promoType',\n                            {\n                                initialValue: promoType,\n                            },\n                        ]",
                            },
                          ],
                          staticStyle: { "margin-top": "10px" },
                          on: { change: _vm.changeDiscountType },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "voucher" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "76.5px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                减\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "promoAmount",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType == "voucher",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字且不能为0",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'promoAmount',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        promoType ==\n                                                        'voucher',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message:\n                                                        '只能填数字且不能为0',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "", maxLength: "9" },
                                    on: { change: _vm.promoAmountChange },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "discount" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立折\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "78px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                打\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          "\n                                        请输入0-100之间的整数，不包括0和100\n                                    "
                                        ),
                                      ]),
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "ratio",
                                              {
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.promoType ==
                                                      "discount",
                                                    message: "打折费率不能为空",
                                                  },
                                                  {
                                                    pattern:
                                                      _vm.promoType ==
                                                        "discount" &&
                                                      /^([1-9]\d{0,1})$/,
                                                    message:
                                                      "请输入0-100之间的整数，不包括0和100",
                                                  },
                                                ],
                                              },
                                            ],
                                            expression:
                                              "[\n                                            'ratio',\n                                            {\n                                                rules: [\n                                                    {\n                                                        required: promoType =='discount',\n                                                        message: '打折费率不能为空',\n                                                    },\n                                                    {\n                                                        pattern: promoType == 'discount' && /^([1-9]\\d{0,1})$/,\n                                                        message:\n                                                            '请输入0-100之间的整数，不包括0和100',\n                                                    },\n                                                ],\n                                            },\n                                        ]",
                                          },
                                        ],
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          placeholder:
                                            "0-100之间的整数，不包括0和100",
                                          maxLength: "2",
                                          suffix: "%",
                                        },
                                      }),
                                      _vm._v(
                                        " 折\n                                "
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                封顶\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "ratioMaxAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType == "discount",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'ratioMaxAmt',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        promoType ==\n                                                        'discount',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "randomReduce" },
                            },
                            [
                              _vm._v(
                                "\n                            随机立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "36.5px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                金额下限\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "rangeMinAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType ==
                                                  "randomReduce",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'rangeMinAmt',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        promoType ==\n                                                        'randomReduce',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                金额上限\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "rangeMaxAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType ==
                                                  "randomReduce",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'rangeMaxAmt',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        promoType ==\n                                                        'randomReduce',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "是否结算" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["settleFlag", { initialValue: "1" }],
                              expression:
                                "['settleFlag', { initialValue: '1' }]",
                            },
                          ],
                          on: { change: _vm.showSettleFlag },
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: "1", checked: true } },
                            [_vm._v("是")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: "0", checked: true } },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSettleFlag == "1",
                          expression: "isSettleFlag == '1'",
                        },
                      ],
                      attrs: { label: "结算类型" },
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "settleType",
                                { initialValue: "settleRate" },
                              ],
                              expression:
                                "[\n                            'settleType',\n                            { initialValue: 'settleRate' },\n                        ]",
                            },
                          ],
                          staticStyle: { "margin-top": "10px" },
                          on: { change: _vm.selectSettleType },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSettleRate,
                                  expression: "showSettleRate",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "settleRate" },
                            },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _vm._v(
                                    "\n                                结算比：    \n                                "
                                  ),
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "settleRate",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.isSettleFlag == "1" &&
                                                  _vm.settleType ===
                                                    "settleRate",
                                                message: "结算比不能为空",
                                              },
                                              {
                                                pattern:
                                                  _vm.isSettleFlag == "1" &&
                                                  _vm.settleType ===
                                                    "settleRate" &&
                                                  /^([1-9]\d?|100)$/,
                                                message:
                                                  "结算比必须是1到100之间的整数（包含1和100）",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'settleRate',\n                                        {\n                                            rules: [\n                                                {\n                                                    required: isSettleFlag == '1' && settleType === 'settleRate',\n                                                    message: '结算比不能为空',\n                                                },\n                                                {\n                                                    pattern: isSettleFlag == '1' && settleType === 'settleRate' && /^([1-9]\\d?|100)$/,\n                                                    message: '结算比必须是1到100之间的整数（包含1和100）',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { with: "100px" },
                                    attrs: {
                                      suffix: "%",
                                      type: "tel",
                                      placeholder: "请输入1到100的整数",
                                      maxLength: "3",
                                      disabled:
                                        _vm.settleType === "settleAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSettleAmount,
                                  expression: "showSettleAmount",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "settleAmount" },
                            },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _vm._v(
                                    "\n                                结算金额：\n                                "
                                  ),
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "settleAmount",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.isSettleFlag == "1" &&
                                                  _vm.settleType ===
                                                    "settleAmount",
                                                message: "结算金额不能为空",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'settleAmount',\n                                        {\n                                            rules: [\n                                                {\n                                                    required: isSettleFlag == '1' && settleType === 'settleAmount',\n                                                    message: '结算金额不能为空',\n                                                }\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { with: "100px" },
                                    attrs: {
                                      disabled: _vm.settleType === "settleRate",
                                      suffix: "RMB",
                                      placeholder:
                                        "大于0且小于等于固定立减金额",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "参与次数" } },
                    [
                      _vm._v(
                        "\n                    活动期间每个卡号可参与\n                    "
                      ),
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "eachCanUse",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写必填项!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'eachCanUse',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请填写必填项!',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "15%" },
                        attrs: {
                          placeholder:
                            "请输入参与次数,优惠次数必须是≥1的整数/不能大于60",
                        },
                      }),
                      _vm._v(" 次活动\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "指定APP" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["app"],
                            expression: "['app']",
                          },
                        ],
                        staticStyle: { width: "95%", "text-align": "left" },
                        attrs: {
                          placeholder:
                            "请输入指定APP的ISSCODE，最短4位，最长12位，英文逗号分隔，最多可输入5个",
                          autosize: { minRows: 6, maxRows: 6 },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "指定卡bin" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "cardBin",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "请填写必填项!",
                                  },
                                  {
                                    pattern: /^.{0,5000}$/,
                                    message:
                                      "卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'cardBin',\n                            {\n                                rules: [\n                                    {\n                                        required: false,\n                                        message: '请填写必填项!',\n                                    },\n                                    {\n                                        pattern: /^.{0,5000}$/,\n                                        message:\n                                            '卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          placeholder:
                            "卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度",
                          autosize: { minRows: 6, maxRows: 6 },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("返回")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: {
                                click: function ($event) {
                                  _vm.activeTab = "1"
                                },
                              },
                            },
                            [_vm._v("上一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("下一步")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "商户规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.merchantForm },
                  on: { submit: _vm.handleNext3 },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动店铺", required: "" } },
                    [
                      _c("CreateModal", {
                        on: {
                          change: function ($event) {
                            return _vm.changeShopData($event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function (_) {
                              return [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("点击添加商户号和对应线下终端号"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "ShopUpload",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.changeShopData($event)
                            },
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "trigger" },
                            [
                              _c(
                                "a-button",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("批量上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.shopData.length > 0
                    ? _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("a-table", {
                            attrs: {
                              columns: _vm.columns,
                              dataSource: _vm.shopData,
                              size: "small",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "action",
                                  fn: function (text, record, index) {
                                    return _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: () =>
                                            _vm.deleteShopData(record),
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              895350068
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("返回")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: {
                                click: function ($event) {
                                  _vm.activeTab = "2"
                                },
                              },
                            },
                            [_vm._v("上一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }