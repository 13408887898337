var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.Q_WHITELIST.LIST] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "q_whitelist" },
              [
                _c("SearchCommonLayout", {
                  attrs: { humanReadableQuery: _vm.humanReadableQuery },
                  scopedSlots: _vm._u([
                    {
                      key: "search-form",
                      fn: function () {
                        return [_c("SearchForm")]
                      },
                      proxy: true,
                    },
                    {
                      key: "actions",
                      fn: function () {
                        return [_c("Actions")]
                      },
                      proxy: true,
                    },
                    {
                      key: "table",
                      fn: function () {
                        return [_c("SearchTable")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }