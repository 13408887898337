
    import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
    import Role from '@/components/custom-form-components/Role.vue';
    import * as services from './services';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import CreateModal from './CreateModal.vue';
    import ShopUpload from './ShopUpload.vue';

    @Component({
        components: {
           
        }
    })
    class RoleChangeModal extends Vue {

        @Prop()
        public userId!: any;
        @Prop()
        public userName!: any;

        @Inject()
        public dispatch!: any;

        public visible = false;
        public value = null;
        public loading = false;
        public PRIVILEGE = PRIVILEGE;

        public handleChange(value: any) {
            this.value = value;
        }

        public handleClose() {
            if (this.loading) {
                return;
            }
            this.value = null;
            this.visible = false;
        }

       
        public onClick() {
            this.visible = true;
        }
    }

    export default RoleChangeModal;
