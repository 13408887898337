
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import moment from 'moment';
    import {mapState} from 'vuex';
    import {deleteEmpty} from '@/utils';
    import {DatePicker} from '@/components/custom-form-components';
import { getBankOrgList } from './services';

    interface SearchForm {
        spinning: boolean;
        form: any;
        bankList: []
    }
    interface QueryParams {
        expenseAccount	:string
    }
    @Component({
        components: {
            DatePicker
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.policyActivitySearchPage.query);
                },
                loading: (state: any) => state.policyActivitySearchPage.loading,
                bankList: (state: any) => state.policyActivitySearchPage.bankList,
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                spinning: false,
                bankList: [],
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            this.invokeGetBankOrgList()
        }
        public invokeGetBankOrgList() {
            this.spinning = true
            getBankOrgList().then(res => {
                console.log('getBankOrgList', res)
                this.bankList = res.list
                this.$store.commit('policyActivitySearchPage/setBankList', res.list);
            }).finally(() => {
                this.spinning = false
            })
        }
        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.dispatch('initQuery', query);
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: any) {
             return {
                name: values.name,
                templetType: values.templetType,
                bankId: values.bankId,
                status:values.status,
                templetId: values.templetId
                // createStartTime: 
                //     values.createTime &&
                //     values.createTime[0] &&
                //     values.createTime[0].format('YYYY-MM-DD HH:mm:ss'),
                // createEndTime:
                //     values.createTime &&
                //     values.createTime[1] &&
                //     values.createTime[1].format('YYYY-MM-DD HH:mm:ss'),
                // createdBy: values.createdBy
            };
        }

        private formatFormData(query: any) {

            return {
                ...query,
                // createTime: [
                //     query.createStartTime && moment(query.createStartTime),
                //     query.createEndTime && moment(query.createEndTime)
                // ]
            };
        }
    }

    export default SearchForm;
