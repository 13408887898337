
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import preferences from './preferences';
    import columns from './columns';
    import * as DICTS from '@/common/dicts';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        district_id: '商圈ID',
        district_name: '商圈名称',
        district_belong: {
            name: '商圈归属',
            values: DICTS.BIZ_DISTRICT_BELONG
        }
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            FieldsFilter,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.bizDistrictSearchPage.fields,
                sortArr: (state: any) => state.bizDistrictSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.bizDistrictSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class BizDistrictPage extends Vue {
        public data() {
            return {
                columns
            };
        }

        public created() {
            // this.$store.dispatch('bizDistrictSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.bizDistrictSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener() {
            this.$store.dispatch('bizDistrictSearchPage/initQuery', this.$route.query);
            // const {
            //     page,
            //     page_size,
            //     ...rest
            // }: any =  this.$route.query;

            // this.$store.commit('bizDistrictSearchPage/setPagination', {
            //     page: (page && parseInt(page, 10)) || 1,
            //     pageSize: (page_size && parseInt(page_size, 10)) || 20
            // });
            // this.$store.commit(
            //     'bizDistrictSearchPage/resetQuery',
            //     rest
            // );
            // this.$store.dispatch('bizDistrictSearchPage/search');
        }

        private handleFieldChange(e: any) {
            this.$store.commit('bizDistrictSearchPage/setFields', e);
        }

        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('bizDistrictSearchPage/sortFields', e);
        }
    }
