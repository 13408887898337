
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { request_yx } from '@/utils';
import * as Constant from '@/common/constant';
@Component<AdStatusCount>({})
class AdStatusCount extends Vue {
    public open: any = 0;
    public draft: any = 0;
    public stop: any = 0;
    public loading = false;

    public async created() {
       this.fetch();
    }

    

    public async fetch() {
        this.loading = true;
        try {
            var platform = '1';
            if (this.$store.state.activityManage.payType != Constant.WX_TYPE) {
                platform = '3';
            }
            
            const data = await request_yx({
                api: '/console/activity/queryCount',
                data: {
                    platform
                }
            });

            this.open = data.openCount;
            this.draft = data.draftCount;
            this.stop = data.stopCount;
        } catch (e) {
            this.$message.error(e.message);
        } finally {
            this.loading = false;
        }
    }
    @Watch('$store.state.activityManage.payType')
    public payTypeChange(newVal: String, oldVal: String) {
        this.fetch()
    }
}
export default AdStatusCount;
