
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import SearchTable from '@/components/SearchTable.vue';
    import {mapState} from 'vuex';
    import columns from './columns';

    export const PayStatus: any = {
        WAIT_PAY: '待支付',
        PAYING: '支付中',
        PAID: '已付款',
        FAIL: '交易失败',
        ERROR: '交易错误',
        REFUND: '已退款',
        CLOSED: '交易关闭',
        REFUND_PROCESSING: '退款中',
        REFUND_FAIL: '退款失败'
    };

    @Component({
        components: {
            SearchTable
        },
        computed: {
            ...mapState({
                list() {
                    return this.state().list;
                },
                total() {
                    return this.state().total;
                },
                page() {
                    return this.state().page;
                },
                pageSize() {
                    return this.state().pageSize;
                },
                loading() {
                    return this.state().loading;
                }
            })
        }
    })
    class StoreSearchTable extends Vue {

        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;

        public data() {
            const genderMap = {
                0: '未知',
                1: '男',
                2: '女'
            };
            return {
                columns
                // columns: [
                //     {
                //         title: '会员名',
                //         dataIndex: 'customer.nick_name',
                //         fixed: 'left',
                //         width: 180,
                //         customRender: defaultCustomRender
                //     },
                //     {
                //         title: '会员卡号',
                //         dataIndex: 'customer.user_name',
                //         width: 180,
                //         customRender: defaultCustomRender
                //     },
                //     {
                //         title: '手机号',
                //         dataIndex: 'customer.mobile'
                //     },
                //     {
                //         title: '交易类型',
                //         dataIndex: 'pay_channel',
                //         customRender: (channel: string, record: any) => {
                //             const status = record.status === 'REFUND' ? '[退款]' : '';
                //             switch (channel) {
                //                 case 'ALIPAY': return status + '支付宝';
                //                 case 'WECHAT': return status + '微信支付';
                //                 case 'YUNSHAN': return status + '云闪付';
                //                 default: return `未知${channel}`;
                //             }
                //         }
                //     },
                //     {
                //         title: '订单金额(元)',
                //         dataIndex: 'total_amount',
                //         customRender: (totalAmount: any, record: any) => {
                //             return (record.status === 'REFUND' ? '-' : '') + priceCustomRender(totalAmount);
                //         }
                //     },
                //     {
                //         title: '进店红包(元)',
                //         dataIndex: 'coupon',
                //         key: 'coupon_NEWBIE',
                //         customRender: couponRenderFactory('NEWBIE')
                //     },
                //     {
                //         title: '拼手气红包(元)',
                //         dataIndex: 'coupon',
                //         key: 'coupon_LUCKY_PACKET',
                //         customRender: couponRenderFactory('LUCKY_PACKET')
                //     },
                //     {
                //         title: '定向发券(元)',
                //         dataIndex: 'coupon',
                //         key: 'coupon_ORIENTATION',
                //         customRender: couponRenderFactory('ORIENTATION')
                //     },
                //     {
                //         title: '商场券(元)',
                //         dataIndex: 'coupon',
                //         key: 'coupon_MALL',
                //         customRender: couponRenderFactory('MALL')
                //     },
                //     {
                //         title: '商圈券(元)',
                //         dataIndex: 'hami_discount',
                //         key: 'hami_discount',
                //         customRender: priceCustomRender
                //     },
                //     {
                //         title: '实收金额(元)',
                //         dataIndex: 'pay_amount',
                //         customRender: (payAmount: any, record: any) => {
                //             return (record.status === 'REFUND' ? '-' : '') + priceCustomRender(payAmount);
                //         }
                //     },
                //     {
                //         title: '订单号',
                //         dataIndex: 'show_id',
                //         customRender: defaultCustomRender
                //     },
                //     {
                //         title: '交易/退款时间',
                //         dataIndex: 'trade_time',
                //         customRender: (tradeTime: string, record: any) => {
                //             if (record.status === 'REFUND') {
                //                 return record.refund_time || '-';
                //             } else {
                //                 return tradeTime || '-';
                //             }
                //         }
                //     },
                //     {
                //         title: '交易状态',
                //         dataIndex: 'status',
                //         customRender: (value: string) => {
                //             return PayStatus[value];
                //         }
                //     }
                // ]
            };
        }

        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handlePageSizeChange(size: number) {
            this.dispatch('pageSizeChange', size);
        }
    }

    export default StoreSearchTable;
