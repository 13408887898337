
    import {Component, Watch,Prop, Vue} from 'vue-property-decorator';
    import {request_yx} from '@/utils';
    import { Modal } from 'ant-design-vue';

    @Component({
        components: {}
    })
    class Role extends Vue {

        @Prop()
        public value!: string;
        @Prop()
        public accountList!:any;
        public items = [];
        public flag = false;
        public loading = false;
        public tmpValue!: string;
        public policyData = [];

        public async created() {
            this.fetch();
        }
        
        @Watch('accountList')
        public async fetch() {
            try {
                this.items = [];
                this.loading = true;
                console.log(this.accountList)
                const list = this.accountList;
                this.items = list.map((i: any) => ({
                    expenseAccount: i.expenseAccount
                }));
                this.policyData = list
            } catch (e) {
            } finally {
                this.loading = false;
            }
        }

        public dropdownVisibleChange(open: number) {
            console.log('on dropdownVisibleChange:', open);
            if (open) {
                this.fetch();
            }
        }

        public data() {
            return {
                dataSource: []
            };
        }


        public onInputKeyDown(value:String){
            console.log(this.flag)
        }

        public handleChange(value: string) {
            const that = this
                that.changeVal(value, that);
        }

        public async changeVal(value: string, that: any) {
            that.$emit('change', value);
        }

        public filterOption(input: string, option: any) {
            return (
                option.componentOptions.children[0].text
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
            );
        }
    }

    export default Role;
