
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        type_name: {
            name: '类型',
            values: {
                mobile: '手机号',
                merchant_no: '商户号'
            }
        },
        type_value: '值',
        name: '创建人',
        start_time: {
            name: '创建时间',
            range: 'from'
        },
        end_time: {
            name: '创建时间',
            range: 'to'
        },
        remark: '备注关键字'
    };
    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.blacklistSearchPage.fields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.blacklistSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class BlacklistPage extends Vue {
        public storeName = 'blacklistSearchPage';

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/blacklist/search' + this.getters('queryString')()
            });
        }

        public data() {
            return {
                columns
            };
        }

        public created() {
            this.dispatch('initQuery', {
                ...this.$route.query
            });
        }

        @Watch('$store.state.blacklistSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            this.dispatch('initQuery', this.$route.query);
        }

        private handleFieldChange(e: any) {
            this.commit('setFields', e);
        }
    }
