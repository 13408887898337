import {request_yx} from '@/utils';
import { message } from 'ant-design-vue';

export async function search(params: {
    query: any;
}) {

    try {
        return await request_yx({
            api: '/console/activity/subsidyRuleNew/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        throw error;
    }
}

export async function create(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRuleNew/addRule',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        throw e;
    }
}
export async function editAct(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRuleNew/editRule',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        throw e;
    }
}

export async function getInfo(params: any) {
    try {
        
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRuleNew/getRule',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('获取信息失败, 请联系技术人员!');
        throw error;
    }
}

export async function updateStatus(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRuleNew/updateStatus',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },

        });
    } catch (e) {
        throw e;
    }
}
