
    import {Component, Prop, Vue} from 'vue-property-decorator';

    const ACTIVITY_MAP = {
        NEWBIE: '进店红包',
        SPREAD_PRIZE: '老客奖励',
        LUCKY_PACKET: '拼手气红包'
    };

    const ACTIVITY_TYPE_MAP = {
        NEWBIE: '满减券',
        SPREAD_PRIZE: '兑换券',
        LUCKY_PACKET: '满减券'
    };

    const ACTIVITY_STATUS = {
        NOT_START: '未开始',
        ACTIVE: '进行中',
        OVERDUE: '已结束'
    };
    const LABEL_MAP = {
        SPREAD_PRIZE: [
            {
                label: '今日发出',
                key: 'send_count_today',
                unit: '张'
            },
            {
                label: '总计发出',
                key: 'send_count_total',
                unit: '张'
            },
            {
                label: '今日核销',
                key: 'used_count_today',
                unit: '张'
            },
            {
                label: '总计核销',
                key: 'used_count_total',
                unit: '张'
            }
        ],
        NEWBIE: [
            {
                label: '今日领券',
                key: 'customer_count_today',
                unit: '人'
            },
            {
                label: '总计领券',
                key: 'customer_count_total',
                unit: '人'
            },
            {
                label: '今日用券流水',
                key: 'amount_today',
                unit: '元'
            },
            {
                label: '总计用券流水',
                key: 'amount_total',
                unit: '元'
            }
        ],
        LUCKY_PACKET: [
            {
                label: '今日领券',
                key: 'customer_count_today',
                unit: '人'
            },
            {
                label: '总计领券',
                key: 'customer_count_total',
                unit: '人'
            },
            {
                label: '今日用券流水',
                key: 'amount_today',
                unit: '元'
            },
            {
                label: '总计用券流水',
                key: 'amount_total',
                unit: '元'
            }
        ]
    };

    @Component({
        components: {},
        computed: {}
    })
    class ActivityItem extends Vue {
        @Prop()
        public item!: any;

        public data() {
            return {
                ACTIVITY_MAP,
                ACTIVITY_STATUS,
                LABEL_MAP,
                ACTIVITY_TYPE_MAP
            };
        }
    }

    export default ActivityItem;
