var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CreateModal", {
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function (_) {
              return [
                _c("a-button", { attrs: { disabled: _vm.loading } }, [
                  _vm._v("新增"),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }