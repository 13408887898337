import {request} from '@/utils';

export async function getPlanData(planId: string) {
    try {
        return await request({
            method: 'GET',
            api: '/console/ad/plan/detail',
            data: {
                plan_id: planId
            }
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function update(payload: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/ad/plan/update',
            data: payload
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
