import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-zhongxin-control-valid-type',
    options: [{
        label: '微信全场',
        value: 1
    }, {
        label: '微信代金券',
        value: 2
    }, {
        label: '银联U点',
        value: 3
    }, {
        label: '银联B2C',
        value: 4
    }, {
        label: '银联代金券',
        value: 5
    },{
        label: '支付宝全场',
        value: 6
    }, {
        label: '支付宝优惠券',
        value: 7
    }, {
        label: '商户优惠费率',
        value: 8
    }, {
        label: '绑卡优惠',
        value: 9
    }, {
        label: '申卡优惠',
        value: 10
    }, {
        label: '其他',
        value: 11
    }]
});
