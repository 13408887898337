
    import {Row, Col, Button} from 'ant-design-vue';
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import axios from 'axios';
    import config from '@/config';
    import {responseHandler} from '@/utils';

    interface UploadImage {
        cropaInstance: any;
        imageUrl: any;
        uploading: boolean;
        file: any;
        errorMsg: string;
    }

    @Component({
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Button.name]: Button
        },
        computed: {
            hasImage() {
                const self: any = this;
                return self.cropaInstance.hasImage && self.cropaInstance.hasImage();
            }
        }
    })
    class UploadImage extends Vue {

        @Prop()
        public value!: any;

        @Prop()
        public action!: string;

        @Prop()
        public headers!: any;

        public data() {
            return {
                cropaInstance: {},
                uploading: false,
                file: '',
                errorMsg: ''
            };
        }

        public handleFileChoose(file: any) {
            this.file = file;
        }

        public handleRemove() {
            this.cropaInstance.chooseFile();
        }

        public async handleUpload() {
            this.uploading = true;
            const blob = await this.cropaInstance.promisedBlob();

            if (!blob) {
                this.uploading = false;
            }
            const fd = new FormData();
            fd.append('files', blob, this.file.name);
            try {
                const result = responseHandler(await axios.post(this.action, fd, {
                    headers: {
                        ...this.headers
                    }
                }));
                this.$emit('change', result.url);
                this.errorMsg = '';
            } catch (e) {
                this.errorMsg = e.message;
            } finally {
                this.uploading = false;
            }
        }
    }

    export default UploadImage;
