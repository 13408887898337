
    import {Component, Prop, Vue, Provide, Inject} from 'vue-property-decorator';
    import Drawer from '@/components/Drawer.vue';
    import {mapState} from 'vuex';
    import columns from './columns';
    import {check, Show, Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import './store';
    import config from '@/config';

    @Component({
        components: {
            Drawer,
            Show,
            Click
        },
        computed: {
            ...mapState({
                list: (state: any) =>
                    state.bankActMerTask.list.map((item: any, idx: any) => ({
                        idx: (state.bankActMerTask.page - 1) * state.bankActMerTask.pageSize + idx + 1,
                        ...item
                    })),
                total: (state: any) => state.bankActMerTask.total,
                pageSize: (state: any) => state.bankActMerTask.pageSize,
                page: (state: any) => state.bankActMerTask.page,
                loading: (state: any) => state.bankActMerTask.loading,
                token: (state: any) => {
                    return state.login.session && state.login.session.token;
                },
                downloadingErrorExcel: (state: any) => state.bankActMerTask.downloadingErrorExcel,
                refreshing: (state: any) => state.bankActMerTask.refreshing
            })
        }
    })
    class Store extends Vue {
        @Prop()
        public uploadType?: string;
        @Prop()
        public operateType?:string;
        @Inject()
        public privileges!: any;
        public fileList: any = [];
        public PRIVILEGE = PRIVILEGE;
        private timer: any;
        private uploading = false;
        public data() {
            return {
                columns,
                config,
            };
        }
        public created() {
           
        }
        @Provide()
        public state() {
            return this.$store.state['bankActMerTask'];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'bankActMerTask/' + action,
                payload
            );
        }

        public handleRefreshClick(record: any) {
            this.dispatch('refresh', {taskId:record.taskId,type:this.uploadType,operateType:this.operateType,activityId:''});
        }

        public handleDownloadErrorClick(record: any) {
            this.dispatch('downloadErrorExcel', {taskId:record.taskId,type:this.uploadType,operateType:this.operateType,activityId:''});
        }

        public handleFileUpload({file, fileList, event}: any) {
            this.fileList = [{
                ...file,
                name: ({
                    uploading: '正在上传',
                    done: '上传完成',
                    error: '上传失败',
                    removed: ''
                } as any)[file.status]
            }];
            this.uploading = true;
            if (file && file.status === 'done' && file.response) {
                if (file.response.status.code !== 0) {
                    this.$message.error(file.response.status.desc);
                } else {
                    this.dispatch('search');
                }
            }

            if (file.status !== 'uploading') {
                this.uploading = false;
            }
        }

        public disabledUpload() {
            if('2' == this.operateType){
                if (check([this.PRIVILEGE.BANK_ACTIVITY_MER.BANCH_EDIT], this.privileges).length > 0) {
                    return true;
                }
            }else{
                if (check([this.PRIVILEGE.BANK_ACTIVITY_MER.BANCH_CREATE], this.privileges).length > 0) {
                    return true;
                }
            }
            return false;
        }

        public handleDownloadTemplateClick() {
            if('2' == this.operateType){
                window.open(process.env.BASE_URL + '批量修改活动商户模板.xlsx', '_blank');
            } else if (this.operateType == '3') {
                window.open(process.env.BASE_URL + '批量修改活动商户名单周期模板.xlsx', '_blank');
            } else {
                window.open(process.env.BASE_URL + '批量新增活动商户模板.xlsx', '_blank');
            }
        }


        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handleDrawerOpen() {
            console.log(this.uploadType)
            this.$store.state.bankActMerTask.list =[];
            this.$store.state.bankActMerTask.total=0;
            if('2' == this.operateType){
                if (check([this.PRIVILEGE.BANK_ACTIVITY_MER.BANCH_EDIT], this.privileges).length > 0) {
                    return;
                }else{
                    this.$store.dispatch('bankActMerTask/initQuery', {
                        type:this.uploadType,
                        operateType:this.operateType
                    }); 
                }
            }else{
                if (check([this.PRIVILEGE.BANK_ACTIVITY_MER.BANCH_CREATE], this.privileges).length > 0) {
                   
                    return;
                }else{
                    this.$store.dispatch('bankActMerTask/initQuery', {
                        type:this.uploadType,
                        operateType:this.operateType
                    }); 
                }
            }
             
        }
    }

    export default Store;
