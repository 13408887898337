var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.ROLE.PERMISSION] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          style: { top: "50px" },
          attrs: {
            maskClosable: false,
            visible: _vm.visible,
            confirmLoading: _vm.saving,
          },
          on: { cancel: _vm.handleClose, ok: _vm.handleOk },
        },
        [
          _c("div", { staticClass: "p25" }),
          _vm.treeData.length > 0
            ? _c("a-tree", {
                staticClass: "resetATree",
                attrs: {
                  checkable: "",
                  defaultExpandAll: "",
                  autoExpandParent: true,
                  treeData: _vm.treeData,
                },
                model: {
                  value: _vm.checkedKeys,
                  callback: function ($$v) {
                    _vm.checkedKeys = $$v
                  },
                  expression: "checkedKeys",
                },
              })
            : _c("a-icon", { attrs: { type: "loading" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }