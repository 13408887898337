
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import moment from 'moment';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import {DatePicker} from '@/components/custom-form-components';
    import {Unionb2cActivityStatus} from '@/components/custom-form-components';
    interface SearchForm {
        form: any;
    }
    interface QueryParams {
        status: string;
        name: string;
        orgId: string;
        startTime: string;
        endTime: string;
    }
    @Component({
        components: {
            DatePicker,
            Unionb2cActivityStatus

        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.unionb2cSearchPage.query);
                },
                loading: (state: any) => state.unionb2cSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.dispatch('initQuery', query);
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: any) {
            return {
                status: values.status,//运营中
                name: values.name,
                orgId: values.orgId,
                startTime: 
                    values.activityTime &&
                    values.activityTime[0] &&
                    values.activityTime[0].format('YYYY-MM-DD HH:mm:ss'),
                endTime:
                    values.activityTime &&
                    values.activityTime[1] &&
                    values.activityTime[1].format('YYYY-MM-DD HH:mm:ss')
            };
        }

        private formatFormData(query: any) {

            return {
                ...query,
                activityTime: [
                    query.startTime && moment(query.startTime),
                    query.endTime && moment(query.endTime)
                ]
            };
        }
    }

    export default SearchForm;
