
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import preferences from './preferences';
    import * as DICTS from '@/common/dicts';
    import {queryToHumanReadable} from '@/utils';

    function priceFormatter(value: any) {
        return (value / 100).toFixed(2);
    }

    const humanReadableQuerySchema = {
        store_name: '商户名称',
        merchant_no: 'LKL商户号',
        offline_terminal_id: '线下终端号',
        sn: '惠码SN号',
        pay_channel: {
            name: '交易类型',
            values: DICTS.PAY_CHANNEL
        },
        // pay_type: {
        //     name: '交易方式',
        //     callback(value: any, ret: any) {
        //         const item = ret.list.find((i: any) => i.id == value);
        //         if (item) {
        //             return item.name;
        //         }
        //     },
        //     options: {
        //         method: 'GET',
        //         api: '/console/order/pay_type/list'
        //     },
        //     getParams(value: any) {
        //         return {
        //             page: 1,
        //             page_size: 1000
        //         };
        //     }
        // },
        status: {
            name: '交易状态',
            values: DICTS.ORDER_STATUS
        },
        order_id: '订单ID',
        mallcoo_code: '商场券号',
        hami_coupon_no: '商圈券号',
        mall_name: {
            name: '归属商场',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/mall/simple_list'
            },
            getParams(value: any) {
                return {
                    page: 1,
                    page_size: 1000
                };
            }
        },
        district_name: {
            name: '归属商圈',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/district/simple_list'
            },
            getParams(value: any) {
                return {
                    page: 1,
                    page_size: 1000
                };
            }
        },
        nick_name: '会员名',
        user_name: '会员卡号',
        mobile: '手机号',
        comp_org_name: '机构名称',
        min_total_amount: {
            name: '订单金额(元)',
            range: 'from',
            formatter: priceFormatter
        },
        max_total_amount: {
            name: '订单金额(元)',
            range: 'to',
            formatter: priceFormatter
        },
        min_pay_amount: {
            name: '实收金额(元)',
            range: 'from',
            formatter: priceFormatter
        },
        max_pay_amount: {
            name: '实收金额(元)',
            range: 'to',
            formatter: priceFormatter
        },
        min_discount_amount: {
            name: '折扣金额(元)',
            range: 'from',
            formatter: priceFormatter
        },
        max_discount_amount: {
            name: '折扣金额(元)',
            range: 'to',
            formatter: priceFormatter
        },
        min_hami_discount: {
            name: '商圈折扣金额(元)',
            range: 'from',
            formatter: priceFormatter
        },
        max_hami_discount: {
            name: '商圈折扣金额(元)',
            range: 'to',
            formatter: priceFormatter
        },
        start_trade_time: {
            name: '交易时间',
            range: 'from'
        },
        end_trade_time: {
            name: '交易时间',
            range: 'to'
        },
        start_refund_time: {
            name: '退款时间',
            range: 'from'
        },
        end_refund_time: {
            name: '退款时间',
            range: 'to'
        },
        start_create_time: {
            name: '订单创建时间',
            range: 'from'
        },
        end_create_time: {
            name: '订单创建时间',
            range: 'to'
        },
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            FieldsFilter,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.orderSearchPage.fields,
                sortArr: (state: any) => state.orderSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.orderSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class OrderPage extends Vue {

        public fixed = false;

        public data() {
            return {
                columns
            };
        }

       

        public created() {
            // this.$store.dispatch('orderSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.orderSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener() {

            this.$store.dispatch('orderSearchPage/initQuery', this.$route.query);
            // const {
            //     page,
            //     page_size,
            //     ...rest
            // }: any =  this.$route.query;

            // this.$store.commit('orderSearchPage/setPagination', {
            //     page: (page && parseInt(page, 10)) || 1,
            //     pageSize: (page_size && parseInt(page_size, 10)) || 20
            // });
            // this.$store.commit(
            //     'orderSearchPage/resetQuery',
            //     rest
            // );

            // this.$store.dispatch('orderSearchPage/search');
        }
        public handleFixed() {
            this.fixed = true;
        }
        private handleFieldChange(e: any) {
            this.$store.commit('orderSearchPage/setFields', e);
        }

        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('orderSearchPage/sortFields', e);
        }
    }
