
import { Component, Inject, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { mapState } from 'vuex';
import { deleteEmpty } from '@/utils';
import qs from 'querystringify';
import { DatePicker } from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}
interface QueryParams {
    batchId: string;
    startTime: string;
    endTime: string;
    merchantId: string;
    terminalId: string;
}
@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.hexiaoUnionManageSearchPage.query);
            },
            loading: (state: any) => state.hexiaoUnionManageSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            console.info("deleteEmpty",query)
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach(key => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        var obj =  {
            unpayActivityId: values.batchId,
            merNo: values.merchantId,
            termNo: values.terminalId,
            startTime:
                values.queryTime &&
                values.queryTime[0] &&
                values.queryTime[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime:
                values.queryTime &&
                values.queryTime[1] &&
                values.queryTime[1].format('YYYY-MM-DD HH:mm:ss')
        };
        console.info("formatQuery",obj)
        return obj
    }

    private formatFormData(query: any) {
        return {
            ...query,
            queryTime: [
                query.startTime && moment(query.startTime),
                query.endTime && moment(query.endTime)
            ]
        };
    }
}

export default SearchForm;
