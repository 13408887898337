var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.store_id,
        width: 1024,
        placement: "right",
        closable: "",
      },
      on: { close: _vm.onClose },
    },
    [
      _c("Show", {
        attrs: { requires: [_vm.PRIVILEGE.STORE.ACTIVITY] },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _vm.state()
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-radio-group",
                          {
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: {
                              disabled: _vm.loading,
                              defaultValue: _vm.query.status,
                              buttonStyle: "solid",
                            },
                            on: { change: _vm.handleStatusChange },
                          },
                          [
                            _c(
                              "a-radio-button",
                              { attrs: { value: "NOT_START" } },
                              [_vm._v("待生效")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "ACTIVE" } },
                              [_vm._v("进行中")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "OVERDUE" } },
                              [_vm._v("已结束")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          { attrs: { gutter: 24 } },
                          [
                            _vm.loading
                              ? _c("a-icon", {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "loading" },
                                })
                              : _vm._l(_vm.list, function (item) {
                                  return _c("ActivityItem", {
                                    key: item.id,
                                    attrs: { item: item },
                                  })
                                }),
                          ],
                          2
                        ),
                        !_vm.loading && _vm.total === 0
                          ? _c("span", { staticStyle: { color: "#ccc" } }, [
                              _vm._v("无数据"),
                            ])
                          : !_vm.loading
                          ? _c("a-pagination", {
                              attrs: {
                                pageSize: 4,
                                size: "small",
                                total: _vm.total,
                                current: _vm.page,
                                showTotal: (total) => `总计 ${total} 条记录`,
                              },
                              on: { change: _vm.handlePageChange },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }