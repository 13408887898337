import {request, downloadRequest} from '@/utils';
import config from '@/config';
export async function list(params: any): Promise<{list: any, total: any}> {
    try {
        return await request({
            api: '/console/white/list/task/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取任务列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function refresh(id: any): Promise<{list: any, total: any}> {
    try {
        return await request({
            api: '/console/white/list/task/refresh',
            data: {task_id: id}
        });
    } catch (e) {
        const error: any = new Error('获取任务信息失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequest({
        data: params,
        prefix: 'task',
        api: '/console/white/list/task/excel'
    });
}

export async function downloadTemplateXls() {
    window.open(config.BASE_URL + '/static/whiteList.xlsx', '__blank');
}
