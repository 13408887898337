
    import {mapState} from 'vuex';
    import {Modal} from 'ant-design-vue';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import AdStatusCount from '@/components/AdStatusCount.vue';
    import WhitelistModal from './WhitelistModal.vue';
    @Component({
        components: {
            Click,
            AdStatusCount,
            WhitelistModal
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.advertisementSearchPage.loading,
                downloading: (state: any) => state.advertisementSearchPage.downloading,
                selectedRowKeys: (state: any) => state.advertisementSearchPage.selectedRowKeys || [],
                interrupting: (state: any) => state.advertisementSearchPage.interrupting
            })
        }
    })
    class Actions extends Vue {

        public hideMessage: any = null;

        public PRIVILEGE = PRIVILEGE;

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
            this.$router.push({
                path: '/console/advertisement/search' + this.$store.getters['advertisementSearchPage/queryString']()
            });
        }

        @Watch('$store.state.advertisementSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.advertisementSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.advertisementSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('advertisementSearchPage/download');
        }

        public handleInterruptClick() {
            Modal.confirm({
                title: '提示',
                content: '是否确认下架?',
                onOk: () => {
                    this.$store.dispatch('advertisementSearchPage/interrupt');
                }
            });
        }

        public handleDeleteClick() {
            Modal.confirm({
                title: '提示',
                content: '是否确认删除?',
                onOk: () => {
                    this.$store.dispatch('advertisementSearchPage/delete');
                }
            });
        }

        public handleAddAdPlan() {
            this.$router.push('/console/advertisement/create');
        }
    }

    export default Actions;
