
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import {queryToHumanReadable} from '@/utils';
    import preferences from './preferences';
    import PRIVILEGE from '@/common/privileges';
    import {Show} from '@/components/Auth';
     const humanReadableQuerySchema = {
        expenseAccount: '费用账户',
        remark: '备注'
    };
    @Component({
        components: {
            SearchForm,
            Actions,
            SearchTable,
            SearchCommonLayout,
            FieldsFilter,
            Show
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.merSubsidyRuleSearchPage.fields,
                sortArr: (state: any) => state.merSubsidyRuleSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.merSubsidyRuleSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class RolePage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'merSubsidyRuleSearchPage';
         public PRIVILEGE = PRIVILEGE;
        public data() {
            return {
                columns
            };
        }

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path:
                    '/console/bankRule/merSubsidyRule/search' + this.getters('queryString')()
            });
        }

        public created() {
            this.$store.dispatch('merSubsidyRuleSearchPage/initQuery', {
                ...this.$route.query
            });
        }

        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }



        private handleFieldChange(e: any) {
            this.$store.commit('merSubsidyRuleSearchPage/setFields', e);
        }
        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('merSubsidyRuleSearchPage/sortFields', e);
        }
    }
