
    import {mapState} from 'vuex';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    interface Actions {
        hideMessage: any;
    }

    @Component({
        components: {
            Click
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.bizDistrictSearchPage.loading,
                downloading: (state: any) => state.bizDistrictSearchPage.downloading
            })
        }
    })
    class Actions extends Vue {

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
            this.$router.push({
                path: '/console/bizDistrict/search' + this.$store.getters['bizDistrictSearchPage/queryString']()
            });
        }

        @Watch('$store.state.bizDistrictSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.bizDistrictSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.bizDistrictSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('bizDistrictSearchPage/download');
        }
    }

    export default Actions;
