
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
    import DatePicker from '@/components/custom-form-components/DatePicker.vue';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import formRules from '@/common/formRules';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        merchantNo: string;
        terminalId: string;
        startDate: string;
        endDate: string;
        upgrade: boolean;
    }

    export interface FormData {
        merchantNo: string;
        terminalId: string;
        createdTime: any[];
        upgrade:boolean;
    }

    @Component({
        components: {
            DistrictBelong,
            DatePicker
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.graySearchPage.query);
                },
                loading: (state: any) => state.graySearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this),
                formRules
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/gray/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            // this.form.resetFields();
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                merchantNo: values.merchantNo,
                terminalId: values.terminalId,
                startDate: values.createdTime &&
                    values.createdTime[0] &&
                    values.createdTime[0].format('YYYY-MM-DD HH:mm:ss'),
                endDate: values.createdTime &&
                    values.createdTime[1] &&
                    values.createdTime[1].format('YYYY-MM-DD HH:mm:ss'),
                upgrade: values.upgrade
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                merchantNo: query.merchantNo,
                terminalId: query.terminalId,
                upgrade: query.upgrade,
                createdTime: [
                    query.startDate && moment(query.startDate),
                    query.endDate && moment(query.endDate)
                ]
            };
        }
    }

    export default SearchForm;
