var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Drawer",
    {
      attrs: { title: "修改日志", width: 900 },
      on: { open: _vm.handleDrawerOpen },
    },
    [
      _vm._t(
        "trigger",
        function () {
          return [
            _c("a-button", { attrs: { size: "small" } }, [_vm._v("click")]),
          ]
        },
        { slot: "trigger" }
      ),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [_c("SearchTable")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }