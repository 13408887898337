var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.auditUploadType } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("导入审核名单")])],
            1
          ),
        ],
        2
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.applyUploadType } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("导入报名名单")])],
            1
          ),
        ],
        2
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.updateApplyUploadType } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("批量修改报名信息")])],
            1
          ),
        ],
        2
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.BANK_SIGNUP_AUTIO.DOWNLOAD] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (slotProps) {
              return [
                _c("a-button", { attrs: { disabled: _vm.downloading } }, [
                  _vm._v("导出Excel表\n            "),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }