import {request, requestStream} from '@/utils';

export async function getConflictDefaultAdPlan(params: {plan_id?: number; position: string; terminals: string[]}) {
    try {
        return await request({
            method: 'GET',
            api: '/console/ad/plan/conflict_default/list',
            data: {
                ...params,
                terminals: params.terminals.join(',')
            }
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
