import {create} from '@/common/storeFactory';
import * as services from './services';

export default () => create({
    search(query: any, page, pageSize) {
        return services.search({
            ...query,
            pageIndex: page,
            pageSize: pageSize
        });
    }
});
