import * as services from './services';
import { CreateParams } from './services';

export default {
    namespaced: true,
    state: {
        loading: false,
        created: null,
        error: null
    },
    mutations: {
        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
        created(state: any) {
            state.created = Date.now();
        }
    },
    actions: {
        /**
         * 新增商场
         * @param context action上下文
         */
        async create(context: any, payload: CreateParams) {
            context.commit('setLoading', true);
            try {
                const ret = await services.create({
                    ...payload
                });
                context.commit('created');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        }
    },
    getters: {

    }
};
