import {create} from '@/common/storeFactory';
import * as services from './services';
import store from '@/store';

store.registerModule('sharedQuotaMerTask', create({
    
    search(query: any, page, pageSize) {
        return services.list(query);
    }
}, {
    state: {
        downloadingErrorExcel: [],
        refreshing: []
    },
    mutations: {
        updateOne(state: any, payload: any) {
            const list = [...state.list];
            let newData = payload.list[0];
            const index = list.findIndex((item) => item.taskId === newData.taskId);
            if (index >= 0) {
                list[index] = newData;
            }
            state.list = list;
        },
        downloadErrorExcelRequested(state: any, taskId: any) {
            state.downloadingErrorExcel = [...state.downloadingErrorExcel, taskId];
        },

        downloadErrorExcelFinally(state: any, taskId: any) {
            const idx = state.downloadingErrorExcel.indexOf(taskId);
            if (idx >= 0) {
                state.downloadingErrorExcel.splice(idx, 1);
                state.downloadingErrorExcel = [...state.downloadingErrorExcel];
            }
        },

        refreshRequested(state: any, taskId: any) {
            state.refreshing = [...state.refreshing, taskId];
        },

        refreshFinally(state: any, taskId: any) {
            const idx = state.refreshing.indexOf(taskId);
            if (idx >= 0) {
                state.refreshing.splice(idx, 1);
                state.refreshing = [...state.refreshing];
            }
        }
    },
    actions: {
        async refresh(context: any, params: any) {
            context.commit('refreshRequested', params);
            try {
                const newData = await services.refresh(params);
                context.commit('updateOne', newData);
            } finally {
                context.commit('refreshFinally', params);
            }
        },

        async downloadErrorExcel(context: any, params: any) {
            context.commit('downloadErrorExcelRequested', params);
            try {
                await services.downloadXls(params);
            } finally {
                context.commit('downloadErrorExcelFinally', params);
            }
        },
    }
}));
