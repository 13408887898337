
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import moment from 'moment';
    import {mapState} from 'vuex';
    import {deleteEmpty} from '@/utils';
    import {DatePicker} from '@/components/custom-form-components';
    import Owner from '@/components/custom-form-components/Owner.vue';
    interface SearchForm {
        form: any;
    }
    interface QueryParams {
        expenseAccount	:string
    }
    @Component({
        components: {
            DatePicker,
            Owner
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.merSubsidyRuleSearchPage.query);
                },
                loading: (state: any) => state.merSubsidyRuleSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.dispatch('initQuery', query);
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: any) {
             return {
                name: values.name,
                ownerId:values.ownerId,
                ruleId:values.ruleId,
                activityId:values.activityId,
                templetId:values.templetId,
                status:values.status,
                startTime: 
                    values.ruleTime &&
                    values.ruleTime[0] &&
                    values.ruleTime[0].format('YYYYMMDDHHmmss'),
                endTime:
                    values.ruleTime &&
                    values.ruleTime[1] &&
                    values.ruleTime[1].format('YYYYMMDDHHmmss')
            };
        }

        private formatFormData(query: any) {
            return {
                ...query,
                ruleTime: [
                    query.startTime && moment(query.startTime),
                    query.endTime && moment(query.endTime)
                ]
            };
        }
    }

    export default SearchForm;
