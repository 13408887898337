import blankCell from '@/common/blankCell';
import {
    defaultCustomRender,priceCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 90
    },
    {
        title: '短信包条数',
        dataIndex: 'count',
        align: 'center',
        fixed: 'left',
        width: 130
    },
    {
        title: '价格',
        dataIndex: 'price',
        align: 'center',
        fixed: 'left',
        sorter: true,
        width: 110,
        customRender: (amount: any) => {
            return priceCustomRender(amount);
        }  
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        fixed: 'left',
        width: 110
    },
    {
        title: '创建时间',
        dataIndex: 'created_time',
        align: 'center',
        fixed: 'left',
        sorter: true,
        width: 220,
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'first_name',
        align: 'center',
        fixed: 'left',
        width: 210
    },
     
];
