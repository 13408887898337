
    import {Component, Prop, Vue, Watch, Inject} from 'vue-property-decorator';

    interface Anchor {
        startX: number;
        startY: number;
    }

    @Component<Anchor>({
        components: {},
        computed: {
            style() {
                return {
                    width: '10px',
                    height: '10px',
                    position: 'absolute',
                    backgroundColor: 'rgba(0,0,0,.2)',
                    border: '1px solid hsla(0,0%,100%,.7)',
                    ...this.pos
                };
            }
        }
    })
    class Anchor extends Vue {

        @Prop()
        public mouse!: any;
        @Prop()
        public origin!: any;
        @Prop()
        public pos!: any;
        @Prop()
        public cursor!: any;

        @Prop()
        public disabled!: any;

        @Inject()
        public setActive!: any;
        @Inject()
        public getActive!: any;

        public startDistance = 0;

        public handleMouseDown(e: MouseEvent) {
            if (this.disabled) {
                return;
            }
            this.setActive(this.$refs.el);
            window.document.body.style.cursor = this.cursor;
            this.$emit('mousedown', this.mouse);
        }

        @Watch('origin')
        public originListner() {
            const dx = this.mouse.x - this.origin.x;
            const dy = this.mouse.y - this.origin.y;
            this.startDistance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        }

        @Watch('mouse')
        public mouseMoveListener(mouse: any, prev: any) {
            if (this.getActive() !== this.$refs.el) {
                return;
            }

            if (mouse.x === prev.x && mouse.y === prev.y) {
                return;
            }

            const dx = mouse.x - this.origin.x;
            const dy = mouse.y - this.origin.y;
            // 计算距离中心点的距离。
            const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
            this.$emit('change', Math.round((distance - this.startDistance) / Math.SQRT2));
            // this.startDistance = distance;
        }

    }

    export default Anchor;
