import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';

export default () => create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    columns,
}, {
    state: {
        unbindSnIng: false,
        lastUnbindTime: null
    },
    mutations: {
        setUnbindSnLoading(state: any, loading: boolean) {
            state.unbindSnIng = loading;
        },
        setUnbindSnSuccessed(state: any) {
            state.unbindSnIng = false;
            state.lastUnbindTime = new Date().toString();
        },
        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        async unbind(context: any, payload: any) {
            context.commit('setUnbindSnLoading', true);
            try {
                await services.unbind({
                    ...payload,
                    merchant_no:sessionStorage.getItem('snModal_merchant_no'),
                    offline_terminal_id:sessionStorage.getItem('snModal_offline_terminal_id')
                });
                context.commit('setUnbindSnSuccessed');
                context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setUnbindSnLoading', false);
            }
        }
    }
});
