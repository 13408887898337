
import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import SearchTable from './SearchTable.vue';
import Actions from './Actions.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {Show} from '@/components/Auth';
import { queryToHumanReadable } from '@/utils';
import PRIVILEGE from '@/common/privileges';
import preferences from './preferences';

const humanReadableQuerySchema: any = {
        startTime: {
            name: '创建时间',
            range: 'from'
        },
        endTime: {
            name: '创建时间',
            range: 'to'
        },
        type: '状态'
    };

@Component({
    components: {
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        Actions,
        Show
    },
    computed: {
        ...mapState({
        })
    }
})
export default class WhitelistPage extends Vue {
    public storeName = 'StatementOperatePage';
    public PRIVILEGE = PRIVILEGE;
    public fixed = false;
    public humanReadableQuery = null;

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            this.storeName + '/' + action,
            payload
        );
    }

    @Watch('$store.state.StatementOperatePage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }
         public created() {
            // this.$store.dispatch('StatementOperatePage/initQuery', {
            //     ...this.$route.query
            // });
        }
        @Watch('$store.state.StatementOperatePage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.StatementOperatePage.query')
        public watchHumanReadableQuery(query: any) {
            console.log('humanReadableQuery:', query);
            this.humanReadableQuery = queryToHumanReadable(query, humanReadableQuerySchema);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            console.log('route:', route);
            this.dispatch('initQuery', this.$route.query);
        }
}
