
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DisableDatePicker,AwardRule,districtCodeTransfer} from '@/components/custom-form-components';
interface DetailForm {
    form: any;
    otherForm:any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        districtCodeTransfer,
        AwardRule
    },
    computed: {
        ...mapState({
            bankList: (state: any) => state.policyActivitySearchPage.bankList,
            configData(state: any) {
                return (
                    state.policyActivitySearchPage.ruleConfigData 
                );
            },
            formData(state: any) {
                return (
                    state.policyActivitySearchPage.data &&
                    this.formatFormData(state.policyActivitySearchPage.data)
                );
            },
            
            spinning(state) {
                return(
                    state.policyActivitySearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public form;
    public districtCodeList = [];
    public awardRuleData= [];
    validObj: any;
  configData: any;
    public data() {
        return {
            districtCodeList:[],
            validObj:{},
            //第一页数据
            form: this.$form.createForm(this),
        };
    }
    public created() {}



    private formatFormData(rawData: any) {
        let template:any;
        if (rawData && rawData.templetJson) {
            template = JSON.parse(rawData.templetJson);
            template = JSON.parse(template)
        }
        if(1 != template.merSettleCardBound){
            rawData.cardBinLimit = undefined;
            rawData.cardBinLimitValue = undefined;
        }
        if( template){
            for(let i in this.configData ){
                const obj = this.configData[i];
                if('checkbox' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                    let newArray=[];
                    console.log(template[obj.key])
                    let array:any = template[obj.key];
                    for(let j in array){
                        let strVal = array[j]+'';
                        newArray.push(strVal);
                    }
                    template = {
                        ...template,
                        [obj.key] : newArray
                    }
                }
                if(obj.disabled) {
                    if (
                        (rawData.hasOwnProperty(obj.key) && rawData[obj.key]) || 
                        (template.hasOwnProperty(obj.key) && (template[obj.key] || template[obj.key] === 0))
                    ) {
                        this.validObj[obj.key] = 'Y';
                    }
                }
            }
            let cardBinLimit=rawData.cardBinLimit;
            if('unlimited' == cardBinLimit){
                cardBinLimit = '0';
            }else if('whiteList' == cardBinLimit){
                cardBinLimit = '1';
            }else if('blackList' == cardBinLimit){
                cardBinLimit = '2';
            }
            template = {
                ...template,
                cardBinLimit : cardBinLimit,
                cardBinLimitValue:rawData.cardBinLimitValue
            }
        }
        return {
            ...rawData,
            ...template
        }
    }
}

export default DetailForm;
