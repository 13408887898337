var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("label", [_vm._v("输入代码:")]),
      _c("a-textarea", {
        attrs: {
          disabled: _vm.disabled,
          value: _vm.data && _vm.data.code,
          placeholder: "请输入",
          autosize: { minRows: 2, maxRows: 6 },
        },
        on: { change: _vm.changeHandler },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }