var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-row",
    { attrs: { gutter: 12 } },
    [
      _vm.province
        ? _c(
            "a-col",
            { attrs: { span: _vm.span } },
            [
              _c("a-auto-complete", {
                attrs: {
                  dataSource: _vm.disableAutoComplete ? [] : _vm.provinces,
                  value: _vm.value && _vm.value[0],
                  filterOption: _vm.filterOption,
                  getPopupContainer: (trigger) => trigger.parentNode,
                  placeholder: "省",
                  allowClear: "",
                },
                on: { change: _vm.handleProvinceChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.city
        ? _c(
            "a-col",
            { attrs: { span: _vm.span } },
            [
              _c("a-auto-complete", {
                attrs: {
                  dataSource: _vm.disableAutoComplete ? [] : _vm.cities,
                  value: _vm.value && _vm.value[1],
                  filterOption: _vm.filterOption,
                  getPopupContainer: (trigger) => trigger.parentNode,
                  placeholder: "市",
                  allowClear: "",
                },
                on: { change: _vm.handleCityChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.district
        ? _c(
            "a-col",
            { attrs: { span: _vm.span } },
            [
              _c("a-auto-complete", {
                attrs: {
                  dataSource: _vm.disableAutoComplete ? [] : _vm.districts,
                  value: _vm.value && _vm.value[2],
                  filterOption: _vm.filterOption,
                  getPopupContainer: (trigger) => trigger.parentNode,
                  placeholder: "区",
                  allowClear: "",
                },
                on: { change: _vm.handleDistrictChange },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }