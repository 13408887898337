import {request_yx, requestStream} from '@/utils';

export async function create(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/add',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        console.log(e);
        const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function editAct(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/editActivityInfo',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}



