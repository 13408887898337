import {
    defaultCustomRender,priceCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';
import Vue from 'vue';

const newVue = new Vue();

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 100,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '批次ID',
        dataIndex: 'platActivity',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '主题活动ID',
        dataIndex: 'gwActivityId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '机构ID',
        dataIndex: 'orgId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '优惠类型',
        dataIndex: 'activityType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易类型',
        dataIndex: 'billType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    } ,
    {
        title: '营销补贴金额',
        dataIndex: 'billAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '用户实付金额',
        dataIndex: 'txSettleAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商户实收金额',
        dataIndex: 'actualAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '惠码orderId',
        dataIndex: 'orderId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易系统单号',
        dataIndex: 'ordeNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '消耗时间',
        dataIndex: 'createdTime',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商户号',
        dataIndex: 'merNo',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '线下终端号',
        dataIndex: 'offlineTerminalId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '营销流水号',
        dataIndex: 'billId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '清结算同步状态',
        dataIndex: 'settleStatus',
        align: 'center',
        width: 200,
        customRender: (value: any) => {
            if (!value) {
                return '-';
            }
            if ('0'===value) {
                return '待处理';
            }else if ('1'===value){
                return '成功';
            }else{
                return '失败';
            }
        }
    },
    {
        title: '清结算同步结果',
        dataIndex: 'settleMsg',
        align: 'center',
        width: 200,
        customRender: (value: string, record: any) => {
            return newVue.$createElement('a-tooltip', {
                props: {
                    title: record.settleCode
                }
            }, value);
        }
    }

];
