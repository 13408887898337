export default {
    title: '',
    dataIndex: '@@BLANK',
    customRender: () => {
        const obj = {
            children: '',
            attrs: {
                style: 'padding:0;background-color:#ffffff;'
            },
        };
        return obj;
    },
    customHeaderCell() {
        return {
            style: 'padding:0;background-color:#fafafa;'
        };
    }
};
