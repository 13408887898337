
    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({
        components: {}
    })
    class CardItem extends Vue {
        @Prop()
        public title?: string;
        @Prop()
        public total?: any;
        @Prop()
        public loading?: boolean;
    }

    export default CardItem;
