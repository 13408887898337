import Vue from 'vue';
import './antComponents';
import privileges from '@/common/privileges';
import App from './App.vue';
import router from './router';
import store from './store';
import filters from './filter';

import './app-style.css';
import Sortable from '@/directives/sortable';

(window as any).PRIVILEGE = privileges;
Vue.config.productionTip = false;

Vue.use(Sortable);

// 把自定义的全局数据过滤器注册在全局
for (const key of Object.keys(filters)) {
    // @ts-ignore
    Vue.filter(key, filters[key]);
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
