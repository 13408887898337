var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-form",
    { attrs: { form: _vm.form } },
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": _vm.formItemLayout.labelCol,
            "wrapper-col": _vm.formItemLayout.wrapperCol,
            label: "政策id",
          },
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "templetId",
                  { rules: [{ required: true, message: "请输入政策id" }] },
                ],
                expression:
                  "[\n        'templetId',\n        { rules: [{ required: true, message: '请输入政策id' }] },\n      ]",
              },
            ],
            attrs: { placeholder: "请输入政策id" },
          }),
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": _vm.formTailLayout.labelCol,
            "wrapper-col": _vm.formTailLayout.wrapperCol,
          },
        },
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.submitting,
                loading: _vm.submitting,
              },
              on: { click: _vm.check },
            },
            [_vm._v("\n      提交\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }