
import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import preferences from './preferences';
import { queryToHumanReadable } from '@/utils';
import FieldsFilter from '@/components/FieldsFilter.vue';

import SearchForm from './SearchForm.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import columns from './columns';

const humanReadableQuerySchema = {
    name: '活动名称',
    platActivity: '批次Id',
    startTime: {
        name: '消耗时间',
        range: 'from'
    },
    endTime: {
        name: '消耗时间',
        range: 'to'
    }
};
@Component({ 
    components: {
        SearchForm,
        Actions,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.activityDetailSearchPageUnion.fields,
            sortArr: (state: any) =>
                state.activityDetailSearchPageUnion.sortFields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.activityDetailSearchPageUnion.query,
                    humanReadableQuerySchema
                );
            }
        })
    }
})
export default class RolePage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'activityDetailSearchPageUnion';

    public data() {
        return {
            columns
        };
    }

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/activities/activityDetailUnion' +
                this.getters('queryString')()
        });
    }

    public created() {
        // this.registerStore();
        // this.dispatch('initQuery', {
        //     ...this.$route.query
        // });
    }

    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    private handleFieldChange(e: any) {
        this.$store.commit('activityDetailSearchPageUnion/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('activityDetailSearchPageUnion/sortFields', e);
    }
}
