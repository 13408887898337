import blankCell from '@/common/blankCell';
import {defaultCustomRender} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '活动商户开始时间',
        dataIndex: 'startTime',
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '活动商户结束时间',
        dataIndex: 'endTime',
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '总条数',
        dataIndex: 'batchTotal',
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
    
];
