
    import {
        Col,
        Row,
        Input
    } from 'ant-design-vue';
    import {Component, Prop, Vue} from 'vue-property-decorator';

    const bigNumber = 99999999;

    @Component<PriceRange>({
        components: {
            [Input.name]: Input,
            [Row.name]: Row,
            [Col.name]: Col
        },
        computed: {
            value1Min() {
                if (this.min !== undefined) {
                    return this.min;
                } else {
                    return -bigNumber;
                }
            },
            value1Max() {
                if (this.max !== undefined) {
                    return Math.min(this.value && parseFloat(this.value[1]) || bigNumber, this.max);
                } else {
                    return this.value && parseFloat(this.value[1]) || bigNumber;
                }
            },
            value2Min() {
                if (this.min !== undefined) {
                    return Math.max(this.value && parseFloat(this.value[0]) || -bigNumber, this.min);
                } else {
                    return this.value && parseFloat(this.value[0]) || -bigNumber;
                }
            },
            value2Max() {
                if (this.max !== undefined) {
                    return this.max;
                } else {
                    return bigNumber;
                }
            }
        }
    })
    class PriceRange extends Vue {

        @Prop()
        public value!: any;

        @Prop()
        public min!: any;

        @Prop()
        public max!: any;

        public minChange(e: any) {
            this.$emit('change', [e, this.value && this.value[1]]);
        }

        public maxChange(e: any) {
            this.$emit('change', [this.value && this.value[0], e]);
        }
    }

    export default PriceRange;
