import {request} from '@/utils';
import { message } from 'ant-design-vue';
import qs from 'querystringify';
export async function transfer(params: {
    all_user: string;
    weekly_limit: string;
    batch_user_id: string;
}) {
    try {
        const ret = await request({
            method: 'POST',
            api: '/console/customer/sms/update',
            data: qs.stringify(params),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        //message.success('提交成功');
        
        console.log(ret);
        return ret;
    } catch (e) {
        if (e.message === '网络错误') {
            message.error('网络错误，请稍后重试!');
        } else {
            message.error('提交失败，或联系技术人员!');
        }
    }
}
