var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-input-search",
    {
      attrs: { value: _vm.value, placeholder: "请输入验证码" },
      on: { search: _vm.handleClick, change: _vm.handleInput },
    },
    [
      _c(
        "a-button",
        {
          style: _vm.buttonStyle || {},
          attrs: {
            slot: "enterButton",
            disabled: _vm.seconds > 0,
            loading: _vm.loading,
            type: "primary",
          },
          slot: "enterButton",
        },
        [
          _vm._v(
            _vm._s(
              `${_vm.seconds > 0 ? "重新获取" + _vm.seconds : "获取验证码"}`
            ) + "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }