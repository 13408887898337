import {RouteConfig} from 'vue-router';
import Search from './SearchPage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    }

    
];

export default route;
