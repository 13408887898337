var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "q-white-list-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined,
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "shenhe",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    width: "88%",
                    margin: "0 auto",
                    "text-align": "left",
                  },
                },
                [
                  _c("RefuseDetail", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: record.status != "待审核",
                        expression: "record.status != '待审核'",
                      },
                    ],
                    attrs: { auditOpinion: record.opinion },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function (_) {
                            return [
                              _c("a-button", { attrs: { size: "small" } }, [
                                _vm._v("详情"),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }