
    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import {request, requestStream, readStream} from '@/utils';
    import MerchantPanel from './panels/Merchant.vue';
    import MallPanel from './panels/Mall.vue';
    import BizDistrictPanel from './panels/BizDistrict.vue';
    import UploadMerchantPanel from './panels/UploadMerchant.vue';

    @Component<AdAssignDetail>({
        components: {
            MerchantPanel,
            MallPanel,
            BizDistrictPanel,
            UploadMerchantPanel
        },
        computed: {
            text() {
                if (this.type === 'directly') {
                    return '商户';
                }
                if (this.type === 'malls') {
                    return '商场';
                }
                if (this.type === 'biz_districts') {
                    return '商圈';
                }
                if (this.type === 'upload_aus') {
                    return '批量上传';
                }
            }
        }
    })
    class AdAssignDetail extends Vue {

        @Prop()
        public adPlanId: any;

        @Prop()
        public type: any;

        public visible = false;

        public handleTriggerClick() {
            this.visible = true;
        }
    }

    export default AdAssignDetail;
