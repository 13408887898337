import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-q-whitelist-reporting-status',
    options: [{
        label: '已报备',
        value: 'True'
    }, {
        label: '未报备',
        value: 'False'
    }]
});
