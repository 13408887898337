var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "el",
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          return _vm.handleMouseDown.apply(null, arguments)
        },
      },
    },
    [_c("div", { style: _vm.style })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }