import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 100,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '出资方名称',
        dataIndex: 'sponsorName',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '批次ID',
        dataIndex: 'platActivity',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '优惠ID',
        dataIndex: 'promoId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '预算ID',
        dataIndex: 'gwActivityId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '机构ID',
        dataIndex: 'orgId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '优惠类型',
        dataIndex: 'promoType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '优惠金额',
        dataIndex: 'promoAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    } ,
    {
        title: '订单总金额',
        dataIndex: 'orderAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '支付单号',
        dataIndex: 'payTradeNo',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '消耗时间',
        dataIndex: 'createdTime',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '消耗商户号',
        dataIndex: 'subMerId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '设备号',
        dataIndex: 'deviceId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '银行流水号',
        dataIndex: 'billRefR',
        align: 'center',
        width: 200,
        fixed: 'right',
        customRender: defaultCustomRender
    }
];
