var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.ACTIVITY_INSPECTIOON.LIST] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "审核意见",
            visible: _vm.visible,
            footer: "",
            width: 900,
          },
          on: { ok: _vm.handleOK, cancel: _vm.handleClose },
        },
        [
          _c("a-textarea", {
            attrs: { autosize: { minRows: 6, maxRows: 6 } },
            model: {
              value: _vm.auditOpinion,
              callback: function ($$v) {
                _vm.auditOpinion = $$v
              },
              expression: "auditOpinion",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }