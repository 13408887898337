import {request} from '@/utils';
import md5 from 'md5';
import config from '@/config';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/store/sms/send/task',
            data: {
                ...params,
                store_id: sessionStorage.getItem('store_id')
            }
        });
    } catch (e) {
        const error: any = new Error('获取发送给记录列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
