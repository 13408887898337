import {request} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/role/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取角色列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function fetchPermissionList() {
    try {
        return await request({
            api: '/console/permission/list',
        });
    } catch (e) {
        const error: any = new Error('获取权限列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function saveRolePrivilege(params: any) {
    try {
        return await request({
            api: '/console/role/set/permissions',
            method: 'POST',
            data: params
        });
    } catch (e) {
        const error: any = new Error('保存失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function createRole(params: any) {
    try {
        return await request({
            api: '/console/role/create',
            method: 'POST',
            data: params
        });
    } catch (e) {
        const error: any = new Error('创建失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteRole(params: any) {
    try {
        return await request({
            api: '/console/role/delete',
            method: 'POST',
            data: params
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
