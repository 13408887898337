import {RouteConfig} from 'vue-router';
import Search from './SearchPage/index.vue';
import Edit from './EditPage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'edit/:id',
        name: '修改',
        component: Edit,
    }
];

export default route;
