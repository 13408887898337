import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-zhongxin-control-active-status',
    options: [{
        label: '有效',
        value: 1
    }, {
        label: '失效',
        value: 2
    }, {
        label: '已删除',
        value: 3
    }]
});
