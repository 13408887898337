var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-row",
    { attrs: { gutter: 12 } },
    [
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c("a-input-number", {
            staticStyle: { width: "100%" },
            attrs: {
              min: _vm.value1Min,
              max: _vm.value1Max,
              step: 0.01,
              precision: 2,
              value: _vm.value && _vm.value[0],
              placeholder: "最小金额",
            },
            on: { change: _vm.minChange },
          }),
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c("a-input-number", {
            staticStyle: { width: "100%" },
            attrs: {
              min: _vm.value2Min,
              max: _vm.value2Max,
              step: 0.01,
              precision: 2,
              value: _vm.value && _vm.value[1],
              placeholder: "最大金额",
            },
            on: { change: _vm.maxChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }