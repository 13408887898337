var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: _vm.privileges || [] },
        on: { pass: _vm.handleTriggerClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _vm._t("trigger", function () {
                    return [_vm._v("\n                点击\n            ")]
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-drawer",
        {
          attrs: {
            wrapClassName: "rich-editor-drawer",
            title: "地址生成",
            placement: "right",
            width: 400,
            visible: _vm.visible,
          },
          on: { close: _vm.handleCancel },
        },
        [
          !_vm.initing
            ? _c("vue-ueditor-wrap", {
                key: _vm.editorProps.key,
                attrs: { config: _vm.editorProps.config },
                on: { ready: _vm.handleEditorReady },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { padding: "8px" } },
            [
              _vm.toolBarHeight
                ? _c(
                    "a-row",
                    { attrs: { gutter: 8 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                disabled: _vm.generating || !_vm.content,
                              },
                              on: { click: _vm.clickHandler },
                            },
                            [_vm._v("地址生成")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 13 } },
                        [
                          _c("a-input", {
                            ref: "url",
                            attrs: { value: _vm.url, placeholder: "" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { disabled: _vm.generating || !_vm.url },
                              on: { click: _vm.copyHandler },
                            },
                            [_vm._v("复制")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c("a-icon", { attrs: { type: "loading" } }),
                      _vm._v(" 正在加载"),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }