import { create } from './selectComponentFactory';

export default create({
    id: 'ad-material-tpl-selector',
    options: [{
        label: '图链',
        value: 'IMAGE_TEXT_LINK'
    }, {
        label: '小程序广告',
        value: 'MINI_APP'
    }]
});
