
    import {Modal} from 'ant-design-vue';
    import {mapState} from 'vuex';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import PrivilegeAssignModal from './PrivilegeAssignModal.vue';
    import * as services from './services';
    import columns from './columns';
    import {check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {calcTableScrollX, getTop} from '@/utils';

    interface SearchTable {
        selectedRowKeys: any[];
    }

    @Component({
        components: {
            PrivilegeAssignModal
        },
        computed: {
            rowSelection() {
                const self: any = this;
                const {selectedRowKeys} = self;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
            ...mapState({
                roles() {
                    const {list, page, pageSize} = this.state();
                    return list.map((order: any, idx: any) => ({
                        idx: (page - 1) * pageSize + idx + 1,
                        ...order
                    }));
                },
                total() {
                    return this.state().total;
                },
                pageSize() {
                    return this.state().pageSize;
                },
                page() {
                    return this.state().page;
                },
                loading() {
                    return this.state().loading;
                },
                privileges() {
                    return this.state().privileges;
                }
            })
        }
    })
    class SearchTable extends Vue {
        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;
        @Inject()
        public commit!: any;
        @Inject()
        public getters!: any;
        @Inject()
        public routerPush!: any;
        @Inject('privileges')
        public ownPrivileges!: any;

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                selectedRowKeys: [],
                columns,
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public created() {
            // this.dispatch('fetchPrivileges');
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.selectedRowKeys = selectedRowKeys;
        }

        public handlePageChange(page: number) {
            this.commit('setPagination', {page});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query);
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.commit('setPagination', {pageSize, page: 1});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query);
        }

        public handleAssignSuccess() {
            this.dispatch('search');
        }

        public async handleDeleteConfirm(roleId: any) {
            const list = check([PRIVILEGE.ROLE.DELETE], this.ownPrivileges);
            if (list.length > 0) {
                return this.$message.error(
                    '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
                );
            } else {
                Modal.confirm({
                    title: '确认删除?',
                    onOk: async () => {

                        try {
                            await services.deleteRole({
                                role_id: roleId
                            });
                            this.dispatch('search');
                        } catch (e) {
                            this.$message.error(e.message);
                        }
                    }
                });
            }
        }
    }

    export default SearchTable;
