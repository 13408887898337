import {request} from '@/utils';
import qs from 'querystringify';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/userwhitelist/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取白名单列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function createUserWhitelist(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/userwhitelist/create',
            data: {
                ...params
            }
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        // hack，下一版本优化
        if (e.message === '网络错误') {
            e.message = '提交失败, 请联系技术人员!';
        }
        const error: any = new Error(e.message);
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteUserWhitelist(params: any) {
    const formData = new URLSearchParams();
    formData.append('batch_id', params.id);
    try {
        return await request({
            api: '/console/userwhitelist/delete',
            method: 'POST',
            data: formData
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteCustomer(params: any) {
    try {
        return await request({
            api: '/console/customer/delete',
            data: params
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}


export async function openDebug(params: {
    batch_id: string;
    enabled: 'False' | 'True'
}) {
    try {
        return await request({
            method: 'POST',
            api: '/console/userwhitelist/batch/switch',
            data: qs.stringify(params),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
