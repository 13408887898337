import {request_yx} from '@/utils';
import qs from 'querystringify';

export async function search(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/billRecon/query',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function adjustment(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/api/recon/adjustment',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('调账失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

