
    import {Modal} from "ant-design-vue";
    import {mapState} from "vuex";
    import {Component, Inject, Watch, Vue} from "vue-property-decorator";
    import CreateModal from "./CreateModal.vue";
    import {Click} from "@/components/Auth";
    import PRIVILEGE from "@/common/privileges";

    interface Actions {
        selectedRows: any;
    }

    @Component({
        components: {
            CreateModal,
            Click
        },
        computed: {
            ...mapState({
                loading() {
                    return this.state().loading;
                },
                selectedRows() {
                    return this.state().selectedRows;
                }
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public dispatch!: any;

        @Inject()
        public state!: any;

        @Watch("$store.state.blacklistSearchPage.lastDeletedTime")
        public watchLastDeletedTime(time: any) {
            this.$message.success("移除成功");
        }

        public data() {
            return {
                PRIVILEGE
            };
        }

        public handleRefresh() {
            this.dispatch("search");
        }

        public async handleDeleteConfirm() {
            Modal.confirm({
                title: "移除后，选中商户将恢复进件及交易功能，确定移除黑名单吗？",
                onOk: () => {
                    this.dispatch("deleteBlacklist");
                }
            });
        }
    }

    export default Actions;
