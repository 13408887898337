
    import {mapState} from 'vuex';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import DetailDrawer from './DetailDrawer/index.vue';
    import columns from './columns';
    import {normColumns, calcTableScrollX, getTop} from '@/utils';
    import ClearText from '@/components/ClearText.vue';
    import PRIVILEGE from '@/common/privileges';

    interface SearchTable {
        selectedRowKeys: any[];
    }

    const sortFieldMap: any = {
        'mobile': 'mobile',
    };

    @Component({
        components: {
            DetailDrawer,
            ClearText
        },
        computed: {
            ...mapState({
                users: (state: any) =>
                    state.customerSearchPage.list.map((order: any, idx: any) => ({
                        idx: (state.customerSearchPage.page - 1) * 10 + idx + 1,
                        ...order
                    })),
                total: (state: any) => state.customerSearchPage.total,
                pageSize: (state: any) => state.customerSearchPage.pageSize,
                page: (state: any) => state.customerSearchPage.page,
                loading: (state: any) => state.customerSearchPage.loading,
                fields: (state: any) => {
                        const {
                        sort_name,
                        sequence
                    } = state.customerSearchPage.query;
                    columns.forEach((col: any) => {
                        if (sortFieldMap[col.dataIndex] === sort_name) {
                            col.sortOrder = String(sequence) === 'True' ? 'ascend' : 'descend';
                        } else {
                            col.sortOrder = false;
                        }
                    });
                    return normColumns(columns, state.customerSearchPage.fields, state.customerSearchPage.sortFields);
                },
                rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys = state.customerSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
            })
        }
    })
    class SearchTable extends Vue {
        @Inject()
        public dispatch!: any;

        @Inject()
        public privileges!: any;

        @Inject()
        public commit!: any;

        @Inject()
        public routerPush!: any;

        public PRIVILEGE = PRIVILEGE;

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public handleTableChange(pagination: any, filters: any, sorter: any,formData:any) {
            const {
                sort_name,
                sequence
            } = this.$store.state.customerSearchPage.query;
            
            const {
                field,
                order
            } = sorter;
            
            function isFieldEqual() {
                if ((field === undefined || field === 'idx') && sort_name === undefined) {
                    return true;
                }
                if (sortFieldMap[field] === sort_name) {
                    return true;
                }
                return false;
            }

            function isOrderEqual() {
                if ((order === undefined || field === 'idx') && sequence === undefined) {
                    return true;
                }
                if (
                    (order === 'descend' && sequence === 'False')
                    || (order === 'ascend' && sequence === 'True')
                ) {
                    return true;
                }
                return false;
            }

            if (isOrderEqual() && isFieldEqual()) {
                return;
            }

            const newQuery: any = {};
            if (field === undefined || order === undefined) {
                newQuery.sort_name = undefined;
                newQuery.sequence = undefined;
            } else {
                newQuery.sort_name = sortFieldMap[field];
                newQuery.sequence = order === 'descend' ? 'False' : 'True';
            }

            this.$store.commit('customerSearchPage/setQuery', newQuery);
            this.$store.commit('customerSearchPage/setPagination', {page: 1});

            // let newQueryString =  this.$store.getters['customerSearchPage/queryString']().substring(1).split('&')
            // if(newQueryString[0].substring(0,6)==='mobile') {
            //     newQueryString[0].substring(8) === ''
            //     console.log(newQueryString[0])
            // }
            
            this.$router.push({
                path: `/console/account/customer/search` + this.$store.getters['customerSearchPage/queryString']()
            });
            // if(this.$store.getters['customerSearchPage/queryString']().substring(1).split('&')[0].substring(0,6)==='mobile') {
            //     this.$store.getters['customerSearchPage/queryString']().substring(1).split('&')[0].substring(7,6)
            // }
            this.routerPush();
            this.dispatch('initQuery', this.$route.query)
            // console.log(this.$store.getters['customerSearchPage/queryString']().substring(1).split('&')[0].substring(0,6)==='mobile',67)

        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                selectedRowKeys: [],
                columns,
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        
        public onSelectChange(selectedRowKeys: any[]) {
            this.$store.commit('customerSearchPage/setSelectedRows', selectedRowKeys);
        }

        public handlePageChange(page: number) {
            this.commit('setPagination', {page});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query)
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.commit('setPagination', {pageSize, page: 1});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query)
        }
    }

    export default SearchTable;
