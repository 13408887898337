var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "mall-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.malls,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined,
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "address",
            fn: function (text, record) {
              return _c("ClearText", {
                attrs: {
                  requires: [_vm.PRIVILEGE.MALL.ADDRESS],
                  text: text,
                  action: "/console/mall/get/address",
                  getClearText: (result) => result.address,
                  params: { mall_id: record.id },
                },
              })
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c("Click", {
                    attrs: { requires: [_vm.PRIVILEGE.MALL.UPDATE] },
                    on: {
                      pass: function ($event) {
                        return _vm.$router.push({
                          path: `/console/mall/${record.id}`,
                        })
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function (slotProps) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    size: "small",
                                    href: "javascript:;",
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c(
                    "EnterDrawer",
                    {
                      attrs: {
                        mall_id: record.id,
                        title: "入驻列表(" + record.mall_name + ")",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("入驻列表")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "MemberDrawer",
                    {
                      attrs: {
                        mall_id: record.id,
                        title: "会员列表(" + record.mall_name + ")",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("会员")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }