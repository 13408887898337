
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import columns from './columns';
    import {mapState} from 'vuex';
    import {queryToHumanReadable} from '@/utils';
    const humanReadableQuerySchema = {
        name: '操作者',
        module: '操作模块',
        role_id: {
            name: '角色',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/role/simple_list'
            },
            getParams(value: any) {
                return {};
            }
        },
        start_time: {
            name: '操作时间',
            range: 'from'
        },
        end_time: {
            name: '操作时间',
            range: 'to'
        }
    };
    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.operateLogSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class OrderPage extends Vue {

        public fixed = false;

        public data() {
            return {
                columns
            };
        }

        public created() {
            this.$store.dispatch('operateLogSearchPage/initQuery', {
                ...this.$route.query
            });
        }

        @Watch('$store.state.operateLogSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener() {
            this.$store.dispatch('operateLogSearchPage/initQuery', this.$route.query);
        }
    }
