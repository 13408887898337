var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Show", {
    attrs: {
      requires: [
        {
          $or: [
            _vm.PRIVILEGE.HOME.STATISTICS_AMOUNT,
            _vm.PRIVILEGE.HOME.STATISTICS_COUPON,
          ],
        },
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function (slotProps) {
          return [_c("Index")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }