import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';
import Create from './Draft/CreatePage/index.vue';
import CreateUnion from './Draft/CreatePageUnion/index.vue';
import DetailDownload from './DetailDownload/index.vue';
import Edit from './Open/EditPage/index.vue';
import EditUnion from './Open/EditPageUnion/index.vue';
import Detail from './Draft/DetailPage/index.vue';
import CreateUnionb2c from './unionb2c/CreatePageUnion/index.vue';
import CreateUnionCoupon from './unionCoupon/CreatePageUnion/index.vue';
import CreateMerSubsidyAct from './merSubsidyAct/CreatePage/index.vue';
import CreateWxCashCouponActivity from './zhongxin/CreatePage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'create',
        name: '创建活动',
        component: Create,
    },
    {
        path: 'createUnion',
        name: '创建银联活动',
        component: CreateUnion,
    },
    {
        path: 'createUnionb2c',
        name: '创建银联B2C全场活动',
        component: CreateUnionb2c,
    },
    {
        path: 'createUnionCoupon',
        name: '创建银联U点代金券活动',
        component: CreateUnionCoupon,
    },
    {
        path: 'createMerSubsidyAct',
        name: '创建商户补贴活动',
        component: CreateMerSubsidyAct,
    },
    {
        path: 'createWxCashCouponActivity',
        name: '创建微信代金券活动',
        component: CreateWxCashCouponActivity,
    },
    {
        path: 'open/search',
        name: '运营中',
        component: Index,
    },
    {
        path: 'draft/search',
        name: '草稿箱',
        component: Index,
    },
    {
        path: 'stop/search',
        name: '已停止',
        component: Index,
    },
    {
        path: 'unionb2c/search',
        name: '银联b2c',
        component: Index,
    },
    {
        path: 'unionCoupon/search',
        name: '银联代金券',
        component: Index,
    },
    {
        path: 'merSubsidyAct/search',
        name: '商户补贴活动',
        component: Index,
    },
    
    {
        path: 'merSubsidy/search',
        name: '商户补贴活动',
        component: Index,
    },
    {
        path: 'wxCashCouponActivity/search',
        name: '微信代金券活动',
        component: Index,
    },
    {
        path: 'detailDownload',
        name: '明细下载',
        component: DetailDownload,
    },
    {
        path: 'edit/:id',
        name: '修改店铺',
        component: Edit,
    },
    {
        path: 'editUnion/:id',
        name: '修改店铺',
        component: EditUnion,
    },
    {
        path: 'detail/:id',
        name: '活动明细',
        component: Detail,
    },
];

export default route;
