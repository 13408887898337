var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.BLACKLIST.CREATE] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form,
          },
          on: { ok: _vm.handleCreate, cancel: _vm.handleClose },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleCreate },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "type_name",
                            {
                              rules: [
                                { required: true, message: "请填写必填项!" },
                              ],
                              initialValue: "",
                            },
                          ],
                          expression:
                            "[\n                            'type_name',\n                            {\n                                rules: [{required: true, message: '请填写必填项!'}],\n                                initialValue: ''\n                            }\n                        ]",
                        },
                      ],
                      attrs: {
                        placeholder: "请选择",
                        getPopupContainer: (trigger) => trigger.parentNode,
                        allowClear: "",
                      },
                      on: { select: _vm.handleSelect },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "mobile" } }, [
                        _vm._v(
                          "\n                            手机号\n                        "
                        ),
                      ]),
                      _c(
                        "a-select-option",
                        { attrs: { value: "merchant_no" } },
                        [
                          _vm._v(
                            "\n                            商户号\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isMobile
                ? _c(
                    "a-form-item",
                    { attrs: { label: "值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "type_value",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项!" },
                                  {
                                    pattern: /^\d{11}$/,
                                    message: "请输入正确手机号",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                        'type_value',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^\\d{11}$/,  message: '请输入正确手机号'}]}\n                    ]",
                          },
                        ],
                        attrs: { placeholder: "请输入" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "a-form-item",
                    { attrs: { label: "值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "type_value",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项!" },
                                  {
                                    pattern: /^[A-Za-z\d]{15}$/,
                                    message: "请输入正确商户号",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                        'type_value',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^[A-Za-z\\d]{15}$/,  message: '请输入正确商户号'}]}\n                        ]",
                          },
                        ],
                        attrs: { placeholder: "请输入" },
                      }),
                    ],
                    1
                  ),
              _c(
                "a-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "remark",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^.{0,100}$/,
                                message: "最多可以输入100个字符",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n                        'remark',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^.{0,100}$/,  message: '最多可以输入100个字符'}]}\n                    ]",
                      },
                    ],
                    attrs: {
                      autosize: { minRows: 4, maxRows: 10 },
                      placeholder: "请输入",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }