import {RouteConfig} from 'vue-router';
import Search from './SearchPage/index.vue';
import createPolicy from './CreatePage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'createPolicy',
        name: '创建活动',
        component: createPolicy,
    },
];

export default route;
