
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import SearchFormWrapper from '@/components/SearchFormWrapper.vue';

    interface Query {
        user_id: string;
    }

    interface FormData {
        user_id: string;
    }

    @Component({
        components: {
            SearchFormWrapper
        },
        computed: {
            ...mapState({
                loading() {
                    return this.state().loading;
                },
                formData() {
                    return this.queryToFormData(this.state().query);
                },
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public state!: any;

        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false,
            };
        }

        public format(values: FormData): Query {
            return {
                user_id: values.user_id
            };
        }

        public queryToFormData(query: Query): FormData {
            return {
                user_id: query.user_id
            };
        }

        public handleSearch(query: Query) {
            this.dispatch('queryChange', query);
        }
    }

    export default SearchForm;
