var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        { staticClass: "mall-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "额度组名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupName",
                      {
                        initialValue: _vm.formData.merGroupName,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'merGroupName',\n                    {\n                        initialValue: formData.merGroupName,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "请输入", maxLength: 15 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityId",
                      {
                        initialValue: _vm.formData.activityId,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'activityId',\n                    {\n                        initialValue: formData.activityId,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { placeholder: "请输入", maxLength: 8, disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleId",
                      {
                        initialValue: _vm.formData.ruleId,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'ruleId',\n                    {\n                        initialValue: formData.ruleId,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动商户有效期" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleTime",
                      {
                        initialValue: _vm.formData.startTime
                          ? _vm.formData.startTime + "—" + _vm.formData.endTime
                          : "",
                      },
                    ],
                    expression:
                      "[\n                    'ruleTime',\n                    {\n                        initialValue: formData.startTime\n                            ? formData.startTime + '—' + formData.endTime\n                            : ''\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "-" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享补贴额度" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "limit",
                      {
                        initialValue: _vm.formData.limit,
                        rules: [{ required: true, message: "请填写必填项!" }],
                      },
                    ],
                    expression:
                      "[\n                    'limit',\n                    {\n                        initialValue: formData.limit,\n                        rules: [\n                            { required: true, message: '请填写必填项!' }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享补贴交易总限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "payLimit",
                      {
                        initialValue: _vm.formData.payLimit,
                        rules: [{ required: true, message: "请填写必填项!" }],
                      },
                    ],
                    expression:
                      "[\n                    'payLimit',\n                    {\n                        initialValue: formData.payLimit,\n                        rules: [\n                            { required: true, message: '请填写必填项!' }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享补贴交易封顶笔数" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "subsidyMaxNum",
                      {
                        initialValue: _vm.formData.subsidyMaxNum,
                        rules: [{ required: true, message: "请填写必填项!" }],
                      },
                    ],
                    expression:
                      "[\n                    'subsidyMaxNum',\n                    {\n                        initialValue: formData.subsidyMaxNum,\n                        rules: [\n                            { required: true, message: '请填写必填项!' }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }