import {request_yx} from '@/utils';
interface IQuery {
    merchantNo: string; // 商户号
    termId: string;     // 线下终端号
}
interface IRegister extends IQuery {
    invoiceAccountId: string;
}
class InvoiceServices {
    // 商户开票注册，支持首次注册和编辑
    public async invoiceRegister(params: IRegister) {
        return await request_yx({
            method: 'POST',
            api: '/console/store/invoice/regist',
            data: {
                ...params
            }
        });
    }
    // 商户开票信息查询
    public async invoiceQuery(params: IQuery) {
        return await request_yx({
            method: 'GET',
            api: '/console/store/invoice/query',
            data: {
                ...params
            }
        });
    }
}

export const invoiceServices = new InvoiceServices();
