import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    columns
});
