
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from './DatePicker.vue';
interface IrregularScheduleTimePicker {
  form: any;
}
let id = Math.random();
let timeId =  Math.random();
let nextKeysTemp =[];
let nextDataKeysTemp =[];
@Component<IrregularScheduleTimePicker>({
    components: {
        DatePicker
    },
    computed: {
        
        
    }
})

class IrregularScheduleTimePicker extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    @Prop()
    public size!: any;

    public timerange!: any;

    public daterange!:any;

    public data() {
        return {
          timerange:[],
          daterange:[],
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                sm: { span: 15, offset: 0 },
                },
            },
        };
    }
    public created() {
        this.form = this.$form.createForm(this);
        nextKeysTemp = [];nextDataKeysTemp = [];
        if(this.value && this.value.timerange){
             this.timerange = this.value.timerange;
             for(let i=0;i<this.timerange.length/2;i++){
                nextKeysTemp.push(i);
             }
             this.form.setFieldsValue({
                 timeKeys: nextKeysTemp,
             });
             this.form.getFieldDecorator('timeKeys', { initialValue: nextKeysTemp, preserve: true });
        }else{
            this.form.getFieldDecorator('timeKeys', { initialValue: [], preserve: true });
        }

        if(this.value && this.value.daterange){
             this.daterange = this.value.daterange;
             for(let i=0;i<this.daterange.length;i++){
                nextDataKeysTemp.push(i);
             }
             this.form.setFieldsValue({
                 keys: nextDataKeysTemp,
             });
             this.form.getFieldDecorator('keys', { initialValue: nextDataKeysTemp, preserve: true });
        }else{
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        }

    }
    public timerangeChange(time: any, timeString: string) {
        this.$emit('change', {timerange: this.timerange,daterange: this.daterange});
    }

    public remove  (k:any) {
      const { form } = this;
      const keys = form.getFieldValue('keys').map((item:number, index:number)=> {return index});
      if (keys.length === 1) {
        return;
      }
      keys.splice(k,1)
      form.setFieldsValue({
        keys: keys.map((item:number, index:number)=> {return index}),
      });
      this.daterange.splice(k,1);
      this.$emit('change', {daterange: this.daterange, timerange: this.timerange});
    }

    public add  () {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      if(keys.length >5){
        this.$message.warning('最多添加6条')
        return;
      }
      const nextKeys = keys.concat(Math.random());
      form.setFieldsValue({
        keys: nextKeys.map((item:number, index:number)=> {return index}),
      });
      this.daterange.push(null);
      this.$emit('change', {daterange: this.daterange, timerange: this.timerange});
    }

    public removeTime  (k:any) {
      const { form } = this;
      const keys = form.getFieldValue('timeKeys').map((item:number, index:number)=> {return index});
      if (keys.length === 1) {
        return;
      }
      keys.splice(k,1)
      form.setFieldsValue({
        timeKeys: keys.map((item:number, index:number)=> {return index}),
      });
      this.timerange.splice(2*k, 2);
      this.$emit('change', {daterange: this.daterange, timerange: this.timerange});
    }

    public addTime  () {
      const { form } = this;
      const keys = form.getFieldValue('timeKeys');
      
      //添加不能超过3个
      if(keys.length >2){
        this.$message.warning('最多添加3条')
        return;
      }
      const nextKeys = keys.concat(Math.random());
      form.setFieldsValue({
        timeKeys: nextKeys.map((item:number, index:number)=> {return index}),
      });
      this.timerange.push(null);
      this.timerange.push(null);
      this.$emit('change', {daterange: this.daterange, timerange: this.timerange});
    }
 

    public handleChange(date: any, dateString: string) {
      let temTimerange = this.timerange.filter((item: any) => item !== undefined)
      this.timerange = temTimerange
      this.$emit('change', {daterange: this.daterange,timerange: this.timerange});
    }

   
    
}
export default IrregularScheduleTimePicker;
