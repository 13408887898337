
    import {Component, Prop, Vue, Provide, Inject} from 'vue-property-decorator';
    import Drawer from '@/components/Drawer.vue';
    import {getValueByPath} from '@/utils';
    import {mapState} from 'vuex';
    import columns from './columns';
    import {check, Show, Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import './store';
    import config from '@/config';

    @Component({
        components: {
            Drawer,
            // SearchTable,
            // SearchForm,
            Show,
            Click
        },
        computed: {
            ...mapState({
                list: (state: any) =>
                    state.merSubsidyActRule.list.map((item: any, idx: any) => ({
                        idx: (state.merSubsidyActRule.page - 1) * state.merSubsidyActRule.pageSize + idx + 1,
                        ...item
                    })),
                total: (state: any) => state.merSubsidyActRule.total,
                pageSize: (state: any) => state.merSubsidyActRule.pageSize,
                page: (state: any) => state.merSubsidyActRule.page,
                loading: (state: any) => state.merSubsidyActRule.loading,
                token: (state: any) => {
                    return state.login.session && state.login.session.token;
                },
                downloadingErrorExcel: (state: any) => state.merSubsidyActRule.downloadingErrorExcel,
                refreshing: (state: any) => state.merSubsidyActRule.refreshing
            })
        }
    })
    class Store extends Vue {
        // @Prop()
        // // tslint:disable-next-line:variable-name
        // public mall_id!: string;

        // @Prop()
        // public title!: string | number;
        @Inject()
        public privileges!: any;
        @Prop()
        public activityId!:any;
        public fileList: any = [];
        public PRIVILEGE = PRIVILEGE;

        private timer: any;

        private uploading = false;

        public data() {
            return {
                columns,
                config
            };
        }
         public created() {
            
        }
        @Provide()
        public state() {
            console.log('chaxun');
            return this.$store.state['merSubsidyActRule'];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'merSubsidyActRule/' + action,
                payload
            );
        }

        public handleRefreshClick(record: any) {
            this.dispatch('refresh', record.taskId);
        }

        public handleDownloadErrorClick(record: any) {
            this.dispatch('downloadErrorExcel', record.taskId);
        }

        public handleFileUpload({file, fileList, event}: any) {
            this.fileList = [{
                ...file,
                name: ({
                    uploading: '正在上传',
                    done: '上传完成',
                    error: '上传失败',
                    removed: ''
                } as any)[file.status]
            }];
            this.uploading = true;
            if (file && file.status === 'done' && file.response) {
                if (file.response.status.code !== 0) {
                    this.$message.error(file.response.status.desc);
                } else {
                    this.dispatch('search');
                }
            }

            if (file.status !== 'uploading') {
                this.uploading = false;
            }
        }

        public disabledUpload() {
            if (check([this.PRIVILEGE.MER_SUBSIDY_ACT.RULE], this.privileges).length > 0) {
                return true;
            }
            return false;
        }

        public handleDownloadTemplateClick() {
            window.open(process.env.BASE_URL + 'activityMerLimits.xlsx', '_blank');
        }


        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handleDrawerOpen() {
            if(check([this.PRIVILEGE.MER_SUBSIDY_ACT.RULE], this.privileges).length > 0) {
                return;
            }
            this.dispatch('search');
        }
    }

    export default Store;
