import {request} from '@/utils';
import { message } from 'ant-design-vue';

export async function transfer(params: {
    store_id: string;
    mobile: string;
}) {
    try {
        const ret = await request({
            api: '/console/store/transfer',
            data: params
        });
        message.success('已成功切换店主微信');
        return ret;
    } catch (e) {
        if (e.message === '网络错误') {
            message.error('网络错误，请稍后重试!');
        } else {
            message.error('转移失败，请检查手机号是否正确，或联系技术人员!');
        }
    }
}
