import {request,downloadRequest} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: string;
}) {

    try {
        return await request({
            api: '/console/store/sms/buying/record',
            data: {
                ...params,
                store_id: sessionStorage.getItem('store_id')
            }
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequest({
        data: params,
        prefix: 'order',
        api: '/console/order/list/excel'
    });
}