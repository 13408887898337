import config from '@/config';
import {request} from '@/utils';
import md5 from 'md5';

export async function updatePassword(params: any) {
    return await request({
        api: '/console/user/update/password',
        data: {
            password: md5(params.password + config.salt),
            new_password: md5(params.new_password + config.salt)
        },
        method: 'POST'
    });
}

/**
 * OSS 图片审核
 * @param url
 */
export async function checkPicture(url: string) {
    try {
        return await request({
            api: '/console/common/check/picture',
            data: {picture_url: url}
        });
    } catch (e) {
        const error: any = new Error('审核错误, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

/**
 * 营销数据
 * @param url
 */
export async function marketingDataSearch(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request({
            api: '/console/marketing/data/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取营销数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
