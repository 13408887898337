import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '对账ID',
        dataIndex: 'id',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '平台账单ID',
        dataIndex: 'actBillId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '平台账单类型',
        dataIndex: 'actBillType',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '营销活动ID',
        dataIndex: 'activityId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '账户流水ID',
        dataIndex: 'accountBillId',
        align: 'center',
        width: 220,
        customRender: defaultCustomRender
    },
    {
        title: 'order表id',
        dataIndex: 'orderId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '平台订单号',
        dataIndex: 'payTradeNo',
        align: 'center',
        width: 280,
        customRender: defaultCustomRender
    },
    {
        title: '对账结果状态明细',
        dataIndex: 'reconStatus',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '对账结果描述',
        dataIndex: 'reconMsg',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '交易日期',
        dataIndex: 'txnDate',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 200,
        fixed: 'right',
        align: 'center',
        scopedSlots: {
            customRender: 'action',
        }
    }
];
