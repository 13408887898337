var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: {
        color: "gray",
        "font-size": "50px",
        height: "300px",
        "font-weight": "bold",
        "padding-top": "100px",
      },
    },
    [
      _vm._v("\n    401 "),
      _c("span", { staticStyle: { "font-weight": "100" } }, [
        _vm._v("无权限访问"),
      ]),
      _c("br"),
      _c(
        "span",
        { staticStyle: { "font-size": "14px", "font-weight": "normal" } },
        [
          _vm._v("点击"),
          _c("a", { on: { click: _vm.handleClick } }, [_vm._v("返回")]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }