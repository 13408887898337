import {request, downloadRequest} from '@/utils';
import qs from 'querystringify';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/customer/user/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取惠码用户列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'customer',
        api: '/console/customer/list/excel'
    });
}

export async function openRefund(params: {
    first_name: 'False' | 'True'
}) {
    try {
        return await request({
            api: '/console/customer/user/list',
            data: qs.stringify(params),
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

