import {defaultCustomRender, priceCustomRender} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '会员手机号',
        dataIndex: 'mobile',
        align: 'center',
        width: 200,
        fixed: 'left',
        sorter: true,
        // customRender: defaultCustomRender
        scopedSlots: { customRender: 'mobile' }
    },
    {
        title: '会员注册时间',
        dataIndex: 'date_joined',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '会员卡号',
        dataIndex: 'username',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '消费笔数',
        dataIndex: 'consumption_number',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '总消费',
        dataIndex: 'pay_amount',
        width: 200,
        align: 'center',
        customRender: priceCustomRender
    },
    {
        title: '短信接收限制',
        dataIndex: 'weekly_limit',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '最后消费时间',
        dataIndex: 'trade_time',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'action' }
    }
];
