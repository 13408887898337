var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("TransactionFlowDrawer", {
        attrs: { store_id: _vm.transactionFlow, title: "店铺流水" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("transactionFlow")
          },
        },
      }),
      _c("ActivityDrawer", {
        attrs: { store_id: _vm.activity, title: "营销数据" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("activity")
          },
        },
      }),
      _c("MemberDrawer", {
        attrs: { store_id: _vm.member, title: "会员列表" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("member")
          },
        },
      }),
      _c("EmployeeDrawer", {
        attrs: { store_id: _vm.employee, title: "员工一览" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("employee")
          },
        },
      }),
      _c("SmsAccountDrawer", {
        attrs: { store_id: _vm.smsAccount, title: "短信账户" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("smsAccount")
          },
        },
      }),
      _c("InvoiceModal", {
        attrs: { merData: _vm.invioceRecord },
        on: {
          close: function ($event) {
            return _vm.handlInvoiceModalrClose("invioce")
          },
        },
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "shop-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.shops,
          bordered: "",
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined,
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "sn",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    width: "88%",
                    margin: "0 auto",
                    "text-align": "left",
                  },
                },
                [
                  !text
                    ? _c(
                        "div",
                        [
                          _c("span", { staticClass: "txt" }, [_vm._v("-")]),
                          _c("SnModal", {
                            attrs: {
                              store_id: record.id,
                              merchant_no: record.merchant_no,
                              offline_terminal_id: record.offline_terminal_id,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function (_) {
                                    return [
                                      _c(
                                        "a-button",
                                        { attrs: { size: "small" } },
                                        [_vm._v("查看")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _vm._l(text.split("-"), function (val, key) {
                            return _c(
                              "span",
                              { key: key, staticClass: "txt" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(val) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                          _c("SnModal", {
                            attrs: {
                              store_id: record.id,
                              merchant_no: record.merchant_no,
                              offline_terminal_id: record.offline_terminal_id,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function (_) {
                                    return [
                                      _c(
                                        "a-button",
                                        { attrs: { size: "small" } },
                                        [_vm._v("查看")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        2
                      ),
                ]
              )
            },
          },
          {
            key: "mobile",
            fn: function (text, record) {
              return _c("ClearText", {
                attrs: {
                  text: text,
                  action: "/console/store/get/mobile",
                  getClearText: (result) => result.mobile,
                  params: { store_id: record.id },
                },
              })
            },
          },
          {
            key: "phone",
            fn: function (text, record) {
              return _c("ClearText", {
                attrs: {
                  text: text,
                  action: "/console/store/get/mobile",
                  getClearText: (result) => result.phone,
                  params: { store_id: record.id },
                },
              })
            },
          },
          {
            key: "store_address",
            fn: function (text, record) {
              return _c("ClearText", {
                attrs: {
                  requires: [_vm.PRIVILEGE.STORE.ADDRESS],
                  text: text,
                  action: "/console/store/get/address",
                  getClearText: (result) => result.store_address,
                  params: { store_id: record.id },
                },
              })
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$router.push(`/console/shop/${record.id}`)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-dropdown",
                    { attrs: { placement: "bottomRight" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "ant-dropdown-link",
                          on: { click: (e) => e.preventDefault() },
                        },
                        [
                          _vm._v("\n                    更多 "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                      _c(
                        "a-menu",
                        { attrs: { slot: "overlay" }, slot: "overlay" },
                        [
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDrawerOpenClick(
                                        "transactionFlow",
                                        record.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("店铺流水")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDrawerOpenClick(
                                        "activity",
                                        record.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("营销数据")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDrawerOpenClick(
                                        "member",
                                        record.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("会员")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDrawerOpenClick(
                                        "employee",
                                        record
                                      )
                                    },
                                  },
                                },
                                [_vm._v("员工一览")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDrawerOpenClick(
                                        "smsAccount",
                                        record.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("短信账户")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOpenInvoiceAccount(
                                        record
                                      )
                                    },
                                  },
                                },
                                [_vm._v("开票账户")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }