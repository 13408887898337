
    import {Component, Vue, Provide} from "vue-property-decorator";
    import SideMenu from "@/components/SideMenu.vue";
    import config from "@/config";
    import {mapState} from "vuex";
    import UpdatePasswordModal from "./UpdatePasswordModal.vue";

    @Component({
        components: {
            SideMenu,
            UpdatePasswordModal
        },
        computed: {
            ...mapState({
                needLogin: (state: any) => state.login.needLogin
            })
        }
    })
    export default class Index extends Vue {
        public collapsed = false;
        public version = config.version;
        public contentScrollTop = 0;

        @Provide()
        public privileges() {
            return this.$store.state.login.session.permissions;
        }

        // public mounted() {
        //     this.contentScrollTop = (this.$refs.content as any).$el.scrollTop;
        // }

        public handleLogout() {
            this.$store.commit("login/clear");
            setTimeout(() => {
                this.$router.replace({
                    path: `/login`
                });
            }, 100);
        }
    }

    // export default Vue.extend({
    //     components: {
    //         SideMenu,
    //         // LoginModal
    //     },

    //     provide: {
    //         // privileges: [
    //         //     'district', 'mall', 'store', 'store-order', 'refund-batch-switch'
    //         // ]
    //         privileges() {
    //             return this.$store && this.$store.state.login.session.permissions;
    //         }
    //     },

    //     data() {
    //         return {
    //             version: config.version,
    //             // privileges: this.$store.state.login.session.permissions
    //         };
    //     },

    //     computed: {
    //         ...mapState({
    //             needLogin: (state: any) => state.login.needLogin
    //         })
    //     },

    //     methods: {
    //         handleLogout() {
    //             this.$store.commit('login/clear');
    //             setTimeout(() => {
    //                 this.$router.replace({
    //                     path: `/login`
    //                 });
    //             }, 100);
    //         },
    //     }
    // });
