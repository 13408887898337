import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-q-whitelist-jump-switch',
    options: [{
        label: '开',
        value: 'true'
    }, {
        label: '关',
        value: 'false'
    }]
});
