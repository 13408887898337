var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("a-range-picker", {
        staticStyle: { width: "100%" },
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          showTime: {
            format: "HH:mm:ss",
            defaultValue: [_vm.defaultStartTime, _vm.defaultEndTime],
          },
          format: "YYYY-MM-DD HH:mm:ss",
        },
        on: { change: _vm.handleChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }