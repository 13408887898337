var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Click", {
        attrs: { requires: _vm.roleName },
        on: { pass: _vm.stopActivities },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("暂停")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("a-button", { on: { click: _vm.DetailDownload } }, [
        _vm._v("\n        明细下载\n    "),
      ]),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      ),
      _c("ActivitiesStatusCount"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }