import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';

const route: RouteConfig[] = [
    {
        path: 'statistics-amount/search',
        name: '金额笔数统计',
        component: Index,
    },
    {
        path: 'statistics-coupon/search',
        name: '用券统计',
        component: Index,
    },
];

export default route;
