import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-shop-control-active-status',
    options: [{
        label: '开启短信',
        value: 'True'
    }, {
        label: '关闭短信',
        value: 'False'
    }]
});
