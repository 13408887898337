var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "活动ID" } },
            [
              _c("BankActivityInfo", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityId",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['activityId', {rules: [{ required: true, message: '请填写必填项!' }]}]",
                  },
                ],
                attrs: { type: 1 },
                on: { initFormData: _vm.initFormData },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityName",
                      { initialValue: _vm.formData.activityName },
                    ],
                    expression:
                      "['activityName',{initialValue: formData.activityName}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动有效期" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityTime",
                      { initialValue: _vm.formData.activityTime },
                    ],
                    expression:
                      "['activityTime',{initialValue: formData.activityTime}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleName",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'ruleName',\n                    {\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { maxLength: 64, placeholder: "请输入64个字以内" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "头寸编号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      { initialValue: _vm.formData.expenseAccount },
                    ],
                    expression:
                      "['expenseAccount',{initialValue: formData.expenseAccount}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动业主方" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ownerId", { initialValue: _vm.formData.ownerId }],
                    expression: "['ownerId',{initialValue: formData.ownerId}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "仅记账" } },
            [
              _c(
                "a-switch",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "accModeType",
                        {
                          valuePropName: "checked",
                          initialValue: false,
                          rules: [{ required: true }],
                        },
                      ],
                      expression:
                        "['accModeType', {valuePropName: 'checked',initialValue:false, rules: [{ required: true }]}]",
                    },
                  ],
                  ref: "",
                  on: { change: _vm.showAccMode },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "checkedChildren", type: "check" },
                    slot: "checkedChildren",
                  }),
                  _c("a-icon", {
                    attrs: { slot: "unCheckedChildren", type: "close" },
                    slot: "unCheckedChildren",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c("SubsidyRule", {
                ref: "ruleChild",
                attrs: {
                  accModeType: _vm.accModeType,
                  isDisabled: _vm.createFlag,
                  value: _vm.ruleListData,
                },
                on: {
                  "update:isDisabled": function ($event) {
                    _vm.createFlag = $event
                  },
                  "update:is-disabled": function ($event) {
                    _vm.createFlag = $event
                  },
                  "update:value": function ($event) {
                    _vm.ruleListData = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }