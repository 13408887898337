var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys || [],
            onChange: _vm.handleSelectChange,
          },
          columns: _vm.fields,
          dataSource: _vm.lists,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined,
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (value, record) {
              return [
                record.reconCode === "23" || record.reconCode === "12"
                  ? _c(
                      "span",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small", href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDrawerOpenClick(
                                  record.id,
                                  record.adjustFlag
                                )
                              },
                            },
                          },
                          [_vm._v("头寸人工调整")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }