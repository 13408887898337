
    import {Component, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {deleteEmpty} from '@/utils';
    import * as services from './services';
    import {SmsControlStatus} from '@/components/custom-form-components';
    import {Modal} from 'ant-design-vue';

    interface TransModal {
        form: any;
        loading: boolean;
        visible: boolean;
    }

    @Component({
        components: {
            Click,
            SmsControlStatus
        }
    })
    class TransModal extends Vue {
        public visible = false;
        public PRIVILEGE = PRIVILEGE;

        public dispatch!: any;
        public data() {
            return {
                loading: false,
                form: this.$form.createForm(this)
            };
        }

        public handleClose() {
            if (this.loading) {
                return;
            }
            this.visible = false;
        }

        public onClick() {
            if (this.loading) {
                return;
            }
            this.visible = true;
        }

        public handleSubmit(e: any) {
            e.preventDefault();
            this.form.validateFields(async (error: any, values: any) => {
                if (!error) {
                    if(values.sms_switch === 'True'){
                        Modal.confirm({
                            title: '提示',
                            content: '选择的商户将被限制单日短信发送数量，确定提交吗?',
                            okText: '确认',
                            cancelText: '取消',
                            onOk: () => {
                                this.loading = true;
                                this.form.validateFields(async (error: any, values: any) => {
                                    const batch_store_id  = this.$store.state.shopSearchPage.selectedRows;
                                    const all_store=0;
                                    const ret = await services.transfer(deleteEmpty({
                                        ...values,
                                        batch_store_id,
                                        all_store
                                    }));
                                    
                                    this.loading = false;
                                    
                                    this.$emit("success");
                                    this.visible = false;
                                    this.$store.dispatch('shopSearchPage/search');
                                });
                            }
                        });
                    }else{
                        Modal.confirm({
                            title: '提示',
                            content: '选择的商户将关闭接收短信，确定提交吗?',
                            okText: '确认',
                            cancelText: '取消',
                            onOk: () => {
                                this.loading = true;
                                this.form.validateFields(async (error: any, values: any) => {
                                    const batch_store_id  = this.$store.state.shopSearchPage.selectedRows;
                                    const all_store=0;
                                    const ret = await services.transfer(deleteEmpty({
                                        ...values,
                                        batch_store_id,
                                        all_store
                                    }));
                                    
                                    this.loading = false;
                                    this.$emit("success");
                                    this.visible = false;
                                    this.$store.dispatch('shopSearchPage/search');
                                });
                                
                            }
                        });
                    }
                    
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }

        public toUpdate(){

        }
    }

    export default TransModal;
