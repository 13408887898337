
    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import BulkSelector from '@/components/custom-form-components/BulkSelector/index.vue';
    import {request, deleteEmpty} from '@/utils';
    import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
    import * as DICTS from '@/common/dicts';
    @Component<BizDistrictPanel>({
        components: {
            BulkSelector,
            DistrictBelong
        }
    })
    class BizDistrictPanel extends Vue {

        @Prop()
        public adPlanId!: any;

        @Prop()
        public value!: any;

        public columns = [{
            title: '商圈名称',
            dataIndex: 'name'
        }, {
            title: '商圈归属',
            dataIndex: 'belong',
            customRender: (belong: string) => {
                return DICTS.BIZ_DISTRICT_BELONG[belong] || '-';
            }
        }];

        public loading = false;

        public query: any = {};

        public form: any;

        public created() {
            this.form = this.$form.createForm(this);
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                this.query = deleteEmpty({
                    ...values
                });
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }
    }

    export default BizDistrictPanel;
