var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("基本信息")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form-item",
            { attrs: { label: "政策" } },
            [
              _c("PolicyInfo", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "templetId",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['templetId', {rules: [{ required: true, message: '请填写必填项!' }]}]",
                  },
                ],
                on: { initFormData: _vm.initFormData },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        initialValue: _vm.formData.name,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'name',\n                    {\n                        initialValue: formData.name,\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { maxLength: 64, placeholder: "请输入64个字以内" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "类型" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "templetType",
                        {
                          initialValue: _vm.formData.templetType,
                          rules: [{ required: true, message: "类型不能为空" }],
                        },
                      ],
                      expression:
                        "[\n                    'templetType',\n                    {   initialValue: formData.templetType,\n                        rules: [\n                            { required: true, message: '类型不能为空' }\n                        ]\n                    },\n                ]",
                    },
                  ],
                  attrs: { disabled: true, placeholder: "请选择" },
                },
                [
                  _c("a-select-option", { attrs: { value: "OPEN_CARD" } }, [
                    _vm._v("开卡活动"),
                  ]),
                  _c("a-select-option", { attrs: { value: "MARKET_ACT" } }, [
                    _vm._v("营销活动"),
                  ]),
                  _c("a-select-option", { attrs: { value: "FEE_SUBSIDY" } }, [
                    _vm._v("手续费补贴"),
                  ]),
                  _c(
                    "a-select-option",
                    { attrs: { value: "DEPOSIT_REWARD" } },
                    [_vm._v("存款奖励")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c("DisableDatePicker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activity_time",
                      {
                        initialValue: _vm.formData.activity_time,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "['activity_time',{initialValue: formData.activity_time,rules: [{required: true, message: '请填写必填项'}]}]",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "APP展示银行" } },
            [
              _c("policyBank", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actBank",
                      {
                        initialValue: _vm.formData.actBank,
                        rules: [
                          { required: true, message: "APP展示银行不能为空" },
                        ],
                      },
                    ],
                    expression:
                      "['actBank', \n                    {\n                        initialValue: formData.actBank,\n                        rules: [{ required: true, message: 'APP展示银行不能为空' }\n                        ]\n                    }\n                ]\n                \n            ",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c("districtCodeTransfer", {
                attrs: { targetDistrictCodeData: _vm.districtCodeList },
                on: {
                  change: _vm.changeDistrictCodeData,
                  "update:targetDistrictCodeData": function ($event) {
                    _vm.districtCodeList = $event
                  },
                  "update:target-district-code-data": function ($event) {
                    _vm.districtCodeList = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动商户机构" } },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["orgLimit", { initialValue: "unlimited" }],
                      expression: "['orgLimit', { initialValue: 'unlimited' }]",
                    },
                  ],
                  on: { change: _vm.showOrgLimit },
                },
                [
                  _c("a-radio", { attrs: { value: "unlimited" } }, [
                    _vm._v("无限制"),
                  ]),
                  _c("a-radio", { attrs: { value: "whiteList" } }, [
                    _vm._v("白名单"),
                  ]),
                  _c("a-radio", { attrs: { value: "blackList" } }, [
                    _vm._v("黑名单"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.orgLimit != "unlimited"
            ? _c(
                "a-form-item",
                { attrs: { label: "" } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "orgLimitValue",
                          {
                            initialValue: _vm.formData.orgLimitValue,
                            rules: [
                              {
                                required: _vm.orgLimit != "unlimited",
                                message: "请填写必填项!",
                              },
                              {
                                pattern: /^.{0,500}$/,
                                message: "必填，限制500字",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n                    'orgLimitValue',\n                    {   initialValue: formData.orgLimitValue,\n                        rules: [\n                            {required: orgLimit != 'unlimited', message: '请填写必填项!'},\n                            {pattern: /^.{0,500}$/, message: '必填，限制500字'}\n                        ]\n                    }\n                ]",
                      },
                    ],
                    staticStyle: { width: "80%", "margin-left": "98px" },
                    attrs: { autosize: { minRows: 2, maxRows: 6 } },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "MCC限制" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merMcc",
                      {
                        initialValue: _vm.formData.merMcc,
                        rules: [
                          { pattern: /^.{0,60}$/, message: "必填，限制60字" },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'merMcc',\n                    {initialValue: formData.merMcc,\n                        rules: [\n                            {pattern: /^.{0,60}$/, message: '必填，限制60字'}\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: {
                  placeholder: "请输入",
                  autosize: { minRows: 5, maxRows: 10 },
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "业主方" } },
            [
              _c("OwnerNew", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ownerId",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['ownerId', {rules: [{ required: true, message: '请填写必填项!' }]}]",
                  },
                ],
                on: { initAccountData: _vm.initAccountData },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "头寸编号" } },
            [
              _c("AccountInfoNew", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['expenseAccount', {rules: [{ required: true, message: '请填写必填项!' }]}]",
                  },
                ],
                attrs: { accountList: _vm.accountList },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动详情" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "description",
                      {
                        initialValue: _vm.formData.description,
                        rules: [
                          { required: true, message: "活动详情不能为空" },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'description',\n                    {   initialValue: formData.description,\n                        rules: [{ required: true, message: '活动详情不能为空' }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: {
                  placeholder: "请输入",
                  autosize: { minRows: 5, maxRows: 10 },
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动图片" } },
            [
              _c("ImageUpload", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "imgUrl",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['imgUrl', {rules: [{required: true, message: '请填写必填项!'}]}]",
                  },
                ],
                ref: "imgUrlChild",
                attrs: { imageWidth: 686, imageHeight: 176, imageMaxSize: 2 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否报名" } },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "signUp",
                        {
                          initialValue: _vm.formData.signUp,
                          rules: [
                            {
                              required: true,
                              message: "请选择必填项",
                            },
                          ],
                        },
                      ],
                      expression:
                        "[\n                    'signUp',\n                    {   initialValue: formData.signUp,\n                        rules: [\n                            {\n                                required: true,\n                                message: '请选择必填项',\n                            },\n                        ]\n                    },\n                ]",
                    },
                  ],
                },
                [
                  _c("a-radio", { attrs: { value: "0" } }, [_vm._v(" 否 ")]),
                  _c("a-radio", { attrs: { value: "1" } }, [_vm._v(" 是 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动优先级" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityLevel",
                      {
                        initialValue: "0",
                        rules: [
                          {
                            pattern: /^[0-9]$|^[1-5][0-9]$|60$/,
                            message: "仅支持0-60数字范围",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'activityLevel',\n                    {\n                        initialValue: '0',\n                        rules: [\n                            {pattern: /^[0-9]$|^[1-5][0-9]$|60$/, message: '仅支持0-60数字范围'}\n                        ]\n                    }\n                ]",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否开启结算周期补贴判断" } },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "subsidyCycleBySettleMode",
                        { initialValue: _vm.formData.subsidyCycleBySettleMode },
                      ],
                      expression:
                        "[\n                    'subsidyCycleBySettleMode',\n                    { initialValue: formData.subsidyCycleBySettleMode },\n                ]",
                    },
                  ],
                },
                [
                  _c("a-radio", { attrs: { value: "0" } }, [_vm._v(" 否 ")]),
                  _c("a-radio", { attrs: { value: "1" } }, [_vm._v(" 是 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.getFieldValue("subsidyCycleBySettleMode") == 1
            ? _c(
                "a-form-item",
                { attrs: { label: "D1提前结束补贴天数" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "subsidyCycleBySettleModeValue",
                          {
                            initialValue: 1,
                            rules: [
                              {
                                required: true,
                                message: "D1提前结束补贴天数不能为空",
                              },
                              {
                                pattern: /^(?!0$)\d+$/,
                                message: "仅支持大于0的正整数",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n                    'subsidyCycleBySettleModeValue',\n                    {\n                        initialValue: 1,\n                        rules: [\n                            { required: true, message: 'D1提前结束补贴天数不能为空' },\n                            { pattern: /^(?!0$)\\d+$/, message: '仅支持大于0的正整数' }\n                        ]\n                    }\n                ]",
                      },
                    ],
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("其他规则")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form",
            { staticClass: "mall-create-form", attrs: { form: _vm.otherForm } },
            _vm._l(_vm.configData, function (item, index) {
              return _c(
                "span",
                { key: index },
                [
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !item.disabled || _vm.validObj[item.key] == "Y",
                          expression:
                            "!item.disabled || validObj[item.key] == 'Y'",
                        },
                      ],
                      attrs: { label: item.label },
                    },
                    [
                      item.type == null
                        ? _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  {
                                    initialValue: _vm.formData[item.key],
                                    rules: [
                                      {
                                        required: _vm.validObj[item.key] == "Y",
                                        message: item.label + "不能为空",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                            item.key,\n                            {   initialValue: formData[item.key],\n                                rules: [\n                                { required: validObj[item.key] == 'Y', message: item.label + '不能为空' }\n                                ]\n                            }\n                            ]",
                              },
                            ],
                          })
                        : _vm._e(),
                      item.type == "radio"
                        ? _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    item.key,
                                    {
                                      initialValue: _vm.formData[item.key] + "",
                                    },
                                  ],
                                  expression:
                                    "[item.key,{initialValue: formData[item.key]+''}]",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  return _vm.changeRadioCheck(
                                    $event,
                                    item.options
                                  )
                                },
                              },
                            },
                            _vm._l(item.options, function (option, index) {
                              return _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "a-radio",
                                    {
                                      attrs: {
                                        value: option.value,
                                        checked: true,
                                      },
                                    },
                                    [_vm._v(_vm._s(option.label))]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      item.type == "checkbox"
                        ? _c(
                            "a-checkbox-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    item.key,
                                    {
                                      initialValue: _vm.formData[item.key] + "",
                                    },
                                  ],
                                  expression:
                                    "[item.key,{initialValue: formData[item.key] +''}]",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  return _vm.changeCheck($event, item.options)
                                },
                              },
                            },
                            [
                              _c(
                                "a-row",
                                _vm._l(item.options, function (option, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: option.value } },
                                        [_vm._v(_vm._s(option.label))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type == "image"
                        ? _c("ImageUpload", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [item.key],
                                expression: "[item.key]",
                              },
                            ],
                            ref: item.key,
                            refInFor: true,
                            attrs: {
                              imageMaxSize: item.imageMaxSize,
                              imageWidth: item.imageWidth,
                              imageHeight: item.imageHeight,
                            },
                          })
                        : _vm._e(),
                      item.type == "textarea"
                        ? _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  {
                                    initialValue: _vm.formData[item.key],
                                    rules: [
                                      {
                                        required: _vm.validObj[item.key] == "Y",
                                        message: item.label + "不能为空",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                        item.key,\n                        {   initialValue: formData[item.key],\n                            rules: [\n                            { required: validObj[item.key] == 'Y', message: item.label + '不能为空' }\n                            ]\n                        }\n                        ]",
                              },
                            ],
                            attrs: { autosize: { minRows: 2, maxRows: 6 } },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }