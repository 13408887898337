import * as services from './services';
import PhoneCode from '@/components/custom-form-components/PhoneCode.vue';

export default {
    namespaced: true,
    state: {
        session: {
            permissions: []
        },
        ing: false,
        error: null,
        redirect: null,
        needLogin: false,

        // reseting: false,
        // resetError: null
    },
    mutations: {

        needLogin(state: any) {
            state.needLogin = true;
        },

        // resetPasswordRequested(state: any) {
        //     state.reseting = true;
        // },

        // resetPasswordSuccessed(state: any) {
        //     state.reseting = false;
        // },
        // resetPasswordFailed(state: any, e: any) {
        //     state.reseting = false;
        //     state.resetError = e;
        // },

        loginRequested(state: any) {
            state.ing = true;
        },

        loginSuccessed(state: any, payload: any) {
            state.ing = false;
            state.session = {...payload};
            state.needLogin = false;
            window.localStorage.setItem('session', JSON.stringify(payload));
        },

        loginFailed(state: any, e: any) {
            state.ing = false;
            state.error = e;
        },

        autoLogin(state: any, {session, from}: any) {
            state.redirect = from;
            state.ing = false;
            state.needLogin = false;
            state.session = {...session};
            window.localStorage.setItem('session', JSON.stringify(session));
        },

        clear(state: any) {
            state.session = {};
            state.redirect = null;
            state.error = null;
            state.ing = false;
            window.localStorage.removeItem('session');
            (PhoneCode as any).clearStorage();
            // window.localStorage.removeItem('code_seconds_login');
            // window.localStorage.removeItem('code_seconds_reset_password');
            window.location.reload();
        }
    },
    actions: {
        async login(context: any, payload: any) {
            context.commit('loginRequested');
            try {
                const ret = await services.login(payload);
                if (ret.is_superuser) {
                    ret.permissions = ['__SUPER__'];
                }
                context.commit('loginSuccessed', ret);
            } catch (e) {
                context.commit('loginFailed', e);
            }
        },
        // async resetPassword(context: any, payload: any) {
        //     context.commit('resetPasswordRequested');
        //     try {
        //         const ret = await services.resetPassword(payload);
        //         context.commit('resetPasswordSuccessed', ret);
        //     } catch (e) {
        //         context.commit('resetPasswordFailed', e);
        //     }
        // }
    }
};
