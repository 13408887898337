var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        { staticClass: "mall-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "活动ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityId",
                      { initialValue: _vm.formData.activityId },
                    ],
                    expression:
                      "[ 'activityId',{initialValue: formData.activityId}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ruleId", { initialValue: _vm.formData.ruleId }],
                    expression: "[ 'ruleId',{initialValue: formData.ruleId}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        initialValue: _vm.formData.name,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'name',\n                    {\n                        initialValue: formData.name,\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: {
                  disabled: true,
                  maxLength: 64,
                  placeholder: "请输入64个字以内",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则有效期" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleTime",
                      {
                        initialValue:
                          null == _vm.formData.startTime
                            ? ""
                            : _vm.formData.startTime +
                              "—" +
                              _vm.formData.endTime,
                      },
                    ],
                    expression:
                      "[\n                    'ruleTime',\n                    {\n                        initialValue: null == formData.startTime ?'':formData.startTime +'—'+formData.endTime\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "-" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "头寸编号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      { initialValue: _vm.formData.expenseAccount },
                    ],
                    expression:
                      "[ 'expenseAccount',{initialValue: formData.expenseAccount}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动业主方" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ownerId", { initialValue: _vm.formData.ownerId }],
                    expression: "[ 'ownerId',{initialValue: formData.ownerId}]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "仅记账" } },
            [
              _c(
                "a-switch",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "accModeType",
                        {
                          valuePropName: "checked",
                          initialValue: _vm.formData.accModeType,
                          rules: [{ required: true }],
                        },
                      ],
                      expression:
                        "['accModeType', {valuePropName: 'checked',initialValue:formData.accModeType, rules: [{ required: true }]}]",
                    },
                  ],
                  attrs: { disabled: true },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "checkedChildren", type: "check" },
                    slot: "checkedChildren",
                  }),
                  _c("a-icon", {
                    attrs: { slot: "unCheckedChildren", type: "close" },
                    slot: "unCheckedChildren",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.isNewRule == "1",
                  expression: "formData.isNewRule == '1'",
                },
              ],
              attrs: { label: "补贴类型", required: "" },
            },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "subsidyType",
                        { initialValue: _vm.formData.subsidyType },
                      ],
                      expression:
                        "['subsidyType', {initialValue: formData.subsidyType}]",
                    },
                  ],
                  attrs: { disabled: true },
                },
                [
                  _c("a-radio", { attrs: { value: "1", checked: true } }, [
                    _vm._v("固定补贴后费率"),
                  ]),
                  _c("a-radio", { attrs: { value: "2", checked: true } }, [
                    _vm._v("固定补贴比例"),
                  ]),
                  _c("a-radio", { attrs: { value: "3", checked: true } }, [
                    _vm._v("固定金额补贴"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.isNewRule == "1",
                  expression: "formData.isNewRule == '1'",
                },
              ],
            },
            [
              _c("FixedRate", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fixedRateShow,
                    expression: "fixedRateShow",
                  },
                ],
                ref: "rateChild",
                attrs: {
                  accModeType: _vm.accModeType,
                  isDisabled: _vm.flag,
                  value: _vm.rateListData,
                },
                on: {
                  "update:isDisabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:is-disabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:value": function ($event) {
                    _vm.rateListData = $event
                  },
                },
              }),
              _c("FixedRatio", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fixedRatioShow,
                    expression: "fixedRatioShow",
                  },
                ],
                ref: "ratioChild",
                attrs: {
                  accModeType: _vm.accModeType,
                  isDisabled: _vm.flag,
                  value: _vm.ratioListData,
                },
                on: {
                  "update:isDisabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:is-disabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:value": function ($event) {
                    _vm.ratioListData = $event
                  },
                },
              }),
              _c("FixedAmount", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fixedAmountShow,
                    expression: "fixedAmountShow",
                  },
                ],
                ref: "amountChild",
                attrs: { isDisabled: _vm.flag, value: _vm.amountListData },
                on: {
                  "update:isDisabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:is-disabled": function ($event) {
                    _vm.flag = $event
                  },
                  "update:value": function ($event) {
                    _vm.amountListData = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.isNewRule == "0",
                  expression: "formData.isNewRule == '0'",
                },
              ],
            },
            [
              _c("SubsidyRule", {
                ref: "ruleChild",
                attrs: {
                  accModeType: _vm.accModeType,
                  isDisabled: _vm.createFlag,
                  value: _vm.ruleListData,
                },
                on: {
                  "update:isDisabled": function ($event) {
                    _vm.createFlag = $event
                  },
                  "update:is-disabled": function ($event) {
                    _vm.createFlag = $event
                  },
                  "update:value": function ($event) {
                    _vm.ruleListData = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.isNewRule == "1",
                  expression: "formData.isNewRule == '1'",
                },
              ],
              attrs: { label: "交易类型" },
            },
            [
              _c(
                "a-checkbox-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "payMode",
                        {
                          initialValue: _vm.formData.payMode,
                          rules: [{ required: true, message: "请填写必填项" }],
                        },
                      ],
                      expression:
                        "['payMode',{initialValue: formData.payMode,rules: [{required: true, message: '请填写必填项'}]}]",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { disabled: true },
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c("a-checkbox", { attrs: { value: "02" } }, [
                            _vm._v("微信"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("a-checkbox", { attrs: { value: "03" } }, [
                            _vm._v("支付宝"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c("a-checkbox", { attrs: { value: "81" } }, [
                            _vm._v("银联二维码借记"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-top": "6px" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c("a-checkbox", { attrs: { value: "82" } }, [
                            _vm._v("银联二维码贷记"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("a-checkbox", { attrs: { value: "00" } }, [
                            _vm._v("刷卡借记"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("a-checkbox", { attrs: { value: "01" } }, [
                            _vm._v("刷卡贷记"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-top": "6px" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c("a-checkbox", { attrs: { value: "05" } }, [
                            _vm._v("外卡DCC"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("a-checkbox", { attrs: { value: "06" } }, [
                            _vm._v("外卡EDC"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }