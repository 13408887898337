import {RouteConfig} from 'vue-router';
import Search from './SearchPage/AuthWrapper.vue';
import Edit from './EditPage/AuthWrapper.vue';

const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    }, {
        path: ':id',
        name: '编辑商户',
        component: Edit,
    }
];

export default route;
