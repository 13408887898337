var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "order-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.orders,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined,
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "mobile",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  record.customer
                    ? _c("ClearText", {
                        attrs: {
                          text: text,
                          action: "/console/user/mobile",
                          getClearText: (result) => result.mobile,
                          params: { customer_id: record.customer.id },
                        },
                      })
                    : _c("span", [_vm._v("-")]),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }