import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-q-whitelist-machines-are-whitelisted',
    options: [{
        label: '是',
        value: 'True'
    }, {
        label: '否',
        value: 'False'
    }]
});
