var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "ad-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "默认广告" } },
            [
              _c(
                "a-switch",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "isDefault",
                        {
                          valuePropName: "checked",
                          initialValue: false,
                          rules: [
                            {
                              required: true,
                            },
                          ],
                        },
                      ],
                      expression:
                        "['isDefault', {\n                valuePropName: 'checked',\n                initialValue: false,\n                rules: [{\n                    required: true\n                }]\n            }]",
                    },
                  ],
                  attrs: { disabled: _vm.formDisabled },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "checkedChildren", type: "check" },
                    slot: "checkedChildren",
                  }),
                  _c("a-icon", {
                    attrs: { slot: "unCheckedChildren", type: "close" },
                    slot: "unCheckedChildren",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "广告名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "title",
                      { rules: [{ required: true, message: "请填写必填项" }] },
                    ],
                    expression:
                      "[\n                    'title',\n                    {rules: [{required: true, message: '请填写必填项'}]}\n                ]",
                  },
                ],
                attrs: { disabled: _vm.formDisabled, placeholder: "请输入" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "上架时间" } },
            [
              _c("SchedulePicker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "schedule",
                      {
                        rules: [
                          {
                            required: true,
                            message: "请填写必填项",
                          },
                          {
                            validator: _vm.validators.scheduleValidator,
                          },
                        ],
                      },
                    ],
                    expression:
                      "['schedule', {\n                rules: [{\n                    required: true,\n                    message: '请填写必填项'\n                },\n                {\n                    validator: validators.scheduleValidator\n                }]\n            }]",
                  },
                ],
                attrs: { disabled: _vm.formDisabled },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "广告位置" } },
            [
              _c("AdPositionSelector", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "position",
                      { rules: [{ required: true, message: "请填写必填项" }] },
                    ],
                    expression:
                      "['position', \n                {rules: [{required: true, message: '请填写必填项'}]}\n            ]",
                  },
                ],
                attrs: {
                  disabledOptions: _vm.disabledPositions,
                  disabled: _vm.formDisabled,
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "展现方式" } },
            [
              _c("AdMaterialTplSelector", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "materialTpl",
                      { rules: [{ required: true, message: "请填写必填项" }] },
                    ],
                    expression:
                      "['materialTpl', \n                {rules: [{required: true, message: '请填写必填项'}]}\n            ]",
                  },
                ],
                attrs: { disabled: _vm.formDisabled },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "广告物料" } },
            [
              _c("AdMaterialList", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "material_content_terminal_list",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            validator: _vm.validators.materialListValidator(
                              _vm.tplClass
                            ),
                          },
                        ],
                      },
                    ],
                    expression:
                      "['material_content_terminal_list', \n                    {rules: [\n                    {required: true, message: '请填写必填项'},\n                    {\n                        validator: validators.materialListValidator(tplClass)\n                    }\n                    ]}\n                ]",
                  },
                ],
                attrs: {
                  tplClass: _vm.tplClass,
                  disabled: !_vm.tplClass || _vm.formDisabled,
                  max: _vm.tplClassMaxNumber,
                },
              }),
            ],
            1
          ),
          !_vm.isDefault
            ? _c(
                "a-form-item",
                { attrs: { label: "分配" } },
                [
                  _c("AdAssign", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "assign",
                          {
                            rules: [
                              {
                                required: true,
                                message: "请填写必填项",
                              },
                              {
                                validator: _vm.validators.assignValidator,
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n                    'assign',\n                    {\n                        rules: [{\n                            required: true,\n                            message: '请填写必填项'\n                        }, {\n                            validator: validators.assignValidator\n                        }]\n                    }\n                ]",
                      },
                    ],
                    attrs: { disabled: _vm.formDisabled },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        disabled: _vm.formDisabled,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.formDisabled },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AdAssignProgress", { ref: "assign" }),
      _c("AdConflictModal", { ref: "conflict" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }