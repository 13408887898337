var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "role-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.columns,
          dataSource: _vm.roles,
          scroll: {
            x: _vm.calcTableScrollX(_vm.columns, 62),
            y: _vm.fixed || undefined,
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c("PrivilegeAssignModal", {
                    attrs: {
                      roleId: record.id,
                      privileges: _vm.privileges,
                      ownPrivileges: record.permissions,
                    },
                    on: { success: _vm.handleAssignSuccess },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function (slotProps) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    size: "small",
                                    href: "javascript:;",
                                  },
                                },
                                [_vm._v("分配权限")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.handleDeleteConfirm(record.id)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }