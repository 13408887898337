import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-zhongxin-control-valid-type',
    options: [{
        label: '绝对时效',
        value: 'ABSOLUTE'
    }, {
        label: '相对时效',
        value: 'RELATIVE'
    }]
});
