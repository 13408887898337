import * as services from './services';
import {getConflictDefaultAdPlan} from '../services';
import {Modal} from 'ant-design-vue';
import {flatAndUniqueMaterialsTerminals} from '@/utils';

export default {
    namespaced: true,
    state: {
        id: null,
        loading: false,
        error: null,
        defaultConflicts: []
    },
    mutations: {

        init(state: any) {
            state.id = null;
            state.loading = false;
            state.error = null;
        },
        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
        created(state: any, id: string) {
            state.id = id;
        }
    },
    actions: {
        /**
         * 新增广告计划
         * @param context action上下文
         */
        async create(context: any, payload: any) {
            context.commit('setLoading', true);

            if (payload.is_default) {
                const conflicts: any[] = await getConflictDefaultAdPlan({
                    terminals: flatAndUniqueMaterialsTerminals(payload.materials_terminals),
                    position: payload.position
                });
                if (conflicts.length > 0) {
                    Modal.error({
                        title: '冲突提示',
                        content: '当前广告位与其他默认广告存在冲突，请先下架或删除冲突广告。'
                    });
                    context.commit('setLoading', false);
                    return;
                }
            }
            try {
                context.commit('created', await services.create({
                    ...payload
                }));
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        }
    },
    getters: {

    }
};
