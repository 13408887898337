import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=6ae7251d&scoped=true"
import script from "./Detail.vue?vue&type=script&lang=js"
export * from "./Detail.vue?vue&type=script&lang=js"
import style0 from "./Detail.vue?vue&type=style&index=0&id=6ae7251d&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae7251d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/lakala/Desktop/LKL/lkl-zf-ss-hui-pam-mgt-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ae7251d')) {
      api.createRecord('6ae7251d', component.options)
    } else {
      api.reload('6ae7251d', component.options)
    }
    module.hot.accept("./Detail.vue?vue&type=template&id=6ae7251d&scoped=true", function () {
      api.rerender('6ae7251d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Console/BankActivities/actShenhe/SearchPage/Detail.vue"
export default component.exports