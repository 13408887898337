
    import {mapState} from 'vuex';
    import {Component, Inject,Watch, Vue} from 'vue-property-decorator';
    import CreateModal from './CreateModal.vue';
    import PRIVILEGE from '@/common/privileges';
    import HexiaoUpload from './HexiaoUpload.vue';
    import {Click} from '@/components/Auth';
    interface Actions {
        hideMessage: any;
    }
    @Component({
        components: {
            Click,
            HexiaoUpload,
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.hexiaoUnionManageSearchPage.loading,
                downloading: (state: any) => state.hexiaoUnionManageSearchPage.downloading,
            })
        }
    })
    class Actions extends Vue {

        @Inject()
        public dispatch!: any;

        @Inject()
        public state!: any;
        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }
        public handleCreateSuccess() {
            this.dispatch('search');
        }
        public mounted() {
            console.log(this.$store)
        }
        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }
        public handleRefresh() {
            this.dispatch('search');
        }
        @Watch('$store.state.hexiaoUnionManageSearchPage.downloading')
        public hexiaoDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }
        @Watch('$store.state.hexiaoUnionManageSearchPage.downloadError')
        public hexiaoDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.hexiaoUnionManageSearchPage.lastDownloadTime')
        public hexiaoDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }
        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('hexiaoUnionManageSearchPage/download');
        }
    }

    export default Actions;
