
import { Modal } from 'ant-design-vue';
import { mapState } from 'vuex';
import StoreChangeModal from './StoreChangeModal.vue';
import columns from './columns';
import columnsUnion from './columnsUnion';
import {
    Component,
    Prop,
    Watch,
    Provide,
    Vue,
    Inject
} from 'vue-property-decorator';
import * as Constant from '@/common/constant';
import * as services from './services';
import { check } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import ClearText from '@/components/ClearText.vue';
import DetailDrawer from '../../Draft/DetailPage/index.vue';
import DetailDrawerUnion from '../../Draft/DetailPageUnion/index.vue';

interface SearchTable {
    form: any;
}
@Component({
    components: {
        StoreChangeModal,
        ClearText,
        DetailDrawerUnion,
        DetailDrawer
    },
    computed: {
        // rowSelection() {
        //     const self: any = this;
        //     const {selectedRowKeys} = self;
        //     return {
        //         selectedRowKeys,
        //         onChange: self.onSelectChange,
        //         hideDefaultSelections: true
        //     };
        // },
        ...mapState({
            lists: (state: any) =>
                state.activitiesOpenSearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.activitiesOpenSearchPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.activitiesOpenSearchPage.total,
            pageSize: (state: any) => state.activitiesOpenSearchPage.pageSize,
            page: (state: any) => state.activitiesOpenSearchPage.page,
            loading: (state: any) => state.activitiesOpenSearchPage.loading,
            selectedRowKeys: (state: any) =>
                state.activitiesOpenSearchPage.selectedRows,
            fields: (state: any) => {
                return normColumns(
                    state.activityManage.payType == Constant.WX_TYPE
                        ? columns
                        : columnsUnion,
                    state.activitiesOpenSearchPage.fields,
                    state.activitiesOpenSearchPage.sortFields
                );
            }
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    public currentColumns = columnsUnion;
    @Prop()
    public paytype!: String;
    public currentPaytype!: String;

    @Inject()
    public routerPush!: any;
    public detail = null;
    public detailUnion = null;
    public editAct = null;
    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit(
            'activitiesOpenSearchPage/setSelectedRows',
            selectedRowKeys
        );
        (this as any).selectedRowKeys = selectedRowKeys;
    }
    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('activitiesOpenSearchPage/setSelectedRows', [
            ...selectedRowKeys
        ]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', { page });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', { pageSize, page: 1 });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }
    public handleDrawerOpenClick(type: 'detail', id: any) {
        console.log(id);
        this[type] = id;
        console.log(this[type]);
    }
    public created() {
        this.currentPaytype = this.$store.state.activityManage.payType
        
    }
    @Watch('$store.state.activityManage.payType') 
    public payTypeChange(newVal: String, oldVal: String) {
        
        this.currentPaytype = this.$store.state.activityManage.payType
    }

    public handleDrawerClose(type: 'detail'|'editAct') {
        this[type] = null;
    }
}

export default SearchTable;
