var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("a-table", {
    attrs: {
      size: "small",
      columns: _vm.columns,
      dataSource: _vm.list,
      rowKey: _vm.rowKey,
      bordered: "",
      rowSelection: {
        selectedRowKeys: _vm.value || [],
        onChange: _vm.handleSelectChange,
      },
      locale: {
        emptyText: _vm.loading ? "加载中..." : "无数据",
      },
      pagination: {
        total: _vm.total,
        pageSize: _vm.pageSize,
        current: _vm.page || 1,
        showSizeChanger: true,
        pageSizeOptions: ["20", "50", "100"],
        showQuickJumper: true,
        showTotal: (total) => `总计 ${total} 条记录`,
        onChange: _vm.handlePageChange,
        onShowSizeChange: _vm.handlePageSizeChange,
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }