import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';
const route: RouteConfig[] = [
    {
        path: 'searchPage',
        name: '核销微信数据',
        component: Index,
    },
    {
        path: 'searchUnionPage',
        name: '核销银联数据',
        component: Index,
    }

    
];

export default route;
