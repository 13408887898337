import {defaultCustomRender} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center'
    },
    {
        title: '模块',
        dataIndex: 'module',
        fixed: 'left',
        width: 120,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '参数',
        dataIndex: 'argument',
        align: 'center',
        width: 200,
        scopedSlots: {customRender: 'argument'},
    },
    {
        title: 'IP地址',
        dataIndex: 'ip',
        align: 'center',
        width: 180,
        customRender: defaultCustomRender
    },
    {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        width: 120,
        customRender: defaultCustomRender
    },

    {
        title: '操作者',
        dataIndex: 'user',
        align: 'center',
        width: 120,
        customRender: defaultCustomRender
    },
    {
        title: '操作者角色',
        dataIndex: 'role',
        align: 'center',
        width: 120,
        customRender: defaultCustomRender
    },
    {
        title: '操作时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    blankCell
];
