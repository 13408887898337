import {zeroCustomRender} from '@/utils';

export default [
    // {
    //     dataIndex: 'pay_type',
    //     title: '支付方式',
    //     align: 'center',
    //     width: 75
    // },
    {
        dataIndex: 'pay_type',
        title: '钱包类型',
        align: 'center',
        width: 75,
        // customRender: zeroCustomRender
        customRender(value:any, row:any, index:any) {
            const obj = {
                children: value,
                attrs: row 
            }
            if(index == 0) {
                obj.attrs.rowSpan = 2
            }
            if (index === 1) {
              obj.attrs.rowSpan = 0;
            }
            if (index === 2) {
              obj.attrs.rowSpan = 2;
            }
            if(index === 3) {
                obj.attrs.rowSpan = 0
            }
            return obj;
        }
    },
    {
        dataIndex: 'carrier',
        title: '载体',
        align: 'center',    
        width: 75,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'today_pay_amount',
        title: '今日实收',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'yesterday_pay_amount',
        title: '昨日实收',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'seven_days_pay_amount',
        title: '最近7天实收',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'thirty_days_pay_amount',
        title: '最近30天实收',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'today_consume_count',
        title: '今日收款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'today_refund_count',
        title: '今日退款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'yesterday_consume_count',
        title: '昨日收款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'yesterday_refund_count',
        title: '昨日退款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'seven_days_consume_count',
        title: '最近7天笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'seven_days_refund_count',
        title: '最近7天退款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'thirty_days_consume_count',
        title: '最近30天笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    },
    {
        dataIndex: 'thirty_days_refund_count',
        title: '最近30天退款笔数',
        align: 'right',
        width: 135,
        customRender: zeroCustomRender
    }
];
