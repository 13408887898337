var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("a-transfer", {
        staticClass: "tree-transfer",
        attrs: {
          "data-source": _vm.dataSource,
          "target-keys": _vm.targetKeys,
          disabled: _vm.disabled && _vm.dataSource,
          render: (item) => item.title,
          "show-select-all": false,
        },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "children",
            fn: function ({
              props: { direction, selectedKeys },
              on: { itemSelect },
            }) {
              return [
                direction === "left"
                  ? _c("a-tree", {
                      staticClass: "resetATree",
                      attrs: {
                        height: "200",
                        blockNode: "",
                        checkable: "",
                        checkStrictly: "",
                        checkedKeys: [...selectedKeys, ..._vm.targetKeys],
                        treeData: _vm.treeDataInfo,
                      },
                      on: {
                        check: (_, props) => {
                          _vm.onChecked(
                            _,
                            props,
                            [...selectedKeys, ..._vm.targetKeys],
                            itemSelect
                          )
                        },
                        select: (_, props) => {
                          _vm.onChecked(
                            _,
                            props,
                            [...selectedKeys, ..._vm.targetKeys],
                            itemSelect
                          )
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }