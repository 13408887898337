import { create } from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import { deleteEmpty } from '@/utils';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        const pageQuery = {
            pageIndex: page,
            pageSize: pageSize
        }
        return services.search({
            ...query,
            pageQuery
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns,
    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
},
    {
        state: {
            activityDetailList: [],
            selectedRows: [],
            error: '',
            sortFields: preferences.sortFields,
        },
        mutations: {
            setActivityDetailList(state: any, payload: any) {
                state.activityDetailList = payload;
            },
            setSelectedRows(state: any, rows: any) {
                state.selectedRows = rows;
            },
            setError(state: any, error: any) {
                state.error = error;
            }
        },

    });
