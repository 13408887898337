export default [
    {
        title: '任务编号',
        dataIndex: 'id',
        align: 'center',
        width: 200
    },
    {
        title: '时间',
        dataIndex: 'create_time',
        align: 'center',
        width: 200
    },
    {
        title: '数量',
        dataIndex: 'count',
        align: 'center',
        width: 100
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        align: 'center'
    },
    {
        title: '成功',
        dataIndex: 'success_count',
        width: 100,
        align: 'center'
    },
    {
        title: '失败',
        dataIndex: 'fail_count',
        width: 100,
        align: 'center'
    },
    {
        title: '操作',
        dataIndex: 'op',
        width: 230,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
