import blankCell from '@/common/blankCell';
import {
    defaultCustomRender,timeCustomRender
} from '@/utils';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '数据日期',
        dataIndex: 'taskDate',
        width: 200,
        fixed: 'left',
        align: 'center',
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '批类型',
        dataIndex: 'taskType',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '批处理状态',
        dataIndex: 'taskStatus',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '创建日期',
        dataIndex: 'createdTime',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '更新人',
        dataIndex: 'modifiedBy',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '更新日期',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '备注',
        dataIndex: 'remark',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 200,
        fixed: 'right',
        align: 'center',
        scopedSlots: {
            customRender: 'action',
        }
    }
];
