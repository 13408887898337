import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';


const route: RouteConfig[] = [
   
    {
        path: 'operate',
        name: '对账手工操作',
        component: Index,
    },
    {
        path: 'info',
        name: '对账表信息',
        component: Index,
    },
    
    
];

export default route;
