
<template>
    <a-form :form="form">
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="政策id"
      >
        <a-input
          v-decorator="[
            'templetId',
            { rules: [{ required: true, message: '请输入政策id' }] },
          ]"
          placeholder="请输入政策id"
        />
      </a-form-item>
      <a-form-item :label-col="formTailLayout.labelCol" :wrapper-col="formTailLayout.wrapperCol">
        <a-button type="primary" :disabled="submitting" :loading="submitting" @click="check">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </template>
  
  <script>
  import { merBind } from "./services"
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };
  export default {
    data() {
      return {
        submitting: false,
        formItemLayout,
        formTailLayout,
        form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      };
    },
    methods: {
      check() {
        this.form.validateFields((err, values) => {
          if (!err) {
            console.info('success', values);
            try {
                this.submitting = true
                merBind(values).then(res => {
                    console.log(11111, res)
                    this.$message.success('提交成功');
                }).catch(e => {
                    console.log('catch22222', e)
                    this.$message.error( e.message );
                }).finally(() => {
                    this.submitting = false
                })
            } catch (error) {
                console.log(222, error)
                this.submitting = false
                this.$message.error(error);
            }
          }
        });
      },
    },
  };
  </script>