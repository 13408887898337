

    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import {request} from '@/utils';

    @Component<ShopSmsCount>({ })
    class ShopSmsCount extends Vue {

        
        public count: any = 0;
        public loading = false; 

        public async created() {
            this.fetch();
        }

        public async fetch() {
            this.loading = true;
            try {
                const data = await request({
                    api: '/console/store/sms/count',
                    data: {
                        store_id: sessionStorage.getItem('store_id')
                    }
                });

                this.count = data.total_amount;
            } catch (e) {
                this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }

    }
    export default ShopSmsCount;
