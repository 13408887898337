
    import {Component, Prop, Vue, Watch, Inject} from 'vue-property-decorator';


    @Component<Anchor>({
        components: {},
        computed: {
            style() {
                return {
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'rgba(0,0,0,.2)',
                    border: '1px solid hsla(0,0%,100%,.7)',
                    borderRadius: '5px',
                    position: 'absolute',
                    ...this.pos
                };
            }
        }
    })
    class Anchor extends Vue {

        @Prop()
        public mouse!: any;
        @Prop()
        public origin!: any;
        @Prop()
        public pos!: any;
        @Prop()
        public disabled!: any;

        @Inject()
        public setActive!: any;
        @Inject()
        public getActive!: any;

        // 与x轴正方向夹角
        public startAngle = 0;

        public handleMouseDown(e: MouseEvent) {
            if (this.disabled) {
                return;
            }
            this.setActive(this.$refs.el);
            this.$emit('mousedown', this.mouse);
        }

        @Watch('origin')
        public originListner() {
            const v1 = {
                x: this.mouse.x - this.origin.x,
                y: this.mouse.y - this.origin.y
            };
            const v2 = {
                x: 1,
                y: 0
            };
            const l = Math.acos(
                (v1.x * v2.x + v1.y * v2.y)
                / (
                    Math.sqrt((Math.pow(v1.x, 2) + Math.pow(v1.y, 2)))
                    * Math.sqrt((Math.pow(v2.x, 2) + Math.pow(v2.y, 2)))
                )
            );

            let angle = l * 180 / Math.PI;
            if (v1.y < 0) {
                angle = 360 - angle;
            }

            this.startAngle = angle;
        }

        @Watch('mouse')
        public mouseMoveListener(mouse: any, prev: any) {
            if (this.getActive() !== this.$refs.el) {
                return;
            }

            if (mouse.x === prev.x && mouse.y === prev.y) {
                return;
            }

            const v1 = {
                x: mouse.x - this.origin.x,
                y: mouse.y - this.origin.y
            };
            const v2 = {
                x: 1,
                y: 0
            };
            const l = Math.acos(
                (v1.x * v2.x + v1.y * v2.y)
                / (
                    Math.sqrt((Math.pow(v1.x, 2) + Math.pow(v1.y, 2)))
                    * Math.sqrt((Math.pow(v2.x, 2) + Math.pow(v2.y, 2)))
                )
            );
            let angle = l * 180 / Math.PI;
            if (v1.y < 0) {
                angle = 360 - angle;
            }

            this.$emit('change', angle - this.startAngle);
        }

    }

    export default Anchor;
