
import { Modal } from 'ant-design-vue';
import { Component,Inject, Vue,Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    FixedRate,
    FixedRatio,
    FixedAmount
} from '@/components/custom-form-components';
import CreateModal from './shopLimitModal.vue';
import ShopUpload from './ShopUpload.vue';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
interface CreateForm {
    baseForm: any;
    otherForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        FixedRate,
        FixedRatio,
        FixedAmount
    },
    computed: {
        ...mapState({
            formData(state: any) {
                    return this.formatFormData(state.actManageSearchPage.data);
                },
            lists: (state: any) =>
                state.actShopLimitSearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.actShopLimitSearchPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.actShopLimitSearchPage.total,
            pageSize: (state: any) => state.actShopLimitSearchPage.pageSize,
            page: (state: any) => state.actShopLimitSearchPage.page,
            loading: (state: any) => state.actShopLimitSearchPage.loading,
        }),
        
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public fixed = 0;
    public baseForm;
    public otherForm;
    @Prop()
    public activityId:any ;
    public baseData: any;
    public activeTab: any;
    public shopData: any;
    public tmpShopData:any;
    public rateListData:any;
    public rules:any;
    public fixedRateShow: boolean = true;
    public ratioListData:any;
    public fixedRatioShow: boolean = false;
    public amountListData:any;
    public fixedAmountShow: boolean = false;
    public accModeType:boolean = false;
    public style = '';
    public created() {};
    @Inject()
    public commit!: any;
    @Inject()
    public routerPush!: any;
    public createFlag = false;
    public data() {
        return {
            baseData: {},
            createFlag : false,
            baseForm: this.$form.createForm(this),
            otherForm: this.$form.createForm(this),
            activeTab: '1',
            rateListData: [{key:0,realSubRate:'0'}],
            rules:[],
            fixedRateShow:true,
            ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',realSubRate:'0'}],
            fixedRatioShow:false,
            amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2'}],
            fixedAmountShow:false,
            accModeType:false,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginLeft: '120px'
            },
            calcTableScrollX,
            shopData: [],
            tmpShopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merchantNo',
                    align: 'center',
                    width: 200

                },
                {
                    title: '终端号',
                    dataIndex: 'termNo',
                    align: 'center',
                    width: 100
                },
                {
                    title: '限额',
                    dataIndex: 'limit',
                    align: 'center',
                    width: 150  
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ],
        };
    }

    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }
    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }
    public showAccMode(data: any) {
        console.log(data)
        this.accModeType = data;
        // this.baseForm.setFieldsValue({
        //     accMode: undefined,
        // });
    }
    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }
    public showSubsidyType(data: any) {
        let type = data.target.value;
        if('1' == type){
            this.fixedRateShow = true;
            this.fixedRatioShow = false;
            this.fixedAmountShow = false;
        }else if('2' == type){
            this.fixedRateShow = false;
            this.fixedRatioShow = true;
            this.fixedAmountShow = false;
        }else if('3' == type){
            this.fixedRateShow = false;
            this.fixedRatioShow = false;
            this.fixedAmountShow = true;
        }
    }
    
    public tabChange(key: any) {
        
        if('2' == key){
            this.$message.info('如有数据更新，请注意保存。');
            this.search();
        }
        this.activeTab = key;
    }

   

    public saveBaseRule(e: any) {
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                //校验梯形数据
                if(!this.createFlag){
                //    if(values.accModeType && !values.accMode){
                //         this.$message.error('仅记账模式打开后应补头寸编号必填。');
                //         return;
                //    }
                    const subsidyType = values.subsidyType;
                    const mustAmtPattern = /(^[1-9]{1}\d{0,5}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)|(^[0]{1}$)/;
                    if('1' == subsidyType){
                        this.rateListData= this.$refs.rateChild.rateListData;
                        this.rules = this.rateListData;
                        for(let rateData of this.rateListData){
                            if(!values.accModeType){
                                rateData.realSubRate = '0';
                            }
                            if(!mustAmtPattern.test(rateData.minAmt) || !mustAmtPattern.test(rateData.maxAmt) || parseFloat(rateData.minAmt) > 999999 || parseFloat(rateData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(rateData.rate)){
                                this.$message.error('优惠后费率0-100,且只能有两位小数');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(rateData.realSubRate)){
                                this.$message.error('实补比例0-100,且只能有两位小数');
                                return;
                            }
                            if(rateData.minSubsidyAmt && (!mustAmtPattern.test(rateData.minSubsidyAmt) || parseFloat(rateData.minSubsidyAmt) > 999999)){
                                this.$message.error('单笔补贴最低金额0-999999');
                                return;
                            }

                            if(rateData.maxSubsidyAmt && (!mustAmtPattern.test(rateData.maxSubsidyAmt) || parseFloat(rateData.maxSubsidyAmt) > 999999)){
                                this.$message.error('单笔补贴封顶金额0-999999');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.rateListData)){
                            return;
                        }
                    }else if('2' == subsidyType){
                        this.ratioListData= this.$refs.ratioChild.ratioListData;
                        this.rules = this.ratioListData;
                        for(let ratioData of this.ratioListData){
                            if(!values.accModeType){
                                ratioData.realSubRate = '0';
                            }
                            if(!mustAmtPattern.test(ratioData.minAmt) || !mustAmtPattern.test(ratioData.maxAmt) || parseFloat(ratioData.minAmt) > 999999 || parseFloat(ratioData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(ratioData.ratio)){
                                this.$message.error('补贴比例只能有两位小数');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(ratioData.realSubRate)){
                                this.$message.error('实补比例0-100,且只能有两位小数');
                                return;
                            }
                            if(ratioData.minSubsidyAmt && (!mustAmtPattern.test(ratioData.minSubsidyAmt) || parseFloat(ratioData.minSubsidyAmt) > 999999 )){
                                this.$message.error('单笔补贴最低金额0-999999');
                                return;
                            }

                            if(ratioData.maxSubsidyAmt && (!mustAmtPattern.test(ratioData.maxSubsidyAmt)|| parseFloat(ratioData.maxSubsidyAmt) > 999999 )){
                                this.$message.error('单笔补贴封顶金额0-999999');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.ratioListData)){
                            return;
                        }
                    }else{
                        this.amountListData= this.$refs.amountChild.amountListData;
                        this.rules = this.amountListData;
                        for(let amountData of this.amountListData){
                            if(!mustAmtPattern.test(amountData.minAmt) || !mustAmtPattern.test(amountData.maxAmt) || parseFloat(amountData.minAmt) > 999999 || parseFloat(amountData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(amountData.transAmt)){
                                this.$message.error('补贴金额只能是数字且有两位小数');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.amountListData)){
                            return;
                        }
                    }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认保存基本规则?',
                        onOk: () => {
                            this.formatBaseForm(values);
                            console.log(this.baseData);
                            this.$store.dispatch(
                                'actManageSearchPage/createBaseRule',
                                this.baseData
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                }else{
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认修改基本规则?',
                        onOk: () => {
                            this.formatBaseForm(values);
                            this.$store.dispatch(
                                'actManageSearchPage/editBaseRule',
                                {
                                    activityId: this.activityId, 
                                    payMode:values.transType,
                                }
                            );
                        },
                        onCancel() {
                        }
                    });
                }
                
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.actManageSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            console.log(123);
            this.$message.success('提交成功');
            this.createFlag=true;

        }
    }
     public checkSpaceAmt(dataList:any){
        for(let i=0;i<dataList.length;i++){
            const rateData = dataList[i];
            //b<a err
            const a = parseFloat(rateData.minAmt);
            const b = parseFloat(rateData.maxAmt);
            if(b < a){
                this.$message.error('交易金额区间最大值必须小于最小值。'+a+"-"+b);
                return false;
            }
            for(let j=i+1;j<dataList.length;j++){
                const nextData = dataList[j];
                //d<c err
                const c =  parseFloat(nextData.minAmt);
                const d = parseFloat(nextData.maxAmt);
                console.log(c,d);
                if( d < c){
                    this.$message.error('交易金额区间最大值必须小于最小值。'+c+"-"+d);
                    return false;
                }
                // a<=c <=b  ||  a<= d <= b  err
                if((c >= a && c <= b) || (d >= a && d <= b) || (c<=a && d>= b)){
                    this.$message.error('交易金额区间重合。'+a+"-"+b+','+c+"-"+d);
                    return false;
                }
            }
        }
        return true;
     }
    

    public deleteShopData(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确认删除该商户?',
            onOk: () => {
                const data = {
                    ...record,
                    activityId:this.activityId
                }
                this.$store.dispatch(
                    'actShopLimitSearchPage/deleteShopLimit',
                    data
                );
                this.$message.success('提交成功');
                //刷新表格------------
                setTimeout(this.search,300);
            },
            onCancel() {
                // do nothing
            }
        });
    }

    public async handleCreate(e: any) {
        e.preventDefault();
        this.otherForm.validateFields(async (error: any, values: any) => {
        const formData ='';
            await this.$store.dispatch('merSubsidySearchPage/create', formData)
        
        });
    }

    private formatBaseForm(formData: any) {
        let accmode = '0';
        if(formData.accModeType){
            accmode = '1';
        }
        this.baseData = {
            activityId: this.activityId, 
            accMode : accmode,
            payMode:formData.transType,                                   // 活动名称
            subsidyType: formData.subsidyType,                         // 补贴类型
            rules:this.rules
        }
    }
    private formatFormData(rawData: any) {
        console.log(rawData)
        let accModeType = false;
        if(rawData){
            let subsidyType = '1';
            let rulesTemp = [{key:0}];
            if(rawData.subsidyType){
                subsidyType = rawData.subsidyType;
                this.createFlag=true;
                rulesTemp = rawData.rules;
            }
            if('3' == rawData.subsidyType){
                this.amountListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = false;
                this.fixedAmountShow = true;
            }else if('2' == rawData.subsidyType){
                this.ratioListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = true;
                this.fixedAmountShow = false;
            }else{
                this.rateListData = rulesTemp;
                this.fixedRateShow = true;
                this.fixedRatioShow = false;
                this.fixedAmountShow = false;
            }
            
            if(rawData.accMode == '1'){
                accModeType = true;
                this.accModeType=true;
            }
            return {
                accModeType:accModeType,
                accMode:rawData.accMode,
                transType:rawData.payMode,
                subsidyType:subsidyType,  
            }
        }else{
            return {
                subsidyType:'1',
                accModeType:accModeType
            }
        }
    }

    private resetQuery(){
        Object.keys(this.otherForm.getFieldsValue()).forEach((key) => {
            this.otherForm.setFieldsValue({
                [key]: undefined
            });
        });
    }
    public search() {
        const merchantNo = this.otherForm.getFieldValue('merchantNo')
        const termNo = this.otherForm.getFieldValue('termNo')
        const query ={
            activityId:this.activityId,
            termNo:termNo,
            merchantNo:merchantNo,
            page: 1,
            r: Math.random()
        };
        this.$store.dispatch('actShopLimitSearchPage/initQuery', query);
       
    }
    public handlePageChange(page: number) {
        this.$store.dispatch('actShopLimitSearchPage/pageChange', page);
    }
}

export default CreateForm;
