import {request} from '@/utils';
import { message } from 'ant-design-vue';

export async function bind(params: {
    offline_terminal_id: String;
    merchant_no: string;
    sn: string;
    name: string;
}) {
    try {
        const ret = await request({
            api: '/console/store/sn/add',
            data: params
        });
        message.success('关联成功');
        return ret;
    } catch (e) {
        message.error(e.message);
    }
}
