
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import config from '@/config';
import {mapState} from 'vuex';

const fakeDoneFile = {
    name: '上传完成',
    status: 'done',
    uid: 'uid'
};

@Component<AdAssign>({
    computed: {
        ...mapState({
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            }
        })
    }
})
class AdAssign extends Vue {

    @Prop()
    public value!: any;

    public visible = false;

    public config = config;

    public uploading = false;

    public fileList: any = [];

    public error: any = [];

    public created() {
        if (this.value && this.value.length > 0) {
            this.fileList = [fakeDoneFile];
        }
        this.error ='';
    }

    public handleTriggerClick() {
        this.visible = true;
    }

    public handlerRemove() {
        this.fileList = [];
    }

    public handleFileUpload({file, fileList, event}: any) {
        this.fileList = [{
            ...file,
            name: ({
                uploading: '正在上传',
                done: '上传完成',
                error: '上传失败',
                removed: ''
            } as any)[file.status]
        }];
        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                const merchants = file.response.result;
                const errors = this.checkAus(merchants);
                if (errors.length > 0) {
                    this.fileList = [];
                    this.error = errors;
                    return;
                    // return this.$message.error(errors[0]);
                } else {
                    this.error = [];
                }
                this.$emit('change', merchants);
                this.visible = false;
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }

    public handleDownloadTemplateClick() {
        // window.open(config.BASE_URL + '/static/activityMerchants.xlsx', '__blank');
        window.open(process.env.BASE_URL + 'activityMerchants.xlsx', '_blank')
    }

    private checkAus(aus: string[][]): string[] {
        const errors = aus.reduce((memo: string[], item: string[], idx: number) => {
            if (item.length !== 2) {
                return memo.concat(`第${idx + 2}行: ` + item.join(','));
            }
            if ((!/^[A-Za-z\d]{15}$/.test(item[0]) || !/^[A-Za-z\d]{8}$/.test(item[1])) && memo.length < 20) {
                return memo.concat(`第${idx + 2}行: ` + item.join(','));
            } else {
                return memo;
            }
        }, []);
        return errors;
    }
}
export default AdAssign;
