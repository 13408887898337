
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import Role from '@/components/custom-form-components/Role.vue';

    interface SearchForm {
        form: any;
    }

    @Component({
        components: {
            Role
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.whitelistSearchPage.query);
                },
                loading: (state: any) => state.whitelistSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {

                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                // this.$router.push({
                //     path: `/console/account/whitelist/search?${qs.stringify(query)}`
                // });
                this.dispatch('initQuery', query);
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: any) {
            return {
                mobile: values.mobile,
                is_debug: values.is_debug
            };
        }

        private formatFormData(query: any) {
            return {
                ...query,
                // role_id: query.role_id && parseInt(query.role_id, 10)
            };
        }
    }

    export default SearchForm;
