var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.BIZ_DISTRICT.LIST] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function (slotProps) {
          return [_c("Index")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }