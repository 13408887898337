
    import {mapState} from 'vuex';
    import {Component, Inject, Watch, Vue} from 'vue-property-decorator';
    import { Click } from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import SmsUpdateDrawer from './SmsUpdateDrawer/index.vue';

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click,
            SmsUpdateDrawer
        },
        computed: {
            ...mapState({
                loading() {
                    return this.state().loading;
                },
                downloading() {
                    return this.state().downloading;
                },
                selectedRows() {
                    return this.state().selectedRows;
                }
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public dispatch!: any;

        @Inject()
        public state!: any;

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public handleRefresh() {
            this.dispatch('search');
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        @Watch('$store.state.customerSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.customerSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.customerSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('customerSearchPage/download');
        }
    }

    export default Actions;
