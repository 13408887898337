
    import {Component, Inject, Watch,Vue} from 'vue-property-decorator';
    import moment from 'moment';
    import {mapState} from 'vuex';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import {DatePicker} from '@/components/custom-form-components';

    interface SearchForm {
        form: any;
    }

    @Component({
        components: {
            DatePicker
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.activitiesStopSearchPage.query);
                },
                loading: (state: any) => state.activitiesStopSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public dispatch!: any;
        public currentPaytype!: String;
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            this.currentPaytype = this.$store.state.activityManage.payType
            
        }
        @Watch('$store.state.activityManage.payType') 
        public payTypeChange(newVal: String, oldVal: String) {
            
            this.currentPaytype = this.$store.state.activityManage.payType
        }
        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {

                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                // this.$router.push({
                //     path: `/console/account/whitelist/search?${qs.stringify(query)}`
                // });
                this.dispatch('initQuery', query);
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: any) {
            let queryTimeFlag = '';
            if(values.queryTime && values.queryTime[0]){
                queryTimeFlag ='3';
            }
            console.info("current paytype",this.$store.state.activityManage.payType)
            return {
                payType: this.$store.state.activityManage.payType,
                status: '3',//暂停
                name: values.name,
                platActivity: values.platActivity,
                activityType: values.activityType,
                queryTimeFlag: queryTimeFlag,//结束时间
                queryStartTime: 
                    values.queryTime &&
                    values.queryTime[0] &&
                    values.queryTime[0].format('YYYY-MM-DD HH:mm:ss'),
                queryEndTime:
                    values.queryTime &&
                    values.queryTime[1] &&
                    values.queryTime[1].format('YYYY-MM-DD HH:mm:ss'),
                createdBy: values.createdBy,
                sponsorName: values.sponsorName
            };
        }

        private formatFormData(query: any) {
            return {
                ...query,
                 queryTime: [
                    query.queryStartTime && moment(query.queryStartTime),
                    query.queryEndTime && moment(query.queryEndTime)
                ],
            };
        }
    }

    export default SearchForm;
