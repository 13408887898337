
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component<MiniApp>({
})
class MiniApp extends Vue {
    public static defaultTerminals = ['MINI_APP'] as const;

    public static allowContainers = ['MINI_APP'] as const;

    public static validator(value: any) {
        if (!value.code) {
            return '小程序代码不能为空';
        }
    }

    @Prop()
    public data!: any;

    @Prop()
    public disabled!: any;

    public changeHandler(e: any) {
        this.$emit('change', {
            code: e.target.value
        });
    }
}
export default MiniApp;
