import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '商户号',
        dataIndex: 'merchant_no',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '终端号',
        dataIndex: 'terminal_id',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '商户名称',
        dataIndex: 'merchant_name',
        width: 300,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '来源',
        dataIndex: 'source',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '激活状态',
        dataIndex: 'active',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '跳转开关',
        dataIndex: 'status',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '报备状态',
        dataIndex: 'ready',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    // {
    //     title: '机具被扫白名单状态',
    //     dataIndex: 'cache',
    //     width: 200,
    //     align: 'center',
    //     customRender: defaultCustomRender
    // },
    {
        title: '创建时间',
        dataIndex: 'create_time',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell
    // {
    //     title: '开关',
    //     dataIndex: 'op',
    //     width: 100,
    //     fixed: 'right',
    //     align: 'center',
    //     scopedSlots: {customRender: 'action'}
    // }
];
