var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-modal",
    { attrs: { title: null, visible: _vm.visible, closable: false } },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.assignLoading ? "正在分配..." : "分配完成") +
          "\n    "
      ),
      _vm.assignCursor - _vm.assignFailList.length
        ? _c("span", [
            _vm._v(
              _vm._s("已分配:" + (_vm.assignCursor - _vm.assignFailList.length))
            ),
          ])
        : _vm._e(),
      _vm.assignFailList.length
        ? _c("span", [_vm._v(_vm._s("失败:" + _vm.assignFailList.length))])
        : _vm._e(),
      _c("a-progress", {
        attrs: { percent: _vm.assignPercent, showInfo: false },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.assignFailList.length > 0,
              expression: "assignFailList.length > 0",
            },
          ],
        },
        [_vm._v("分配失败商终号:")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.assignFailList.length > 0,
              expression: "assignFailList.length > 0",
            },
          ],
          staticStyle: {
            margin: "5px 0 0 0",
            "max-height": "300px",
            "overflow-y": "scroll",
            background: "#f5f5f5",
            padding: "5px",
          },
        },
        _vm._l(_vm.assignFailList, function (failItem) {
          return _c(
            "div",
            { key: failItem.merchant_no + "#" + failItem.offline_terminal_id },
            [
              _vm._v(
                "\n            " +
                  _vm._s(failItem.merchant_no) +
                  ", " +
                  _vm._s(failItem.offline_terminal_id) +
                  "\n            "
              ),
              _c(
                "div",
                { staticStyle: { color: "gray", "font-size": "10px" } },
                [_vm._v("原因: " + _vm._s(failItem.msg))]
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            {
              attrs: { disabled: _vm.assignLoading },
              on: { click: _vm.handlerConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }