import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 100,
        align: 'center'
    },
    {
        title: '购买名称',
        dataIndex: 'name',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '购买时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '购买人',
        dataIndex: 'user_name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    }
];
