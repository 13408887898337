var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "额度组名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupName",
                      {
                        initialValue: _vm.formData.merGroupName,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'merGroupName',\n                    {\n                        initialValue: formData.merGroupName,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "请输入", maxLength: 15 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "额度组ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupId",
                      {
                        initialValue: _vm.formData.merGroupId,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'merGroupId',\n                    {\n                        initialValue: formData.merGroupId,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商户号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantNo",
                      {
                        initialValue: _vm.formData.merchantNo,
                        rules: [{ required: true, message: "请填写必填项" }],
                      },
                    ],
                    expression:
                      "[\n                    'merchantNo',\n                    {\n                        initialValue: formData.merchantNo,\n                        rules: [{ required: true, message: '请填写必填项' }]\n                    }\n                ]",
                  },
                ],
                attrs: { placeholder: "请输入", maxLength: 32 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "终端号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["termNo", { initialValue: _vm.formData.termNo }],
                    expression: "['termNo', { initialValue: formData.termNo }]",
                  },
                ],
                attrs: { placeholder: "请输入", maxLength: 32 },
              }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "right" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }