
import { mapState } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import MemberDrawer from './MemberDrawer/index.vue';
import TransactionFlowDrawer from './TransactionFlowDrawer/index.vue';
import ActivityDrawer from './ActivityDrawer/index.vue';
import EmployeeDrawer from './EmployeeDrawer/index.vue';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import columns from './columns';
import ClearText from '@/components/ClearText.vue';
import SnModal from './SnModal/index.vue';
import PRIVILEGE from '@/common/privileges';
import SmsAccountDrawer from './SmsAccount/index.vue';
import InvoiceModal from './InvoiceModal/index.vue';

const sortFieldMap: any = {
    name: 'name',
    merchant_no: 'merchant_no',
    sn: 'sn',
    online_terminal_id: 'online_terminal_id',
    offline_terminal_id: 'offline_terminal_id',
    'mallcoo.mall_belong': 'mallcoo_mall__mall_belong',
    mallcoo_shop_id: 'mallcoo_shop_id',
    'mallcoo.mallcoo_mall_name': 'mallcoo_mall__id',
    'biz_district.name': 'biz_district__id',
    mcc_max_name: 'mcc_max_name',
    mcc_middle_name: 'mcc_middle_name',
    mcc_min_name: 'mcc_min_name',
    biz_type: 'biz_type',
    store_city: 'city',
    store_address: 'address',
    daily_limit: 'daily_limit',
    refund_open: 'refund_open',
    mobile: 'mobile',
    phone: 'phone',
    created_time: 'created_time',
    updated_time: 'updated_time',
};

@Component({
    components: {
        MemberDrawer,
        TransactionFlowDrawer,
        ActivityDrawer,
        ClearText,
        SnModal,
        EmployeeDrawer,
        SmsAccountDrawer,
        InvoiceModal
    },
    computed: {
        ...mapState({
            shops: (state: any) =>
                state.shopSearchPage.list.map((shop: any, idx: any) => ({
                    idx:
                        (state.shopSearchPage.page - 1) *
                            state.shopSearchPage.pageSize +
                        idx +
                        1,
                    ...shop,
                })),
            total: (state: any) => state.shopSearchPage.total,
            pageSize: (state: any) => state.shopSearchPage.pageSize,
            page: (state: any) => state.shopSearchPage.page,
            loading: (state: any) => state.shopSearchPage.loading,
            fields: (state: any) => {
                const { sort_name, sequence } = state.shopSearchPage.query;
                columns.forEach((col: any) => {
                    if (sortFieldMap[col.dataIndex] === sort_name) {
                        col.sortOrder =
                            String(sequence) === 'true' ? 'ascend' : 'descend';
                    } else {
                        col.sortOrder = false;
                    }
                });
                return normColumns(
                    columns,
                    state.shopSearchPage.fields,
                    state.shopSearchPage.sortFields
                );
            },
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys = state.shopSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true,
                };
            },
        }),
    },
})
class SearchTable extends Vue {
    public transactionFlow = null;
    public member = null;
    public activity = null;
    public employee = null;
    public smsAccount = null;
    public invoiceAccount = null;
    public fixed = 0;
    public invioceRecord = null;

    public style = '';
    public mounted() {
        this.invioceRecord = null
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
        sessionStorage.removeItem('store_id');
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            filteredInfo: null,
            calcTableScrollX,
            PRIVILEGE,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            ),
        };
    }

    public handleTableChange(pagination: any, filters: any, sorter: any) {
        const { sort_name, sequence } = this.$store.state.shopSearchPage.query;
        const { field, order } = sorter;

        function isFieldEqual() {
            if (
                (field === undefined || field === 'idx') &&
                sort_name === undefined
            ) {
                return true;
            }
            if (sortFieldMap[field] === sort_name) {
                return true;
            }
            return false;
        }

        function isOrderEqual() {
            if (
                (order === undefined || field === 'idx') &&
                sequence === undefined
            ) {
                return true;
            }
            if (
                (order === 'descend' && sequence === 'false') ||
                (order === 'ascend' && sequence === 'true')
            ) {
                return true;
            }
            return false;
        }

        if (isOrderEqual() && isFieldEqual()) {
            return;
        }

        const newQuery: any = {};

        if (field === undefined || order === undefined) {
            newQuery.sort_name = undefined;
            newQuery.sequence = undefined;
        } else {
            newQuery.sort_name = sortFieldMap[field];
            newQuery.sequence = order === 'descend' ? 'false' : 'true';
        }

        this.$store.commit('shopSearchPage/setQuery', newQuery);
        this.$store.commit('shopSearchPage/setPagination', { page: 1 });
        this.$router.push({
            path:
                `/console/shop/search` +
                this.$store.getters['shopSearchPage/queryString'](),
        });
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('shopSearchPage/setSelectedRows', selectedRowKeys);
    }

    public handleDrawerOpenClick(
        type:
            | 'transactionFlow'
            | 'member'
            | 'activity'
            | 'employee'
            | 'smsAccount',
        id: any
    ) {
        this[type] = id;
    }

    public handleOpenInvoiceAccount(record: any) {
        this.invioceRecord = null;
        this.invioceRecord = record;
        console.log('this.invioceRecord', this.invioceRecord)
    }

    public handleDrawerClose(
        type:
            | 'transactionFlow'
            | 'member'
            | 'activity'
            | 'employee'
            | 'smsAccount'
    ) {
        this[type] = null;
    }

    public handlePageChange(page: number) {
        this.$store.commit('shopSearchPage/setPagination', { page });
        this.$router.push({
            path:
                '/console/shop/search' +
                this.$store.getters['shopSearchPage/queryString'](),
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('shopSearchPage/setPagination', {
            pageSize,
            page: 1,
        });
        this.$router.push({
            path:
                '/console/shop/search' +
                this.$store.getters['shopSearchPage/queryString'](),
        });
    }
    public handlInvoiceModalrClose (type: string) {
        this.invioceRecord = null;
    }
}

export default SearchTable;
