
    import EditActForm from './EditActForm.vue';
    import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Show,
            EditActForm
        }
    })
    export default class CreatePage extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public activityInfo !: any;
        @Prop()
        public paytype !: string;
        public created() {
        }

        @Watch('activityInfo')
        public initQueryData(error: any) {
            this.$store.dispatch('actManageSearchPage/getRuleConfig');
            if(!this.activityInfo){
                return; 
            }
            this.$store.dispatch('actManageSearchPage/initData', {
                activityId: this.activityInfo.activityId,
            });
        }

        

        @Watch('$store.state.actManageSearchPage.error') 
        public errorCreateListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        public onClose() {
            this.$emit('close');
        }
    }
