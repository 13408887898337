var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.UNION_ACTIVITIES_OPEN.UPDATE] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form,
          },
          on: { ok: _vm.handleCreate, cancel: _vm.handleClose },
        },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form }, on: { submit: _vm.handleCreate } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.textareaTitle } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "data",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                            ],
                          },
                        ],
                        expression:
                          "[\n                        'data',\n                        {rules: [{required: true, message: '请填写必填项!'}]}\n                    ]",
                      },
                    ],
                    attrs: { autosize: { minRows: 6, maxRows: 6 } },
                  }),
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.onlyMer
                      ? "商户号每条请使用半角分号分隔"
                      : "商户号、线下终端号请用半角逗号分隔，每条请使用半角分号分隔"
                  )
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }