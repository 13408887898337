
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import DatePicker from '@/components/custom-form-components/DatePicker.vue';
    import {deleteEmpty, trimParams} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import formRules from '@/common/formRules';
    import {ShortMessageStatus} from '@/components/custom-form-components';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        status: string;
        first_name: string;
        start_time: string;
        end_time: string;
    }

    export interface FormData {
        status: string;
        first_name: string;
        created_time: any[];
    }

    @Component({
        components: {
            DatePicker,
            ShortMessageStatus
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.shortMessageSearchPage.query);
                },
                loading: (state: any) => state.shortMessageSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this),
                formRules
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/short-message/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                status: values.status,
                first_name: values.first_name,
                start_time: values.created_time &&
                    values.created_time[0] &&
                    values.created_time[0].format('YYYY-MM-DD HH:mm:ss'),
                end_time: values.created_time &&
                    values.created_time[1] &&
                    values.created_time[1].format('YYYY-MM-DD HH:mm:ss')
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                status: query.status,
                first_name: query.first_name,
                created_time: [
                    query.start_time && moment(query.start_time),
                    query.end_time && moment(query.end_time)
                ]
            };
        }    
    }

    export default SearchForm;
