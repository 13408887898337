
    import EditActForm from './EditActForm.vue';
    import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Show,
           EditActForm
        }
    })
    export default class CreatePage extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public accountInfo !: string;
        public remark="";
        public expenseAccount="";
        public name="";
        public ownerId="";
        public created() {
        }

        @Watch('accountInfo')
        public initQueryData(error: any) {
            if(this.accountInfo == null){
                return; 
            }
            this.remark= this.accountInfo.remark;
            this.expenseAccount = this.accountInfo.expenseAccount;
            this.name = this.accountInfo.name;
            this.ownerId = this.accountInfo.ownerId;
        }

        @Watch('$store.state.accountActivitySearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        public onClose() {
            this.$emit('close');
        }
        public onCancel() {
            this.$emit('cancel');
        }
    }
