
    import {Component, Prop, Watch, Inject, Vue} from 'vue-property-decorator';
    import * as services from './services';
    import {Click} from '@/components/Auth';
    import PRIVILEGE, {travel, buildPrivilegeNodes} from '@/common/privileges';

    @Component<PrivilegeAssignModal>({
        components: {
            Click
        },
        computed: {
            treeData() {
                const privileges = this.privileges;
                if (!privileges || privileges.length === 0) {
                    return [];
                }
                const nodes = buildPrivilegeNodes(privileges);
                travel(nodes, node => {
                    const privilege = privileges.find((p: any) => {
                        return p.english_name === node.en_name;
                    });
                    if (privilege) {
                        node.key = privilege.id;
                    } else {
                        node.disableCheckbox = true;
                    }
                    node.selectable = false;
                });
                return nodes;
            },
            expandedKeys() {
                return this.privileges.map((p: any) => p.id);
            }
        }
    })
    class PrivilegeAssignModal extends Vue {
        @Prop()
        public privileges!: any;
        @Prop()
        public ownPrivileges!: any;
        @Prop()
        public roleId!: any;

        @Inject()
        public dispatch!: any;

        public saving = false;

        public visible = false;

        public PRIVILEGE = PRIVILEGE;

        public checkedKeys: any = [...this.ownPrivileges];
        public savedCheckedKeys: any = [...this.ownPrivileges];
        public halfCheckedKeys: any = [];

        @Watch('ownPrivileges')
        public updateCheckedKeys(ownPrivileges: any) {
            this.checkedKeys = [...ownPrivileges];
        }

        @Watch('privileges')
        public watchPrivileges() {
            this.initCheckedKeys();
        }

        public handleClose() {
            if (this.saving) {
                return;
            }
            this.visible = false;
            this.checkedKeys = [...this.savedCheckedKeys];
        }

        public onClick() {
            if (!this.privileges || this.privileges.length === 0) {
                this.dispatch('fetchPrivileges');
            } else {
                this.initCheckedKeys();
            }
            this.visible = true;
        }

        public selectAllParent(node: any, privileges: any): any {
            if (node.parent === null || node.parent === undefined) {
                return [];
            }
            const parentNode: any = privileges.find((p: any) => p.id === node.parent);

            if (parentNode) {
                return [parentNode].concat(this.selectAllParent(parentNode, privileges));
            }
        }

        public initCheckedKeys() {
            const checkedKeys: any = [];
            this.ownPrivileges.forEach((key: any) => {

                const privilege: any = this.privileges.find((p: any) => p.id === key);
                if (!this.isAllChildrenChecked(privilege, this.privileges, this.ownPrivileges)) {
                    return;
                }
                checkedKeys.push(key);
            });
            this.checkedKeys = [...checkedKeys];
            this.savedCheckedKeys = [...checkedKeys];
        }

        public isAllChildrenChecked(node: any, privileges: any, ownPrivileges: any) {
            const directChildren = privileges.filter((privilege: any) => privilege.parent === node.id);
            return directChildren.every((child: any) => {

                return ownPrivileges.indexOf(child.id) >= 0 && this.isAllChildrenChecked(child, privileges, ownPrivileges);
            });
        }

        public async handleOk() {
            this.saving = true;
            const checkedPermissions = this.checkedKeys.reduce((memo: any, checkedKey: any) => {
                const node = this.privileges.find((privilege: any) => privilege.id === checkedKey);
                const parents = this.selectAllParent(node, this.privileges);
                return memo.concat(parents.filter((p: any) => memo.indexOf(p) < 0));
            }, []).map((p: any) => p.id).concat(this.checkedKeys).join(',');
            try {
                await services.saveRolePrivilege({
                    role_id: this.roleId,
                    permissions: checkedPermissions
                });
                this.visible = false;
                this.savedCheckedKeys = checkedPermissions;
                this.$emit('success');
            } catch (e) {
                this.$message.error(e.message);
            } finally {
                this.saving = false;
            }
        }
    }

    export default PrivilegeAssignModal;
