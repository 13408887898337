
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import columns from './columns';
    import preferences from './preferences';
    import {queryToHumanReadable} from '@/utils';
    import './store';
    const humanReadableQuerySchema: any = {
        position: {
            name: '广告位置',
            values: {
                B_HOME_BOTTOM: 'B端首页下方广告位',
                C_HOME_BOTTOM: 'C端首页下方广告位',
                BEFORE_PAY: '支付前页面广告位',
                AFTER_PAY: '支付后页面广告位',
                ORDER_DETAIL: '订单详情页广告位'
            }
        },
        title: '广告名称',
        terminal: {
            name: '载体',
            values: {
                H5: '惠码H5',
                MINI_APP: '惠码小程序'
            }
        },
        material_tpl: {
            name: '展现方式',
            values: {
                IMAGE_TEXT_LINK: '图链',
                MINI_APP: '小程序广告'
            }
        },
        assignment_type: {
            name: '分配',
            values: {
                directly: '商户',
                malls: '商场',
                biz_districts: '商圈',
                upload: '批量上传商户',
                default: '默认'
            }
        },
        status: {
            name: '状态',
            values: {
                NOT_STARTED: '未上架',
                ACTIVED: '上架中',
                DONE: '已下架'
            }
        },
        creator: '创建人',
        start_time_from: {
            name: '上架时间',
            range: 'from'
        },
        start_time_to: {
            name: '上架时间',
            range: 'to'
        },
        created_time_from: {
            name: '创建时间',
            range: 'from'
        },
        created_time_to: {
            name: '创建时间',
            range: 'to'
        }
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout,
            Show,
            FieldsFilter
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.advertisementSearchPage.fields,
                sortArr: (state: any) => state.advertisementSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.advertisementSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class AdvertisementPage extends Vue {
        public storeName = 'advertisementSearchPage';
        public PRIVILEGE = PRIVILEGE;
        public fixed = false;
        public columns = columns;

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        public created() {
            this.initQuery();
        }

        @Watch('$store.state.advertisementSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.advertisementSearchPage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            this.initQuery();
        }

        public initQuery() {
            const {
                _search,
                ...query
            } = this.$route.query;
            if (_search !== '0') {
                this.dispatch('initQuery', query);
            }
        }

        public handleFixed() {
            this.fixed = true;
        }
        private handleFieldChange(e: any) {
            this.$store.commit('advertisementSearchPage/setFields', e);
        }

        private handleSearch() {
            this.$store.commit('advertisementSearchPage/setSelectedRowKeys', []);
        }

        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('advertisementSearchPage/sortFields', e);
        }
    }
