import {RouteConfig} from 'vue-router';
import Search from './SearchPage/AuthWrapper.vue';
import Edit from './EditPage/AuthWrapper.vue';
import Create from './CreatePage/AuthWrapper.vue';

const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'create',
        name: '新增商场',
        component: Create,
    },
    {
        path: ':id',
        name: '编辑商场',
        component: Edit,
    }
];

export default route;
