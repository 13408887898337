var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("label", [_vm._v("图片/二维码:")]),
      _c("ImageCrop", {
        attrs: {
          disabled: _vm.disabled,
          noPreview: true,
          noCrop: true,
          minWidth: 750,
          value: _vm.imageFile,
        },
        on: {
          change: function ($event) {
            return _vm.changeHandler("image", $event)
          },
        },
      }),
      _c("label", [_vm._v("跳转URL:")]),
      _c("a-input", {
        attrs: { disabled: _vm.disabled, value: _vm.data && _vm.data.url },
        on: {
          change: function ($event) {
            return _vm.changeHandler("url", $event.target.value)
          },
        },
      }),
      _c(
        "Rich",
        { attrs: { initUrl: _vm.data && _vm.data.url } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { disabled: _vm.disabled, size: "small" },
                },
                [_vm._v("创建")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "12px",
            "text-align": "left",
            clear: "both",
            color: "#EC4A3B",
          },
        },
        [
          _vm._v(
            "\n        备注：未配置URL，点击图片仅放大，无跳转。小程序码无需设置跳转URL\n    "
          ),
        ]
      ),
      _c("label", [_vm._v("文字展示:")]),
      _c("a-input", {
        attrs: { disabled: _vm.disabled, value: _vm.data && _vm.data.text },
        on: {
          change: function ($event) {
            return _vm.changeHandler("text", $event.target.value)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }