
import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker,FixedRate,
    FixedRatio,
    FixedAmount} from '@/components/custom-form-components';
import moment from 'moment';
import { findRepeatData, isRepeatArr } from '@/utils';
interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DisableDatePicker,
            FixedRate,
            FixedRatio,
            FixedAmount,

        },
        computed: {
            ...mapState({
                loading: (state: any) => state.merSubsidyActSearchPage.loading,
                configData(state: any) {
                    return (
                        state.merSubsidyActSearchPage.ruleConfigData 
                    )
                },
                formData(state: any) {
                    return state.merSubsidyActSearchPage.data && this.formatFormData(state.merSubsidyActSearchPage.data);
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        public districtCodeList=[];
        public awardRuleData= [];
        public activityId:any ;
        public createFlag = true;
         public rateListData:any;
        public rules:any;
        public fixedRateShow: boolean = true;
        public ratioListData:any;
        public fixedRatioShow: boolean = false;
        public amountListData:any;
        public fixedAmountShow: boolean = false;
        public accModeType:boolean = false;
        form: any;
        otherForm: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this),
                createFlag : true,
                rateListData: [{key:0}],
                rules:[],
                fixedRateShow:true,
                ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
                fixedRatioShow:false,
                amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
                fixedAmountShow:false,
                accModeType:false
            };
        }
        public created() {
            
        }
        
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'merSubsidyActSearchPage/editAct',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.merSubsidyActSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$message.success('提交成功');
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onCancel');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
       
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            
            return {
                activityId:this.activityId,
                name: formData.name,
                payMode:formData.payMode,
                startTime: formData.activity_time[0].format(
                    'YYYYMMDDHHmmss'
                ),
                endTime: formData.activity_time[1].format(
                    'YYYYMMDDHHmmss'
                )
            };
        }

        
        private formatFormData(rawData: any) {
            let rulesTemp = [{key:0}];
            if(rawData.subsidyType){
                this.createFlag=true;
                rulesTemp = rawData.rules;
            }
            if(rawData.accMode == '1'){
                this.accModeType = true;
            }
            if('3' == rawData.subsidyType){
                this.amountListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = false;
                this.fixedAmountShow = true;
            }else if('2' == rawData.subsidyType){
                this.ratioListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = true;
                this.fixedAmountShow = false;
            }else{
                this.rateListData = rulesTemp;
                this.fixedRateShow = true;
                this.fixedRatioShow = false;
                this.fixedAmountShow = false;
            }
            this.activityId = rawData.activityId;
            return {
                activity_time: [
                    rawData.startTime && moment(rawData.startTime),
                    rawData.endTime && moment(rawData.endTime)
                ],
                accModeType:this.accModeType,
                ...rawData,
            }
        }
    }

    export default createForm;
