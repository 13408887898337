import {
    couponRenderFactory,
    priceCustomRender,
    defaultCustomRender,
    mallCouponRender
} from '@/utils';

import * as DICTS from '@/common/dicts';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center'
    },
    {
        title: '商户名称',
        dataIndex: 'store.name',
        fixed: 'left',
        width: 250,
        align: 'center',
        // sorter: true,
        customRender: defaultCustomRender
    },
    {
        title: 'LKL商户号',
        dataIndex: 'store.merchant_no',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '惠码SN号',
        dataIndex: 'store.sn',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '线下终端号',
        dataIndex: 'offline_terminal_id',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易类型',
        dataIndex: 'pay_channel',
        align: 'center',
        width: 150,
        customRender: defaultCustomRender
    },
    {
        title: '交易方式',
        dataIndex: 'pay_type',
        align: 'center',
        width: 150,
        customRender: defaultCustomRender
    },
    {
        title: '订单号',
        dataIndex: 'show_id',
        align: 'center',
        width: 220,
        customRender: defaultCustomRender
    },
    {
        title: 'order_ID',
        dataIndex: 'id',
        width: 250,
        align: 'center',
        // sorter: true,
        customRender: defaultCustomRender
    },
    {
        title: 'tradeNo',
        dataIndex: 'trade_no',
        width: 250,
        align: 'center',
        // sorter: true,
        customRender: defaultCustomRender
    },
    {
        title: '订单金额(元)',
        dataIndex: 'total_amount',
        align: 'center',
        width: 100,
        customRender: (totalAmount: any, record: any) => {
            return (
                (record.status === 'REFUND' ? '-' : '') +
                priceCustomRender(totalAmount)
            );
        }
    },
    {
        title: '实收金额(元)',
        dataIndex: 'pay_amount',
        align: 'center',
        width: 100,
        customRender: (payAmount: any, record: any) => {
            return (
                (record.status === 'REFUND' ? '-' : '') +
                priceCustomRender(payAmount)
            );
        }
    },
    {
        title: '交易状态',
        dataIndex: 'status',
        align: 'center',
        width: 150,
        customRender: defaultCustomRender
    },
    {
        title: '订单创建时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易时间',
        dataIndex: 'trade_time',
        align: 'center',
        width: 200,
        customRender: (tradeTime: string) => {
            return tradeTime || '-';
        }
    },
    {
        title: '商户别名',
        dataIndex: 'store.alias',
        width: 250,
        align: 'center',
        // sorter: true,
        customRender: defaultCustomRender
    },
    {
        title: '机构名称',
        dataIndex: 'comp_org_name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },

    {
        title: '归属商场',
        dataIndex: 'store.mall_name',
        align: 'center',
        sorter: true,
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '归属商圈',
        dataIndex: 'store.biz_district_name',
        align: 'center',
        sorter: true,
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '会员名',
        dataIndex: 'customer.nick_name',
        align: 'center',
        sorter: true,
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '会员卡号',
        dataIndex: 'customer.member_no',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '手机号',
        dataIndex: 'customer.mobile',
        align: 'center',
        sorter: true,
        width: 180,
        scopedSlots: { customRender: 'mobile' }
    },

    {
        title: '进店红包(元)',
        dataIndex: 'coupon_NEWBIE',
        key: 'coupon_NEWBIE',
        align: 'center',
        width: 100,
        customRender: couponRenderFactory(['NEWBIE', 'OTHER|NEWBIE'])
    },
    {
        title: '拼手气红包(元)',
        dataIndex: 'coupon_LUCKY_PACKET',
        key: 'coupon_LUCKY_PACKET',
        align: 'center',
        width: 120,
        customRender: couponRenderFactory([
            'LUCKY_PACKET',
            'OTHER|LUCKY_PACKET'
        ])
    },
    {
        title: '定向发券(元)',
        dataIndex: 'coupon_ORIENTATION',
        key: 'coupon_ORIENTATION',
        align: 'center',
        width: 100,
        customRender: couponRenderFactory(['ORIENTATION', 'OTHER|ORIENTATION'])
    },
    {
        title: '折扣金额(元)',
        dataIndex: 'discount_amount',
        key: 'discount_amount',
        align: 'center',
        width: 100,
        customRender: (discountAmount: any) => {
            return (
                (discountAmount > 0 ? '-' : '') +
                priceCustomRender(discountAmount)
            );
        }
    },
    {
        title: '商场券(元)',
        dataIndex: 'coupon_MALL',
        key: 'coupon_MALL',
        align: 'center',
        width: 100,
        customRender: mallCouponRender
    },
    {
        title: '商场券号',
        dataIndex: 'mallcoo_v_code',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商圈券(元)',
        dataIndex: 'hami_discount',
        key: 'hami_discount',
        align: 'center',
        width: 100,
        customRender: (hamiDiscount: any) => {
            return (
                (hamiDiscount > 0 ? '-' : '') + priceCustomRender(hamiDiscount)
            );
        }
    },
    {
        title: '商圈券号',
        dataIndex: 'hami_coupon_no',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '退款时间',
        dataIndex: 'refund_time',
        align: 'center',
        width: 200,
        customRender: (refundTime: string) => {
            return refundTime || '-';
        }
    },

    blankCell
];
