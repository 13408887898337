
import { Component, Vue } from 'vue-property-decorator';
import OperatePage from './Operate/index.vue';
import InfoPage from './Info/index.vue';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        OperatePage,
        InfoPage,
        Show
    },
    computed: {}
})
class Index extends Vue {
    public activeKey = 'operate';

    public PRIVILEGE = PRIVILEGE;

    public created() {
        if (this.$route.path === '/console/statement/operate') {
            this.activeKey = 'operate';
        } else if (this.$route.path === '/console/statement/info') {
            this.activeKey = 'info';
        }
    }

    public handleChange(key: string) {
        this.activeKey = key;

        if (key === 'operate') {
            this.$router.push({
                path: '/console/statement/operate'
            });
        } else if (key === 'info') {
            this.$router.push({
                path: '/console/statement/info'
            });
        }
    }
}

export default Index;
