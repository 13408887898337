
    import {Modal} from 'ant-design-vue';
    import {mapState} from 'vuex';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import * as services from './services';
    import columns from './columns';
    import {check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {normColumns,calcTableScrollX, getTop} from '@/utils';

    interface SearchTable {
        selectedRowKeys: any[];
    }

    @Component({
        components: {
        },
        computed: {
            rowSelection() {
                const self: any = this;
                const {selectedRowKeys} = self;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
            ...mapState({
                roles: (state: any) =>
                    state.hexiaoUnionManageSearchPage.list.map((order: any, idx: any) => ({
                        idx: (state.hexiaoUnionManageSearchPage.page - 1) * state.hexiaoUnionManageSearchPage.pageSize + idx + 1,
                        ...order
                    })),
                total: (state: any) => state.hexiaoUnionManageSearchPage.total,
                pageSize: (state: any) => state.hexiaoUnionManageSearchPage.pageSize,
                page: (state: any) => state.hexiaoUnionManageSearchPage.page,
                loading: (state: any) => state.hexiaoUnionManageSearchPage.loading,
                privileges() {
                    return this.state().privileges;
                },
                fields: (state: any) => normColumns(
                    columns, state.hexiaoUnionManageSearchPage.fields, state.hexiaoUnionManageSearchPage.sortFields),
            })
        }
    })
    class SearchTable extends Vue {
        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;
        @Inject()
        public commit!: any;
        @Inject()
        public getters!: any;
        @Inject()
        public routerPush!: any;
        @Inject('privileges')
        public ownPrivileges!: any;

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                selectedRowKeys: [],
                columns,
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public created() {
            // this.dispatch('fetchPrivileges');
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.$store.commit('hexiaoUnionManageSearchPage/setSelectedRows', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        }

        public handlePageChange(page: number) {
            //this.commit('setPagination', {page});
            //this.routerPush();
            //this.dispatch('initQuery', this.$route.query);
            this.dispatch('pageChange', page);
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            //this.commit('setPagination', {pageSize, page: 1});
            //this.routerPush();
            //this.dispatch('initQuery', this.$route.query);
            this.dispatch('pageSizeChange', pageSize);
        }

        public handleAssignSuccess() {
            this.dispatch('search');
        }

       
    }

    export default SearchTable;
