
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import EnterDrawer from './EnterDrawer/index.vue';
    import MemberDrawer from './MemberDrawer/index.vue';
    import {normColumns, calcTableScrollX, getTop} from '@/utils';
    import columns from './columns';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import ClearText from '@/components/ClearText.vue';

    interface SearchTable {
        selectedRowKeys: any;
    }

    @Component<SearchTable>({
        components: {
            EnterDrawer,
            MemberDrawer,
            Click,
            ClearText
        },
        computed: {
            rowSelection() {
                const self: any = this;
                const {selectedRowKeys} = self;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
            ...mapState({
                malls: (state: any) =>
                    state.mallSearchPage.list.map((mall: any, idx: any) => ({
                        idx:
                            (state.mallSearchPage.page - 1) *
                            state.mallSearchPage.pageSize +
                            idx +
                            1,
                        ...mall
                    })),
                total: (state: any) => state.mallSearchPage.total,
                pageSize: (state: any) => state.mallSearchPage.pageSize,
                page: (state: any) => state.mallSearchPage.page,
                loading: (state: any) => state.mallSearchPage.loading,
                fields: (state: any) =>
                    normColumns(
                        columns,
                        state.mallSearchPage.fields,
                        state.mallSearchPage.sortFields
                    )
            })
        }
    })
    class SearchTable extends Vue {

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                columns,
                selectedRowKeys: [],
                PRIVILEGE,
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.selectedRowKeys = selectedRowKeys;
        }

        public handlePageChange(page: number) {
            this.$store.commit('mallSearchPage/setPagination', {page});
            this.$router.push({
                path:
                    '/console/mall/search' +
                    this.$store.getters['mallSearchPage/queryString']()
            });
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.$store.commit('mallSearchPage/setPagination', {
                pageSize,
                page: 1
            });
            this.$router.push({
                path:
                    '/console/mall/search' +
                    this.$store.getters['mallSearchPage/queryString']()
            });
        }
    }

    export default SearchTable;
