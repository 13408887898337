import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-short-message-active-status',
    options: [{
        label: '上架中',
        value: 'ON'
    }, {
        label: '已下架',
        value: 'OFF'
    }, {
        label: '待上架',
        value: 'WAITE'
    }]
});
