
import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import Actions from './Actions.vue';
import FieldsFilter from '@/components/FieldsFilter.vue';
import SearchForm from './SearchForm.vue';
import SearchTable from './SearchTable.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import columns from './columns';
import * as Constant from '@/common/constant';
import store from './newstore';
import { queryToHumanReadable } from '@/utils';
import preferences from './preferences';
const humanReadableQuerySchema = {
    name: '活动名称',
    orgId: '机构号',
    budgetActivity: '活动id',
    platform: '平台方',
    validType: '时效类型',
    status: '状态',
    startTime: {
        name: '创建时间',
        range: 'from'
    },
    endTime: {
        name: '创建时间',
        range: 'to'
    },
};
@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.zhongxinSearchPage.fields,
            sortArr: (state: any) => state.zhongxinSearchPage.sortFields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.zhongxinSearchPage.query,
                    humanReadableQuerySchema
                );
            }
        })
    }
})
export default class DraftPage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'zhongxinSearchPage';


    public currentColumns = columns;

    public data() {
        return {
            columns
        };
    }

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/activity/draft/search' + this.getters('queryString')()
        });
    }

    public created() {
       this.currentColumns = columns;
    }

    public registerStore() {
        if (!this.state()) {
            this.$store.registerModule([this.storeName], store);
            this.$watch(function() {
                return this.$store.state[this.storeName].error;
            }, this.errorListener);
        }
    }

    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    private handleFieldChange(e: any) {
        this.$store.commit('zhongxinSearchPage/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('zhongxinSearchPage/sortFields', e);
    }
}
