
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import SearchTable from '@/components/SearchTable.vue';
    import {mapState} from 'vuex';
    import {defaultCustomRender} from '@/utils';
    import qs from 'querystringify';

    @Component({
        components: {
            SearchTable
        },
        computed: {
            ...mapState({
                list() {
                    return this.state().list;
                },
                total() {
                    return this.state().total;
                },
                page() {
                    return this.state().page;
                },
                pageSize() {
                    return this.state().pageSize;
                },
                loading() {
                    return this.state().loading;
                }
            })
        }
    })
    class StoreSearchTable extends Vue {

        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                columns: [
                    {
                        title: '商户名称',
                        dataIndex: 'name',
                        fixed: 'left',
                        width: 250,
                        customRender: defaultCustomRender
                    },
                    {
                        title: 'LKL商户号',
                        dataIndex: 'merchant_no',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '惠码SN号',
                        dataIndex: 'sn',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '商城',
                        dataIndex: 'mallcoo.mallcoo_mall_name',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '商圈',
                        dataIndex: 'biz_district.name',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '楼层/铺位',
                        dataIndex: 'floor_door_no',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        // 必须使用箭头函数
                        customRender: (text: any, record: any, idx: any) => {
                            return this.$createElement('a-button', {
                                props: {
                                    size: 'small'
                                },
                                on: {
                                    click: () => this.$router.push({
                                        // tslint:disable-next-line:max-line-length
                                        path: `/console/shop/search?${qs.stringify({
                                            page: 1,
                                            store_name: record.name,
                                            merchant_no: record.merchant_no,
                                            r: Math.random()
                                        })}`
                                    })
                                }
                            }, '跳转商户列表查看');
                        }
                    }
                ]
            };
        }

        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handlePageSizeChange(pageSize: number) {
            this.dispatch('pageSizeChange', pageSize);
        }
    }

    export default StoreSearchTable;
