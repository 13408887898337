import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns,

    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
}, {
    state: {
        selectedRows: [],
        openRefundIng: false,
        sortFields: preferences.sortFields,
    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setOpenRefundLoading(state: any, loading: boolean) {
            state.openRefundIng = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        async openRefund(context: any, open: boolean) {
            context.commit('setOpenRefundLoading', true);
            try {
                await services.openRefund({
                    batch_store_id: context.state.selectedRows.join(','),
                    refund_open: open ? 'True' : 'False'
                });
                context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setOpenRefundLoading', false);
            }
        },
        async smsSwitch(context: any, query: any) {
            context.commit('setOpenRefundLoading', true);
            try {
                await services.smsSwitch({
                    daily_limit: query.dailyLimit,
                    sms_switch: query.open ? 'True' : 'False'
                });
               // context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setOpenRefundLoading', false);
            }
        },
    }
});
