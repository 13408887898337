import {create} from '@/common/storeFactory';
import * as services from './services';
import store from '@/store';

store.registerModule('qWhitelistUpload', create({
    search(query: any, page, pageSize) {
        return services.list({
            ...query,
            page,
            page_size: pageSize
        });
    }
}, {
    state: {
        downloadingErrorExcel: [],
        refreshing: []
    },
    mutations: {
        updateOne(state: any, payload: any) {
            const list = [...state.list];
            const index = list.findIndex((item) => item.id === payload.id);
            if (index >= 0) {
                list[index] = payload;
            }
            state.list = list;
        },
        downloadErrorExcelRequested(state: any, id: any) {
            state.downloadingErrorExcel = [...state.downloadingErrorExcel, id];
        },

        downloadErrorExcelFinally(state: any, id: any) {
            const idx = state.downloadingErrorExcel.indexOf(id);
            if (idx >= 0) {
                state.downloadingErrorExcel.splice(idx, 1);
                state.downloadingErrorExcel = [...state.downloadingErrorExcel];
            }
        },

        refreshRequested(state: any, id: any) {
            state.refreshing = [...state.refreshing, id];
        },

        refreshFinally(state: any, id: any) {
            const idx = state.refreshing.indexOf(id);
            if (idx >= 0) {
                state.refreshing.splice(idx, 1);
                state.refreshing = [...state.refreshing];
            }
        }
    },
    actions: {
        async refresh(context: any, id: any) {
            context.commit('refreshRequested', id);
            try {
                const newData = await services.refresh(id);
                context.commit('updateOne', newData);
            } finally {
                context.commit('refreshFinally', id);
            }
        },

        async downloadErrorExcel(context: any, id: any) {
            context.commit('downloadErrorExcelRequested', id);
            try {
                await services.downloadXls({task_id: id});
            } finally {
                context.commit('downloadErrorExcelFinally', id);
            }
        },

        async downloadTemplateExcel(context: any) {
            await services.downloadTemplateXls();
        }
    }
}));
