
    import {mapState} from 'vuex';
    import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import UploadDrawer from './UploadDrawer/index.vue';
    import PRIVILEGE from '@/common/privileges';

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click,  
            UploadDrawer
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.sharedQuotaConfSearchPage.loading,
                downloading: (state: any) => state.sharedQuotaConfSearchPage.downloading,
                selectedRows: (state: any) => state.sharedQuotaConfSearchPage.selectedRows,
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public applyUploadType:String = 'act_mer_group_bind';
        public changeUploadType:String = 'act_mer_group_change';

        public data() {
            return {
                PRIVILEGE,
                hideMessage: null
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleAdd() {
            this.$router.push('/console/sharedQuotaConf/createSharedQuota');
        }

        public handleDownload() {
            this.$store.dispatch('sharedQuotaConfSearchPage/download');
        }
    }

    export default Actions;
