
    import {mapState} from 'vuex';
    import {Component, Inject,Watch, Vue} from 'vue-property-decorator';
    import CreateModal from './CreateModal.vue';
    import PRIVILEGE from '@/common/privileges';
    import createFrom from './createFrom.vue';
    import {Click} from '@/components/Auth';
    import { Modal } from 'ant-design-vue';
    interface Actions {
        hideMessage: any;
    }
    @Component({
        components: {
            Click,
            CreateModal,
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.accountActivitySearchPage.loading,
                selectedRows: (state: any) =>state.accountActivitySearchPage.selectedRowKeys
            })
        }
    })
    class Actions extends Vue {

        @Inject()
        public dispatch!: any;

        @Inject()
        public state!: any;
        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }
        public handleCreateSuccess() {
            this.dispatch('search');
        }
        public mounted() {
            console.log(this.$store)
        }
        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }
        public handleRefresh() {
            this.dispatch('search');
        }
        public handleAddAdPlan() {
            this.$router.push('/console/config/addSubject');
        }
        public deleteAccount() {
            Modal.confirm({
                title: '提示',
                content: '删除后不可恢复！',
                onOk: () => {
                    this.dispatch('deleteAccount', {
                        ids: this.selectedRows.join(',')
                    });
                    this.dispatch('search');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
    }

    export default Actions;
