
    import EditActForm from './EditActForm.vue';
    import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Show,
            EditActForm
        }
    })
    export default class CreatePage extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public templetId !: any;
        public created() {
        }

        @Watch('templetId')
        public initQueryData(error: any) {
            this.$store.dispatch('policyActivitySearchPage/getRuleConfig');
            if(!this.templetId){
                return; 
            }
            this.$store.dispatch('policyActivitySearchPage/initData', {
                templetId: this.templetId,
            });
        }

        

        @Watch('$store.state.policyActivitySearchPage.error') 
        public errorCreateListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        public onClose() {
            this.$emit('close');
        }
    }
