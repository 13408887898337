import {request, downloadRequest} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/sms/package/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取短信包配置列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'sms',
        api: '/console/sms/package/excel'
    });
}
export async function updateStatus(params: any) {

    try {
        return await request({
            method: 'POST',
            api: '/console/sms/package/update',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function createSm(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/sms/package/create',
            data: {
                ...params
            }
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        // hack，下一版本优化
       
        if (e.message === '网络错误') {
            e.message='超时，请检查网络!';
        } else {
            e.message='提交失败，或联系技术人员!';
        }
        const error: any = new Error(e.message);
        error.silent = e.is403;
        throw error;
    }
}
