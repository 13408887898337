var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SearchTable", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.list,
      total: _vm.total,
      page: _vm.page,
      pageSize: _vm.pageSize,
      loading: _vm.loading,
      rowKey: "id",
      scroll: { x: 1000 },
    },
    on: {
      pageChange: _vm.handlePageChange,
      pageSizeChange: _vm.handlePageSizeChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }