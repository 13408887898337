import axios from 'axios';
import config from '@/config';
import {responseHandler, BizError} from '@/utils';
import md5 from 'md5';

export async function login(params: any) {
    try {
        return responseHandler(await axios.post(config.BASE_URL + '/console/login', {
            mobile: params.mobile,
            password: md5(params.password + config.salt),
            captcha: params.captcha
        }));
    } catch (e) {
        if (e instanceof BizError) {
            throw e;
        }
        throw new Error('登录失败, 请联系技术人员!');
    }
}

export async function resetPassword(params: any) {
    return responseHandler(await axios.post(config.BASE_URL + '/console/user/reset/password', {
        mobile: params.mobile,
        password: md5(params.password + config.salt),
        captcha: params.captcha
    }));
}

// export async function fetchPermissionList() {
//     try {
//         return await request({
//             api: '/console/permission/list',
//             data: {
//                 page_size: 999
//             }
//         });
//     } catch (e) {
//         const error: any = new Error('获取角色列表失败, 请联系技术人员!');
//         error.silent = e.is403;
//         throw error;
//     }
// }
