import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-q-whitelist-trigger-status',
    options: [{
        label: '开',
        value: 'True'
    }, {
        label: '关',
        value: 'False'
    }]
});
