var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "16%" },
                    attrs: { type: "dashed", disabled: _vm.isDisabledFlag },
                    on: { click: _vm.add },
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 新增\n          "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("keys"), function (k, index) {
              return _c(
                "a-form-item",
                { key: "data" + index, attrs: { keys: k, required: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "custom-form-item-wrapper",
                      staticStyle: { width: "90%", display: "inline-block" },
                    },
                    [
                      _vm._v("\n          交易金额"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("："),
                      _c("a-input", {
                        staticStyle: {
                          "margin-left": "28px",
                          width: "26%",
                          "margin-right": "13px",
                        },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.amountListData[index].minAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.amountListData[index], "minAmt", $$v)
                          },
                          expression: "amountListData[index].minAmt",
                        },
                      }),
                      _vm._v("\n                   -\n                   "),
                      _c("a-input", {
                        staticStyle: { "margin-left": "13px", width: "26%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.amountListData[index].maxAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.amountListData[index], "maxAmt", $$v)
                          },
                          expression: "amountListData[index].maxAmt",
                        },
                      }),
                      _c("br"),
                      _vm._v("\n          补贴金额"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("："),
                      _c("a-input", {
                        staticStyle: { "margin-left": "26px", width: "60%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.amountListData[index].transAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.amountListData[index], "transAmt", $$v)
                          },
                          expression: "amountListData[index].transAmt",
                        },
                      }),
                      _c("br"),
                      _vm._v("\n          补贴金额大于交易金额："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _c(
                        "a-radio-group",
                        {
                          attrs: { disabled: _vm.isDisabledFlag },
                          model: {
                            value: _vm.amountListData[index].subAmtGtTrans,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.amountListData[index],
                                "subAmtGtTrans",
                                $$v
                              )
                            },
                            expression: "amountListData[index].subAmtGtTrans",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v("可以"),
                          ]),
                          _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v("不可以"),
                          ]),
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._v("\n          补贴金额大于交易手续费："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _c(
                        "a-radio-group",
                        {
                          attrs: { disabled: _vm.isDisabledFlag },
                          model: {
                            value: _vm.amountListData[index].subAmtGtFee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.amountListData[index],
                                "subAmtGtFee",
                                $$v
                              )
                            },
                            expression: "amountListData[index].subAmtGtFee",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v("可以"),
                          ]),
                          _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v("不可以"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "10%", top: "-60px", left: "4px" },
                      attrs: { type: "dashed", disabled: _vm.isDisabledFlag },
                      on: { click: () => _vm.remove(k) },
                    },
                    [_vm._v("\n          删除\n      ")]
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }