import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '活动ID',
        dataIndex: 'id',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '批次ID',
        dataIndex: 'platActivity',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    
    {
        title: '主题活动ID',
        dataIndex: 'gwActivityId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: '优惠方式',
        dataIndex: 'activityType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '出资方',
        dataIndex: 'sponsorName',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: '可用起始时间',
        dataIndex: 'startTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '可用结束时间',
        dataIndex: 'endTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },

    {
        title: '激活时间',
        dataIndex: 'activeTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '最后修改时间',
        dataIndex: 'updatedTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'createdUser',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 170,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
