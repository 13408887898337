import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns,

    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
}, {
    state: {
        selectedRows: [],
        deleting: false
    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setDeleteLogLoading(state: any, loading: boolean) {
            state.deleting = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        async deleteLog(context: any, time: string = '') {
            context.commit('setDeleteLogLoading', true);
            try {
                let params: object;
                time ? params = {time} : params = {ids: context.state.selectedRows.join(',')};
                await services.deleteLog(deleteEmpty(params));
                context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setDeleteLogLoading', false);
            }
        }
    }
});
