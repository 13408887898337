import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import store from '@/store';
// import preferences from './preferences';

store.registerModule(
    'sharedQuotaConfSearchPage',
    create(
        {   
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    pageIndex: page,
                    pageSize: pageSize
                });
            },

            download(query) {
                return services.downloadXls(
                    deleteEmpty({
                        ...query
                    })
                );
            },
            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRows', []);
            }
        },
        {
            state: {
                updatingStatus: [],
                openingStatus: [],
                joiningStatus: [],
                updateStatusError: null,
                openStatusError: null,
                joinStatusError: null,
                selectedRows: [],
                error: '',
                updatedSuccess:false,
            },
            mutations: {
                setSelectedRows(state: any, rows: any) {
                    state.selectedRows = rows;
                },
                updateOneStatus(state: any, { id, status }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], status };
                    }
                    state.list = list;
                },
                openOneStatus(state: any, { id, ready }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], ready };
                    }
                    state.list = list;
                },
                joinOneStatus(state: any, { id, cache }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], cache };
                    }
                    state.list = list;
                },
                updateStatusRequested(state: any, id: any) {
                    state.updatingStatus = [...state.updatingStatus, id];
                },
                openStatusRequested(state: any, id: any) {
                    state.openingStatus = [...state.openingStatus, id];
                },
                joinStatusRequested(state: any, id: any) {
                    state.joiningStatus = [...state.joiningStatus, id];
                },

                updateStatusFailed(state: any, error: any) {
                    state.updateStatusError = error;
                },

                openStatusFailed(state: any, error: any) {
                    state.openStatusError = error;
                },
                joinStatusFailed(state: any, error: any) {
                    state.joinStatusError = error;
                },
                setUpdatedSuccess(state: any, updatedSuccess: any) {
                    state.updatedSuccess = updatedSuccess;
                },
                updateStatusFinally(state: any, id: any) {
                    const idx = state.updatingStatus.indexOf(id);
                    if (idx >= 0) {
                        state.updatingStatus.splice(idx, 1);
                        state.updatingStatus = [...state.updatingStatus];
                    }
                },

                joinStatusFinally(state: any, id: any) {
                    const idx = state.joiningStatus.indexOf(id);
                    if (idx >= 0) {
                        state.joiningStatus.splice(idx, 1);
                        state.joiningStatus = [...state.joiningStatus];
                    }
                },
                openStatusFinally(state: any, id: any) {
                    const idx = state.openingStatus.indexOf(id);
                    if (idx >= 0) {
                        state.openingStatus.splice(idx, 1);
                        state.openingStatus = [...state.openingStatus];
                    }
                },
                initDataRequested(state: any) {
                    state.loading = true;
                    state.data = null;
                    state.updated = null;
                },
                initDataSuccessed(state: any, data: any) {
                    state.loading = false;
                    state.data = data;
                },
                initDataFailed(state: any, error: any) {
                    state.loading = false;
                    state.error = error;
                },
                setError(state: any, error: any) {
                    state.error = error;
                },
            },
            actions: {
                
                /**
                 * 新增
                 * @param context action上下文
                 */
                async create(context: any, payload: any) {
                    context.commit('setUpdatedSuccess', false);
                    context.commit('setLoading', true);
                    try {
                        context.commit('created', await services.createSharedQuota({
                            ...payload
                        }));
                        context.commit('setUpdatedSuccess', true);
                    } catch (e) {
                        // context.commit('setError', e);
                    } finally {
                        context.commit('setLoading', false);
                    }
                },
                /**
                 * 编辑活动
                 * @param context action上下文
                 */
                async editAct(context: any, payload: any) {
                    context.commit('setUpdatedSuccess',false);
                    context.commit('setLoading', true);
                    try {
                        context.commit('created', await services.editSharedQuota({
                            ...payload
                        }));
                        context.commit('setUpdatedSuccess',true);
                        context.dispatch('search');
                    } catch (e) {
                        console.info("error editAct")
                        // context.commit('setError', e);
                    } finally {
                        context.commit('setLoading', false);
                    }

                },
                 /**
                 * 添加商户名单
                 * @param context action上下文
                 */
                 async bindMer(context: any, payload: any) {
                    context.commit('setUpdatedSuccess',false);
                    context.commit('setLoading', true);
                    try {
                        context.commit('created', await services.bindMer({
                            ...payload
                        }));
                        context.commit('setUpdatedSuccess',true);
                        context.dispatch('search');
                    } catch (e) {
                        console.info("error bindMer")
                        // context.commit('setError', e);
                    } finally {
                        context.commit('setLoading', false);
                    }

                },
                async initData(context: any, activityMer: any) {
                    context.commit('initDataRequested');
                    try {
                        context.commit('initDataSuccessed', activityMer);
                    } catch (e) {
                        context.commit('initDataFailed', e);
                    }
                },
                async updateStatus(context: any, { ids, status }: any) {
                    context.commit('updateStatusRequested', ids);
                    context.commit('setUpdatedSuccess',false);
                    try {
                        await services.updateStatus({
                            merGroupId: ids,
                            status: status
                        });
                        context.commit('setUpdatedSuccess',true);
                        context.dispatch('search');
                    } catch (e) {
                        context.commit('updateStatusFailed', e);
                    } finally {
                        context.commit('updateStatusFinally', ids);
                    }
                },
            }
        }
    )
);
