
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import SearchTable from '@/components/SearchTable.vue';
    import {mapState} from 'vuex';
    import {defaultCustomRender} from '@/utils';
    import qs from 'querystringify';

    @Component({
        components: {
            SearchTable
        },
        computed: {
            ...mapState({
                list(state) {
                    return this.state().list;
                },
                total(state) {
                    return this.state().total;
                },
                page(state) {
                    return this.state().page;
                },
                pageSize(state) {
                    return this.state().pageSize;
                },
                loading(state) {
                    return this.state().loading;
                }
            })
        }
    })
    class StoreSearchTable extends Vue {

        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                columns: [
                    {
                        title: '商户名称',
                        dataIndex: 'name',
                        fixed: 'left',
                        width: 250,
                        customRender: defaultCustomRender
                    },
                    {
                        title: 'LKL商户号',
                        dataIndex: 'merchant_no',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '惠码SN号',
                        dataIndex: 'sn',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '商户归属',
                        dataIndex: 'mallcoo',
                        customRender: (mallcoo: any, record: any) => {
                            return mallcoo ? (
                                mallcoo.mallcoo_mall_name +
                                '(' +
                                mallcoo.mall_belong +
                                ')'
                            ) : '-';
                        }
                    },
                    {
                        title: '商户状态',
                        dataIndex: 'status',
                        width: 100,
                        customRender: (status: string) => {
                            if (status === 'OPEN') {
                                return 'ON';
                            } else if (status === 'CLOSE') {
                                return 'OFF';
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: '楼层/铺位',
                        dataIndex: 'floor_door_no',
                        width: 120,
                        customRender: defaultCustomRender
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        customRender: (text: any, record: any, idx: any) => {
                            return this.$createElement('a-button', {
                                props: {
                                    size: 'small'
                                },
                                on: {
                                    click: () => this.$router.push({
                                        // tslint:disable-next-line:max-line-length
                                        path: `/console/shop/search?${qs.stringify({
                                            page: 1,
                                            store_name: record.name,
                                            merchant_no: record.merchant_no,
                                            r: Math.random()
                                        })}`
                                    })
                                }
                            }, '跳转商户列表查看');
                        }
                    }
                ]
            };
        }

        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handlePageSizeChange(size: number) {
            this.dispatch('pageSizeChange', size);
        }
    }

    export default StoreSearchTable;
