import { request, downloadRequest } from '@/utils';
import {deleteEmpty} from '@/utils';

export async function statistics() {
    try {
        return await request({
            api: '/console/bill/table/statistics/list'
        });
    } catch (e) {
        const error: any = new Error('获取统计数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function bill(params: {
    pay_channel?: string;
    start_time: string;
    end_time: string;
    field_name?: string;
}) {
    try {
        return await request({
            api: '/console/bill/statistics/list',
            data: deleteEmpty(params)
        });
    } catch (e) {
        const error: any = new Error('获取订单统计数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function exportExcel(params: {
    pay_channel?: string;
    start_time: string;
    end_time: string;
}) {
    try {
        return await downloadRequest({
            data: deleteEmpty(params),
            prefix: 'statistics-amount',
            api: '/console/bill/statistics/list/excel'
        });
    } catch (e) {
        const error: any = new Error('导出数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}