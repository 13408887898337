
    import EditForm from './EditForm.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';

    @Component({
        components: {
            EditForm
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.bizDistrictEditPage.loading
            })
        }
    })
    export default class EditPage extends Vue {
        public created() {
            this.$store.dispatch('bizDistrictEditPage/initData', this.$route.params.id);
        }

        @Watch('$store.state.bizDistrictEditPage.lastSaveTime')
        public lastSaveTimeListener(lastSaveTime: string) {
            this.$message.success('提交成功!');

            this.$router.push({
                path: '/console/bizDistrict/search' + this.$store.getters['bizDistrictSearchPage/queryString']()
            });
        }

        @Watch('$store.state.bizDistrictEditPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }
    }
