
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import StoreDrawer from './StoreDrawer/index.vue';
    import MemberDrawer from './MemberDrawer/index.vue';
    import * as DICTS from '@/common/dicts';
    import columns from './columns';
    import {normColumns, calcTableScrollX, getTop} from '@/utils';

    interface SearchTable {
        selectedRowKeys: any;
    }

    @Component({
        components: {
            StoreDrawer,
            MemberDrawer
        },
        computed: {
            rowSelection() {
                const self: any = this;
                const {selectedRowKeys} = self;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
            ...mapState({
                bizDistricts: (state: any) =>
                    state.bizDistrictSearchPage.list.map((bizDistrict: any, idx: any) => ({
                        idx: (state.shopSearchPage.page - 1) * state.bizDistrictSearchPage.pageSize + idx + 1,
                        ...bizDistrict
                    })),
                total: (state: any) => state.bizDistrictSearchPage.total,
                pageSize: (state: any) => state.bizDistrictSearchPage.pageSize,
                page: (state: any) => state.bizDistrictSearchPage.page,
                loading: (state: any) => state.bizDistrictSearchPage.loading,
                fields: (state: any) => normColumns(
                    columns, state.bizDistrictSearchPage.fields, state.bizDistrictSearchPage.sortFields)
            })
        }
    })
    class SearchTable extends Vue {

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                DICTS,
                columns,
                selectedRowKeys: [],
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.selectedRowKeys = selectedRowKeys;
        }

        public handlePageChange(page: number) {
            this.$store.commit('bizDistrictSearchPage/setPagination', {page});
            this.$router.push({
                path: '/console/bizDistrict/search' + this.$store.getters['bizDistrictSearchPage/queryString']()
            });
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.$store.commit('bizDistrictSearchPage/setPagination', {pageSize, page: 1});
            this.$router.push({
                path: '/console/bizDistrict/search' + this.$store.getters['bizDistrictSearchPage/queryString']()
            });
        }
    }

    export default SearchTable;
