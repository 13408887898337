
import { Component, Inject, Vue } from 'vue-property-decorator';
import DatePicker from '@/components/custom-form-components/DatePicker.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { deleteEmpty } from '@/utils';
import qs from 'querystringify';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        type: string;
        startTime: string;
        endTime: string;
    }

    export interface FormData {
        type: string;
        created_time: any[];
    }

@Component({
    components: {
        DatePicker,
    },
    computed: {
        ...mapState({
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.StatementOperatePage.query);
                },
                loading: (state: any) => state.StatementOperatePage.loading
            })
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public currentRecon = 0;
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                reconType: values.type,
                startTime: values.created_time &&
                    values.created_time[0] &&
                    values.created_time[0].format('YYYY-MM-DD HH:mm:ss'),
                endTime: values.created_time &&
                    values.created_time[1] &&
                    values.created_time[1].format('YYYY-MM-DD HH:mm:ss'),
                page: 1,
                r: Math.random()
            });
            this.dispatch('queryChange', query);

        });
    }
   


    public handleInvokingGW(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = {
                date: values.infoTime.format('YYYYMMDD')
            };

            this.dispatch('invokingGW', query);
        });
    }

    public handleQuery(e: any) {
        e.preventDefault();
        const query = deleteEmpty({
            page: 1,
            pageSize: 10,
            r: Math.random()
        });
        this.dispatch('initQuery', query);
    }

    public handleMenuClick(object: any) {
        console.info('object:', object);
        this.currentRecon = object.key;
    }
    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }
    private formatFormData(query: Query): FormData {
            return {
                type: query.type,
                created_time: [
                    query.startTime && moment(query.startTime),
                    query.endTime && moment(query.endTime)
                ]
            };
        } 
}

export default SearchForm;
