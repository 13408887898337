
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Watch, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import preferences from './preferences';
    import * as DICTS from '@/common/dicts';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        store_name: '商户名称',
        merchant_no: 'LKL商户号',
        sn: '惠码SN号',
        online_terminal_id: '线上终端号',
        offline_terminal_id: '线下终端号',
        store_type: {
            name: '商户类型',
            values: DICTS.SERVICE_PLATFORM
        },
        mallcoo_shop_id: '猫酷ShopID',
        address: '地址关键字',
        province: '省',
        city: '市',
        district: '区',
        mcc_max_name: '行业大类',
        mcc_middle_name: '行业中类',
        mcc_min_name: '行业小类',
        mall_name: {
            name: '归属商场',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/mall/simple_list'
            },
            getParams(value: any) {
                return {
                    page: 1,
                    page_size: 1000
                };
            }
        },
        biz_district: {
            name: '归属商圈',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/district/simple_list'
            },
            getParams(value: any) {
                return {
                    page: 1,
                    page_size: 1000
                };
            }
        },
        refund_open: {
            name: '开通退款',
            values: {
                'True': '开启',
                'False': '关闭'
            }
        },
        start_time: {
            name: '创建时间',
            range: 'from'
        },
        end_time: {
            name: '创建时间',
            range: 'to'
        },
    }

    @Component({
        components: {
            Actions,
            SearchForm,
            FieldsFilter,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.shopSearchPage.fields,
                sortArr: (state: any) => state.shopSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.shopSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class ShopPage extends Vue {
        public data() {
            return {
                columns
            };
        }

        public created() {
            // this.$store.dispatch('shopSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.shopSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener() {
            this.$store.dispatch('shopSearchPage/initQuery', this.$route.query);
            // const {
            //     page,
            //     page_size,
            //     ...rest
            // }: any =  this.$route.query;

            // this.$store.commit('shopSearchPage/setPagination', {
            //     page: (page && parseInt(page, 10)) || 1,
            //     pageSize: (page_size && parseInt(page_size, 10)) || 20
            // });
            // this.$store.commit(
            //     'shopSearchPage/resetQuery',
            //     rest
            // );

            // this.$store.dispatch('shopSearchPage/search');
        }

        private handleFieldChange(e: any) {
            this.$store.commit('shopSearchPage/setFields', e);
        }

        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('shopSearchPage/sortFields', e);
        }
    }
