import {RouteConfig} from 'vue-router';
import Init from './init/index.vue';

const route: RouteConfig[] = [
    {
        path: 'init',
        name: '初始化',
        component: Init,
    }
];

export default route;
