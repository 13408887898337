var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "show-search": "",
        notFoundContent: _vm.loading ? "加载中" : "无数据",
        allowClear: "",
        value: _vm.value,
        placeholder: "请选择头寸",
        getPopupContainer: (trigger) => trigger.parentNode,
      },
      on: {
        dropdownVisibleChange: _vm.dropdownVisibleChange,
        change: _vm.handleChange,
      },
    },
    _vm._l(_vm.items, function (item) {
      return _c(
        "a-select-option",
        { key: item.expenseAccount, attrs: { value: item.expenseAccount } },
        [_vm._v(_vm._s(item.expenseAccount))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }