var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.MER_SUBSIDY_ACT.CREATE] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "ad_create" }, [_c("CreateForm")], 1),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }