
    import {Modal} from 'ant-design-vue';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import * as DICTS from '@/common/dicts';
    import columns from './columns';
    import {normColumns, calcTableScrollX, getTop} from '@/utils';
    import {check, Show, Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import EditDrawer from '../EditPage/index.vue';
    interface SearchTable {
        selectedRowKeys: any;
    }

    @Component({
        components: {
            Click,
            Show,
            EditDrawer
        },
        computed: {
            
            ...mapState({
                list: (state: any) =>
                    state.districtCodeSearchPage.list.map((item: any, idx: any) => ({
                        idx: (state.districtCodeSearchPage.page - 1) * state.districtCodeSearchPage.pageSize + idx + 1,
                        ...item
                    })),
                total: (state: any) => state.districtCodeSearchPage.total,
                pageSize: (state: any) => state.districtCodeSearchPage.pageSize,
                page: (state: any) => state.districtCodeSearchPage.page,
                loading: (state: any) => state.districtCodeSearchPage.loading,
                fields: (state: any) => normColumns(
                    columns, state.districtCodeSearchPage.fields, state.districtCodeSearchPage.sortFields),
                updatingStatus: (state: any) => state.districtCodeSearchPage.updatingStatus,
                rowSelection(state: any) {
                    const self: any = this;
                    const selectedRowKeys = state.districtCodeSearchPage.selectedRows;
                    return {
                        selectedRowKeys,
                        onChange: self.onSelectChange,
                        hideDefaultSelections: true
                    };
                },
            })
        }
    })
    class SearchTable extends Vue {

        @Inject()
        public dispatch!: any;
        public fixed = 0;
        public PRIVILEGE = PRIVILEGE;
        public style = '';
        public edit = null;
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }
        
        public data() {
            return {
                DICTS,
                columns,
                selectedRowKeys: [],
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.$store.commit('districtCodeSearchPage/setSelectedRows', selectedRowKeys);
        }

        public handlePageChange(page: number) {
            this.$store.commit('districtCodeSearchPage/setPagination', {page});
            this.$router.push({
                path: '/console/districtCode/search' + this.$store.getters['districtCodeSearchPage/queryString']()
            });
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.$store.commit('districtCodeSearchPage/setPagination', {pageSize, page: 1});
            this.$router.push({
                path: '/console/districtCode/search' + this.$store.getters['districtCodeSearchPage/queryString']()
            });
        }
         public handleDrawerOpenClick(type: 'edit' , id: any) {
            this[type] = id;
        }
       

        public handleDrawerClose(type: 'edit') {
            this[type] = null;
        }
    }

    export default SearchTable;
