var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "biz-district-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商圈名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          ..._vm.BIZ_DISTRICT_RULES.NAME,
                        ],
                      },
                    ],
                    expression:
                      "['name', {\n                    rules: [\n                        {required: true, message: '请填写必填项!'},\n                        ...BIZ_DISTRICT_RULES.NAME\n                    ]\n                }]",
                  },
                ],
                attrs: { placeholder: "请输入" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "英文名" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name_english",
                      {
                        rules: [..._vm.BIZ_DISTRICT_RULES.NAME_ENGLISH],
                      },
                    ],
                    expression:
                      "['name_english', {\n                    rules: [...BIZ_DISTRICT_RULES.NAME_ENGLISH]\n                }]",
                  },
                ],
                attrs: { placeholder: "请输入" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商圈归属" } },
            [
              _c("DistrictBelong", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "belong",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['belong', {rules: [{required: true, message: '请填写必填项!',}]}]",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "H5_URL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "link_url",
                      {
                        rules: [..._vm.BIZ_DISTRICT_RULES.LINK_URL],
                      },
                    ],
                    expression:
                      "['link_url', {\n                    rules: [...BIZ_DISTRICT_RULES.LINK_URL]\n                }]",
                  },
                ],
                attrs: { placeholder: "请选择" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议标题" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_title",
                      {
                        rules: [..._vm.BIZ_DISTRICT_RULES.AGREEMENT_TITLE],
                      },
                    ],
                    expression:
                      "['agreement_title', {\n                    rules: [...BIZ_DISTRICT_RULES.AGREEMENT_TITLE]\n                }]",
                  },
                ],
                attrs: { placeholder: "请选择" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议URL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_url",
                      {
                        rules: [..._vm.BIZ_DISTRICT_RULES.AGREEMENT_URL],
                      },
                    ],
                    expression:
                      "['agreement_url', {\n                    rules: [...BIZ_DISTRICT_RULES.AGREEMENT_URL]\n                }]",
                  },
                ],
                attrs: { placeholder: "请选择" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商圈Logo" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "logo",
                      { rules: [{ required: true, message: "请填写必填项!" }] },
                    ],
                    expression:
                      "['logo', {rules: [{required: true, message: '请填写必填项!'}]}]",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }