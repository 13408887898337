
    import {Component, Prop, Vue, Watch, Inject} from 'vue-property-decorator';

    @Component<Anchor>({
        components: {}
    })
    class Anchor extends Vue {

        @Prop()
        public mouse!: any;
        @Prop()
        public width!: any;
        @Prop()
        public disabled!: any;

        @Inject()
        public setActive!: any;
        @Inject()
        public getActive!: any;

        public startX = 0;
        public startY = 0;

        public handleMouseDown(e: MouseEvent) {
            if (this.disabled) {
                return;
            }
            this.setActive(this.$refs.el);
            this.startX = this.mouse.x;
            this.startY = this.mouse.y;
            window.document.body.style.cursor = 'move';
            this.$emit('mousedown', e);
        }

        @Watch('mouse')
        public mouseMoveListener(mouse: any) {
            if (this.getActive() !== this.$refs.el) {
                return;
            }
            const dx = mouse.x - this.startX;
            const dy = mouse.y - this.startY;

            this.$emit('change', {
                x: dx,
                y: dy
            });
        }

    }

    export default Anchor;
