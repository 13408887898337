const regions = [{
    "value": "3000_江苏省",
    "label": "江苏省",
    "children": [{
        "value": "3010_南京市",
        "label": "南京市",
        "children": [{"value": "3014_溧水县", "label": "溧水县"}, {
            "value": "3015_高淳县",
            "label": "高淳县"
        }, {"value": "983010_南京市", "label": "南京市"}, {"value": "3015_雨花台区", "label": "雨花台区"}, {
            "value": "3015_浦口区",
            "label": "浦口区"
        }, {"value": "3015_京口区", "label": "京口区"}, {"value": "3015_江宁区", "label": "江宁区"}, {
            "value": "3015_鼓楼区",
            "label": "鼓楼区"
        }, {"value": "3015_栖霞区", "label": "栖霞区"}, {"value": "3015_秦淮区", "label": "秦淮区"}, {
            "value": "3015_句容区",
            "label": "句容区"
        }]
    }, {
        "value": "3020_无锡市",
        "label": "无锡市",
        "children": [{"value": "3022_江阴市", "label": "江阴市"}, {
            "value": "3023_宜兴市",
            "label": "宜兴市"
        }, {"value": "983020_无锡市", "label": "无锡市"}, {"value": "3023_滨湖区", "label": "滨湖区"}]
    }, {
        "value": "3030_徐州市",
        "label": "徐州市",
        "children": [{"value": "3031_丰县", "label": "丰县"}, {"value": "3032_沛县", "label": "沛县"}, {
            "value": "3033_铜山县",
            "label": "铜山县"
        }, {"value": "3034_睢宁县", "label": "睢宁县"}, {"value": "3035_邳州市", "label": "邳州市"}, {
            "value": "3036_新沂市",
            "label": "新沂市"
        }, {"value": "983030_徐州市", "label": "徐州市"}, {"value": "3032_鼓楼区", "label": "鼓楼区"}]
    }, {
        "value": "3040_常州市",
        "label": "常州市",
        "children": [{"value": "3041_武进区", "label": "武进区"}, {"value": "3042_金坛市", "label": "金坛市"}, {
            "value": "3043_溧阳市",
            "label": "溧阳市"
        }, {"value": "983040_常州市", "label": "常州市"}, {"value": "3042_新北区", "label": "新北区"}]
    }, {
        "value": "3050_苏州市",
        "label": "苏州市",
        "children": [{"value": "3051_太仓市", "label": "太仓市"}, {"value": "3052_昆山市", "label": "昆山市"}, {
            "value": "3054_吴江市",
            "label": "吴江市"
        }, {"value": "3055_常熟市", "label": "常熟市"}, {"value": "3056_张家港市", "label": "张家港市"}, {
            "value": "983050_苏州市",
            "label": "苏州市"
        }, {"value": "3055_吴中区", "label": "吴中区"}, {"value": "3055_姑苏区", "label": "姑苏区"}, {
            "value": "3055_虎丘区",
            "label": "虎丘区"
        }]
    }, {
        "value": "3060_南通市",
        "label": "南通市",
        "children": [{"value": "3061_海安县", "label": "海安县"}, {"value": "3062_如皋市", "label": "如皋市"}, {
            "value": "3063_如东县",
            "label": "如东县"
        }, {"value": "3065_海门市", "label": "海门市"}, {"value": "3066_启东市", "label": "启东市"}, {
            "value": "983060_南通市",
            "label": "南通市"
        }, {"value": "3065_崇川区", "label": "崇川区"}]
    }, {
        "value": "3070_连云港市",
        "label": "连云港市",
        "children": [{"value": "3071_赣榆县", "label": "赣榆县"}, {"value": "3072_东海县", "label": "东海县"}, {
            "value": "3073_灌云县",
            "label": "灌云县"
        }, {"value": "3074_灌南县", "label": "灌南县"}, {"value": "983070_连云港市", "label": "连云港市"}, {
            "value": "3072_海州区",
            "label": "海州区"
        }]
    }, {
        "value": "3080_淮安市",
        "label": "淮安市",
        "children": [{"value": "3085_涟水县", "label": "涟水县"}, {"value": "3087_洪泽县", "label": "洪泽县"}, {
            "value": "3088_盱眙县",
            "label": "盱眙县"
        }, {"value": "3089_金湖县", "label": "金湖县"}, {"value": "983080_淮安市", "label": "淮安市"}, {
            "value": "3085_淮安区",
            "label": "淮安区"
        }]
    }, {
        "value": "3110_盐城市",
        "label": "盐城市",
        "children": [{"value": "3111_响水县", "label": "响水县"}, {"value": "3112_滨海县", "label": "滨海县"}, {
            "value": "3113_阜宁县",
            "label": "阜宁县"
        }, {"value": "3114_射阳县", "label": "射阳县"}, {"value": "3115_建湖县", "label": "建湖县"}, {
            "value": "3116_大丰市",
            "label": "大丰市"
        }, {"value": "3117_东台市", "label": "东台市"}, {"value": "983110_盐城市", "label": "盐城市"}]
    }, {
        "value": "3120_扬州市",
        "label": "扬州市",
        "children": [{"value": "3121_高邮市", "label": "高邮市"}, {"value": "3122_宝应县", "label": "宝应县"}, {
            "value": "3125_江都市",
            "label": "江都市"
        }, {"value": "3129_仪征市", "label": "仪征市"}, {"value": "983120_扬州市", "label": "扬州市"}, {
            "value": "3122_广陵区",
            "label": "广陵区"
        }]
    }, {
        "value": "3140_镇江市",
        "label": "镇江市",
        "children": [{"value": "3142_句容市", "label": "句容市"}, {"value": "3143_扬中市", "label": "扬中市"}, {
            "value": "3144_丹阳市",
            "label": "丹阳市"
        }, {"value": "983140_镇江市", "label": "镇江市"}]
    }, {
        "value": "3160_泰州市",
        "label": "泰州市",
        "children": [{"value": "3161_兴化市", "label": "兴化市"}, {"value": "3162_靖江市", "label": "靖江市"}, {
            "value": "3163_泰兴市",
            "label": "泰兴市"
        }, {"value": "3164_姜堰市", "label": "姜堰市"}, {"value": "983160_泰州市", "label": "泰州市"}]
    }, {
        "value": "3180_宿迁市",
        "label": "宿迁市",
        "children": [{"value": "3181_沭阳县", "label": "沭阳县"}, {"value": "3182_泗阳县", "label": "泗阳县"}, {
            "value": "3183_泗洪县",
            "label": "泗洪县"
        }, {"value": "983180_宿迁市", "label": "宿迁市"}, {"value": "3181_宿城区", "label": "宿城区"}]
    }]
}, {
    "value": "3300_浙江省",
    "label": "浙江省",
    "children": [{
        "value": "3310_杭州市",
        "label": "杭州市",
        "children": [{"value": "3311_桐庐县", "label": "桐庐县"}, {"value": "3312_富阳市", "label": "富阳市"}, {
            "value": "3313_临安市",
            "label": "临安市"
        }, {"value": "3315_建德市", "label": "建德市"}, {"value": "3316_淳安县", "label": "淳安县"}, {
            "value": "983310_杭州市",
            "label": "杭州市"
        }, {"value": "3311_余杭区", "label": "余杭区"}, {"value": "3311_萧山区", "label": "萧山区"}, {
            "value": "3311_上城区",
            "label": "上城区"
        }, {"value": "3311_江干区", "label": "江干区"}]
    }, {
        "value": "3330_温州市",
        "label": "温州市",
        "children": [{"value": "3333_乐清市", "label": "乐清市"}, {"value": "3334_永嘉县", "label": "永嘉县"}, {
            "value": "3335_平阳县",
            "label": "平阳县"
        }, {"value": "3337_文成县", "label": "文成县"}, {"value": "3338_泰顺县", "label": "泰顺县"}, {
            "value": "3339_瑞安市",
            "label": "瑞安市"
        }, {"value": "983330_温州市", "label": "温州市"}, {"value": "3332_洞头县", "label": "洞头县"}, {
            "value": "3336_苍南县",
            "label": "苍南县"
        }, {"value": "3334_鹿城区", "label": "鹿城区"}, {"value": "3334_龙湾区", "label": "龙湾区"}]
    }, {
        "value": "3360_湖州市",
        "label": "湖州市",
        "children": [{"value": "3361_德清县", "label": "德清县"}, {
            "value": "3363_安吉县",
            "label": "安吉县"
        }, {"value": "983360_湖州市", "label": "湖州市"}, {"value": "3362_长兴县", "label": "长兴县"}, {
            "value": "3361_吴兴区",
            "label": "吴兴区"
        }]
    }, {
        "value": "3370_绍兴市",
        "label": "绍兴市",
        "children": [{"value": "3371_绍兴县", "label": "绍兴县"}, {"value": "3373_嵊州市", "label": "嵊州市"}, {
            "value": "3374_新昌县",
            "label": "新昌县"
        }, {"value": "3375_诸暨市", "label": "诸暨市"}, {"value": "983370_绍兴市", "label": "绍兴市"}, {
            "value": "3372_上虞市",
            "label": "上虞市"
        }]
    }, {
        "value": "3420_舟山市",
        "label": "舟山市",
        "children": [{"value": "3421_岱山县", "label": "岱山县"}, {
            "value": "3422_嵊泗县",
            "label": "嵊泗县"
        }, {"value": "983420_舟山市", "label": "舟山市"}]
    }, {
        "value": "3450_台州市",
        "label": "台州市",
        "children": [{"value": "3451_椒江区", "label": "椒江区"}, {"value": "3452_临海市", "label": "临海市"}, {
            "value": "3455_仙居县",
            "label": "仙居县"
        }, {"value": "3456_天台县", "label": "天台县"}, {"value": "3457_三门县", "label": "三门县"}, {
            "value": "983450_台州市",
            "label": "台州市"
        }, {"value": "3454_温岭市", "label": "温岭市"}, {"value": "3458_玉环县", "label": "玉环县"}]
    }, {
        "value": "3320_宁波市",
        "label": "宁波市",
        "children": [{"value": "3324_余姚市", "label": "余姚市"}, {"value": "3323_江东区", "label": "江东区"}, {
            "value": "3323_北仑区",
            "label": "北仑区"
        }, {"value": "3321_象山县", "label": "象山县"}, {"value": "3322_宁海县", "label": "宁海县"}, {
            "value": "3323_鄞州区",
            "label": "鄞州区"
        }, {"value": "3325_慈溪市", "label": "慈溪市"}, {"value": "3326_奉化市", "label": "奉化市"}, {
            "value": "983320_宁波市",
            "label": "宁波市"
        }]
    }, {
        "value": "3350_嘉兴市",
        "label": "嘉兴市",
        "children": [{"value": "3354_桐乡市", "label": "桐乡市"}, {"value": "3351_嘉善县", "label": "嘉善县"}, {
            "value": "3352_平湖市",
            "label": "平湖市"
        }, {"value": "3353_海盐县", "label": "海盐县"}, {"value": "3355_海宁市", "label": "海宁市"}, {
            "value": "983350_嘉兴市",
            "label": "嘉兴市"
        }]
    }, {
        "value": "3380_金华市",
        "label": "金华市",
        "children": [{"value": "3385_磐安县", "label": "磐安县"}, {"value": "3382_永康市", "label": "永康市"}, {
            "value": "3383_武义县",
            "label": "武义县"
        }, {"value": "3384_浦江县", "label": "浦江县"}, {"value": "3386_兰溪市", "label": "兰溪市"}, {
            "value": "3387_义乌市",
            "label": "义乌市"
        }, {"value": "3388_东阳市", "label": "东阳市"}, {"value": "983380_金华市", "label": "金华市"}]
    }, {
        "value": "3410_衢州市",
        "label": "衢州市",
        "children": [{"value": "3415_江山市", "label": "江山市"}, {"value": "3412_柯城区", "label": "柯城区"}, {
            "value": "3412_常山县",
            "label": "常山县"
        }, {"value": "3413_开化县", "label": "开化县"}, {"value": "3414_龙游县", "label": "龙游县"}, {
            "value": "983410_衢州市",
            "label": "衢州市"
        }]
    }, {
        "value": "3430_丽水市",
        "label": "丽水市",
        "children": [{"value": "3435_缙云县", "label": "缙云县"}, {"value": "3439_龙泉市", "label": "龙泉市"}, {
            "value": "3432_青田县",
            "label": "青田县"
        }, {"value": "3433_云和县", "label": "云和县"}, {"value": "3434_庆元县", "label": "庆元县"}, {
            "value": "3436_遂昌县",
            "label": "遂昌县"
        }, {"value": "3437_松阳县", "label": "松阳县"}, {"value": "3438_景宁畲族自治县", "label": "景宁畲族自治县"}, {
            "value": "983430_丽水市",
            "label": "丽水市"
        }]
    }]
}, {
    "value": "3600_安徽省",
    "label": "安徽省",
    "children": [{
        "value": "3610_合肥市",
        "label": "合肥市",
        "children": [{"value": "3611_长丰县", "label": "长丰县"}, {
            "value": "3613_肥西县",
            "label": "肥西县"
        }, {"value": "983610_合肥市", "label": "合肥市"}, {"value": "3612_肥东县", "label": "肥东县"}, {
            "value": "3611_蜀山区",
            "label": "蜀山区"
        }, {"value": "3611_包河区", "label": "包河区"}, {"value": "3611_瑶海区", "label": "瑶海区"}]
    }, {
        "value": "3620_芜湖市",
        "label": "芜湖市",
        "children": [{"value": "3621_芜湖县", "label": "芜湖县"}, {
            "value": "3623_南陵县",
            "label": "南陵县"
        }, {"value": "983620_芜湖市", "label": "芜湖市"}, {"value": "3622_繁昌县", "label": "繁昌县"}]
    }, {
        "value": "3630_蚌埠市",
        "label": "蚌埠市",
        "children": [{"value": "3631_怀远县", "label": "怀远县"}, {
            "value": "3633_固镇县",
            "label": "固镇县"
        }, {"value": "983630_蚌埠市", "label": "蚌埠市"}, {"value": "3632_五河县", "label": "五河县"}]
    }, {
        "value": "3640_淮南市",
        "label": "淮南市",
        "children": [{"value": "3641_凤台县", "label": "凤台县"}, {"value": "983640_淮南市", "label": "淮南市"}]
    }, {
        "value": "3660_淮北市",
        "label": "淮北市",
        "children": [{"value": "3661_濉溪县", "label": "濉溪县"}, {"value": "983660_淮北市", "label": "淮北市"}]
    }, {
        "value": "3670_铜陵市",
        "label": "铜陵市",
        "children": [{"value": "983670_铜陵市", "label": "铜陵市"}, {"value": "3671_铜陵县", "label": "铜陵县"}]
    }, {
        "value": "3680_安庆市",
        "label": "安庆市",
        "children": [{"value": "3681_桐城市", "label": "桐城市"}, {"value": "3682_怀宁县", "label": "怀宁县"}, {
            "value": "3684_潜山县",
            "label": "潜山县"
        }, {"value": "3685_太湖县", "label": "太湖县"}, {"value": "3686_宿松县", "label": "宿松县"}, {
            "value": "3688_岳西县",
            "label": "岳西县"
        }, {"value": "983680_安庆市", "label": "安庆市"}, {"value": "3683_枞阳县", "label": "枞阳县"}, {
            "value": "3687_望江县",
            "label": "望江县"
        }]
    }, {
        "value": "3710_黄山市",
        "label": "黄山市",
        "children": [{"value": "3711_歙县", "label": "歙县"}, {"value": "3713_黟县", "label": "黟县"}, {
            "value": "3714_祁门县",
            "label": "祁门县"
        }, {"value": "983710_黄山市", "label": "黄山市"}, {"value": "3712_休宁县", "label": "休宁县"}]
    }, {
        "value": "3720_阜阳市",
        "label": "阜阳市",
        "children": [{"value": "3725_太和县", "label": "太和县"}, {"value": "3728_阜南县", "label": "阜南县"}, {
            "value": "3729_颍上县",
            "label": "颍上县"
        }, {"value": "983720_阜阳市", "label": "阜阳市"}, {"value": "3724_临泉县", "label": "临泉县"}, {
            "value": "3731_界首市",
            "label": "界首市"
        }]
    }, {
        "value": "3740_宿州市",
        "label": "宿州市",
        "children": [{"value": "3742_砀山县", "label": "砀山县"}, {"value": "3743_萧县", "label": "萧县"}, {
            "value": "3746_泗县",
            "label": "泗县"
        }, {"value": "983740_宿州市", "label": "宿州市"}, {"value": "3745_灵璧县", "label": "灵璧县"}]
    }, {
        "value": "3750_滁州市",
        "label": "滁州市",
        "children": [{"value": "3752_天长市", "label": "天长市"}, {"value": "3754_全椒县", "label": "全椒县"}, {
            "value": "3755_定远县",
            "label": "定远县"
        }, {"value": "3756_凤阳县", "label": "凤阳县"}, {"value": "3757_明光市", "label": "明光市"}, {
            "value": "983750_滁州市",
            "label": "滁州市"
        }, {"value": "3753_来安县", "label": "来安县"}]
    }, {
        "value": "3760_六安市",
        "label": "六安市",
        "children": [{"value": "3763_寿县", "label": "寿县"}, {"value": "3764_霍邱县", "label": "霍邱县"}, {
            "value": "3765_舒城县",
            "label": "舒城县"
        }, {"value": "3766_金寨县", "label": "金寨县"}, {"value": "3767_霍山县", "label": "霍山县"}, {
            "value": "983760_六安市",
            "label": "六安市"
        }]
    }, {
        "value": "3770_宣城市",
        "label": "宣城市",
        "children": [{"value": "3772_郎溪县", "label": "郎溪县"}, {"value": "3773_广德县", "label": "广德县"}, {
            "value": "3774_宁国市",
            "label": "宁国市"
        }, {"value": "3775_泾县", "label": "泾县"}, {"value": "3776_旌德县", "label": "旌德县"}, {
            "value": "3777_绩溪县",
            "label": "绩溪县"
        }, {"value": "983770_宣城市", "label": "宣城市"}]
    }, {
        "value": "3780_巢湖市",
        "label": "巢湖市",
        "children": [{"value": "3782_庐江县", "label": "庐江县"}, {"value": "3783_无为县", "label": "无为县"}, {
            "value": "3784_含山县",
            "label": "含山县"
        }, {"value": "3785_和县", "label": "和县"}, {"value": "983780_巢湖市", "label": "巢湖市"}]
    }, {
        "value": "3790_池州市",
        "label": "池州市",
        "children": [{"value": "3792_东至县", "label": "东至县"}, {"value": "3793_石台县", "label": "石台县"}, {
            "value": "3794_青阳县",
            "label": "青阳县"
        }, {"value": "983790_池州市", "label": "池州市"}]
    }, {
        "value": "3810_亳州市",
        "label": "亳州市",
        "children": [{"value": "3811_涡阳县", "label": "涡阳县"}, {"value": "3812_蒙城县", "label": "蒙城县"}, {
            "value": "3813_利辛县",
            "label": "利辛县"
        }, {"value": "983810_亳州市", "label": "亳州市"}]
    }, {
        "value": "3650_马鞍山市",
        "label": "马鞍山市",
        "children": [{"value": "3651_当涂县", "label": "当涂县"}, {"value": "983650_马鞍山市", "label": "马鞍山市"}]
    }]
}, {
    "value": "3900_福建省",
    "label": "福建省",
    "children": [{
        "value": "3910_福州市",
        "label": "福州市",
        "children": [{"value": "3911_闽侯县", "label": "闽侯县"}, {"value": "3912_连江县", "label": "连江县"}, {
            "value": "3913_罗源县",
            "label": "罗源县"
        }, {"value": "3914_闽清县", "label": "闽清县"}, {"value": "3915_永泰县", "label": "永泰县"}, {
            "value": "3916_长乐市",
            "label": "长乐市"
        }, {"value": "3917_福清市", "label": "福清市"}, {"value": "3918_平潭县", "label": "平潭县"}, {
            "value": "983910_福州市",
            "label": "福州市"
        }, {"value": "3916_台江区", "label": "台江区"}, {"value": "3916_晋安区", "label": "晋安区"}, {
            "value": "3916_仓山区",
            "label": "仓山区"
        }]
    }, {
        "value": "3930_厦门市",
        "label": "厦门市",
        "children": [{"value": "983930_厦门市", "label": "厦门市"}]
    }, {
        "value": "3940_莆田市",
        "label": "莆田市",
        "children": [{"value": "3942_仙游县", "label": "仙游县"}, {
            "value": "983940_莆田市",
            "label": "莆田市"
        }, {"value": "3942_城厢区", "label": "城厢区"}]
    }, {
        "value": "3950_三明市",
        "label": "三明市",
        "children": [{"value": "3951_明溪县", "label": "明溪县"}, {"value": "3952_清流县", "label": "清流县"}, {
            "value": "3953_宁化县",
            "label": "宁化县"
        }, {"value": "3954_大田县", "label": "大田县"}, {"value": "3955_尤溪县", "label": "尤溪县"}, {
            "value": "3956_沙县",
            "label": "沙县"
        }, {"value": "3957_将乐县", "label": "将乐县"}, {"value": "3958_泰宁县", "label": "泰宁县"}, {
            "value": "3959_建宁县",
            "label": "建宁县"
        }, {"value": "3961_永安市", "label": "永安市"}, {"value": "983950_三明市", "label": "三明市"}]
    }, {
        "value": "3970_泉州市",
        "label": "泉州市",
        "children": [{"value": "3971_惠安县", "label": "惠安县"}, {"value": "3972_晋江市", "label": "晋江市"}, {
            "value": "3973_南安市",
            "label": "南安市"
        }, {"value": "3974_安溪县", "label": "安溪县"}, {"value": "3975_永春县", "label": "永春县"}, {
            "value": "3976_德化县",
            "label": "德化县"
        }, {"value": "3977_金门县", "label": "金门县"}, {"value": "3978_石狮市", "label": "石狮市"}, {
            "value": "983970_泉州市",
            "label": "泉州市"
        }, {"value": "3971_思明区", "label": "思明区"}, {"value": "3971_海沧区", "label": "海沧区"}, {
            "value": "3972_丰泽区",
            "label": "丰泽区"
        }, {"value": "3971_湖里区", "label": "湖里区"}, {"value": "3972_鲤城区", "label": "鲤城区"}]
    }, {
        "value": "3990_漳州市",
        "label": "漳州市",
        "children": [{"value": "3991_龙海市", "label": "龙海市"}, {"value": "3992_云霄县", "label": "云霄县"}, {
            "value": "3993_漳浦县",
            "label": "漳浦县"
        }, {"value": "3994_诏安县", "label": "诏安县"}, {"value": "3995_长泰县", "label": "长泰县"}, {
            "value": "3996_东山县",
            "label": "东山县"
        }, {"value": "3997_南靖县", "label": "南靖县"}, {"value": "3998_平和县", "label": "平和县"}, {
            "value": "3999_华安县",
            "label": "华安县"
        }, {"value": "983990_漳州市", "label": "漳州市"}]
    }, {
        "value": "4010_南平市",
        "label": "南平市",
        "children": [{"value": "4012_邵武市", "label": "邵武市"}, {"value": "4013_顺昌县", "label": "顺昌县"}, {
            "value": "4014_建阳市",
            "label": "建阳市"
        }, {"value": "4015_建瓯市", "label": "建瓯市"}, {"value": "4016_浦城县", "label": "浦城县"}, {
            "value": "4017_武夷山市",
            "label": "武夷山市"
        }, {"value": "4018_光泽县", "label": "光泽县"}, {"value": "4019_松溪县", "label": "松溪县"}, {
            "value": "4021_政和县",
            "label": "政和县"
        }, {"value": "984010_南平市", "label": "南平市"}]
    }, {
        "value": "4030_宁德市",
        "label": "宁德市",
        "children": [{"value": "4032_福鼎市", "label": "福鼎市"}, {"value": "4033_霞浦县", "label": "霞浦县"}, {
            "value": "4034_福安市",
            "label": "福安市"
        }, {"value": "4035_古田县", "label": "古田县"}, {"value": "4036_屏南县", "label": "屏南县"}, {
            "value": "4037_寿宁县",
            "label": "寿宁县"
        }, {"value": "4038_周宁县", "label": "周宁县"}, {"value": "4039_柘荣县", "label": "柘荣县"}, {
            "value": "984030_宁德市",
            "label": "宁德市"
        }]
    }, {
        "value": "4050_龙岩市",
        "label": "龙岩市",
        "children": [{"value": "4052_长汀县", "label": "长汀县"}, {"value": "4053_永定县", "label": "永定县"}, {
            "value": "4054_上杭县",
            "label": "上杭县"
        }, {"value": "4055_武平县", "label": "武平县"}, {"value": "4056_漳平市", "label": "漳平市"}, {
            "value": "4057_连城县",
            "label": "连城县"
        }, {"value": "984050_龙岩市", "label": "龙岩市"}, {"value": "4053_新罗区", "label": "新罗区"}]
    }]
}, {
    "value": "4200_江西省",
    "label": "江西省",
    "children": [{
        "value": "4210_南昌市",
        "label": "南昌市",
        "children": [{"value": "4211_南昌县", "label": "南昌县"}, {"value": "4212_新建县", "label": "新建县"}, {
            "value": "4213_安义县",
            "label": "安义县"
        }, {"value": "4214_进贤县", "label": "进贤县"}, {"value": "984210_南昌市", "label": "南昌市"}, {
            "value": "4211_青山湖区",
            "label": "青山湖区"
        }, {"value": "4211_东湖区", "label": "东湖区"}, {"value": "4211_青山湖区", "label": "青山湖区"}]
    }, {
        "value": "4220_景德镇市",
        "label": "景德镇市",
        "children": [{"value": "4221_乐平市", "label": "乐平市"}, {
            "value": "4222_浮梁县",
            "label": "浮梁县"
        }, {"value": "984220_景德镇市", "label": "景德镇市"}]
    }, {
        "value": "4230_萍乡市",
        "label": "萍乡市",
        "children": [{"value": "4231_莲花县", "label": "莲花县"}, {"value": "4232_上栗县", "label": "上栗县"}, {
            "value": "4233_芦溪县",
            "label": "芦溪县"
        }, {"value": "984230_萍乡市", "label": "萍乡市"}]
    }, {
        "value": "4240_九江市",
        "label": "九江市",
        "children": [{"value": "4241_九江县", "label": "九江县"}, {"value": "4242_瑞昌市", "label": "瑞昌市"}, {
            "value": "4243_武宁县",
            "label": "武宁县"
        }, {"value": "4244_修水县", "label": "修水县"}, {"value": "4245_永修县", "label": "永修县"}, {
            "value": "4246_德安县",
            "label": "德安县"
        }, {"value": "4247_星子县", "label": "星子县"}, {"value": "4248_都昌县", "label": "都昌县"}, {
            "value": "4249_湖口县",
            "label": "湖口县"
        }, {"value": "4251_彭泽县", "label": "彭泽县"}, {"value": "984240_九江市", "label": "九江市"}, {
            "value": "4243_浔阳区",
            "label": "浔阳区"
        }]
    }, {
        "value": "4260_新余市",
        "label": "新余市",
        "children": [{"value": "4261_分宜县 ", "label": "分宜县 "}, {"value": "984260_新余市", "label": "新余市"}]
    }, {
        "value": "4270_鹰潭市",
        "label": "鹰潭市",
        "children": [{"value": "4271_贵溪市", "label": "贵溪市"}, {
            "value": "4272_余江县",
            "label": "余江县"
        }, {"value": "984270_鹰潭市", "label": "鹰潭市"}]
    }, {
        "value": "4280_赣州市",
        "label": "赣州市",
        "children": [{"value": "4282_赣县", "label": "赣县"}, {"value": "4283_南康市", "label": "南康市"}, {
            "value": "4284_信丰县",
            "label": "信丰县"
        }, {"value": "4285_大余县", "label": "大余县"}, {"value": "4286_上犹县", "label": "上犹县"}, {
            "value": "4288_安远县",
            "label": "安远县"
        }, {"value": "4289_龙南县", "label": "龙南县"}, {"value": "4291_定南县", "label": "定南县"}, {
            "value": "4293_宁都县",
            "label": "宁都县"
        }, {"value": "4294_于都县", "label": "于都县"}, {"value": "4295_兴国县", "label": "兴国县"}, {
            "value": "4297_会昌县",
            "label": "会昌县"
        }, {"value": "4298_寻乌县", "label": "寻乌县"}, {"value": "4299_石城县", "label": "石城县"}, {
            "value": "984280_赣州市",
            "label": "赣州市"
        }, {"value": "4287_崇义县", "label": "崇义县"}, {"value": "4292_全南县", "label": "全南县"}, {
            "value": "4296_瑞金市",
            "label": "瑞金市"
        }, {"value": "4284_章贡区", "label": "章贡区"}]
    }, {
        "value": "4330_上饶市",
        "label": "上饶市",
        "children": [{"value": "4332_上饶县", "label": "上饶县"}, {"value": "4334_玉山县", "label": "玉山县"}, {
            "value": "4335_铅山县",
            "label": "铅山县"
        }, {"value": "4336_横峰县", "label": "横峰县"}, {"value": "4338_余干县", "label": "余干县"}, {
            "value": "4339_鄱阳县",
            "label": "鄱阳县"
        }, {"value": "4341_万年县", "label": "万年县"}, {"value": "4343_婺源县", "label": "婺源县"}, {
            "value": "984330_上饶市",
            "label": "上饶市"
        }, {"value": "4333_广丰县", "label": "广丰县"}, {"value": "4337_弋阳县", "label": "弋阳县"}, {
            "value": "4342_德兴市",
            "label": "德兴市"
        }, {"value": "4332_信州区", "label": "信州区"}]
    }, {
        "value": "4350_吉安市",
        "label": "吉安市",
        "children": [{"value": "4352_井冈山市", "label": "井冈山市"}, {
            "value": "4354_吉水县",
            "label": "吉水县"
        }, {"value": "4355_峡江县", "label": "峡江县"}, {"value": "4356_新干县", "label": "新干县"}, {
            "value": "4358_泰和县",
            "label": "泰和县"
        }, {"value": "4359_遂川县", "label": "遂川县"}, {"value": "4361_万安县", "label": "万安县"}, {
            "value": "4363_永新县",
            "label": "永新县"
        }, {"value": "984350_吉安市", "label": "吉安市"}, {"value": "4353_吉安县", "label": "吉安县"}, {
            "value": "4357_永丰县",
            "label": "永丰县"
        }, {"value": "4362_安福县", "label": "安福县"}]
    }, {
        "value": "4370_抚州市",
        "label": "抚州市",
        "children": [{"value": "4372_南城县", "label": "南城县"}, {"value": "4374_南丰县", "label": "南丰县"}, {
            "value": "4375_崇仁县",
            "label": "崇仁县"
        }, {"value": "4376_乐安县", "label": "乐安县"}, {"value": "4378_金溪县", "label": "金溪县"}, {
            "value": "4379_资溪县",
            "label": "资溪县"
        }, {"value": "4381_东乡县", "label": "东乡县"}, {"value": "984370_抚州市", "label": "抚州市"}, {
            "value": "4373_黎川县",
            "label": "黎川县"
        }, {"value": "4377_宜黄县", "label": "宜黄县"}, {"value": "4382_广昌县", "label": "广昌县"}]
    }, {
        "value": "4310_宜春市",
        "label": "宜春市",
        "children": [{"value": "4315_奉新县", "label": "奉新县"}, {
            "value": "4319_靖安县",
            "label": "靖安县"
        }, {"value": "984310_宜春市", "label": "宜春市"}, {"value": "4312_丰城市", "label": "丰城市"}, {
            "value": "4313_樟树市",
            "label": "樟树市"
        }, {"value": "4314_高安市", "label": "高安市"}, {"value": "4316_万载县", "label": "万载县"}, {
            "value": "4317_上高县",
            "label": "上高县"
        }, {"value": "4318_宜丰县", "label": "宜丰县"}, {"value": "4321_铜鼓县", "label": "铜鼓县"}]
    }]
}, {
    "value": "4500_山东省",
    "label": "山东省",
    "children": [{
        "value": "4510_济南市",
        "label": "济南市",
        "children": [{"value": "4511_章丘市", "label": "章丘市"}, {"value": "4514_济阳县", "label": "济阳县"}, {
            "value": "4515_商河县",
            "label": "商河县"
        }, {"value": "984510_济南市", "label": "济南市"}, {"value": "4513_平阴县", "label": "平阴县"}]
    }, {
        "value": "4520_青岛市",
        "label": "青岛市",
        "children": [{"value": "4522_胶南市", "label": "胶南市"}, {"value": "4523_莱西市", "label": "莱西市"}, {
            "value": "4524_平度市",
            "label": "平度市"
        }, {"value": "4521_即墨市", "label": "即墨市"}, {"value": "4525_胶州市", "label": "胶州市"}, {
            "value": "984520_青岛市",
            "label": "青岛市"
        }, {"value": "4525_市北区", "label": "市北区"}]
    }, {
        "value": "4530_淄博市",
        "label": "淄博市",
        "children": [{"value": "4531_桓台县", "label": "桓台县"}, {
            "value": "4532_高青县",
            "label": "高青县"
        }, {"value": "984530_淄博市", "label": "淄博市"}, {"value": "4533_沂源县", "label": "沂源县"}, {
            "value": "4532_张店区",
            "label": "张店区"
        }]
    }, {
        "value": "4540_枣庄市",
        "label": "枣庄市",
        "children": [{"value": "4541_滕州市", "label": "滕州市"}, {
            "value": "984540_枣庄市",
            "label": "枣庄市"
        }, {"value": "4541_薛城区", "label": "薛城区"}]
    }, {
        "value": "4550_东营市",
        "label": "东营市",
        "children": [{"value": "4552_利津县", "label": "利津县"}, {
            "value": "4553_广饶县",
            "label": "广饶县"
        }, {"value": "984550_东营市", "label": "东营市"}, {"value": "4551_垦利县", "label": "垦利县"}, {
            "value": "4551_河口区",
            "label": "河口区"
        }]
    }, {
        "value": "4560_烟台市",
        "label": "烟台市",
        "children": [{"value": "4562_招远市", "label": "招远市"}, {"value": "4563_栖霞市", "label": "栖霞市"}, {
            "value": "4564_海阳市",
            "label": "海阳市"
        }, {"value": "4567_龙口市", "label": "龙口市"}, {"value": "4568_莱阳市", "label": "莱阳市"}, {
            "value": "4569_莱州市",
            "label": "莱州市"
        }, {"value": "4561_蓬莱市", "label": "蓬莱市"}, {"value": "4566_长岛县", "label": "长岛县"}, {
            "value": "984560_烟台市",
            "label": "烟台市"
        }, {"value": "4566_芝罘区", "label": "芝罘区"}, {"value": "4566_福山区", "label": "福山区"}]
    }, {
        "value": "4610_济宁市",
        "label": "济宁市",
        "children": [{"value": "4611_兖州市", "label": "兖州市"}, {"value": "4612_邹城市", "label": "邹城市"}, {
            "value": "4614_鱼台县",
            "label": "鱼台县"
        }, {"value": "4615_金乡县", "label": "金乡县"}, {"value": "4616_嘉祥县", "label": "嘉祥县"}, {
            "value": "4618_泗水县",
            "label": "泗水县"
        }, {"value": "4619_曲阜市", "label": "曲阜市"}, {"value": "4621_梁山县", "label": "梁山县"}, {
            "value": "4613_微山县",
            "label": "微山县"
        }, {"value": "4617_汶上县", "label": "汶上县"}, {"value": "984610_济宁市", "label": "济宁市"}]
    }, {
        "value": "4650_威海市",
        "label": "威海市",
        "children": [{"value": "4651_乳山市", "label": "乳山市"}, {"value": "4652_文登市", "label": "文登市"}, {
            "value": "4653_荣成市",
            "label": "荣成市"
        }, {"value": "984650_威海市", "label": "威海市"}, {"value": "4653_环翠区", "label": "环翠区"}]
    }, {
        "value": "4660_滨州市",
        "label": "滨州市",
        "children": [{"value": "4662_惠民县", "label": "惠民县"}, {"value": "4663_阳信县", "label": "阳信县"}, {
            "value": "4664_无棣县",
            "label": "无棣县"
        }, {"value": "4665_沾化县", "label": "沾化县"}, {"value": "4666_博兴县", "label": "博兴县"}, {
            "value": "4667_邹平县",
            "label": "邹平县"
        }, {"value": "984660_滨州市", "label": "滨州市"}]
    }, {
        "value": "4680_德州市",
        "label": "德州市",
        "children": [{"value": "4682_乐陵市", "label": "乐陵市"}, {"value": "4683_陵县", "label": "陵县"}, {
            "value": "4684_平原县",
            "label": "平原县"
        }, {"value": "4685_夏津县", "label": "夏津县"}, {"value": "4686_武城县", "label": "武城县"}, {
            "value": "4687_齐河县",
            "label": "齐河县"
        }, {"value": "4688_禹城市", "label": "禹城市"}, {"value": "4689_临邑县", "label": "临邑县"}, {
            "value": "4693_宁津县",
            "label": "宁津县"
        }, {"value": "4694_庆云县", "label": "庆云县"}, {"value": "984680_德州市", "label": "德州市"}]
    }, {
        "value": "4710_聊城市",
        "label": "聊城市",
        "children": [{"value": "4712_临清市", "label": "临清市"}, {"value": "4713_阳谷县", "label": "阳谷县"}, {
            "value": "4714_莘县",
            "label": "莘县"
        }, {"value": "4715_茌平县", "label": "茌平县"}, {"value": "4716_东阿县", "label": "东阿县"}, {
            "value": "4717_冠县",
            "label": "冠县"
        }, {"value": "4718_高唐县", "label": "高唐县"}, {"value": "984710_聊城市", "label": "聊城市"}]
    }, {
        "value": "4730_临沂市",
        "label": "临沂市",
        "children": [{"value": "4733_郯城县", "label": "郯城县"}, {"value": "4734_苍山县", "label": "苍山县"}, {
            "value": "4735_莒南县",
            "label": "莒南县"
        }, {"value": "4737_沂水县", "label": "沂水县"}, {"value": "4739_蒙阴县", "label": "蒙阴县"}, {
            "value": "4741_平邑县",
            "label": "平邑县"
        }, {"value": "4742_费县", "label": "费县"}, {"value": "4743_沂南县", "label": "沂南县"}, {
            "value": "4744_临沭县",
            "label": "临沭县"
        }, {"value": "984730_临沂市", "label": "临沂市"}, {"value": "4733_兰山区", "label": "兰山区"}]
    }, {
        "value": "4750_荷泽市",
        "label": "荷泽市",
        "children": [{"value": "4752_曹县", "label": "曹县"}, {"value": "4753_定陶县", "label": "定陶县"}, {
            "value": "4754_成武县",
            "label": "成武县"
        }, {"value": "4755_单县", "label": "单县"}, {"value": "4756_巨野县", "label": "巨野县"}, {
            "value": "4758_郓城县",
            "label": "郓城县"
        }, {"value": "4759_鄄城县", "label": "鄄城县"}, {"value": "4761_东明县", "label": "东明县"}, {
            "value": "984750_荷泽市",
            "label": "荷泽市"
        }]
    }, {
        "value": "4770_日照市",
        "label": "日照市",
        "children": [{"value": "4771_五莲县", "label": "五莲县"}, {"value": "4772_莒县", "label": "莒县"}, {
            "value": "984770_日照市",
            "label": "日照市"
        }]
    }, {
        "value": "4790_莱芜市",
        "label": "莱芜市",
        "children": [{"value": "984790_莱芜市", "label": "莱芜市"}]
    }, {
        "value": "4580_潍坊市",
        "label": "潍坊市",
        "children": [{"value": "4584_昌乐县", "label": "昌乐县"}, {
            "value": "4589_诸城市",
            "label": "诸城市"
        }, {"value": "984580_潍坊市", "label": "潍坊市"}, {"value": "4581_安丘市", "label": "安丘市"}, {
            "value": "4582_寿光市",
            "label": "寿光市"
        }, {"value": "4583_临朐县", "label": "临朐县"}, {"value": "4585_昌邑市", "label": "昌邑市"}, {
            "value": "4586_高密市",
            "label": "高密市"
        }, {"value": "4588_青州市", "label": "青州市"}]
    }, {
        "value": "4630_泰安市",
        "label": "泰安市",
        "children": [{"value": "4635_新泰市", "label": "新泰市"}, {
            "value": "984630_泰安市",
            "label": "泰安市"
        }, {"value": "4631_宁阳县", "label": "宁阳县"}, {"value": "4632_肥城市", "label": "肥城市"}, {
            "value": "4633_东平县",
            "label": "东平县"
        }]
    }, {"value": "450001_菏泽市", "label": "菏泽市"}]
}, {
    "value": "4900_河南省",
    "label": "河南省",
    "children": [{
        "value": "4910_郑州市",
        "label": "郑州市",
        "children": [{"value": "4911_荥阳市", "label": "荥阳市"}, {"value": "4912_中牟县", "label": "中牟县"}, {
            "value": "4913_新郑市",
            "label": "新郑市"
        }, {"value": "4914_巩义市", "label": "巩义市"}, {"value": "4915_登封市", "label": "登封市"}, {
            "value": "4916_新密市",
            "label": "新密市"
        }, {"value": "984910_郑州市", "label": "郑州市"}, {"value": "4913_管城回族区", "label": "管城回族区"}, {
            "value": "4913_二七区",
            "label": "二七区"
        }, {"value": "4913_金水区", "label": "金水区"}]
    }, {
        "value": "4920_开封市",
        "label": "开封市",
        "children": [{"value": "4921_杞县", "label": "杞县"}, {"value": "4922_通许县", "label": "通许县"}, {
            "value": "4923_尉氏县",
            "label": "尉氏县"
        }, {"value": "4924_开封县", "label": "开封县"}, {"value": "4925_兰考县", "label": "兰考县"}, {
            "value": "984920_开封市",
            "label": "开封市"
        }]
    }, {
        "value": "4930_洛阳市",
        "label": "洛阳市",
        "children": [{"value": "4931_偃师市", "label": "偃师市"}, {"value": "4932_孟津县", "label": "孟津县"}, {
            "value": "4933_新安县",
            "label": "新安县"
        }, {"value": "4934_栾川县", "label": "栾川县"}, {"value": "4935_嵩县", "label": "嵩县"}, {
            "value": "4936_汝阳县",
            "label": "汝阳县"
        }, {"value": "4937_宜阳县", "label": "宜阳县"}, {"value": "4938_洛宁县", "label": "洛宁县"}, {
            "value": "4939_伊川县",
            "label": "伊川县"
        }, {"value": "984930_洛阳市", "label": "洛阳市"}, {"value": "4933_洛龙区", "label": "洛龙区"}]
    }, {
        "value": "4950_平顶山市",
        "label": "平顶山市",
        "children": [{"value": "4951_宝丰县", "label": "宝丰县"}, {"value": "4952_叶县", "label": "叶县"}, {
            "value": "4953_鲁山县",
            "label": "鲁山县"
        }, {"value": "4954_郏县", "label": "郏县"}, {"value": "4956_汝州市", "label": "汝州市"}, {
            "value": "4957_舞钢市",
            "label": "舞钢市"
        }, {"value": "984950_平顶山市", "label": "平顶山市"}]
    }, {
        "value": "4960_安阳市",
        "label": "安阳市",
        "children": [{"value": "4961_林州市", "label": "林州市"}, {"value": "4962_安阳县", "label": "安阳县"}, {
            "value": "4963_汤阴县",
            "label": "汤阴县"
        }, {"value": "4964_滑县", "label": "滑县"}, {"value": "4965_内黄县", "label": "内黄县"}, {
            "value": "984960_安阳市",
            "label": "安阳市"
        }]
    }, {
        "value": "4970_鹤壁市",
        "label": "鹤壁市",
        "children": [{"value": "4971_浚县", "label": "浚县"}, {"value": "4972_淇县", "label": "淇县"}, {
            "value": "984970_鹤壁市",
            "label": "鹤壁市"
        }]
    }, {
        "value": "4980_新乡市",
        "label": "新乡市",
        "children": [{"value": "4981_新乡县", "label": "新乡县"}, {"value": "4982_获嘉县", "label": "获嘉县"}, {
            "value": "4983_原阳县",
            "label": "原阳县"
        }, {"value": "4984_延津县", "label": "延津县"}, {"value": "4985_封丘县", "label": "封丘县"}, {
            "value": "4986_长垣县",
            "label": "长垣县"
        }, {"value": "4987_卫辉市", "label": "卫辉市"}, {"value": "4988_辉县市", "label": "辉县市"}, {
            "value": "984980_新乡市",
            "label": "新乡市"
        }, {"value": "4982_红旗区", "label": "红旗区"}, {"value": "4982_卫滨区", "label": "卫滨区"}]
    }, {
        "value": "5010_焦作市",
        "label": "焦作市",
        "children": [{"value": "5011_修武县", "label": "修武县"}, {"value": "5012_博爱县", "label": "博爱县"}, {
            "value": "5013_武陟县",
            "label": "武陟县"
        }, {"value": "5014_沁阳市", "label": "沁阳市"}, {"value": "5015_温县", "label": "温县"}, {
            "value": "5016_孟州市",
            "label": "孟州市"
        }, {"value": "5017_济源市（省直辖）", "label": "济源市（省直辖）"}, {"value": "985010_焦作市", "label": "焦作市"}]
    }, {
        "value": "5020_濮阳市",
        "label": "濮阳市",
        "children": [{"value": "5021_清丰县", "label": "清丰县"}, {"value": "5022_南乐县", "label": "南乐县"}, {
            "value": "5023_范县",
            "label": "范县"
        }, {"value": "5024_台前县", "label": "台前县"}, {"value": "5025_濮阳县", "label": "濮阳县"}, {
            "value": "985020_濮阳市",
            "label": "濮阳市"
        }, {"value": "5024_华龙区", "label": "华龙区"}]
    }, {
        "value": "5030_许昌市",
        "label": "许昌市",
        "children": [{"value": "5031_长葛市", "label": "长葛市"}, {"value": "5032_许昌县", "label": "许昌县"}, {
            "value": "5033_鄢陵县",
            "label": "鄢陵县"
        }, {"value": "5034_禹州市", "label": "禹州市"}, {"value": "5035_襄城县", "label": "襄城县"}, {
            "value": "985030_许昌市",
            "label": "许昌市"
        }]
    }, {
        "value": "5040_漯河市",
        "label": "漯河市",
        "children": [{"value": "5041_舞阳县", "label": "舞阳县"}, {
            "value": "5042_临颍县",
            "label": "临颍县"
        }, {"value": "985040_漯河市", "label": "漯河市"}]
    }, {
        "value": "5050_三门峡市",
        "label": "三门峡市",
        "children": [{"value": "5051_渑池县", "label": "渑池县"}, {"value": "5052_陕县", "label": "陕县"}, {
            "value": "5053_灵宝市",
            "label": "灵宝市"
        }, {"value": "5054_卢氏县", "label": "卢氏县"}, {"value": "5055_义马市", "label": "义马市"}, {
            "value": "985050_三门峡市",
            "label": "三门峡市"
        }]
    }, {
        "value": "5060_商丘市",
        "label": "商丘市",
        "children": [{"value": "5062_虞城县", "label": "虞城县"}, {"value": "5064_民权县", "label": "民权县"}, {
            "value": "5065_宁陵县",
            "label": "宁陵县"
        }, {"value": "5066_睢县", "label": "睢县"}, {"value": "5067_夏邑县", "label": "夏邑县"}, {
            "value": "5068_柘城县",
            "label": "柘城县"
        }, {"value": "5069_永城市", "label": "永城市"}, {"value": "985060_商丘市", "label": "商丘市"}]
    }, {
        "value": "5110_驻马店市",
        "label": "驻马店市",
        "children": [{"value": "5112_确山县", "label": "确山县"}, {"value": "5114_遂平县", "label": "遂平县"}, {
            "value": "5115_西平县",
            "label": "西平县"
        }, {"value": "5116_上蔡县", "label": "上蔡县"}, {"value": "5118_平舆县", "label": "平舆县"}, {
            "value": "5119_新蔡县",
            "label": "新蔡县"
        }, {"value": "5121_正阳县", "label": "正阳县"}, {"value": "5113_泌阳县", "label": "泌阳县"}, {
            "value": "5117_汝南县",
            "label": "汝南县"
        }, {"value": "985110_驻马店市", "label": "驻马店市"}]
    }, {
        "value": "5080_周口市",
        "label": "周口市",
        "children": [{"value": "5085_太康县", "label": "太康县"}, {
            "value": "5089_沈丘县",
            "label": "沈丘县"
        }, {"value": "985080_周口市", "label": "周口市"}, {"value": "5082_扶沟县", "label": "扶沟县"}, {
            "value": "5083_西华县",
            "label": "西华县"
        }, {"value": "5084_商水县", "label": "商水县"}, {"value": "5086_鹿邑县", "label": "鹿邑县"}, {
            "value": "5087_郸城县",
            "label": "郸城县"
        }, {"value": "5088_淮阳县", "label": "淮阳县"}, {"value": "5091_项城市", "label": "项城市"}]
    }, {
        "value": "5130_南阳市",
        "label": "南阳市",
        "children": [{"value": "5135_西峡县", "label": "西峡县"}, {
            "value": "5141_社旗县",
            "label": "社旗县"
        }, {"value": "985130_南阳市", "label": "南阳市"}, {"value": "5132_邓州市", "label": "邓州市"}, {
            "value": "5133_南召县",
            "label": "南召县"
        }, {"value": "5134_方城县", "label": "方城县"}, {"value": "5137_镇平县", "label": "镇平县"}, {
            "value": "5138_内乡县",
            "label": "内乡县"
        }, {"value": "5139_淅川县", "label": "淅川县"}, {"value": "5142_唐河县", "label": "唐河县"}, {
            "value": "5143_新野县",
            "label": "新野县"
        }, {"value": "5144_桐柏县", "label": "桐柏县"}]
    }, {
        "value": "5150_信阳市",
        "label": "信阳市",
        "children": [{"value": "5156_光山县", "label": "光山县"}, {"value": "5161_新县", "label": "新县"}, {
            "value": "985150_信阳市",
            "label": "信阳市"
        }, {"value": "5159_平桥区", "label": "平桥区"}, {"value": "5152_息县", "label": "息县"}, {
            "value": "5153_淮滨县",
            "label": "淮滨县"
        }, {"value": "5155_潢川县", "label": "潢川县"}, {"value": "5157_固始县", "label": "固始县"}, {
            "value": "5158_商城县",
            "label": "商城县"
        }, {"value": "5159_罗山县", "label": "罗山县"}]
    }]
}, {
    "value": "5200_湖北省",
    "label": "湖北省",
    "children": [{
        "value": "5210_武汉市",
        "label": "武汉市",
        "children": [{"value": "985210_武汉市", "label": "武汉市"}]
    }, {
        "value": "5220_黄石市",
        "label": "黄石市",
        "children": [{"value": "5222_阳新县", "label": "阳新县"}, {
            "value": "5221_大冶市",
            "label": "大冶市"
        }, {"value": "985220_黄石市", "label": "黄石市"}, {"value": "5222_西塞山区", "label": "西塞山区"}]
    }, {
        "value": "5230_十堰市",
        "label": "十堰市",
        "children": [{"value": "5231_郧县", "label": "郧县"}, {"value": "5233_竹山县", "label": "竹山县"}, {
            "value": "5234_竹溪县",
            "label": "竹溪县"
        }, {"value": "5235_房县", "label": "房县"}, {"value": "5232_郧西县", "label": "郧西县"}, {
            "value": "5236_丹江口市",
            "label": "丹江口市"
        }, {"value": "985230_十堰市", "label": "十堰市"}]
    }, {
        "value": "5250_宜昌市",
        "label": "宜昌市",
        "children": [{"value": "5251_宜都市", "label": "宜都市"}, {"value": "5262_当阳市", "label": "当阳市"}, {
            "value": "5265_远安县",
            "label": "远安县"
        }, {"value": "5266_兴山县", "label": "兴山县"}, {"value": "5267_秭归县", "label": "秭归县"}, {
            "value": "5269_五峰土家族自治县",
            "label": "五峰土家族自治县"
        }, {"value": "5264_枝江市", "label": "枝江市"}, {
            "value": "5268_长阳土家族自治县",
            "label": "长阳土家族自治县"
        }, {"value": "985250_宜昌市", "label": "宜昌市"}, {"value": "5266_西陵区", "label": "西陵区"}]
    }, {
        "value": "5270_随州市",
        "label": "随州市",
        "children": [{"value": "5271_广水市", "label": "广水市"}, {"value": "5272_随县", "label": "随县"}, {
            "value": "985270_随州市",
            "label": "随州市"
        }]
    }, {
        "value": "5280_襄樊市",
        "label": "襄樊市",
        "children": [{"value": "5283_南漳县", "label": "南漳县"}, {"value": "5284_谷城县", "label": "谷城县"}, {
            "value": "5285_保康县",
            "label": "保康县"
        }, {"value": "5288_枣阳市", "label": "枣阳市"}, {"value": "5282_宜城市", "label": "宜城市"}, {
            "value": "5287_老河口市",
            "label": "老河口市"
        }, {"value": "985280_襄樊市", "label": "襄樊市"}]
    }, {
        "value": "5310_鄂州市",
        "label": "鄂州市",
        "children": [{"value": "5311_神农架林区（省直辖）", "label": "神农架林区（省直辖）"}, {"value": "985310_鄂州市", "label": "鄂州市"}]
    }, {
        "value": "5350_孝感市",
        "label": "孝感市",
        "children": [{"value": "5351_孝昌县", "label": "孝昌县"}, {"value": "5353_安陆市", "label": "安陆市"}, {
            "value": "5355_大悟县",
            "label": "大悟县"
        }, {"value": "5356_云梦县", "label": "云梦县"}, {"value": "5352_应城市", "label": "应城市"}, {
            "value": "5357_汉川市",
            "label": "汉川市"
        }, {"value": "985350_孝感市", "label": "孝感市"}]
    }, {
        "value": "5360_咸宁市",
        "label": "咸宁市",
        "children": [{"value": "5363_嘉鱼县", "label": "嘉鱼县"}, {"value": "5364_通城县", "label": "通城县"}, {
            "value": "5366_通山县",
            "label": "通山县"
        }, {"value": "5367_赤壁市", "label": "赤壁市"}, {"value": "5365_崇阳县", "label": "崇阳县"}, {
            "value": "985360_咸宁市",
            "label": "咸宁市"
        }, {"value": "5364_咸安区", "label": "咸安区"}]
    }, {
        "value": "5370_荆州市",
        "label": "荆州市",
        "children": [{"value": "5372_石首市", "label": "石首市"}, {
            "value": "5373_洪湖市",
            "label": "洪湖市"
        }, {"value": "5374_天门市（省直辖）", "label": "天门市（省直辖）"}, {
            "value": "5375_潜江市（省直辖）",
            "label": "潜江市（省直辖）"
        }, {"value": "5376_江陵县", "label": "江陵县"}, {"value": "5378_公安县", "label": "公安县"}, {
            "value": "5379_监利县",
            "label": "监利县"
        }, {"value": "5371_仙桃市（省直辖）", "label": "仙桃市（省直辖）"}, {
            "value": "5377_松滋市",
            "label": "松滋市"
        }, {"value": "985370_荆州市", "label": "荆州市"}, {"value": "5378_沙市区", "label": "沙市区"}, {
            "value": "5378_荆州区",
            "label": "荆州区"
        }]
    }, {
        "value": "5410_恩施土家族苗族自治州",
        "label": "恩施土家族苗族自治州",
        "children": [{"value": "985410_恩施土家族苗族自治州", "label": "恩施土家族苗族自治州"}, {
            "value": "5411_恩施市",
            "label": "恩施市"
        }, {"value": "5412_利川市", "label": "利川市"}, {"value": "5413_建始县", "label": "建始县"}, {
            "value": "5414_巴东县",
            "label": "巴东县"
        }, {"value": "5415_宣恩县", "label": "宣恩县"}, {"value": "5416_咸丰县", "label": "咸丰县"}, {
            "value": "5417_来凤县",
            "label": "来凤县"
        }, {"value": "5418_鹤峰县", "label": "鹤峰县"}]
    }, {
        "value": "5320_荆门市",
        "label": "荆门市",
        "children": [{"value": "985320_荆门市", "label": "荆门市"}, {
            "value": "5321_京山县",
            "label": "京山县"
        }, {"value": "5322_沙洋县", "label": "沙洋县"}, {"value": "5323_钟祥市", "label": "钟祥市"}]
    }, {
        "value": "5330_黄冈市",
        "label": "黄冈市",
        "children": [{"value": "5334_红安县", "label": "红安县"}, {
            "value": "5338_蕲春县",
            "label": "蕲春县"
        }, {"value": "985330_黄冈市", "label": "黄冈市"}, {"value": "5334_洪山区", "label": "洪山区"}, {
            "value": "5334_汉南区",
            "label": "汉南区"
        }, {"value": "5334_江夏区", "label": "江夏区"}, {"value": "5334_武昌区", "label": "武昌区"}, {
            "value": "5334_江岸区",
            "label": "江岸区"
        }, {"value": "5334_江汉区", "label": "江汉区"}, {"value": "5334_青山区", "label": "青山区"}, {
            "value": "5331_麻城市",
            "label": "麻城市"
        }, {"value": "5332_武穴市", "label": "武穴市"}, {"value": "5333_团风县", "label": "团风县"}, {
            "value": "5335_罗田县",
            "label": "罗田县"
        }, {"value": "5336_英山县", "label": "英山县"}, {"value": "5337_浠水县", "label": "浠水县"}, {
            "value": "5339_黄梅县",
            "label": "黄梅县"
        }]
    }]
}, {
    "value": "1200_河北省",
    "label": "河北省",
    "children": [{
        "value": "1210_石家庄市",
        "label": "石家庄市",
        "children": [{"value": "1211_井陉县", "label": "井陉县"}, {"value": "1212_鹿泉市", "label": "鹿泉市"}, {
            "value": "1213_正定县",
            "label": "正定县"
        }, {"value": "1214_栾城县", "label": "栾城县"}, {"value": "1221_辛集市", "label": "辛集市"}, {
            "value": "1222_晋州市",
            "label": "晋州市"
        }, {"value": "1223_深泽县", "label": "深泽县"}, {"value": "1224_无极县", "label": "无极县"}, {
            "value": "1225_藁城市",
            "label": "藁城市"
        }, {"value": "1226_赵县", "label": "赵县"}, {"value": "1227_新乐市", "label": "新乐市"}, {
            "value": "1228_高邑县",
            "label": "高邑县"
        }, {"value": "1229_元氏县", "label": "元氏县"}, {"value": "1231_赞皇县", "label": "赞皇县"}, {
            "value": "1232_平山县",
            "label": "平山县"
        }, {"value": "1233_灵寿县", "label": "灵寿县"}, {"value": "1234_行唐县", "label": "行唐县"}, {
            "value": "981210_石家庄市",
            "label": "石家庄市"
        }, {"value": "1214_裕华区", "label": "裕华区"}, {"value": "1214_桥西区", "label": "桥西区"}]
    }, {
        "value": "1240_唐山市",
        "label": "唐山市",
        "children": [{"value": "1243_滦县", "label": "滦县"}, {"value": "1244_滦南县", "label": "滦南县"}, {
            "value": "1245_乐亭县",
            "label": "乐亭县"
        }, {"value": "1246_迁安市", "label": "迁安市"}, {"value": "1247_迁西县", "label": "迁西县"}, {
            "value": "1248_遵化市",
            "label": "遵化市"
        }, {"value": "1249_玉田县", "label": "玉田县"}, {"value": "1251_唐海县", "label": "唐海县"}, {
            "value": "981240_唐山市",
            "label": "唐山市"
        }, {"value": "1243_丰润区", "label": "丰润区"}]
    }, {
        "value": "1260_秦皇岛市",
        "label": "秦皇岛市",
        "children": [{"value": "1261_青龙满族自治县", "label": "青龙满族自治县"}, {
            "value": "1262_昌黎县",
            "label": "昌黎县"
        }, {"value": "1263_抚宁县", "label": "抚宁县"}, {"value": "1264_卢龙县", "label": "卢龙县"}, {
            "value": "981260_秦皇岛市",
            "label": "秦皇岛市"
        }]
    }, {
        "value": "1270_邯郸市",
        "label": "邯郸市",
        "children": [{"value": "1271_邯郸县", "label": "邯郸县"}, {"value": "1281_大名县", "label": "大名县"}, {
            "value": "1282_魏县",
            "label": "魏县"
        }, {"value": "1283_曲周县", "label": "曲周县"}, {"value": "1284_邱县", "label": "邱县"}, {
            "value": "1285_鸡泽县",
            "label": "鸡泽县"
        }, {"value": "1286_肥乡县", "label": "肥乡县"}, {"value": "1287_广平县", "label": "广平县"}, {
            "value": "1288_成安县",
            "label": "成安县"
        }, {"value": "1289_临漳县", "label": "临漳县"}, {"value": "1291_磁县", "label": "磁县"}, {
            "value": "1292_涉县",
            "label": "涉县"
        }, {"value": "1293_永年县", "label": "永年县"}, {"value": "1294_馆陶县", "label": "馆陶县"}, {
            "value": "1295_武安市",
            "label": "武安市"
        }, {"value": "981270_邯郸市", "label": "邯郸市"}, {"value": "1271_复兴区", "label": "复兴区"}]
    }, {
        "value": "1310_邢台市",
        "label": "邢台市",
        "children": [{"value": "1311_邢台县", "label": "邢台县"}, {"value": "1321_南宫市", "label": "南宫市"}, {
            "value": "1322_沙河市",
            "label": "沙河市"
        }, {"value": "1323_临城县", "label": "临城县"}, {"value": "1324_内丘县", "label": "内丘县"}, {
            "value": "1325_柏乡县",
            "label": "柏乡县"
        }, {"value": "1326_隆尧县", "label": "隆尧县"}, {"value": "1327_任县", "label": "任县"}, {
            "value": "1328_南和县",
            "label": "南和县"
        }, {"value": "1329_宁晋县", "label": "宁晋县"}, {"value": "1331_巨鹿县", "label": "巨鹿县"}, {
            "value": "1332_新河县",
            "label": "新河县"
        }, {"value": "1333_广宗县", "label": "广宗县"}, {"value": "1334_平乡县", "label": "平乡县"}, {
            "value": "1335_威县",
            "label": "威县"
        }, {"value": "1336_清河县", "label": "清河县"}, {"value": "1337_临西县", "label": "临西县"}, {
            "value": "981310_邢台市",
            "label": "邢台市"
        }]
    }, {
        "value": "1340_保定市",
        "label": "保定市",
        "children": [{"value": "1341_满城县", "label": "满城县"}, {"value": "1342_清苑县", "label": "清苑县"}, {
            "value": "1351_定州市",
            "label": "定州市"
        }, {"value": "1352_涿州市", "label": "涿州市"}, {"value": "1353_易县", "label": "易县"}, {
            "value": "1354_徐水县",
            "label": "徐水县"
        }, {"value": "1355_涞源县", "label": "涞源县"}, {"value": "1356_定兴县", "label": "定兴县"}, {
            "value": "1357_顺平县",
            "label": "顺平县"
        }, {"value": "1358_唐县", "label": "唐县"}, {"value": "1359_望都县", "label": "望都县"}, {
            "value": "1361_涞水县",
            "label": "涞水县"
        }, {"value": "1362_高阳县", "label": "高阳县"}, {"value": "1363_安新县", "label": "安新县"}, {
            "value": "1364_雄县",
            "label": "雄县"
        }, {"value": "1365_容城县", "label": "容城县"}, {"value": "1366_高碑店市", "label": "高碑店市"}, {
            "value": "1367_曲阳县",
            "label": "曲阳县"
        }, {"value": "1368_阜平县", "label": "阜平县"}, {"value": "1369_安国市", "label": "安国市"}, {
            "value": "1371_博野县",
            "label": "博野县"
        }, {"value": "1372_蠡县", "label": "蠡县"}, {"value": "981340_保定市", "label": "保定市"}]
    }, {
        "value": "1380_张家口市",
        "label": "张家口市",
        "children": [{"value": "1381_宣化县", "label": "宣化县"}, {"value": "1391_张北县", "label": "张北县"}, {
            "value": "1392_康保县",
            "label": "康保县"
        }, {"value": "1393_沽源县", "label": "沽源县"}, {"value": "1394_尚义县", "label": "尚义县"}, {
            "value": "1395_蔚县",
            "label": "蔚县"
        }, {"value": "1396_阳原县", "label": "阳原县"}, {"value": "1397_怀安县", "label": "怀安县"}, {
            "value": "1398_万全县",
            "label": "万全县"
        }, {"value": "1399_怀来县", "label": "怀来县"}, {"value": "1401_涿鹿县", "label": "涿鹿县"}, {
            "value": "1402_赤城县",
            "label": "赤城县"
        }, {"value": "1403_崇礼县", "label": "崇礼县"}, {"value": "981380_张家口市", "label": "张家口市"}]
    }, {
        "value": "1410_承德市",
        "label": "承德市",
        "children": [{"value": "1411_承德县", "label": "承德县"}, {
            "value": "1421_宽城满族自治县",
            "label": "宽城满族自治县"
        }, {"value": "1422_兴隆县", "label": "兴隆县"}, {"value": "1423_平泉县", "label": "平泉县"}, {
            "value": "1424_滦平县",
            "label": "滦平县"
        }, {"value": "1425_丰宁满族自治县", "label": "丰宁满族自治县"}, {
            "value": "1426_隆化县",
            "label": "隆化县"
        }, {"value": "1427_围场满族蒙古族自治县", "label": "围场满族蒙古族自治县"}, {"value": "981410_承德市", "label": "承德市"}]
    }, {
        "value": "1430_沧州市",
        "label": "沧州市",
        "children": [{"value": "1431_沧县", "label": "沧县"}, {"value": "1432_青县", "label": "青县"}, {
            "value": "1441_泊头市",
            "label": "泊头市"
        }, {"value": "1442_任丘市", "label": "任丘市"}, {"value": "1443_河间市", "label": "河间市"}, {
            "value": "1444_肃宁县",
            "label": "肃宁县"
        }, {"value": "1445_献县", "label": "献县"}, {"value": "1446_吴桥县", "label": "吴桥县"}, {
            "value": "1447_东光县",
            "label": "东光县"
        }, {"value": "1448_南皮县", "label": "南皮县"}, {"value": "1449_盐山县", "label": "盐山县"}, {
            "value": "1451_黄骅市",
            "label": "黄骅市"
        }, {"value": "1453_海兴县", "label": "海兴县"}, {"value": "1452_孟村回族自治县", "label": "孟村回族自治县"}, {
            "value": "981430_沧州市",
            "label": "沧州市"
        }]
    }, {
        "value": "1460_廊坊市",
        "label": "廊坊市",
        "children": [{"value": "1461_三河市", "label": "三河市"}, {"value": "1462_固安县", "label": "固安县"}, {
            "value": "1463_永清县",
            "label": "永清县"
        }, {"value": "1465_大城县", "label": "大城县"}, {"value": "1466_文安县", "label": "文安县"}, {
            "value": "1467_霸州市",
            "label": "霸州市"
        }, {"value": "1464_香河县", "label": "香河县"}, {"value": "1468_大厂回族自治县", "label": "大厂回族自治县"}, {
            "value": "981460_廊坊市",
            "label": "廊坊市"
        }]
    }, {
        "value": "1480_衡水市",
        "label": "衡水市",
        "children": [{"value": "1482_冀州市", "label": "冀州市"}, {"value": "1483_枣强县", "label": "枣强县"}, {
            "value": "1484_武邑县",
            "label": "武邑县"
        }, {"value": "1486_武强县", "label": "武强县"}, {"value": "1487_饶阳县", "label": "饶阳县"}, {
            "value": "1488_安平县",
            "label": "安平县"
        }, {"value": "1491_景县", "label": "景县"}, {"value": "1492_阜城县", "label": "阜城县"}, {
            "value": "1485_深州市",
            "label": "深州市"
        }, {"value": "1489_故城县", "label": "故城县"}, {"value": "981480_衡水市", "label": "衡水市"}]
    }]
}, {
    "value": "1600_山西省",
    "label": "山西省",
    "children": [{
        "value": "1620_大同市",
        "label": "大同市",
        "children": [{"value": "1631_阳高县", "label": "阳高县"}, {"value": "1632_天镇县", "label": "天镇县"}, {
            "value": "1634_灵丘县",
            "label": "灵丘县"
        }, {"value": "1635_浑源县", "label": "浑源县"}, {"value": "1637_左云县", "label": "左云县"}, {
            "value": "1633_广灵县",
            "label": "广灵县"
        }, {"value": "1639_大同县", "label": "大同县"}, {"value": "981620_大同市", "label": "大同市"}]
    }, {
        "value": "1650_阳泉市",
        "label": "阳泉市",
        "children": [{"value": "1651_平定县", "label": "平定县"}, {"value": "1652_盂县", "label": "盂县"}, {
            "value": "981650_阳泉市",
            "label": "阳泉市"
        }]
    }, {
        "value": "1690_朔州市",
        "label": "朔州市",
        "children": [{"value": "1691_山阴县", "label": "山阴县"}, {"value": "1693_右玉县", "label": "右玉县"}, {
            "value": "1694_怀仁县",
            "label": "怀仁县"
        }, {"value": "1692_应县", "label": "应县"}, {"value": "981690_朔州市", "label": "朔州市"}]
    }, {
        "value": "1710_忻州市",
        "label": "忻州市",
        "children": [{"value": "1713_五台县", "label": "五台县"}, {"value": "1714_原平市", "label": "原平市"}, {
            "value": "1715_代县",
            "label": "代县"
        }, {"value": "1717_宁武县", "label": "宁武县"}, {"value": "1718_静乐县", "label": "静乐县"}, {
            "value": "1719_神池县",
            "label": "神池县"
        }, {"value": "1722_岢岚县", "label": "岢岚县"}, {"value": "1723_河曲县", "label": "河曲县"}, {
            "value": "1724_保德县",
            "label": "保德县"
        }, {"value": "1712_定襄县", "label": "定襄县"}, {"value": "1716_繁峙县", "label": "繁峙县"}, {
            "value": "1721_五寨县",
            "label": "五寨县"
        }, {"value": "1725_偏关县", "label": "偏关县"}, {"value": "981710_忻州市", "label": "忻州市"}]
    }, {
        "value": "1730_吕梁市",
        "label": "吕梁市",
        "children": [{"value": "1731_汾阳市", "label": "汾阳市"}, {"value": "1732_文水县", "label": "文水县"}, {
            "value": "1734_孝义市",
            "label": "孝义市"
        }, {"value": "1735_兴县", "label": "兴县"}, {"value": "1736_临县", "label": "临县"}, {
            "value": "1738_石楼县",
            "label": "石楼县"
        }, {"value": "1739_岚县", "label": "岚县"}, {"value": "1741_方山县", "label": "方山县"}, {
            "value": "1744_交口县",
            "label": "交口县"
        }, {"value": "1733_交城县", "label": "交城县"}, {"value": "1737_柳林县", "label": "柳林县"}, {
            "value": "1743_中阳县",
            "label": "中阳县"
        }, {"value": "981730_吕梁市", "label": "吕梁市"}, {"value": "1733_离石区", "label": "离石区"}]
    }, {
        "value": "1750_晋中市",
        "label": "晋中市",
        "children": [{"value": "1752_榆社县", "label": "榆社县"}, {"value": "1754_和顺县", "label": "和顺县"}, {
            "value": "1755_昔阳县",
            "label": "昔阳县"
        }, {"value": "1756_寿阳县", "label": "寿阳县"}, {"value": "1758_祁县", "label": "祁县"}, {
            "value": "1759_平遥县",
            "label": "平遥县"
        }, {"value": "1761_介休市", "label": "介休市"}, {"value": "1753_左权县", "label": "左权县"}, {
            "value": "1757_太谷县",
            "label": "太谷县"
        }, {"value": "1762_灵石县", "label": "灵石县"}, {"value": "981750_晋中市", "label": "晋中市"}, {
            "value": "1753_榆次区",
            "label": "榆次区"
        }]
    }, {
        "value": "1770_临汾市",
        "label": "临汾市",
        "children": [{"value": "1772_侯马市", "label": "侯马市"}, {"value": "1773_曲沃县", "label": "曲沃县"}, {
            "value": "1775_襄汾县",
            "label": "襄汾县"
        }, {"value": "1776_洪洞县", "label": "洪洞县"}, {"value": "1777_霍州市", "label": "霍州市"}, {
            "value": "1779_安泽县",
            "label": "安泽县"
        }, {"value": "1781_浮山县", "label": "浮山县"}, {"value": "1782_吉县", "label": "吉县"}, {
            "value": "1784_蒲县",
            "label": "蒲县"
        }, {"value": "1785_大宁县", "label": "大宁县"}, {"value": "1786_永和县", "label": "永和县"}, {
            "value": "1787_隰县",
            "label": "隰县"
        }, {"value": "1788_汾西县", "label": "汾西县"}, {"value": "1774_翼城县", "label": "翼城县"}, {
            "value": "1778_古县",
            "label": "古县"
        }, {"value": "1783_乡宁县", "label": "乡宁县"}, {"value": "981770_临汾市", "label": "临汾市"}]
    }, {
        "value": "1810_运城市",
        "label": "运城市",
        "children": [{"value": "1812_永济市", "label": "永济市"}, {"value": "1813_芮城县", "label": "芮城县"}, {
            "value": "1814_临猗县",
            "label": "临猗县"
        }, {"value": "1815_万荣县", "label": "万荣县"}, {"value": "1816_新绛县", "label": "新绛县"}, {
            "value": "1817_稷山县",
            "label": "稷山县"
        }, {"value": "1818_河津市", "label": "河津市"}, {"value": "1819_闻喜县", "label": "闻喜县"}, {
            "value": "1821_夏县",
            "label": "夏县"
        }, {"value": "1822_绛县", "label": "绛县"}, {"value": "1823_平陆县", "label": "平陆县"}, {
            "value": "1824_垣曲县",
            "label": "垣曲县"
        }, {"value": "981810_运城市", "label": "运城市"}, {"value": "1815_盐湖区", "label": "盐湖区"}]
    }, {
        "value": "1610_太原市",
        "label": "太原市",
        "children": [{"value": "1614_古交市", "label": "古交市"}, {
            "value": "981610_太原市",
            "label": "太原市"
        }, {"value": "1611_小店区", "label": "小店区"}, {"value": "1611_万柏林区", "label": "万柏林区"}, {
            "value": "1611_杏花岭区",
            "label": "杏花岭区"
        }, {"value": "1611_清徐县", "label": "清徐县"}, {"value": "1612_阳曲县", "label": "阳曲县"}, {
            "value": "1613_娄烦县",
            "label": "娄烦县"
        }]
    }, {
        "value": "1660_长治市",
        "label": "长治市",
        "children": [{"value": "1664_屯留县", "label": "屯留县"}, {
            "value": "1668_长子县",
            "label": "长子县"
        }, {"value": "981660_长治市", "label": "长治市"}, {"value": "1661_长治县", "label": "长治县"}, {
            "value": "1662_潞城市",
            "label": "潞城市"
        }, {"value": "1663_襄垣县", "label": "襄垣县"}, {"value": "1665_平顺县", "label": "平顺县"}, {
            "value": "1666_黎城县",
            "label": "黎城县"
        }, {"value": "1667_壶关县", "label": "壶关县"}, {"value": "1669_武乡县", "label": "武乡县"}, {
            "value": "1671_沁县",
            "label": "沁县"
        }, {"value": "1672_沁源县", "label": "沁源县"}]
    }, {
        "value": "1680_晋城市",
        "label": "晋城市",
        "children": [{"value": "1684_陵川县", "label": "陵川县"}, {
            "value": "981680_晋城市",
            "label": "晋城市"
        }, {"value": "1681_沁水县", "label": "沁水县"}, {"value": "1682_阳城县", "label": "阳城县"}, {
            "value": "1683_高平市",
            "label": "高平市"
        }, {"value": "1685_泽州县", "label": "泽州县"}]
    }]
}, {
    "value": "1900_内蒙古自治区",
    "label": "内蒙古自治区",
    "children": [{
        "value": "1910_呼和浩特市",
        "label": "呼和浩特市",
        "children": [{"value": "1911_土默特左旗", "label": "土默特左旗"}, {
            "value": "1912_托克托县",
            "label": "托克托县"
        }, {"value": "1913_武川县", "label": "武川县"}, {"value": "1914_和林格尔县", "label": "和林格尔县"}, {
            "value": "1915_清水河县",
            "label": "清水河县"
        }, {"value": "981910_呼和浩特市", "label": "呼和浩特市"}, {"value": "1913_赛罕区", "label": "赛罕区"}, {
            "value": "1913_回民区",
            "label": "回民区"
        }]
    }, {
        "value": "1920_包头市",
        "label": "包头市",
        "children": [{"value": "1921_土默特右旗", "label": "土默特右旗"}, {
            "value": "1922_固阳县",
            "label": "固阳县"
        }, {"value": "1923_达尔罕茂明安联合旗", "label": "达尔罕茂明安联合旗"}, {
            "value": "981920_包头市",
            "label": "包头市"
        }, {"value": "1922_九原区", "label": "九原区"}, {"value": "1922_青山区", "label": "青山区"}]
    }, {
        "value": "1930_乌海市",
        "label": "乌海市",
        "children": [{"value": "981930_乌海市", "label": "乌海市"}]
    }, {
        "value": "1940_赤峰市",
        "label": "赤峰市",
        "children": [{"value": "1941_阿鲁科尔沁旗", "label": "阿鲁科尔沁旗"}, {
            "value": "1942_巴林左旗",
            "label": "巴林左旗"
        }, {"value": "1943_巴林右旗", "label": "巴林右旗"}, {"value": "1944_林西县", "label": "林西县"}, {
            "value": "1945_克什克腾旗",
            "label": "克什克腾旗"
        }, {"value": "1946_翁牛特旗", "label": "翁牛特旗"}, {"value": "1947_喀喇沁旗", "label": "喀喇沁旗"}, {
            "value": "1948_宁城县",
            "label": "宁城县"
        }, {"value": "1949_敖汉旗", "label": "敖汉旗"}, {"value": "981940_赤峰市", "label": "赤峰市"}]
    }, {
        "value": "1960_呼伦贝尔市",
        "label": "呼伦贝尔市",
        "children": [{"value": "1962_满洲里市", "label": "满洲里市"}, {
            "value": "1963_扎兰屯市",
            "label": "扎兰屯市"
        }, {"value": "1964_牙克石市", "label": "牙克石市"}, {"value": "1965_阿荣旗", "label": "阿荣旗"}, {
            "value": "1966_莫力达瓦达斡尔族自治旗",
            "label": "莫力达瓦达斡尔族自治旗"
        }, {"value": "1967_额尔古纳市", "label": "额尔古纳市"}, {"value": "1968_根河市", "label": "根河市"}, {
            "value": "1969_鄂伦春自治旗",
            "label": "鄂伦春自治旗"
        }, {"value": "1971_鄂温克族自治旗", "label": "鄂温克族自治旗"}, {
            "value": "1972_新巴尔虎右旗",
            "label": "新巴尔虎右旗"
        }, {"value": "1973_新巴尔虎左旗", "label": "新巴尔虎左旗"}, {
            "value": "1974_陈巴尔虎旗",
            "label": "陈巴尔虎旗"
        }, {"value": "981960_呼伦贝尔市", "label": "呼伦贝尔市"}]
    }, {
        "value": "1980_兴安盟",
        "label": "兴安盟",
        "children": [{"value": "1981_乌兰浩特市", "label": "乌兰浩特市"}, {
            "value": "1982_科尔沁右翼前旗",
            "label": "科尔沁右翼前旗"
        }, {"value": "1983_科尔沁右翼中旗", "label": "科尔沁右翼中旗"}, {"value": "1984_扎赉特旗", "label": "扎赉特旗"}, {
            "value": "1985_突泉县",
            "label": "突泉县"
        }, {"value": "1986_阿尔山市", "label": "阿尔山市"}, {"value": "981980_兴安盟", "label": "兴安盟"}]
    }, {
        "value": "1990_通辽市",
        "label": "通辽市",
        "children": [{"value": "1992_霍林郭勒市", "label": "霍林郭勒市"}, {
            "value": "1993_科尔沁左翼中旗",
            "label": "科尔沁左翼中旗"
        }, {"value": "1994_科尔沁左翼后旗", "label": "科尔沁左翼后旗"}, {"value": "1995_开鲁县", "label": "开鲁县"}, {
            "value": "1996_库伦旗",
            "label": "库伦旗"
        }, {"value": "1997_奈曼旗", "label": "奈曼旗"}, {"value": "1998_扎鲁特旗", "label": "扎鲁特旗"}, {
            "value": "981990_通辽市",
            "label": "通辽市"
        }]
    }, {
        "value": "2010_锡林郭勒盟",
        "label": "锡林郭勒盟",
        "children": [{"value": "2011_二连浩特市", "label": "二连浩特市"}, {
            "value": "2012_锡林浩特市",
            "label": "锡林浩特市"
        }, {"value": "2013_阿巴嘎旗", "label": "阿巴嘎旗"}, {"value": "2014_苏尼特左旗", "label": "苏尼特左旗"}, {
            "value": "2015_苏尼特右旗",
            "label": "苏尼特右旗"
        }, {"value": "2016_东乌珠穆沁旗", "label": "东乌珠穆沁旗"}, {
            "value": "2017_西乌珠穆沁旗",
            "label": "西乌珠穆沁旗"
        }, {"value": "2018_太仆寺旗", "label": "太仆寺旗"}, {"value": "2019_镶黄旗", "label": "镶黄旗"}, {
            "value": "2021_正镶白旗",
            "label": "正镶白旗"
        }, {"value": "2022_正蓝旗", "label": "正蓝旗"}, {"value": "2023_多伦县", "label": "多伦县"}, {
            "value": "982010_锡林郭勒盟",
            "label": "锡林郭勒盟"
        }]
    }, {
        "value": "2030_乌兰察布市",
        "label": "乌兰察布市",
        "children": [{"value": "2035_卓资县", "label": "卓资县"}, {"value": "2036_化德县", "label": "化德县"}, {
            "value": "2037_商都县",
            "label": "商都县"
        }, {"value": "2038_兴和县", "label": "兴和县"}, {"value": "2039_丰镇市", "label": "丰镇市"}, {
            "value": "2041_凉城县",
            "label": "凉城县"
        }, {"value": "2042_察哈尔右翼前旗", "label": "察哈尔右翼前旗"}, {
            "value": "2043_察哈尔右翼中旗",
            "label": "察哈尔右翼中旗"
        }, {"value": "2044_察哈尔右翼后旗", "label": "察哈尔右翼后旗"}, {
            "value": "2046_四子王旗",
            "label": "四子王旗"
        }, {"value": "982030_乌兰察布市", "label": "乌兰察布市"}]
    }, {
        "value": "2050_鄂尔多斯市",
        "label": "鄂尔多斯市",
        "children": [{"value": "2051_东胜区", "label": "东胜区"}, {
            "value": "2052_达拉特旗",
            "label": "达拉特旗"
        }, {"value": "2053_准格尔旗", "label": "准格尔旗"}, {"value": "2054_鄂托克前旗", "label": "鄂托克前旗"}, {
            "value": "2055_鄂托克旗",
            "label": "鄂托克旗"
        }, {"value": "2056_杭锦旗", "label": "杭锦旗"}, {"value": "2057_乌审旗", "label": "乌审旗"}, {
            "value": "2058_伊金霍洛旗",
            "label": "伊金霍洛旗"
        }, {"value": "982050_鄂尔多斯市", "label": "鄂尔多斯市"}]
    }, {
        "value": "2070_巴彦淖尔市",
        "label": "巴彦淖尔市",
        "children": [{"value": "2072_五原县", "label": "五原县"}, {
            "value": "2073_磴口县",
            "label": "磴口县"
        }, {"value": "2074_乌拉特前旗", "label": "乌拉特前旗"}, {"value": "2075_乌拉特中旗", "label": "乌拉特中旗"}, {
            "value": "2076_乌拉特后旗",
            "label": "乌拉特后旗"
        }, {"value": "2077_杭锦后旗", "label": "杭锦后旗"}, {"value": "982070_巴彦淖尔市", "label": "巴彦淖尔市"}]
    }, {
        "value": "2080_阿拉善盟",
        "label": "阿拉善盟",
        "children": [{"value": "2081_阿拉善左旗", "label": "阿拉善左旗"}, {
            "value": "2082_阿拉善右旗",
            "label": "阿拉善右旗"
        }, {"value": "2083_额济纳旗", "label": "额济纳旗"}, {"value": "982080_阿拉善盟   ", "label": "阿拉善盟   "}]
    }]
}, {
    "value": "2200_辽宁省",
    "label": "辽宁省",
    "children": [{
        "value": "2210_沈阳市",
        "label": "沈阳市",
        "children": [{"value": "2211_新民市", "label": "新民市"}, {"value": "2212_辽中县", "label": "辽中县"}, {
            "value": "2213_康平县",
            "label": "康平县"
        }, {"value": "2214_法库县", "label": "法库县"}, {"value": "982210_沈阳市", "label": "沈阳市"}, {
            "value": "2213_和平区",
            "label": "和平区"
        }, {"value": "2213_沈河区", "label": "沈河区"}, {"value": "2213_于洪区", "label": "于洪区"}, {
            "value": "2213_东陵区",
            "label": "东陵区"
        }, {"value": "2213_大东区", "label": "大东区"}]
    }, {
        "value": "2220_大连市",
        "label": "大连市",
        "children": [{"value": "2221_普兰店市", "label": "普兰店市"}, {
            "value": "2222_长海县",
            "label": "长海县"
        }, {"value": "2223_庄河市", "label": "庄河市"}, {"value": "2224_瓦房店市", "label": "瓦房店市"}, {
            "value": "982220_大连市",
            "label": "大连市"
        }, {"value": "2222_旅顺口区", "label": "旅顺口区"}]
    }, {
        "value": "2230_鞍山市",
        "label": "鞍山市",
        "children": [{"value": "2231_台安县", "label": "台安县"}, {
            "value": "2232_海城市",
            "label": "海城市"
        }, {"value": "2233_岫岩满族自治县", "label": "岫岩满族自治县"}, {"value": "982230_鞍山市", "label": "鞍山市"}]
    }, {
        "value": "2240_抚顺市",
        "label": "抚顺市",
        "children": [{"value": "2241_抚顺县", "label": "抚顺县"}, {
            "value": "2242_新宾满族自治县",
            "label": "新宾满族自治县"
        }, {"value": "2243_清原满族自治县", "label": "清原满族自治县"}, {"value": "982240_抚顺市", "label": "抚顺市"}]
    }, {
        "value": "2250_本溪市",
        "label": "本溪市",
        "children": [{"value": "2251_本溪满族自治县", "label": "本溪满族自治县"}, {
            "value": "2252_桓仁满族自治县",
            "label": "桓仁满族自治县"
        }, {"value": "982250_本溪市", "label": "本溪市"}]
    }, {
        "value": "2260_丹东市",
        "label": "丹东市",
        "children": [{"value": "2261_凤城市", "label": "凤城市"}, {
            "value": "2263_东港市",
            "label": "东港市"
        }, {"value": "2264_宽甸满族自治县", "label": "宽甸满族自治县"}, {"value": "982260_丹东市", "label": "丹东市"}, {
            "value": "2263_振兴区",
            "label": "振兴区"
        }]
    }, {
        "value": "2280_营口市",
        "label": "营口市",
        "children": [{"value": "2281_大石桥市", "label": "大石桥市"}, {
            "value": "2282_盖州市",
            "label": "盖州市"
        }, {"value": "982280_营口市", "label": "营口市"}, {"value": "2282_西市区", "label": "西市区"}]
    }, {
        "value": "2310_辽阳市",
        "label": "辽阳市",
        "children": [{"value": "2312_灯塔市", "label": "灯塔市"}, {
            "value": "2311_辽阳县",
            "label": "辽阳县"
        }, {"value": "982310_辽阳市", "label": "辽阳市"}]
    }, {
        "value": "2320_盘锦市",
        "label": "盘锦市",
        "children": [{"value": "2321_大洼县", "label": "大洼县"}, {
            "value": "2322_盘山县",
            "label": "盘山县"
        }, {"value": "982320_盘锦市", "label": "盘锦市"}]
    }, {
        "value": "2330_铁岭市",
        "label": "铁岭市",
        "children": [{"value": "2331_铁岭县", "label": "铁岭县"}, {
            "value": "2332_西丰县",
            "label": "西丰县"
        }, {"value": "2336_调兵山市", "label": "调兵山市"}, {"value": "2337_开原市", "label": "开原市"}, {
            "value": "2333_昌图县",
            "label": "昌图县"
        }, {"value": "982330_铁岭市", "label": "铁岭市"}]
    }, {
        "value": "2340_朝阳市",
        "label": "朝阳市",
        "children": [{"value": "2342_建平县", "label": "建平县"}, {
            "value": "2343_凌源市",
            "label": "凌源市"
        }, {"value": "2344_喀喇沁左翼蒙古族自治县", "label": "喀喇沁左翼蒙古族自治县"}, {
            "value": "2341_朝阳县",
            "label": "朝阳县"
        }, {"value": "2346_北票市", "label": "北票市"}, {"value": "982340_朝阳市", "label": "朝阳市"}]
    }, {
        "value": "2360_葫芦岛市",
        "label": "葫芦岛市",
        "children": [{"value": "2361_绥中县", "label": "绥中县"}, {"value": "2362_建昌县", "label": "建昌县"}, {
            "value": "2363_兴城市",
            "label": "兴城市"
        }, {"value": "982360_葫芦岛市  ", "label": "葫芦岛市  "}]
    }, {
        "value": "2270_锦州市",
        "label": "锦州市",
        "children": [{"value": "2275_义县", "label": "义县"}, {"value": "982270_锦州市", "label": "锦州市"}, {
            "value": "2272_凌海市",
            "label": "凌海市"
        }, {"value": "2273_北镇市", "label": "北镇市"}, {"value": "2274_黑山县", "label": "黑山县"}]
    }, {
        "value": "2290_阜新市",
        "label": "阜新市",
        "children": [{"value": "982290_阜新市", "label": "阜新市"}, {
            "value": "2291_阜新蒙古族自治县",
            "label": "阜新蒙古族自治县"
        }, {"value": "2292_彰武县", "label": "彰武县"}]
    }]
}, {
    "value": "2400_吉林省",
    "label": "吉林省",
    "children": [{
        "value": "2410_长春市",
        "label": "长春市",
        "children": [{"value": "2411_榆树市", "label": "榆树市"}, {"value": "2413_德惠市", "label": "德惠市"}, {
            "value": "2415_九台市",
            "label": "九台市"
        }, {"value": "2412_农安县", "label": "农安县"}, {"value": "982410_长春市", "label": "长春市"}, {
            "value": "2412_绿园区",
            "label": "绿园区"
        }]
    }, {
        "value": "2420_吉林市",
        "label": "吉林市",
        "children": [{"value": "2422_舒兰市", "label": "舒兰市"}, {"value": "2423_磐石市", "label": "磐石市"}, {
            "value": "2424_蛟河市",
            "label": "蛟河市"
        }, {"value": "2421_永吉县", "label": "永吉县"}, {"value": "2425_桦甸市", "label": "桦甸市"}, {
            "value": "982420_吉林市",
            "label": "吉林市"
        }, {"value": "2421_船营区", "label": "船营区"}, {"value": "2421_昌邑区", "label": "昌邑区"}]
    }, {
        "value": "2430_四平市",
        "label": "四平市",
        "children": [{"value": "2431_梨树县", "label": "梨树县"}, {
            "value": "2432_伊通满族自治县",
            "label": "伊通满族自治县"
        }, {"value": "2434_公主岭市", "label": "公主岭市"}, {"value": "2433_双辽市", "label": "双辽市"}, {
            "value": "982430_四平市",
            "label": "四平市"
        }]
    }, {
        "value": "2440_辽源市",
        "label": "辽源市",
        "children": [{"value": "2441_东丰县", "label": "东丰县"}, {
            "value": "2442_东辽县",
            "label": "东辽县"
        }, {"value": "982440_辽源市", "label": "辽源市"}]
    }, {
        "value": "2450_通化市",
        "label": "通化市",
        "children": [{"value": "2451_通化县", "label": "通化县"}, {
            "value": "2452_辉南县",
            "label": "辉南县"
        }, {"value": "2454_梅河口市", "label": "梅河口市"}, {"value": "2455_集安市", "label": "集安市"}, {
            "value": "2453_柳河县",
            "label": "柳河县"
        }, {"value": "982450_通化市", "label": "通化市"}]
    }, {
        "value": "2460_白山市",
        "label": "白山市",
        "children": [{"value": "2462_靖宇县", "label": "靖宇县"}, {
            "value": "2463_长白朝鲜族自治县",
            "label": "长白朝鲜族自治县"
        }, {"value": "2464_临江市", "label": "临江市"}, {"value": "2461_抚松县", "label": "抚松县"}, {
            "value": "982460_白山市",
            "label": "白山市"
        }]
    }, {
        "value": "2490_延边朝鲜族自治州",
        "label": "延边朝鲜族自治州",
        "children": [{"value": "2491_延吉市", "label": "延吉市"}, {"value": "2492_图们市", "label": "图们市"}, {
            "value": "2494_珲春市",
            "label": "珲春市"
        }, {"value": "2495_龙井市", "label": "龙井市"}, {"value": "2496_和龙市", "label": "和龙市"}, {
            "value": "2498_安图县",
            "label": "安图县"
        }, {"value": "2493_敦化市", "label": "敦化市"}, {"value": "2497_汪清县", "label": "汪清县"}, {
            "value": "982490_延边朝鲜族自治州",
            "label": "延边朝鲜族自治州"
        }]
    }, {
        "value": "2510_松原市",
        "label": "松原市",
        "children": [{"value": "2511_扶余县", "label": "扶余县"}, {
            "value": "2513_前郭尔罗斯蒙古族自治县",
            "label": "前郭尔罗斯蒙古族自治县"
        }, {"value": "2514_乾安县", "label": "乾安县"}, {"value": "2512_长岭县", "label": "长岭县"}, {
            "value": "982510_松原市",
            "label": "松原市"
        }]
    }, {
        "value": "2470_白城市",
        "label": "白城市",
        "children": [{"value": "2478_通榆县", "label": "通榆县"}, {
            "value": "982470_白城市",
            "label": "白城市"
        }, {"value": "2472_洮南市", "label": "洮南市"}, {"value": "2474_大安市", "label": "大安市"}, {
            "value": "2477_镇赉县",
            "label": "镇赉县"
        }]
    }]
}, {
    "value": "2600_黑龙江省",
    "label": "黑龙江省",
    "children": [{
        "value": "2640_齐齐哈尔市",
        "label": "齐齐哈尔市",
        "children": [{"value": "2642_讷河市", "label": "讷河市"}, {"value": "2643_依安县", "label": "依安县"}, {
            "value": "2644_泰来县",
            "label": "泰来县"
        }, {"value": "2641_龙江县", "label": "龙江县"}, {"value": "2645_甘南县", "label": "甘南县"}, {
            "value": "982640_齐齐哈尔市",
            "label": "齐齐哈尔市"
        }, {"value": "2647_富裕县", "label": "富裕县"}, {"value": "2649_克山县", "label": "克山县"}, {
            "value": "2651_克东县",
            "label": "克东县"
        }, {"value": "2652_拜泉县", "label": "拜泉县"}]
    }, {
        "value": "2610_哈尔滨市",
        "label": "哈尔滨市",
        "children": [{"value": "2623_宾县", "label": "宾县"}, {
            "value": "2627_通河县",
            "label": "通河县"
        }, {"value": "982610_哈尔滨市", "label": "哈尔滨市"}, {"value": "2624_南岗区", "label": "南岗区"}, {
            "value": "2613_依兰县",
            "label": "依兰县"
        }, {"value": "2621_双城市", "label": "双城市"}, {"value": "2622_尚志市", "label": "尚志市"}, {
            "value": "2624_五常市",
            "label": "五常市"
        }, {"value": "2625_巴彦县", "label": "巴彦县"}, {"value": "2626_木兰县", "label": "木兰县"}, {
            "value": "2628_方正县",
            "label": "方正县"
        }, {"value": "2629_延寿县", "label": "延寿县"}]
    }, {
        "value": "2660_鸡西市",
        "label": "鸡西市",
        "children": [{"value": "2661_鸡东县", "label": "鸡东县"}, {"value": "2662_虎林市", "label": "虎林市"}, {
            "value": "2663_密山市",
            "label": "密山市"
        }, {"value": "982660_鸡西市", "label": "鸡西市"}]
    }, {
        "value": "2670_鹤岗市",
        "label": "鹤岗市",
        "children": [{"value": "2671_萝北县", "label": "萝北县"}, {
            "value": "2672_绥滨县",
            "label": "绥滨县"
        }, {"value": "982670_鹤岗市", "label": "鹤岗市"}]
    }, {
        "value": "2680_双鸭山市",
        "label": "双鸭山市",
        "children": [{"value": "2681_集贤县", "label": "集贤县"}, {"value": "2682_宝清县", "label": "宝清县"}, {
            "value": "2683_饶河县",
            "label": "饶河县"
        }, {"value": "2684_友谊县", "label": "友谊县"}, {"value": "982680_双鸭山市", "label": "双鸭山市"}]
    }, {
        "value": "2690_大庆市",
        "label": "大庆市",
        "children": [{"value": "2691_林甸县", "label": "林甸县"}, {
            "value": "2692_杜尔伯特蒙古族自治县",
            "label": "杜尔伯特蒙古族自治县"
        }, {"value": "2693_肇源县", "label": "肇源县"}, {"value": "2694_肇州县", "label": "肇州县"}, {
            "value": "982690_大庆市",
            "label": "大庆市"
        }, {"value": "2694_龙凤区", "label": "龙凤区"}]
    }, {
        "value": "2710_伊春市",
        "label": "伊春市",
        "children": [{"value": "2711_嘉荫县", "label": "嘉荫县"}, {
            "value": "2712_铁力市",
            "label": "铁力市"
        }, {"value": "982710_伊春市", "label": "伊春市"}]
    }, {
        "value": "2720_佳木斯市",
        "label": "佳木斯市",
        "children": [{"value": "2721_桦南县", "label": "桦南县"}, {"value": "2723_桦川县", "label": "桦川县"}, {
            "value": "2725_汤原县",
            "label": "汤原县"
        }, {"value": "2727_抚远县", "label": "抚远县"}, {"value": "2729_同江市", "label": "同江市"}, {
            "value": "2731_富锦市",
            "label": "富锦市"
        }, {"value": "982720_佳木斯市", "label": "佳木斯市"}]
    }, {
        "value": "2740_七台河市",
        "label": "七台河市",
        "children": [{"value": "2741_勃利县", "label": "勃利县"}, {"value": "982740_七台河市", "label": "七台河市"}]
    }, {
        "value": "2750_牡丹江市",
        "label": "牡丹江市",
        "children": [{"value": "2751_宁安市", "label": "宁安市"}, {"value": "2752_海林市", "label": "海林市"}, {
            "value": "2753_穆棱市",
            "label": "穆棱市"
        }, {"value": "2754_东宁县", "label": "东宁县"}, {"value": "2755_林口县", "label": "林口县"}, {
            "value": "2757_绥芬河市",
            "label": "绥芬河市"
        }, {"value": "982750_牡丹江市", "label": "牡丹江市"}]
    }, {
        "value": "2760_绥化市",
        "label": "绥化市",
        "children": [{"value": "2762_安达市", "label": "安达市"}, {"value": "2763_肇东市", "label": "肇东市"}, {
            "value": "2764_海伦市",
            "label": "海伦市"
        }, {"value": "2765_望奎县", "label": "望奎县"}, {"value": "2766_兰西县", "label": "兰西县"}, {
            "value": "2767_青冈县",
            "label": "青冈县"
        }, {"value": "2771_庆安县", "label": "庆安县"}, {"value": "2772_明水县", "label": "明水县"}, {
            "value": "2773_绥棱县",
            "label": "绥棱县"
        }, {"value": "982760_绥化市", "label": "绥化市"}]
    }, {
        "value": "2780_黑河市",
        "label": "黑河市",
        "children": [{"value": "2782_北安市", "label": "北安市"}, {
            "value": "2783_五大连池市",
            "label": "五大连池市"
        }, {"value": "2784_嫩江县", "label": "嫩江县"}, {"value": "2786_逊克县", "label": "逊克县"}, {
            "value": "2787_孙吴县",
            "label": "孙吴县"
        }, {"value": "982780_黑河市", "label": "黑河市"}]
    }, {
        "value": "2790_大兴安岭地区",
        "label": "大兴安岭地区",
        "children": [{"value": "2791_呼玛县", "label": "呼玛县"}, {"value": "2792_塔河县", "label": "塔河县"}, {
            "value": "2793_漠河县",
            "label": "漠河县"
        }, {"value": "982790_大兴安岭地区", "label": "大兴安岭地区"}]
    }]
}, {
    "value": "6400_海南省",
    "label": "海南省",
    "children": [{
        "value": "6410_海口市",
        "label": "海口市",
        "children": [{"value": "986410_海口市", "label": "海口市"}]
    }, {
        "value": "6420_三亚市",
        "label": "三亚市",
        "children": [{"value": "6421_五指山市", "label": "五指山市"}, {
            "value": "6423_文昌市",
            "label": "文昌市"
        }, {"value": "6424_琼海市", "label": "琼海市"}, {"value": "6425_万宁市", "label": "万宁市"}, {
            "value": "6426_定安县",
            "label": "定安县"
        }, {"value": "6427_屯昌县", "label": "屯昌县"}, {"value": "6428_澄迈县", "label": "澄迈县"}, {
            "value": "6429_临高县",
            "label": "临高县"
        }, {"value": "6431_儋州市", "label": "儋州市"}, {
            "value": "6432_白沙黎族自治县",
            "label": "白沙黎族自治县"
        }, {"value": "6433_昌江黎族自治县", "label": "昌江黎族自治县"}, {
            "value": "6434_东方市（省直辖）",
            "label": "东方市（省直辖）"
        }, {"value": "6435_乐东黎族自治县（省直辖）", "label": "乐东黎族自治县（省直辖）"}, {
            "value": "6436_陵水黎族自治县（省直辖）",
            "label": "陵水黎族自治县（省直辖）"
        }, {"value": "6437_保亭黎族苗族自治县（省直辖）", "label": "保亭黎族苗族自治县（省直辖）"}, {
            "value": "6438_琼中黎族苗族自治县（省直辖）",
            "label": "琼中黎族苗族自治县（省直辖）"
        }, {"value": "6439_西沙群岛", "label": "西沙群岛"}, {
            "value": "6441_南沙群岛",
            "label": "南沙群岛"
        }, {"value": "6442_中沙群岛的岛礁及其海域", "label": "中沙群岛的岛礁及其海域"}, {
            "value": "986420_三亚市",
            "label": "三亚市"
        }, {"value": "6426_龙华区", "label": "龙华区"}, {"value": "6426_琼山区", "label": "琼山区"}]
    }]
}, {
    "value": "6500_四川省",
    "label": "四川省",
    "children": [{
        "value": "6510_成都市",
        "label": "成都市",
        "children": [{"value": "6511_金堂县", "label": "金堂县"}, {"value": "6512_双流县", "label": "双流县"}, {
            "value": "6514_郫县",
            "label": "郫县"
        }, {"value": "6516_彭州市", "label": "彭州市"}, {"value": "6517_崇州市", "label": "崇州市"}, {
            "value": "6518_大邑县",
            "label": "大邑县"
        }, {"value": "6519_邛崃市", "label": "邛崃市"}, {"value": "6521_蒲江县", "label": "蒲江县"}, {
            "value": "6522_新津县",
            "label": "新津县"
        }, {"value": "6523_都江堰市", "label": "都江堰市"}, {"value": "986510_成都市", "label": "成都市"}, {
            "value": "6511_成华区",
            "label": "成华区"
        }, {"value": "6511_青羊区", "label": "青羊区"}, {"value": "6511_武侯区", "label": "武侯区"}, {
            "value": "6511_金牛区",
            "label": "金牛区"
        }, {"value": "6511_新都区", "label": "新都区"}]
    }, {
        "value": "6550_自贡市",
        "label": "自贡市",
        "children": [{"value": "6551_荣县", "label": "荣县"}, {"value": "6552_富顺县", "label": "富顺县"}, {
            "value": "986550_自贡市",
            "label": "自贡市"
        }]
    }, {
        "value": "6560_攀枝花市",
        "label": "攀枝花市",
        "children": [{"value": "6561_米易县", "label": "米易县"}, {
            "value": "6562_盐边县",
            "label": "盐边县"
        }, {"value": "986560_攀枝花市", "label": "攀枝花市"}]
    }, {
        "value": "6570_泸州市",
        "label": "泸州市",
        "children": [{"value": "6571_泸县", "label": "泸县"}, {"value": "6572_合江县", "label": "合江县"}, {
            "value": "6574_叙永县",
            "label": "叙永县"
        }, {"value": "6575_古蔺县", "label": "古蔺县"}, {"value": "986570_泸州市", "label": "泸州市"}]
    }, {
        "value": "6580_德阳市",
        "label": "德阳市",
        "children": [{"value": "6581_绵竹市", "label": "绵竹市"}, {"value": "6582_中江县", "label": "中江县"}, {
            "value": "6583_什邡市",
            "label": "什邡市"
        }, {"value": "6584_广汉市", "label": "广汉市"}, {"value": "6585_罗江县", "label": "罗江县"}, {
            "value": "986580_德阳市",
            "label": "德阳市"
        }]
    }, {
        "value": "6590_绵阳市",
        "label": "绵阳市",
        "children": [{"value": "6591_三台县", "label": "三台县"}, {"value": "6592_盐亭县", "label": "盐亭县"}, {
            "value": "6593_安县",
            "label": "安县"
        }, {"value": "6594_梓潼县", "label": "梓潼县"}, {"value": "6595_北川羌族自治县", "label": "北川羌族自治县"}, {
            "value": "6596_平武县",
            "label": "平武县"
        }, {"value": "6597_江油市", "label": "江油市"}, {"value": "986590_绵阳市", "label": "绵阳市"}]
    }, {
        "value": "6610_广元市",
        "label": "广元市",
        "children": [{"value": "6611_旺苍县", "label": "旺苍县"}, {"value": "6612_青川县", "label": "青川县"}, {
            "value": "6613_剑阁县",
            "label": "剑阁县"
        }, {"value": "6614_苍溪县", "label": "苍溪县"}, {"value": "986610_广元市", "label": "广元市"}]
    }, {
        "value": "6620_遂宁市",
        "label": "遂宁市",
        "children": [{"value": "6621_蓬溪县", "label": "蓬溪县"}, {"value": "6622_射洪县", "label": "射洪县"}, {
            "value": "6623_大英县",
            "label": "大英县"
        }, {"value": "986620_遂宁市", "label": "遂宁市"}]
    }, {
        "value": "6630_内江市",
        "label": "内江市",
        "children": [{"value": "6634_威远县", "label": "威远县"}, {"value": "6635_资中县", "label": "资中县"}, {
            "value": "6638_隆昌县",
            "label": "隆昌县"
        }, {"value": "986630_内江市", "label": "内江市"}]
    }, {
        "value": "6650_乐山市",
        "label": "乐山市",
        "children": [{"value": "6653_犍为县", "label": "犍为县"}, {"value": "6654_井研县", "label": "井研县"}, {
            "value": "6655_夹江县",
            "label": "夹江县"
        }, {"value": "6658_沐川县", "label": "沐川县"}, {
            "value": "6662_峨边彝族自治县",
            "label": "峨边彝族自治县"
        }, {"value": "6663_马边彝族自治县", "label": "马边彝族自治县"}, {
            "value": "6664_峨眉山市",
            "label": "峨眉山市"
        }, {"value": "986650_乐山市", "label": "乐山市"}]
    }, {
        "value": "6670_眉山市",
        "label": "眉山市",
        "children": [{"value": "6671_仁寿县", "label": "仁寿县"}, {"value": "6672_彭山县", "label": "彭山县"}, {
            "value": "6673_洪雅县",
            "label": "洪雅县"
        }, {"value": "6674_丹棱县", "label": "丹棱县"}, {"value": "6675_青神县", "label": "青神县"}, {
            "value": "986670_眉山市",
            "label": "眉山市"
        }, {"value": "6672_东坡区", "label": "东坡区"}]
    }, {
        "value": "6690_广安市",
        "label": "广安市",
        "children": [{"value": "6691_岳池县", "label": "岳池县"}, {"value": "6692_武胜县", "label": "武胜县"}, {
            "value": "6693_邻水县",
            "label": "邻水县"
        }, {"value": "6694_华蓥市", "label": "华蓥市"}, {"value": "986690_广安市", "label": "广安市"}]
    }, {
        "value": "6710_宜宾市",
        "label": "宜宾市",
        "children": [{"value": "6712_宜宾县", "label": "宜宾县"}, {"value": "6713_南溪县", "label": "南溪县"}, {
            "value": "6715_长宁县",
            "label": "长宁县"
        }, {"value": "6716_高县", "label": "高县"}, {"value": "6717_筠连县", "label": "筠连县"}, {
            "value": "6719_兴文县",
            "label": "兴文县"
        }, {"value": "6721_屏山县", "label": "屏山县"}, {"value": "6714_江安县", "label": "江安县"}, {
            "value": "6718_珙县",
            "label": "珙县"
        }, {"value": "986710_宜宾市", "label": "宜宾市"}]
    }, {
        "value": "6730_南充市",
        "label": "南充市",
        "children": [{"value": "6736_营山县", "label": "营山县"}, {"value": "6738_蓬安县", "label": "蓬安县"}, {
            "value": "6739_仪陇县",
            "label": "仪陇县"
        }, {"value": "6743_阆中市", "label": "阆中市"}, {"value": "6734_南部县", "label": "南部县"}, {
            "value": "6742_西充县",
            "label": "西充县"
        }, {"value": "986730_南充市", "label": "南充市"}]
    }, {
        "value": "6750_达州市",
        "label": "达州市",
        "children": [{"value": "6752_达县", "label": "达县"}, {"value": "6754_开江县", "label": "开江县"}, {
            "value": "6755_万源市",
            "label": "万源市"
        }, {"value": "6761_大竹县", "label": "大竹县"}, {"value": "6753_宣汉县", "label": "宣汉县"}, {
            "value": "6762_渠县",
            "label": "渠县"
        }, {"value": "986750_达州市", "label": "达州市"}]
    }, {
        "value": "6770_雅安市",
        "label": "雅安市",
        "children": [{"value": "6772_名山县", "label": "名山县"}, {"value": "6773_荥经县", "label": "荥经县"}, {
            "value": "6775_石棉县",
            "label": "石棉县"
        }, {"value": "6776_天全县", "label": "天全县"}, {"value": "6777_芦山县", "label": "芦山县"}, {
            "value": "6774_汉源县",
            "label": "汉源县"
        }, {"value": "6778_宝兴县", "label": "宝兴县"}, {"value": "986770_雅安市", "label": "雅安市"}]
    }, {
        "value": "6790_阿坝藏族羌族自治州",
        "label": "阿坝藏族羌族自治州",
        "children": [{"value": "6791_汶川县", "label": "汶川县"}, {"value": "6792_理县", "label": "理县"}, {
            "value": "6794_松潘县",
            "label": "松潘县"
        }, {"value": "6795_九寨沟县", "label": "九寨沟县"}, {"value": "6796_金川县", "label": "金川县"}, {
            "value": "6798_黑水县",
            "label": "黑水县"
        }, {"value": "6799_马尔康县", "label": "马尔康县"}, {"value": "6801_壤塘县", "label": "壤塘县"}, {
            "value": "6803_若尔盖县",
            "label": "若尔盖县"
        }, {"value": "6804_红原县", "label": "红原县"}, {"value": "6793_茂县", "label": "茂县"}, {
            "value": "6797_小金县",
            "label": "小金县"
        }, {"value": "6802_阿坝县", "label": "阿坝县"}, {"value": "986790_阿坝藏族羌族自治州", "label": "阿坝藏族羌族自治州"}]
    }, {
        "value": "6810_甘孜藏族自治州",
        "label": "甘孜藏族自治州",
        "children": [{"value": "6812_泸定县", "label": "泸定县"}, {"value": "6813_丹巴县", "label": "丹巴县"}, {
            "value": "6814_九龙县",
            "label": "九龙县"
        }, {"value": "6816_道孚县", "label": "道孚县"}, {"value": "6817_炉霍县", "label": "炉霍县"}, {
            "value": "6818_甘孜县",
            "label": "甘孜县"
        }, {"value": "6821_德格县", "label": "德格县"}, {"value": "6822_白玉县", "label": "白玉县"}, {
            "value": "6823_石渠县",
            "label": "石渠县"
        }, {"value": "6825_理塘县", "label": "理塘县"}, {"value": "6826_巴塘县", "label": "巴塘县"}, {
            "value": "6827_乡城县",
            "label": "乡城县"
        }, {"value": "6829_得荣县", "label": "得荣县"}, {"value": "6811_康定县", "label": "康定县"}, {
            "value": "6815_雅江县",
            "label": "雅江县"
        }, {"value": "6819_新龙县", "label": "新龙县"}, {"value": "6824_色达县", "label": "色达县"}, {
            "value": "6828_稻城县",
            "label": "稻城县"
        }, {"value": "986810_甘孜藏族自治州", "label": "甘孜藏族自治州"}]
    }, {
        "value": "6840_凉山彝族自治州",
        "label": "凉山彝族自治州",
        "children": [{"value": "6841_西昌市", "label": "西昌市"}, {"value": "6843_盐源县", "label": "盐源县"}, {
            "value": "6844_德昌县",
            "label": "德昌县"
        }, {"value": "6845_会理县", "label": "会理县"}, {"value": "6846_会东县", "label": "会东县"}, {
            "value": "6848_普格县",
            "label": "普格县"
        }, {"value": "6849_布拖县", "label": "布拖县"}, {"value": "6851_金阳县", "label": "金阳县"}, {
            "value": "6853_喜德县",
            "label": "喜德县"
        }, {"value": "6854_冕宁县", "label": "冕宁县"}, {"value": "6855_越西县", "label": "越西县"}, {
            "value": "6857_美姑县",
            "label": "美姑县"
        }, {"value": "6858_雷波县", "label": "雷波县"}, {"value": "6842_木里藏族自治县", "label": "木里藏族自治县"}, {
            "value": "6847_宁南县",
            "label": "宁南县"
        }, {"value": "6852_昭觉县", "label": "昭觉县"}, {"value": "6856_甘洛县", "label": "甘洛县"}, {
            "value": "986840_凉山彝族自治州",
            "label": "凉山彝族自治州"
        }]
    }, {
        "value": "6870_巴中市",
        "label": "巴中市",
        "children": [{"value": "6872_南江县", "label": "南江县"}, {
            "value": "6873_平昌县",
            "label": "平昌县"
        }, {"value": "986870_巴中市", "label": "巴中市"}, {"value": "6871_通江县", "label": "通江县"}]
    }, {
        "value": "6880_资阳市",
        "label": "资阳市",
        "children": [{"value": "6882_乐至县", "label": "乐至县"}, {
            "value": "6883_简阳市",
            "label": "简阳市"
        }, {"value": "986880_资阳市", "label": "资阳市"}, {"value": "6881_安岳县", "label": "安岳县"}]
    }]
}, {
    "value": "7000_贵州省",
    "label": "贵州省",
    "children": [{
        "value": "7010_贵阳市",
        "label": "贵阳市",
        "children": [{"value": "7011_开阳县", "label": "开阳县"}, {"value": "7012_息烽县", "label": "息烽县"}, {
            "value": "7013_修文县",
            "label": "修文县"
        }, {"value": "7014_清镇市", "label": "清镇市"}, {"value": "987010_贵阳市", "label": "贵阳市"}, {
            "value": "7013_乌当区",
            "label": "乌当区"
        }, {"value": "7013_花溪区", "label": "花溪区"}, {"value": "7013_南明区", "label": "南明区"}]
    }, {
        "value": "7020_六盘水市",
        "label": "六盘水市",
        "children": [{"value": "7021_水城县", "label": "水城县"}, {
            "value": "7022_盘县",
            "label": "盘县"
        }, {"value": "987020_六盘水市", "label": "六盘水市"}]
    }, {
        "value": "7030_遵义市",
        "label": "遵义市",
        "children": [{"value": "7032_遵义县", "label": "遵义县"}, {"value": "7033_桐梓县", "label": "桐梓县"}, {
            "value": "7034_绥阳县",
            "label": "绥阳县"
        }, {"value": "7035_正安县", "label": "正安县"}, {
            "value": "7036_道真仡佬族苗族自治县",
            "label": "道真仡佬族苗族自治县"
        }, {"value": "7037_务川仡佬族苗族自治县", "label": "务川仡佬族苗族自治县"}, {
            "value": "7038_凤冈县",
            "label": "凤冈县"
        }, {"value": "7039_湄潭县", "label": "湄潭县"}, {"value": "7041_余庆县", "label": "余庆县"}, {
            "value": "7042_仁怀市",
            "label": "仁怀市"
        }, {"value": "7043_赤水市", "label": "赤水市"}, {"value": "7044_习水县", "label": "习水县"}, {
            "value": "987030_遵义市",
            "label": "遵义市"
        }, {"value": "7032_红花岗区", "label": "红花岗区"}]
    }, {
        "value": "7050_铜仁地区",
        "label": "铜仁地区",
        "children": [{"value": "7051_铜仁市", "label": "铜仁市"}, {
            "value": "7052_江口县",
            "label": "江口县"
        }, {"value": "7053_玉屏侗族自治县", "label": "玉屏侗族自治县"}, {"value": "7054_石阡县", "label": "石阡县"}, {
            "value": "7055_思南县",
            "label": "思南县"
        }, {"value": "7056_印江土家族苗族自治县", "label": "印江土家族苗族自治县"}, {
            "value": "7057_德江县",
            "label": "德江县"
        }, {"value": "7058_沿河土家族自治县", "label": "沿河土家族自治县"}, {
            "value": "7059_松桃苗族自治县",
            "label": "松桃苗族自治县"
        }, {"value": "7061_万山特区", "label": "万山特区"}, {"value": "987050_铜仁地区", "label": "铜仁地区"}]
    }, {
        "value": "7070_黔西南布依族苗族自治州",
        "label": "黔西南布依族苗族自治州",
        "children": [{"value": "7071_兴义市", "label": "兴义市"}, {"value": "7072_兴仁县", "label": "兴仁县"}, {
            "value": "7073_普安县",
            "label": "普安县"
        }, {"value": "7074_晴隆县", "label": "晴隆县"}, {"value": "7075_贞丰县", "label": "贞丰县"}, {
            "value": "7076_望谟县",
            "label": "望谟县"
        }, {"value": "7077_册亨县", "label": "册亨县"}, {"value": "7078_安龙县", "label": "安龙县"}, {
            "value": "987070_黔西南布依族苗族自治州",
            "label": "黔西南布依族苗族自治州"
        }]
    }, {
        "value": "7090_毕节地区",
        "label": "毕节地区",
        "children": [{"value": "7091_毕节市", "label": "毕节市"}, {"value": "7092_大方县", "label": "大方县"}, {
            "value": "7093_黔西县",
            "label": "黔西县"
        }, {"value": "7094_金沙县", "label": "金沙县"}, {"value": "7095_织金县", "label": "织金县"}, {
            "value": "7096_纳雍县",
            "label": "纳雍县"
        }, {"value": "7097_威宁彝族回族苗族自治县", "label": "威宁彝族回族苗族自治县"}, {
            "value": "7098_赫章县",
            "label": "赫章县"
        }, {"value": "987090_毕节地区", "label": "毕节地区"}]
    }, {
        "value": "7110_安顺市",
        "label": "安顺市",
        "children": [{"value": "7117_平坝县", "label": "平坝县"}, {
            "value": "7118_普定县",
            "label": "普定县"
        }, {"value": "7119_关岭布依族苗族自治县", "label": "关岭布依族苗族自治县"}, {
            "value": "7121_镇宁布依族苗族自治县",
            "label": "镇宁布依族苗族自治县"
        }, {"value": "7122_紫云苗族布依族自治县", "label": "紫云苗族布依族自治县"}, {"value": "987110_安顺市", "label": "安顺市"}]
    }, {
        "value": "7130_黔东南苗族侗族自治州",
        "label": "黔东南苗族侗族自治州",
        "children": [{"value": "7131_凯里市", "label": "凯里市"}, {"value": "7132_黄平县", "label": "黄平县"}, {
            "value": "7133_施秉县",
            "label": "施秉县"
        }, {"value": "7134_三穗县", "label": "三穗县"}, {"value": "7135_镇远县", "label": "镇远县"}, {
            "value": "7136_岑巩县",
            "label": "岑巩县"
        }, {"value": "7137_天柱县", "label": "天柱县"}, {"value": "7138_锦屏县", "label": "锦屏县"}, {
            "value": "7139_剑河县",
            "label": "剑河县"
        }, {"value": "7141_台江县", "label": "台江县"}, {"value": "7142_黎平县", "label": "黎平县"}, {
            "value": "7143_榕江县",
            "label": "榕江县"
        }, {"value": "7144_从江县", "label": "从江县"}, {"value": "7145_雷山县", "label": "雷山县"}, {
            "value": "7146_麻江县",
            "label": "麻江县"
        }, {"value": "7147_丹寨县", "label": "丹寨县"}, {"value": "987130_黔东南苗族侗族自治州", "label": "黔东南苗族侗族自治州"}]
    }, {
        "value": "7150_黔南布依族苗族自治州",
        "label": "黔南布依族苗族自治州",
        "children": [{"value": "7151_都匀市", "label": "都匀市"}, {"value": "7152_荔波县", "label": "荔波县"}, {
            "value": "7153_贵定县",
            "label": "贵定县"
        }, {"value": "7154_福泉市", "label": "福泉市"}, {"value": "7155_瓮安县", "label": "瓮安县"}, {
            "value": "7156_独山县",
            "label": "独山县"
        }, {"value": "7157_平塘县", "label": "平塘县"}, {"value": "7158_罗甸县", "label": "罗甸县"}, {
            "value": "7159_长顺县",
            "label": "长顺县"
        }, {"value": "7161_龙里县", "label": "龙里县"}, {"value": "7162_惠水县", "label": "惠水县"}, {
            "value": "7163_三都水族自治县",
            "label": "三都水族自治县"
        }, {"value": "987150_黔南布依族苗族自治州", "label": "黔南布依族苗族自治州"}]
    }]
}, {
    "value": "7300_云南省",
    "label": "云南省",
    "children": [{
        "value": "7310_昆明市",
        "label": "昆明市",
        "children": [{"value": "7311_呈贡县", "label": "呈贡县"}, {"value": "7312_晋宁县", "label": "晋宁县"}, {
            "value": "7313_安宁市",
            "label": "安宁市"
        }, {"value": "7314_富民县", "label": "富民县"}, {"value": "7315_宜良县", "label": "宜良县"}, {
            "value": "7316_石林彝族自治县",
            "label": "石林彝族自治县"
        }, {"value": "7317_嵩明县", "label": "嵩明县"}, {
            "value": "7318_禄劝彝族苗族自治县",
            "label": "禄劝彝族苗族自治县"
        }, {"value": "7319_寻甸回族彝族自治县", "label": "寻甸回族彝族自治县"}, {
            "value": "987310_昆明市",
            "label": "昆明市"
        }, {"value": "7312_五华区", "label": "五华区"}, {"value": "7312_官渡区", "label": "官渡区"}]
    }, {
        "value": "7340_昭通市",
        "label": "昭通市",
        "children": [{"value": "7342_鲁甸县", "label": "鲁甸县"}, {"value": "7343_巧家县", "label": "巧家县"}, {
            "value": "7344_盐津县",
            "label": "盐津县"
        }, {"value": "7345_大关县", "label": "大关县"}, {"value": "7346_永善县", "label": "永善县"}, {
            "value": "7347_绥江县",
            "label": "绥江县"
        }, {"value": "7348_镇雄县", "label": "镇雄县"}, {"value": "7349_彝良县", "label": "彝良县"}, {
            "value": "7351_威信县",
            "label": "威信县"
        }, {"value": "7352_水富县", "label": "水富县"}, {"value": "987340_昭通市", "label": "昭通市"}]
    }, {
        "value": "7360_曲靖市",
        "label": "曲靖市",
        "children": [{"value": "7362_马龙县", "label": "马龙县"}, {"value": "7363_宣威市", "label": "宣威市"}, {
            "value": "7364_富源县",
            "label": "富源县"
        }, {"value": "7365_罗平县", "label": "罗平县"}, {"value": "7366_师宗县", "label": "师宗县"}, {
            "value": "7367_陆良县",
            "label": "陆良县"
        }, {"value": "7369_会泽县", "label": "会泽县"}, {"value": "7371_沾益县", "label": "沾益县"}, {
            "value": "987360_曲靖市",
            "label": "曲靖市"
        }]
    }, {
        "value": "7380_楚雄彝族自治州",
        "label": "楚雄彝族自治州",
        "children": [{"value": "7381_楚雄市", "label": "楚雄市"}, {"value": "7382_双柏县", "label": "双柏县"}, {
            "value": "7383_牟定县",
            "label": "牟定县"
        }, {"value": "7384_南华县", "label": "南华县"}, {"value": "7385_姚安县", "label": "姚安县"}, {
            "value": "7386_大姚县",
            "label": "大姚县"
        }, {"value": "7387_永仁县", "label": "永仁县"}, {"value": "7388_元谋县", "label": "元谋县"}, {
            "value": "7391_禄丰县",
            "label": "禄丰县"
        }, {"value": "987380_楚雄彝族自治州", "label": "楚雄彝族自治州"}, {"value": "7389_武定县", "label": "武定县"}]
    }, {
        "value": "7410_玉溪市",
        "label": "玉溪市",
        "children": [{"value": "7412_江川县", "label": "江川县"}, {"value": "7414_通海县", "label": "通海县"}, {
            "value": "7415_华宁县",
            "label": "华宁县"
        }, {"value": "7416_易门县", "label": "易门县"}, {
            "value": "7418_新平彝族傣族自治县",
            "label": "新平彝族傣族自治县"
        }, {"value": "7419_元江哈尼族彝族傣族自治县", "label": "元江哈尼族彝族傣族自治县"}, {
            "value": "987410_玉溪市",
            "label": "玉溪市"
        }, {"value": "7413_澄江县", "label": "澄江县"}, {"value": "7417_峨山彝族自治县", "label": "峨山彝族自治县"}]
    }, {
        "value": "7430_红河哈尼族彝族自治州",
        "label": "红河哈尼族彝族自治州",
        "children": [{"value": "7431_个旧市", "label": "个旧市"}, {
            "value": "7433_蒙自县",
            "label": "蒙自县"
        }, {"value": "7434_屏边苗族自治县", "label": "屏边苗族自治县"}, {"value": "7435_建水县", "label": "建水县"}, {
            "value": "7437_弥勒县",
            "label": "弥勒县"
        }, {"value": "7438_泸西县", "label": "泸西县"}, {"value": "7439_元阳县", "label": "元阳县"}, {
            "value": "7442_金平苗族瑶族傣族自治县",
            "label": "金平苗族瑶族傣族自治县"
        }, {"value": "7443_绿春县", "label": "绿春县"}, {
            "value": "7444_河口瑶族自治县",
            "label": "河口瑶族自治县"
        }, {"value": "987430_红河哈尼族彝族自治州", "label": "红河哈尼族彝族自治州"}, {
            "value": "7432_开远市",
            "label": "开远市"
        }, {"value": "7436_石屏县", "label": "石屏县"}, {"value": "7441_红河县", "label": "红河县"}]
    }, {
        "value": "7470_普洱市",
        "label": "普洱市",
        "children": [{"value": "7473_墨江哈尼族自治县", "label": "墨江哈尼族自治县"}, {
            "value": "7474_景东彝族自治县",
            "label": "景东彝族自治县"
        }, {"value": "7475_景谷傣族彝族自治县", "label": "景谷傣族彝族自治县"}, {
            "value": "7476_镇沅彝族哈尼族拉祜族自治县",
            "label": "镇沅彝族哈尼族拉祜族自治县"
        }, {"value": "7477_江城哈尼族彝族自治县", "label": "江城哈尼族彝族自治县"}, {
            "value": "7479_澜沧拉祜族自治县",
            "label": "澜沧拉祜族自治县"
        }, {"value": "7481_西盟佤族自治县", "label": "西盟佤族自治县"}, {
            "value": "987470_普洱市",
            "label": "普洱市"
        }, {"value": "7472_宁洱哈尼族彝族自治县", "label": "宁洱哈尼族彝族自治县"}, {"value": "7478_孟连傣族拉祜族佤族自治县", "label": "孟连傣族拉祜族佤族自治县"}]
    }, {
        "value": "7490_西双版纳傣族自治州",
        "label": "西双版纳傣族自治州",
        "children": [{"value": "7491_景洪市", "label": "景洪市"}, {"value": "7492_勐海县", "label": "勐海县"}, {
            "value": "7493_勐腊县",
            "label": "勐腊县"
        }, {"value": "987490_西双版纳傣族自治州", "label": "西双版纳傣族自治州"}]
    }, {
        "value": "7530_保山市",
        "label": "保山市",
        "children": [{"value": "7532_施甸县", "label": "施甸县"}, {"value": "7534_龙陵县", "label": "龙陵县"}, {
            "value": "7535_昌宁县",
            "label": "昌宁县"
        }, {"value": "987530_保山市", "label": "保山市"}, {"value": "7533_腾冲县", "label": "腾冲县"}]
    }, {
        "value": "7540_德宏傣族景颇族自治州",
        "label": "德宏傣族景颇族自治州",
        "children": [{"value": "7543_梁河县", "label": "梁河县"}, {"value": "7544_盈江县", "label": "盈江县"}, {
            "value": "7545_陇川县",
            "label": "陇川县"
        }, {"value": "987540_德宏傣族景颇族自治州", "label": "德宏傣族景颇族自治州"}, {
            "value": "7542_潞西市",
            "label": "潞西市"
        }, {"value": "7546_瑞丽市", "label": "瑞丽市"}]
    }, {
        "value": "7550_丽江市",
        "label": "丽江市",
        "children": [{"value": "7552_永胜县", "label": "永胜县"}, {
            "value": "7553_华坪县",
            "label": "华坪县"
        }, {"value": "7555_玉龙纳西族自治县", "label": "玉龙纳西族自治县"}, {
            "value": "987550_丽江市",
            "label": "丽江市"
        }, {"value": "7554_宁蒗彝族自治县", "label": "宁蒗彝族自治县"}]
    }, {
        "value": "7560_怒江傈僳族自治州",
        "label": "怒江傈僳族自治州",
        "children": [{"value": "7561_泸水县", "label": "泸水县"}, {
            "value": "7562_福贡县",
            "label": "福贡县"
        }, {"value": "7564_兰坪白族普米族自治县", "label": "兰坪白族普米族自治县"}, {
            "value": "987560_怒江傈僳族自治州",
            "label": "怒江傈僳族自治州"
        }, {"value": "7563_贡山独龙族怒族自治县", "label": "贡山独龙族怒族自治县"}]
    }, {
        "value": "7570_迪庆藏族自治州",
        "label": "迪庆藏族自治州",
        "children": [{"value": "7571_香格里拉县", "label": "香格里拉县"}, {
            "value": "7572_德钦县",
            "label": "德钦县"
        }, {"value": "7573_维西傈僳族自治县", "label": "维西傈僳族自治县"}, {"value": "987570_迪庆藏族自治州", "label": "迪庆藏族自治州"}]
    }, {
        "value": "7580_临沧市",
        "label": "临沧市",
        "children": [{"value": "7583_云县", "label": "云县"}, {"value": "7584_永德县", "label": "永德县"}, {
            "value": "7585_镇康县",
            "label": "镇康县"
        }, {"value": "7587_耿马傣族佤族自治县", "label": "耿马傣族佤族自治县"}, {
            "value": "7588_沧源佤族自治县",
            "label": "沧源佤族自治县"
        }, {"value": "987580_临沧市", "label": "临沧市"}, {
            "value": "7582_凤庆县",
            "label": "凤庆县"
        }, {"value": "7586_双江拉祜族佤族布朗族傣族自治县", "label": "双江拉祜族佤族布朗族傣族自治县"}]
    }, {"value": "730001_思茅市", "label": "思茅市"}, {
        "value": "7450_文山壮族苗族自治州",
        "label": "文山壮族苗族自治州",
        "children": [{"value": "7456_丘北县", "label": "丘北县"}, {"value": "7451_文山县", "label": "文山县"}, {
            "value": "7452_砚山县",
            "label": "砚山县"
        }, {"value": "7453_西畴县", "label": "西畴县"}, {"value": "7454_麻栗坡县", "label": "麻栗坡县"}, {
            "value": "7455_马关县",
            "label": "马关县"
        }, {"value": "7457_广南县", "label": "广南县"}, {"value": "7458_富宁县", "label": "富宁县"}, {
            "value": "987450_文山壮族苗族自治州",
            "label": "文山壮族苗族自治州"
        }]
    }, {
        "value": "7510_大理白族自治州",
        "label": "大理白族自治州",
        "children": [{"value": "7516_南涧彝族自治县", "label": "南涧彝族自治县"}, {
            "value": "7522_剑川县",
            "label": "剑川县"
        }, {"value": "7511_大理市", "label": "大理市"}, {"value": "7512_漾濞彝族自治县", "label": "漾濞彝族自治县"}, {
            "value": "7513_祥云县",
            "label": "祥云县"
        }, {"value": "7514_宾川县", "label": "宾川县"}, {"value": "7515_弥渡县", "label": "弥渡县"}, {
            "value": "7517_巍山彝族回族自治县",
            "label": "巍山彝族回族自治县"
        }, {"value": "7518_永平县", "label": "永平县"}, {"value": "7519_云龙县", "label": "云龙县"}, {
            "value": "7521_洱源县",
            "label": "洱源县"
        }, {"value": "7523_鹤庆县", "label": "鹤庆县"}, {"value": "987510_大理白族自治州", "label": "大理白族自治州"}]
    }]
}, {
    "value": "7700_西藏自治区",
    "label": "西藏自治区",
    "children": [{
        "value": "7710_拉萨市",
        "label": "拉萨市",
        "children": [{"value": "7711_林周县", "label": "林周县"}, {"value": "7712_当雄县", "label": "当雄县"}, {
            "value": "7713_尼木县",
            "label": "尼木县"
        }, {"value": "7714_曲水县", "label": "曲水县"}, {"value": "7715_堆龙德庆县", "label": "堆龙德庆县"}, {
            "value": "7716_达孜县",
            "label": "达孜县"
        }, {"value": "7717_墨竹工卡县", "label": "墨竹工卡县"}, {"value": "987710_拉萨市", "label": "拉萨市"}]
    }, {
        "value": "7720_昌都地区",
        "label": "昌都地区",
        "children": [{"value": "7721_昌都县", "label": "昌都县"}, {"value": "7722_江达县", "label": "江达县"}, {
            "value": "7723_贡觉县",
            "label": "贡觉县"
        }, {"value": "7724_类乌齐县", "label": "类乌齐县"}, {"value": "7725_丁青县", "label": "丁青县"}, {
            "value": "7726_察雅县",
            "label": "察雅县"
        }, {"value": "7727_八宿县", "label": "八宿县"}, {"value": "7728_左贡县", "label": "左贡县"}, {
            "value": "7729_芒康县",
            "label": "芒康县"
        }, {"value": "7731_洛隆县", "label": "洛隆县"}, {"value": "7732_边坝县", "label": "边坝县"}, {
            "value": "987720_昌都地区",
            "label": "昌都地区"
        }]
    }, {
        "value": "7740_山南地区",
        "label": "山南地区",
        "children": [{"value": "7741_乃东县", "label": "乃东县"}, {"value": "7742_扎囊县", "label": "扎囊县"}, {
            "value": "7743_贡嘎县",
            "label": "贡嘎县"
        }, {"value": "7744_桑日县", "label": "桑日县"}, {"value": "7745_琼结县", "label": "琼结县"}, {
            "value": "7746_曲松县",
            "label": "曲松县"
        }, {"value": "7747_措美县", "label": "措美县"}, {"value": "7748_洛扎县", "label": "洛扎县"}, {
            "value": "7749_加查县",
            "label": "加查县"
        }, {"value": "7751_隆子县", "label": "隆子县"}, {"value": "7752_错那县", "label": "错那县"}, {
            "value": "7753_浪卡子县",
            "label": "浪卡子县"
        }, {"value": "987740_山南地区", "label": "山南地区"}]
    }, {
        "value": "7760_日喀则地区",
        "label": "日喀则地区",
        "children": [{"value": "7761_日喀则市", "label": "日喀则市"}, {
            "value": "7762_南木林县",
            "label": "南木林县"
        }, {"value": "7763_江孜县", "label": "江孜县"}, {"value": "7764_定日县", "label": "定日县"}, {
            "value": "7765_萨迦县",
            "label": "萨迦县"
        }, {"value": "7766_拉孜县", "label": "拉孜县"}, {"value": "7767_昂仁县", "label": "昂仁县"}, {
            "value": "7768_谢通门县",
            "label": "谢通门县"
        }, {"value": "7769_白朗县", "label": "白朗县"}, {"value": "7771_仁布县", "label": "仁布县"}, {
            "value": "7772_康马县",
            "label": "康马县"
        }, {"value": "7773_定结县", "label": "定结县"}, {"value": "7774_仲巴县", "label": "仲巴县"}, {
            "value": "7775_亚东县",
            "label": "亚东县"
        }, {"value": "7776_吉隆县", "label": "吉隆县"}, {"value": "7777_聂拉木县", "label": "聂拉木县"}, {
            "value": "7778_萨嘎县",
            "label": "萨嘎县"
        }, {"value": "7779_岗巴县", "label": "岗巴县"}, {"value": "987760_日喀则地区", "label": "日喀则地区"}]
    }, {
        "value": "7790_那曲地区",
        "label": "那曲地区",
        "children": [{"value": "7791_那曲县", "label": "那曲县"}, {"value": "7792_嘉黎县", "label": "嘉黎县"}, {
            "value": "7793_比如县",
            "label": "比如县"
        }, {"value": "7794_聂荣县", "label": "聂荣县"}, {"value": "7795_安多县", "label": "安多县"}, {
            "value": "7796_申扎县",
            "label": "申扎县"
        }, {"value": "7797_索县", "label": "索县"}, {"value": "7798_班戈县", "label": "班戈县"}, {
            "value": "7799_巴青县",
            "label": "巴青县"
        }, {"value": "7801_尼玛县以及双湖特别区", "label": "尼玛县以及双湖特别区"}, {"value": "987790_那曲地区", "label": "那曲地区"}]
    }, {
        "value": "7810_阿里地区",
        "label": "阿里地区",
        "children": [{"value": "7811_普兰县", "label": "普兰县"}, {"value": "7812_札达县", "label": "札达县"}, {
            "value": "7813_噶尔县",
            "label": "噶尔县"
        }, {"value": "7814_日土县", "label": "日土县"}, {"value": "7815_革吉县", "label": "革吉县"}, {
            "value": "7816_改则县",
            "label": "改则县"
        }, {"value": "7817_措勤县", "label": "措勤县"}, {"value": "987810_阿里地区", "label": "阿里地区"}]
    }, {
        "value": "7830_林芝地区",
        "label": "林芝地区",
        "children": [{"value": "7831_林芝县", "label": "林芝县"}, {
            "value": "7832_工布江达县",
            "label": "工布江达县"
        }, {"value": "7833_米林县", "label": "米林县"}, {"value": "7834_墨脱县", "label": "墨脱县"}, {
            "value": "7835_波密县",
            "label": "波密县"
        }, {"value": "7836_察隅县", "label": "察隅县"}, {"value": "7837_朗县", "label": "朗县"}, {
            "value": "987830_林芝地区",
            "label": "林芝地区"
        }]
    }]
}, {
    "value": "7900_陕西省",
    "label": "陕西省",
    "children": [{
        "value": "7910_西安市",
        "label": "西安市",
        "children": [{"value": "7912_蓝田县", "label": "蓝田县"}, {"value": "7914_周至县", "label": "周至县"}, {
            "value": "7915_户县",
            "label": "户县"
        }, {"value": "7916_高陵县", "label": "高陵县"}, {"value": "987910_西安市", "label": "西安市"}, {
            "value": "7912_长安区",
            "label": "长安区"
        }, {"value": "7912_雁塔区", "label": "雁塔区"}, {"value": "7912_未央区", "label": "未央区"}, {
            "value": "7912_碑林区",
            "label": "碑林区"
        }]
    }, {
        "value": "7920_铜川市",
        "label": "铜川市",
        "children": [{"value": "7922_宜君县", "label": "宜君县"}, {"value": "987920_铜川市", "label": "铜川市"}]
    }, {
        "value": "7930_宝鸡市",
        "label": "宝鸡市",
        "children": [{"value": "7932_凤翔县", "label": "凤翔县"}, {"value": "7933_岐山县", "label": "岐山县"}, {
            "value": "7934_扶风县",
            "label": "扶风县"
        }, {"value": "7935_眉县", "label": "眉县"}, {"value": "7936_陇县", "label": "陇县"}, {
            "value": "7937_千阳县",
            "label": "千阳县"
        }, {"value": "7938_麟游县", "label": "麟游县"}, {"value": "7939_凤县", "label": "凤县"}, {
            "value": "7941_太白县",
            "label": "太白县"
        }, {"value": "987930_宝鸡市", "label": "宝鸡市"}]
    }, {
        "value": "7950_咸阳市",
        "label": "咸阳市",
        "children": [{"value": "7951_兴平市", "label": "兴平市"}, {"value": "7952_三原县", "label": "三原县"}, {
            "value": "7953_泾阳县",
            "label": "泾阳县"
        }, {"value": "7954_乾县", "label": "乾县"}, {"value": "7955_礼泉县", "label": "礼泉县"}, {
            "value": "7956_永寿县",
            "label": "永寿县"
        }, {"value": "7957_彬县", "label": "彬县"}, {"value": "7958_长武县", "label": "长武县"}, {
            "value": "7959_旬邑县",
            "label": "旬邑县"
        }, {"value": "7961_淳化县", "label": "淳化县"}, {"value": "7962_武功县", "label": "武功县"}, {
            "value": "987950_咸阳市",
            "label": "咸阳市"
        }]
    }, {
        "value": "7970_渭南市",
        "label": "渭南市",
        "children": [{"value": "7972_韩城市", "label": "韩城市"}, {"value": "7973_华县", "label": "华县"}, {
            "value": "7974_华阴市",
            "label": "华阴市"
        }, {"value": "7975_潼关县", "label": "潼关县"}, {"value": "7976_大荔县", "label": "大荔县"}, {
            "value": "7977_蒲城县",
            "label": "蒲城县"
        }, {"value": "7978_澄城县", "label": "澄城县"}, {"value": "7979_白水县", "label": "白水县"}, {
            "value": "7981_合阳县",
            "label": "合阳县"
        }, {"value": "7982_富平县", "label": "富平县"}, {"value": "987970_渭南市", "label": "渭南市"}, {
            "value": "7975_临渭区",
            "label": "临渭区"
        }]
    }, {
        "value": "7990_汉中市",
        "label": "汉中市",
        "children": [{"value": "7992_南郑县", "label": "南郑县"}, {"value": "7993_城固县", "label": "城固县"}, {
            "value": "7994_洋县",
            "label": "洋县"
        }, {"value": "7995_西乡县", "label": "西乡县"}, {"value": "7996_勉县", "label": "勉县"}, {
            "value": "7997_宁强县",
            "label": "宁强县"
        }, {"value": "7998_略阳县", "label": "略阳县"}, {"value": "7999_镇巴县", "label": "镇巴县"}, {
            "value": "8001_留坝县",
            "label": "留坝县"
        }, {"value": "8002_佛坪县", "label": "佛坪县"}, {"value": "987990_汉中市", "label": "汉中市"}]
    }, {
        "value": "8030_商洛市",
        "label": "商洛市",
        "children": [{"value": "8032_洛南县", "label": "洛南县"}, {"value": "8034_商南县", "label": "商南县"}, {
            "value": "8035_山阳县",
            "label": "山阳县"
        }, {"value": "8036_镇安县", "label": "镇安县"}, {"value": "988030_商洛市", "label": "商洛市"}, {
            "value": "8033_丹凤县",
            "label": "丹凤县"
        }, {"value": "8037_柞水县", "label": "柞水县"}]
    }, {
        "value": "8040_延安市",
        "label": "延安市",
        "children": [{"value": "8042_延长县", "label": "延长县"}, {"value": "8043_延川县", "label": "延川县"}, {
            "value": "8045_安塞县",
            "label": "安塞县"
        }, {"value": "8046_志丹县", "label": "志丹县"}, {"value": "8047_吴起县", "label": "吴起县"}, {
            "value": "8049_富县",
            "label": "富县"
        }, {"value": "8051_洛川县", "label": "洛川县"}, {"value": "8052_宜川县", "label": "宜川县"}, {
            "value": "8054_黄陵县",
            "label": "黄陵县"
        }, {"value": "988040_延安市", "label": "延安市"}, {"value": "8044_子长县", "label": "子长县"}, {
            "value": "8048_甘泉县",
            "label": "甘泉县"
        }, {"value": "8053_黄龙县", "label": "黄龙县"}, {"value": "8043_宝塔区", "label": "宝塔区"}]
    }, {
        "value": "8060_榆林市",
        "label": "榆林市",
        "children": [{"value": "8062_神木县", "label": "神木县"}, {"value": "8064_横山县", "label": "横山县"}, {
            "value": "8065_靖边县",
            "label": "靖边县"
        }, {"value": "8066_定边县", "label": "定边县"}, {"value": "8068_米脂县", "label": "米脂县"}, {
            "value": "8069_佳县",
            "label": "佳县"
        }, {"value": "8071_吴堡县", "label": "吴堡县"}, {"value": "8073_子洲县", "label": "子洲县"}, {
            "value": "988060_榆林市",
            "label": "榆林市"
        }, {"value": "8063_府谷县", "label": "府谷县"}, {"value": "8067_绥德县", "label": "绥德县"}, {
            "value": "8072_清涧县",
            "label": "清涧县"
        }, {"value": "8063_榆阳区", "label": "榆阳区"}]
    }, {
        "value": "8010_安康市",
        "label": "安康市",
        "children": [{"value": "8015_紫阳县", "label": "紫阳县"}, {"value": "8019_旬阳县", "label": "旬阳县"}, {
            "value": "8012_汉阴县",
            "label": "汉阴县"
        }, {"value": "8013_石泉县", "label": "石泉县"}, {"value": "8014_宁陕县", "label": "宁陕县"}, {
            "value": "8016_岚皋县",
            "label": "岚皋县"
        }, {"value": "8017_平利县", "label": "平利县"}, {"value": "8018_镇坪县", "label": "镇坪县"}, {
            "value": "8021_白河县",
            "label": "白河县"
        }, {"value": "988010_安康市", "label": "安康市"}]
    }]
}, {
    "value": "8200_甘肃省",
    "label": "甘肃省",
    "children": [{
        "value": "8210_兰州市",
        "label": "兰州市",
        "children": [{"value": "8212_皋兰县", "label": "皋兰县"}, {
            "value": "8213_榆中县",
            "label": "榆中县"
        }, {"value": "988210_兰州市", "label": "兰州市"}, {"value": "8211_永登县", "label": "永登县"}]
    }, {
        "value": "8220_嘉峪关市",
        "label": "嘉峪关市",
        "children": [{"value": "988220_嘉峪关市", "label": "嘉峪关市"}]
    }, {
        "value": "8240_白银市",
        "label": "白银市",
        "children": [{"value": "8241_靖远县", "label": "靖远县"}, {
            "value": "8243_景泰县",
            "label": "景泰县"
        }, {"value": "988240_白银市", "label": "白银市"}, {"value": "8242_会宁县", "label": "会宁县"}, {
            "value": "8241_平川区",
            "label": "平川区"
        }]
    }, {
        "value": "8250_天水市",
        "label": "天水市",
        "children": [{"value": "8251_清水县", "label": "清水县"}, {"value": "8253_甘谷县", "label": "甘谷县"}, {
            "value": "8254_武山县",
            "label": "武山县"
        }, {"value": "8255_张家川回族自治县", "label": "张家川回族自治县"}, {
            "value": "988250_天水市",
            "label": "天水市"
        }, {"value": "8252_秦安县", "label": "秦安县"}]
    }, {
        "value": "8270_张掖市",
        "label": "张掖市",
        "children": [{"value": "8272_肃南裕固族自治县", "label": "肃南裕固族自治县"}, {
            "value": "8273_民乐县",
            "label": "民乐县"
        }, {"value": "8275_高台县", "label": "高台县"}, {"value": "8276_山丹县", "label": "山丹县"}, {
            "value": "988270_张掖市",
            "label": "张掖市"
        }, {"value": "8274_临泽县", "label": "临泽县"}]
    }, {
        "value": "8280_武威市",
        "label": "武威市",
        "children": [{"value": "8283_古浪县", "label": "古浪县"}, {
            "value": "8284_天祝藏族自治县",
            "label": "天祝藏族自治县"
        }, {"value": "988280_武威市", "label": "武威市"}, {"value": "8282_民勤县", "label": "民勤县"}]
    }, {
        "value": "8290_定西市",
        "label": "定西市",
        "children": [{"value": "8293_陇西县", "label": "陇西县"}, {"value": "8294_渭源县", "label": "渭源县"}, {
            "value": "8295_临洮县",
            "label": "临洮县"
        }, {"value": "8297_岷县", "label": "岷县"}, {"value": "988290_定西市", "label": "定西市"}, {
            "value": "8292_通渭县",
            "label": "通渭县"
        }, {"value": "8296_漳县", "label": "漳县"}, {"value": "8293_安定区", "label": "安定区"}]
    }, {
        "value": "8310_陇南市",
        "label": "陇南市",
        "children": [{"value": "8312_宕昌县", "label": "宕昌县"}, {"value": "8314_康县", "label": "康县"}, {
            "value": "8315_文县",
            "label": "文县"
        }, {"value": "8316_西和县", "label": "西和县"}, {"value": "8318_两当县", "label": "两当县"}, {
            "value": "8319_徽县",
            "label": "徽县"
        }, {"value": "988310_陇南市", "label": "陇南市"}, {"value": "8313_成县", "label": "成县"}, {
            "value": "8317_礼县",
            "label": "礼县"
        }]
    }, {
        "value": "8330_平凉市",
        "label": "平凉市",
        "children": [{"value": "8333_灵台县", "label": "灵台县"}, {"value": "8334_崇信县", "label": "崇信县"}, {
            "value": "8335_华亭县",
            "label": "华亭县"
        }, {"value": "8337_静宁县", "label": "静宁县"}, {"value": "988330_平凉市", "label": "平凉市"}, {
            "value": "8332_泾川县",
            "label": "泾川县"
        }, {"value": "8336_庄浪县", "label": "庄浪县"}]
    }, {
        "value": "8340_庆阳市",
        "label": "庆阳市",
        "children": [{"value": "8342_庆城县", "label": "庆城县"}, {"value": "8344_华池县", "label": "华池县"}, {
            "value": "8345_合水县",
            "label": "合水县"
        }, {"value": "8346_正宁县", "label": "正宁县"}, {"value": "8348_镇原县", "label": "镇原县"}, {
            "value": "988340_庆阳市",
            "label": "庆阳市"
        }, {"value": "8343_环县", "label": "环县"}, {"value": "8347_宁县", "label": "宁县"}]
    }, {
        "value": "8360_临夏回族自治州",
        "label": "临夏回族自治州",
        "children": [{"value": "8361_临夏市", "label": "临夏市"}, {"value": "8363_康乐县", "label": "康乐县"}, {
            "value": "8364_永靖县",
            "label": "永靖县"
        }, {"value": "8365_广河县", "label": "广河县"}, {
            "value": "8367_东乡族自治县",
            "label": "东乡族自治县"
        }, {"value": "8368_积石山保安族东乡族撒拉族自治县", "label": "积石山保安族东乡族撒拉族自治县"}, {
            "value": "988360_临夏回族自治州",
            "label": "临夏回族自治州"
        }, {"value": "8362_临夏县", "label": "临夏县"}, {"value": "8366_和政县", "label": "和政县"}]
    }, {
        "value": "8380_甘南藏族自治州",
        "label": "甘南藏族自治州",
        "children": [{"value": "8381_临潭县", "label": "临潭县"}, {"value": "8382_卓尼县", "label": "卓尼县"}, {
            "value": "8383_舟曲县",
            "label": "舟曲县"
        }, {"value": "8384_迭部县", "label": "迭部县"}, {"value": "8385_玛曲县", "label": "玛曲县"}, {
            "value": "8386_碌曲县",
            "label": "碌曲县"
        }, {"value": "8387_夏河县", "label": "夏河县"}, {"value": "8388_合作市", "label": "合作市"}, {
            "value": "988380_甘南藏族自治州",
            "label": "甘南藏族自治州"
        }]
    }, {
        "value": "8230_金昌市",
        "label": "金昌市",
        "children": [{"value": "8231_永昌县", "label": "永昌县"}, {"value": "988230_金昌市", "label": "金昌市"}]
    }, {
        "value": "8260_酒泉市",
        "label": "酒泉市",
        "children": [{"value": "8265_肃北蒙古族自治县", "label": "肃北蒙古族自治县"}, {
            "value": "8261_肃州区",
            "label": "肃州区"
        }, {"value": "8261_玉门市", "label": "玉门市"}, {"value": "8263_敦煌市", "label": "敦煌市"}, {
            "value": "8264_金塔县",
            "label": "金塔县"
        }, {"value": "8266_阿克塞哈萨克族自治县", "label": "阿克塞哈萨克族自治县"}, {
            "value": "8267_瓜州县",
            "label": "瓜州县"
        }, {"value": "988260_酒泉市", "label": "酒泉市"}]
    }]
}, {
    "value": "8500_青海省",
    "label": "青海省",
    "children": [{
        "value": "8510_西宁市",
        "label": "西宁市",
        "children": [{"value": "8511_大通回族土族自治县", "label": "大通回族土族自治县"}, {
            "value": "8512_湟中县",
            "label": "湟中县"
        }, {"value": "8513_湟源县", "label": "湟源县"}, {"value": "988510_西宁市", "label": "西宁市"}]
    }, {
        "value": "8520_海东地区",
        "label": "海东地区",
        "children": [{"value": "8521_平安县", "label": "平安县"}, {
            "value": "8522_民和回族土族自治县",
            "label": "民和回族土族自治县"
        }, {"value": "8523_乐都县", "label": "乐都县"}, {
            "value": "8526_互助土族自治县",
            "label": "互助土族自治县"
        }, {"value": "8527_化隆回族自治县", "label": "化隆回族自治县"}, {
            "value": "8528_循化撒拉族自治县",
            "label": "循化撒拉族自治县"
        }, {"value": "988520_海东地区", "label": "海东地区"}]
    }, {
        "value": "8540_海北藏族自治州",
        "label": "海北藏族自治州",
        "children": [{"value": "8541_门源回族自治县", "label": "门源回族自治县"}, {
            "value": "8542_祁连县",
            "label": "祁连县"
        }, {"value": "8543_海晏县", "label": "海晏县"}, {"value": "8544_刚察县", "label": "刚察县"}, {
            "value": "988540_海北藏族自治州",
            "label": "海北藏族自治州"
        }]
    }, {
        "value": "8550_黄南藏族自治州",
        "label": "黄南藏族自治州",
        "children": [{"value": "8551_同仁县", "label": "同仁县"}, {"value": "8552_尖扎县", "label": "尖扎县"}, {
            "value": "8553_泽库县",
            "label": "泽库县"
        }, {"value": "8554_河南蒙古族自治县", "label": "河南蒙古族自治县"}, {"value": "988550_黄南藏族自治州", "label": "黄南藏族自治州"}]
    }, {
        "value": "8560_海南藏族自治州",
        "label": "海南藏族自治州",
        "children": [{"value": "8561_共和县", "label": "共和县"}, {"value": "8562_同德县", "label": "同德县"}, {
            "value": "8563_贵德县",
            "label": "贵德县"
        }, {"value": "8564_兴海县", "label": "兴海县"}, {"value": "8565_贵南县", "label": "贵南县"}, {
            "value": "988560_海南藏族自治州",
            "label": "海南藏族自治州"
        }]
    }, {
        "value": "8570_果洛藏族自治州",
        "label": "果洛藏族自治州",
        "children": [{"value": "8571_玛沁县", "label": "玛沁县"}, {"value": "8572_班玛县", "label": "班玛县"}, {
            "value": "8573_甘德县",
            "label": "甘德县"
        }, {"value": "8574_达日县", "label": "达日县"}, {"value": "8575_久治县", "label": "久治县"}, {
            "value": "8576_玛多县",
            "label": "玛多县"
        }, {"value": "988570_果洛藏族自治州", "label": "果洛藏族自治州"}]
    }, {
        "value": "8580_玉树藏族自治州",
        "label": "玉树藏族自治州",
        "children": [{"value": "8581_玉树县", "label": "玉树县"}, {"value": "8582_杂多县", "label": "杂多县"}, {
            "value": "8583_称多县",
            "label": "称多县"
        }, {"value": "8584_治多县", "label": "治多县"}, {"value": "8585_囊谦县", "label": "囊谦县"}, {
            "value": "8586_曲麻莱县",
            "label": "曲麻莱县"
        }, {"value": "988580_玉树藏族自治州", "label": "玉树藏族自治州"}]
    }, {
        "value": "8590_海西蒙古族藏族自治州",
        "label": "海西蒙古族藏族自治州",
        "children": [{"value": "8591_格尔木市", "label": "格尔木市"}, {
            "value": "8592_德令哈市",
            "label": "德令哈市"
        }, {"value": "8593_乌兰县", "label": "乌兰县"}, {"value": "8594_都兰县", "label": "都兰县"}, {
            "value": "8595_天峻县",
            "label": "天峻县"
        }, {"value": "988590_海西蒙古族藏族自治州", "label": "海西蒙古族藏族自治州"}]
    }]
}, {
    "value": "8800_新疆自治区",
    "label": "新疆自治区",
    "children": [{
        "value": "8810_乌鲁木齐市",
        "label": "乌鲁木齐市",
        "children": [{"value": "8811_乌鲁木齐县", "label": "乌鲁木齐县"}, {
            "value": "988810_乌鲁木齐市",
            "label": "乌鲁木齐市"
        }, {"value": "8811_水磨沟区", "label": "水磨沟区"}, {"value": "8811_沙依巴克区", "label": "沙依巴克区"}, {
            "value": "8811_新市区",
            "label": "新市区"
        }]
    }, {
        "value": "8820_克拉玛依市",
        "label": "克拉玛依市",
        "children": [{"value": "988820_克拉玛依市", "label": "克拉玛依市"}]
    }, {
        "value": "8830_吐鲁番地区",
        "label": "吐鲁番地区",
        "children": [{"value": "8831_吐鲁番市", "label": "吐鲁番市"}, {
            "value": "8832_鄯善县",
            "label": "鄯善县"
        }, {"value": "8833_托克逊县", "label": "托克逊县"}, {"value": "988830_吐鲁番地区", "label": "吐鲁番地区"}]
    }, {
        "value": "8840_哈密地区",
        "label": "哈密地区",
        "children": [{"value": "8841_哈密市", "label": "哈密市"}, {
            "value": "8842_巴里坤哈萨克自治县",
            "label": "巴里坤哈萨克自治县"
        }, {"value": "8843_伊吾县", "label": "伊吾县"}, {"value": "988840_哈密地区", "label": "哈密地区"}]
    }, {
        "value": "8850_昌吉回族自治州",
        "label": "昌吉回族自治州",
        "children": [{"value": "8851_昌吉市", "label": "昌吉市"}, {
            "value": "8853_呼图壁县",
            "label": "呼图壁县"
        }, {"value": "8854_玛纳斯县", "label": "玛纳斯县"}, {"value": "8855_奇台县", "label": "奇台县"}, {
            "value": "8856_阜康市",
            "label": "阜康市"
        }, {"value": "8857_吉木萨尔县", "label": "吉木萨尔县"}, {
            "value": "8858_木垒哈萨克自治县",
            "label": "木垒哈萨克自治县"
        }, {"value": "988850_昌吉回族自治州", "label": "昌吉回族自治州"}]
    }, {
        "value": "8870_博尔塔拉蒙古族自治州",
        "label": "博尔塔拉蒙古族自治州",
        "children": [{"value": "8871_博乐市", "label": "博乐市"}, {"value": "8872_精河县", "label": "精河县"}, {
            "value": "8873_温泉县",
            "label": "温泉县"
        }, {"value": "988870_博尔塔拉蒙古族自治州", "label": "博尔塔拉蒙古族自治州"}]
    }, {
        "value": "8880_巴音郭楞蒙古族自治州",
        "label": "巴音郭楞蒙古族自治州",
        "children": [{"value": "8881_库尔勒市", "label": "库尔勒市"}, {
            "value": "8882_轮台县",
            "label": "轮台县"
        }, {"value": "8883_尉犁县", "label": "尉犁县"}, {"value": "8884_若羌县", "label": "若羌县"}, {
            "value": "8885_且末县",
            "label": "且末县"
        }, {"value": "8886_焉耆回族自治县", "label": "焉耆回族自治县"}, {"value": "8887_和静县", "label": "和静县"}, {
            "value": "8888_和硕县",
            "label": "和硕县"
        }, {"value": "8889_博湖县", "label": "博湖县"}, {"value": "988880_巴音郭楞蒙古族自治州", "label": "巴音郭楞蒙古族自治州"}]
    }, {
        "value": "8910_阿克苏地区",
        "label": "阿克苏地区",
        "children": [{"value": "8911_阿克苏市", "label": "阿克苏市"}, {
            "value": "8912_温宿县",
            "label": "温宿县"
        }, {"value": "8913_库车县", "label": "库车县"}, {"value": "8914_沙雅县", "label": "沙雅县"}, {
            "value": "8915_新和县",
            "label": "新和县"
        }, {"value": "8916_拜城县", "label": "拜城县"}, {"value": "8917_乌什县", "label": "乌什县"}, {
            "value": "8918_阿瓦提县",
            "label": "阿瓦提县"
        }, {"value": "8919_柯坪县", "label": "柯坪县"}, {"value": "988910_阿克苏地区", "label": "阿克苏地区"}]
    }, {
        "value": "8930_克孜勒苏柯尔克孜自治州",
        "label": "克孜勒苏柯尔克孜自治州",
        "children": [{"value": "8931_阿图什市", "label": "阿图什市"}, {
            "value": "8932_阿克陶县",
            "label": "阿克陶县"
        }, {"value": "8933_阿合奇县", "label": "阿合奇县"}, {
            "value": "8934_乌恰县",
            "label": "乌恰县"
        }, {"value": "988930_克孜勒苏柯尔克孜自治州", "label": "克孜勒苏柯尔克孜自治州"}]
    }, {
        "value": "8940_喀什地区",
        "label": "喀什地区",
        "children": [{"value": "8941_喀什市", "label": "喀什市"}, {"value": "8942_疏附县", "label": "疏附县"}, {
            "value": "8943_疏勒县",
            "label": "疏勒县"
        }, {"value": "8944_英吉沙县", "label": "英吉沙县"}, {"value": "8945_泽普县", "label": "泽普县"}, {
            "value": "8946_莎车县",
            "label": "莎车县"
        }, {"value": "8947_叶城县", "label": "叶城县"}, {"value": "8948_麦盖提县", "label": "麦盖提县"}, {
            "value": "8949_岳普湖县",
            "label": "岳普湖县"
        }, {"value": "8952_巴楚县", "label": "巴楚县"}, {
            "value": "8953_塔什库尔干塔吉克自治县",
            "label": "塔什库尔干塔吉克自治县"
        }, {"value": "988940_喀什地区", "label": "喀什地区"}, {"value": "8951_伽师县", "label": "伽师县"}]
    }, {
        "value": "8960_和田地区",
        "label": "和田地区",
        "children": [{"value": "8962_和田县", "label": "和田县"}, {"value": "8963_墨玉县", "label": "墨玉县"}, {
            "value": "8964_皮山县",
            "label": "皮山县"
        }, {"value": "8966_策勒县", "label": "策勒县"}, {"value": "8967_于田县", "label": "于田县"}, {
            "value": "8968_民丰县",
            "label": "民丰县"
        }, {"value": "988960_和田地区", "label": "和田地区"}, {"value": "8961_和田市", "label": "和田市"}, {
            "value": "8965_洛浦县",
            "label": "洛浦县"
        }]
    }, {
        "value": "9010_塔城地区",
        "label": "塔城地区",
        "children": [{"value": "9011_塔城市", "label": "塔城市"}, {"value": "9012_额敏县", "label": "额敏县"}, {
            "value": "9014_沙湾县",
            "label": "沙湾县"
        }, {"value": "9015_托里县", "label": "托里县"}, {"value": "9016_裕民县", "label": "裕民县"}, {
            "value": "989010_塔城地区",
            "label": "塔城地区"
        }, {"value": "9013_乌苏市", "label": "乌苏市"}, {"value": "9017_和布克赛尔蒙古自治县", "label": "和布克赛尔蒙古自治县"}]
    }, {
        "value": "9020_阿勒泰地区",
        "label": "阿勒泰地区",
        "children": [{"value": "9021_阿勒泰市", "label": "阿勒泰市"}, {
            "value": "9022_布尔津县",
            "label": "布尔津县"
        }, {"value": "9023_富蕴县", "label": "富蕴县"}, {"value": "9024_福海县", "label": "福海县"}, {
            "value": "9025_哈巴河县",
            "label": "哈巴河县"
        }, {"value": "9027_吉木乃县", "label": "吉木乃县"}, {
            "value": "9028_石河子市（自治区直辖）",
            "label": "石河子市（自治区直辖）"
        }, {"value": "9041_阿拉尔市（自治区直辖）", "label": "阿拉尔市（自治区直辖）"}, {
            "value": "9043_五家渠市（自治区直辖）",
            "label": "五家渠市（自治区直辖）"
        }, {"value": "989020_阿勒泰地区", "label": "阿勒泰地区"}, {
            "value": "9026_青河县",
            "label": "青河县"
        }, {"value": "9042_图木舒克市（自治区直辖）", "label": "图木舒克市（自治区直辖）"}]
    }, {
        "value": "8980_伊犁哈萨克自治州",
        "label": "伊犁哈萨克自治州",
        "children": [{"value": "8995_巩留县", "label": "巩留县"}, {
            "value": "8999_尼勒克县",
            "label": "尼勒克县"
        }, {"value": "8981_奎屯市", "label": "奎屯市"}, {"value": "8991_伊宁市", "label": "伊宁市"}, {
            "value": "8992_伊宁县",
            "label": "伊宁县"
        }, {"value": "8993_察布查尔锡伯自治县", "label": "察布查尔锡伯自治县"}, {
            "value": "8994_霍城县",
            "label": "霍城县"
        }, {"value": "8996_新源县", "label": "新源县"}, {"value": "8997_昭苏县", "label": "昭苏县"}, {
            "value": "8998_特克斯县",
            "label": "特克斯县"
        }, {"value": "988980_伊犁哈萨克自治州", "label": "伊犁哈萨克自治州"}]
    }]
}, {
    "value": "991000_北京",
    "label": "北京",
    "children": [{
        "value": "1000_北京市",
        "label": "北京市",
        "children": [{"value": "1027_密云县", "label": "密云县"}, {
            "value": "1028_延庆县",
            "label": "延庆县"
        }, {"value": "100001_东城区", "label": "东城区"}, {"value": "100002_西城区", "label": "西城区"}, {
            "value": "100003_崇文区",
            "label": "崇文区"
        }, {"value": "100004_宣武区", "label": "宣武区"}, {"value": "100005_朝阳区", "label": "朝阳区"}, {
            "value": "100007_石景山区",
            "label": "石景山区"
        }, {"value": "100008_海淀区", "label": "海淀区"}, {"value": "100009_门头沟区", "label": "门头沟区"}, {
            "value": "100011_通州区",
            "label": "通州区"
        }, {"value": "100012_顺义区", "label": "顺义区"}, {"value": "100013_昌平区", "label": "昌平区"}, {
            "value": "100015_怀柔区",
            "label": "怀柔区"
        }, {"value": "100016_平谷区", "label": "平谷区"}, {"value": "100006_丰台区", "label": "丰台区"}, {
            "value": "100010_房山区",
            "label": "房山区"
        }, {"value": "100014_大兴区", "label": "大兴区"}]
    }]
}, {
    "value": "991100_天津",
    "label": "天津",
    "children": [{
        "value": "1100_天津市",
        "label": "天津市",
        "children": [{"value": "1121_宁河县", "label": "宁河县"}, {"value": "1123_静海县", "label": "静海县"}, {
            "value": "1125_蓟县",
            "label": "蓟县"
        }, {"value": "110001_和平区", "label": "和平区"}, {"value": "110003_河西区", "label": "河西区"}, {
            "value": "110004_南开区",
            "label": "南开区"
        }, {"value": "110005_河北区", "label": "河北区"}, {"value": "110007_塘沽区", "label": "塘沽区"}, {
            "value": "110008_汉沽区",
            "label": "汉沽区"
        }, {"value": "110009_大港区", "label": "大港区"}, {"value": "110011_西青区", "label": "西青区"}, {
            "value": "110012_津南区",
            "label": "津南区"
        }, {"value": "110013_北辰区", "label": "北辰区"}, {"value": "110015_宝坻区", "label": "宝坻区"}, {
            "value": "110002_河东区",
            "label": "河东区"
        }, {"value": "110006_红桥区", "label": "红桥区"}, {"value": "110010_东丽区", "label": "东丽区"}, {
            "value": "110014_武清区",
            "label": "武清区"
        }]
    }]
}, {
    "value": "992900_上海",
    "label": "上海",
    "children": [{
        "value": "2900_上海市",
        "label": "上海市",
        "children": [{"value": "2929_崇明县", "label": "崇明县"}, {
            "value": "290001_黄浦区",
            "label": "黄浦区"
        }, {"value": "290002_徐汇区", "label": "徐汇区"}, {"value": "290004_静安区", "label": "静安区"}, {
            "value": "290005_普陀区",
            "label": "普陀区"
        }, {"value": "290006_闸北区", "label": "闸北区"}, {"value": "290008_杨浦区", "label": "杨浦区"}, {
            "value": "290009_闵行区",
            "label": "闵行区"
        }, {"value": "290010_宝山区", "label": "宝山区"}, {"value": "290012_浦东新区", "label": "浦东新区"}, {
            "value": "290013_金山区",
            "label": "金山区"
        }, {"value": "290014_松江区", "label": "松江区"}, {"value": "290016_南汇区", "label": "南汇区"}, {
            "value": "290017_奉贤区",
            "label": "奉贤区"
        }, {"value": "290003_长宁区", "label": "长宁区"}, {"value": "290007_虹口区", "label": "虹口区"}, {
            "value": "290011_嘉定区",
            "label": "嘉定区"
        }, {"value": "290015_青浦区", "label": "青浦区"}]
    }]
}, {
    "value": "996900_重庆", "label": "重庆", "children": [{
        "value": "6900_重庆市",
        "label": "重庆市",
        "children": [{"value": "6914_潼南县", "label": "潼南县"}, {"value": "6915_铜梁县", "label": "铜梁县"}, {
            "value": "6917_大足县",
            "label": "大足县"
        }, {"value": "6919_璧山县", "label": "璧山县"}, {"value": "6921_梁平县", "label": "梁平县"}, {
            "value": "6922_城口县",
            "label": "城口县"
        }, {"value": "6924_垫江县", "label": "垫江县"}, {"value": "6925_武隆县", "label": "武隆县"}, {
            "value": "6926_忠县",
            "label": "忠县"
        }, {"value": "6927_开县", "label": "开县"}, {"value": "6928_云阳县", "label": "云阳县"}, {
            "value": "6929_奉节县",
            "label": "奉节县"
        }, {"value": "6931_巫山县", "label": "巫山县"}, {"value": "6932_巫溪县", "label": "巫溪县"}, {
            "value": "6933_石柱土家族自治县",
            "label": "石柱土家族自治县"
        }, {"value": "6934_秀山土家族苗族自治县", "label": "秀山土家族苗族自治县"}, {
            "value": "6935_酉阳土家族苗族自治县",
            "label": "酉阳土家族苗族自治县"
        }, {"value": "6936_彭水苗族土家族自治县", "label": "彭水苗族土家族自治县"}, {
            "value": "690002_涪陵区",
            "label": "涪陵区"
        }, {"value": "690003_渝中区", "label": "渝中区"}, {"value": "690004_大渡口区", "label": "大渡口区"}, {
            "value": "690006_沙坪坝区",
            "label": "沙坪坝区"
        }, {"value": "690007_九龙坡区", "label": "九龙坡区"}, {"value": "690008_南岸区", "label": "南岸区"}, {
            "value": "690009_北碚区",
            "label": "北碚区"
        }, {"value": "690010_万盛区", "label": "万盛区"}, {"value": "690011_双桥区", "label": "双桥区"}, {
            "value": "690012_渝北区",
            "label": "渝北区"
        }, {"value": "690013_巴南区", "label": "巴南区"}, {"value": "690014_黔江区", "label": "黔江区"}, {
            "value": "690015_长寿区",
            "label": "长寿区"
        }, {"value": "690016_江津区", "label": "江津区"}, {"value": "690017_合川区", "label": "合川区"}, {
            "value": "690018_永川区",
            "label": "永川区"
        }, {"value": "690019_南川区", "label": "南川区"}, {"value": "6911_綦江县", "label": "綦江县"}, {
            "value": "6918_荣昌县",
            "label": "荣昌县"
        }, {"value": "6923_丰都县", "label": "丰都县"}, {"value": "690001_万州区", "label": "万州区"}, {
            "value": "690005_江北区",
            "label": "江北区"
        }]
    }]
}, {
    "value": "5500_湖南省",
    "label": "湖南省",
    "children": [{
        "value": "5510_长沙市",
        "label": "长沙市",
        "children": [{"value": "5511_长沙县", "label": "长沙县"}, {"value": "5512_望城县", "label": "望城县"}, {
            "value": "5513_浏阳市",
            "label": "浏阳市"
        }, {"value": "5514_宁乡县", "label": "宁乡县"}, {"value": "5512_岳麓区", "label": "岳麓区"}, {
            "value": "5513_雨花区",
            "label": "雨花区"
        }, {"value": "985510_长沙市", "label": "长沙市"}]
    }, {
        "value": "5520_株洲市",
        "label": "株洲市",
        "children": [{"value": "5521_株洲县", "label": "株洲县"}, {"value": "5522_攸县", "label": "攸县"}, {
            "value": "5523_茶陵县",
            "label": "茶陵县"
        }, {"value": "5524_炎陵县", "label": "炎陵县"}, {"value": "5525_醴陵市", "label": "醴陵市"}, {
            "value": "5521_天元区",
            "label": "天元区"
        }, {"value": "985520_株洲市", "label": "株洲市"}]
    }, {
        "value": "5530_湘潭市",
        "label": "湘潭市",
        "children": [{"value": "5531_湘潭县", "label": "湘潭县"}, {"value": "5532_湘乡市", "label": "湘乡市"}, {
            "value": "5533_韶山市",
            "label": "韶山市"
        }, {"value": "985530_湘潭市", "label": "湘潭市"}, {"value": "5531_岳塘区", "label": "岳塘区"}]
    }, {
        "value": "5540_衡阳市",
        "label": "衡阳市",
        "children": [{"value": "5541_衡阳县", "label": "衡阳县"}, {"value": "5542_衡南县", "label": "衡南县"}, {
            "value": "5543_衡山县",
            "label": "衡山县"
        }, {"value": "5544_衡东县", "label": "衡东县"}, {"value": "5545_常宁市", "label": "常宁市"}, {
            "value": "5546_祁东县",
            "label": "祁东县"
        }, {"value": "5547_耒阳市", "label": "耒阳市"}, {"value": "5542_雁峰区", "label": "雁峰区"}, {
            "value": "985540_衡阳市",
            "label": "衡阳市"
        }]
    }, {
        "value": "5550_邵阳市",
        "label": "邵阳市",
        "children": [{"value": "5551_邵东县", "label": "邵东县"}, {"value": "5552_新邵县", "label": "新邵县"}, {
            "value": "5553_邵阳县",
            "label": "邵阳县"
        }, {"value": "5554_隆回县", "label": "隆回县"}, {"value": "5555_洞口县", "label": "洞口县"}, {
            "value": "5556_武冈市",
            "label": "武冈市"
        }, {"value": "5557_绥宁县", "label": "绥宁县"}, {"value": "5558_新宁县", "label": "新宁县"}, {
            "value": "5559_城步苗族自治县",
            "label": "城步苗族自治县"
        }, {"value": "985550_邵阳市", "label": "邵阳市"}]
    }, {
        "value": "5570_岳阳市",
        "label": "岳阳市",
        "children": [{"value": "5571_岳阳县", "label": "岳阳县"}, {"value": "5572_临湘市", "label": "临湘市"}, {
            "value": "5573_华容县",
            "label": "华容县"
        }, {"value": "5574_湘阴县", "label": "湘阴县"}, {"value": "5575_平江县", "label": "平江县"}, {
            "value": "5576_汨罗市",
            "label": "汨罗市"
        }, {"value": "985570_岳阳市", "label": "岳阳市"}]
    }, {
        "value": "5580_常德市",
        "label": "常德市",
        "children": [{"value": "5581_安乡县", "label": "安乡县"}, {"value": "5582_汉寿县", "label": "汉寿县"}, {
            "value": "5583_澧县",
            "label": "澧县"
        }, {"value": "5584_临澧县", "label": "临澧县"}, {"value": "5585_桃源县", "label": "桃源县"}, {
            "value": "5586_石门县",
            "label": "石门县"
        }, {"value": "5587_津市市", "label": "津市市"}, {"value": "985580_常德市", "label": "常德市"}, {
            "value": "5581_武陵区",
            "label": "武陵区"
        }]
    }, {
        "value": "5590_张家界市",
        "label": "张家界市",
        "children": [{"value": "5591_慈利县", "label": "慈利县"}, {
            "value": "5592_桑植县",
            "label": "桑植县"
        }, {"value": "985590_张家界市", "label": "张家界市"}]
    }, {
        "value": "5610_益阳市",
        "label": "益阳市",
        "children": [{"value": "5612_沅江市", "label": "沅江市"}, {"value": "5614_南县", "label": "南县"}, {
            "value": "5615_桃江县",
            "label": "桃江县"
        }, {"value": "5616_安化县", "label": "安化县"}, {"value": "985610_益阳市", "label": "益阳市"}]
    }, {
        "value": "5620_娄底市",
        "label": "娄底市",
        "children": [{"value": "5622_冷水江市", "label": "冷水江市"}, {
            "value": "5623_涟源市",
            "label": "涟源市"
        }, {"value": "5624_双峰县", "label": "双峰县"}, {"value": "5625_新化县", "label": "新化县"}, {
            "value": "5625_娄星区",
            "label": "娄星区"
        }, {"value": "985620_娄底市", "label": "娄底市"}]
    }, {
        "value": "5630_郴州市",
        "label": "郴州市",
        "children": [{"value": "5632_资兴市", "label": "资兴市"}, {"value": "5634_桂阳县", "label": "桂阳县"}, {
            "value": "5635_永兴县",
            "label": "永兴县"
        }, {"value": "5636_宜章县", "label": "宜章县"}, {"value": "5637_嘉禾县", "label": "嘉禾县"}, {
            "value": "5638_临武县",
            "label": "临武县"
        }, {"value": "5639_汝城县", "label": "汝城县"}, {"value": "5641_桂东县", "label": "桂东县"}, {
            "value": "5642_安仁县",
            "label": "安仁县"
        }, {"value": "985630_郴州市", "label": "郴州市"}, {"value": "5634_北湖区", "label": "北湖区"}]
    }, {
        "value": "5650_永州市",
        "label": "永州市",
        "children": [{"value": "5653_东安县", "label": "东安县"}, {"value": "5654_道县", "label": "道县"}, {
            "value": "5655_宁远县",
            "label": "宁远县"
        }, {"value": "5656_江永县", "label": "江永县"}, {"value": "5657_江华瑶族自治县", "label": "江华瑶族自治县"}, {
            "value": "5658_蓝山县",
            "label": "蓝山县"
        }, {"value": "5659_新田县", "label": "新田县"}, {"value": "5661_双牌县", "label": "双牌县"}, {
            "value": "5662_祁阳县",
            "label": "祁阳县"
        }, {"value": "985650_永州市", "label": "永州市"}]
    }, {
        "value": "5670_怀化市",
        "label": "怀化市",
        "children": [{"value": "5672_洪江市", "label": "洪江市"}, {"value": "5674_沅陵县", "label": "沅陵县"}, {
            "value": "5675_辰溪县",
            "label": "辰溪县"
        }, {"value": "5676_溆浦县", "label": "溆浦县"}, {
            "value": "5677_麻阳苗族自治县",
            "label": "麻阳苗族自治县"
        }, {"value": "5678_新晃侗族自治县", "label": "新晃侗族自治县"}, {
            "value": "5679_芷江侗族自治县",
            "label": "芷江侗族自治县"
        }, {"value": "5681_会同县", "label": "会同县"}, {
            "value": "5682_靖州苗族侗族自治县",
            "label": "靖州苗族侗族自治县"
        }, {"value": "5683_通道侗族自治县", "label": "通道侗族自治县"}, {"value": "5684_中方县", "label": "中方县"}, {
            "value": "985670_怀化市",
            "label": "怀化市"
        }]
    }, {
        "value": "5690_湘西土家族苗族自治州",
        "label": "湘西土家族苗族自治州",
        "children": [{"value": "5691_吉首市", "label": "吉首市"}, {"value": "5692_泸溪县", "label": "泸溪县"}, {
            "value": "5693_凤凰县",
            "label": "凤凰县"
        }, {"value": "5694_花垣县", "label": "花垣县"}, {"value": "5695_保靖县", "label": "保靖县"}, {
            "value": "5696_古丈县",
            "label": "古丈县"
        }, {"value": "5697_永顺县", "label": "永顺县"}, {"value": "5698_龙山县", "label": "龙山县"}, {
            "value": "985690_湘西土家族苗族自治州",
            "label": "湘西土家族苗族自治州"
        }]
    }]
}, {
    "value": "5800_广东省",
    "label": "广东省",
    "children": [{
        "value": "5810_广州市",
        "label": "广州市",
        "children": [{"value": "5812_从化市", "label": "从化市"}, {"value": "5813_增城市", "label": "增城市"}, {
            "value": "5813_荔湾区",
            "label": "荔湾区"
        }, {"value": "5813_番禺区", "label": "番禺区"}, {"value": "5813_番禺区", "label": "番禺区"}, {
            "value": "5813_白云区",
            "label": "白云区"
        }, {"value": "5813_海珠区", "label": "海珠区"}, {"value": "5813_越秀区", "label": "越秀区"}, {
            "value": "5813_萝岗区",
            "label": "萝岗区"
        }, {"value": "5813_天河区", "label": "天河区"}, {"value": "5813_花都区", "label": "花都区"}, {
            "value": "985810_广州市",
            "label": "广州市"
        }]
    }, {
        "value": "5820_韶关市",
        "label": "韶关市",
        "children": [{"value": "5822_始兴县", "label": "始兴县"}, {"value": "5823_南雄市", "label": "南雄市"}, {
            "value": "5824_仁化县",
            "label": "仁化县"
        }, {"value": "5825_乐昌市", "label": "乐昌市"}, {"value": "5826_翁源县", "label": "翁源县"}, {
            "value": "5827_乳源瑶族自治县",
            "label": "乳源瑶族自治县"
        }, {"value": "5828_新丰县", "label": "新丰县"}, {"value": "985820_韶关市", "label": "韶关市"}]
    }, {
        "value": "5840_深圳市",
        "label": "深圳市",
        "children": [{"value": "985840_深圳市", "label": "深圳市"}]
    }, {
        "value": "5850_珠海市",
        "label": "珠海市",
        "children": [{"value": "985850_珠海市", "label": "珠海市"}]
    }, {
        "value": "5860_汕头市",
        "label": "汕头市",
        "children": [{"value": "5863_南澳县", "label": "南澳县"}, {"value": "985860_汕头市", "label": "汕头市"}]
    }, {
        "value": "5880_佛山市",
        "label": "佛山市",
        "children": [{"value": "5881_三水区", "label": "三水区"}, {
            "value": "5882_  南海区",
            "label": "  南海区"
        }, {"value": "5883_  顺德区", "label": "  顺德区"}, {"value": "985880_佛山市", "label": "佛山市"}, {
            "value": "5883_宝安区",
            "label": "宝安区"
        }, {"value": "5883_福田区", "label": "福田区"}, {"value": "5883_龙岗区", "label": "龙岗区"}, {
            "value": "5883_福田区",
            "label": "福田区"
        }, {"value": "5882_南山区", "label": "南山区"}, {"value": "5882_顺德区", "label": "顺德区"}, {
            "value": "5882_罗湖区",
            "label": "罗湖区"
        }]
    }, {
        "value": "5890_江门市",
        "label": "江门市",
        "children": [{"value": "5892_台山市", "label": "台山市"}, {"value": "5893_恩平市", "label": "恩平市"}, {
            "value": "5894_开平市",
            "label": "开平市"
        }, {"value": "5895_鹤山市", "label": "鹤山市"}, {"value": "985890_江门市", "label": "江门市"}]
    }, {
        "value": "5910_湛江市",
        "label": "湛江市",
        "children": [{"value": "5911_吴川市", "label": "吴川市"}, {"value": "5912_廉江市", "label": "廉江市"}, {
            "value": "5914_雷州市",
            "label": "雷州市"
        }, {"value": "5915_徐闻县", "label": "徐闻县"}, {"value": "5913_遂溪县", "label": "遂溪县"}, {
            "value": "985910_湛江市",
            "label": "湛江市"
        }]
    }, {
        "value": "5920_茂名市",
        "label": "茂名市",
        "children": [{"value": "5922_高州市", "label": "高州市"}, {"value": "5923_电白县", "label": "电白县"}, {
            "value": "5924_化州市",
            "label": "化州市"
        }, {"value": "5921_信宜市", "label": "信宜市"}, {"value": "985920_茂名市", "label": "茂名市"}]
    }, {
        "value": "5950_惠州市",
        "label": "惠州市",
        "children": [{"value": "5953_惠东县", "label": "惠东县"}, {"value": "5954_龙门县", "label": "龙门县"}, {
            "value": "5952_博罗县",
            "label": "博罗县"
        }, {"value": "5954_惠阳区", "label": "惠阳区"}, {"value": "985950_惠州市", "label": "惠州市"}]
    }, {
        "value": "5960_梅州市",
        "label": "梅州市",
        "children": [{"value": "5962_大埔县", "label": "大埔县"}, {"value": "5963_丰顺县", "label": "丰顺县"}, {
            "value": "5964_五华县",
            "label": "五华县"
        }, {"value": "5966_平远县", "label": "平远县"}, {"value": "5967_蕉岭县", "label": "蕉岭县"}, {
            "value": "5961_梅县",
            "label": "梅县"
        }, {"value": "5965_兴宁市", "label": "兴宁市"}, {"value": "985960_梅州市", "label": "梅州市"}]
    }, {
        "value": "5970_汕尾市",
        "label": "汕尾市",
        "children": [{"value": "5972_陆丰市", "label": "陆丰市"}, {"value": "5973_陆河县", "label": "陆河县"}, {
            "value": "5971_海丰县",
            "label": "海丰县"
        }, {"value": "985970_汕尾市", "label": "汕尾市"}]
    }, {
        "value": "5980_河源市",
        "label": "河源市",
        "children": [{"value": "5982_龙川县", "label": "龙川县"}, {"value": "5983_连平县", "label": "连平县"}, {
            "value": "5984_和平县",
            "label": "和平县"
        }, {"value": "5981_紫金县", "label": "紫金县"}, {"value": "5985_东源县", "label": "东源县"}, {
            "value": "985980_河源市",
            "label": "河源市"
        }]
    }, {
        "value": "5990_阳江市",
        "label": "阳江市",
        "children": [{"value": "5991_阳西县", "label": "阳西县"}, {
            "value": "5992_阳春市",
            "label": "阳春市"
        }, {"value": "985990_阳江市", "label": "阳江市"}, {"value": "5993_阳东县", "label": "阳东县"}]
    }, {
        "value": "6010_清远市",
        "label": "清远市",
        "children": [{"value": "6011_佛冈县", "label": "佛冈县"}, {"value": "6012_英德市", "label": "英德市"}, {
            "value": "6014_连州市",
            "label": "连州市"
        }, {"value": "6015_连山壮族瑶族自治县", "label": "连山壮族瑶族自治县"}, {
            "value": "6016_连南瑶族自治县",
            "label": "连南瑶族自治县"
        }, {"value": "6013_阳山县", "label": "阳山县"}, {"value": "6017_清新县", "label": "清新县"}, {
            "value": "986010_清远市",
            "label": "清远市"
        }]
    }, {
        "value": "6020_东莞市",
        "label": "东莞市",
        "children": [{"value": "986020_东莞市", "label": "东莞市"}, {"value": "986021_虎门", "label": "虎门"}]
    }, {
        "value": "6030_中山市",
        "label": "中山市",
        "children": [{"value": "986030_中山市", "label": "中山市"}]
    }, {
        "value": "6040_潮州市",
        "label": "潮州市",
        "children": [{"value": "6042_饶平县", "label": "饶平县"}, {
            "value": "6041_潮安县",
            "label": "潮安县"
        }, {"value": "986040_潮州市", "label": "潮州市"}]
    }, {
        "value": "6050_揭阳市",
        "label": "揭阳市",
        "children": [{"value": "6051_揭东县", "label": "揭东县"}, {"value": "6053_惠来县", "label": "惠来县"}, {
            "value": "6054_普宁市",
            "label": "普宁市"
        }, {"value": "6052_揭西县", "label": "揭西县"}, {"value": "986050_揭阳市", "label": "揭阳市"}]
    }, {
        "value": "6060_云浮市",
        "label": "云浮市",
        "children": [{"value": "6062_郁南县", "label": "郁南县"}, {"value": "6063_云安县", "label": "云安县"}, {
            "value": "6064_罗定市",
            "label": "罗定市"
        }, {"value": "6061_新兴县", "label": "新兴县"}, {"value": "986060_云浮市", "label": "云浮市"}]
    }, {
        "value": "5930_肇庆市",
        "label": "肇庆市",
        "children": [{"value": "5934_怀集县", "label": "怀集县"}, {"value": "5931_高要市", "label": "高要市"}, {
            "value": "5932_四会市",
            "label": "四会市"
        }, {"value": "5933_广宁县", "label": "广宁县"}, {"value": "5935_封开县", "label": "封开县"}, {
            "value": "5936_德庆县",
            "label": "德庆县"
        }, {"value": "985930_肇庆市", "label": "肇庆市"}]
    }]
}, {
    "value": "8700_宁夏自治区",
    "label": "宁夏自治区",
    "children": [{
        "value": "8710_银川市",
        "label": "银川市",
        "children": [{"value": "8711_永宁县", "label": "永宁县"}, {"value": "8712_贺兰县", "label": "贺兰县"}, {
            "value": "8713_灵武市",
            "label": "灵武市"
        }, {"value": "988710_银川市", "label": "银川市"}, {"value": "8712_金凤区", "label": "金凤区"}]
    }, {
        "value": "8720_石嘴山市",
        "label": "石嘴山市",
        "children": [{"value": "8721_平罗县", "label": "平罗县"}, {"value": "988720_石嘴山市", "label": "石嘴山市"}]
    }, {
        "value": "8730_吴忠市",
        "label": "吴忠市",
        "children": [{"value": "8732_青铜峡市", "label": "青铜峡市"}, {
            "value": "8736_盐池县",
            "label": "盐池县"
        }, {"value": "8737_同心县", "label": "同心县"}, {"value": "988730_吴忠市", "label": "吴忠市"}]
    }, {
        "value": "8740_固原市",
        "label": "固原市",
        "children": [{"value": "8743_西吉县", "label": "西吉县"}, {"value": "8744_隆德县", "label": "隆德县"}, {
            "value": "8745_泾源县",
            "label": "泾源县"
        }, {"value": "8746_彭阳县", "label": "彭阳县"}, {"value": "988740_固原市", "label": "固原市"}]
    }, {
        "value": "8750_中卫市",
        "label": "中卫市",
        "children": [{"value": "8751_中宁县", "label": "中宁县"}, {
            "value": "8752_海原县",
            "label": "海原县"
        }, {"value": "988750_中卫市", "label": "中卫市"}]
    }]
}, {
    "value": "6100_广西自治区",
    "label": "广西自治区",
    "children": [{
        "value": "6170_桂林市",
        "label": "桂林市",
        "children": [{"value": "6182_全州县", "label": "全州县"}, {
            "value": "6186_龙胜各族自治县",
            "label": "龙胜各族自治县"
        }, {"value": "986170_桂林市", "label": "桂林市"}, {"value": "6171_阳朔县", "label": "阳朔县"}, {
            "value": "6172_临桂县",
            "label": "临桂县"
        }, {"value": "6181_灵川县", "label": "灵川县"}, {"value": "6183_兴安县", "label": "兴安县"}, {
            "value": "6184_永福县",
            "label": "永福县"
        }, {"value": "6185_灌阳县", "label": "灌阳县"}, {"value": "6187_资源县", "label": "资源县"}, {
            "value": "6188_平乐县",
            "label": "平乐县"
        }, {"value": "6189_荔蒲县", "label": "荔蒲县"}, {"value": "6191_恭城瑶族自治县", "label": "恭城瑶族自治县"}]
    }, {
        "value": "6210_梧州市",
        "label": "梧州市",
        "children": [{"value": "6224_蒙山县", "label": "蒙山县"}, {
            "value": "986210_梧州市",
            "label": "梧州市"
        }, {"value": "6211_苍梧县", "label": "苍梧县"}, {"value": "6221_岑溪市", "label": "岑溪市"}, {
            "value": "6222_藤县",
            "label": "藤县"
        }]
    }, {
        "value": "6280_河池市",
        "label": "河池市",
        "children": [{"value": "6282_宜州市", "label": "宜州市"}, {
            "value": "6283_罗城仫佬族自治县",
            "label": "罗城仫佬族自治县"
        }, {"value": "6284_环江毛南族自治县", "label": "环江毛南族自治县"}, {"value": "6285_南丹县", "label": "南丹县"}, {
            "value": "6286_天峨县",
            "label": "天峨县"
        }, {"value": "6287_凤山县", "label": "凤山县"}, {"value": "6288_东兰县", "label": "东兰县"}, {
            "value": "6289_巴马瑶族自治县",
            "label": "巴马瑶族自治县"
        }, {"value": "6291_都安瑶族自治县", "label": "都安瑶族自治县"}, {
            "value": "6292_大化瑶族自治县",
            "label": "大化瑶族自治县"
        }, {"value": "986280_河池市", "label": "河池市"}]
    }, {
        "value": "6310_钦州市",
        "label": "钦州市",
        "children": [{"value": "6314_灵山县", "label": "灵山县"}, {
            "value": "6315_浦北县",
            "label": "浦北县"
        }, {"value": "986310_钦州市", "label": "钦州市"}]
    }, {
        "value": "6320_防城港市",
        "label": "防城港市",
        "children": [{"value": "6321_上思县", "label": "上思县"}, {
            "value": "6322_东兴市",
            "label": "东兴市"
        }, {"value": "986320_防城港市", "label": "防城港市"}]
    }, {
        "value": "6330_贵港市",
        "label": "贵港市",
        "children": [{"value": "6331_平南县", "label": "平南县"}, {
            "value": "6332_桂平市",
            "label": "桂平市"
        }, {"value": "986330_贵港市", "label": "贵港市"}, {"value": "6332_港北区", "label": "港北区"}]
    }, {
        "value": "6340_贺州市",
        "label": "贺州市",
        "children": [{"value": "6341_昭平县", "label": "昭平县"}, {
            "value": "6342_钟山县",
            "label": "钟山县"
        }, {"value": "6343_富川瑶族自治县", "label": "富川瑶族自治县"}, {"value": "986340_贺州市", "label": "贺州市"}]
    }, {
        "value": "6350_来宾市",
        "label": "来宾市",
        "children": [{"value": "6351_忻城县", "label": "忻城县"}, {"value": "6352_象州县", "label": "象州县"}, {
            "value": "6353_武宣县",
            "label": "武宣县"
        }, {"value": "6354_金秀瑶族自治县", "label": "金秀瑶族自治县"}, {"value": "6355_合山市", "label": "合山市"}, {
            "value": "986350_来宾市",
            "label": "来宾市"
        }]
    }, {
        "value": "6360_崇左市",
        "label": "崇左市",
        "children": [{"value": "6361_扶绥县", "label": "扶绥县"}, {"value": "6362_宁明县", "label": "宁明县"}, {
            "value": "6363_龙州县",
            "label": "龙州县"
        }, {"value": "6364_大新县", "label": "大新县"}, {"value": "6365_天等县", "label": "天等县"}, {
            "value": "6366_凭祥市",
            "label": "凭祥市"
        }, {"value": "986360_崇左市", "label": "崇左市"}]
    }, {
        "value": "6110_南宁市",
        "label": "南宁市",
        "children": [{"value": "6112_武鸣县", "label": "武鸣县"}, {"value": "6123_宾阳县", "label": "宾阳县"}, {
            "value": "6124_上林县",
            "label": "上林县"
        }, {"value": "6125_隆安县", "label": "隆安县"}, {"value": "986110_南宁市", "label": "南宁市"}, {
            "value": "6122_横县",
            "label": "横县"
        }, {"value": "6126_马山县", "label": "马山县"}, {"value": "6125_良庆区", "label": "良庆区"}, {
            "value": "6125_邕宁区",
            "label": "邕宁区"
        }]
    }, {
        "value": "6140_柳州市",
        "label": "柳州市",
        "children": [{"value": "6141_柳江县", "label": "柳江县"}, {"value": "6142_柳城县", "label": "柳城县"}, {
            "value": "6156_融安县",
            "label": "融安县"
        }, {"value": "6157_三江侗族自治县", "label": "三江侗族自治县"}, {
            "value": "6158_融水苗族自治县",
            "label": "融水苗族自治县"
        }, {"value": "6152_鹿寨县", "label": "鹿寨县"}, {"value": "986140_柳州市", "label": "柳州市"}]
    }, {
        "value": "6230_北海市",
        "label": "北海市",
        "children": [{"value": "6231_合浦县", "label": "合浦县"}, {
            "value": "6231_海城区",
            "label": "海城区"
        }, {"value": "986230_北海市", "label": "北海市"}]
    }, {
        "value": "6240_玉林市",
        "label": "玉林市",
        "children": [{"value": "6246_北流市", "label": "北流市"}, {"value": "6247_陆川县", "label": "陆川县"}, {
            "value": "6248_博白县",
            "label": "博白县"
        }, {"value": "986240_玉林市", "label": "玉林市"}, {"value": "6245_容县", "label": "容县"}, {
            "value": "6249_兴业县",
            "label": "兴业县"
        }, {"value": "6247_玉州区", "label": "玉州区"}]
    }, {
        "value": "6260_百色市",
        "label": "百色市",
        "children": [{"value": "6262_田阳县", "label": "田阳县"}, {"value": "6263_田东县", "label": "田东县"}, {
            "value": "6264_平果县",
            "label": "平果县"
        }, {"value": "6265_德保县", "label": "德保县"}, {"value": "6266_靖西县", "label": "靖西县"}, {
            "value": "6267_那坡县",
            "label": "那坡县"
        }, {"value": "6268_凌云县", "label": "凌云县"}, {"value": "6269_乐业县", "label": "乐业县"}, {
            "value": "6271_田林县",
            "label": "田林县"
        }, {"value": "6272_隆林各族自治县", "label": "隆林各族自治县"}, {"value": "6273_西林县", "label": "西林县"}, {
            "value": "986260_百色市",
            "label": "百色市"
        }]
    }]
}];

export default regions;
