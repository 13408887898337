
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<AdContainerSelector>({})
class AdContainerSelector extends Vue {

    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    @Prop()
    public disabledOptions!: string[];

    public isDisabledOption(value: string) {
        return this.disabledOptions && this.disabledOptions.indexOf(value) >= 0;
    }

    public changeHandler(value: string) {
        this.$emit('change', value);
    }
}
export default AdContainerSelector;
