import { request_yx, downloadRequest_yxcsv } from '@/utils';

export async function search(params: { query: any }) {
    try {
        return await request_yx({
            api: '/console/activity/merBatch/query/list',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        throw error;
    }
}

export async function list(params: any): Promise<{ list: any; total: any }> {
    try {
        return await request_yx({
            api: '/console/activity/merBatch/query/list',
            data: {
                type: 'act_mer',
                activityId: sessionStorage.getItem('temp_activity_id')
            }
        });
    } catch (e) {
        throw e;
    }
}
export async function refresh(id: any): Promise<{ list: any; total: any }> {
    try {
        return await request_yx({
            api: '/console/activity/merBatch/query/list',
            data: {
                taskId: id,
                type: 'act_mer',
                activityId: sessionStorage.getItem('temp_activity_id')
            }
        });
    } catch (e) {
        throw e;
    }
}
export async function downloadXls(params: any) {
    return await request_yx({
        method: 'POST',
        data: params,
        api: '/console/activity/merBatch/batch/task/insert'
    });
}

export async function downloadCSV(params: any) {
    return await downloadRequest_yxcsv({
        data: params,
        prefix: 'task_'+params.id,
        api: '/console/activity/merBatch/file/download'
    });
}
