import {request_yx} from '@/utils';
import qs from 'querystringify';

export async function search(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/task/query',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function recon(params: any) {
    console.info("params ",params)
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/api/startRecon/recon',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('对账失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function invokingGW(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/invoking/GW',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('对账失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function searchInfo(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/billRecon/query',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function adjustment(params: any) {

    try {
        return await request_yx({
            method: 'GET',
            api: '/api/reconBills/recon',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('调账失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

