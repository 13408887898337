export function scheduleValidator(rule: any, value: any, callback: (message?: string) => void) {
    if (!value) {
        return callback('');
    }
    if (!value.range || value.range.filter(Boolean).length < 2) {
        callback('请填写开始时间和结束时间字段');
    } else if (!value.days || value.days.length === 0) {
        callback('请选择天');
    } else if (value.range[1] <= value.range[0]) {
        callback('上架结束时间必须大于开始时间');
    } else {
        callback();
    }
}

export function assignValidator(rule: any, value: any, callback: (message?: string) => void) {
    if (!value) {
        return callback('');
    }
    if ((!value.aus || value.aus.length === 0)
        && (!value.malls || value.malls.length === 0)
        && (!value.biz_districts || value.biz_districts.length === 0)
        && (!value.upload_aus || value.upload_aus.length === 0)) {
        return callback('请填写必填项');
    }
    return callback();
}

export function materialListValidator(tplClass: any) {
    if (!tplClass) {
        return (rule: any, value: any, callback: any) => callback();
    }
    return (rule: any, value: any, callback: any) => {
        if (!value) {
            callback('请填写必填项');
        }

        if (!tplClass) {
            callback();
        }
        for (const item of value) {
            const error = tplClass.validator(item.content);
            if (error) {
                return callback(error);
            }
            if (!item.terminals || item.terminals.length === 0) {
                return callback('载体不能为空');
            }
        }
        callback();
    };
}
