
import { mapState } from 'vuex';
import ActivitiesStatusCount from '@/components/ActivitiesStatusCount.vue';
import {Modal} from 'ant-design-vue';
import {Click} from '@/components/Auth';
import { Component, Inject, Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';


interface Actions {
    selectedRows: any;
}

@Component({
    components: {
        Click,
        ActivitiesStatusCount,

    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) => state.activitiesOpenSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public roleName: any = [];

    public data() {
        return {
            PRIVILEGE
        };
    }
    public created() {
         if(this.$store.state.activityManage.payType === 'wx'){
             this.roleName.push(PRIVILEGE.ACTIVITIES_OPEN.STOP);
         }else{
             this.roleName .push(PRIVILEGE.UNION_ACTIVITIES_OPEN.STOP);
         }
    }
    public handleRefresh() {
        this.dispatch('search');
    }
    public stopActivities() {
        Modal.confirm({
            title: '提示',
            content: '暂停后该批次立即中止，在有效期内可在已停止页签也进行手动重启！',
            onOk: () => {
                this.dispatch('updateStatus', {
                        payType: this.$store.state.activityManage.payType,
                        ids: this.selectedRows.join(','),
                        status: '3' 
                    });
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public DetailDownload() {
        this.$router.push('/console/activity/detailDownload');
    }

}
export default Actions;
