import {request, downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'district',
        api: '/console/district/list/excel'
    });
}

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request({
            api: '/console/district/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取商圈列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
