import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-shop-message-active-status',
    options: [{
        label: 'ON',
        value: 'True'
    }, {
        label: 'OFF',
        value: 'False'
    }]
});
