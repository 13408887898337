
import { Component, Watch, Vue } from 'vue-property-decorator';
import CardItem from '@/components/CardItem.vue';
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/dataZoom';
import columns1 from './columns1';
import columns2 from './columns2';
import * as services from './services';
import moment from 'moment';
import option from './option';
import {calcTableScrollX, integerFormat, floatFormat} from '@/utils';
import ChartDataType from '@/components/custom-form-components/ChartDataType';
import DateRangeType from '@/components/custom-form-components/DateRangeType';

interface CouponPage {
    columns1: any;
    columns2: any;
    statistics: any;
    tableData1: any;
    tableData2: any;
    option: any;
    dataType: string;
    dateRangeType: string;
    changeValue: any;
    chartData: any;
    loading: boolean;
    custom_time: boolean;
    custom_time_data_start: any;
    custom_time_data_end: any;
    startValue: any,
    endValue: any,
    endOpen:boolean,
    changeStartTime:string,
    changeEndTime: string,
    page_size: any
    
}

@Component({
    components: {
        ChartDataType,
        DateRangeType,
        CardItem,
        'v-chart': ECharts
    }
})
class CouponPage extends Vue {
    public data() {
        return {
            columns1,
            columns2,
            statistics: {},
            tableData1: [],
            tableData2: [],
            option,
            changeStartTime:'',
            changeEndTime: '',
            custom_time_data_start: '',
            custom_time_data_end: '',
            startValue: null,
            endValue: null,
            endOpen:false,
            custom_time: false,
            loading: false,
            calcTableScrollX,
            dataType: '',
            dateRangeType: '',
            chartData: null,
            changeValue: {
                discountCouponType: 'NEWBIE',
                startTime: '',
                endTime: moment().format('YYYY-MM-DD 00:00:00'),
                page_size: 0
            }
        };
    }

    public handleRefresh() {
        this.getTableData();
        this.$message.success('刷新成功');
    }

    public created() {
        this.getTableData();
    }

    public getFormatDate(arg:any) {
        if (arg == undefined || arg == '') {
            return '';
        }
 
        var re = arg + '';
        if (re.length < 2) {
            re = '0' + re;
        }
 
        return re;
    }

    public addDate(date:any, days:number) {
        var d = new Date(date);
        d.setDate(d.getDate() + days);
        var month = d.getMonth() + 1;
        var day = d.getDate();
        return d.getFullYear() + '-' + this.getFormatDate(month) + '-' + this.getFormatDate(day);
    }

    public onChangeStart(date:any, dateString:any) {
        this.changeStartTime = dateString
    }

    public onChangeEnd(date:any, dateString: any) {
         this.changeEndTime = this.addDate(dateString,0);
    }
    
    public disabledStartDate(startValue:any) {
        const endValue = this.endValue;
        if (!startValue || !endValue) {
            return startValue.valueOf() > new Date().getTime();
        }
        return endValue.valueOf() - startValue.valueOf() > 24*60*60*1000*30 || endValue.valueOf() <= startValue.valueOf();
    }

    public disabledEndDate(endValue:any) {
       const startValue = this.startValue;
        if (!endValue || !startValue) {
            return endValue.valueOf() > new Date().getTime();
        }
        return endValue.valueOf() <= startValue.valueOf() || endValue.valueOf() > new Date().getTime() || endValue.valueOf() - startValue.valueOf() > 24*60*60*1000*30;
    }

    public handleStartOpenChange(open:any) {
        if (!open) {
          this.endOpen = true;
        }
      }

    public handleEndOpenChange(open:any) {
        this.endOpen = open;
      }
    public handleCustomTime() {
       this.custom_time = false;
        this.changeValue.startTime = this.changeStartTime+ ' ' + '00:00:00';
        this.changeValue.endTime = this.changeEndTime+ ' ' + '00:00:00';
        this.changeValue.page_size = 30;
    }

    @Watch('startValue', {deep: true, immediate:true})

    @Watch('endValue',{deep:true,immediate:true})

    @Watch('changeValue', { deep: true, immediate: true })
    public async watchChangeValue(changeValue: any) {
        if (this.changeValue.startTime) {
            const res = await services.marketing_data_list({
                discount_coupon_type: changeValue.discountCouponType,
                start_time: changeValue.startTime,
                end_time: changeValue.endTime,
                // @ts-ignore
                page_size: changeValue.page_size,
            });
            // 倒序
            res.list.reverse();
            this.chartData = res;
            this.option.xAxis.data = [];
            switch (changeValue.discountCouponType) {
                case '':
                    break;
                case 'NEWBIE':
                    this.option.series[0].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[0].data.push(val.coupon_amount);
                    });
                    break;
                case 'LUCKY_PACKET':
                    this.option.series[1].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[1].data.push(val.coupon_amount);
                    });
                    break;
                case 'ORIENTATION':
                    this.option.series[2].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[2].data.push(val.coupon_amount);
                    });
                    break;
                case 'MALL':
                    this.option.series[3].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[3].data.push(val.coupon_amount);
                    });
                    break;
                case 'OTHER':
                    this.option.series[4].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[4].data.push(val.coupon_amount);
                    }); 
                    break;
            }
        } else {
            this.option.xAxis.data = [];
            Object.keys(this.option.series).forEach(key => {
                this.option.series[key].data = [];
            });
        }
    }

    public onDateRangeTypeChange(e: any) {
        this.dateRangeType = e;
        switch (e) {
            case '':
                this.changeValue.startTime = '';
                break;
            case 'last7':
                this.changeValue.startTime = moment()
                    .subtract(7, 'day')
                    .format('YYYY-MM-DD 00:00:00');
                this.changeValue.page_size = 7;
                break;
            case 'last15':
                this.changeValue.startTime = moment()
                    .subtract(15, 'day')
                    .format('YYYY-MM-DD 00:00:00');
                this.changeValue.page_size = 15;
                break;
            case 'last30':
                this.changeValue.startTime = moment()
                    .subtract(30, 'day')
                    .format('YYYY-MM-DD 00:00:00');
                this.changeValue.page_size = 30;
                break;
            case 'custom':
                    // console.log('在这里弹窗');
                    this.custom_time = true;
                    break;
        }
    }

    public onTypeChange(e: any) {
        this.dataType = e;
        Object.keys(this.option.series).forEach(key => {
            this.option.xAxis.data = [];
            this.option.series[key].data = [];
            this.chartData.list.forEach((val: any) => {
                this.option.xAxis.data.push(val.date);
                this.option.series[key].data.push(val[e]);
            });
        });
    }       

    public async onLegendSelectChange(e: any) {
        // this.dataType = 'coupon_amount';
        switch (e.name) {
            case '进店红包':
                this.changeValue.discountCouponType = 'NEWBIE';
                break;
            case '拼手气红包':
                this.changeValue.discountCouponType = 'LUCKY_PACKET';
                break;
            case '定向券':
                this.changeValue.discountCouponType = 'ORIENTATION';
                break;
            case '商场券':
                this.changeValue.discountCouponType = 'MALL';
                break;
            case '商圈券':
                this.changeValue.discountCouponType = 'OTHER';
                break;
        }
    }

    public async getTableData() {
        try {
            this.loading = true;
            const res = await services.statistics();
            this.statistics = res;
            this.tableData1 = [
                {
                    pay_type: '微信',
                    carrier:'小程序',
                    today_use_coupons_amount: floatFormat(
                        res.wx_today_use_coupons_amount / 100
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        res.wx_yesterday_use_coupons_amount / 100
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                        res.wx_seven_days_use_coupons_amount / 100
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                        res.wx_thirty_days_use_coupons_amount / 100
                    )
                },
                {
                    pay_type: '微信',
                    carrier: 'H5',  
                    today_use_coupons_amount: floatFormat(
                        res.wx_h5_today_use_coupons_amount / 100
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        res.wx_h5_yesterday_use_coupons_amount / 100
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                        res.wx_h5_seven_days_use_coupons_amount / 100
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                        res.wx_h5_thirty_days_use_coupons_amount / 100
                    )
                },
                {
                    pay_type: '支付宝',
                    carrier: 'H5',
                    today_use_coupons_amount: floatFormat(
                        res.alipay_today_use_coupons_amount / 100
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        res.alipay_yesterday_use_coupons_amount / 100
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                        res.alipay_seven_days_use_coupons_amount / 100
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                        res.alipay_thirty_days_use_coupons_amount / 100
                    )
                },
                 {
                    pay_type: '支付宝',
                    carrier: '小程序',
                    today_use_coupons_amount: floatFormat(
                        0.00
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        0.00
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                       0.00
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                       0.00
                    )
                },
                {
                    pay_type: '云闪付',
                    carrier:'H5',
                    today_use_coupons_amount: floatFormat(
                        res.uq_today_use_coupons_amount / 100
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        res.uq_yesterday_use_coupons_amount / 100
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                        res.uq_seven_days_use_coupons_amount / 100
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                        res.uq_thirty_days_use_coupons_amount / 100
                    )
                },

                {
                    pay_type: '华为钱包',
                    carrier:'H5',   
                    today_use_coupons_amount: floatFormat(
                        res.hw_today_use_coupons_amount / 100
                    ),
                    yesterday_use_coupons_amount: floatFormat(
                        res.hw_yesterday_use_coupons_amount / 100
                    ),
                    seven_days_use_coupons_amount: floatFormat(
                        res.hw_seven_days_use_coupons_amount / 100
                    ),
                    thirty_days_use_coupons_amount: floatFormat(
                        res.hw_thirty_days_use_coupons_amount / 100
                    )
                }
            ];
            this.tableData2 = [
                {
                    pay_type: '微信',
                    carrier: '小程序',
                    today_use_coupons_count: integerFormat(res.wx_today_use_coupons_count),
                    yesterday_use_coupons_count:
                        integerFormat(res.wx_yesterday_use_coupons_count),
                    seven_days_use_coupons_count:
                        integerFormat(res.wx_seven_days_use_coupons_count),
                    thirty_days_use_coupons_count:
                        integerFormat(res.wx_thirty_days_use_coupons_count)
                },
                 {
                    pay_type: '微信',
                    carrier: 'H5',
                    today_use_coupons_count: integerFormat(res.wx_h5_today_use_coupons_count),
                    yesterday_use_coupons_count:
                        integerFormat(res.wx_h5_yesterday_use_coupons_count),
                    seven_days_use_coupons_count:
                        integerFormat(res.wx_h5_seven_days_use_coupons_count),
                    thirty_days_use_coupons_count:
                        integerFormat(res.wx_h5_thirty_days_use_coupons_count)
                },
                {
                    pay_type: '支付宝',
                    carrier: 'H5',
                    today_use_coupons_count: integerFormat(res.alipay_today_use_coupons_count),
                    yesterday_use_coupons_count:
                        integerFormat(res.alipay_yesterday_use_coupons_count),
                    seven_days_use_coupons_count:
                        integerFormat(res.alipay_seven_days_use_coupons_count),
                    thirty_days_use_coupons_count:
                        integerFormat(res.alipay_thirty_days_use_coupons_count)
                },
                 {
                    pay_type: '支付宝',
                    carrier: '小程序',
                    today_use_coupons_count: 0,
                    yesterday_use_coupons_count:0,
                    seven_days_use_coupons_count:0,
                    thirty_days_use_coupons_count:0
                },
                {
                    pay_type: '云闪付',
                    carrier:'H5',
                    today_use_coupons_count: integerFormat(res.uq_today_use_coupons_count),
                    yesterday_use_coupons_count:
                        integerFormat(res.uq_yesterday_use_coupons_count),
                    seven_days_use_coupons_count:
                        integerFormat(res.uq_seven_days_use_coupons_count),
                    thirty_days_use_coupons_count:
                        integerFormat(res.uq_thirty_days_use_coupons_count)
                },
                
               
                {
                    pay_type: '华为钱包',
                    carrier:'H5',
                    today_use_coupons_count: integerFormat(res.hw_today_use_coupons_count),
                    yesterday_use_coupons_count:
                        integerFormat(res.hw_yesterday_use_coupons_count),
                    seven_days_use_coupons_count:
                        integerFormat(res.hw_seven_days_use_coupons_count),
                    thirty_days_use_coupons_count:
                        integerFormat(res.hw_thirty_days_use_coupons_count)
                }
            ];
            this.loading = false;
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取统计数据失败, 请联系技术人员!');
            }
        }
    }
}

export default CouponPage;
