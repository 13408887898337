
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import Actions from './Actions.vue';
    import {DatePicker} from '@/components/custom-form-components';

    interface SearchForm {
        form: any;
    }

    @Component({
        components: {
            DatePicker,
            Actions
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.orientationSearchPage.query);
                },
                loading: (state: any) => state.orientationSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {

                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/marketing/newbie-data/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        public handleDateChange(e: any) {
            return this.form && this.form.setFieldsValue({
                range_time: [e.start, e.end]
            });
        }

        private formatQuery(values: any) {
            return {
                discount_coupon_type: 'ORIENTATION',
                start_time:
                    values.range_time &&
                    values.range_time[0] &&
                    values.range_time[0].format('YYYY-MM-DD HH:mm:ss'),
                end_time:
                    values.range_time &&
                    values.range_time[1] &&
                    values.range_time[1].format('YYYY-MM-DD HH:mm:ss'),
            };
        }

        private formatFormData(query: any) {
            return {
                ...query,
                range_time: [
                    query.start_time && moment(query.start_time),
                    query.end_time && moment(query.end_time)
                ]
            };
        }
    }

    export default SearchForm;
