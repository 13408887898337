import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    columns,

    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
}, {
    state: {
        selectedRows: [],
        lastDeletedTime: null
    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setDeleted(state: any) {
            state.lastDeletedTime = new Date();
        },
        setError(state: any, error: any) {
            state.error = error;
        },
    },
    actions: {
        async deleteBlacklist(context: any) {
            try {
                const ids = context.state.selectedRows.map((item: any) => item.id).join(',');
                await services.deleteBlacklist({
                    ids
                });
                context.commit('setDeleted');
                context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            }
        }
    }
});
