import {request} from '@/utils';

export async function search(params: any): Promise<{ list: any[], total: number }> | never {

    const {
        id,
        ...rest
    } = params;
    try {
        return await request({
            api: '/console/district/member/list',
            data: {
                ...rest,
                district_id: id
            }
        });
    } catch (e) {
        const error: any = new Error('获取商圈会员列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
