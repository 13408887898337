
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import * as validators from '../../validators';

interface CreateForm {
    baseForm: any;
    otherForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({})
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public baseForm;

    public otherForm;

    public baseData: any;
    public activeTab: any;
    public isShowAllDiscount: boolean = false;
    public timeRuleData: any;
    public timeRulevisible: boolean = false;
    public noTimeRuleData: any;
    public noTimeRulevisible: boolean = false;
    public shopData: any;
    public bankData: any;
    public activitySponsors: any;
    public budgetLimitCheck: any;
    public paywayCheck: any;
    public discountRuleCheck: any;

    public isDefault: boolean = false;

    public conflictReplaceList: any;

    public merchantTotal = 0;

    public validators = validators;
    public timeArrayTemp = [];
    public dateArrayTemp: any = [];
    public isShowFlag:any;
    public isSettleFlag:any;
    public onlyMer: boolean = false;
    public created() {};

    public data() {
        return {
            baseData: {},
            baseForm: this.$form.createForm(this),
            otherForm: this.$form.createForm(this),
            activeTab: '1',
            isShowFlag: '1',
            isSettleFlag:'1',
            isShowAllDiscount: false,
            timeRuleData: {},
            timeRulevisible: false,
            noTimeRuleData: {},
            noTimeRulevisible: false,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginLeft: '120px'
            },
            budgetLimit: '2',
            budgetInput: false,
            discountRuleCheck: '1',
            budgetLimitCheck: '2',
            paywayCheck: '0',
            shopData: [],
            bankData: [],
            activitySponsors: [],
            timeArrayTemp: [],
            ateArrayTemp: [],
            hasTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ],
            noTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]
        };
    }

    public discountRuleChange(e: any) {
        this.discountRuleCheck = e.target.value;
    }

    public timeRuleChange(e: any) {
        if (e.target.value == '1') {
            //.timeRuleData = {};
            this.timeRulevisible = true;
        } else if (e.target.value == '2') {
            //.noTimeRuleData = {};
            this.noTimeRulevisible = true;
        }
    }

    public initTimeRuleData(data: any) {
        this.timeRuleData = data;
    }

    public showAllDiscount(data: any) {
        console.info('showAllDiscount', data);
        this.isShowFlag = data.target.value;
        if ('1' == data.target.value) {
            this.isShowAllDiscount = false;
        } else {
            this.isShowAllDiscount = true;
        }
    }
    public showSettleFlag(data: any) {
        this.isSettleFlag = data.target.value;
    }
    

    public handleTimeRuleHide() {
        console.log(this.timeRuleData.validDays);
        if (!this.timeRuleData.validDays || this.timeRuleData.validDays.length === 0) {
            this.$message.error('星期不能为空');
            return;
        }
        if (
            !this.timeRuleData.timerange ||
            this.timeRuleData.timerange.length === 0 ||
            this.timeRuleData.timerange.length % 2 !== 0
        ) {
            this.$message.error('时间区间不能为空');
            return;
        }
        for (var rule of this.timeRuleData.timerange) {
            if (rule === null) {
                this.$message.error('时间区间不能为空');
                return;
            }
        }
        this.timeArrayTemp = [];
        for (var i = 1; i <= this.timeRuleData.timerange.length / 2; i++) {
            const startTime = this.timeRuleData.timerange[(i - 1) * 2].format(
                'HH:mm'
            );
            const endTime = this.timeRuleData.timerange[i * 2 - 1].format(
                'HH:mm'
            );
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }
        this.timeRulevisible = false;
    }

    public handleTimeRuleHideCancel() {
        this.timeRulevisible = false;
    }

    public initNoTimeRuleData(data: any) {
        this.noTimeRuleData = data;
    }

    public handleNoTimeRuleHide() {
        if (
            !this.noTimeRuleData.daterange ||
            this.noTimeRuleData.daterange.length === 0
        ) {
            this.$message.error('日期区间不能为空');
            return;
        }
        for (var drule of this.noTimeRuleData.daterange) {
            if (drule === null) {
                this.$message.error('日期区间不能为空');
                return;
            }
        }
        if (
            !this.noTimeRuleData.timerange ||
            this.noTimeRuleData.timerange.length === 0 ||
            this.noTimeRuleData.timerange.length % 2 !== 0
        ) {
            this.$message.error('时间区间不能为空');
            return;
        }
        for (var rule of this.noTimeRuleData.timerange) {
            if (rule === null) {
                this.$message.error('时间区间不能为空');
                return;
            }
        }
        this.timeArrayTemp = [];
        this.dateArrayTemp = [];
        for (var i = 1; i <= this.noTimeRuleData.timerange.length / 2; i++) {
            const startTime = this.noTimeRuleData.timerange[(i - 1) * 2].format(
                'HH:mm'
            );
            const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format(
                'HH:mm'
            );
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }

        this.noTimeRuleData.daterange.forEach((date: any) => {
            if (date) {
                const startDate = date[0].format('YYYYMMDD');
                const endDate = date[1].format('YYYYMMDD');
                this.dateArrayTemp.push(startDate + '-' + endDate);
            }
        });
        this.noTimeRulevisible = false;
    }
    public handleNoTimeRuleHideCancel() {
        this.noTimeRulevisible = false;
    }
    public tabChange(key: any) {
        console.log(key)
        if (key === '2') {
            this.baseForm.validateFields((error: any, values: any) => {
                if (!error) {
                    const promoAmount = parseFloat(values.promoAmount);
                    const orderMinAmt = parseFloat(values.orderMinAmt);
                    console.log(promoAmount, orderMinAmt)
                    if (+promoAmount >= +orderMinAmt) {
                        this.$message.error('使用门槛金额需大于券面额');
                        return;
                    }
                    if (values.validType == '1') {
                        if (
                            this.timeRuleData.validDays == undefined ||
                            this.timeRuleData.validDays.length === 0
                        ) {
                            this.$message.error('规则日期天数不能为空错误');
                            return;
                        }
                        if (
                            this.timeRuleData.timerange == undefined ||
                            this.timeRuleData.timerange.length % 2 !== 0
                        ) {
                            this.$message.error('规则日期时间范围错误');
                            return;
                        }
                        for (var rule of this.timeRuleData.timerange) {
                            if (rule == null) {
                                this.$message.error('规则日期时间不能为空');
                                return;
                            }
                        }
                    } else if (values.validType == '2') {
                    }
                    // if(this.isSettleFlag === 1 && 
                    //     (!values.settleRate || 
                    //     parseFloat(values.settleRate) === 0 || 
                    //     parseFloat(values.settleRate) === 100 )
                    // ) {
                    //     this.$message.error('结算比不能为空/不能为0');
                    //     return;
                    // }
                    // if(this.isSettleFlag === 1 && 
                    //     (!values.settleAmount || 
                    //     parseFloat(values.settleAmount) === 0 )
                    // ) {
                    //     this.$message.error('结算金额不能为空/不能为0');
                    //     return;
                    // }
                    this.formatBaseForm(values);
                    this.activeTab = '2';
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        } else {
            this.activeTab = key;
        }
    }

    // public budgetLimitChange(value: any, options: any) {
    //     this.budgetLimitCheck = value;
    //     if (value === '2') {
    //         this.otherForm.setFieldsValue({ budgetLimitInput: '' });
    //     }
    // }

    public paywayChange(e: any) {
        if (e.target.value == '1') {
            this.paywayCheck = '1';
        } else {
            this.paywayCheck = '0';
            this.otherForm.setFieldsValue({ bankName: '' });
            this.otherForm.setFieldsValue({ cardType: '' });
            this.otherForm.setFieldsValue({ cardBin: '' });
        }
    }

    public handleNext(e: any) {
        
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.log('handleNext')
                const promoAmount = parseFloat(values.promoAmount);
                const orderMinAmt = parseFloat(values.orderMinAmt);

                if (+promoAmount >= +orderMinAmt) {
                    this.$message.error('减价金额必须小于使用门槛金额');
                    return;
                }
                if (values.validType == '1') {
                    if (
                        this.timeRuleData.validDays == undefined ||
                        this.timeRuleData.validDays.length === 0
                    ) {
                        this.$message.error('规则日期天数不能为空错误');
                        return;
                    }
                    if (
                        this.timeRuleData.timerange == undefined ||
                        this.timeRuleData.timerange.length % 2 !== 0
                    ) {
                        this.$message.error('规则日期时间范围错误');
                        return;
                    }
                    for (var rule of this.timeRuleData.timerange) {
                        if (rule == null) {
                            this.$message.error('规则日期时间不能为空');
                            return;
                        }
                    }
                    
                } else if (values.validType == '2') {
                }
                // console.log(1111111, this.isSettleFlag)
                // if(this.isSettleFlag == '1' && 
                //     (!values.settleRate || 
                //     parseFloat(values.settleRate) === 0 )
                // ) {
                //     this.$message.error('结算比不能为空/不能为0');
                //     return
                // }
                // if(this.isSettleFlag == '1' && 
                //     (!values.settleAmount || 
                //     parseFloat(values.settleAmount) === 0 )
                // ) {
                //     this.$message.error('结算金额不能为空/不能为0');
                //     return
                // }
                this.formatBaseForm(values);
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        console.log(value)
        value.forEach((data: any) => {
            if (this.onlyMer) {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data
                    };
                    this.shopData.push(objData);
                }
            } else {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data[0] && shop.termNo === data[1]) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data[0],
                        termNo: data[1]
                    };
                    this.shopData.push(objData);
                }
            }
            
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.otherForm.validateFields((error: any, values: any) => {
            if (!error) {
                // console.log('budgetAmount', values.budget);
                // console.log('dailyAmountLimit', values.budgetLimitInput);
                // console.log(
                //     null != values.budgetLimitInput &&
                //         '' != values.budgetLimitInput
                // );
                // if (
                //     null != values.budgetLimitInput &&
                //     '' != values.budgetLimitInput &&
                //     parseFloat(values.budgetLimitInput) >
                //         parseFloat(values.budget)
                // ) {
                //     this.$message.error('当天消耗金额上限必须小于活动预算金额');
                //     return;
                // }
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public async handleSubmitConfirm(values: any) {
        try {
            let baseData = this.baseData;
            console.log(this.baseData);
            if (null == baseData) {
                baseData = this.formatBaseForm(values);
            }
            const otherData = this.formatOtherForm(values);
            const formData = { ...baseData, ...otherData };
            console.log(formData);
            await this.$store.dispatch('zhongxinSearchPage/create', formData)
            console.log(this.$store.state.zhongxinSearchPage.updatedSuccess)
            
            if (this.$store.state.zhongxinSearchPage.updatedSuccess) {
                this.$message.success('提交成功');
                const queryString = this.$store.getters[
                    'zhongxinSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/wxCashCouponActivity/search' + queryString
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'zhongxinSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/wxCashCouponActivity/search' + queryString
                });
            }
        });
    }

    public handleBack() {
        this.activeTab = '1';
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {}

    @Watch('onlyMer')
    public watchOnlyMerChange(onlyMer: boolean) {
        console.log(onlyMer)
        this.shopData = []
    }

    private formatBaseForm(formData: any) {
        let timeArray = [];
        let dateArray: any = [];
        if (formData.validType == '1') {
            for (var i = 1; i <= this.timeRuleData.timerange.length / 2; i++) {
                const startTime = this.timeRuleData.timerange[
                    (i - 1) * 2
                ].format('HH:mm');
                const endTime = this.timeRuleData.timerange[i * 2 - 1].format(
                    'HH:mm'
                );
                timeArray.push(startTime + '-' + endTime);
            }
        } else if (formData.validType == '2') {
            console.log(this.noTimeRuleData.daterange);
            for (
                var i = 1;
                i <= this.noTimeRuleData.timerange.length / 2;
                i++
            ) {
                const startTime = this.noTimeRuleData.timerange[
                    (i - 1) * 2
                ].format('HH:mm');
                const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format(
                    'HH:mm'
                );
                timeArray.push(startTime + '-' + endTime);
            }

            this.noTimeRuleData.daterange.forEach((date: any) => {
                if (date) {
                    const startDate = date[0].format('YYYYMMDD');
                    const endDate = date[1].format('YYYYMMDD');
                    dateArray.push(startDate + '-' + endDate);
                }
            });
        }
        if (formData.settleFlag == '1') {
            this.baseData = {
                name: formData.name,                                    // 活动名称
                budgetActivity: formData.budgetActivity,                // 活动id
                orgId: 'lakala',                                  // 机构号
                stockId: formData.stockId + '',                         // 批次号
                showFlag: formData.showFlag,                            // 是否小程序展示
                channelId: formData.channelId,                          // 优惠渠道
                promoAmount: (formData.promoAmount * 100).toFixed(0),   // 面额
                orderMinAmt: (formData.orderMinAmt * 100).toFixed(0),   // 使用门槛
                description: formData.description,                      // 描述说明
                settleFlag: formData.settleFlag,                        // 是否结算
                settleRate: '100',                        // 结算比例
                validType: formData.validType,                          // 选择时效类型
                validDays: formData.validDays,                          // 用户领取多少天内可用
                validStartTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                validEndTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                // availableTime: {
                //     type: formData.validType,
                //     weekDays: this.timeRuleData.validDays,
                //     datePeriods: dateArray,
                //     timePeriods: timeArray
                // },
                // activitySponsors: this.activitySponsors
            }
        } else {
            this.baseData = {
                name: formData.name,                                    // 活动名称
                budgetActivity: formData.budgetActivity,                // 活动id
                orgId: 'lakala',                                  // 机构号
                stockId: formData.stockId + '',                         // 批次号
                showFlag: formData.showFlag,                            // 是否小程序展示
                channelId: formData.channelId,                          // 优惠渠道
                promoAmount: (formData.promoAmount * 100).toFixed(0),   // 面额
                orderMinAmt: (formData.orderMinAmt * 100).toFixed(0),   // 使用门槛
                description: formData.description,                      // 描述说明
                settleFlag: formData.settleFlag,                        // 是否结算
                validType: formData.validType,                          // 选择时效类型
                validDays: formData.validDays,                          // 用户领取多少天内可用
                validStartTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                validEndTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                // availableTime: {
                //     type: formData.validType,
                //     weekDays: this.timeRuleData.validDays,
                //     datePeriods: dateArray,
                //     timePeriods: timeArray
                // },
                // activitySponsors: this.activitySponsors
            }
        }
    }

    private formatOtherForm(formData: any) {
        // let dailyAmountLimit = '';
        // if (
        //     null != formData.budgetLimitInput &&
        //     '' != formData.budgetLimitInput
        // ) {
        //     dailyAmountLimit = (formData.budgetLimitInput * 100).toFixed(0);
        // }
        // const objData = {
        //     bankName: formData.bankName,
        //     cardType: formData.cardType,
        //     cardBin: formData.cardBin
        // };
        // this.bankData.push(objData);
        const otherData = {
            // budgetAmount: (formData.budget * 100).toFixed(0),
            // dailyAmountLimit: dailyAmountLimit,
            // userLimit: {
            //     countLimit: formData.timesLimit,
            //     dailyCountLimit: formData.discountRuleInput
            // },
            merchantList: this.shopData,
            listType: formData.listType,
            merLimit: formData.listType === 'merId' ? '1' : '2' // 商户/商终限制标识：0-不限制(暂时不用);1-商户列表;2-商终列表
            // bankCardList: this.bankData,
        };
        return otherData;
    }

    private chooseMerchantType(e:any) {
        console.log(e.target.value)
        if(e.target.value === 'merId') {
            this.onlyMer = true
        } else {
            this.onlyMer = false
        }
    }
}

export default CreateForm;
