import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-order-status',
    options: [{
        label: '待支付',
        value: 'WAIT_PAY'
    }, {
        label: '支付中',
        value: 'PAYING'
    }, {
        label: '已付款',
        value: 'PAID'
    }, {
        label: '交易失败',
        value: 'FAIL'
    }, {
        label: '交易错误',
        value: 'ERROR'
    }, {
        label: '已退款(已退款且已退优惠(如有优惠))',
        value: 'REFUND'
    }, {
        label: '交易关闭',
        value: 'CLOSED'
    }, {
        label: '退款中',
        value: 'REFUND_PROCESSING'
    }, {
        label: '退款失败(已退优惠(如有优惠)，退款失败)',
        value: 'REFUND_FAIL'
    }, {
        label: '已退优惠(单独退优惠成功，款项未退)',
        value: 'COUPON_REFUND'
    }, {
        label: '退优惠失败',
        value: 'COUPON_REFUND_FAIL'
    }]
});
