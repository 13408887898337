
    import CommonLayout from '@/components/CommonLayout.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';

    @Component({
        components: {
            Actions,
            SearchTable,
            CommonLayout
        },
        computed: {
            ...mapState({
                fields() {
                    return [] || this.state().fields;
                }
            })
        }
    })
    export default class RolePage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'buyRecordSearchPage';

        public data() {
            return {
                columns
            };
        }

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/store/sms/buying/record' + this.getters('queryString')()
            });
        }

        public created() {

            // this.registerStore();

            // this.dispatch('initQuery', {
            //     ...this.$route.query
            // });
        }

        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        

        private handleFieldChange(e: any) {
            this.commit('setFields', e);
        }
    }
