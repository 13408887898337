import {message, Modal} from 'ant-design-vue';
import {Moment} from 'moment';
import axios from 'axios';
import store from '@/store';
import config from '@/config';
import qs from 'querystringify';
import router from '@/router';
import download from '@/lib/download';
import sortBy from 'sort-array';

export class BizError extends Error {

}

export function deleteEmpty(obj: any) {
    Object.keys(obj)
        .forEach((key: string) => (obj[key] === undefined || obj[key] === null || obj[key] === '') && delete obj[key]);
    return obj;
}

export function trimParams(obj: any) {
    return Object.keys(obj).map((key: string) => {
        return typeof obj[key] === 'string' && obj[key].trim();
    });
}

export function responseHandler(response: any) {
    if (response.status !== 200) {
        throw new Error(response.statusText);
    } else {
        if (response.data.status.code !== 0) {
            throw new BizError(response.data.status.desc);
        }
        return response.data.result;

    }
}

let alreadyAlert = false;

function readFile(data: any) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(JSON.parse(reader.result as any));
        reader.readAsText(data);
    });
}

async function process403(error: any) {
    if (alreadyAlert) {
        return;
    }
    alreadyAlert = true;
    let data = error.response.data;
    if (data instanceof Blob) {
        data = await readFile(data);
    }
    const detail = data.detail;
    let type: 'error' | 'info' = 'error';
    let content = '';
    let onOk = () => {
        alreadyAlert = false;
    };
    switch (detail) {
        case 'permission':
            content = '权限不足';
            type = 'error';
            break;
        case 'exist':
            content = '您的账号已在别处登录';
            type = 'info';
            onOk = () => {
                store.commit('login/clear');
                alreadyAlert = false;
            };
            break;
        case 'timeout':
            content = '登录超时，请重新登录';
            type = 'info';
            onOk = () => {
                store.commit('login/clear');
                alreadyAlert = false;
            };
            break;
        default:
            content = '未知错误';
            type = 'info';
            break;
    }
    return Modal[type]({
        title: '提示',
        content,
        okText: '确认',
        onOk
    });
}

export function getTop(e: any) {
    let offset = e.offsetTop;
    if (e.offsetParent != null) {
        offset += getTop(e.offsetParent);
    }
    return offset;
}

export function Uint8ArrayToString(fileData: Uint8Array) {
    let dataString = '';
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < fileData.length; i++) {
      dataString += String.fromCharCode(fileData[i]);
    }
    return dataString;
}

export async function requestStream(options: {
    api: string, method?: 'GET' | 'POST', data?: any, headers?: object}): Promise<ReadableStream<Uint8Array> | null> {
    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }

    session = JSON.parse(session);
    const res = await fetch(config.BASE_URL + options.api, {
        method: 'post',
        headers: {
            'Authorization': session.token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(options.data)
    });

    return res.body;
}

export function readStream(body: any, {onFinished, onProgress, onError}: any) {
    if (body) {
        const reader = body.getReader();
        let tail = '';
        function pump() {
            reader.read().then(({done, value}: any) => {
                if (!done) {
                    const lines = (tail + Uint8ArrayToString(value)).split('\n');
                    if (lines.length > 1) {
                        const completeLines = lines.slice(0, -1).map((line) => JSON.parse(line));
                        const successLines = completeLines.filter((line) => line.status.code === 0);
                        const errorLines = completeLines.filter((line) => line.status.code !== 0);
                        if (successLines.length > 0) {
                            onProgress(successLines.map((line) => line.result));
                        }
                        if (errorLines.length > 0) {
                            onError(new Error(errorLines[0].status.desc));
                        }
                    }
                    tail = lines[lines.length - 1];
                    pump();
                } else {
                    onFinished();
                }
            }).catch(onError);
        }
        pump();
    } else {
        onError(new Error('系统异常'));
    }
}

export async function request(options: { api: string, method?: 'GET' | 'POST', data?: any, headers?: object,
    responseType?: string}) {

    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }

    session = JSON.parse(session);
    try {
        if (options.method === 'POST') {
            return responseHandler(await axios.post(config.BASE_URL + options.api, options.data, {
                responseType: options.responseType || 'json',
                headers: {
                    Authorization: session.token,
                    ...options.headers
                }
            }));
        } else {
            return responseHandler(
                await axios.get(
                    config.BASE_URL + options.api + (options.data ? qs.stringify(options.data, true) : ''), {
                        responseType: options.responseType || 'json',
                        headers: {
                            Authorization: session.token,
                            ...options.headers
                        }
                    }
                )
            );
        }
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}
//营销平台
export async function request_yx(options: { api: string, method?: 'GET' | 'POST', data?: any, headers?: object,
    responseType?: string}) {

    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }

    session = JSON.parse(session);
    try {
        if (options.method === 'POST') {
            return responseHandler(await axios.post(config.XY_BASE_URL + options.api, options.data, {
                responseType: options.responseType || 'json',
                headers: {
                    Authorization: session.token,
                    ...options.headers
                }
            }));
        } else {
            console.info("get options:",options)
            console.info("get options.data:",options.data)
            console.info("get options.data 1:",qs.stringify(options.data, true))
            return responseHandler(
                await axios.get(
                    config.XY_BASE_URL + options.api + (options.data ? qs.stringify(options.data, true) : ''), {
                        responseType: options.responseType || 'json',
                        headers: {
                            Authorization: session.token,
                            ...options.headers
                        }
                    }
                )
            );
        }
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}

export async function downloadRequest(options: { api: string, data: object, prefix: string }) {
    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }
    session = JSON.parse(session);
    try {
        const response = await axios.get(config.BASE_URL + options.api + qs.stringify(options.data, true), {
            headers: {
                Authorization: session.token
            },
            responseType: 'blob',
            timeout: config.EXPORT_EXCEL_TIMEOUT
        });
        let fileName = options.prefix + '_' + Date.now() + '.xls';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.match(/filename=(.*)/)) {
            fileName = contentDisposition.match(/filename=(.*)/)[1];
        }
        download(response.data, fileName, 'application/vnd.ms-excel');
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}
//营销平台下载
export async function downloadRequest_yx(options: { api: string, data: object, prefix: string }) {
    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }
    session = JSON.parse(session);
    try {
        const response = await axios.get(config.XY_BASE_URL + options.api + qs.stringify(options.data, true), {
            headers: {
                Authorization: session.token
            },
            responseType: 'blob',
            timeout: config.EXPORT_EXCEL_TIMEOUT
        });
        let fileName = options.prefix + '_' + Date.now() + '.xls';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.match(/filename=(.*)/)) {
            fileName = contentDisposition.match(/filename=(.*)/)[1];
        }
        download(response.data, fileName, 'application/vnd.ms-excel');
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}
export async function downloadRequest_yxcsv(options: { api: string, data: object, prefix: string }) {
    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }
    session = JSON.parse(session);
    try {
        const response = await axios.get(config.XY_BASE_URL + options.api + qs.stringify(options.data, true), {
            headers: {
                Authorization: session.token
            },
            responseType: 'blob',
            timeout: config.EXPORT_EXCEL_TIMEOUT
        });
        let fileName = options.prefix + '.csv';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.match(/filename=(.*)/)) {
            fileName = contentDisposition.match(/filename=(.*)/)[1];
        }
        download(response.data, fileName, 'application/vnd.ms-excel');
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}

//营销平台下载
export async function downloadRequestPOST_yx(options: { api: string, data: object, prefix: string,headers?: object}) {
    let session: any = window.localStorage.getItem('session');
    if (!session) {
        router.replace({
            path: '/login'
        });
        const error: any = new Error('未登录');
        error.is403 = true;
        throw error;
    }
    session = JSON.parse(session);
    try {
        const response = await axios.post(config.XY_BASE_URL + options.api, options.data, {
            headers: {
                Authorization: session.token,
                ...options.headers
            },
            responseType: 'blob',
            timeout: config.EXPORT_EXCEL_TIMEOUT
        });
        // const response = await axios.get(config.XY_BASE_URL + options.api + qs.stringify(options.data, true), {
        //     headers: {
        //         Authorization: session.token
        //     },
        //     responseType: 'blob',
        //     timeout: config.EXPORT_EXCEL_TIMEOUT
        // });
        let fileName = options.prefix + '_' + Date.now() + '.xls';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition && contentDisposition.match(/filename=(.*)/)) {
            fileName = contentDisposition.match(/filename=(.*)/)[1];
        }
        download(response.data, fileName, 'application/vnd.ms-excel');
    } catch (e) {
        const error: any = e;
        if (error.message === 'Network Error') {
            throw new Error('网络错误');
        } else if (error.response && error.response.status === 403) {
            error.is403 = true;
            await process403(error);
            throw error;
        } else {
            throw error;
        }
    }
}
export function normFile(e: any) {
    if (Array.isArray(e)) {
        return e;
    }

    if (e && e.file.status === 'done') {
        if (e.file.response.status.code === 0) {
            e.file.url = e.fileList[0].url = e.file.response.result.url;
            message.success('上传成功!');
        } else {
            message.error(e.file.response.status.desc);
            return [];
        }
    } else if (e && e.file.status === 'error') {
        message.error('上传失败，请联系技术人员!');
        return [];
    }
    return e && e.fileList;
}
export function yx_normFile(e: any) {
    if (Array.isArray(e)) {
        return e;
    }

    if (e && e.file.status === 'done') {
        if (e.file.response.status.code === 0) {
            console.log( e.file.response.result);
            e.file.url = e.fileList[0].url = e.file.response.result;
            message.success('上传成功!');
        } else {
            message.error(e.file.response.status.desc);
            return [];
        }
    } else if (e && e.file.status === 'error') {
        message.error('上传失败，请联系技术人员!');
        return [];
    }
    return e && e.fileList;
}
export function auditImage(e: any) {
    // 图片审核
    if (e.fileList[0] && e.fileList[0].url) {
        store.dispatch(
            'common/checkPicture',
            e.fileList[0].url
        );
    }
}

export function couponRenderFactory(type: string | string[]) {
    return (_: any, record: any) => {
        const coupon = record.coupon;
        if (typeof type === 'string') {
            type = [type];
        }
        if (coupon && type.indexOf(coupon.coupon_type) >= 0) {
            return '-' + (coupon.discount_amount / 100).toFixed(2);
        } else {
            return '0.00';
        }
    };
}

export function mallCouponRender(_: any, record: any) {
    if (record.mallcoo_v_code) {
        return '-' + (record.discount_amount / 100).toFixed(2);
    } else {
        return '0.00';
    }
}

export function calcTableScrollX(fields: any, w: number) {
    return fields.filter((f: any) => f.width).reduce((memo: number, f: any) => memo + f.width, w);
}

export function priceCustomRender(value: any) {
    value = value || 0;
    return (value / 100).toFixed(2);
}

export function genderCustomRender(value: any) {
    let gender: string;
    if (value === 1) {
        gender = '男';
    } else if (value === 2) {
        gender = '女';
    } else {
        gender = '未知';
    }
    return gender;
}

export function officialAccountCustomRender(value: any){
    let test: string;
    if (value === 'wsf') {
        test = '微商服';
    } else if (value === 'sht') {
        test = '商户通';
    } else if (value === '95016') {
        test = '95016客服';
    } else {
        test = value;
    }
    return test;
}
export function shtStatusCustomRender(value: any){
    let test: string;
    if (value === 1) {
        test = '开通';
    } else if (value === 0) {
        test = '关闭';
    } else {
        test = '未知';
    }
    return test;
}
export function shtBindCustomRender(value: any){
    let test: string;
    if (value === 1) {
        test = '已绑定';
    } else if (value === 2) {
        test = '已解绑';
    } else {
        test = '未知';
    }
    return test;
}

export function defaultCustomRender(value: any) {
    return (value === undefined || value === null || value === '') ? '-' : value;
}

export function zeroCustomRender(value: any) {
    return (value === undefined || value === null || value === '') ? '0' : value;
}

export function isTrueEmpty(obj: any) {
    return obj === undefined || obj === null || obj === '' || isNaN(obj);
}

export function getValueByPath(value: any, path: any[]): any {
    if (path.length === 0) {
        return value;
    }
    return getValueByPath(value[path[0]], path.slice(1));
}

export function timeRangeFormat(timeRange: Moment[]) {
    return {
        start_time: timeRange && timeRange[0] && timeRange[0].format('YYYY-MM-DD HH:mm:ss'),
        end_time: timeRange && timeRange[1] && timeRange[1].format('YYYY-MM-DD HH:mm:ss')
    };
}

export function normColumns(columns: any, fields: any, sortFields: any) {

    let filtedColumns = columns.filter((c: any) => {
        return fields[c.dataIndex];
    });

    const isAllFixed = filtedColumns.reduce((memo: boolean, c: any) => {
        return memo && c.fixed;
    }, true);

    if (isAllFixed) {
        filtedColumns = filtedColumns.map((c: any) => ({
            ...c,
            fixed: undefined
        }));
    }

    sortBy(filtedColumns, ['fixed', 'dataIndex'], {
        fixed: ['left', undefined, 'right'],
        dataIndex: sortFields
    });

    return filtedColumns;
}


export function queryToHumanReadable(query: any, map: any) {
    function parse(query: any) {
        const nodes: any = [];
        Object.keys(query).forEach(async (key: any) => {
            if (map[key]) {
                const schema: any = map[key];
                let parser: any = null;
                if (typeof schema === 'string') {
                    parser = plainTextNode;
                } else if (schema.values) {
                    parser = enumNode;
                } else if (schema.range) {
                    parser = rangeNode;
                } else if (schema.callback) {
                    parser = actionNode;
                }
                await parser({
                    key,
                    value: query[key]
                }, schema, nodes);
            }
        });
        return nodes;
    }

    function plainTextNode(query: any, schema: any, nodes: any) {
        nodes.push({
            name: schema,
            value: query.value
        });
    }

    function enumNode(query: any, schema: any, nodes: any) {
        nodes.push({
            name: schema.name,
            value: schema.values[query.value]
        });
    }

    function rangeNode(query: any, schema: any, nodes: any) {
        const node = nodes.find((n: any) => n.name === schema.name);
        const formatter: any = schema.formatter || function(i: any) {return i;};
        if (node) {
            node.value = node.value.replace('#', formatter(query.value));
        } else {
            if (schema.range === 'from') {
                nodes.push({
                    name: schema.name,
                    value: formatter(query.value) + ' 至 #'
                });
            } else {
                nodes.push({
                    name: schema.name,
                    value: '# 至 ' + formatter(query.value)
                });
            }
        }
    }

    async function actionNode(query: any, schema: any, nodes: any) {
        const ret = await request({
            ...schema.options,
            data: schema.getParams(query.value)
        });
        const value = schema.callback(query.value, ret);
        nodes.push({
            name: schema.name,
            value
        });
    }

    return parse(query);
}

export function integerFormat(value: number) {
    if (!value) {
        return '0';
    }
    const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
    return intPartFormat;
}

export function floatFormat(value: number) {
    if (!value) {
        return '0.00';
    }
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function flatAndUniqueMaterialsTerminals(materialsTerminals: string[][]) {
    const ret = new Set(materialsTerminals.reduce((memo, terminals) => {
        return memo.concat(terminals);
    }, []));
    return Array.from(new Set(ret));
}
export function unionb2cStatusCustomRender(value: any) {
    let status: string;
    if (value === 1) {
        status = '生效';
    } else if (value === 2) {
        status = '失效';
    } else {
        status = '删除';
    }
    return status;
}
export function timeCustomRender(value: any){
    let time : string;
    if(value){
        if(14 === value.length){
            time = value.substring(0,4)+"-"+value.substring(4,6)+"-"+value.substring(6,8)+" "+value.substring(8,10)+":"+value.substring(10,12)+":"+value.substring(12,14)
        }else if(value.length === 8){
            time = value.substring(0,4)+"-"+value.substring(4,6)+"-"+value.substring(6,8)
        }else{
            time = value;
        }
    }else{
        time = '-';
    }
    return time;
}
export function taskActivityStatus(value: any) {
    let status: string;
    if (value === '1') {
        status = '完成';
    } else {
        status = '处理中';
    }
    return status;
}
export function bankActivityStatus(value: any) {
    let status: string;
    if (value === 1) {
        status = '已失效';
    } else {
        status = '已生效';
    }
    return status;
}
export function bankTemplateType(value: any) {
    let status: string;
    if (value === 'OPEN_CARD') {
        status = '开卡活动';
    } else if (value === 'MARKET_ACT') {
        status = '营销活动';
    }else if (value === 'FEE_SUBSIDY') {
        status = '手续费补贴';
    }else {
        status = '存款奖励';
    }
    return status;
}
export function bankActivityMerStatus(value: any) {
    let status: string;
    if (value === 'online') {
        status = '已生效';
    } else {
        status = '已失效';
    }
    return status;
}

export function templetstatusRender(value: any) {
    let status: string;
    if (value === 'OFF') {
        status = '已失效';
    } else if (value === 'ON') {
        status = '已生效';
    } else {
        status = '删除';
    }
    return status;
}
export function templetTypeRender(value: any) {
    let status: string;
    if (value === 'OPEN_CARD') {
        status = '开卡活动';
    } else if (value === 'MARKET_ACT') {
        status = '营销活动';
    }  else if (value === 'FEE_SUBSIDY') {
        status = '手续费补贴';
    }else {
        status = '存款奖励';
    }
    return status;
}

/**
 * 判断数据有无重复数据
 * @param target
 * @returns boolean
 */
export function isRepeatArr<T>(target: T[]): boolean {
    const preLength: number = target.length;
    const curLength: number = Array.from(new Set(target)).length;
    if (preLength === curLength) {
        return false;
    } else {
        return true;
    }
}
/**
 * 查找出数组中重复的数据
 * @param target
 * @returns
 */
export function findRepeatData<T>(target: T[]): any {
    const tempArr = target.sort();
    for (let i = 0; i < tempArr.length - 1; i++) {
        if (tempArr[i] === tempArr[i + 1]) {
          return tempArr[i];
        }
    }
}
