
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {request_yx} from '@/utils';

    @Component({
        components: {}
    })
    class Role extends Vue {

        @Prop()
        public value!: string;

        public items = [];

        public loading = false;

        public async created() {
            this.fetch();
        }

        public async fetch() {
            try {
                this.items = [];
                this.loading = true;
                const ret = await request_yx({
                    api: '/console/activity/subsidyRule/getBankList',
                    data: null
                });
                const list = ret;
                this.items = list.map((i: any) => ({
                    name: i.name
                }));
            } catch (e) {
                    this.$message.error('获取列表失败, 请联系技术人员!');
            } finally {
                this.loading = false;
            }
        }

        public dropdownVisibleChange(open: number) {
            console.log('on dropdownVisibleChange:', open);
            if (open) {
                this.fetch();
            }
        }

        public data() {
            return {
                dataSource: []
            };
        }

        public handleChange(value: string) {
            this.$emit('change', value);
        }

        public filterOption(input: string, option: any) {
            return (
                option.componentOptions.children[0].text
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
            );
        }
    }

    export default Role;
