import {request_yx,downloadRequest_yx} from '@/utils';

export async function search(params: {
    query: any;
}) {

    try {
        return await request_yx({
            api: '/console/activity/subject/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function create(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subject/add',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function editAct(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subject/edit',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}