
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import {queryToHumanReadable} from '@/utils';
    import preferences from './preferences';
    import PRIVILEGE from '@/common/privileges';
    import {Show} from '@/components/Auth';
     const humanReadableQuerySchema = {
        name: '活动名称',
        platActivity: '批次Id',
        startTime:{
            name: '消耗时间',
            range: 'from'
        },
        endTime:{
            name: '消耗时间',
            range: 'to'
        }
    };
    @Component({
        components: {
            SearchForm,
            Actions,
            SearchTable,
            SearchCommonLayout,
            FieldsFilter,
            Show
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.hexiaoManageSearchPage.fields,
                sortArr: (state: any) => state.hexiaoManageSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.hexiaoManageSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class RolePage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'hexiaoManageSearchPage';
         public PRIVILEGE = PRIVILEGE;
        public data() {
            return {
                columns
            };
        }

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/hexiao/search' + this.getters('queryString')()
            });
        }

        public created() {

            // this.registerStore();

            // this.dispatch('initQuery', {
            //     ...this.$route.query
            // });
        }

        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }



        private handleFieldChange(e: any) {
            this.$store.commit('hexiaoManageSearchPage/setFields', e);
        }
        private handleFieldSort(e: any) {
            preferences.setSortFields(e);
            this.$store.commit('hexiaoManageSearchPage/sortFields', e);
        }
    }
