
    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import {request, requestStream, readStream} from '@/utils';
    import { VNode } from 'vue';

    @Component<ConflictModal>({
        components: {},
        computed: {
            // ...mapState({
            //     count_conflict_loading: (state: any) => state.adCreatePage.count_conflict_loading,
            //     conflict_count: (state: any) => state.adCreatePage.conflict_count,
            //     data_source(state: any) {
            //         return state.adCreatePage.conflict_fetching ? [] : state.adCreatePage.conflict_merchants;
            //     },
            //     conflict_fetching: (state: any) => state.adCreatePage.conflict_fetching,
            //     conflict_cursor: (state: any) => state.adCreatePage.conflict_cursor,
            //     merchant_nos_total: (state: any) => state.adCreatePage.merchant_nos_total
            // })

            dataSource() {
                let list = this.conflictFetching ? [] : this.conflictMerchants;
                if (this.searchMerchantName) {
                    list = list.filter((item: any) => {
                        return (new RegExp(this.searchMerchantName)).test(item.name);
                    });
                }

                if (this.searchMerchantNo) {
                    list = list.filter((item: any) => {
                        return this.searchMerchantNo === item.merchant_no;
                    });
                }

                if (this.searchOfflineTerminalId) {
                    list = list.filter((item: any) => {
                        return this.searchOfflineTerminalId === item.offline_terminal_id;
                    });
                }
                return list;
            }
        }
    })
    class ConflictModal extends Vue {

        public query: any;

        public changeHandler: any;

        public selectedRowKeys: any = []

        public conflictCounting = false;

        public conflictCount = 0;

        public conflictFetching = false;

        public conflictMerchants: any = [];

        public conflictCursor = 0;

        public merchantTotal = 0;

        public tipVisible = false;

        public selectorVisible = false;

        public title: VNode | null = null;

        public searchMerchantName = '';

        public searchMerchantNo = '';

        public searchOfflineTerminalId = '';

        public form: any;

        public created() {
            this.title = <a-table class="conflict-table-th" columns={[{
                title: '广告名',
                
            }, {
                title: '上架时间',
                width: 200,
            }, {
                title: '下架时间',
                width: 200,
            }]}/>
            this.form = this.$form.createForm(this);
        }

        public async count(query: any, changeHandler: any) {
            this.query = query;
            this.changeHandler = changeHandler;
            try {
                this.conflictCounting = true;
                this.conflictCount = 0;
                this.merchantTotal = 0;
                const {conflict_count, total} = await request({
                    method: 'POST',
                    api: '/console/ad/plan/conflict_merchants/count',
                    data: query
                });
                this.conflictCount = conflict_count;
                this.merchantTotal = total;
                if (conflict_count > 0) {
                    this.tipVisible = true;
                } else {
                    changeHandler('NO_CONFLICT', total);
                    // this.$emit('change', 'NO_CONFLICT');
                }
            } catch (e) {
                const error: any = new Error('提交失败, 请联系技术人员!');
                error.silent = e.is403;
                if (!error.silent) {
                    this.$message.error(error.message);
                }
            } finally {
                this.conflictCounting = false;
            }
        }

        public async handlePartialReplace() {
            this.selectorVisible = true;
            this.tipVisible = false;
            this.selectedRowKeys = [];
            this.conflictFetching = true;
            this.conflictMerchants = [];
            this.conflictCursor = 0;

            try {
                readStream(await requestStream({
                    method: 'POST',
                    api: '/console/ad/plan/conflict_merchants/list',
                    data: this.query
                }), {
                    onProgress: (lines: any) => {
                        this.conflictMerchants = [
                            ...this.conflictMerchants,
                            ...lines.filter(Boolean).map((item: any) => {
                                return {
                                    ...item,
                                    rowKey: item.merchant_no + '#' + item.offline_terminal_id
                                };
                            })
                        ];
                        this.conflictCursor += lines.length;
                    },
                    onFinished: () => {
                        this.conflictFetching = false;
                    },
                    onError: (e: any) => {
                        this.conflictFetching = false;
                        this.$message.error(e.message);
                    }
                });
            } catch (e) {
                this.$message.error(e.message);
            }
        }

        public handleAllReplace() {
            this.tipVisible = false;
            Modal.confirm({
                title: '全部替换确认',
                content: '确定全部替换吗?',
                onOk: () => {
                    this.changeHandler('ALL', this.merchantTotal);
                },
                onCancel: () => {
                    this.tipVisible = true;
                }
            });
        }

        public handleSelectChange(selectedRowKeys: any) {
            this.selectedRowKeys = selectedRowKeys;
        }

        public handleSelectorOk() {
            this.changeHandler(this.selectedRowKeys.map((item: string) => {
                return item.split('#');
            }), this.merchantTotal);
            this.selectorVisible = false;
        }

        public handleSelectorCancel() {
            this.selectorVisible = false;
            this.tipVisible = true;
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                this.searchMerchantName = values.merchant_name;
                this.searchMerchantNo = values.merchant_no;
                this.searchOfflineTerminalId = values.offline_terminal_id;
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }
    }

    export default ConflictModal;
