import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-chart-datatype',
    options: [{
        label: '请选择查询项',
        value: ''
    }, {
        label: '按总券数',
        value: 'coupon_amount'
    }, {
        label: '按已过期',
        value: 'expiration_time_amount'
    }, {
        label: '按发券商户数',
        value: 'store_amount'
    }, {
        label: '按用券会员数',
        value: 'user_amount'
    }, {
        label: '按用券消费笔数',
        value: 'consume_amount'
    }, {
        label: '按用券抵扣金额',
        value: 'deduction_amount'
    }, {
        label: '按用券订单金额',
        value: 'total_amount'
    }]
});
