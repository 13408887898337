export default function check(requires: any, privileges: any) {
    const list: any = [];

    requires.forEach((p: any) => {

        if (p.$or) {
            const orList: any = [];
            p.$or.forEach((por: any) => {
                if ((privileges().indexOf('__SUPER__') >= 0 || privileges().indexOf(por.name) >= 0)) {
                    return;
                } else {
                    orList.push(por);
                }
            });
            if (orList.length === p.$or.length) {
                list.push({
                    name: orList.map((o: any) => o.name).join('_'),
                    text: orList.map((o: any) => o.text).join(' 或 ')
                });
            }
        } else {
            if ((privileges().indexOf('__SUPER__') >= 0 || privileges().indexOf(p.name) >= 0)) {
                return;
            } else {
                list.push(p);
            }
        }
    });

    return list;
}
