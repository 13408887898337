
    import {
        Icon,
        Checkbox,
        Dropdown,
        Menu
    } from 'ant-design-vue';
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import sortBy from 'sort-array';

    interface FieldsFilter {
        visible: boolean;
    }

    @Component({
        components: {
            [Icon.name]: Icon,
            [Checkbox.name]: Checkbox,
            [Checkbox.Group.name]: Checkbox.Group,
            [Dropdown.name]: Dropdown,
            [Menu.name]: Menu,
            [Menu.Item.name]: Menu.Item
        }
    })
    class FieldsFilter extends Vue {
        @Prop()
        public columns!: any;

        @Prop()
        public excludeFields!: string[];

        @Prop()
        public sortArr!: any;

        @Prop()
        public fields!: any;

        public onUpdate(visible: boolean) {
            if (!visible) {
                const menu: any = this.$refs.menu;
                const els = menu.$el.getElementsByClassName('checkbox');
                const sortArr = Array.prototype.map.call(els, (e: any, idx: number, arr: any) => {
                    return e.dataset.key;
                });
                sortArr.push('@@BLANK');
                this.$emit('sort', sortArr);
            }
        }

        private data() {

            const fieldList = this.columns
                .filter(
                    (column: any) =>
                        !column.fixed
                );
            sortBy(fieldList, 'dataIndex', {dataIndex: this.sortArr || []});
            return {
                visible: false,
                fieldList: fieldList.map((column: any) => [column.dataIndex, column.title, column.filtable])
                    .filter((c: any) => c[0] !== '@@BLANK')
            };
        }

        private noop() {
            /** do nothing */
        }

        private handleChange(e: any) {
            this.$emit('change', {
                key: e.target.value,
                checked: e.target.checked
            });
        }
    }

    export default FieldsFilter;
