import {request} from '@/utils';

export interface SaveParams {

    district_id: string;
    name: string;
    name_english?: string;
    logo: string;
    agreement_title?: string;
    agreement_url?: string;
    link_url?: string;
    belong: string;
}


export async function save(params: SaveParams) {

    try {
        return await request({
            method: 'POST',
            api: '/console/district/update',
            data: params
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function detail(id: string) {
    try {
        return await request({
            api: '/console/district/info',
            data: {district_id: id}
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        const error: any = new Error('获取商圈信息失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
