import * as services from './services';
import {Modal} from 'ant-design-vue';
import {flatAndUniqueMaterialsTerminals} from '@/utils';

export default {
    namespaced: true,
    state: {
        id: null,
        loading: false,
        error: null,
        defaultConflicts: [],
        updatedSuccess:false
    },
    mutations: {

        init(state: any) {
            state.id = null;
            state.loading = false;
            state.error = null;
        },
        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
        setUpdatedSuccess(state: any, updatedSuccess: any) {
            state.updatedSuccess = updatedSuccess;
        },
        created(state: any, id: string) {
            state.id = id;
        }
    },
    actions: {
        /**
         * 新增
         * @param context action上下文
         */
        async create(context: any, payload: any) {
            context.commit('setUpdatedSuccess',false);
            context.commit('setLoading', true);
            try {
                context.commit('created', await services.create({
                    ...payload 
                }));
                context.commit('setUpdatedSuccess',true);
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        },
        /**
         * 编辑活动
         * @param context action上下文
         */
        async editAct(context: any, payload: any) {
            context.commit('setUpdatedSuccess',false);
            context.commit('setLoading', true);
            try {
                context.commit('created', await services.editAct({
                    ...payload
                }));
                context.commit('setUpdatedSuccess',true);
            } catch (e) {
                console.info("error editAct")
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }

        }
    },
    getters: {

    }
};
