
    import Index from './index.vue';
    import {Component, Vue} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Index,
            Show
        }
    })
    export default class AuthWrapper extends Vue {
        public PRIVILEGE = PRIVILEGE;
    }
