
    import EditForm from './EditForm.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Show,
            EditForm
        }
    })
    export default class EditPage extends Vue {
        public PRIVILEGE = PRIVILEGE;
        public created() {
            this.$store.dispatch('activityEditPage/initData', this.$route.params.id);
        }

        @Watch('$store.state.activityEditPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }
    }
