var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: null,
            visible: _vm.conflictCounting,
            closable: false,
            footer: null,
          },
        },
        [_vm._v("\n    冲突检测中...\n")]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "选择替换",
            visible: _vm.selectorVisible,
            width: 1200,
            footer: _vm.conflictFetching ? null : undefined,
            closable: _vm.conflictFetching ? false : true,
            destroyOnClose: true,
          },
          on: { ok: _vm.handleSelectorOk, cancel: _vm.handleSelectorCancel },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "ant-advanced-search-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSearch },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "商户名" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["merchant_name"],
                                expression: "['merchant_name']",
                              },
                            ],
                            attrs: { placeholder: "请输入(模糊查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "商户号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["merchant_no"],
                                expression: "['merchant_no']",
                              },
                            ],
                            attrs: { placeholder: "请输入(精确查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "线下终端号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["offline_terminal_id"],
                                expression: "['offline_terminal_id']",
                              },
                            ],
                            attrs: { placeholder: "请输入(精确查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { style: { textAlign: "right" }, attrs: { span: "6" } },
                    [
                      _c("a-form-item", [
                        _c(
                          "div",
                          [
                            _c("a-button", { on: { click: _vm.handleReset } }, [
                              _vm._v(
                                "\n                            重置\n                        "
                              ),
                            ]),
                            _c(
                              "a-button",
                              {
                                style: { marginLeft: "8px" },
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            查询\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-divider"),
          _c("a-table", {
            attrs: {
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys || [],
                onChange: _vm.handleSelectChange,
              },
              locale: {
                emptyText: _vm.conflictFetching
                  ? "正在检测冲突商户号: " +
                    _vm.conflictCursor +
                    "/" +
                    _vm.merchantTotal
                  : "无数据",
              },
              pagination: {
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100"],
                pageSize: 20,
                showTotal: (total) => `总计 ${_vm.conflictCount} 条记录`,
              },
              rowKey: "rowKey",
              columns: [
                {
                  title: "商户名",
                  dataIndex: "name",
                  width: 200,
                },
                {
                  title: "商户号",
                  width: 200,
                  dataIndex: "merchant_no",
                },
                {
                  title: "线下终端号",
                  width: 150,
                  dataIndex: "offline_terminal_id",
                },
                {
                  title: _vm.title,
                  dataIndex: "conflict_plans",
                  scopedSlots: { customRender: "plans" },
                },
              ],
              dataSource: _vm.dataSource,
            },
            scopedSlots: _vm._u([
              {
                key: "plans",
                fn: function (plans) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("a-table", {
                        staticClass: "conflict-plans-table",
                        attrs: {
                          showHeader: false,
                          rowKey: "id",
                          pagination: false,
                          columns: [
                            {
                              title: "广告名称",
                              dataIndex: "title",
                            },
                            {
                              title: "上架时间",
                              width: 200,
                              dataIndex: "start_time",
                            },
                            {
                              title: "下架时间",
                              width: 200,
                              dataIndex: "end_time",
                            },
                          ],
                          dataSource: plans,
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "提示" },
          model: {
            value: _vm.tipVisible,
            callback: function ($$v) {
              _vm.tipVisible = $$v
            },
            expression: "tipVisible",
          },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { key: "all", on: { click: _vm.handleAllReplace } },
                [_vm._v("全部替换")]
              ),
              _c(
                "a-button",
                {
                  key: "partial",
                  attrs: { type: "primary" },
                  on: { click: _vm.handlePartialReplace },
                },
                [_vm._v("选择替换")]
              ),
            ],
            1
          ),
          _vm._v(
            "\n    本次提交的广告配置中分配的商终, 有" +
              _vm._s(_vm.conflictCount) +
              "个商终已有广告，是否替换？\n"
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }