
    import {Component, Vue} from 'vue-property-decorator';
    import newbieSearchPage from './Newbie/SearchPage/index.vue';
    import LuckyPacketSearchPage from './LuckyPacket/SearchPage/index.vue';
    import OrientationSearchPage from './Orientation/SearchPage/index.vue';
    import MallSearchPage from './Mall/SearchPage/index.vue';
    import OtherSearchPage from './Other/SearchPage/index.vue';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            newbieSearchPage,
            LuckyPacketSearchPage,
            OrientationSearchPage,
            MallSearchPage,
            OtherSearchPage,
            Show
        },
        computed: {}
    })
    class Index extends Vue {

        public activeKey = 'newbie';

        public PRIVILEGE = PRIVILEGE;

        public created() {
            if (this.$route.path === '/console/marketing/newbie-data/search') {
                this.activeKey = 'newbie';
            } else if (this.$route.path === '/console/marketing/lucky-packet-data/search') {
                this.activeKey = 'lucky_packet';
            } else if (this.$route.path === '/console/marketing/orientation-data/search') {
                this.activeKey = 'orientation';
            } else if (this.$route.path === '/console/marketing/mall-data/search') {
                this.activeKey = 'mall';
            } else if (this.$route.path === '/console/marketing/other-data/search') {
                this.activeKey = 'other';
            }
        }

        public handleChange(key: string) {
            this.activeKey = key;

            if (key === 'newbie') {
                this.$router.push({
                    path: '/console/marketing/newbie-data/search?discount_coupon_type=NEWBIE&r='
                        + Math.random(),
                });
            } else if (key === 'lucky_packet') {
                this.$router.push({
                    path: '/console/marketing/lucky-packet-data/search?discount_coupon_type=LUCKY_PACKET&r='
                        + Math.random(),
                });
            } else if (key === 'orientation') {
                this.$router.push({
                    path: '/console/marketing/orientation-data/search?discount_coupon_type=ORIENTATION&r='
                        + Math.random(),
                });
            } else if (key === 'mall') {
                this.$router.push({
                    path: '/console/marketing/mall-data/search?discount_coupon_type=MALL&r='
                        + Math.random(),
                });
            } else {
                this.$router.push({
                    path: '/console/marketing/other-data/search?discount_coupon_type=OTHER&r='
                        + Math.random(),
                });
            }
        }
    }

    export default Index;
