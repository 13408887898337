var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c(
        "a-table",
        {
          ref: "table",
          staticClass: "districtCode-list-search-table",
          attrs: {
            size: "middle",
            rowKey: "id",
            bordered: "",
            rowSelection: _vm.rowSelection,
            columns: _vm.fields,
            dataSource: _vm.list,
            locale: {
              emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
            },
            scroll: {
              x: _vm.calcTableScrollX(_vm.fields, 0),
              y: _vm.fixed || undefined,
            },
            pagination: {
              total: _vm.total,
              pageSize: _vm.pageSize,
              current: _vm.page,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: ["20", "50", "100"],
              showTotal: (total) => `总计 ${total} 条记录`,
              onChange: _vm.handlePageChange,
              onShowSizeChange: _vm.handlePageSizeChange,
            },
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (value, record) {
                return [
                  _c(
                    "a-button",
                    {
                      attrs: { slot: "action", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: `/console/districtCode/edit/${record.id}`,
                          })
                        },
                      },
                      slot: "action",
                    },
                    [_vm._v("修改")]
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v("\n`           ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }