
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import store from './newstore';

    @Component<RolePage>({
        components: {
            Actions,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields() {
                    return [] || this.state().fields;
                }
            })
        }
    })
    export default class RolePage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'roleSearchPage';

        public data() {
            return {
                columns
            };
        }

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/account/role/search' + this.getters('queryString')()
            });
        }

        public created() {

            // this.registerStore();

            // this.dispatch('initQuery', {
            //     ...this.$route.query
            // });
        }

        public registerStore() {
            if (!this.state()) {
                this.$store.registerModule(
                    [this.storeName],
                    store
                );
                this.$watch(function() {
                    return this.$store.state[this.storeName].error;
                }, this.errorListener);
            }
        }

        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        // @Watch('$route')
        // public routeListener() {
        //     if (!this.active) {
        //         return;
        //     }
        //     this.dispatch('initQuery', this.$route.query);
        //     // const {
        //     //     page,
        //     //     page_size,
        //     //     ...rest
        //     // }: any =  this.$route.query;

        //     // this.commit('setPagination', {
        //     //     page: (page && parseInt(page, 10)) || 1,
        //     //     pageSize: (page_size && parseInt(page_size, 10)) || 20
        //     // });
        //     // this.commit('resetQuery', rest);
        //     // this.dispatch('search');
        // }

        private handleFieldChange(e: any) {
            this.commit('setFields', e);
        }
    }
