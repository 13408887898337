var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.STORE.BIND_SN] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "关联SN",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form,
          },
          on: { ok: _vm.handleSubmit, cancel: _vm.handleClose },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              {
                                pattern: /^[0-9a-zA-Z\u4e00-\u9fa5]{0,10}$/,
                                message: "最多可输入10个字符，不能包含特殊字符",
                              },
                            ],
                          },
                        ],
                        expression:
                          "['name',\n                        {rules: [{pattern: /^[0-9a-zA-Z\\u4e00-\\u9fa5]{0,10}$/,  message: '最多可输入10个字符，不能包含特殊字符'}]}]",
                      },
                    ],
                    attrs: { placeholder: "请输入" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "SN号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "sn",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                            ],
                          },
                        ],
                        expression:
                          "[\n                            'sn',\n                            {rules: [{required: true, message: '请填写必填项!'}]}\n                        ]",
                      },
                    ],
                    attrs: { placeholder: "请输入" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }