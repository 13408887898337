var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("a-select", {
        attrs: {
          options: _vm.options,
          placeholder: "请选择",
          value: _vm.options.length === 0 ? null : _vm.value,
          getPopupContainer: (trigger) => trigger.parentNode,
          allowClear: "",
        },
        on: { change: _vm.handleChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }