
    import {AutoComplete} from 'ant-design-vue';
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {request} from '@/utils';

    interface BizDistrict {
        options: any[];
        loading: boolean;
    }

    @Component({
        components: {
            [AutoComplete.name]: AutoComplete
        }
    })
    class BizDistrict extends Vue {
        @Prop()
        public value!: string;

        @Prop({default: false})
        public showTip!: boolean;

        public async created() {
            this.loading = true;
            try {
                const ret = await request({
                    api: '/console/district/simple_list',
                    data: {page: 1, page_size: 1000}
                });

                const list = ret.list;

                this.options = list.map((data: any) => {
                    return {
                        value: data.id,
                        label: data.name
                    };
                });
            } catch (e) {
                if (!e.is403) {
                    this.$message.error('获取商圈列表失败, 请联系技术人员!');
                }
                // do nothing
            } finally {
                this.loading = false;
            }
        }

        public data() {
            return {
                options: [],
                loading: false
            };
        }

        public handleChange(value: string) {
            this.$emit('change', value);
        }
    }

    export default BizDistrict;
