
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<AdAssignMerchants>({})
class AdAssignMerchants extends Vue {

    public text: string = '';
    public error: string = '';
    public visible = false;

    @Prop()
    public value!: string[][];

    public handleTriggerClick() {
        this.visible = true;
        this.text = this.value && this.value.map(i => i.join(',')).join(';');
    }

    public cancelHandler() {
        this.error = '';
    }
    public okHandler() {
        this.text = this.text.toString().replace(/\s+/g, '');
        const aus: string[][] = Array.from(new Set(this.text.split(/[;]/)
            .filter(Boolean))).map(item => (item as string).split(/[,，]/));
        const errors = this.checkAus(aus); 
        if (errors.length > 0) {
            this.error = '无效输入: ' + errors.join(',');
            return;
        } else {
            this.error = '';
        }
        this.$emit('change', aus);
        this.visible = false;
    }
   
    private checkAus(aus: string[][]): string[] {
        const errors = aus.reduce((memo: string[], item: string[]) => {
            if (item.length !== 2) {
                return memo.concat(item.join(','));
            }
            if (!/^[A-Za-z\d]{15}$/.test(item[0]) || !/^[A-Za-z\d]{8}$/.test(item[1])) {
                return memo.concat(item.join(','));
            } else {
                return memo;
            }
        }, []);
        return errors;
    }
}
export default AdAssignMerchants;
