import {downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'lucky_packet',
        api: '/console/marketing/data/excel'
    });
}
