import {request_yx} from '@/utils';
import config from '@/config';
export async function search(params: {
    query: any;
}) {

    try {
        console.log(params)
        return await request_yx({
            method: 'POST',
            api: '/console/districtCode/query',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function update(params: any){
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/districtCode/update',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('修改失败！请联系挤术人员！');
        e.silent = e.is403;
        throw e;
    }
}
export async function getInfo(id: string) {
    try {
        return await request_yx({
            method: 'GET',
            api: '/console/districtCode/getInfo',
            data: {
                id: id
            }
        });
    } catch (e) {
        const error: any = new Error('获取失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}




