import {request_yx} from '@/utils';

interface IParams {
    pageSize: number;
    page: number;
    mobile: string;
}

export async function search(params: IParams) {

    try {
        return await request_yx({
            api: '/console/store/open/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取店铺白名单列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteMembers(params: {ids: string}) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/store/open/delete',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function seeMember(params: {id: string}) {
    try {
        return await request_yx({
            api: '/console/store/open/mobile/find',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function create(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/store/open/create',
            data: {
                ...params
            }
        });
    } catch (e) {
        console.log('eeeeeeeeeeee',e)
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        // hack，下一版本优化
        // if (e.message === '网络错误') {
        //     e.message = '超时，请检查网络!';
        // } else {
        //     e.message = '提交失败，或联系技术人员!';
        // }
        const error: any = new Error(e);
        error.silent = e.is403;
        throw error;
    }
}
