import {request, downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'order',
        api: '/console/order/list/excel'
    });
}

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/order/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取订单列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function sum(params: any) {
    try {
        return await request({
            api: '/console/order/sum',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取金额求和失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
