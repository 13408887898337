export default {
    grid: {
        left: '5%',
        right: '5%',
        top: 100,
        width: '90%'
    },
    legend: {
        data: ['进店红包', '拼手气红包', '定向券', '商场券', '商圈券'],
        // type: 'scroll',
        width: '40%',
        left: 10,
        itemWidth: 10,
        itemHeight: 10,
        selectedMode: 'single'
    },
    toolbox: {
        top: 5,
        right: 20,
        feature: {
            saveAsImage: {
                name: '用券统计',
                type: 'jpeg',
                title: '导出图片',
                pixelRatio: 2,
                excludeComponents: ['toolbox', 'dataZoom']
            }
        }
    },
    // dataZoom: {
    //     type: 'slider',
    //     filterMode: 'filter'
    // },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            axis: 'x',
            snap: true
        },
        backgroundColor: 'rgba(245, 245, 245, 0.8)',
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        textStyle: {
            color: '#000'
        },
        extraCssText:  'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' 
    },
    xAxis: {
        type: 'category',
        axisTick: {
            alignWithLabel: true
        },
        axisLabel: {
            rotate: 45
        },
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: '进店红包',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '拼手气红包',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '定向券',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        },
        {
            name: '商场券',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10 + 100;
            }
        },
        {
            name: '商圈券',
            type: 'bar',
            barMaxWidth: 30,
            data: [],
            label: {
                normal: {
                    distance: 15,
                    rotate: 45,
                    offset: [10, 0],
                    show: true,
                    position: 'top'
                }
            },
            animationDelay(idx: number) {
                return idx * 10;
            }
        }
    ],
    animationEasing: 'elasticOut',
    animationDelayUpdate(idx: number) {
        return idx * 5;
    }
};
