var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "span",
        { on: { click: _vm.handleTriggerClick } },
        [
          _vm._t("trigger", function () {
            return [_c("a-button", [_vm._v("白名单")])]
          }),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "广告白名单", width: 1010, footer: null },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "ant-advanced-search-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSearch },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "商户名" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["merchant_name"],
                                expression: "['merchant_name']",
                              },
                            ],
                            attrs: { placeholder: "请输入(模糊查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "商户号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["merchant_no"],
                                expression: "['merchant_no']",
                              },
                            ],
                            attrs: { placeholder: "请输入(精确查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "线下终端号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["offline_terminal_id"],
                                expression: "['offline_terminal_id']",
                              },
                            ],
                            attrs: { placeholder: "请输入(精确查询)" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { style: { textAlign: "right" }, attrs: { span: "12" } },
                    [
                      _c("a-form-item", [
                        _c(
                          "div",
                          [
                            _c("a-button", { on: { click: _vm.handleReset } }, [
                              _vm._v(
                                "\n                                重置\n                            "
                              ),
                            ]),
                            _c(
                              "a-button",
                              {
                                style: { marginLeft: "8px" },
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                查询\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-divider", { staticStyle: { margin: "0 0 20px 0" } }),
          _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _c(
                "a-col",
                { attrs: { span: "2" } },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.addVisible = true
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "2" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        loading: _vm.removing,
                        disabled: _vm.selectedRowKeys.length === 0,
                      },
                      on: { click: _vm.handleRemove },
                    },
                    [_vm._v("移除")]
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "2" } },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$refs.table.search()
                        },
                      },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("BulkSelector", {
                ref: "table",
                attrs: {
                  action: "/console/ad/ad_block/list",
                  columns: [
                    {
                      title: "商户名称",
                      dataIndex: "name",
                    },
                    {
                      title: "商户号",
                      dataIndex: "merchant_no",
                      width: 300,
                    },
                    {
                      title: "线下终端号",
                      dataIndex: "offline_terminal_id",
                      width: 200,
                    },
                  ],
                  rowKey: "id",
                  query: _vm.query,
                },
                model: {
                  value: _vm.selectedRowKeys,
                  callback: function ($$v) {
                    _vm.selectedRowKeys = $$v
                  },
                  expression: "selectedRowKeys",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "添加", confirmLoading: _vm.adding },
          on: { ok: _vm.handleAddOk },
          model: {
            value: _vm.addVisible,
            callback: function ($$v) {
              _vm.addVisible = $$v
            },
            expression: "addVisible",
          },
        },
        [
          _c("label", [_vm._v("商户号:")]),
          _c("a-input", {
            staticStyle: { margin: "10px 0" },
            model: {
              value: _vm.addMerchantNo,
              callback: function ($$v) {
                _vm.addMerchantNo = $$v
              },
              expression: "addMerchantNo",
            },
          }),
          _c("label", [_vm._v("线下终端号:")]),
          _c("a-input", {
            staticStyle: { "margin-top": "10px" },
            model: {
              value: _vm.addOfflineTerminalId,
              callback: function ($$v) {
                _vm.addOfflineTerminalId = $$v
              },
              expression: "addOfflineTerminalId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }