import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';
import CreateMerSubsidyRule from './merSubsidyRule/CreatePage/index.vue';
const route: RouteConfig[] = [
    
    {
        path: 'createMerSubsidyRule',
        name: '创建商户补贴规则',
        component: CreateMerSubsidyRule,
    },
    {
        path: 'merSubsidyRule/search',
        name: '商户补贴规则',
        component: Index,
    },
];

export default route;
