
    import {Component, Prop, Provide, Watch, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import SearchTable from './SearchTable.vue';
    import PRIVILEGE from '@/common/privileges';
    import store from './store';
    import {getValueByPath} from '@/utils';

    interface SNModal {
        visible: boolean;
    }

    @Component({
        components: {
            Click,
            SearchTable
        }
    })
    class SNModal extends Vue {
        @Prop()
        // tslint:disable-next-line:variable-name
        public store_id!: string;
        @Prop()
        public merchant_no!: string;
        @Prop()
        public offline_terminal_id!:string;

        public visible = false;

        public cacheStoreId!: any;

        public PRIVILEGE = PRIVILEGE;

        @Provide()
        public state() {
            return getValueByPath(this.$store.state, [
                'shopSearchPage',
                this.cacheStoreId + '@snModal'
            ]);
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'shopSearchPage/' +
                this.cacheStoreId +
                '@snModal/' +
                action,
                payload
            );
        }

        @Provide()
        public commit(action: string, payload?: any) {
            this.$store.commit(
                'shopSearchPage/' +
                this.cacheStoreId +
                '@snModal/' +
                action,
                payload
            );
        }

        public errorListener(error: any) {
            if (error) {
                this.$message.error(error.message);
            }
        }

        @Watch('visible')
        public handleModalOpen() {
            sessionStorage.setItem('snModal_merchant_no', this.merchant_no);
            sessionStorage.setItem('snModal_offline_terminal_id', this.offline_terminal_id)
            if (!this.visible || !this.store_id) {
                return;
            }

            this.cacheStoreId = this.store_id;

            if (!this.state()) {
                this.$store.registerModule(
                    ['shopSearchPage', this.store_id + '@snModal'],
                    store()
                );
                //this.commit('setQuery', {store_id: this.store_id});
                this.commit('setQuery', {merchant_no: this.merchant_no,offline_terminal_id:this.offline_terminal_id});
                this.$watch(function() {
                    return (
                        this.$store.state.shopSearchPage[
                        this.store_id + '@snModal'
                            ] &&
                        this.$store.state.shopSearchPage[
                        this.store_id + '@snModal'
                            ].error
                    );
                }, this.errorListener);
            }
            //this.dispatch('resetQuery', {store_id: this.store_id});
            //this.dispatch('resetQuery', {store_id: this.store_id});
            this.dispatch('resetQuery', {merchant_no: this.merchant_no,offline_terminal_id:this.offline_terminal_id});
                
        }

        public handleClose() {
            this.visible = false;
            sessionStorage.removeItem('snModal_merchant_no');
            sessionStorage.removeItem('snModal_offline_terminal_id');
        }

        public handleOK() {
            this.visible = false;
        }

        public onClick() {
            this.visible = true;
        }
    }

    export default SNModal;
