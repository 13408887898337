import {defaultCustomRender} from '@/utils';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '类型',
        dataIndex: 'type_name',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '值',
        dataIndex: 'type_value',
        align: 'center',
        width: 200,
        scopedSlots: {customRender: 'mobile'}
    },
    {
        title: '创建时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'user',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '备注',
        dataIndex: 'remark',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    }
];
