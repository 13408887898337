import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-excel-range-type',
    options: [{
        label: 'Excel导出',
        value: ''
    }, {
        label: '自定义-微信',
        value: 'WECHAT'
    }, {
        label: '自定义-微信H5',
        value: 'WECHATH5'
    }, {
        label: '自定义-支付宝',
        value: 'ALIPAY'
    }, {
        label: '自定义-云闪付',
        value: 'UQRCODEPAY'
    }, {
        label: '自定义-华为钱包',
        value: 'HWWALLET'
    }
    // , {
    //     label: '自定义-翼支付',
    //     value: 'YiZhiFu'
    // }
    ]
});
