import {downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'mall',
        api: '/console/marketing/data/mallcoo/excel'
    });
}
