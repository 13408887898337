
import { Component, Vue } from 'vue-property-decorator';
import OpenSearchPage from './Open/SearchPage/index.vue';
import DraftSearchPage from './Draft/SearchPage/index.vue';
import StopSearchPage from './Stop/SearchPage/index.vue';
import Unionb2cSearchPage from './unionb2c/SearchPage/index.vue';
import UnionCouponSearchPage from './unionCoupon/SearchPage/index.vue';
import MerSubsidyActSearchPage from './merSubsidyAct/SearchPage/index.vue';
import ZhongxinSearchPage from './zhongxin/SearchPage/index.vue';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        OpenSearchPage,
        DraftSearchPage,
        StopSearchPage,
        Unionb2cSearchPage,
        ZhongxinSearchPage,
        UnionCouponSearchPage,
        MerSubsidyActSearchPage,
        Show
    },
    computed: {}
})
class Index extends Vue {
    public activeKey = 'open';

    public PRIVILEGE = PRIVILEGE;
    public tabkey = 'wx';

    public created() {
        if (this.$route.path === '/console/activity/open/search') {
            this.activeKey = 'open';
        } else if (this.$route.path === '/console/activity/draft/search') {
            this.activeKey = 'draft';
        } else if (this.$route.path === '/console/activity/stop/search') {
            this.activeKey = 'stop';
        }else if (this.$route.path === '/console/activity/unionb2c/search') {
            this.activeKey = 'unionb2c';
        }else if (this.$route.path === '/console/activity/wxCashCouponActivity/search') {
            this.activeKey = 'wxCashCouponActivity';
        }else if (this.$route.path === '/console/activity/unionCoupon/search') {
            this.activeKey = 'unionCoupon';
        }else if (this.$route.path === '/console/activity/merSubsidyAct/search') {
            this.activeKey = 'merSubsidyAct';
        }
    }
    public handleChangePay(key: string){
        console.log("handleChangePay",key);
        
        this.$store.commit("activityManage/setType",key)
    }
    public mounted(){
        console.log("mounted",this.$store.state.activityManage.payType);
        this.tabkey = this.$store.state.activityManage.payType
    }
    public handleChange(key: string) {
        this.activeKey = key;

        if (key === 'open') {
            this.$router.push({
                path:
                    '/console/activity/open/search?' +
                    this.$store.getters[
                        'activitiesOpenSearchPage/queryString'
                    ]()
            });
        } else if (key === 'draft') {
            if (this.$store.getters['activitiesDraftSearchPage/queryString']) {
                this.$router.push({
                    path:
                        '/console/activity/draft/search?' +
                        this.$store.getters[
                            'activitiesDraftSearchPage/queryString'
                        ]()
                });
            } else {
                this.$router.push({
                    path: '/console/activity/draft/search?r=' + Math.random()
                });
            }
        } else {
            if (this.$store.getters['activitiesStopSearchPage/queryString']) {
                this.$router.push({
                    path:
                        '/console/activity/stop/search?' +
                        this.$store.getters[
                            'activitiesStopSearchPage/queryString'
                        ]()
                });
            } else {
                this.$router.push({
                    path: '/console/activity/stop/search?r=' + Math.random()
                });
            }
        }
    }
}

export default Index;
