import {request, downloadRequest} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/export/contract_order',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {
    console.log(params)
    return await downloadRequest({
        data: params,
        prefix: 'contract_order',
        api: '/console/export/contract_order'
    });
}
