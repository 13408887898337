
import {Modal} from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import moment from 'moment';

interface ChangeForm {
    baseForm: any;
    formData: any;
}

@Component<ChangeForm>({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return ( this.formatFormData());
            },
        }),
    },
})
class ChangeForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public baseForm;
    public activeTab: any;

    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public promoType: any;

    public appValue: string = '';
    public cardBinValue: string = '';

    public created() {}

    public data() {
        return {
            activeTab: '1',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            
        };
    }
    public handleNext(e: any) {
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            console.log("9090"+values);
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch('shtSearchPage/updateStatus', values);
                        this.$message.success("提交成功");
                        this.$emit('onCancel');
                    }
                    
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onCancel');
            }
        });
    }
    private formatFormData() {
         const detail = sessionStorage.getItem('sht_detail');
        console.log("0999999"+detail);
        return {
            openId: sessionStorage.getItem('sht_openId'),
            shopNo:sessionStorage.getItem('sht_shopNo'),
            remark:sessionStorage.getItem('sht_remark'),
            officialAccount:sessionStorage.getItem('sht_officialAccount'),
            tradeNotify:sessionStorage.getItem('sht_tradeNotify'),
            billNotify:sessionStorage.getItem('sht_billNotify'),
        };
    }
}

export default ChangeForm;
