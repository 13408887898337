
    import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
    import {responseHandler} from '@/utils';
    import config from '@/config';
    import axios from 'axios';

    @Component({
        components: {}
    })
    class PhoneCode extends Vue {
        public static storage: any = [];

        public static clearStorage() {
            PhoneCode.storage.forEach((s: string) => {
                window.localStorage.removeItem(s);
            });
        }

        @Prop()
        public mobile!: any;
        @Prop({default: 'LOGIN'})
        public sendType!: string;
        @Prop()
        public id!: any;
        @Prop()
        public value!: any;
        @Prop()
        public buttonStyle!: any;

        public loading = false;
        public timer: any = null;
        public seconds = 0; // parseInt(window.localStorage.getItem('code_seconds_' + this.id) || '0', 10);

        constructor() {
            super();
            PhoneCode.storage.push('code_seconds_' + this.id);
        }

        public async handleClick(value: any) {
            this.$emit('send');
        }

        @Watch('mobile')
        public async updateMobileNumber() {
            const mobileNumber = this.mobile.split('_')[0];
            if (!/^\d{11}$/.test(mobileNumber)) {
                return this.$message.error('请输入正确的手机号');
            }
            this.loading = true;
            const {success, code} = await this.requestCode(mobileNumber);
            this.loading = false;
            if (success) {
                this.$emit('change', code);
                this.seconds = 60;
                this.count();
            }
        }

        public async requestCode(mobileNumber: string) {
            try {
                const params = new URLSearchParams();
                params.append('mobile', mobileNumber);
                params.append('param', this.sendType);
                const code = responseHandler(await axios.post(config.BASE_URL + '/console/common/send/captcha', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }));
                // 为了方便测试，在开发环境和测试环境返回验证码，直接填入
                // 生产环境使用短信接收，返回空
                return {
                    success: true,
                    code: process.env.NODE_ENV === 'production' ? '' : code
                };
            } catch (e) {
                if (e.message === 'Network Error') {
                    this.$message.error('网络错误');
                } else if (e.code === 'ECONNABORTED') {
                    this.$message.error('请求超时');
                } else {
                    this.$message.error(e.message);
                }
                return {
                    success: false,
                    code: ''
                };
            }
        }

        // public created() {
        //     this.count();
        // }

        public handleInput(e: any) {
            this.$emit('change', e.target.value);
        }

        public count() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            if (this.seconds > 0) {
                this.timer = setInterval(() => {
                    this.seconds -= 1;
                    // window.localStorage.setItem('code_seconds_' + this.id, String(this.seconds));
                    if (this.seconds === 0) {
                        clearInterval(this.timer);
                    }
                }, 1000);
            }
        }
    }

    export default PhoneCode;
