import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 200,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '批次ID',
        dataIndex: 'batchId',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '优惠ID',
        dataIndex: 'promoId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '优惠类型',
        dataIndex: 'promoType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '优惠金额',
        dataIndex: 'promoAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    } ,
    {
        title: '订单总金额',
        dataIndex: 'orderAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易类型',
        dataIndex: 'tradeType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '支付单号',
        dataIndex: 'payTradeNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '消耗时间',
        dataIndex: 'tradeTime',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '消耗商户号',
        dataIndex: 'subMerId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '设备号',
        dataIndex: 'deviceId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '银行流水号',
        dataIndex: 'bankBill',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '单品信息',
        dataIndex: 'goodsInfo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    blankCell,
];
