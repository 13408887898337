import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '发送名称',
        dataIndex: 'name',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '发送时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '发送条数',
        dataIndex: 'send_count',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '发送成功条数',
        dataIndex: 'success_count',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '空白条数',
        dataIndex: 'no_status_count',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '发送失败条数',
        dataIndex: 'fail_count',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    }
];
