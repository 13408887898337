
    import DetailForm from './DetailForm.vue';
    import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Show,
            DetailForm
        }
    })
    export default class index extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public activityInfo !: any;
        @Prop()
        public paytype !: string;
        public created() {
        }

        @Watch('activityInfo') 
        public async initQueryData(error: any) {
            console.info("zhongxin ", this.activityInfo)
            if(!this.activityInfo){
                return; 
            }
            await this.$store.dispatch('unionCouponSearchPage/initData', {
                promotionId: this.activityInfo.promotionId,
                listType: this.activityInfo.merLimit == '1' ? 'merId' : 'merIdAndTermId', // merLimit: 商户/商终限制标识：0-不限制(暂时不用);1-商户列表;2-商终列表
             });
        }

        @Watch('$store.state.unionCouponSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        public onClose() {
            this.$emit('close');
        }
    }
