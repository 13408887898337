
    import CreateForm from './CreateForm.vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';

    @Component({
        components: {
            CreateForm
        }
    })
    export default class CreatePage extends Vue {

        @Watch('$store.state.bizDistrictCreatePage.created')
        public createdListener() {

            this.$message.success('提交成功!');

            this.$router.push({
                path: '/console/bizDistrict/search' + this.$store.getters['bizDistrictSearchPage/queryString']()
            });
        }

        @Watch('$store.state.bizDistrictCreatePage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }
    }
