

    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import {request} from '@/utils';

    @Component<ShopSmsStatusCount>({ })
    class ShopSmsStatusCount extends Vue {

        
        public sms_switch: string = '';
        public daily_limit: any = 0;
        public loading = false;

        public async created() {
            this.fetch();
        }

        public async fetch() {
            this.loading = true;
            try {
                const data: any = await request({
                    api: '/console/store/sms/info'
                });
                console.log(data)
               
                if(data.daily_limit === '0'){
                     this.daily_limit = '无限制';
                }else if(data.daily_limit === '' || data.daily_limit === null ){
                     this.daily_limit = '无配置';
                }else{
                     this.daily_limit = data.daily_limit;
                }
                if(data.sms_switch === 'True'){
                    this.sms_switch = 'ON';
                }else{
                    this.sms_switch = 'OFF';
                }
                sessionStorage.setItem('sms_switch', this.sms_switch)
            } catch (e) {
                this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }

    }
    export default ShopSmsStatusCount;
