const filters = {
    integerFormat(value: number) {
        if (!value) {
            return '0';
        }
        const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
        return intPartFormat;
    },
    floatFormat(value: number) {
        if (!value) {
            return '0.00';
        }
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    toFixed(value: number, n: number) {
        if (!value) {
            return '';
        }
        return value.toFixed(n);
    },
    dividedBy(value: number, n: number) {
        if (!value) {
            return '';
        }
        return value / n;
    },
    RMB(value: string) {
        if (!value) {
            return '0';
        }
        return '¥' + value;
    }
};

export default filters;
