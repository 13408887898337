
    import {Component, Prop, Vue, Watch, Inject, Provide} from 'vue-property-decorator';
    import Drawer from '@/components/Drawer.vue';
    import SearchTable from './SearchTable.vue';
    import SearchForm from './SearchForm.vue';
    import store from './store';
    import {getValueByPath} from '@/utils';
    import {Show, check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Drawer,
            SearchTable,
            SearchForm,
            Show
        }
    })
    class Member extends Vue {
        @Prop()
        // tslint:disable-next-line:variable-name
        public store_id!: any;

        @Prop()
        public title!: string | number;

        @Inject()
        public privileges!: any;

        public cacheStoreId!: any;
        public PRIVILEGE = PRIVILEGE;

        @Provide()
        public state() {
            return getValueByPath(this.$store.state, [
                'shopSearchPage',
                this.cacheStoreId + '@employee'
            ]);
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'shopSearchPage/' + this.cacheStoreId + '@employee/' + action,
                payload
            );
        }

        @Provide()
        public commit(action: string, payload?: any) {
            this.$store.commit(
                'shopSearchPage/' + this.cacheStoreId + '@employee/' + action,
                payload
            );
        }

        public created() {
            // if (!this.state()) {
            //     this.$store.registerModule(
            //         ['shopSearchPage', this.store_id + '@member'],
            //         store()
            //     );
            //     this.commit('setQuery', {store_id: this.store_id});
            //     this.$watch(function() {
            //         return this
            //             .$store.state.shopSearchPage[this.store_id + '@member'].error;
            //     }, this.errorListener);

            // }
        }

        public errorListener(error: any) {
            if (error) {
                this.$message.error(error.message);
            }
        }

        @Watch('store_id')
        public handleDrawerOpen(storeId: any) {

            if (check([this.PRIVILEGE.STORE.STAFF_LIST], this.privileges).length > 0) {
                return;
            }

            if (!storeId) {
                return;
            }

            this.cacheStoreId = storeId.id;
            const store_id = this.store_id.id
            if (!this.state()) {
                this.$store.registerModule(
                    ['shopSearchPage', store_id + '@employee'],
                    store()
                );
                this.commit('setQuery', {
                    store_id: store_id, 
                    store_name: this.store_id.name, 
                    merchant_no: this.store_id.merchant_no 
                });
                this.$watch(function() {
                    return this.$store.state.shopSearchPage[store_id + '@employee']
                        && this.$store.state.shopSearchPage[store_id + '@employee'].error;
                }, this.errorListener);

            }
            this.dispatch('resetQuery',  {
                store_id: store_id, 
                store_name: this.store_id.name, 
                merchant_no: this.store_id.merchant_no 
            });
        }

        public onClose() {
            this.$emit('close');
        }
    }

    export default Member;
