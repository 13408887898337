
import { mapState } from 'vuex';
import ActivitiesStatusCount from '@/components/ActivitiesStatusCount.vue';
import { Modal } from 'ant-design-vue';
import { Click } from '@/components/Auth';
import { Component, Inject, Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRows: any;
}

@Component<Actions>({
    components: {
        Click,
        ActivitiesStatusCount
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) =>
                state.activitiesDraftSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public roleName: any = [];
    public roleName1: any = [];
    public data() {
        return {
            PRIVILEGE
        };
    }   
    public created() {
         if(this.$store.state.activityManage.payType === 'wx'){
             this.roleName.push(PRIVILEGE.ACTIVITIES_DRAFT.JIHUO);
             this.roleName1.push(PRIVILEGE.ACTIVITIES_DRAFT.STOP);
         }else{
             this.roleName .push(PRIVILEGE.UNION_ACTIVITIES_DRAFT.JIHUO);
             this.roleName1 .push(PRIVILEGE.UNION_ACTIVITIES_DRAFT.STOP);
         }
    }
    public handleCreateSuccess() {
        this.dispatch('search');
    }

    public handleRefresh() {
        this.dispatch('search');
    }
    public stopActivities() {
        Modal.confirm({
            title: '提示',
            content: '终止会导致本批次活动未发放就停止！终止后不可恢复！',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: '4'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public jhActivities() {
        Modal.confirm({
            title: '提示',
            content: '是否激活选择的活动？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: '1'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public handleAddAdPlan() {
        if (this.$store.state.activityManage.payType == 'wx') {
            this.$router.push('/console/activity/create');
        } else {
            this.$router.push('/console/activity/createUnion');
        }
    }
    public handleAddAdPlanUnion() {
        this.$router.push('/console/activity/createUnion');
    }
    public DetailDownload() {
        this.$router.push('/console/activity/detailDownload');
    }
}

export default Actions;
