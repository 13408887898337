import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-unionactivity-control-active-status',
    options: [{
        label: '固定立减',
        value: 2
    }, {
        label: '固定立折',
        value: 3
    }, {
        label: '随机立减',
        value: 7
    }, {
        label: '折后一口价',
        value: 4
    }, {
        label: '单品营销',
        value: 8
    }]
});
