import * as services from './services';
import {SaveParams} from './services';
import moment from 'moment';

export default {
    namespaced: true,
    state: {
        data: null,
        saving: false,
        loading: false,
        lastSaveTime: null,
        error: null
    },
    mutations: {
        setData(state: any, data: any) {
            state.data = {
                ...state.date,
                ...data,
            };
        },

        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },

        setSaving(state: any, saving: boolean) {
            state.saving = saving;
        },

        saved(state: any) {
            state.lastSaveTime = moment().format('YYYY-MM-DD HH:mm:ss');
        },

        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        async initData(context: any, id: string) {

            context.commit('setLoading', true);
            try {
                const ret = await services.detail(id);
                context.commit('setData', ret);
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        },

        /**
         * 保存商场信息
         * @param context action上下文
         */
        async save(context: any, payload: SaveParams) {
            context.commit('setSaving', true);

            try {
                await services.save({
                    ...payload
                });
                context.commit('saved');
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setSaving', false);
            }

        },
    },

};
