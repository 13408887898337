import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=d0b6dcda"
import script from "./NotFound.vue?vue&type=script&lang=ts"
export * from "./NotFound.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/lakala/Desktop/LKL/lkl-zf-ss-hui-pam-mgt-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d0b6dcda')) {
      api.createRecord('d0b6dcda', component.options)
    } else {
      api.reload('d0b6dcda', component.options)
    }
    module.hot.accept("./NotFound.vue?vue&type=template&id=d0b6dcda", function () {
      api.rerender('d0b6dcda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/NotFound.vue"
export default component.exports