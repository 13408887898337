import {RouteConfig} from 'vue-router';
import Search from './SearchPage/index.vue';
import createActivityMer from './CreatePage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'createActivityMer',
        name: '创建活动',
        component: createActivityMer,
    },
];

export default route;
