
    import {Modal} from 'ant-design-vue';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import * as DICTS from '@/common/dicts';
    import columns from './columns';
    import {normColumns, calcTableScrollX, getTop} from '@/utils';
    import {check, Show, Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    interface SearchTable {
        selectedRowKeys: any;
    }

    @Component({
        components: {
            Click,
            Show
        },
        computed: {
            // rowSelection() {
            //     const self: any = this;
            //     const {selectedRowKeys} = self;
            //     return {
            //         selectedRowKeys,
            //         onChange: self.onSelectChange,
            //         hideDefaultSelections: true
            //     };
            // },
            ...mapState({
                list: (state: any) =>
                    state.qWhitelistSearchPage.list.map((item: any, idx: any) => ({
                        idx: (state.qWhitelistSearchPage.page - 1) * state.qWhitelistSearchPage.pageSize + idx + 1,
                        ...item
                    })),
                total: (state: any) => state.qWhitelistSearchPage.total,
                pageSize: (state: any) => state.qWhitelistSearchPage.pageSize,
                page: (state: any) => state.qWhitelistSearchPage.page,
                loading: (state: any) => state.qWhitelistSearchPage.loading,
                fields: (state: any) => normColumns(
                    columns, state.qWhitelistSearchPage.fields, state.qWhitelistSearchPage.sortFields),
                updatingStatus: (state: any) => state.qWhitelistSearchPage.updatingStatus,
                rowSelection(state: any) {
                    const self: any = this;
                    const selectedRowKeys = state.qWhitelistSearchPage.selectedRows;
                    return {
                        selectedRowKeys,
                        onChange: self.onSelectChange,
                        hideDefaultSelections: true
                    };
                },
            })
        }
    })
    class SearchTable extends Vue {

        @Inject()
        public dispatch!: any;
        public fixed = 0;
        public PRIVILEGE = PRIVILEGE;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }
        // public handleSwitchClick(record: any) {
        //     if (!record.status) {
        //         Modal.confirm({
        //             title: '请确认',
        //             content: '是否已完成公众号配置?',
        //             okText: '确认',
        //             cancelText: '取消',
        //             onOk: () => {
        //                 this.dispatch('updateStatus', {
        //                     id: record.id,
        //                     status: true
        //                 });
        //             }
        //         });
        //     } else {
        //         this.dispatch('updateStatus', {
        //             id: record.id,
        //             status: false
        //         });
        //     }
        // }
        public data() {
            return {
                DICTS,
                columns,
                selectedRowKeys: [],
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public onSelectChange(selectedRowKeys: any[]) {
            this.$store.commit('qWhitelistSearchPage/setSelectedRows', selectedRowKeys);
        }

        public handlePageChange(page: number) {
            this.$store.commit('qWhitelistSearchPage/setPagination', {page});
            this.$router.push({
                path: '/console/q_whitelist/search' + this.$store.getters['qWhitelistSearchPage/queryString']()
            });
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.$store.commit('qWhitelistSearchPage/setPagination', {pageSize, page: 1});
            this.$router.push({
                path: '/console/q_whitelist/search' + this.$store.getters['qWhitelistSearchPage/queryString']()
            });
        }
    }

    export default SearchTable;
