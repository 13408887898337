var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Click", {
        attrs: {
          requires: [_vm.PRIVILEGE.DETAIL_DOWNLOAD.SPONSOR_DETAIL_EXCEL],
        },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.downloading } },
                  [_vm._v("下载明细")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }