import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-date-range-type',
    options: [{
        label: '请选择时间段',
        value: ''
    }, {
        label: '最近7天',
        value: 'last7'
    }, {
        label: '最近15天',
        value: 'last15'
    }, {
        label: '最近30天',
        value: 'last30'
    }, {
        label: '自定义时间段',
        value: 'custom'
    }]
});
