import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '报名ID',
        dataIndex: 'enrollId',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '业主方ID',
        dataIndex: 'ownerId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '政策ID',
        dataIndex: 'templetId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '商户号',
        dataIndex: 'merNo',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '商户所属机构',
        dataIndex: 'orgId',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '报名时间',
        dataIndex: 'createTime',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '审核时间',
        dataIndex: 'auditTime',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '审核意见',
        dataIndex: 'shenhe',
        align: 'center',
        width: 100,
        scopedSlots: {customRender: 'shenhe'},
    },
    {
        title: '审核人',
        dataIndex: 'updateBy',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '更新时间',
        dataIndex: 'gmtModified',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '推荐人',
        dataIndex: 'referral',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '报名方式',
        dataIndex: 'enrollWay',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell
    ,
    {
        title: '操作',
        dataIndex: 'op',
        width: 150,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
