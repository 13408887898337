
    import {Modal} from 'ant-design-vue';
    import {mapState} from 'vuex';
    import {Component, Inject, Vue} from 'vue-property-decorator';
    import columns from './columns';
    import * as services from './services';
    import {check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {calcTableScrollX, getTop} from '@/utils';
    import ClearText from '@/components/ClearText.vue';

    @Component({
        components: {
            ClearText
        },
        computed: {
            ...mapState({
                lists: (state: any) =>
                    state.whitelistSearchPage.list.map((order: any, idx: any) => ({
                        idx: (state.whitelistSearchPage.page - 1) * 10 + idx + 1,
                        ...order
                    })),
                total: (state: any) => state.whitelistSearchPage.total,
                pageSize: (state: any) => state.whitelistSearchPage.pageSize,
                page: (state: any) => state.whitelistSearchPage.page,
                loading: (state: any) => state.whitelistSearchPage.loading,
                rowSelection(state: any) {
                    const self: any = this;
                    const selectedRowKeys: any[] = [];
                    state.whitelistSearchPage.selectedRows.forEach((item: any) => selectedRowKeys.push(item.id));
                    return {
                        selectedRowKeys,
                        onChange: self.onSelectChange,
                        hideDefaultSelections: true
                    };
                }
            })
        }
    })
    class SearchTable extends Vue {
        @Inject()
        public dispatch!: any;

        @Inject()
        public privileges!: any;

        @Inject()
        public commit!: any;

        @Inject()
        public routerPush!: any;

        public fixed = 0;
        public style = '';
        public mounted() {
            this.fixed = window.document.body.clientHeight - 102;
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowScroll() {
            const top = getTop(this.$refs.wrapper) - window.scrollY;
            if (top < 0) {
                this.style = 'transform:translateY(' + (-top) + 'px)';
            } else {
                this.style = '';
            }
        }

        public destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('scroll', this.handleWindowScroll);
        }

        public handleWindowResize() {
            this.fixed = window.document.body.clientHeight - 102;
        }

        public data() {
            return {
                columns,
                calcTableScrollX,
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public onSelectChange(selectedRowKeys: any[], selectedRows: any[]) {
            this.$store.commit('whitelistSearchPage/setSelectedRows', selectedRows);
        }

        public handlePageChange(page: number) {
            this.commit('setPagination', {page});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query)
        }

        public handlePageSizeChange(current: number, pageSize: number) {
            this.commit('setPagination', {pageSize, page: 1});
            this.routerPush();
            this.dispatch('initQuery', this.$route.query)
        }

        public async handleDeleteConfirm(id: any) {
            const list = check([PRIVILEGE.WHITELIST.WHITELIST_DELETE], this.privileges);
            if (list.length > 0) {
                return this.$message.error(
                    '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
                );
            } else {
                Modal.confirm({
                    title: '确认从白名单移除吗?',
                    onOk: async () => {
                        try {
                            await services.deleteUserWhitelist({
                                id
                            });
                            this.dispatch('search');
                        } catch (e) {
                            this.$message.error(e.message);
                        }
                    }
                });
            }
        }
    }

    export default SearchTable;
