var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.STORE.SN_LIST] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "店铺SN",
            visible: _vm.visible,
            width: 900,
          },
          on: { ok: _vm.handleOK, cancel: _vm.handleClose },
        },
        [_c("SearchTable")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }