import {request_yx,downloadRequest_yx} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: string;
}) {
    
     
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/bill/query',
            data: {
                ...params
            },
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequest_yx({
        data: params,
        prefix: 'bill',
        api: '/console/activity/bill/download'
    });
}