
    import {mapState} from 'vuex';
    import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import UploadDrawer from './UploadDrawer/index.vue';
    import PRIVILEGE from '@/common/privileges';
    import {Modal} from 'ant-design-vue';

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click,  
            UploadDrawer
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.actShenheSearchPage.loading,
                downloading: (state: any) => state.actShenheSearchPage.downloading,
                selectedRows: (state: any) => state.actShenheSearchPage.selectedRows,
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public dispatch!: any;
        public applyUploadType:String = 'act_check_enroll';
        public auditUploadType:string = 'act_enroll';
        public updateApplyUploadType:string = 'act_enroll_update';
        
        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
            this.$router.push({
                path: '/console/actShenhe/search' + this.$store.getters['actShenheSearchPage/queryString']()
            });
        }
        
        @Watch('$store.state.actShenheSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.actShenheSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.actShenheSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('actShenheSearchPage/download');
        }
    }

    export default Actions;
