import * as services from './services';
import {Modal} from 'ant-design-vue';
import {flatAndUniqueMaterialsTerminals} from '@/utils';

export default {
    namespaced: true,
    state: {
        loading: false,
        data: null,
        error: null,
        updated: null
    },
    mutations: {
        initDataRequested(state: any) {
            state.loading = true;
            state.data = null;
            state.updated = null;
        },
        initDataSuccessed(state: any, data: any) {
            state.loading = false;
            state.data = data;
        },
        initDataFailed(state: any, error: any) {
            state.loading = false;
            state.error = error;
        },

        updateRequested(state: any) {
            state.loading = true;
        },
        updateSuccessed(state: any, data: any) {
            state.updated = new Date();
            state.loading = false;
        },
        updateFailed(state: any, error: any) {
            state.loading = false;
            state.error = error;
        },
    },
    actions: {
        async initData(context: any, id: any) {
            context.commit('initDataRequested');
            try {
                const data = await services.getInfo(id);
                context.commit('initDataSuccessed', data);
            } catch (e) {
                context.commit('initDataFailed', e);
            }
        },

        async edit(context: any, payload: any) {
            context.commit('updateRequested');
            try {
                const data = await services.update(payload);
                context.commit('updateSuccessed', data);
            } catch (e) {
                context.commit('updateFailed', e);
            }
        }
    },
    getters: {

    }
};
