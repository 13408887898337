import {
    defaultCustomRender,unionb2cStatusCustomRender,timeCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center',
    },
    {
        title: '活动ID',
        dataIndex: 'promotionId',
        width: 160,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'name',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '主题活动ID',
        dataIndex: 'budgetActivity',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 100,
        customRender: (status: any) => {
            return unionb2cStatusCustomRender(status);
        }
    },
    {
        title: '可用时间起始',
        dataIndex: 'validStartTime',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '可用时间结束',
        dataIndex: 'validEndTime',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '最后修改时间',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 150,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
