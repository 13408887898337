
    import {Component, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {deleteEmpty} from '@/utils';
    import * as services from './services';

    interface TransModal {
        form: any;
        loading: boolean;
        visible: boolean;
    }

    @Component({
        components: {
            Click
        }
    })
    class TransModal extends Vue {
        public visible = false;
        public PRIVILEGE = PRIVILEGE;

        public data() {
            return {
                loading: false,
                form: this.$form.createForm(this)
            };
        }

        public handleClose() {
            if (this.loading) {
                return;
            }
            this.visible = false;
        }

        public onClick() {
            if (this.loading) {
                return;
            }
            this.visible = true;
        }

        public handleSubmit(e: any) {
            e.preventDefault();
            this.form.validateFields(async (error: any, values: any) => {
                if (!error) {
                    this.loading = true;
                    // tslint:disable-next-line:variable-name
                    const store_id = this.$store.state.shopSearchPage.selectedRows;
                    const ret = await services.transfer(deleteEmpty({
                        ...values,
                        store_id
                    }));
                    this.loading = false;
                    if (ret) {
                        this.visible = false;
                        this.$store.dispatch('shopSearchPage/search');
                    }
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
    }

    export default TransModal;
