
import { Component, Prop, Vue } from 'vue-property-decorator';
import {mapState} from 'vuex';
import Role from '@/components/custom-form-components/Role.vue';
import { Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import * as services from "../../merSubsidyAct/RulePage/services";
interface CreateModal {
    form: any;
}

@Component({
    components: {
        Role,
        Click
    },
    computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData();
                },
                
            }),
            
        }
})
class CreateModal extends Vue {

    public visible = false;

    public loading = false;

    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public activityId!:any;
    @Prop()
    public recordData!:any;
    //@Inject()
    //public dispatch!: any;

    public data() {
        return {
            form: this.$form.createForm(this)
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }
   
    public onClick() {
        this.visible = true;
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields(async (error: any, values: any) => {
            if (!error) {
                try {
                    this.loading = true;
                    let operateType = '1';
                    if(this.recordData){
                        operateType = '2';
                    }
                    const data = {
                        activityId:this.activityId,
                        operateType:operateType,
                        ...values
                    }
                    await services.createShopLimit(data);
                    this.visible = false;
                    this.$message.success("提交成功");
                    this.$emit('searchData');
                } catch (e) {
                    this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });

    }
    private formatFormData(rawData: any) {
        console.log(this.recordData)
        if(!this.recordData){
            return {
                merchantNo:null,
                termNo:null,
                limit:null

            }
        }
        return {
            ...this.recordData
        }
    }
}
export default CreateModal;
