/**
 * @file 商圈归属
 */
import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-district-belong',
    options: [{
        label: '哈密市商业银行',
        value: 'HAMI_CITY_COMMERICIAL_BANK'
    }]
});
