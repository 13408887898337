import {request_yx,downloadRequest_yxcsv} from '@/utils';

export async function search(params: {
    query: any;
}) {

    try {
        return await request_yx({
            api: '/console/activity/subsidyRule/queryShopLimit',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        throw error;
    }
}

export async function createShopLimit(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRule/addShopLimit',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function editShopLimit(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRule/editShopLimit',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function deleteShopLimit(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRule/deleteShopLimit',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function list(params: any): Promise<{list: any, total: any}> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: {type:'act_mer',activityId: sessionStorage.getItem('temp_activity_id')}
        });
    } catch (e) {
        throw e;
    }
}
export async function refresh(id: any): Promise<{list: any, total: any}> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: {taskId: id,type:'act_mer',activityId: sessionStorage.getItem('temp_activity_id')}
        });
    } catch (e) {
        throw e;
    }
}
export async function downloadXls(id: any) {
    return await downloadRequest_yxcsv({
        data: {taskId: id,type:'act_mer',activityId: sessionStorage.getItem('temp_activity_id')},
        prefix: 'task_'+id,
        api: '/console/batch/task/file/download'
    });
}