
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        district_id: string;
        district_name: string;
        district_belong: string;
    }

    export interface FormData {
        district_id: string;
        district_name: string;
        district_belong: string;
    }

    @Component({
        components: {
            DistrictBelong
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.bizDistrictSearchPage.query);
                },
                loading: (state: any) => state.bizDistrictSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this)
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                // this.$store.commit('bizDistrictSearchPage/setPagination', { page: 1 });
                // this.$store.commit(
                //     'bizDistrictSearchPage/setQuery',
                //     this.formatQuery(values)
                // );
                // this.$router.push({
                //     path: this.$store.getters['bizDistrictSearchPage/url']()
                // });

                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/bizDistrict/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            this.form.setFieldsValue({
                district_id: undefined,
                district_name: undefined,
                district_belong: undefined
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                district_id: values.district_id,
                district_name: values.district_name,
                district_belong: values.district_belong
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                ...query,
            };
        }
    }

    export default SearchForm;
