import {request_yx} from '@/utils';

export async function getInfo(id: string) {
    try {
        return await request_yx({
            method: 'GET',
            api: '/console/activity/unionpay/getActivityInfo',
            data: {
                id: id
            }
        });
    } catch (e) {
        const error: any = new Error('获取失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function update(payload: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/editMerInfo',
            data: payload
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
