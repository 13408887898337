
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import './store';
    // import columns from './columns';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        merNo: '商户号',
        activityId: '活动ID',
        status: {
            name: '报名状态',
            values: {
                '0': '待审核',
                '1': '审核通过',
                '2': '审核拒绝'
            }
        },
        templetId:'政策ID',
        referral:'推荐人',
        startTime: {
            name: '报名时间',
            range: 'from'
        },
        endTime: {
            name: '报名时间',
            range: 'to'
        }
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout,
            Show
        },
        computed: {
            ...mapState({
                
            })
        }
    })
    export default class QWhitelistPage extends Vue {
        public storeName = 'actShenheSearchPage';
        public PRIVILEGE = PRIVILEGE;
        public humanReadableQuery = null;
       

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }


        public created() {
            
        }

        @Watch('$store.state.actShenheSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.actShenheSearchPage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.actShenheSearchPage.query')
        public watchHumanReadableQuery(query: any) {
            console.log('humanReadableQuery:', query);
            this.humanReadableQuery = queryToHumanReadable(query, humanReadableQuerySchema);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            console.log('route:', route);
            this.dispatch('initQuery', this.$route.query);
        }

        // private handleFieldChange(e: any) {
        //     this.commit('setFields', e);
        // }
    }
