var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "customer-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.users,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.columns, 62),
            y: _vm.fixed || undefined,
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "mobile",
            fn: function (text, record) {
              return _c("ClearText", {
                attrs: {
                  text: text,
                  action: "/console/customer/mobile",
                  getClearText: (result) => result.mobile,
                  params: { id: record.id },
                  requires: [_vm.PRIVILEGE.USER_MOBILE],
                },
              })
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c(
                    "DetailDrawer",
                    {
                      attrs: {
                        user_id: record.id,
                        title: "详情(" + record.username + ")",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("查看")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }