import * as DICTS from '@/common/dicts';
import {defaultCustomRender} from '@/utils';

export default [
        {
            title: '商户号',
            dataIndex: 'shopNo',
            width: 200,
            align: 'center',
            customRender: defaultCustomRender
        },
        {
            title: '终端号',
            dataIndex: 'termNo',
            width: 100,
            align: 'center',
            customRender: defaultCustomRender
        },
        {
            title: 'SN号',
            dataIndex: 'qrCode',
            width: 200,
            align: 'center',
            customRender: defaultCustomRender
        },
        {
            title: 'SN状态',
            dataIndex: 'status',
            align: 'center',
            customRender: (status: string) => {
                return DICTS.SN_STATUS[status] || '-';
            }
        },
        {
            title: '操作',
            dataIndex: 'op',
            width: 120,
            align: 'center',
            scopedSlots: {customRender: 'action'}
        }
    ];
