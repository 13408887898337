import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        width: 80
    },
    {
        title: '商户号',
        dataIndex: 'merchantNo',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '终端号',
        dataIndex: 'terminalId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'gray',
        width: 300,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: '创建时间',
        dataIndex: 'createdTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    }
];
