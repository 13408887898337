
    import {Modal} from 'ant-design-vue';
    import {Component,Prop, Vue,Watch} from 'vue-property-decorator';
    import {CreateParams} from './services';
    import {mapState} from 'vuex';
    import config from '@/config';
    import moment from 'moment';
    import AccountInfo from '@/components/custom-form-components/AccountInfo.vue';
    import Owner from '@/components/custom-form-components/Owner.vue';
    interface SearchForm {
        form: any;
        hasLogo: boolean;
    }

    @Component({
        components: {
            Owner,
            AccountInfo
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.subjectActivitySearchPage.loading,
                
            })
        }
    })
    class SearchForm extends Vue {
        @Prop()
        public activityId;
        @Prop()
        public name!: string;
        @Prop()
        public ownerId!: string;
        @Prop()
        public expenseAccount!: string;
        @Prop()
        public description!: string;
        public data() {
            return {
                config,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            
        }
        
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'subjectActivitySearchPage/edit',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.subjectActivitySearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$message.success('提交成功');
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onClose');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
      
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            const formValue =  {
                activityId:this.activityId,
                name: formData.name,
                description:formData.description,
                ownerId:formData.ownerId,
                expenseAccount:formData.expenseAccount
            }
            return formValue;
        }

    }

    export default SearchForm;
