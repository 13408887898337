
    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';

    import config from '@/config';

    interface EditForm {
        form: any;
    }



    @Component({
        components: {
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.districtCodeSearchPage.data);
                },
                saving: state => state.districtCodeSearchPage.saving,
                auditing: state => state.common.auditing
            })
        }
    })
    class EditForm extends Vue {
       public codeId:any;

        public data() {
            return {
                config,
                codeId:0,
                form: this.$form.createForm(this),
            };
        }

        

        public handleSubmit(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                 const formData = {
                    ...values,
                    id:this.codeId
                };
                if (!error) {
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'districtCodeSearchPage/edit',
                                formData
                            );
                            this.$message.success("提交成功");
                            const queryString = this.$store.getters['districtCodeSearchPage/queryString']();
                            this.$router.push({
                                path: '/console/districtCode/search' + queryString
                            });
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }

        public handleReset() {
            this.form.resetFields();
        }

        public handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    const queryString = this.$store.getters['districtCodeSearchPage/queryString']();
                    this.$router.push({
                        path: '/console/districtCode/search' + queryString
                    });
                },
                onCancel() {
                    // do nothing
                }
            });
        }

        private formatFormData(rawData: any) {
            const formData = {
                ...rawData
            };
            this.codeId=formData.id;
            return formData;
        }

    }

    export default EditForm;
