var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper" },
    [
      _c("DetailDrawer", {
        attrs: { activityInfo: _vm.detailUnion, title: "查看" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("detailUnion")
          },
        },
      }),
      _c("EditActDrawer", {
        attrs: { activityInfo: _vm.editActUnion, title: "修改" },
        on: {
          close: function ($event) {
            return _vm.handleDrawerClose("editActUnion")
          },
        },
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          size: "middle",
          rowKey: "ruleId",
          bordered: "",
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys || [],
            onChange: _vm.handleSelectChange,
          },
          columns: _vm.fields,
          dataSource: _vm.lists,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined,
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (value, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { size: "small", href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleDrawerOpenClick("detailUnion", record)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: record.status == "online",
                        expression: "record.status == 'online'",
                      },
                    ],
                    style: { paddingLeft: "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDrawerOpenClick(
                              "editActUnion",
                              record
                            )
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { style: { paddingLeft: "5px" } },
                  [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              record.isNewRule == "0" &&
                              record.status == "online",
                            expression:
                              "record.isNewRule == '0' && record.status == 'online'",
                          },
                        ],
                        attrs: {
                          requires: [_vm.PRIVILEGE.MER_SUBSIDY_RULE.STATUS],
                          size: "small",
                          href: "javascript:;",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toInvalid(record)
                          },
                        },
                      },
                      [_vm._v("失效")]
                    ),
                  ],
                  1
                ),
                _c(
                  "ModifyLogDrawer",
                  {
                    key: record.id,
                    attrs: {
                      ruleId: record.ruleId,
                      activityId: record.activityId,
                    },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          slot: "trigger",
                          size: "small",
                          href: "javascript:;",
                        },
                        slot: "trigger",
                      },
                      [_vm._v("查看修改日志")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }