
import { Modal } from 'ant-design-vue';
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as DICTS from '@/common/dicts';
import columns from './columns';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import { check, Show, Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import DetailDrawer from '../DetailPage/index.vue';
import EditActDrawer from '../EditPage/index.vue';
import ModifyLogDrawer from './ModifyLogDrawer/index.vue';
import AddMerList from '../AddMerList/index.vue'
interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        Click,
        Show,
        DetailDrawer,
        EditActDrawer,
        ModifyLogDrawer,
        AddMerList
    },
    computed: {
        ...mapState({
            list: (state: any) =>
                state.sharedQuotaConfSearchPage.list.map(
                    (item: any, idx: any) => ({
                        idx:
                            (state.sharedQuotaConfSearchPage.page - 1) *
                                state.sharedQuotaConfSearchPage.pageSize +
                            idx +
                            1,
                        ...item
                    })
                ),
            total: (state: any) => state.sharedQuotaConfSearchPage.total,
            pageSize: (state: any) => state.sharedQuotaConfSearchPage.pageSize,
            page: (state: any) => state.sharedQuotaConfSearchPage.page,
            loading: (state: any) => state.sharedQuotaConfSearchPage.loading,
            fields: (state: any) =>
                normColumns(
                    columns,
                    state.sharedQuotaConfSearchPage.fields,
                    state.sharedQuotaConfSearchPage.sortFields
                ),
            updatingStatus: (state: any) =>
                state.sharedQuotaConfSearchPage.updatingStatus,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys =
                    state.sharedQuotaConfSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            }
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public type = null;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public detail = null;
    public detailUnion = null;
    public editAct = null;
    public editActUnion = null;
    public addMerList = null;
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit(
            'sharedQuotaConfSearchPage/setSelectedRows',
            selectedRowKeys
        );
    }

    public handlePageChange(page: number) {
        this.$store.commit('sharedQuotaConfSearchPage/setPagination', { page });
        this.$router.push({
            path:
                '/console/sharedQuotaConf/search' +
                this.$store.getters['sharedQuotaConfSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('sharedQuotaConfSearchPage/setPagination', {
            pageSize,
            page: 1
        });
        this.$router.push({
            path:
                '/console/sharedQuotaConf/search' +
                this.$store.getters['sharedQuotaConfSearchPage/queryString']()
        });
    }
    public handleDrawerOpenClick(
        type: 'detailUnion' | 'editActUnion' | 'addMerList',
        record: any
    ) {
        this[type] = record;
    }

    public handleDrawerClose(type: 'detailUnion' | 'editActUnion' | 'addMerList') {
        this[type] = null;
    }
    
    public toInvalid(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确定将所选额度组失效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: record.merGroupId,
                    status: 'offline'
                });
            }
        });
    }
    public tovalid(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确定将所选额度组生效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: record.merGroupId,
                    status: 'online'
                });
            }
        });
    }

    public openActivityMer(row: any) {
        this.$router.push({
            path: `/console/activityMer/search?page=1&page_size=10&merGroupId=${
                row.merGroupId
            }`
        });
    }

    @Watch('$store.state.sharedQuotaConfSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('修改成功！');
        }
    }
}

export default SearchTable;
