var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "span",
        { on: { click: _vm.handleTriggerClick } },
        [
          _vm._t("trigger", function () {
            return [
              _vm.text
                ? _c("a-button", { attrs: { size: "small" } }, [
                    _vm._v(_vm._s(_vm.text)),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "分配详情", footer: null, width: 1010 },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _vm.type === "directly"
            ? _c("MerchantPanel", { attrs: { adPlanId: _vm.adPlanId } })
            : _vm._e(),
          _vm.type === "malls"
            ? _c("MallPanel", { attrs: { adPlanId: _vm.adPlanId } })
            : _vm._e(),
          _vm.type === "biz_districts"
            ? _c("BizDistrictPanel", { attrs: { adPlanId: _vm.adPlanId } })
            : _vm._e(),
          _vm.type === "upload_aus"
            ? _c("UploadMerchantPanel", { attrs: { adPlanId: _vm.adPlanId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }