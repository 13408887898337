import {request} from '@/utils';
import md5 from 'md5';
import config from '@/config';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {

    try {
        return await request({
            api: '/console/user/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取用户列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function setRole(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/user/update/group',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function createUser(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/user/create',
            data: {
                ...params,
                password: md5(params.password + config.salt)
            }
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteUser(params: any) {
    const formData = new URLSearchParams();
    formData.append('batch_user_id', params.user_id);
    try {
        return await request({
            api: '/console/user/delete',
            method: 'POST',
            data: formData
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
