import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';

export default () => create({
        search(query, page, pageSize) {
            return services.search({
                ...query,
                page,
                page_size: pageSize
            });
        },
        download(query) {
            return services.downloadXls(deleteEmpty({
                ...query
            }));
        },
        // columns
    }
);
