
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ConfigDrawer from './ConfigDrawer/index.vue';
import ActivityDrawer from './ActivityDrawer/index.vue';
import WXActivityDrawer from './WXActivityDrawer/index.vue';
import columns from './columns';
import { normColumns, calcTableScrollX, getTop } from '@/utils';

interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        ConfigDrawer,
        ActivityDrawer,
        WXActivityDrawer
    },
    computed: {
        rowSelection() {
            const self: any = this;
            const { selectedRowKeys } = self;
            return {
                selectedRowKeys,
                onChange: self.onSelectChange,
                hideDefaultSelections: true
            };
        },
        ...mapState({
            dataSource: (state: any) =>
                state.companyQueryConfSearchPage.list.map(
                    (item: any, idx: any) => ({
                        idx:
                            (state.companyQueryConfSearchPage.page - 1) *
                                state.companyQueryConfSearchPage.pageSize +
                            idx +
                            1,
                        ...item
                    })
                ),
            total: (state: any) => state.companyQueryConfSearchPage.total,
            pageSize: (state: any) => state.companyQueryConfSearchPage.pageSize,
            page: (state: any) => state.companyQueryConfSearchPage.page,
            loading: (state: any) => state.companyQueryConfSearchPage.loading,
            fields: (state: any) =>
                normColumns(
                    columns,
                    state.companyQueryConfSearchPage.fields,
                    state.companyQueryConfSearchPage.sortFields
                )
        })
    }
})
class SearchTable extends Vue {
    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    public handlePageChange(page: number) {
        this.$store.commit('companyQueryConfSearchPage/setPagination', {
            page
        });
        this.$router.push({
            path:
                '/console/companyQueryConf/search' +
                this.$store.getters['companyQueryConfSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('companyQueryConfSearchPage/setPagination', {
            pageSize,
            page: 1
        });
        this.$router.push({
            path:
                '/console/companyQueryConf/search' +
                this.$store.getters['companyQueryConfSearchPage/queryString']()
        });
    }
}

export default SearchTable;
