
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import moment from 'moment';
    import {timeRangeFormat} from '@/utils';
    import SearchFormWrapper from '@/components/SearchFormWrapper.vue';

    interface Query {
        store_id: string;
        store_name: string;
        merchant_no: string;
    }

    interface FormData {
        store_id: string;
        store_name: string;
        merchant_no: string;
    }

    @Component({
        components: {
            SearchFormWrapper
        },
        computed: {
            ...mapState({
                loading(state) {
                    return this.state().loading;
                },
                formData() {
                    return this.queryToFormData(this.state().query);
                }
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public state!: any;

        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false
            };
        }

        public format(values: FormData): Query {
            return {
                store_id: values.store_id,
                store_name: values.store_name,
                merchant_no: values.merchant_no,
            };
        }

        public queryToFormData(query: Query): FormData {
            return {
                store_id: query.store_id,
                store_name: query.store_name,
                merchant_no: query.merchant_no,
            };
        }

        public handleSearch(query: Query) {
            this.dispatch('queryChange', query);
        }
    }

    export default SearchForm;
