import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '活动ID',
        dataIndex: 'id',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '批次ID',
        dataIndex: 'platActivity',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: '主题活动ID',
        dataIndex: 'gwActivityId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    
    {
        title: '优惠方式',
        dataIndex: 'activityType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '出资方',
        dataIndex: 'sponsorName',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
    title: '结束时间',
        dataIndex: 'endTime',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'createdUser',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 200,
        fixed: 'right',
        align: 'center',
        scopedSlots: {
            customRender: 'action',
        }
    }
];
