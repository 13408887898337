
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import './store';
    // import columns from './columns';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        merchant_name: '商户名称',
        merchant_no: 'LKL商户号',
        terminal_id: '终端号',
        active: {
            name: '开关状态',
            values: {
                True: '开',
                False: '关'
            }
        },
        source: {
            name: '来源',
            values: {
                SINGLE_IMPORT: '自助绑定',
                BULK_IMPORT: '运营平台批量导入'
            }
        },
        status: {
            name: '激活状态',
            values: {
                True: '已激活',
                False: '未激活'
            }
        },
        start_time: {
            name: '创建时间',
            range: 'from'
        },
        end_time: {
            name: '创建时间',
            range: 'to'
        }
    };

    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout,
            Show
        },
        computed: {
            ...mapState({
                
            })
        }
    })
    export default class QWhitelistPage extends Vue {
        public storeName = 'qWhitelistSearchPage';
        public PRIVILEGE = PRIVILEGE;
        public humanReadableQuery = null;
        // @Provide()
        // public state() {
        //     return this.$store.state[this.storeName];
        // }

        // @Provide()
        // public getters(name: string) {
        //     return this.$store.getters[this.storeName + '/' + name];
        // }

        // @Provide()
        // public commit(mutation: string, payload?: any) {
        //     this.$store.commit(
        //         this.storeName + '/' + mutation,
        //         payload
        //     );
        // }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        // @Provide()
        // public routerPush() {
        //     this.$router.push({
        //         path: '/console/blacklist/search' + this.getters('queryString')()
        //     });
        // }

        // public data() {
        //     return {
        //         columns
        //     };
        // }

        public created() {
            // this.$store.dispatch('qWhitelistSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.qWhitelistSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.qWhitelistSearchPage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.qWhitelistSearchPage.query')
        public watchHumanReadableQuery(query: any) {
            console.log('humanReadableQuery:', query);
            this.humanReadableQuery = queryToHumanReadable(query, humanReadableQuerySchema);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            console.log('route:', route);
            this.dispatch('initQuery', this.$route.query);
        }

        // private handleFieldChange(e: any) {
        //     this.commit('setFields', e);
        // }
    }
