var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商户号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantNo",
                      {
                        initialValue: _vm.formData.merchantNo,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            pattern: /^[A-Za-z\d]{15}$/,
                            message: "请输入15位商户号",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'merchantNo',\n                    {\n                        initialValue: formData.merchantNo,\n                        rules: [{required: true, message: '请填写必填项'},\n                        {pattern: /^[A-Za-z\\d]{15}$/, message: '请输入15位商户号'}]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "请输入", maxLength: 15 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "终端号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "termNo",
                      {
                        initialValue: _vm.formData.termNo,
                        rules: [
                          {
                            pattern: /^[A-Za-z\d]{8}$/,
                            message: "请输入8位终端号",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'termNo',\n                        {\n                            initialValue: formData.termNo,\n                            rules: [{pattern: /^[A-Za-z\\d]{8}$/, message: '请输入8位终端号'}]\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "请输入", maxLength: 8 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityId",
                      {
                        initialValue: _vm.formData.activityId,
                        rules: [{ required: true, message: "请填写必填项!" }],
                      },
                    ],
                    expression:
                      "[\n                    'activityId',\n                        {\n                            initialValue: formData.activityId,\n                            rules: [{ required: true, message: '请填写必填项!' }]\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleId",
                      {
                        initialValue: _vm.formData.ruleId,
                      },
                    ],
                    expression:
                      "[\n                    'ruleId',\n                        {\n                            initialValue: formData.ruleId\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c("a-form-item", { attrs: { label: "活动商户有效期" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
              },
              [
                _c("DatePicker", {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "ruleTime",
                        { initialValue: _vm.formData.ruleTime },
                      ],
                      expression:
                        "['ruleTime',{initialValue: formData.ruleTime}]",
                    },
                  ],
                  attrs: { disabled: "" },
                }),
                _vm.formData.merGroupId === "0"
                  ? [
                      _c(
                        "a-range-picker",
                        {
                          staticStyle: { width: "90px", "text-align": "right" },
                          attrs: {
                            "default-value": _vm.formData.ruleTime,
                            format: "YYYY-MM-DD HH:mm:ss",
                            "show-time": "",
                          },
                          on: { ok: _vm.handleChangeTimeRange },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { width: "90px", cursor: "pointer" },
                            },
                            [_vm._v("修改有效期")]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "a-form-item",
            { attrs: { label: "补贴限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "limit",
                      {
                        initialValue: _vm.formData.limit,
                        rules: [
                          {
                            pattern:
                              /(^0$)|(^max$)|(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'limit',\n                        {  initialValue: formData.limit,\n                            rules: [{pattern: /(^0$)|(^max$)|(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/, message: '请正确输入金额'}]\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: _vm.formData.merGroupId !== "0" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "补贴总交易限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "payLimit",
                      {
                        initialValue: _vm.formData.payLimit,
                        rules: [
                          {
                            pattern:
                              /(^0$)|(^max$)|(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'payLimit',\n                        {  initialValue: formData.payLimit,\n                            rules: [{pattern: /(^0$)|(^max$)|(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/, message: '请正确输入金额'}]\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: _vm.formData.merGroupId !== "0" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "补贴交易封顶笔数" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "subsidyMaxNum",
                      {
                        initialValue: _vm.formData.subsidyMaxNum,
                        rules: [
                          {
                            pattern:
                              /(^0$)|(^max$)|(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'subsidyMaxNum',\n                        {  initialValue: formData.subsidyMaxNum,\n                            rules: [{pattern: /(^0$)|(^max$)|(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/, message: '请正确输入金额'}]\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: _vm.formData.merGroupId !== "0" },
              }),
            ],
            1
          ),
          _c("a-form-item", { attrs: { label: "共享额度" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.formData.merGroupId === "0" ? "否" : "是")),
            ]),
          ]),
          _c(
            "a-form-item",
            { attrs: { label: "优先级" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "priority",
                      {
                        initialValue: _vm.formData.priority,
                      },
                    ],
                    expression:
                      "[\n                    'priority',\n                        {\n                            initialValue: formData.priority\n                        }\n                    ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "自定义规则" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleJson",
                      { initialValue: _vm.formData.ruleJson },
                    ],
                    expression:
                      "[ 'ruleJson',{  initialValue: formData.ruleJson} ]",
                  },
                ],
                staticStyle: { width: "95%" },
                attrs: {
                  disabled: true,
                  autosize: { minRows: 6, maxRows: 100 },
                },
              }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "right" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }