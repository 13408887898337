
import { Menu, Icon } from 'ant-design-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        [Menu.name]: Menu,
        [Menu.Item.name]: Menu.Item,
        [Icon.name]: Icon,
    },
})
export default class SideMenu extends Vue {
    @Prop({ default: false })
    public collapsed!: boolean;

    public openKeys: string[] = [];
    public rootSubmenuKeys = [
        'shopManage',
        'activities',
        'system',
        'statistics',
        'shopManage',
    ];

    private selectedKey: Array<string | undefined> = [];

    @Watch('$route')
    public updateSelectedKey(): void {
        this.selectedKey = [this.parseKeyFromRoutePath()];
    }

    private parseKeyFromRoutePath() {
        const matchRet = this.$route.path.match(/^\/console\/([^/]+)\/?.*/);
        if (matchRet) {
            return matchRet[1];
        }
    }

    private created() {
        this.updateSelectedKey();
    }
    private onOpenChange(openKeys: string[]) {
        const latestOpenKey = openKeys.find(
            (key: string) => this.openKeys.indexOf(key) === -1
        );
        if (this.rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            this.openKeys = openKeys;
        } else {
            this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
    }

    private scrolTop() {
        this.scrollTo(document.documentElement, 0, 200);
    }
    private scrollTo(element: Element, to: number, duration: number) {
        let start = element.scrollTop,
            change = to - start,
            increment = 20;

        let animateScroll = (elapsedTime: number) => {
            elapsedTime += increment;
            let position = this.easeInOut(elapsedTime, start, change, duration);
            element.scrollTop = position;
            if (elapsedTime < duration) {
                setTimeout(function () {
                    animateScroll(elapsedTime);
                }, increment);
            }
        };

        animateScroll(0);
    }
    private easeInOut(
        currentTime: number,
        start: number,
        change: number,
        duration: number
    ) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return (change / 2) * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
    }
}
