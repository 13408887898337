var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleAdd } },
        [_vm._v("新增")]
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.applyUploadType } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("批量新增商户名单")])],
            1
          ),
        ],
        2
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.changeUploadType, operateType: "1" } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("批量新增共享额度")])],
            1
          ),
        ],
        2
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.changeUploadType, operateType: "2" } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("批量修改共享额度")])],
            1
          ),
        ],
        2
      ),
      _c(
        "UploadDrawer",
        { attrs: { uploadType: _vm.changeUploadType, operateType: "3" } },
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("批量上下线共享额度")])],
            1
          ),
        ],
        2
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SHARED_QUOTA_CONF.EXPORT] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (slotProps) {
              return [
                _c("a-button", { attrs: { disabled: _vm.downloading } }, [
                  _vm._v("导出Excel表"),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }