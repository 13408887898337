/**
 * @file 配置应用，保存在localStorage里
 */

const storage = window.localStorage;

export default {
    setSortFields(page: string, config: any) {
        storage.setItem('app_sort_fields' + page, JSON.stringify(config));
    },

    getSortFields(page: string) {
        return JSON.parse(storage.getItem('app_sort_fields' + page) || '[]');
    }
};
