var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.store_id,
        width: 1020,
        placement: "right",
        closable: "",
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "div",
        [
          _c(
            "a-tabs",
            {
              staticStyle: { overflow: "visible" },
              attrs: { defaultActiveKey: "sendTask", animated: false },
              on: { change: _vm.handleChange },
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: "sendTask",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "发送记录" },
                },
                [
                  _c("Show", {
                    attrs: { requires: [_vm.PRIVILEGE.STORE.STORE_SMSTASK] },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("SendTaskSearchPage", {
                              attrs: { active: _vm.activeKey === "sendTask" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "buyRecord",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "购买记录" },
                },
                [
                  _c("Show", {
                    attrs: { requires: [_vm.PRIVILEGE.STORE.STORE_SMSRECORD] },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("BuyRecordSearchPage", {
                              attrs: { active: _vm.activeKey === "buyRecord" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }