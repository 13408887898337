var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "span",
        { on: { click: _vm.handleTriggerClick } },
        [
          _vm._t("trigger", function () {
            return [_c("a", [_vm._v("点击")])]
          }),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "批量上传", footer: null },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "a-upload",
                {
                  attrs: {
                    name: "file",
                    fileList: _vm.fileList,
                    action:
                      _vm.config.XY_BASE_URL + "/console/trade-transfer/upload",
                    headers: { Authorization: _vm.token },
                    remove: _vm.handlerRemove,
                  },
                  on: { change: _vm.handleFileUpload },
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", disabled: _vm.disabledUpload() },
                    },
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _vm._v(
                        _vm._s(_vm.disabledUpload() ? "需要上传权限" : "上传") +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Click", {
                attrs: { requires: [_vm.PRIVILEGE.PAY_EXCHANGE.DOWNLOAD] },
                on: { pass: _vm.handleDownloadTemplateClick },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "125px",
                            },
                            attrs: { size: "small" },
                          },
                          [
                            _vm._v(
                              "\n                    下载模板\n                "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm.error && _vm.error.length > 0
                ? _c(
                    "div",
                    [
                      _c("a-divider", { staticStyle: { margin: "10px 0px" } }),
                      _c("div", [_vm._v("无效输入: ")]),
                      _vm._l(_vm.error, function (e) {
                        return _c("div", { key: e }, [
                          _vm._v(
                            "\n                " + _vm._s(e) + "\n            "
                          ),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }