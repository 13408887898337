import * as services from './services';
import qs from 'querystringify';
import {deleteEmpty} from '@/utils';

export default {
    namespaced: true,
    state: {
        roles: [],
        query: null,
        page: 1,
        pageSize: 10,
        total: 0,
        loading: false,
        error: null,
        // store_id:null,
    },
    mutations: {
        setRoles(state: any, {roles, total}: any) {
            state.roles = roles;
            state.total = total;
        },

        setQuery(state: any, payload: any) {
            state.query = {
                ...state.query,
                ...payload,
            };
        },

        resetQuery(state: any, query: any) {
            state.query = {...query};
        },

        clearQuery(state: any) {
            state.query = {};
        },
        // setStoreId(state: any, store_id: any) {
        //     state.store_id = store_id;
        // },

        setPagination(state: any, {page, pageSize}: any) {
            state.page = page || state.page;
            state.pageSize = pageSize || state.pageSize;
        },

        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },

        setFields(state: any, {key, checked}: any) {
            state.fields = {
                ...state.fields,
                [key]: checked,
            };
        },
        setError(state: any, error: any) {
            state.error = error;
        }
    },
    actions: {
        async initQuery(context: any, query: any) {

            const {
                page,
                page_size,
                ...rest
            } = query;

            context.commit('resetQuery', rest);
            const pagination: any = {};
            if (page) {
                pagination.page = parseInt(page, 10);
            }
            if (page_size) {
                pagination.pageSize = parseInt(page_size, 10);
            }
            context.commit('setPagination', pagination);
            await context.dispatch('search');
        },

        /**
         * 查询用户
         * @param context action上下文
         */
        async search(context: any,state: any) {
            context.commit('setLoading', true);
            context.commit('setRoles', {roles: [], total: 0});
            try {
                const ret = await services.search(deleteEmpty({
                    // store_id: state.store_id,
                    page: context.state.page,
                    page_size: context.state.pageSize,
                }));
                context.commit('setRoles', {roles: ret.list, total: ret.total});
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        },
        pageChange(context: any, page: number) {
            context.commit('setPagination', {page});
            context.dispatch('search');
        },
        pageSizeChange(context: any, size: number) {
            context.commit('setPagination', {pageSize: size, page: 1});
            context.dispatch('search');
        },
        queryChange(context: any, query: any) {
            context.commit('setQuery', query);
            context.dispatch('pageChange', 1);
        },
        resetQuery(context: any, query: any) {
            context.commit('resetQuery', query);
            context.dispatch('pageChange', 1);
        }
    },
    getters: {
        url: (state: any) => () => {
            const q = {
                // ...state.store_id,
                page: state.page,
                page_size: 10,
                r: Math.random(),
            };
            console.log(q);
            const querystr = qs.stringify(deleteEmpty(q));
            console.log(querystr);
            return `/console/store/sms/buying/record${querystr}`;
        }
    }
};
