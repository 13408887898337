
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
    import DatePicker from '@/components/custom-form-components/DatePicker.vue';
    import QWhitelistTriggerStatus from '@/components/custom-form-components/QWhitelistTriggerStatus';
    import QWhitelistChannelSelector from '@/components/custom-form-components/QWhitelistChannelSelector';
    import QWhitelistActiveStatus from '@/components/custom-form-components/QWhitelistActiveStatus';
    import QWhitelistReportingStatus from '@/components/custom-form-components/QWhitelistReportingStatus';
    import QWhitelistJumpSwitch from '@/components/custom-form-components/QWhitelistJumpSwitch';
    import QwhitelistMachinesAreWhitelisted from '@/components/custom-form-components/QwhitelistMachinesAreWhitelisted';
    import {deleteEmpty} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import formRules from '@/common/formRules';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        merchant_name: string;
        merchant_no: string;
        terminal_id: string;
        active: string;
        ready: string;
        source: string;
        status: string;
        switch: string;
        cache: string,
        start_time: string;
        end_time: string;
    }

    export interface FormData {
        merchant_name: string;
        merchant_no: string;
        terminal_id: string;
        active: string;
        ready: string;
        source: string;
        status: string;
        switch: string;
        cache: string,
        created_time: any[];
    }

    @Component({
        components: {
            DistrictBelong,
            DatePicker,
            QWhitelistTriggerStatus,
            QWhitelistChannelSelector,
            QWhitelistActiveStatus,
            QWhitelistReportingStatus,
            QWhitelistJumpSwitch,
            QwhitelistMachinesAreWhitelisted
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.qWhitelistSearchPage.query);
                },
                loading: (state: any) => state.qWhitelistSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this),
                formRules
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/q_whitelist/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            // this.form.resetFields();
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                merchant_name: values.merchant_name,
                merchant_no: values.merchant_no,
                terminal_id: values.terminal_id,
                active: values.active,
                ready: values.ready,
                source: values.source,
                status: values.status,
                switch: values.switch,
                cache: values.cache,
                start_time: values.created_time &&
                    values.created_time[0] &&
                    values.created_time[0].format('YYYY-MM-DD HH:mm:ss'),
                end_time: values.created_time &&
                    values.created_time[1] &&
                    values.created_time[1].format('YYYY-MM-DD HH:mm:ss')
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                merchant_name: query.merchant_name,
                merchant_no: query.merchant_no,
                terminal_id: query.terminal_id,
                active: query.active,
                ready: query.ready,
                source: query.source,
                status: query.status,
                switch: query.switch,
                cache: query.cache,
                created_time: [
                    query.start_time && moment(query.start_time),
                    query.end_time && moment(query.end_time)
                ]
            };
        }
    }

    export default SearchForm;
