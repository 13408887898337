var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.activityInfo,
        width: 700,
        placement: "right",
        closable: "",
      },
      on: { close: _vm.onClose },
    },
    [
      _c("Show", {
        attrs: { requires: [_vm.PRIVILEGE.MER_SUBSIDY_ACT.EDIT] },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "ad_detail" },
                  [_c("EditActForm", { on: { onCancel: _vm.onClose } })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }