var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-tabs",
    {
      staticStyle: { overflow: "visible" },
      attrs: { activeKey: _vm.activeKey, animated: false },
      on: { change: _vm.handleChange },
    },
    [
      _c(
        "a-tab-pane",
        {
          key: "merSubsidyRule",
          staticStyle: { padding: "20px" },
          attrs: { tab: "商户补贴规则" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MER_SUBSIDY_RULE.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("MerSubsidyRuleSearchPage", {
                      attrs: { paytype: "merSubsidyRule" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }