
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { deleteEmpty } from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';
import { DatePicker } from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}
@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(
                    state.activityMerBatchSearchPage.query
                );
            },
            loading: (state: any) => state.activityMerBatchSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            if (!values.activityId) {
                return this.$message.error('请填写活动ID');
            }
            if (!values.activityTime || !values.activityTime[0]) {
                return this.$message.error('请选择时间');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            sessionStorage.setItem('activityBatchDownloadParams', JSON.stringify(query))
            this.$store.dispatch('activityMerBatchSearchPage/download');
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach(key => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        const [startTime, endTime] = values.activityTime;
        return {
            activityId: values.activityId ? values.activityId.trim() : '',
            startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
            endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            activityTime: [
                query.startTime && moment(query.startTime),
                query.endTime && moment(query.endTime)
            ]
        };
    }
}

export default SearchForm;
