var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "16%" },
                    attrs: { disabled: _vm.isDisabledFlag, type: "dashed" },
                    on: { click: _vm.add },
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 新增\n          "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("keys"), function (k, index) {
              return _c(
                "a-form-item",
                { key: "data" + index, attrs: { keys: k, required: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "custom-form-item-wrapper",
                      staticStyle: { width: "90%", display: "inline-block" },
                    },
                    [
                      _vm._v("\n          交易金额"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("："),
                      _c("a-input", {
                        staticStyle: {
                          "margin-left": "62px",
                          width: "26%",
                          "margin-right": "15px",
                        },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.rateListData[index].minAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.rateListData[index], "minAmt", $$v)
                          },
                          expression: "rateListData[index].minAmt",
                        },
                      }),
                      _vm._v("\n                   -\n                   "),
                      _c("a-input", {
                        staticStyle: { "margin-left": "15px", width: "26%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.rateListData[index].maxAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.rateListData[index], "maxAmt", $$v)
                          },
                          expression: "rateListData[index].maxAmt",
                        },
                      }),
                      _c("br"),
                      _vm._v("\n          优惠后费率(%)"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("："),
                      _c("a-input", {
                        staticStyle: { "margin-left": "26px", width: "60%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.rateListData[index].rate,
                          callback: function ($$v) {
                            _vm.$set(_vm.rateListData[index], "rate", $$v)
                          },
                          expression: "rateListData[index].rate",
                        },
                      }),
                      _c("br"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.accModeType,
                              expression: "accModeType",
                            },
                          ],
                        },
                        [
                          _vm._v("实补比例(%)："),
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "46px",
                              width: "60%",
                            },
                            attrs: { disabled: _vm.isDisabledFlag },
                            model: {
                              value: _vm.rateListData[index].realSubRate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.rateListData[index],
                                  "realSubRate",
                                  $$v
                                )
                              },
                              expression: "rateListData[index].realSubRate",
                            },
                          }),
                          _c("br"),
                        ],
                        1
                      ),
                      _vm._v("\n          单笔补贴最低金额："),
                      _c("a-input", {
                        staticStyle: { "margin-left": "10px", width: "60%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.rateListData[index].minSubsidyAmt,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.rateListData[index],
                              "minSubsidyAmt",
                              $$v
                            )
                          },
                          expression: "rateListData[index].minSubsidyAmt",
                        },
                      }),
                      _c("br"),
                      _vm._v("\n          单笔补贴封顶金额："),
                      _c("a-input", {
                        staticStyle: { "margin-left": "10px", width: "60%" },
                        attrs: { disabled: _vm.isDisabledFlag },
                        model: {
                          value: _vm.rateListData[index].maxSubsidyAmt,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.rateListData[index],
                              "maxSubsidyAmt",
                              $$v
                            )
                          },
                          expression: "rateListData[index].maxSubsidyAmt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "10%", top: "-60px", left: "4px" },
                      attrs: { type: "dashed", disabled: _vm.isDisabledFlag },
                      on: { click: () => _vm.remove(k) },
                    },
                    [_vm._v("\n          删除\n      ")]
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }