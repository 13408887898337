import {request_yx} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request_yx({
            api: '/console/activity/userEmp/getUsetList',
            method: 'POST',
            data: params,
        });
    } catch (e) {
        const error: any = new Error('获取数据配置列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
