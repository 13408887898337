var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.baseForm },
                  on: { submit: _vm.handleNext },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动名称不能为空",
                                  },
                                  {
                                    max: 64,
                                    message: "长度超限，请检查后重新输入!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'name',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '活动名称不能为空',\n                                    },\n                                    {\n                                        max: 64,\n                                        message:\n                                            '长度超限，请检查后重新输入!',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请填写活动名称（64个字以内）",
                          maxLength: "64",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "主题活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "gwActivityId",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写必填项",
                                  },
                                  {
                                    max: 32,
                                    message: "长度超限，请检查后重新输入!",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'gwActivityId',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请填写必填项',\n                                    },\n                                    {\n                                        max: 32,\n                                        message:\n                                            '长度超限，请检查后重新输入!',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        attrs: {
                          type: "number",
                          maxLength: "32",
                          placeholder: "请输入主题活动ID",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "银联活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "unionActivityId",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "ID不能为空",
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: "不允许出现空格",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'unionActivityId',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'ID不能为空',\n                                    },\n                                    {pattern: /^[^\\s]*$/, message: '不允许出现空格'}\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        attrs: { placeholder: "请填写已创建的银联活动ID" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动有效期" } },
                    [
                      _c("DisableDatePicker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "range_valiable_time",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "开始时间或结束时间不能为空",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'range_valiable_time',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message:\n                                            '开始时间或结束时间不能为空',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动总金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "activityTotalAmount",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动总金额不能为空",
                                  },
                                  {
                                    pattern:
                                      /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                    message: "只能填数字",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'activityTotalAmount',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '活动总金额不能为空',\n                                    },\n                                    {\n                                        pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                        message: '只能填数字',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { placeholder: "请输入活动总金额" },
                      }),
                      _vm._v(" 元\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "出资方名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "sponsorName",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "出资方名称不能为空",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'sponsorName',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '出资方名称不能为空',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        attrs: { placeholder: "请填写出资方名称" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "预警阈值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "warnRatio",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "预警阈值不能为空",
                                  },
                                  {
                                    pattern: /^[1-9]\d?$/,
                                    message: "请输入整数且不能为0或100",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'warnRatio',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '预警阈值不能为空',\n                                    },\n                                    {\n                                        pattern: /^[1-9]\\d?$/,\n                                        message: '请输入整数且不能为0或100',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { placeholder: "请输入整数" },
                      }),
                      _vm._v(" %\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "短信推送号码" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "warnMobile",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "手机号码不能为空",
                                  },
                                  {
                                    pattern: /^1\d{10}?$/,
                                    message: "请输入正确的手机号码",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'warnMobile',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '手机号码不能为空',\n                                    },\n                                    {\n                                        pattern: /^1\\d{10}?$/,\n                                        message: '请输入正确的手机号码',\n                                    },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "58%" },
                        attrs: { placeholder: "请输入手机号码" },
                      }),
                      _vm._v(" \n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("下一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginLeft: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "优惠规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.discountForm },
                  on: { submit: _vm.handleNext2 },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "订单最小金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orderMinAmount",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入订单最小金额",
                                  },
                                  {
                                    pattern:
                                      /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                                    message: "只能输入大于0的数字，精确到分",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n                            'orderMinAmount',\n                            { \n                                rules: [\n                                    { required: true, message: '请输入订单最小金额' },\n                                    { pattern: /^(([1-9][0-9]*)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/, message: '只能输入大于0的数字，精确到分' },\n                                ],\n                            },\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          placeholder: "请输入订单最小金额",
                          maxLength: "9",
                        },
                      }),
                      _vm._v(" 元\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "优惠类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "discountType",
                                {
                                  initialValue: _vm.discountType,
                                },
                              ],
                              expression:
                                "[\n                            'discountType',\n                            {\n                                initialValue: discountType,\n                            },\n                        ]",
                            },
                          ],
                          staticStyle: { margintop: "10px" },
                          on: { change: _vm.changeDiscountType },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "0" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "76.5px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                减\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue0",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "0",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字且不能为0",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'firstValue0',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '0',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message:\n                                                        '只能填数字且不能为0',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "", maxLength: "9" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "1" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立折\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "78px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                打\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          "\n                                        请输入0-100之间的整数，不包括0和100\n                                    "
                                        ),
                                      ]),
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "firstValue1",
                                              {
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.discountType == "1",
                                                    message: "打折费率不能为空",
                                                  },
                                                  {
                                                    pattern:
                                                      _vm.discountType == "1" &&
                                                      /^([1-9]\d{0,1})$/,
                                                    message:
                                                      "请输入0-100之间的整数，不包括0和100",
                                                  },
                                                ],
                                              },
                                            ],
                                            expression:
                                              "[\n                                            'firstValue1',\n                                            {\n                                                rules: [\n                                                    {\n                                                        required:\n                                                            discountType == '1',\n                                                        message:\n                                                            '打折费率不能为空',\n                                                    },\n                                                    {\n                                                        pattern: discountType == '1' && /^([1-9]\\d{0,1})$/,\n                                                        message: '请输入0-100之间的整数，不包括0和100',\n                                                    },\n                                                ],\n                                            },\n                                        ]",
                                          },
                                        ],
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          placeholder:
                                            "0-100之间的整数，不包括0和100",
                                          maxLength: "2",
                                          suffix: "%",
                                        },
                                      }),
                                      _vm._v(
                                        " 折\n                                "
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                封顶\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue1",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "1",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'secValue1',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '1',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "2" },
                            },
                            [
                              _vm._v(
                                "\n                            随机立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "36.5px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                金额下限\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue2",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "2",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'firstValue2',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '2',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                金额上限\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue2",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "2",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'secValue2',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '2',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "3" },
                            },
                            [
                              _vm._v(
                                "\n                            折后一口价\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                折后金额\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue3",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "3",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'firstValue3',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '3',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                封顶\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue3",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "3",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'secValue3',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '3',\n                                                    message: '金额不能为空',\n                                                },\n                                                {\n                                                    pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/,\n                                                    message: '只能填数字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "4" },
                            },
                            [
                              _vm._v(
                                "\n                            单品营销\n\n                            "
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue4",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "4",
                                                message: "单品描述不能为空",
                                              },
                                              {
                                                pattern: /^.{0,60}$/,
                                                message: "非必填，限制60字",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'firstValue4',\n                                        {\n                                            rules: [\n                                                {\n                                                    required:\n                                                        discountType == '4',\n                                                    message:\n                                                        '单品描述不能为空',\n                                                },\n                                                {\n                                                    pattern: /^.{0,60}$/,\n                                                    message:\n                                                        '非必填，限制60字',\n                                                },\n                                            ],\n                                        },\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "400px" },
                                    attrs: { placeholder: "限制60字" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("返回")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: {
                                click: function ($event) {
                                  _vm.activeTab = "1"
                                },
                              },
                            },
                            [_vm._v("上一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("下一步")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "商户规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.merchantForm },
                  on: { submit: _vm.handleNext3 },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动店铺", required: "" } },
                    [
                      _c("CreateModal", {
                        on: {
                          change: function ($event) {
                            return _vm.changeShopData($event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function (_) {
                              return [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("点击添加商户号和对应线下终端号"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "ShopUpload",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.changeShopData($event)
                            },
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "trigger" },
                            [
                              _c(
                                "a-button",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("批量上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.shopData.length > 0
                    ? _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("a-table", {
                            attrs: {
                              columns: _vm.columns,
                              dataSource: _vm.shopData,
                              size: "small",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "action",
                                  fn: function (text, record) {
                                    return _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: () =>
                                            _vm.deleteShopData(record),
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              2353402790
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("返回")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: {
                                click: function ($event) {
                                  _vm.activeTab = "2"
                                },
                              },
                            },
                            [_vm._v("上一步")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }