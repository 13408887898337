import {request, downloadRequest} from '@/utils';

export async function search(params: any) {

    try {
        return await request({
            api: '/console/ad/plan/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取广告列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'store',
        api: '/console/ad/plan/list/excel'
    });
}
export async function bulkInterrupt(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/ad/plan/bulk_interrupt',
            data: params
        });
    } catch (e) {
        const error: any = new Error('下架失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function bulkDelete(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/ad/plan/bulk_delete',
            data: params
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}