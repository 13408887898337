import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-unionb2c-control-active-status',
    options: [{
        label: '有效',
        value: 1
    }, {
        label: '无效',
        value: 2
    }, {
        label: '删除',
        value: 3
    }]
});
