import {request_yx} from '@/utils';
import * as Constant from '@/common/constant';
export async function search(params: any) {

    try {
        var api = '/console/activity/unionpayb2c/query'
        return await request_yx({
            method: 'POST',
            api,
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        const error: any = new Error('获取活动列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function updateStatus(params: any) {
    var api = '/console/activity/unionpayb2c/updateStatus'
    try {
        return await request_yx({
            method: 'POST',
            api,
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}
export async function create(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/unionpayb2c/add',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function editAct(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/unionpayb2c/edit',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        // const error: any = new Error('提交失败, 请联系技术人员!');
        // error.silent = e.is403;
        throw e;
    }
}
export async function getInfo(id: string) {
    try {
        return await request_yx({
            method: 'GET',
            api: '/console/activity/unionpayb2c/getActivityInfo',
            data: {
                id: id
            }
        });
    } catch (e) {
        const error: any = new Error('获取失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

