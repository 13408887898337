
    import {Component,Prop, Vue,Provide, Watch} from 'vue-property-decorator';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import {Click} from '@/components/Auth';
    interface index {
        visible: boolean;
    }
    @Component({
        components: {
            Show,
            Click,
        }
    })
    export default class auditOpinion extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public auditOpinion!: any;
        public visible = false;
        public created() {
           

        }

        @Watch('visible')
        public initQueryData(error: any) {
            console.log('dfd'+this.auditOpinion);
            if(this.auditOpinion == null){
                return; 
            }
        }
        public handleClose() {
            this.visible = false;
        }

        public handleOK() {
            this.visible = false;
        }

        public onClick() {
            this.visible = true;
        }
    }
