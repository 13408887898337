export const BIZ_DISTRICT_BELONG: any = {
    HAMI_CITY_COMMERICIAL_BANK: '哈密市商业银行'
};

export const SERVICE_PLATFORM: any = {
    MALLCOO: '猫酷'
};

export const ORDER_STATUS: any = {
    WAIT_PAY: '待支付',
    PAYING: '支付中',
    PAID: '已付款',
    FAIL: '交易失败',
    ERROR: '交易错误',
    REFUND: '已退款(已退款且已退优惠(如有优惠))',
    CLOSED: '交易关闭',
    REFUND_PROCESSING: '退款中',
    REFUND_FAIL: '退款失败(已退优惠(如有优惠)，退款失败)',
    COUPON_REFUND: '已退优惠(单独退优惠成功，款项未退)',
    COUPON_REFUND_FAIL: '退优惠失败'
};

export const PAY_CHANNEL: any = {
    ALIPAY: '支付宝',
    WECHAT: '微信支付',
    WECHATH5: '微信H5支付',
    UQRCODEPAY: '云闪付'
};

export const PAY_TYPE: any = {
    ONLINE: '线上',
    C2B_STATIC: 'C2B静态码',
    C2B_DYNAMIC: 'C2B动态码',
    B2C: 'B2C'
};

export const SN_STATUS: any = {
    0: '未绑定',
    1: '已绑定',
    2: '已解绑'
};
