var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "image-crop" }, [
    _c(
      "div",
      [
        _c(
          "a-upload",
          {
            ref: "image",
            attrs: {
              disabled: _vm.disabled,
              name: "upload",
              fileList: _vm.cropping
                ? [
                    {
                      uid: "-1",
                      name: "原始图像",
                      status: "done",
                      url: _vm.oriImageUrl,
                    },
                  ]
                : _vm.value,
              action: _vm.config.BASE_URL + "/console/ad/upload_image/material",
              headers: {
                Authorization: _vm.token,
              },
              showUploadList: {
                showRemoveIcon: !!(
                  (_vm.value &&
                    _vm.value.length > 0 &&
                    _vm.value[0].status !== "uploading") ||
                  _vm.noCrop
                ),
                showPreviewIcon: !_vm.noPreview,
              },
              accept: ".jpg,.gif,.jpeg,.png",
              remove: _vm.handleRemoveClick,
              listType: "picture-card",
              beforeUpload: _vm.beforeUpload,
            },
            on: { change: _vm.handleImageChange },
          },
          [!_vm.hasImage ? _c("div", [_vm._v("点击上传")]) : _vm._e()]
        ),
      ],
      1
    ),
    !_vm.noCrop
      ? _c(
          "div",
          [
            _vm.value && _vm.value.length > 0
              ? _c("Canvas", {
                  attrs: {
                    disabled: _vm.disabled,
                    image: _vm.oriImageUrl || _vm.value[0].url,
                  },
                  on: {
                    loaded: _vm.imageLoaded,
                    loadError: _vm.imageLoadError,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function (slotProps) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  width: _vm.selectorW + "px",
                                  height: _vm.selectorH + "px",
                                  top: 0,
                                  left: 0,
                                  border: "1px solid",
                                  transform: `translate(${_vm.selectorX}px, ${
                                    _vm.selectorY
                                  }px) rotate(${Math.round(_vm.angle)}deg)`,
                                  boxShadow: "0 0 0 9999em rgba(0,0,0,.5)",
                                  borderImageSource: _vm.borderImageSource,
                                  borderImageSlice: 1,
                                  borderImageRepeat: "repeat",
                                },
                              },
                              [
                                _c("Move", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    width: _vm.selectorW,
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                  },
                                  on: {
                                    change: _vm.handleMoveChange,
                                    mousedown: _vm.handleMoveMouseDown,
                                  },
                                }),
                                _c("Resize", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    cursor: _vm.resizeCursors.rb,
                                    pos: {
                                      right: -5 + "px",
                                      bottom: -5 + "px",
                                    },
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                    origin: _vm.resizeOrigin,
                                  },
                                  on: {
                                    mousedown: _vm.handleRBResizeMouseDown,
                                    change: _vm.handleRBResizeChange,
                                  },
                                }),
                                _c("Resize", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    cursor: _vm.resizeCursors.lb,
                                    pos: { bottom: -5 + "px", left: -5 + "px" },
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                    origin: _vm.resizeOrigin,
                                  },
                                  on: {
                                    mousedown: _vm.handleLBResizeMouseDown,
                                    change: _vm.handleLBResizeChange,
                                  },
                                }),
                                _c("Resize", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    cursor: _vm.resizeCursors.lt,
                                    pos: { top: -5 + "px", left: -5 + "px" },
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                    origin: _vm.resizeOrigin,
                                  },
                                  on: {
                                    mousedown: _vm.handleLTResizeMouseDown,
                                    change: _vm.handleLTResizeChange,
                                  },
                                }),
                                _c("Resize", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    cursor: _vm.resizeCursors.rt,
                                    pos: { top: -5 + "px", right: -5 + "px" },
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                    origin: _vm.resizeOrigin,
                                  },
                                  on: {
                                    mousedown: _vm.handleRTResizeMouseDown,
                                    change: _vm.handleRTResizeChange,
                                  },
                                }),
                                _c("Rotate", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    pos: { top: 10 + "px", left: 10 + "px" },
                                    mouse: { x: slotProps.x, y: slotProps.y },
                                    origin: _vm.rotateOrigin,
                                  },
                                  on: {
                                    change: _vm.handleRotateChange,
                                    mousedown: _vm.handleRotateMouseDown,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.rotating
                              ? _c(
                                  "div",
                                  {
                                    style: {
                                      position: "absolute",
                                      top:
                                        _vm.selectorY +
                                        _vm.selectorH / 2 +
                                        "px",
                                      left:
                                        _vm.selectorX +
                                        _vm.selectorW / 2 +
                                        "px",
                                      lineHeight: "15px",
                                      padding: "5px",
                                      color: "white",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      transform: "translate(-50%,-50%)",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.displayAngle) +
                                        "°\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3457835689
                  ),
                })
              : _vm._e(),
            _vm.showLoadingCanvasImage
              ? _c(
                  "div",
                  { staticStyle: { clear: "both" } },
                  [_c("a-icon", { attrs: { type: "loading" } })],
                  1
                )
              : _vm._e(),
            _vm.showLoadingCropArea
              ? _c(
                  "div",
                  { staticStyle: { clear: "both" } },
                  [
                    _c("a-icon", { attrs: { type: "loading" } }),
                    _vm._v("\n            正在初始化裁剪区域\n        "),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "12px",
                  "text-align": "left",
                  clear: "both",
                  color: "#EC4A3B",
                },
              },
              [
                _vm._v(
                  "\n            备注：支持jpg,gif,jpeg,png格式，无限制大小，\n            自动裁剪为正方形\n        "
                ),
              ]
            ),
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "12px",
                "text-align": "left",
                clear: "both",
                color: "#EC4A3B",
              },
            },
            [
              _vm._v(
                "\n            备注：支持jpg,gif,jpeg,png格式" +
                  _vm._s(
                    _vm.minWidth > 0 ? `, 图片宽度不小于${_vm.minWidth}px` : ""
                  ) +
                  "\n        "
              ),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }