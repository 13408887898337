var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "a-form",
        {
          staticClass: "ad-create-form",
          attrs: { form: _vm.otherForm },
          on: { submit: _vm.handleCreate },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "活动店铺" } },
            [
              _c("CreateModal", {
                on: {
                  change: function ($event) {
                    return _vm.changeShopData($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function (_) {
                      return [
                        _c("a-button", { attrs: { type: "primary" } }, [
                          _vm._v("点击添加商户号和对应线下终端号"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "ShopUpload",
                {
                  on: {
                    change: function ($event) {
                      return _vm.changeShopData($event)
                    },
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "trigger" },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "20px" } },
                        [_vm._v("批量上传")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.shopData.length > 0
            ? _c(
                "a-form-item",
                { attrs: { label: "" } },
                [
                  _c("a-table", {
                    attrs: {
                      columns: _vm.columns,
                      dataSource: _vm.shopData,
                      size: "small",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function (text, record, index) {
                            return _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: () => _vm.deleteShopData(record.index),
                                },
                              },
                              [_vm._v("删除")]
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      781665732
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }