import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        const pageQuery = {
            pageIndex: page,
            pageSize
        };
        return services.search({
            payType: 'wx',
            status: '3',
            pageQuery,
            ...query,
        });
    },
    columns,
    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
}, {
    state: {
        selectedRows: [],
        openDebugIng: false,
        sortFields: preferences.sortFields,
        updatingStatus: [],
        updateStatusError: null,
    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setColumns(state: any, columns: any) {
            state.columns = columns;
        },
        setOpenDebugLoading(state: any, loading: boolean) {
            state.openDebugIng = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
        updateStatusRequested(state: any, id: any) {
            state.updatingStatus = [...state.updatingStatus, id];
        },
       
        updateStatusFailed(state: any, error: any) {
            state.updateStatusError = error;
        },

        updateStatusFinally(state: any, id: any) {
            const idx = state.updatingStatus.indexOf(id);
            if (idx >= 0) {
                state.updatingStatus.splice(idx, 1);
                state.updatingStatus = [...state.updatingStatus];
            }
        },
    },
    actions: {
        async updateStatus(context: any, { ids, status }: any) {
            context.commit('updateStatusRequested', ids);
            try {
                console.log(ids);
                await services.updateStatus({
                    activityIds: ids,
                    status: status
                });
                // context.commit('updateOneStatus', { ids, status });
                // 状态更新成功后，重新获取列表
                context.dispatch('search');
            } catch (e) {
                context.commit('updateStatusFailed', e);
            } finally {
                context.commit('updateStatusFinally', ids);
            }
        }
    }
});
