var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "com-query-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.dataSource,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined,
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c(
                    "ConfigDrawer",
                    {
                      key: record.id + record.orgId,
                      attrs: {
                        id: record.id,
                        title: `配置政策(${record.username}、${record.mobile})`,
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("配置政策")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "ActivityDrawer",
                    {
                      key: record.id,
                      attrs: {
                        id: record.id,
                        orgId: record.orgId,
                        title: `配置活动(${record.username}、${record.mobile})`,
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("配置活动")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "WXActivityDrawer",
                    {
                      key: record.id,
                      attrs: {
                        id: record.id,
                        orgId: record.orgId,
                        title: `配置微信优惠活动(${record.username}、${record.mobile})`,
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("配置微信优惠活动")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }