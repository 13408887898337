import {RouteConfig} from 'vue-router';
import Download from './DownloadPage/index.vue';

const route: RouteConfig[] = [
    {
        path: 'download',
        name: '搜索',
        component: Download,
    }
];

export default route;
