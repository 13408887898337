import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';

const route: RouteConfig[] = [
    {
        path: 'user/search',
        name: '平台用户搜索',
        component: Index,
    },
    {
        path: 'role/search',
        name: '权限配置搜索',
        component: Index,
    },
    {
        path: 'whitelist/search',
        name: '白名单搜索',
        component: Index,
    },
    {
        path: 'customer/search',
        name: '惠码用户管理搜索',
        component: Index,
    },
];

export default route;
