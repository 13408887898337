import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import {deleteEmpty} from '@/utils';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns,
    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
},{
    state: {
        sponsorDetailList: [],
        selectedRows: [],
        error: '',
        sortFields: preferences.sortFields,
    },
    mutations: {
        setSponsorDetailList(state: any, payload: any) {
            state.sponsorDetailList = payload;
        },
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setError(state: any, error: any) {
            state.error = error;
        }
    },
    
});