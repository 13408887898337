import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    columns
}, {
    state: {
        privileges: []
    },
    mutations: {
        setPermissionList(state: any, payload: any) {
            state.privileges = payload;
        }
    },
    actions: {
        async fetchPrivileges(context: any, payload: any) {
            try {
                context.commit('setPermissionList', []);
                const list = (await services.fetchPermissionList()).list;
                context.commit('setPermissionList', list);
            } catch (e) {
                // context.commit('setError', e);
            }
        }
    }
});
