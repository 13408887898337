
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker,
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import moment from 'moment';
import * as validators from '../../validators';
import { findRepeatData, isRepeatArr } from '@/utils';

interface EditActForm {
    baseForm: any;
    discountForm: any;
    merchantForm: any;
    formData: any;
}

@Component<EditActForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker,
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.unionb2cSearchPage.data &&
                    this.formatFormData(state.unionb2cSearchPage.data)
                );
            },
        }),
    },
})
class EditActForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public discountForm;
    public merchantForm;
    public activeTab: any;

    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public promoType: any;

    public appValue: string = '';
    public cardBinValue: string = '';

    public created() {}

    public data() {
        return {
            activeTab: '1',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            discountForm: this.$form.createForm(this),
            promoType: '2',

            //第三页数据
            merchantForm: this.$form.createForm(this),
            shopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo',
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo',
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action',
                    },
                },
            ],
        };
    }

    public tabChange(key: any) {
        if (key === '2') {
            this.handleNextAction();
        } else if (key === '3') {
            this.handleNextAction2();
        } else {
            console.info('activeTab', key);
            this.activeTab = '1';
        }
    }
    public watchCardBin(e:any) {
        this.formData.cardBin = e.target.value
    }
    public watchApp(e:any) {
        this.formData.app = e.target.value
    }
    public handleNext(e: any) {
        e.preventDefault();
        this.handleNextAction();
    }

    public handleNext2(e: any) {
        e.preventDefault();
        this.handleNextAction2();
    }
    public handleNext3(e: any) {
        e.preventDefault();
        this.handleNextAction3();
    }

    public handleNextAction() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction2() {
        console.log('app & cardbin', this.cardBinValue, this.appValue)
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.discountForm.validateFields((error: any, values: any) => {
                    if (!error) {
                        const cardbins = values.cardBin || this.formData.cardBin;
                        const appisscodes = values.app || this.formData.app;
                        console.log('cardbins', cardbins);
                        console.log('appisscodes', appisscodes);
                        // if (!appisscodes && !cardbins) {
                        //     this.$message.error('APP和卡bin至少填写其一');
                        //     return false;
                        // }
                        // 指定APP校验
                        if (this.appValue) {
                            if (!appisscodes) {
                                this.$message.error('指定APP不能为空！');
                                return false;
                            }
                            let appisscodesArr = appisscodes.split(',');
                            if (isRepeatArr(appisscodesArr)) {
                                this.$message.error(
                                    `指定app的ISSCODE "${findRepeatData(
                                        appisscodesArr
                                    )}" 重复，请检查！`
                                );
                                return false;
                            }
                            if (appisscodesArr.length > 5) {
                                this.$message.error(
                                    '指定app的ISSCODE最多支持输入5条！'
                                );
                                return false;
                            }
                            const reg = /^[0-9]*$/;
                            for (let isscode of appisscodesArr) {
                                if (!isscode) {
                                    this.$message.error(
                                        '请删除指定APP最后的英文逗号！'
                                    );
                                    return false;
                                }
                                if (reg.test(isscode) == false) {
                                    this.$message.error(
                                        `${isscode}不符合规则，指定APP的ISSCODE只能为数字，英文逗号分隔`
                                    );
                                    return false;
                                }
                                if (isscode.length < 4 || isscode.length > 12) {
                                    this.$message.error(
                                        `${isscode}不符合长度规则，指定APP的ISSCODE，最短4位，最长12位`
                                    );
                                    return false;
                                }
                            }
                        }
                        // 指定卡bin校验
                        if (this.cardBinValue) {
                            if (!cardbins) {
                                this.$message.error('指定卡bin不能为空！');
                                return false;
                            }
                            let bins = cardbins.split(',');
                            if (isRepeatArr(bins)) {
                                this.$message.error(
                                    `卡bin数据 "${findRepeatData(
                                        bins
                                    )}" 重复，请检查！`
                                );
                                return false;
                            }
                            if (cardbins.length > 5000) {
                                this.$message.error(
                                    '卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度'
                                );
                                return false;
                            }
                            var reg = /^[0-9]*$/;
                            for (let bin of bins) {
                                if (!bin) {
                                    this.$message.error(
                                        '请删除指定卡bin最后的英文逗号！'
                                    );
                                    return false;
                                }
                                if (reg.test(bin) == false) {
                                    this.$message.error(
                                        `${bin}不符合规则，卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度`
                                    );
                                    return false;
                                }
                                if (bin.length < 6 || bin.length > 9) {
                                    this.$message.error(
                                        `${bin}不符合长度规则，卡bin为银行卡前几位数字，最短6位，最长9位`
                                    );
                                    return false;
                                }
                            }
                        }

                        this.formatBaseForm(values, '2');
                        this.activeTab = '3';
                    } else {
                        this.$message.error('请填写优惠的正确信息');
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction3() {
        this.merchantForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.info('merchantForm start');
                this.formatBaseForm(values, '3');
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                console.info('handleSubmitConfirm start');
                this.handleSubmitConfirm(values);
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeDiscountType(e: any) {
        this.promoType = e.target.value;
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        value.forEach((data: any) => {
            let flag = false;
            for (let shop of this.shopData) {
                if (shop.merNo === data[0] && shop.termNo === data[1]) {
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                const objData = {
                    index: this.shopData.length + 1,
                    merNo: data[0],
                    termNo: data[1],
                };
                this.shopData.push(objData);
            }
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleSubmitConfirm(values: any) {
        Modal.confirm({
            title: '提示',
            content: '是否确认提交?',
            onOk: () => {
                let baseData = this.baseData;

                const formData = { ...baseData };
                console.log(formData);
                this.$store.dispatch('unionb2cSearchPage/editAct', formData);
            },
        });
    }

    @Watch('$store.state.unionb2cSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            this.$emit('onCancel');
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onCancel');
            },
        });
    }

    private formatBaseForm(formData: any, activeTab: any) {
        console.log('this.formData', this.formData)
        if (activeTab == '1') {
            this.baseData = {
                name: formData.name,
            };
        } else if (activeTab == '2') {
            this.baseData.cardBin = formData.cardBin || this.formData.cardBin;
            this.baseData.app = formData.app || this.formData.app;
        } else if (activeTab == '3') {
            this.baseData.merchantList = this.shopData;
            this.baseData.promotionId = this.$store.state.unionb2cSearchPage.data.promotionId;
            this.baseData.groupId = this.$store.state.unionb2cSearchPage.data.groupId;
        }
        console.log('this.baseData', this.baseData)
    }
    private formatFormData(rawData: any) {
        this.appValue = rawData.app;
        this.cardBinValue = rawData.cardBin;
        this.promoType = rawData.promoType;
        this.shopData = rawData.merchantList;
        this.shopData = rawData.merchantList.map(
            (item: any, index: number) => ({
                index,
                ...item,
            })
        );
        return {
            ...rawData,
            activityTime: [
                rawData.startTime && moment(rawData.startTime),
                rawData.endTime && moment(rawData.endTime),
            ],
        };
    }
}

export default EditActForm;
