import {
    zeroCustomRender, priceCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '日期',
        dataIndex: 'date',
        align: 'center',
        width: 120,
        fixed: 'left',
        scopedSlots: {customRender: 'date'},
    },
    {
        title: '总券数',
        dataIndex: 'coupon_amount',
        align: 'center',
        width: 80,
        customRender: zeroCustomRender
    },
    {
        title: '已过期',
        dataIndex: 'expiration_time_amount',
        align: 'center',
        width: 80,
        customRender: zeroCustomRender
    },
    {
        title: '用券会员数',
        dataIndex: 'user_amount',
        width: 120,
        align: 'center',
        customRender: zeroCustomRender
    },
    {
        title: '用券消费笔数',
        dataIndex: 'consume_amount',
        width: 150,
        align: 'center',
        customRender: zeroCustomRender
    },
    {
        title: '用券抵扣金额数(元)',
        dataIndex: 'deduction_amount',
        width: 150,
        align: 'center',
        customRender: (amount: any) => {
            return priceCustomRender(amount);
        }
    },
    {
        title: '用券订单金额数(元)',
        dataIndex: 'total_amount',
        width: 150,
        align: 'center',
        customRender: (amount: any) => {
            return priceCustomRender(amount);
        }
    },
    blankCell
];
