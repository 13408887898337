
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import SearchTable from '@/components/SearchTable.vue';
    import {mapState} from 'vuex';
    import {defaultCustomRender, priceCustomRender, genderCustomRender} from '@/utils';
    import * as DICTS from '@/common/dicts';

    @Component({
        components: {
            SearchTable
        },
        computed: {
            ...mapState({
                list() {
                    return this.formatList(this.state().list);
                },
                total() {
                    return this.state().total;
                },
                page() {
                    return this.state().page;
                },
                pageSize() {
                    return this.state().pageSize;
                },
                loading() {
                    return this.state().loading;
                }
            })
        }
    })
    class StoreSearchTable extends Vue {

        @Inject()
        public state!: any;
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                columns: [
                    {
                        title: '会员类型',
                        dataIndex: 'channel',
                        fixed: 'left',
                        width: 120,
                        customRender: (channel: string) => {
                            return DICTS.PAY_CHANNEL[channel] || '-';
                        }
                    },
                    {
                        title: 'OpenID/UserID',
                        dataIndex: 'openid',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '会员昵称',
                        dataIndex: 'nick_name',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '性别',
                        dataIndex: 'gender',
                        customRender: genderCustomRender
                    },
                    {
                        title: '国家',
                        dataIndex: 'country',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '省',
                        dataIndex: 'province',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '市',
                        dataIndex: 'city',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '消费笔数',
                        dataIndex: 'consumption_number',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '总消费',
                        dataIndex: 'pay_amount',
                        customRender: priceCustomRender
                    },
                    {
                        title: '会员注册时间',
                        dataIndex: 'created_time',
                        customRender: defaultCustomRender
                    },
                    {
                        title: '最后消费时间',
                        dataIndex: 'trade_time',
                        customRender: defaultCustomRender
                    },
                ]
            };
        }

        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public handlePageSizeChange(pageSize: number) {
            this.dispatch('pageSizeChange', pageSize);
        }

        private formatList(values: any) {
            const list: any[] = [];
            values.forEach((value: any) => {
                if (value.channel === 'WECHAT') {
                    list.push({
                        ...value,
                        openid: value.openid,
                        pay_amount: value.wx_pay_amount,
                        consumption_number: value.wx_consumption_number,
                        trade_time: value.wx_trade_time,
                    });
                } else if (value.channel === 'WECHATH5') {
                    list.push({
                        ...value,
                        openid: value.wx_h5openid,
                        pay_amount: value.wx_h5_pay_amount,
                        consumption_number: value.wx_h5_consumption_number,
                        trade_time: value.wx_h5_trade_time,
                    });
                } else if (value.channel === 'ALIPAY') {
                    list.push({
                        ...value,
                        openid: value.ali_user_id,
                        pay_amount: value.alipay_pay_amount,
                        consumption_number: value.alipay_consumption_number,
                        trade_time: value.alipay_trade_time,
                    });
                } else if (value.channel === 'UQRCODEPAY') {
                    list.push({
                        ...value,
                        openid: value.union_openid,
                        pay_amount: value.uqpay_pay_amount,
                        consumption_number: value.uqpay_consumption_number,
                        trade_time: value.uqpay_rade_time,
                    });
                }
            });
            return list;
        }
    }

    export default StoreSearchTable;
