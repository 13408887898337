var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CreateModal", {
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function (_) {
              return [
                _c("a-button", { attrs: { disabled: _vm.loading } }, [
                  _vm._v("新增"),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SM.BULK_ON] },
        on: { pass: _vm.handleOnClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("上架\n                ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SM.BULK_OFF] },
        on: { pass: _vm.handleOffClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRowKeys.length === 0 || _vm.loading,
                    },
                  },
                  [_vm._v("下架\n                ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SM.EXPORT] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.downloading } },
                  [_vm._v("Excel导出")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      ),
      _c("SmStatusCount"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }