
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Region from '@/components/custom-form-components/Region.vue';

    interface SearchForm {
        form: any;
    }

    @Component({
        components: {
            Region
        },
        computed: {}
    })
    class SearchForm extends Vue {

        @Prop()
        public format!: (formData: any) => any;
        @Prop()
        public loading!: any;
        @Prop()
        public buttonSpan!: any;
        @Prop()
        public consts!: any;
        @Prop({default: true}) 
        public showBtn!: any;

        public data() {
            return {
                form: this.$form.createForm(this),
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                this.$emit('search', this.format(values));
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                if (this.consts && this.consts.indexOf(key) >= 0) {
                    return;
                }
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

    }

    export default SearchForm;
