var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "code-edit-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "shop-edit-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "行政区划代码" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "administrativeDivisionCode",
                      {
                        rules: [
                          {
                            pattern: /^\d{0,6}$/,
                            message: "仅限输入数字，长度不超过6位!",
                          },
                        ],
                        initialValue: _vm.formData.administrativeDivisionCode,
                      },
                    ],
                    expression:
                      "[\n                        'administrativeDivisionCode',\n                        {\n                            rules: [\n                                {pattern: /^\\d{0,6}$/, message: '仅限输入数字，长度不超过6位!'}\n                            ],\n                            initialValue: formData.administrativeDivisionCode\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入数字，长度不超过6位" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "地区" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mapDistrictName",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            pattern: /^[^\s]*$/,
                            message: "不可输入空格,不能为空!",
                          },
                        ],
                        initialValue: _vm.formData.mapDistrictName,
                      },
                    ],
                    expression:
                      "[\n                        'mapDistrictName',\n                        {\n                            rules: [\n                                {required: true, message: '请填写必填项'},\n                                {pattern: /^[^\\s]*$/, message: '不可输入空格,不能为空!'}\n                            ],\n                            initialValue: formData.mapDistrictName\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入必填项" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "省市区" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mapDistrictLevelName",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            pattern: /^[^\s]*$/,
                            message: "不可输入空格,不能为空!",
                          },
                        ],
                        initialValue: _vm.formData.mapDistrictLevelName,
                      },
                    ],
                    expression:
                      "[\n                        'mapDistrictLevelName',\n                        {\n                            rules: [\n                                {required: true, message: '请填写必填项'},\n                                {pattern: /^[^\\s]*$/, message: '不可输入空格,不能为空!'}\n                            ],\n                            initialValue: formData.mapDistrictLevelName\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入必填项" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "DISTRICT_ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "districtId",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            pattern: /^[0-9]\d*$/,
                            message: "仅限输入数字，不可为空",
                          },
                        ],
                        initialValue: _vm.formData.districtId,
                      },
                    ],
                    expression:
                      "[\n                        'districtId',\n                        {\n                            rules: [\n                                {required: true, message: '请填写必填项'},\n                                {pattern: /^[0-9]\\d*$/, message: '仅限输入数字，不可为空'}\n                            ],\n                            initialValue: formData.districtId\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入数字" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "DISTRICT_NAME" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "districtName",
                      {
                        rules: [{ required: true, message: "请填写必填项" }],
                        initialValue: _vm.formData.districtName,
                      },
                    ],
                    expression:
                      "[\n                        'districtName',\n                        {\n                            rules: [\n                                {required: true, message: '请填写必填项'}\n                            ],\n                            initialValue: formData.districtName\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入必填项" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "DISTRICT_PARENT" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "districtParent",
                      {
                        rules: [
                          { pattern: /^[0-9]\d*$/, message: "仅限输入数字!" },
                        ],
                        initialValue: _vm.formData.districtParent,
                      },
                    ],
                    expression:
                      "[\n                        'districtParent',\n                        {\n                            rules: [\n                                {pattern: /^[0-9]\\d*$/, message: '仅限输入数字!'}\n                            ],\n                            initialValue: formData.districtParent\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "DISTRICT_LEVEL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "districtLevel",
                      {
                        rules: [
                          { pattern: /^[0-9]\d*$/, message: "仅限输入数字!" },
                        ],
                        initialValue: _vm.formData.districtLevel,
                      },
                    ],
                    expression:
                      "[\n                        'districtLevel',\n                        {\n                            rules: [\n                                {pattern: /^[0-9]\\d*$/, message: '仅限输入数字!'}\n                            ],\n                            initialValue: formData.districtLevel\n                        }\n                    ]",
                  },
                ],
                attrs: { placeholder: "请输入" },
              }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        disabled: _vm.auditing,
                        loading: _vm.saving,
                      },
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.saving },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("\n                    取消\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }