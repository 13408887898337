import {request_yx, downloadRequest_yx, downloadRequest_yxcsv} from '@/utils';
import config from '@/config';
export async function list(params: any): Promise<{list: any, total: any}> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取任务列表失败, 请联系技术人员!');
        throw error;
    }
}
export async function refresh(params: any): Promise<{list: any, total: any}> {
    try {
        return await request_yx({
            api: '/console/batch/task/query',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取任务信息失败, 请联系技术人员!');
        throw error;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequest_yxcsv({
        data: params,
        prefix: 'task_'+params.id,
        api: '/console/batch/task/file/download'
    });
}

