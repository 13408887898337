var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        destroyOnClose: "",
        title: "开票账户",
        visible: !!_vm.merData,
        confirmLoading: _vm.confirmLoading || _vm.spinning,
        okText: _vm.spinning ? "loading..." : "确定",
      },
      on: { cancel: _vm.onClose, ok: _vm.onInvoiceRegister },
    },
    [
      _c(
        "a-spin",
        {
          attrs: {
            spinning: _vm.spinning || _vm.confirmLoading,
            tip: _vm.spinning ? "开票信息查询中..." : "注册中...",
          },
        },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: "商户号",
                "label-col": { span: 6 },
                "wrapper-col": { span: 14 },
              },
            },
            [
              _c("a-input", {
                attrs: {
                  value: _vm.merData && _vm.merData.merchant_no,
                  disabled: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "线下终端号",
                "label-col": { span: 6 },
                "wrapper-col": { span: 14 },
              },
            },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  value: _vm.merData && _vm.merData.offline_terminal_id,
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "注册账号",
                "label-col": { span: 6 },
                "wrapper-col": { span: 14 },
              },
            },
            [
              _c("a-input", {
                attrs: {
                  value: _vm.invoiceAccountId,
                  maxLength: "50",
                  placeholder: "请输入注册账号",
                },
                on: { change: _vm.setInvoiceAccount },
              }),
              _c("p", { staticClass: "tip" }, [
                _vm._v("注：只允许输入数字、字母、汉字，最长50字符"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }