import {request_yx,downloadRequest_yx} from '@/utils';

export async function search(params: {
    query: any;
}) {

    try {
        console.info("params",params)
        return await request_yx({
            api: '/console/activity/unionpayBill/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        }); 
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function downloadXls(params: any) {
    return await downloadRequest_yx({
        data: params,
        prefix: 'unionpay_bill',
        api: '/console/activity/unionpayBill/file/download'
    });
}