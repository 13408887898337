import {
    defaultCustomRender,taskActivityStatus,timeCustomRender,templetTypeRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '任务编号',
        dataIndex: 'taskId',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '时间',
        dataIndex: 'taskTime',
        width: 170,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '数量',
        dataIndex: 'total',
        align: 'center',
        width: 80,
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 100,
        customRender: (time: any) => {
            return taskActivityStatus(time);
        }
    },
    {
        title: '成功',
        dataIndex: 'success',
        width: 90,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '失败',
        dataIndex: 'fail',
        align: 'center',
        width: 90,
        customRender:defaultCustomRender
    },
    
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 219,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
