
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import {queryToHumanReadable} from '@/utils';
    const humanReadableQuerySchema = {
        start_time: {
            name: '日期',
            range: 'from'
        },
        end_time: {
            name: '日期',
            range: 'to'
        }
    };
    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.newbieSearchPage.fields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.newbieSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class UserPage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'newbieSearchPage';

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/marketing/newbie-data/search' + this.getters('queryString')()
            });
        }

        public data() {
            return {
                columns
            };
        }

        public created() {
            this.$store.dispatch('newbieSearchPage/initQuery', {
                ...this.$route.query
            });
        }

        @Watch('$store.state.newbieSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            if (!this.active) {
                return;
            }
            this.dispatch('initQuery', this.$route.query);
        }
    }
