/**
 * @file 服务商
 */
import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-service-platform',
    options: [{
        label: '猫酷',
        value: 'MALLCOO'
    }]
});
