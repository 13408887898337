import {create} from '@/common/storeFactory';
import * as services from './services';
import {deleteEmpty} from '@/utils';
import columns from './columns';
import store from '@/store';
// import preferences from './preferences';

store.registerModule('advertisementSearchPage', create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    download(query) {
        return services.downloadXls(deleteEmpty({
            ...query
        }));
    },
    columns
}, {
    state: {
        selectedRowKeys: [],
        interrupting: false
    },

    mutations: {
        setSelectedRowKeys(state: any, selectedRowKeys: any[]) {
            state.selectedRowKeys = selectedRowKeys;
        },

        interruptRequested(state: any) {
            state.interrupting = true;
        },
        interruptSuccessed(state: any) {
            state.interrupting = false;
        },

        interruptFailed(state: any, error: any) {
            state.interrupting = false;
            state.error = error;
        },

        deleteRequested(state: any) {
            state.deleteing = true;
        },
        deleteSuccessed(state: any) {
            state.deleteing = false;
        },

        deleteFailed(state: any, error: any) {
            state.interrupting = false;
            state.error = error;
        }

    },
    actions: {
        async interrupt(context: any, payload: any) {
            context.commit('interruptRequested');
            try {
                await services.bulkInterrupt({
                    plan_ids: context.state.selectedRowKeys
                });
                context.commit('setSelectedRowKeys', []);
                context.dispatch('search');
                context.commit('interruptSuccessed');
            } catch (e) {
                context.commit('interruptFailed', e);
            }
        },

        async delete(context: any, payload: any) {
            context.commit('deleteRequested');
            try {
                await services.bulkDelete({
                    plan_ids: context.state.selectedRowKeys
                });
                context.commit('setSelectedRowKeys', []);
                context.dispatch('search');
                context.commit('deleteSuccessed');
            } catch (e) {
                context.commit('deleteFailed', e);
            }
        }
    }
}));
