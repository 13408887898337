
    import {Modal} from 'ant-design-vue';
    import {Component, Inject, Vue,Watch, Prop} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {
    defaultCustomRender,priceCustomRender
    }from '@/utils';
    import {request} from '@/utils';
    const columns = [
        {
            title: '总计售出笔数',
            dataIndex: 'total_count'
        },
        {
            title: '总计售出金额',
            dataIndex: 'total_amount'   
        }
    ];
   
    @Component({
        components: {
        },
        computed: {
            ...mapState({
                
                fields: (state: any) => {
                    return columns;
                },
            })
        }
    })
    class ResultTable extends Vue {
        
        public total_count: any = 0;
        public total_amount: any = 0;
        public loading = false;
        public list!:any;

        public data() {
            return {
                list: [],
                loadingIcon: <span><a-icon type='loading'/> 加载中</span>
            };
        }

        public mounted() {

        }

        public async created() {
            this.fetch();
        }

        public async fetch() {
            this.loading = true;
            try {
                this.list = [];
                const data = await request({
                    api: '/console/sms/package/statistics'
                });
                const val = {
                    total_count: data.total_amount,
                    total_amount: data.total_price/100
                }
                this.list.push(val);
            } catch (e) {
                this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }
    }
    export default ResultTable;
