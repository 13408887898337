import {request, downloadRequest} from '@/utils';
import qs from 'querystringify';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'store',
        api: '/console/store/list/excel'
    });
}

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request({
            api: '/console/store/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取商户列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function openRefund(params: {
    batch_store_id: string;
    refund_open: 'False' | 'True'
}) {
    try {
        return await request({
            method: 'POST',
            api: '/console/store/refund/batch/switch',
            data: qs.stringify(params),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function smsSwitch(params: {
    daily_limit: string;
    sms_switch: 'False' | 'True'
}) {
    try {
        return await request({
            method: 'POST',
            api: '/console/store/sms/switch',
            data: qs.stringify(params),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function smsUpdate(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/store/sms/update',
            data: {
                ...params
            }
        });
    } catch (e) {
        // 注意！由于需求要求所有提交失败均报"提交失败，请联系技术人员!"，所以这里屏蔽了后端返回的错误信息
        // hack，下一版本优化
        if (e.message === '网络错误') {
            e.message = '提交失败, 请联系技术人员!';
        }
        const error: any = new Error(e.message);
        error.silent = e.is403;
        throw error;
    }
}
