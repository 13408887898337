
import { Component, Prop, Vue, Inject, Provide } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import store from './store';
import { getValueByPath } from '@/utils';

@Component({
    components: {
        Drawer,
        SearchTable
    }
})
class Config extends Vue {
    @Prop()
    public ruleId!: string;

    @Prop()
    public activityId!: string;

    @Inject()
    public privileges!: any;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'merSubsidyRuleSearchPage',
            this.ruleId + this.activityId + '@config'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'merSubsidyRuleSearchPage/' + this.ruleId + this.activityId + '@config/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'merSubsidyRuleSearchPage/' + this.ruleId + this.activityId + '@config/' + action,
            payload
        );
    }

    public created() {
        if (!this.state()) {
            this.$store.registerModule(
                ['merSubsidyRuleSearchPage', this.ruleId + this.activityId + '@config'],
                store()
            );
            this.$watch(function() {
                return (
                    this.$store.state.merSubsidyRuleSearchPage[
                        this.ruleId + this.activityId + '@config'
                    ] &&
                    this.$store.state.merSubsidyRuleSearchPage[this.ruleId + this.activityId + '@config']
                        .error
                );
            }, this.errorListener);
        }
    }

    public errorListener(error: any) {
        if (error && !error.silent) {
            this.$message.error(error.message);
        }
    }

    public handleDrawerOpen() {
        this.dispatch('resetQuery', { ruleId: this.ruleId, activityId: this.activityId });
    }
}

export default Config;
