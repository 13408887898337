
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 90
    },
    {
        title: '会员手机号',
        dataIndex: 'mobile',
        align: 'center',
        fixed: 'left',
        width: 200,
        scopedSlots: { customRender: 'mobile' }
    },
    {
        title: '加入时间',
        dataIndex: 'createdTime',
        align: 'center',
        fixed: 'left',
        width: 200,
    },
    {
        title: '创建人',
        dataIndex: 'createdName',
        align: 'center',
        fixed: 'left',
        width: 200
    },
    // {
    //     title: '操作',
    //     dataIndex: 'op',
    //     width: 100,
    //     fixed: 'right',
    //     align: 'center',
    //     scopedSlots: {customRender: 'action'}
    // }
];
