
    import {mapState} from 'vuex';
    import {Component, Inject,Watch, Vue} from 'vue-property-decorator';
    import CreateModal from './CreateModal.vue';
    import PRIVILEGE from '@/common/privileges';
    import createFrom from './createFrom.vue';
    import {Click} from '@/components/Auth';
    interface Actions {
        hideMessage: any;
    }
    @Component({
        components: {
            Click,
            CreateModal,
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.subjectActivitySearchPage.loading,
                downloading: (state: any) => state.subjectActivitySearchPage.downloading,
            })
        }
    })
    class Actions extends Vue {

        @Inject()
        public dispatch!: any;

        @Inject()
        public state!: any;
        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }
        public handleCreateSuccess() {
            this.dispatch('search');
        }
        public mounted() {
            console.log(this.$store)
        }
        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }
        public handleRefresh() {
            this.dispatch('search');
        }
        public handleAddAdPlan() {
            this.$router.push('/console/config/addSubject');
        }
    }

    export default Actions;
