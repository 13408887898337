var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SHOP_WHITE_LIST.ADD] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("trigger")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form,
          },
          on: { ok: _vm.handleCreate, cancel: _vm.handleClose },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleCreate },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mobile",
                          {
                            rules: [
                              {
                                required: true,
                                message: "请输入必填项!",
                              },
                              {
                                pattern: /^1\d{10}$/,
                                message: "请输入正确手机号",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n                        'mobile',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入必填项!',\n                                },\n                                {\n                                    pattern:  /^1\\d{10}$/,\n                                    message: '请输入正确手机号',\n                                },\n                            ],\n                        },\n                    ]",
                      },
                    ],
                    attrs: {
                      type: "tel",
                      maxLength: "11",
                      placeholder: "请输入手机号",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }