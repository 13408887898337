import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-zhongxin-control-active-status',
    options: [{
        label: '待审核',
        value: 'WAIT'
    }, {
        label: '审核通过',
        value: 'PASS'
    }, {
        label: '审核拒绝',
        value: 'REFUSE'
    }
]
});
