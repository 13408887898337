import {
    defaultCustomRender,
    priceCustomRender,
    couponRenderFactory,
    mallCouponRender
} from '@/utils';

export const PayStatus: any = {
    WAIT_PAY: '待支付',
    PAYING: '支付中',
    PAID: '已付款',
    FAIL: '交易失败',
    ERROR: '交易错误',
    REFUND: '已退款',
    CLOSED: '交易关闭',
    REFUND_PROCESSING: '退款中',
    REFUND_FAIL: '退款失败'
};

export default [
    {
        title: '会员名',
        dataIndex: 'customer.nick_name',
        fixed: 'left',
        width: 180,
        customRender: defaultCustomRender
    },
    {
        title: '会员卡号',
        dataIndex: 'customer.member_no',
        fixed: 'left',
        width: 180,
        customRender: defaultCustomRender
    },
    {
        title: '手机号',
        dataIndex: 'customer.mobile',
        width: 180,
        scopedSlots: {customRender: 'mobile'},
    },
    {
        title: '交易类型',
        dataIndex: 'pay_channel',
        customRender: (channel: string, record: any) => {
            const status = record.status === 'REFUND' ? '[退款]' : '';
            return status + channel;
        }
    },
    {
        title: '订单金额(元)',
        dataIndex: 'total_amount',
        customRender: (totalAmount: any, record: any) => {
            return (record.status === 'REFUND' ? '-' : '') + priceCustomRender(totalAmount);
        }
    },
    {
        title: '进店红包(元)',
        dataIndex: 'coupon',
        key: 'coupon_NEWBIE',
        customRender: couponRenderFactory(['NEWBIE', 'OTHER|NEWBIE'])
    },
    {
        title: '拼手气红包(元)',
        dataIndex: 'coupon',
        key: 'coupon_LUCKY_PACKET',
        customRender: couponRenderFactory(['LUCKY_PACKET', 'OTHER|LUCKY_PACKET'])
    },
    {
        title: '定向发券(元)',
        dataIndex: 'coupon',
        key: 'coupon_ORIENTATION',
        customRender: couponRenderFactory(['ORIENTATION', 'OTHER|ORIENTATION'])
    },
    {
        title: '商场券(元)',
        dataIndex: 'coupon',
        key: 'coupon_MALL',
        customRender: mallCouponRender
    },
    {
        title: '商圈券(元)',
        dataIndex: 'hami_discount',
        key: 'hami_discount',
        customRender: priceCustomRender
    },
    {
        title: '实收金额(元)',
        dataIndex: 'pay_amount',
        customRender: (payAmount: any, record: any) => {
            return (record.status === 'REFUND' ? '-' : '') + priceCustomRender(payAmount);
        }
    },
    {
        title: '订单号',
        dataIndex: 'show_id',
        customRender: defaultCustomRender
    },
    {
        title: '交易时间',
        dataIndex: 'trade_time',
        customRender: (tradeTime: string) => {
            return tradeTime || '-';
        }
    },
    {
        title: '退款时间',
        dataIndex: 'refund_time',
        customRender: (refundTime: string) => {
            return refundTime || '-';
        }
    },
    {
        title: '交易状态',
        dataIndex: 'status',
    },
     {
        title: '备注',
        dataIndex: 'remarks',
        width: 250,
        height: 30,
        align: 'center',
        scopedSlots: {customRender: 'remarks'},
    }
];
