import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-zhongxin-control-active-status',
    options: [{
        label: '已生效',
        value: 'VALID'
    }, {
        label: '已失效',
        value: 'INVALID'
    }, {
        label: '未生效',
        value: 'INEFFECTIVE'
    }, {
        label: '报名结束',
        value: 'END'
    }
]
});
