import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            page,
            page_size: pageSize
        });
    },
    columns,

    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
}, {
    state: {
        selectedRows: [],
        openDebugIng: false
    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setOpenDebugLoading(state: any, loading: boolean) {
            state.openDebugIng = loading;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
    },
    actions: {
        async openDebug(context: any, open: boolean) {
            context.commit('setOpenDebugLoading', true);
            try {
                const selectedRowKeys: any[] = [];
                context.state.selectedRows.forEach((item: any) => selectedRowKeys.push(item.id));
                await services.openDebug({
                    batch_id: selectedRowKeys.join(','),
                    enabled: open ? 'True' : 'False'
                });
                context.dispatch('search');
            } catch (e) {

                context.commit('setError', e);
            } finally {
                context.commit('setOpenDebugLoading', false);
            }
        },
        async deleteCustomer(context: any) {
            try {
                const selectedRowKeys: any[] = [];
                context.state.selectedRows.forEach((item: any) => selectedRowKeys.push(item.id));
                await services.deleteCustomer({
                    ids: selectedRowKeys.join(',')
                });
                context.dispatch('search');
            } catch (e) {
                context.commit('setError', e);
            }
        }
    }
});
