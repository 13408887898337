import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: 'ID',
        dataIndex: 'id',
        width: 200,
        align: 'center'
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
        align: 'center',
        width: 200,
        scopedSlots: {customRender: 'mobile'},
    },
    {
        title: '是否开启调试',
        dataIndex: 'is_debug',
        align: 'center',
        width: 200,
        customRender: (value: boolean) => {
            return value ? '是' : '否';
        }
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 150,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
