var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        { staticClass: "ad-create-form", attrs: { form: _vm.otherForm } },
        [
          _c(
            "a-form-item",
            [
              _c("CreateModal", {
                attrs: { activityId: _vm.activityId },
                on: { searchData: _vm.search },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c("a-button", { attrs: { type: "primary" } }, [
                          _vm._v("新增"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "ShopUpload",
                {
                  attrs: {
                    activityId: _vm.activityId,
                    recordData: { merchantNo: null },
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "trigger" },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "20px" } },
                        [_vm._v("批量新增")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "" } },
            [
              _vm._v("\n            商户号："),
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["merchantNo"],
                    expression: "['merchantNo']",
                  },
                ],
                staticStyle: { width: "150px" },
                attrs: { maxLength: 15, placeholder: "请输入商户号" },
              }),
              _vm._v("\n                \n            终端号："),
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["termNo"],
                    expression: "['termNo']",
                  },
                ],
                staticStyle: { width: "150px" },
                attrs: { maxLength: 8, placeholder: "请输入终端号" },
              }),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.search },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "" } },
            [
              _c("a-table", {
                ref: "table",
                staticClass: "user-search-table",
                attrs: {
                  size: "middle",
                  rowKey: "activityId",
                  bordered: "",
                  columns: _vm.columns,
                  dataSource: _vm.lists,
                  scroll: {
                    x: _vm.calcTableScrollX(_vm.columns, 0),
                    y: _vm.fixed || undefined,
                  },
                  locale: {
                    emptyText: _vm.loading ? "加载中" : "无数据",
                  },
                  pagination: {
                    total: _vm.total,
                    pageSize: _vm.pageSize,
                    current: _vm.page,
                    showTotal: (total) => `总计 ${total} 条记录`,
                    onChange: _vm.handlePageChange,
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (value, record) {
                      return [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small", href: "javascript:;" },
                            on: { click: () => _vm.deleteShopData(record) },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "span",
                          { style: { paddingLeft: "5px" } },
                          [
                            _c("CreateModal", {
                              attrs: {
                                activityId: _vm.activityId,
                                recordData: record,
                              },
                              on: { searchData: _vm.search },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "trigger",
                                    fn: function () {
                                      return [
                                        _c(
                                          "a-button",
                                          { attrs: { size: "small" } },
                                          [_vm._v("修改")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }