import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 200,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '活动预算授权码',
        dataIndex: 'activityBudgetCode',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商户订单号',
        dataIndex: 'merOrderId',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '主账号',
        dataIndex: 'mainAccount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    } ,
    {
        title: 'Token号',
        dataIndex: 'token',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易类型',
        dataIndex: 'tradeType',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '清算日期',
        dataIndex: 'settleDate',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '交易时间',
        dataIndex: 'tradeTime',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '交易金额',
        dataIndex: 'tradeAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '优惠总金额',
        dataIndex: 'promoAmount',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商户补贴金额',
        dataIndex: 'cashbackAmount',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '银联优惠金额',
        dataIndex: 'unionpayPromoAmount',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '商户号',
        dataIndex: 'merNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '商户名称',
        dataIndex: 'merName',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '终端号',
        dataIndex: 'termNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '系统参考号',
        dataIndex: 'sysRefNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '系统跟踪号',
        dataIndex: 'sysTraceNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '受理机构代码',
        dataIndex: 'acptOrgCode',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '发送机构代码',
        dataIndex: 'sendOrgCode',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '营销类型',
        dataIndex: 'promoType',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: 'log_id',
        dataIndex: 'logId',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '原交易ori',
        dataIndex: 'origTradeKey',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '活动结项标志',
        dataIndex: 'endFlag',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '接收机构号',
        dataIndex: 'acptOrgNo',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '跨日清算标志',
        dataIndex: 'crossSettleFlag',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '交易类型名称',
        dataIndex: 'tradeTypeName',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '交易ID',
        dataIndex: 'tradeId',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    {
        title: '原交易ID',
        dataIndex: 'origTradeId',
        align: 'center',
        width: 300,
        customRender: defaultCustomRender
    },
    blankCell,
];
