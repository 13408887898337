var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("span", { on: { click: _vm.onClick } }, [_vm._t("trigger")], 2),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            width: 400,
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form,
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleClose },
        },
        [
          _c(
            "a-form",
            { staticClass: "account-create-form", attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "原密码" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[_0-9a-zA-Z]{6,20}$/,
                                message: "密码为数字、字母或下划线, 6到20位",
                              },
                            ],
                          },
                        ],
                        expression:
                          "['password',{rules: [{required: true, message: '请填写必填项!'}, {pattern: /^[_0-9a-zA-Z]{6,20}$/,  message: '密码为数字、字母或下划线, 6到20位'}]}]",
                      },
                    ],
                    attrs: { type: "password", placeholder: "请输入" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "新密码" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "new_password",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[_0-9a-zA-Z]{6,20}$/,
                                message: "密码为数字、字母或下划线, 6到20位",
                              },
                            ],
                          },
                        ],
                        expression:
                          "['new_password',{rules: [{required: true, message: '请填写必填项!'}, {pattern: /^[_0-9a-zA-Z]{6,20}$/,  message: '密码为数字、字母或下划线, 6到20位'}]}]",
                      },
                    ],
                    attrs: { type: "password", placeholder: "请输入" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "确认密码" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "new_password_confirm",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[_0-9a-zA-Z]{6,20}$/,
                                message: "密码为数字、字母或下划线, 6到20位",
                              },
                            ],
                          },
                        ],
                        expression:
                          "['new_password_confirm', {rules: [{ required: true, message: '请填写必填项!' }, {pattern: /^[_0-9a-zA-Z]{6,20}$/,  message: '密码为数字、字母或下划线, 6到20位'}]}]",
                      },
                    ],
                    attrs: { type: "password", placeholder: "请输入" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }