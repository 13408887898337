import {request_yx,downloadRequest_yx} from '@/utils';
import qs from 'querystringify';
export async function search(params: any) {

    try {
        return await request_yx({
            api: '/console/activity/subsidyRecord/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        throw error;
    }
}


export async function downloadXls(params: any) {
    return await downloadRequest_yx({
        data: params,
        prefix: 'subsidyRecord',
        api: '/console/activity/subsidyRecord/file/download'
    });
}



