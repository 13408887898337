
import { Modal } from 'ant-design-vue';
import { flatAndUniqueMaterialsTerminals } from '@/utils';
import * as Constant from '@/common/constant';
export default {
    namespaced: true,
    state: {
        payType: Constant.WX_TYPE
    },
    mutations: {
        setType(state: any, type: string) {
            state.payType = type;
        },
    },
    actions: {

    },

};



