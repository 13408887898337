
import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker,FixedRate,
    FixedRatio,
    FixedAmount} from '@/components/custom-form-components';
import moment from 'moment';
import SubsidyRule from '@/components/custom-form-components/SubsidyRule.vue';
interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DisableDatePicker,
            FixedRate,
            FixedRatio,
            FixedAmount,
            SubsidyRule
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.merSubsidyRuleSearchPage.loading,
                
                formData(state: any) {
                    return state.merSubsidyRuleSearchPage.data && this.formatFormData(state.merSubsidyRuleSearchPage.data);
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        public activityId:any ;
        public createFlag = 'edit';
        public flag = true;
        public rateListData:any;
        public ruleListData:any;
        public isNewRule:any;
        public rules:any;
        public fixedRateShow: boolean = true;
        public ratioListData:any;
        public fixedRatioShow: boolean = false;
        public amountListData:any;
        public fixedAmountShow: boolean = false;
        public accModeType:boolean = false;
        form: any;
        otherForm: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this),
                rateListData: [{key:0}],
                rules:[],
                fixedRateShow:true,
                ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
                fixedRatioShow:false,
                amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
                ruleListData:[{key:0,realSubRate:'0',subAmtGtTrans: '2', subAmtGtFee: '2',subsidyType:'1', settleMode: null}],
                fixedAmountShow:false,
                accModeType:false
            };
        }
        public created() {
            
        }
        
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    const mustAmtPattern = /(^[1-9]{1}\d{0,6}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)|(^[0]{1}$)/;
                    const mustAmtPattern1 = /(^[1-9]{1}\d{0,6}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)|(^[0]{1}$)/;
                    const ratePattern = /^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/;
                    this.ruleListData= this.$refs.ruleChild.ruleListData;
                    this.rules = this.ruleListData;
                    console.log(this.ruleListData)
                    for(let ruleData of this.ruleListData){
                        if(!values.accModeType){
                            ruleData.realSubRate = '0';
                        }
                        if(!mustAmtPattern1.test(ruleData.minAmt) || !mustAmtPattern1.test(ruleData.maxAmt) || parseFloat(ruleData.minAmt) > 9999999 || parseFloat(ruleData.maxAmt) > 9999999 ){
                            this.$message.error('交易金额区间值必须0-9999999');
                            return;
                        }
                        const a = parseFloat(ruleData.minAmt);
                        const b = parseFloat(ruleData.maxAmt);
                        if(b < a){
                            this.$message.error('交易金额区间最大值必须小于最小值。'+a+"-"+b);
                            return false;
                        }
                        if((ruleData.minRate && !ruleData.maxRate) || (!ruleData.minRate && ruleData.maxRate)){
                            this.$message.error('交易费率区间填写不完整');
                            return;
                        }
                        if((ruleData.minRate && !ratePattern.test(ruleData.minRate)) || (ruleData.maxRate && !ratePattern.test(ruleData.maxRate))){
                            this.$message.error('交易费率0-100,且只能有两位小数');
                            return;
                        }
                        const c = parseFloat(ruleData.minRate);
                        const d = parseFloat(ruleData.maxRate);
                        if(d < c){
                            this.$message.error('交易费率区间最大值必须小于最小值。'+c+"-"+d);
                            return false;
                        }
                        if('3' != ruleData.subsidyType){
                            //新规则页面提示
                            if(!values.subsidyType){
                                if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(ruleData.realSubRate)){
                                    this.$message.error('实补比例0-100,且只能有两位小数');
                                    return;
                                }
                            }

                            if(ruleData.minSubsidyAmt && (!mustAmtPattern.test(ruleData.minSubsidyAmt) || parseFloat(ruleData.minSubsidyAmt) > 9999999)){
                                this.$message.error('单笔补贴最低金额0-9999999');
                                return;
                            }

                            if(ruleData.maxSubsidyAmt && (!mustAmtPattern.test(ruleData.maxSubsidyAmt) || parseFloat(ruleData.maxSubsidyAmt) > 9999999)){
                                this.$message.error('单笔补贴封顶金额0-9999999');
                                return;
                            }
                        }
                        
                        
                        if('1' == ruleData.subsidyType){
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(ruleData.rate)){
                                this.$message.error('优惠后费率0-100,且只能有两位小数');
                                return;
                            }
                        }else if('2' == ruleData.subsidyType){
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(ruleData.ratio)){
                                this.$message.error('补贴比例只能有两位小数');
                                return;
                            }
                        }else if('3' == ruleData.subsidyType){
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(ruleData.transAmt)){
                                this.$message.error('补贴金额只能是数字且有两位小数');
                                return;
                            }
                        }
                        console.log(ruleData.payMode)
                        if(!values.subsidyType){
                            if(ruleData.payMode == undefined || ruleData.payMode.length == 0){
                                this.$message.error('交易类型限制必填');
                                return;
                            }
                        }
                        
                    }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'merSubsidyRuleSearchPage/editAct',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.merSubsidyRuleSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$message.success('提交成功');
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onCancel');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
       
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            let accMode = '0';
            if(formData.accModeType){
                accMode = '1';
            }
            return {
                activityId:this.activityId,
                ruleId:formData.ruleId,
                name: formData.name,
                payMode:formData.payMode,
                // startTime: formData.activity_time[0].format(
                //     'YYYYMMDDHHmmss'
                // ),
                // endTime: formData.activity_time[1].format(
                //     'YYYYMMDDHHmmss'
                // ),
                subsidyType:formData.subsidyType,
                rules:this.rules,
                accMode:accMode
            };
        }

        
        private formatFormData(rawData: any) {
            let rulesTemp =  rawData.rules;
            this.ruleListData = rulesTemp;
            if(rawData.accMode == '1'){
                this.accModeType = true;
            }
            if(rawData.subsidyType){
                if('3' == rawData.subsidyType){
                    this.amountListData = rulesTemp;
                    this.fixedRateShow = false;
                    this.fixedRatioShow = false;
                    this.fixedAmountShow = true;
                }else if('2' == rawData.subsidyType){
                    this.ratioListData = rulesTemp;
                    this.fixedRateShow = false;
                    this.fixedRatioShow = true;
                    this.fixedAmountShow = false;
                }else{
                    this.rateListData = rulesTemp;
                    this.fixedRateShow = true;
                    this.fixedRatioShow = false;
                    this.fixedAmountShow = false;
                }
            }
            //交易类型必输，新规则隐藏次参数会报错，故赋值，后端对此不作处理
            let payMode = ["01"];
            if(rawData.payMode){
                payMode = rawData.payMode;
            }
            this.isNewRule = rawData.isNewRule;
            this.activityId = rawData.activityId;
            return {
                // activity_time: [
                //     rawData.startTime && moment(rawData.startTime),
                //     rawData.endTime && moment(rawData.endTime)
                // ],
                accModeType:this.accModeType,
                payMode1:payMode,
                ...rawData,
            }
        }
    }

    export default createForm;
