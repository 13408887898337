
import { Component, Inject, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as DICTS from '@/common/dicts';
import columns from './columns';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import { check } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import * as services from './services';
interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {},
    computed: {
        ...mapState({
            list: (state: any) =>
                state.activityMerBatchSearchPage.list.map(
                    (item: any, idx: any) => ({
                        idx:
                            (state.activityMerBatchSearchPage.page - 1) *
                                state.activityMerBatchSearchPage.pageSize +
                            idx +
                            1,
                        ...item
                    })
                ),
            total: (state: any) => state.activityMerBatchSearchPage.total,
            pageSize: (state: any) => state.activityMerBatchSearchPage.pageSize,
            page: (state: any) => state.activityMerBatchSearchPage.page,
            loading: (state: any) => state.activityMerBatchSearchPage.loading,
            fields: (state: any) =>
                normColumns(
                    columns,
                    state.activityMerBatchSearchPage.fields,
                    state.activityMerBatchSearchPage.sortFields
                ),
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys =
                    state.activityMerBatchSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            }
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit(
            'activityMerBatchSearchPage/setSelectedRows',
            selectedRowKeys
        );
    }

    public handlePageChange(page: number) {
        this.$store.commit('activityMerBatchSearchPage/setPagination', {
            page
        });
        this.$router.push({
            path:
                '/console/activityMerBatch/search' +
                this.$store.getters['activityMerBatchSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('activityMerBatchSearchPage/setPagination', {
            pageSize,
            page: 1
        });
        this.$router.push({
            path:
                '/console/activityMerBatch/search' +
                this.$store.getters['activityMerBatchSearchPage/queryString']()
        });
    }
    public async handleDownloadClick(record: any) {
        if (!record.batchUrl) return;
        this.$message.loading('导出中...', 0);
        await services.downloadCSV({url: record.batchUrl, id: record.activityId});
        this.$message.destroy();
        this.$message.success('导出完成!')
    }
}

export default SearchTable;
