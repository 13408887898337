const link = {pattern: /^https?\:\/\//, message: '请输入http://或https://!'};

export const MALL = {
    NAME: [{max: 30, message: '长度超限，请检查后重新输入!'}],
    APPID: [{max: 36, message: '长度超限，请检查后重新输入!'}],
    PRIVATE_KEY: [{max: 36, message: '长度超限，请检查后重新输入!'}],
    PUBLIC_KEY: [{max: 36, message: '长度超限，请检查后重新输入!'}],
    AGREEMENT_TITLE: [{max: 100, message: '长度超限，请检查后重新输入!'}],
    AGREEMENT_URL: [{max: 300, message: '长度超限，请检查后重新输入!'}, link],
    ADDRESS: [{max: 50, message: '长度超限，请检查后重新输入!'}]
};

export const STORE = {
    NAME: [{max: 60, message: '长度超限，请检查后重新输入!'}],
    AD_LINK: [{max: 300, message: '长度超限，请检查后重新输入!'}, link],
    FLOOR_DOOR_NO: [{max: 300, message: '长度超限，请检查后重新输入!'}]
};

export const BIZ_DISTRICT = {
    NAME: [{max: 30, message: '长度超限，请检查后重新输入!'}],
    NAME_ENGLISH: [{max: 60, message: '长度超限，请检查后重新输入!'}],
    AGREEMENT_TITLE: [{max: 100, message: '长度超限，请检查后重新输入!'}],
    AGREEMENT_URL: [{max: 300, message: '长度超限，请检查后重新输入!'}, link],
    LINK_URL: [{max: 500, message: '长度超限，请检查后重新输入!'}, link]
};

export const COMMON = {
    link: [{pattern: /^https?\:\/\//, message: '请输入http://或https://!'}],
    number: [{pattern: /^\d+$/, message: '必须为数字'}]
};
