
    import {Modal} from 'ant-design-vue';
    import {Component, Vue} from 'vue-property-decorator';
    import {CreateParams} from './services';
    import {mapState} from 'vuex';
    import {BIZ_DISTRICT as BIZ_DISTRICT_RULES} from '@/common/formItemRules';
    import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
    import UploadImage from '@/components/custom-form-components/UploadImage.vue';
    import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
    import config from '@/config';

    interface SearchForm {
        form: any;
        hasLogo: boolean;
    }

    @Component({
        components: {
            DistrictBelong,
            UploadImage,
            ImageCrop
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.bizDistrictCreatePage.loading
            })
        }
    })
    class SearchForm extends Vue {
        public data() {
            return {
                BIZ_DISTRICT_RULES,
                config,
                form: this.$form.createForm(this),
                hasLogo: false
            };
        }

        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'bizDistrictCreatePage/create',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }

        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    const queryString = this.$store.getters['bizDistrictSearchPage/queryString']();
                    this.$router.push({
                        path: '/console/bizDistrict/search' + queryString
                    });
                },
                onCancel() {
                    // do nothing
                }
            });
        }

        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any): CreateParams {
            return {
                name: formData.name,
                name_english: formData.name_english,
                logo: formData.logo && formData.logo[0] && formData.logo[0].url,
                agreement_title: formData.agreement_title,
                agreement_url: formData.agreement_url,
                link_url: formData.link_url,
                belong: formData.belong
            };
            return formData;
        }
    }

    export default SearchForm;
