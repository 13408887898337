var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-tabs",
    {
      on: { change: _vm.handleChangePay },
      model: {
        value: _vm.tabkey,
        callback: function ($$v) {
          _vm.tabkey = $$v
        },
        expression: "tabkey",
      },
    },
    [
      _c(
        "a-tab-pane",
        {
          key: "wx",
          staticStyle: { padding: "20px" },
          attrs: { tab: "微信全场活动(指定终端)" },
        },
        [
          _c(
            "a-tabs",
            {
              staticStyle: { overflow: "visible" },
              attrs: { activeKey: _vm.activeKey, animated: false },
              on: { change: _vm.handleChange },
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: "open",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "运营中列表" },
                },
                [
                  _c("Show", {
                    attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_OPEN.TAB] },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("OpenSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "open",
                                paytype: "wx",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "draft",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "草稿箱列表" },
                },
                [
                  _c("Show", {
                    attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_DRAFT.TAB] },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("DraftSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "draft",
                                paytype: "wx",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "stop",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "已停止列表" },
                },
                [
                  _c("Show", {
                    attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_STOP.TAB] },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("StopSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "stop",
                                paytype: "wx",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "wxCashCouponActivity",
          staticStyle: { padding: "20px" },
          attrs: { tab: "微信活动(代金券&全场)" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_CITIC.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("ZhongxinSearchPage", {
                      attrs: {
                        active: _vm.activeKey === "stop",
                        paytype: "wxCashCouponActivity",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "union",
          staticStyle: { padding: "20px" },
          attrs: { tab: "银联U点活动" },
        },
        [
          _c(
            "a-tabs",
            {
              staticStyle: { overflow: "visible" },
              attrs: { activeKey: _vm.activeKey, animated: false },
              on: { change: _vm.handleChange },
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: "open",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "运营中列表" },
                },
                [
                  _c("Show", {
                    attrs: {
                      requires: [_vm.PRIVILEGE.UNION_ACTIVITIES_OPEN.TAB],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("OpenSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "open",
                                paytype: "union",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "draft",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "草稿箱列表" },
                },
                [
                  _c("Show", {
                    attrs: {
                      requires: [_vm.PRIVILEGE.UNION_ACTIVITIES_DRAFT.TAB],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("DraftSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "draft",
                                paytype: "union",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "stop",
                  staticStyle: { padding: "20px" },
                  attrs: { tab: "已停止列表" },
                },
                [
                  _c("Show", {
                    attrs: {
                      requires: [_vm.PRIVILEGE.UNION_ACTIVITIES_STOP.TAB],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("StopSearchPage", {
                              attrs: {
                                active: _vm.activeKey === "stop",
                                paytype: "union",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "unionb2c",
          staticStyle: { padding: "20px" },
          attrs: { tab: "银联B2C活动(自营)" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_UNIONB2C.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("Unionb2cSearchPage", {
                      attrs: {
                        active: _vm.activeKey === "stop",
                        paytype: "unionb2c",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "unionCoupon",
          staticStyle: { padding: "20px" },
          attrs: { tab: "银联代金券活动" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_UNIONCOUPON.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("UnionCouponSearchPage", {
                      attrs: {
                        active: _vm.activeKey === "stop",
                        paytype: "unionCoupon",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }