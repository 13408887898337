var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("a-select", {
        attrs: {
          options: _vm.options,
          placeholder: "请选择",
          value: _vm.options.length === 0 ? null : _vm.value,
          getPopupContainer: (trigger) => trigger.parentNode,
          allowClear: "",
        },
        on: { change: _vm.handleChange },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTip,
              expression: "showTip",
            },
          ],
          staticStyle: {
            "font-size": "12px",
            "text-align": "left",
            clear: "both",
            color: "#EC4A3B",
          },
        },
        [_vm._v("\n        备注：商圈商户，请务必填写归属商圈\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }