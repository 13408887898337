import {request} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request({
            api: '/console/store/sn/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取SN列表失败，请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function unbind(params: {
    sn: string;
    merchant_no: string;
    offline_terminal_id:string;
}) {
    try {
        return await request({
            api: '/console/store/sn/unbind',
            data: params
        });
    } catch (e) {
        const error: any = new Error('解绑失败，请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
