var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "canvas",
      style: {
        width:
          (this.width
            ? _vm.width > _vm.height
              ? this.CANVAS_MAX_WIDTH
              : _vm.width * _vm.r
            : 0) + "px",
        height:
          (this.height
            ? _vm.height >= _vm.width
              ? this.CANVAS_MAX_HEIGHT
              : _vm.height * _vm.r
            : 0) + "px",
        overflow: "hidden",
        position: "relative",
        clear: "both",
      },
    },
    [
      _c("img", {
        ref: "image",
        style: {
          width: _vm.width > _vm.height ? "100%" : "auto",
          height: _vm.width <= _vm.height ? "100%" : "auto",
          position: "absolute",
          top: 0,
          left: 0,
          "-webkit-user-select": "none",
          "-moz-user-select": "none",
          "-khtml-user-select": "none",
          "-ms-user-select": "none",
        },
        attrs: { src: _vm.image },
        on: { load: _vm.imageLoaded, error: _vm.imageError },
      }),
      _vm._t("content", null, {
        x: _vm.x,
        y: _vm.y,
        width: _vm.width * _vm.r,
        height: _vm.height * _vm.r,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }