var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-tabs",
    {
      staticStyle: { overflow: "visible" },
      attrs: { activeKey: _vm.activeKey, animated: false },
      on: { change: _vm.handleChange },
    },
    [
      _c(
        "a-tab-pane",
        {
          key: "user",
          staticStyle: { padding: "20px" },
          attrs: { tab: "平台用户列表" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.USER.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("UserSearchPage", {
                      attrs: { active: _vm.activeKey === "user" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "role",
          staticStyle: { padding: "20px" },
          attrs: { tab: "用户权限配置" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.ROLE.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("RoleSearchPage", {
                      attrs: { active: _vm.activeKey === "role" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "whitelist",
          staticStyle: { padding: "20px" },
          attrs: { tab: "白名单" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.WHITELIST.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("WhitelistSearchPage", {
                      attrs: { active: _vm.activeKey === "whitelist" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "customer",
          staticStyle: { padding: "20px" },
          attrs: { tab: "惠码用户管理" },
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.CUSTOMER.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("CustomerSearchPage", {
                      attrs: { active: _vm.activeKey === "customer" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }