
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import moment from 'moment';
    import {timeRangeFormat} from '@/utils';
    import Region from '@/components/custom-form-components/Region.vue';
    import SearchFormWrapper from '@/components/SearchFormWrapper.vue';
    import {
        DatePicker
    } from '@/components/custom-form-components';

    interface Query {
        mall_id: string;
        nick_name: string;
        member_no: string;
        gender: string;
        country: string;
        province: string;
        city: string;
        mobile: string;
        start_time: string;
        end_time: string;
    }

    interface FormData {
        mall_id: string;
        nick_name: string;
        member_no: string;
        gender: string;
        country: string;
        region: string[];
        mobile: string;
        time_range: any[];
    }

    @Component({
        components: {
            SearchFormWrapper,
            Region,
            DatePicker
        },
        computed: {
            ...mapState({
                loading(state) {
                    return this.state().loading;
                },
                formData() {
                    return this.queryToFormData(this.state().query);
                }
            })
        }
    })
    class SearchForm extends Vue {
        @Inject()
        public state!: any;

        @Inject()
        public dispatch!: any;

        public data() {
            return {
                collapsed: false,
            };
        }

        public format(values: FormData): Query {

            return {
                mall_id: values.mall_id,
                nick_name: values.nick_name,
                member_no: values.member_no,
                gender: values.gender,
                country: values.country,
                province: values.region && values.region[0],
                city: values.region && values.region[1],
                mobile: values.mobile,
                ...timeRangeFormat(values.time_range)
            };
        }

        public queryToFormData(query: Query) {
            return {
                mall_id: query.mall_id,
                nick_name: query.nick_name,
                member_no: query.member_no,
                gender: query.gender,
                country: query.country,
                region: [query.province, query.city].map((r: any) => r || undefined),
                mobile: query.mobile,
                time_range: [query.start_time, query.end_time].map((datetime: string) => datetime && moment(datetime))
            };
        }

        public handleSearch(query: Query) {
            this.dispatch('queryChange', query);
        }
    }

    export default SearchForm;
