import {request, requestStream} from '@/utils';

export async function create(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/ad/plan/create',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function countConflict(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/ad/plan/conflict_merchants/count',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function getConflictMerchants(params: any) {
    try {
        return await requestStream({
            method: 'POST',
            api: '/console/ad/plan/conflict_merchants/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function getConflictDefaultAdPlan(params: {planId: number; position: string; terminal: string}) {
    try {
        return await requestStream({
            method: 'GET',
            api: '/console/ad/plan/conflict_default/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('提交失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
