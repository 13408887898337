import {RouteConfig} from 'vue-router';
import Index from './AuthWrapper.vue';

const route: RouteConfig[] = [
    {
        path: 'newbie-data/search',
        name: '进店红包营销搜索',
        component: Index,
    },
    {
        path: 'lucky-packet-data/search',
        name: '拼手气红包营销搜索',
        component: Index,
    },
    {
        path: 'orientation-data/search',
        name: '定向红包营销搜索',
        component: Index,
    },
    {
        path: 'mall-data/search',
        name: '商场券营销搜索',
        component: Index,
    },
    {
        path: 'other-data/search',
        name: '商圈券营销搜索',
        component: Index,
    },
];

export default route;
