var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          span: 24,
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.columns,
          dataSource: _vm.lists,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据",
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.columns, 62),
            y: _vm.fixed || undefined,
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: (total) => `总计 ${total} 条记录`,
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "mobile",
            fn: function (text, record) {
              return _c("span", {}, [
                text.includes("*")
                  ? _c(
                      "span",
                      [
                        _c("ClearText", {
                          attrs: {
                            requires: [_vm.PRIVILEGE.USER_MOBILE],
                            text: text,
                            action: "/console/riskcontrolblacklist/get/mobile",
                            getClearText: (result) => result.mobile,
                            params: { id: record.id },
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                " + _vm._s(text) + "\n            "
                      ),
                    ]),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }