
    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
    import BulkSelector from '@/components/custom-form-components/BulkSelector/index.vue';
    import {request, deleteEmpty} from '@/utils';
    @Component<MerchantPanel>({
        components: {
            BulkSelector
        }
    })
    class MerchantPanel extends Vue {

        @Prop()
        public adPlanId!: any;

        public form: any;

        public columns = [{
            title: '商户名称',
            dataIndex: 'name'
        }, {
            title: '商户号',
            dataIndex: 'merchant_no',
            width: 300
        }, {
            title: '线下终端号',
            dataIndex: 'offline_terminal_id',
            width: 150
        }];

        public query: any = {
            plan_id: this.adPlanId
        };

        public loading = false;

        public selectedMerchantNos = [];

        public async handleRemoveClick() {
            if (this.selectedMerchantNos && this.selectedMerchantNos.length > 0) {
                Modal.confirm({
                    title: '删除确认',
                    content: '确定删除吗?',
                    onOk: async () => {
                        try {
                            this.loading = true;
                            await request({
                                method: 'POST',
                                api: '/console/ad/plan/assign_merchants/remove',
                                data: {
                                    plan_id: this.adPlanId,
                                    auids: this.selectedMerchantNos
                                }
                            });
                            (this.$refs.table as BulkSelector).search(1);
                            this.selectedMerchantNos = [];
                        } catch (e) {
                            this.$message.error(e.message);
                        } finally {
                            this.loading = false;
                        }
                    }
                });
            }
        }

        public created() {
            this.form = this.$form.createForm(this);
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }

                this.query = deleteEmpty({
                    plan_id: this.adPlanId,
                    ...values
                });
            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }
    }

    export default MerchantPanel;
