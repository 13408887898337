
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {request_yx} from '@/utils';
    import { Modal } from 'ant-design-vue';

    @Component({
        components: {}
    })
    class Role extends Vue {

        @Prop()
        public value!: string;
        @Prop()
        public type!: string;
        public items = [];
        public flag = false;
        public loading = false;
        public tmpValue!: string;
        public policyData = [];

        public async created() {
            this.fetch();
        }

        public async fetch() {
            try {
                this.items = [];
                this.loading = true;
                
                const ret =  await request_yx({
                    api: '/console/activity/subsidyRuleNew/queryActivityList',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const list = ret;
                this.items = list.map((i: any) => ({
                    activityId: i.activityId,
                    activityName:i.activityName,
                    name:i.activityId+'-'+i.activityName,
                    ownerId:i.ownerId,
                    expenseAccount:i.expenseAccount,
                    activityTime:i.activityStartTime+'至'+i.activityEndTime
                }));
                this.policyData = list
            } catch (e) {
                    // this.$message.error('获取列表失败, 请联系技术人员!');
            } finally {
                this.loading = false;
            }
        }

        public dropdownVisibleChange(open: number) {
            console.log('on dropdownVisibleChange:', open);
            if (open) {
                this.fetch();
            }
        }

        public data() {
            return {
                dataSource: []
            };
        }


        public onInputKeyDown(value:String){
            // console.log(this.flag)
            // if(this.flag){
            //     Modal.confirm({
            //         title: '提示',
            //         content: '更新活动后页面将重新赋值',
            //         onOk: () => {
                       
            //         },
            //         onCancel() {
            //             // do nothing
            //         }
            //     });
            // }
        }

        public handleChange(value: string) {
            const that = this
            // if (that.flag) {
            //     Modal.confirm({
            //         title: '提示',
            //         content: '活动ID改变会初始化规则ID页面参数',
            //         onOk: () => {
            //             that.changeVal(value, that);
            //         },
            //         onCancel() {
            //             that.value = that.tmpValue
            //         }
            //     });
            // } else {
            //     that.changeVal(value, that);
            // }
            that.changeVal(value, that);
        }

        public async changeVal(value: string, that: any) {
            let formData = {};
            //规则管理页面不需要查询规则列表
            if(this.type != '1'){
                if (value == undefined) {
                    formData = {}
                } else {
                    const ret = await request_yx({
                        api: '/console/activity/subsidyRuleNew/queryRuleList',
                        method: 'POST',
                        data: {activityId:value},
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                    });
                    formData = ret;
                    that.flag = true;
                }
            }else{
                for(const item of this.items){
                    if(value == item.activityId){
                        formData = item;
                    }
                }
            }
            that.tmpValue = value;
            that.$emit('initFormData', formData);
            that.$emit('change', value);
        }

        public filterOption(input: string, option: any) {
            return (
                option.componentOptions.children[0].text
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
            );
        }
    }

    export default Role;
