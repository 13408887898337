import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-org',
    options: [{
        label: '安徽分公司(收单)',
        value: '安徽分公司(收单)'
    }, {
        label: '河南分公司(收单)',
        value: '河南分公司(收单)'
    }, {
        label: '湖南分公司(收单)',
        value: '湖南分公司(收单)'
    }, {
        label: '江西分公司(收单)',
        value: '江西分公司(收单)'
    }, {
        label: '天津分公司(收单)',
        value: '天津分公司(收单)'
    }, {
        label: '河北分公司(收单)',
        value: '河北分公司(收单)'
    }, {
        label: '山西分公司(收单)',
        value: '山西分公司(收单)'
    }, {
        label: '内蒙古分公司(收单)',
        value: '内蒙古分公司(收单)'
    }, {
        label: '沈阳分公司(收单)',
        value: '沈阳分公司(收单)'
    }, {
        label: '大连分公司(收单)',
        value: '大连分公司(收单)'
    }, {
        label: '吉林分公司(收单)',
        value: '吉林分公司(收单)'
    }, {
        label: '黑龙江分公司(收单)',
        value: '黑龙江分公司(收单)'
    }, {
        label: '上海分公司(收单)',
        value: '上海分公司(收单)'
    }, {
        label: '江苏分公司(收单)',
        value: '江苏分公司(收单)'
    }, {
        label: '浙江分公司(收单)',
        value: '浙江分公司(收单)'
    }, {
        label: '福建分公司(收单)',
        value: '福建分公司(收单)'
    }, {
        label: '济南分公司(收单)',
        value: '济南分公司(收单)'
    }, {
        label: '青岛分公司(收单)',
        value: '青岛分公司(收单)'
    }, {
        label: '淄博分公司(收单)',
        value: '淄博分公司(收单)'
    }, {
        label: '湖北分公司(收单)',
        value: '湖北分公司(收单)'
    }, {
        label: '广州分公司(收单)',
        value: '广州分公司(收单)'
    }, {
        label: '深圳分公司(收单)',
        value: '深圳分公司(收单)'
    }, {
        label: '广西分公司(收单)',
        value: '广西分公司(收单)'
    }, {
        label: '海南分公司(收单)',
        value: '海南分公司(收单)'
    }, {
        label: '四川分公司(收单)',
        value: '四川分公司(收单)'
    }, {
        label: '重庆分公司(收单)',
        value: '重庆分公司(收单)'
    }, {
        label: '贵州分公司(收单)',
        value: '贵州分公司(收单)'
    }, {
        label: '云南分公司(收单)',
        value: '云南分公司(收单)'
    }, {
        label: '陕西分公司(收单)',
        value: '陕西分公司(收单)'
    }, {
        label: '甘肃分公司(收单)',
        value: '甘肃分公司(收单)'
    }, {
        label: '厦门分公司(收单)',
        value: '厦门分公司(收单)'
    }, {
        label: '青海分公司(收单)',
        value: '青海分公司(收单)'
    }, {
        label: '新疆分公司(收单)',
        value: '新疆分公司(收单)'
    }, {
        label: '宁夏分公司(收单)',
        value: '宁夏分公司(收单)'
    }, {
        label: '北京分公司(收单)',
        value: '北京分公司(收单)'
    }, {
        label: '宁波分公司(收单)',
        value: '宁波分公司(收单)'
    }, {
        label: '西藏分公司(收单)',
        value: '西藏分公司(收单)'
    }]
    , extraProps: {
        showSearch: true,
        optionFilterProp: 'children',
        filterOption(input: any, option: any) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
    }
});
