<template>
    <a-drawer
        :visible="visible"
        title="报名详情"
        width="800"
        placement="right"
        closable
        @close="close"
    >
        <div class="content">
            <div class="cell" v-for="(value, key) in showKeys" :key="key">
                <div class="label">{{ value }}：</div>
                <div class="desc">{{ detailData[key] || '/' }}</div>
            </div>
        </div>
    </a-drawer>
</template>
<script>
import { bankAuditExt } from './services';
export default {
    name: 'verifyDetail',
    data() {
        return {
            visible: false,
            detailData: {},
            showKeys: {
                activityId: '活动ID',
                merNo: '商户号',
                merName: '商户名称',
                referral: '推荐人',
                merMcc: '商户MCC',
                merCity: '商户地区市',
                orgName: '商户所属机构名',
                tokerOrgNo: '商户所属合作方编号',
                tokerOrgName: '商户所属合作方名',
                dtbitCard: '结算卡号',
                bankId: '银行联行号',
                bankName: '银行名称',
                bankStaffNo: '银行人员工号',
                bankStaffName: '银行人员姓名',
                bankOrgNo: '银行内部机构号',
                bankRemarks: '银行备注信息',
                attrStr1: '备注1',
                attrStr2: '备注2',
                attrStr3: '备注3'
            }
        };
    },
    methods: {
        open(record) {
            const { enrollId, activityId, merNo, referral } = record;
            bankAuditExt({ enrollId })
                .then(res => {
                    const result = res || {};
                    this.detailData = Object.assign({}, result, {
                        activityId,
                        merNo,
                        referral
                    });
                    this.visible = true;
                })
                .catch(e => {
                    this.$message.error(e.message);
                });
        },
        close() {
            this.visible = false;
        }
    }
};
</script>
<style lang="less" scoped>
.content {
    display: flex;
    flex-wrap: wrap;
}
.cell {
    width: calc(50% - 8px);
    line-height: 36px;
    display: flex;
    margin-right: 8px;
    border-bottom: 1px solid #e0e0e0;

    .label {
        min-width: 55px;
        color: #333;
    }
    .desc {
        color: #666;
    }
}
</style>
