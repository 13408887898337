
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import config from '@/config';

@Component({
    components: {},
    computed: {
        ...mapState({
            loading: (state: any) => state.sharedQuotaConfSearchPage.loading,
            formData(state: any) {
                return (
                    state.sharedQuotaConfSearchPage.data &&
                    this.formatFormData(state.sharedQuotaConfSearchPage.data)
                );
            }
        })
    }
})
class AddForm extends Vue {
    form: any;
    public data() {
        return {
            config,
            form: this.$form.createForm(this)
        };
    }
    public created() {}

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch(
                            'sharedQuotaConfSearchPage/bindMer',
                            values
                        );
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.sharedQuotaConfSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$emit('onCancel');
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onCancel');
            }
        });
    }

    private formatFormData(rawData: any) {
        return {
            merGroupId: rawData.merGroupId,
            merGroupName: rawData.merGroupName
        };
    }
}

export default AddForm;
