import {
    defaultCustomRender,bankActivityMerStatus,timeCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center',
    },
    {
        title: '规则ID',
        dataIndex: 'ruleId',
        width: 200,
        fixed: 'left',
        align: 'center',
        customRender: defaultCustomRender
    },
    // {
    //     title: '规则名称',
    //     dataIndex: 'name',
    //     width: 200,
    //     fixed: 'left',
    //     align: 'center',
    //     customRender: defaultCustomRender
    // },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    // {
    //     title: '规则开始时间',
    //     dataIndex: 'startTime',
    //     align: 'center',
    //     width: 200,
    //     customRender: (time: any) => {
    //         return timeCustomRender(time);
    //     }
    // },
    // {
    //     title: '规则结束时间',
    //     dataIndex: 'endTime',
    //     align: 'center',
    //     width: 200,
    //     customRender: (time: any) => {
    //         return timeCustomRender(time);
    //     }
    // },
    {
        title: '活动业主方',
        dataIndex: 'ownerId',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '头寸编号',
        dataIndex: 'expenseAccount',
        align: 'center',
        width: 200,
        customRender:defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 100,
        customRender: (status: any) => {
            return bankActivityMerStatus(status);
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '修改人',
        dataIndex: 'updateBy',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
