import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import store from '@/store';

store.registerModule(
    'graySearchPage',
    create(
        {
            search(query, page, pageSize) {
                return services.search({
                    ...query,
                    pageIndex: page,
                    pageSize: pageSize
                });
            },

            columns,
            beforeSearch(context: any) {
                context.commit('setSelectedRows', []);
            }
        },
        {
            state: {
                updatingStatus: [],
                openingStatus: [],
                joiningStatus: [],
                updateStatusError: null,
                openStatusError: null,
                joinStatusError: null,
                selectedRows: []
            },
            mutations: {
                setSelectedRows(state: any, rows: any) {
                    state.selectedRows = rows;
                },
                updateOneStatus(state: any, { id, status }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], status };
                    }
                    state.list = list;
                },
                openOneStatus(state: any, { id, ready }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], ready };
                    }
                    state.list = list;
                },
                joinOneStatus(state: any, { id, cache }: any) {
                    const list = [...state.list];
                    const index = list.findIndex(item => item.id === id);
                    if (index >= 0) {
                        list[index] = { ...list[index], cache };
                    }
                    state.list = list;
                },
                updateStatusRequested(state: any, id: any) {
                    state.updatingStatus = [...state.updatingStatus, id];
                },
                openStatusRequested(state: any, id: any) {
                    state.openingStatus = [...state.openingStatus, id];
                },
                joinStatusRequested(state: any, id: any) {
                    state.joiningStatus = [...state.joiningStatus, id];
                },

                updateStatusFailed(state: any, error: any) {
                    state.updateStatusError = error;
                },

                openStatusFailed(state: any, error: any) {
                    state.openStatusError = error;
                },
                joinStatusFailed(state: any, error: any) {
                    state.joinStatusError = error;
                },


                updateStatusFinally(state: any, id: any) {
                    const idx = state.updatingStatus.indexOf(id);
                    if (idx >= 0) {
                        state.updatingStatus.splice(idx, 1);
                        state.updatingStatus = [...state.updatingStatus];
                    }
                },

                joinStatusFinally(state: any, id: any) {
                    const idx = state.joiningStatus.indexOf(id);
                    if (idx >= 0) {
                        state.joiningStatus.splice(idx, 1);
                        state.joiningStatus = [...state.joiningStatus];
                    }
                },
                openStatusFinally(state: any, id: any) {
                    const idx = state.openingStatus.indexOf(id);
                    if (idx >= 0) {
                        state.openingStatus.splice(idx, 1);
                        state.openingStatus = [...state.openingStatus];
                    }
                }
            },
            actions: {
                async updateStatus(context: any, { ids, status }: any) {
                    context.commit('updateStatusRequested', ids);
                    try {
                        await services.updateStatus({
                            ids: ids,
                            gray: status ? true : false
                         });
                        // 状态更新成功后，重新获取列表
                        context.dispatch('search');
                    } catch (e) {
                        context.commit('updateStatusFailed', e);
                    } finally { 
                        context.commit('updateStatusFinally', ids);
                    }
                },
                
                async downloadTemplateExcel(context: any) {
                    await services.downloadTemplateXls();
                },
                async deleteMerTerm(context: any, { merchants}: any) {
                    try {
                        await services.deleteMerTerm({
                            merchants: merchants,
                         });
                        // 状态更新成功后，重新获取列表
                        context.dispatch('search');
                    } catch (e) {
                        context.commit('updateStatusFailed', e);
                    } finally { 
                    }
                },
                
            }
        }
    )
);
