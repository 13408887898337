
    import {Component, Prop, Vue, Watch, Inject, Provide} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import Drawer from '@/components/Drawer.vue';
    import ActivityItem from './ActivityItem.vue';
    import store from './store';
    import {getValueByPath} from '@/utils';
    import {Show, check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Drawer,
            ActivityItem,
            Show
        },
        computed: {
            ...mapState({
                query() {
                    return this.state().query;
                },
                list() {
                    return this.state().list;
                },
                total() {
                    return this.state().total;
                },
                page() {
                    return this.state().page;
                },
                loading() {
                    return this.state().loading;
                }
            })
        }
    })
    class Activity extends Vue {
        @Prop()
        // tslint:disable-next-line:variable-name
        public store_id!: string;

        @Prop()
        public title!: string | number;

        @Inject()
        public privileges!: any;

        public cacheStoreId!: any;

        public PRIVILEGE = PRIVILEGE;

        @Provide()
        public state() {
            return getValueByPath(this.$store.state, [
                'shopSearchPage',
                this.cacheStoreId + '@activity'
            ]);
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                'shopSearchPage/' + this.cacheStoreId + '@activity/' + action,
                payload
            );
        }

        @Provide()
        public commit(action: string, payload?: any) {
            this.$store.commit(
                'shopSearchPage/' + this.cacheStoreId + '@activity/' + action,
                payload
            );
        }

        public created() {
            // if (!this.state()) {
            //     this.$store.registerModule(
            //         ['shopSearchPage', this.store_id + '@activity'],
            //         store()
            //     );
            //     this.commit('setQuery', {store_id: this.store_id, status: 'ACTIVE'});
            //     this.$watch(function() {
            //         return this
            //             .$store.state.shopSearchPage[this.store_id + '@activity'].error;
            //     }, this.errorListener);

            // }
        }

        public errorListener(error: any) {
            if (error) {
                this.$message.error(error.message);
            }
        }

        @Watch('store_id')
        public handleDrawerOpen(storeId: any) {

            if (check([this.PRIVILEGE.STORE.ACTIVITY], this.privileges).length > 0) {
                return;
            }

            if (!storeId) {
                return;
            }
            this.cacheStoreId = storeId;
            if (!this.state()) {
                this.$store.registerModule(
                    ['shopSearchPage', this.store_id + '@activity'],
                    store()
                );
                this.commit('setQuery', {store_id: this.store_id, status: 'ACTIVE'});
                this.$watch(function() {
                    return this.$store.state.shopSearchPage[this.store_id + '@activity']
                        && this.$store.state.shopSearchPage[this.store_id + '@activity'].error;
                }, this.errorListener);

            }
            this.dispatch('resetQuery', {store_id: this.store_id, status: 'ACTIVE'});
            // if (this.state().list.length === 0) {
            //     this.dispatch('search');
            // }
        }

        public handleStatusChange(e: any) {
            this.dispatch(
                'queryChange',
                {
                    status: e.target.value
                }
            );
        }

        public handlePageChange(page: number) {
            this.dispatch('pageChange', page);
        }

        public onClose() {
            this.$emit('close');
        }
    }

    export default Activity;
