
import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DatePicker, ScheduleTimePicker, IrregularScheduleTimePicker} from '@/components/custom-form-components';
import * as validators from '../../validators';

interface DetailForm {
    baseForm: any;
    otherForm: any;
}

@Component<DetailForm>({
    components: {
        DatePicker,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
       ...mapState({
                formData(state: any) {
                    return state.activityEditPage.data && this.formatFormData(state.activityEditPage.data);
                }
            })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public baseForm;

    public otherForm;

    public baseData:any;
    public activeTab:any;
    public timeRuleData:any;
    public timeRulevisible:boolean = false;
    public noTimeRuleData:any;
    public noTimeRulevisible:boolean = false;
    public shopData:any;
    public bankData:any;
    public activitySponsors:any;
    public budgetLimitCheck:any;
    public paywayCheck:any;
    public discountRuleCheck:any;

    public isDefault: boolean = false;

    public conflictReplaceList: any;

    public merchantTotal = 0;

    public validators = validators;

    public created() {
        console.log(this.$store.state.activityEditPage);
    }

    public data() {
        return {
          baseData: {},
          baseForm: this.$form.createForm(this),
          otherForm: this.$form.createForm(this),
          activeTab: "1",
          timeRuleData: {},
          timeRulevisible: false,
          noTimeRuleData: {},
          noTimeRulevisible: false,
          radioStyle: {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft: '120px',
          },
          budgetLimit: "2",
          budgetInput: false,
          discountRuleCheck: "1",
          budgetLimitCheck: "2",
          paywayCheck: "0",
          shopData: [],
          bankData:[],
          activitySponsors:[],
          columns: [
            {
                title: '商户号',
                dataIndex: 'merNo',
            },
            {
                title: '终端号',
                dataIndex: 'termNo',
            }
          ]
        };
    }

    public initTimeRuleData(data:any){
        this.timeRuleData = data;
    }

    public handleTimeRuleHide(){

        this.timeRulevisible = false;
    }

    public initNoTimeRuleData(data:any){
        this.noTimeRuleData = data;
    }

    public handleNoTimeRuleHide(){
        console.log(this.noTimeRuleData)

        this.noTimeRulevisible = false;
    }

    public tabChange(key:any) {
        this.activeTab = key;
    }


    public handleNext(e: any) {
       this.activeTab = "2";
    }

    public handleCreate(e: any) {
        e.preventDefault();
        const queryString = this.$store.getters[
            'activitiesDraftSearchPage/queryString'
        ]();
        this.$router.push({
            path: '/console/activity/open/search' + queryString
        });
    }

    public async handleCancel() {
        const queryString = this.$store.getters[
            'activitiesDraftSearchPage/queryString'
        ]();
        this.$router.push({
            path: '/console/activity/open/search' + queryString
        });
    }
    private formatFormData(rawData: any) {
         if(null != rawData.userLimit.dailyCountLimit){
             this.discountRuleCheck = '2'
         }
         let budgetLimit = '2'
         if(null != rawData.dailyAmountLimit && '' != rawData.dailyAmountLimit){
             budgetLimit = '1';
             this.budgetLimitCheck = '1';
         }
        this.shopData= rawData.merchantList;
        let bankName ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].bankName){
            bankName = rawData.bankCardList[0].bankName;
            this.paywayCheck='1'
        }
        let cardBin ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].cardBin){
            cardBin = rawData.bankCardList[0].cardBin;
        }
        let cardType ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].cardType){
            cardType = rawData.bankCardList[0].cardType;
        }
        //
        let weekDays:any =[];
        if(null != rawData.availableTime.weekDays && rawData.availableTime.weekDays.length>0){

           rawData.availableTime.weekDays.forEach((data:string)=> {
                weekDays.push(data+'');
           })
        }
        return {
            ...rawData,
            discountRuleCheck:this.discountRuleCheck,
            shopData: rawData.merchantList,
            budgetLimit:budgetLimit,
            paywayCheck:this.paywayCheck,
            bankName: bankName,
            cardBin:cardBin,
            cardType:cardType,
            weekDays:weekDays,
            sponsorName:rawData.activitySponsors[0].sponsorName,
            warnRatio:rawData.activitySponsors[0].warnRatio,
            warnMobile:rawData.activitySponsors[0].warnMobile,

        }
    }
    public handleBack() {
        this.activeTab = "1";
    }


}

export default DetailForm;
