
    import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
    import Role from '@/components/custom-form-components/Role.vue';
    import * as services from './services';
    import {Click} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    @Component({
        components: {
            Role,
            Click
        }
    })
    class RoleChangeModal extends Vue {

        @Prop()
        public userId!: any;
        @Prop()
        public userName!: any;

        @Inject()
        public dispatch!: any;

        public visible = false;
        public value = null;
        public loading = false;
        public PRIVILEGE = PRIVILEGE;

        public handleChange(value: any) {
            this.value = value;
        }

        public handleClose() {
            if (this.loading) {
                return;
            }
            this.value = null;
            this.visible = false;
        }

        public async handleOk() {
            if (!this.value) {
                return this.$message.error('请选择角色');
            }
            this.loading = true;
            try {
                await services.setRole({
                    group_id: this.value,
                    user_id: this.userId
                });
                this.visible = false;
                this.dispatch('search');
            } catch (e) {
                this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }

        public onClick() {
            this.visible = true;
        }
    }

    export default RoleChangeModal;
