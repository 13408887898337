var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("a-auto-complete", {
    attrs: {
      dataSource: _vm.dataSource,
      placeholder: "请选择或手动输入",
      value: _vm.value,
      filterOption: _vm.filterOption,
    },
    on: { change: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }