import Vue from 'vue';
import Sortable from 'sortablejs';

const vSortable = {
    config: {},
    install() {
        Vue.directive('sortable', {
            inserted(el: any) {
                const sortable = new Sortable(el);
                if (el.arg && !el.vm.sortable) {
                    el.vm.sortable = {};
                }
                //  Throw an error if the given ID is not unique
                if (el.arg && el.vm.sortable[el.arg]) {
                    // console.warn('[vue-sortable] cannot set already defined sortable id: \'' + el.arg + '\'')
                } else if (el.arg) {
                    el.vm.sortable[el.arg] = sortable;
                }
            },
            bind(el: any, binding) {
                // const self: any = this;
                el.options = binding.value || {};
            }
        });
    }
};
export default vSortable;
