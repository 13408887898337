import {request_yx} from '@/utils';
import config from '@/config';
export async function search(params: {
    query: any;
}) {

    try {
        console.log(params)
        return await request_yx({
            method: 'POST',
            api: '/console/sht/manage/query',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function updateStatus(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/sht/manage/updateStatus',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function unbind(params: any) {

    try {
        return await request_yx({
            method: 'POST',
            api: '/console/sht/manage/unbind',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        throw e;
    }
}

