import * as DICTS from '@/common/dicts';
import {defaultCustomRender} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center'
    },
    {
        title: '商场ID',
        dataIndex: 'id',
        fixed: 'left',
        width: 200,
        // width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: 'appID',
        dataIndex: 'app_id',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: 'PublicKEY',
        dataIndex: 'public_key',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: 'PrivateKEY',
        dataIndex: 'private_key',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商场名称',
        dataIndex: 'mall_name',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '商场归属',
        dataIndex: 'mall_belong',
        align: 'center',
        width: 200,
        customRender: (belong: string) => {
            return DICTS.SERVICE_PLATFORM[belong] || '-';
        }
    },
    {
        title: '省',
        dataIndex: 'province',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '市',
        dataIndex: 'city',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '区',
        dataIndex: 'district',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '地址',
        dataIndex: 'address',
        align: 'center',
        width: 300,
       scopedSlots: {customRender: 'address'},
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 250,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
