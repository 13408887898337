import {deleteEmpty, request} from '@/utils';

export async function statistics() {
    try {
        return await request({
            api: '/console/coupons/statistics/list'
        });
    } catch (e) {
        const error: any = new Error('获取统计数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function marketing_data_list(params: {
    discount_coupon_type: string;
    start_time: string;
    end_time: string;
}) {
    try {
        return await request({
            api: '/console/marketing/data/list',
            data: deleteEmpty(params)
        });
    } catch (e) {
        const error: any = new Error('获取统计数据失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
