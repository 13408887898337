
    import {Component, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';

    import DatePicker from '@/components/custom-form-components/DatePicker.vue';
    import {deleteEmpty, trimParams} from '@/utils';
    import qs from 'querystringify';
    import moment from 'moment';
    import formRules from '@/common/formRules';
    import {
        AdMaterialTplSelector,
        AdPositionSelector
    } from '@/components/custom-form-components';

    interface SearchForm {
        form: any;
    }

    export interface Query {
        position: string;
        title: string;
        material_tpl: string;
        terminal: string;
        status: string;
        creator: string;
        assignment_type: string;
        start_time_from: string;
        start_time_to: string;
        created_time_from: string;
        created_time_to: string;
    }

    export interface FormData {
        position: string;
        title: string;
        material_tpl: string;
        terminal: string;
        creator: string;
        status: string;
        assignment_type: string;
        start_time: any[];
        created_time: any[];
    }

    @Component({
        components: {
            DatePicker,
            AdMaterialTplSelector,
            AdPositionSelector
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return this.formatFormData(state.advertisementSearchPage.query);
                },
                loading: (state: any) => state.advertisementSearchPage.loading
            })
        }
    })
    class SearchForm extends Vue {
        
        public data() {
            return {
                collapsed: false,
                form: this.$form.createForm(this),
                formRules
            };
        }

        public handleSearch(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (error) {
                    return this.$message.error('请填写正确信息');
                }
                this.$emit('search');
                const query = deleteEmpty({
                    ...this.formatQuery(values),
                    page: 1,
                    r: Math.random()
                });
                this.$router.push({
                    path: `/console/advertisement/search?${qs.stringify(query)}`
                });

            });
        }

        public handleReset() {
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
        }

        private formatQuery(values: FormData): Query {
            return {
                position: values.position,
                title: values.title,
                terminal: values.terminal,
                material_tpl: values.material_tpl,
                assignment_type: values.assignment_type,
                status: values.status,
                creator: values.creator,
                start_time_from: values.start_time &&
                    values.start_time[0] &&
                    values.start_time[0].format('YYYY-MM-DD HH:mm:ss'),
                start_time_to: values.start_time &&
                    values.start_time[1] &&
                    values.start_time[1].format('YYYY-MM-DD HH:mm:ss'),
                created_time_from: values.created_time &&
                    values.created_time[0] &&
                    values.created_time[0].format('YYYY-MM-DD HH:mm:ss'),
                created_time_to: values.created_time &&
                    values.created_time[1] &&
                    values.created_time[1].format('YYYY-MM-DD HH:mm:ss')
            };
        }

        private formatFormData(query: Query): FormData {
            return {
                position: query.position,
                title: query.title,
                terminal: query.terminal,
                material_tpl: query.material_tpl,
                assignment_type: query.assignment_type,
                status: query.status,
                creator: query.creator,
                start_time: [
                    query.start_time_from && moment(query.start_time_from),
                    query.start_time_to && moment(query.start_time_to)
                ],
                created_time: [
                    query.created_time_from && moment(query.created_time_from),
                    query.created_time_to && moment(query.created_time_to)
                ]   
            };
        }
    }

    export default SearchForm;
