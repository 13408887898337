import { create } from '@/common/storeFactory';
import * as services from '../services';
import columns from './columns';

export default create({
    search(query, page, pageSize) {
        const pageQuery = {
            pageIndex: page,
            pageSize: pageSize
        }
        return services.search({
            ...query,
            pageQuery
        });
    },
    columns,
    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }

}, {
    state: {
        selectedRows: [],

        openDebugIng: false,

    },
    mutations: {
        setSelectedRows(state: any, rows: any) {
            state.selectedRows = rows;
        },
        setOpenDebugLoading(state: any, loading: boolean) {
            state.openDebugIng = loading;
        },

    },
    actions: {
        async recon(context: any, query:any) {
            
            context.commit('setLoading', true);
            try {
                await services.recon({
                    date:query.date,
                    type:query.type
                });
                alert("对账成功")
            } catch (e) {
                context.commit('failed', {
                    error: e
                });
            }
            context.commit('setLoading', false);
        },
        async invokingGW(context: any,query:any) {
            context.commit('setLoading', true);
            try {
                await services.invokingGW({
                    date:query.date,
                    type:query.type
                });
                alert("营销对账成功")
            } catch (e) {
                context.commit('failed', {
                    error: e
                });
            }
            context.commit('setLoading', false);
            
        },
    }
});
