import {request} from '@/utils';

export async function search(params: any) {
    try {
        return await request({
            api: '/console/mall/store/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取入驻商户列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
