import {request, downloadRequest} from '@/utils';

export async function downloadXls(params: any) {

    return await downloadRequest({
        data: params,
        prefix: 'bill',
        api: '/console/store/bill/list/excel'
    });
}

export async function search(params: any) {

    try {
        return await request({
            api: '/console/store/order/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取商户交易流水失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
