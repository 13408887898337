import { message } from 'ant-design-vue';
import { request_yx } from '@/utils';


export async function updateShopLimitTimeRange(params: any) {
    try {
        return await request_yx({
            method: 'POST',
            api: '/console/activity/subsidyRule/updateShopLimitTimeRange',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('修改商户有效期失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
