import {create} from './selectComponentFactory';

export default create({
    id: 'search-form-component-store-status',
    options: [{
        label: 'ON',
        value: 'OPEN'
    }, {
        label: 'OFF',
        value: 'CLOSE'
    }]
});
