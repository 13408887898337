import {
    Select
} from 'ant-design-vue';
import Vue from 'vue';

interface OptionItem {
    label: string;
    value: any;
}

interface Config {
    id: string;
    placeholder?: string;
    options?: OptionItem[];
    extraProps?: any;
}

export function create(config: Config) {
    const {
        id,
        placeholder = '请选择',
        options = [],
        extraProps = {}
    } = config;

    return Vue.component(id, {

        methods: {
            changeHandler(value: any) {
                this.$emit('change', value);
            }
        },

        props: ['value', 'container', 'disabled'],
        render(createElement: any) {
            return createElement(Select, {
                props: {
                    value: this.value,
                    disabled: this.disabled,
                    placeholder,
                    allowClear: true,
                    options,
                    getPopupContainer(trigger: any) {
                        return trigger.parentNode;
                    },
                    ...extraProps
                },
                on: {
                    change: (this as any).changeHandler
                }
            });
        }
    });
}


