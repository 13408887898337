import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '展现量(H5)',
        dataIndex: 'amount_show_h5',
        align: 'center',
        sorter: true,
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '点击量(H5)',
        dataIndex: 'amount_click_h5',
        width: 200,
        sorter: true,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '展现量(小程序)',
        dataIndex: 'amount_show_mini_app',
        align: 'center',
        sorter: true,
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '点击量(小程序)',
        dataIndex: 'amount_click_mini_app',
        width: 200,
        sorter: true,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '广告名称',
        dataIndex: 'title',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '广告位置',
        dataIndex: 'position',
        width: 200,
        align: 'center',
        customRender: (value: string) => {
            return ({
                B_HOME_BOTTOM: 'B端首页下方广告位',
                C_HOME_BOTTOM: 'C端首页下方广告位',
                BEFORE_PAY: '支付前页面广告位',
                AFTER_PAY: '支付后页面广告位',
                ORDER_DETAIL: '订单详情页广告位'
            } as any)[value];
        }
    },
    {
        title: '展现方式',
        dataIndex: 'material_tpls',
        width: 200,
        align: 'center',
        customRender: (value: any) => {
            return value.map((k: string) => {
                return ({
                    IMAGE_TEXT_LINK: '图链',
                    MINI_APP: '小程序广告'
                } as any)[k];
            }).join('、');
        }
    },
    {
        title: '载体',
        dataIndex: 'terminals',
        width: 200,
        align: 'center',
        customRender: (value: any) => {
            return value.map((k: string) => {
                return ({
                    H5: '惠码H5',
                    MINI_APP: '惠码小程序'
                } as any)[k];
            }).join('、');
        }
    },
    {
        title: '分配',
        dataIndex: 'assignment_types',
        width: 300,
        align: 'center',
        scopedSlots: {customRender: 'assign'}
    },
    {
        title: '上架时间',
        dataIndex: 'schedule.start_time',
        width: 200,
        sorter: true,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '下架时间',
        dataIndex: 'schedule.end_time',
        width: 200,
        sorter: true,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '日期循环',
        dataIndex: 'schedule',
        width: 300,
        align: 'center',
        customRender: (value: any) => {
            if (!value) {
                return '-';
            }
            let days = [];
            if (value.monday) {
                days.push('周一');
            }
            if (value.tuesday) {
                days.push('周二');
            }
            if (value.wednesday) {
                days.push('周三');
            }
            if (value.thursday) {
                days.push('周四');
            }
            if (value.friday) {
                days.push('周五');
            }
            if (value.saturday) {
                days.push('周六');
            }
            if (value.sunday) {
                days.push('周日');
            }

            if (days.length === 7) {
                days = ['每天'];
            }
            return days.join('、');
        }
    },
    {
        title: '广告状态',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        customRender: (value: any, record: any) => {
            if (!record.schedule) {
                return '-';
            }
            if (record.interrupted_time) {
                return '已下架';
            }
            const now = Date.now();
            const start = + new Date(record.schedule.start_time);
            const end = + new Date(record.schedule.end_time);
            if (now < start) {
                return '未上架';
            } else if (now > end) {
                return '已下架';
            } else {
                return '上架中';
            }
        }
    },
    {
        title: '创建时间',
        dataIndex: 'created_time',
        width: 200,
        sorter: true,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'creator',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 100,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
