var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        { staticClass: "mall-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商户号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantNo",
                      {
                        initialValue: _vm.formData.merchantNo,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          {
                            pattern: /^[A-Za-z\d]{15}$/,
                            message: "请输入15位商户号",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'merchantNo',\n                    {\n                        initialValue: formData.merchantNo,\n                        rules: [\n                            { required: true, message: '请填写必填项' },\n                            {\n                                pattern: /^[A-Za-z\\d]{15}$/,\n                                message: '请输入15位商户号'\n                            }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "请输入", maxLength: 15 },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "终端号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "termNo",
                      {
                        initialValue: _vm.formData.termNo,
                        rules: [
                          {
                            pattern: /^[A-Za-z\d]{8}$/,
                            message: "请输入8位终端号",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'termNo',\n                    {\n                        initialValue: formData.termNo,\n                        rules: [\n                            {\n                                pattern: /^[A-Za-z\\d]{8}$/,\n                                message: '请输入8位终端号'\n                            }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { placeholder: "请输入", maxLength: 8, disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityId",
                      {
                        initialValue: _vm.formData.activityId,
                      },
                    ],
                    expression:
                      "[\n                    'activityId',\n                    {\n                        initialValue: formData.activityId\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "规则ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleId",
                      {
                        initialValue: _vm.formData.ruleId,
                      },
                    ],
                    expression:
                      "[\n                    'ruleId',\n                    {\n                        initialValue: formData.ruleId\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动商户有效期" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleTime",
                      {
                        initialValue: _vm.formData.startTime
                          ? _vm.formData.startTime + "—" + _vm.formData.endTime
                          : "",
                      },
                    ],
                    expression:
                      "[\n                    'ruleTime',\n                    {\n                        initialValue: formData.startTime\n                            ? formData.startTime + '—' + formData.endTime\n                            : ''\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true, placeholder: "-" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "补贴限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "limit",
                      {
                        initialValue: _vm.formData.limit,
                        rules: [
                          {
                            pattern:
                              /(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'limit',\n                    {\n                        initialValue: formData.limit,\n                        rules: [\n                            {\n                                pattern: /(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/,\n                                message: '请正确输入金额'\n                            }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "补贴总交易限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "payLimit",
                      {
                        initialValue: _vm.formData.payLimit,
                        rules: [
                          {
                            pattern:
                              /(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'payLimit',\n                    {\n                        initialValue: formData.payLimit,\n                        rules: [\n                            {\n                                pattern: /(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/,\n                                message: '请正确输入金额'\n                            }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "补贴交易封顶笔数" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "subsidyMaxNum",
                      {
                        initialValue: _vm.formData.subsidyMaxNum,
                        rules: [
                          {
                            pattern:
                              /(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/,
                            message: "请正确输入金额",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n                    'subsidyMaxNum',\n                    {\n                        initialValue: formData.subsidyMaxNum,\n                        rules: [\n                            {\n                                pattern: /(^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$)|(^0\\.[1-9]{1}\\d{0,1}$)|(^0\\.\\d{1}[1-9]{1}$)/,\n                                message: '请正确输入金额'\n                            }\n                        ]\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c("a-form-item", { attrs: { label: "共享额度" } }, [
            _c("div", [
              _vm._v(_vm._s(_vm.formData.merGroupId === "0" ? "否" : "是")),
            ]),
          ]),
          _c(
            "a-form-item",
            { attrs: { label: "共享额度补贴额度包" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupLimit",
                      { initialValue: _vm.formData.merGroupLimit },
                    ],
                    expression:
                      "[\n                    'merGroupLimit',\n                    { initialValue: formData.merGroupLimit }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享额度已累计补贴金额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupTotalSubsidyAmt",
                      { initialValue: _vm.formData.merGroupTotalSubsidyAmt },
                    ],
                    expression:
                      "[\n                    'merGroupTotalSubsidyAmt',\n                    { initialValue: formData.merGroupTotalSubsidyAmt }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享补贴交易总限额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupPayLimit",
                      { initialValue: _vm.formData.merGroupPayLimit },
                    ],
                    expression:
                      "[\n                    'merGroupPayLimit',\n                    { initialValue: formData.merGroupPayLimit }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享额度已累计补贴交易总金额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupTotalPaySubsidyAmt",
                      { initialValue: _vm.formData.merGroupTotalPaySubsidyAmt },
                    ],
                    expression:
                      "[\n                    'merGroupTotalPaySubsidyAmt',\n                    { initialValue: formData.merGroupTotalPaySubsidyAmt }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享已累计补贴笔数" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupTotalSubsidyNum",
                      { initialValue: _vm.formData.merGroupTotalSubsidyNum },
                    ],
                    expression:
                      "[\n                    'merGroupTotalSubsidyNum',\n                    { initialValue: formData.merGroupTotalSubsidyNum }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "共享补贴交易封顶笔数" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merGroupSubsidyMaxNum",
                      { initialValue: _vm.formData.merGroupSubsidyMaxNum },
                    ],
                    expression:
                      "[\n                    'merGroupSubsidyMaxNum',\n                    { initialValue: formData.merGroupSubsidyMaxNum }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "优先级" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "priority",
                      {
                        initialValue: _vm.formData.priority,
                      },
                    ],
                    expression:
                      "[\n                    'priority',\n                    {\n                        initialValue: formData.priority\n                    }\n                ]",
                  },
                ],
                attrs: { disabled: true },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "自定义规则" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ruleJson",
                      { initialValue: _vm.formData.ruleJson },
                    ],
                    expression:
                      "[\n                    'ruleJson',\n                    { initialValue: formData.ruleJson }\n                ]",
                  },
                ],
                staticStyle: { width: "95%" },
                attrs: {
                  disabled: true,
                  autosize: { minRows: 6, maxRows: 100 },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }