<template>
  <div>
    <a-transfer
      class="tree-transfer"
      :data-source="dataSource"
      :target-keys="targetKeys"
      :disabled="disabled && dataSource"
      :render="item => item.title"
      :show-select-all="false"
      @change="onChange"
    >
      <template
        slot="children"
        slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
      >
        <a-tree
          v-if="direction === 'left'"
          class="resetATree"
          height=200
          blockNode
          checkable
          checkStrictly
          :checkedKeys="[...selectedKeys, ...targetKeys]"
          :treeData="treeDataInfo"
          @check="
            (_, props) => {
              onChecked(_, props, [...selectedKeys, ...targetKeys], itemSelect);
            }
          "
          @select="
            (_, props) => {
              onChecked(_, props, [...selectedKeys, ...targetKeys], itemSelect);
            }
          "
        />
      </template>
    </a-transfer>
  </div>
</template>
<script>
// const treeData = [
//   { key: '0-0', title: '0-0' },
//   {
//     key: '0-1',
//     title: '0-1',
//     children: [
//       { key: '0-1-0', title: '0-1-0' },
//       { key: '0-1-1', title: '0-1-1' },
//     ],
//   },
//   { key: '0-2', title: '0-3' },
// ];

let treeData = [];
let transferDataSource = [];
function flatten(data) {
  if(data){
    for (var code of data) {
      transferDataSource.push(code);
      if(code.children){
        flatten(code.children);
      }
    }
  }
}
// flatten(treeData);
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

function handleTreeData(data, targetKeys = []) {
  data.forEach(item => {
    //item['disabled'] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}

export default {
  props: {
    targetDistrictCodeData: Array,
    disabled:Boolean
  },
  data() {
    return {
      targetKeys: [],
      dataSource: transferDataSource, 
      treeDataInfo: []
    };
  },
  created() {
    if(transferDataSource.length != 0){
      transferDataSource=[];
    }
    treeData=this.$store.state.activitySignUpPage.districtCodeData || JSON.parse(localStorage.getItem('activityDistrictList'));
    flatten(treeData);
    this.targetKeys = this.targetDistrictCodeData;
    console.log('---->', this.targetKeys);
    this.treeDataInfo = handleTreeData(treeData, this.targetKeys);
  },
  methods: {
    onChange(targetKeys) {
      console.log('Target Keys:', targetKeys);
      this.targetKeys = targetKeys;
      this.$emit('change', this.targetKeys);
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
  },
  watch:{
    targetDistrictCodeData() {
      this.targetKeys = this.targetDistrictCodeData;
    }
  }
};
</script>
<style scoped>
.tree-transfer .ant-transfer-list:first-child {
  width: 50%;
  flex: none;
}
</style>
