
import { Component, Watch, Inject, Vue } from 'vue-property-decorator';
import CardItem from '@/components/CardItem.vue';
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/axisPointer';
import columns from './columns';
import * as services from './services';
import moment from 'moment';
import option from './option';
import { calcTableScrollX, integerFormat, floatFormat } from '@/utils';
import DateRangeType from '@/components/custom-form-components/DateRangeType';
import ExcelRangeType from '@/components/custom-form-components/ExcelRangeType';
import { DatePicker, Modal } from 'ant-design-vue';
import PRIVILEGE from '@/common/privileges';
import {check} from '@/components/Auth';

interface AmountPage {
    clickDispic: Date,
    columns: any;
    statistics: any;
    tableData: any;
    option: any;
    loading: boolean;
    dateRangeType: string;
    excelRangeType: string;
    changeValue: any;
    exportValue: any;
    custom_time: boolean;
    custom_time_data_start: any;
    custom_time_data_end: any;
    date: any;
    dateString: any;
    days: any;
    arg: any;
    endDate: any;
    endValue: any;
    exportEndValue: any;
    startValue: any;
    exportStartValue:any;
    endOpen: boolean;
    exportEndOpen: boolean;
    changeStartTime:string;
    changeEndTime: string;
    exportChangeStartTime:string;
    exportChangeEndTime: string;
    export_custom: boolean;
    pay_channel: any;
    disabledButtom: boolean;
    hideMessage: any;
}

@Component({
    components: {
        DateRangeType,
        ExcelRangeType,
        CardItem,
        'v-chart': ECharts
    }
})
class AmountPage extends Vue {
    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    public data() {
        return {
            hideMessage: null,
            clickDispic: new Date(),
            columns,
            statistics: {},
            tableData: [],
            option,
            startValue: null,
            endValue: null,
            exportStartValue:null,
            exportEndValue:null,
            endOpen: false,
            exportEndOpen:false,
            custom_time_data_start: '',
            custom_time_data_end: '',
            custom_time: false,
            export_custom: false,
            disabledButtom: false,
            loading: false,
            calcTableScrollX,
            dateRangeType: '',
            excelRangeType: '',
            changeStartTime:'',
            changeEndTime: '',
            exportChangeStartTime:'',
            exportChangeEndTime: '',
            exportValue: {
                pay_channel: '',
                startTime:'',
                endTime: ''
            },
            changeValue: {
                startTime: '',
                endTime: '',
                payChannel: '',
                field_name: '1'
            }
        };
    }

    public handleRefresh() {
        this.getTableData();
        this.$message.success('刷新成功');
    }

    public created() {
        this.getTableData();
    }

    public getFormatDate(arg: any) {
        if (arg == undefined || arg == '') {
            return '';
        }
        var re = arg + '';
        if (re.length < 2) {
            re = '0' + re;
        }
        return re;
    }

    public addDate(date: any, days: number) {
        var d = new Date(date);
        d.setDate(d.getDate() + days);
        var month = d.getMonth() + 1;
        var day = d.getDate();
        return (
            d.getFullYear() +
            '-' +
            this.getFormatDate(month) +
            '-' +
            this.getFormatDate(day)
        );
    }

    public onChangeStart(date:any, dateString:any) {
         this.changeStartTime = dateString
    }

    public onChangeEnd(date:any, dateString: any) {
        this.changeEndTime = this.addDate(dateString,1);
    }

     public onExportChangeStart(date:any, dateString:any) {
         this.exportChangeStartTime = dateString
    }

    public onExportChangeEnd(date:any, dateString: any) {
        this.exportChangeEndTime = this.addDate(dateString,1);
    }


    public exportDisabledStartDate(exportStartValue:any) {
        const exportEndValue = this.exportEndValue;
        if (!exportStartValue || !exportEndValue) {
            return exportStartValue.valueOf() > new Date().getTime();
        } 
        return exportEndValue.valueOf() - exportStartValue.valueOf() > (24*60*60*1000*30)*6 || exportEndValue.valueOf() <= exportStartValue.valueOf();
    }
    
    public disabledStartDate(startValue:any) {
        const endValue = this.endValue;
        if (!startValue || !endValue) {
            return startValue.valueOf() > new Date().getTime();
        }
        return endValue.valueOf() - startValue.valueOf() > 24*60*60*1000*30 || endValue.valueOf() <= startValue.valueOf();
    }

    public exportDisabledEndDate(exportEndValue:any) {
        const exportStartValue = this.exportStartValue;
        if (!exportEndValue || !exportStartValue) {
            return exportEndValue.valueOf() > new Date().getTime();
        }
        return exportEndValue.valueOf() <= exportStartValue.valueOf() || exportEndValue.valueOf() > new Date().getTime() || exportEndValue.valueOf() - exportStartValue.valueOf() > (24*60*60*1000*30)*6;
    }   

    public disabledEndDate(endValue:any) {
        const startValue = this.startValue;
        if (!endValue || !startValue) {
            return endValue.valueOf() > new Date().getTime();
        }
        return endValue.valueOf() <= startValue.valueOf() || endValue.valueOf() > new Date().getTime() || endValue.valueOf() - startValue.valueOf() > 24*60*60*1000*30;
    }
    
    public handleStartOpenChange(open:any) {
        if (!open) {
          this.endOpen = true;
        }
    }

    public handleExportStartOpenChange(exportOpen:any) {
        if (!exportOpen) {
          this.exportEndOpen = true;    
        }
    }

    public handleEndOpenChange(open:any) {
        this.endOpen = open;
    }

    public handleExportEndOpenChange(exportOpen:any) {
        this.exportEndOpen = exportOpen;
    }
     public handleExportTime() {
        this.export_custom = false;
        this.exportValue.startTime = this.exportChangeStartTime;
        this.exportValue.endTime = this.exportChangeEndTime
        console.log( this.exportValue.startTime, this.exportValue.endTime)
    }

    public handleCustomTime() {
        this.custom_time = false;
        
        if(this.changeStartTime === '' || this.changeEndTime === '') {
            return
        } else {
            this.changeValue.startTime = this.changeStartTime;
            this.changeValue.endTime = this.changeEndTime;
        }
      
    }
 
    @Watch('exportValue', { deep: true, immediate:true })
    public async watchExportValue(exportValue: any) {
        if(exportValue.startTime) {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            try {
                const res = await services.exportExcel({
                    pay_channel: exportValue.pay_channel,
                    start_time: exportValue.startTime,
                    end_time: exportValue.endTime
                })
                this.$message.success('导出完成!');
                this.hideMessage();
                this.exportValue.startTime = '';
                this.exportValue.endTime = '';
            } catch(e) {
                this.$message.error('导出失败，请联系技术人员!');
                this.hideMessage();
                this.exportValue.startTime = '';
                this.exportValue.endTime = '';
            }

            this.option.xAxis.data = [];
        }

    }

    @Watch('changeValue', { deep: true, immediate: true })
    public async watchChangeValue(changeValue: any) {
        if (changeValue.startTime) {
            const res = await services.bill({
                pay_channel: changeValue.payChannel,
                start_time: changeValue.startTime,
                end_time: changeValue.endTime,
                field_name: changeValue.field_name
            });
            this.option.xAxis.data = [];
            switch (changeValue.payChannel) {
                case '':
                    this.option.series[0].data = [];
                    this.option.series[1].data = [];
                    this.option.series[2].data = [];
                    this.option.series[3].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[0].data.push(val.total_amount / 100);
                        this.option.series[1].data.push(val.pay_amount / 100);
                        this.option.series[2].data.push(val.consume_count);
                        this.option.series[3].data.push(val.refund_count);
                    });
                    break;
                case 'ALIPAY_small_program':
                    this.option.series[4].data = [];
                    this.option.series[5].data = [];
                    this.option.series[6].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[4].data.push(val.pay_amount / 100);
                        this.option.series[5].data.push(val.consume_count);
                        this.option.series[6].data.push(val.refund_count);
                    });
                    break;
                 case 'ALIPAY':
                    this.option.series[7].data = [];
                    this.option.series[8].data = [];
                    this.option.series[9].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[7].data.push(val.pay_amount / 100);
                        this.option.series[8].data.push(val.consume_count);
                        this.option.series[9].data.push(val.refund_count);
                    });
                    break;
                case 'WECHAT':
                    this.option.series[10].data = [];
                    this.option.series[11].data = [];
                    this.option.series[12].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[10].data.push(val.pay_amount / 100);
                        this.option.series[11].data.push(val.consume_count);
                        this.option.series[12].data.push(val.refund_count);
                    });
                    break;
                 case 'WECHATH5':
                    this.option.series[13].data = [];
                    this.option.series[14].data = [];
                    this.option.series[15].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[13].data.push(val.pay_amount / 100);
                        this.option.series[14].data.push(val.consume_count);
                        this.option.series[15].data.push(val.refund_count);
                    });
                    break;
                case 'UQRCODEPAY':
                    this.option.series[16].data = [];
                    this.option.series[17].data = [];
                    this.option.series[18].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[16].data.push(val.pay_amount / 100);
                        this.option.series[17].data.push(val.consume_count);
                        this.option.series[18].data.push(val.refund_count);
                    });
                    break;
               
                case 'HWWALLET':
                    this.option.series[19].data = [];
                    this.option.series[20].data = [];
                    this.option.series[21].data = [];
                    res.list.forEach((val: any) => {
                        this.option.xAxis.data.push(val.date);
                        this.option.series[19].data.push(val.pay_amount / 100);
                        this.option.series[20].data.push(val.consume_count);
                        this.option.series[21].data.push(val.refund_count);
                    });
                    break;
            }
        } else {
            this.option.xAxis.data = [];
            Object.keys(this.option.series).forEach(key => {
                this.option.series[key].data = [];
            });
        }
    }
    public onExcelRangeTypeChange(e:any) {
        const list = check([PRIVILEGE.HOME.STATISTICS_EXCEL], this.privileges);
        if (list.length > 0) {
            return this.$message.error(
                '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
            );
        }

        this.excelRangeType = e;
        switch(e) {
            case "":
                this.exportValue.pay_channel = '';
                break;
            case 'WECHAT':
                this.export_custom = true;
                this.exportValue.pay_channel = 'WECHAT';
                break;
            case 'WECHATH5':
                this.export_custom = true;
                this.exportValue.pay_channel = 'WECHATH5';
                break;
            case 'ALIPAY':
                this.export_custom = true;
                this.exportValue.pay_channel = 'ALIPAY';
                break;
            case 'UQRCODEPAY':
                this.export_custom = true;
                this.exportValue.pay_channel = 'UQRCODEPAY';
                break;
            case 'HWWALLET':
                this.export_custom = true;
                this.exportValue.pay_channel = 'HWWALLET';
                break;
        }
    }
    public onDateRangeTypeChange(e: any) {
        this.dateRangeType = e;
        switch (e) {
            case '':
                this.changeValue.startTime = '';
                this.changeValue.endTime = '';
                break;
            case 'last7':
                this.changeValue.startTime = moment()
                    .subtract(7, 'day')
                    .format('YYYY-MM-DD');
                this.changeValue.endTime=moment().format('YYYY-MM-DD')
                break;
            case 'last15':
                this.changeValue.startTime = moment()
                    .subtract(15, 'day')
                    .format('YYYY-MM-DD');
                this.changeValue.endTime=moment().format('YYYY-MM-DD')
                break;
            case 'last30':
                this.changeValue.startTime = moment()
                    .subtract(30, 'day')
                    .format('YYYY-MM-DD');
                 this.changeValue.endTime=moment().format('YYYY-MM-DD')
                break;
            case 'custom':
                this.custom_time = true;
                break;
        }
    }

    public async onLegendSelectChange(e: any) {
        switch (e.name) {
            case '汇总订单金额':
            case '汇总实收金额':
            case '汇总收款笔数':
            case '汇总退款笔数':
                this.changeValue.payChannel = '';
                this.changeValue.field_name = '1';
                break;
            case '支付宝小程序实收':
            case '支付宝小程序笔数':
            case '支付宝小程序退款笔数':
                this.changeValue.payChannel = 'ALIPAY_small_program';
                this.changeValue.field_name = '';
                break;
            case '支付宝H5实收':
            case '支付宝H5笔数':
            case '支付宝H5退款笔数':
                this.changeValue.payChannel = 'ALIPAY';
                this.changeValue.field_name = '';
                break;
            case '微信小程序实收':
            case '微信小程序笔数':
            case '微信小程序退款笔数':
                this.changeValue.payChannel = 'WECHAT';
                this.changeValue.field_name = '';
                break;
            case '微信H5实收':
            case '微信H5笔数':
            case '微信H5退款笔数':
                this.changeValue.payChannel = 'WECHATH5';
                this.changeValue.field_name = '';
                break;
            case '云闪付H5实收':
            case '云闪付H5笔数':
            case '云闪付H5退款笔数':
                this.changeValue.payChannel = 'UQRCODEPAY';
                this.changeValue.field_name = '';
                break;
            case '华为钱包H5实收':
            case '华为钱包H5笔数':
            case '华为钱包H5退款笔数':
                this.changeValue.payChannel = 'HWWALLET';
                this.changeValue.field_name = '';
                break;
        }
    }

    public async getTableData() {
        try {
            this.loading = true;
            const res = await services.statistics();
            this.statistics = res;
            this.tableData = [
                {
                    pay_type: '微信',
                    carrier: '小程序',
                    today_pay_amount: floatFormat(
                        res.wx_today_pay_amount / 100
                    ),
                    yesterday_pay_amount: floatFormat(
                        res.wx_yesterday_pay_amount / 100
                    ),
                    seven_days_pay_amount: floatFormat(
                        res.wx_seven_days_pay_amount / 100
                    ),
                    thirty_days_pay_amount: floatFormat(
                        res.wx_thirty_days_pay_amount / 100
                    ),

                    today_consume_count: integerFormat(
                        res.wx_today_consume_count
                    ),
                    yesterday_consume_count: integerFormat(
                        res.wx_yesterday_consume_count
                    ),
                    seven_days_consume_count: integerFormat(
                        res.wx_seven_days_consume_count
                    ),
                    thirty_days_consume_count: integerFormat(
                        res.wx_thirty_days_consume_count
                    ),
                    today_refund_count: integerFormat(
                        res.wx_today_refund_count
                    ),
                    yesterday_refund_count: integerFormat(
                        res.wx_yesterday_refund_count
                    ),
                    seven_days_refund_count: integerFormat(
                        res.wx_seven_days_refund_count
                    ),
                    thirty_days_refund_count: integerFormat(
                        res.wx_thirty_days_refund_count
                    )
                },
                {
                    pay_type: '微信',
                    carrier:'H5',
                    today_pay_amount: floatFormat(
                        res.wx_h5_today_pay_amount / 100
                    ),
                    yesterday_pay_amount: floatFormat(
                        res.wx_h5_yesterday_pay_amount / 100
                    ),
                    seven_days_pay_amount: floatFormat(
                        res.wx_h5_seven_days_pay_amount / 100
                    ),
                    thirty_days_pay_amount: floatFormat(
                        res.wx_h5_thirty_days_pay_amount / 100
                    ),

                    today_consume_count: integerFormat(
                        res.wx_h5_today_consume_count
                    ),
                    yesterday_consume_count: integerFormat(
                        res.wx_h5_yesterday_consume_count
                    ),
                    seven_days_consume_count: integerFormat(
                        res.wx_h5_seven_days_consume_count
                    ),
                    thirty_days_consume_count: integerFormat(
                        res.wx_h5_thirty_days_consume_count
                    ),
                    today_refund_count: integerFormat(
                        res.wx_h5_today_refund_count
                    ),
                    yesterday_refund_count: integerFormat(
                        res.wx_h5_yesterday_refund_count
                    ),
                    seven_days_refund_count: integerFormat(
                        res.wx_h5_seven_days_refund_count
                    ),
                    thirty_days_refund_count: integerFormat(
                        res.wx_h5_thirty_days_refund_count
                    )
                },
                {
                    pay_type: '支付宝',
                    carrier: 'H5 ',
                    today_pay_amount: floatFormat(
                        res.alipay_today_pay_amount / 100
                    ),
                    yesterday_pay_amount: floatFormat(
                        res.alipay_yesterday_pay_amount / 100
                    ),
                    seven_days_pay_amount: floatFormat(
                        res.alipay_seven_days_pay_amount / 100
                    ),
                    thirty_days_pay_amount: floatFormat(
                        res.alipay_thirty_days_pay_amount / 100
                    ),

                    today_consume_count: integerFormat(
                        res.alipay_today_consume_count
                    ),
                    yesterday_consume_count: integerFormat(
                        res.alipay_yesterday_consume_count
                    ),
                    seven_days_consume_count: integerFormat(
                        res.alipay_seven_days_consume_count
                    ),
                    thirty_days_consume_count: integerFormat(
                        res.alipay_thirty_days_consume_count
                    ),
                    today_refund_count: integerFormat(
                        res.alipay_today_refund_count
                    ),
                    yesterday_refund_count: integerFormat(
                        res.alipay_yesterday_refund_count
                    ),
                    seven_days_refund_count: integerFormat(
                        res.alipay_seven_days_refund_count
                    ),
                    thirty_days_refund_count: integerFormat(
                        res.alipay_thirty_days_refund_count
                    )
                },
                {
                    pay_type: '支付宝',
                    carrier: '小程序 ',
                    today_pay_amount: floatFormat(
                       0.00
                    ),
                    yesterday_pay_amount: floatFormat(
                        0.00
                    ),
                    seven_days_pay_amount: floatFormat(
                       0.00
                    ),
                    thirty_days_pay_amount: floatFormat(
                        0.00
                    ),

                    today_consume_count: integerFormat(
                        0
                    ),
                    yesterday_consume_count: integerFormat(
                        0
                    ),
                    seven_days_consume_count: integerFormat(
                        0
                    ),
                    thirty_days_consume_count: integerFormat(
                        0
                    ),
                    today_refund_count: integerFormat(
                        0
                    ),
                    yesterday_refund_count: integerFormat(
                        0
                    ),
                    seven_days_refund_count: integerFormat(
                        0
                    ),
                    thirty_days_refund_count: integerFormat(
                        0
                    )
                },
                {
                    pay_type: '云闪付',
                    carrier:'H5',
                    today_pay_amount: floatFormat(
                        res.uq_today_pay_amount / 100
                    ),
                    yesterday_pay_amount: floatFormat(
                        res.uq_yesterday_pay_amount / 100
                    ),
                    seven_days_pay_amount: floatFormat(
                        res.uq_seven_days_pay_amount / 100
                    ),
                    thirty_days_pay_amount: floatFormat(
                        res.uq_thirty_days_pay_amount / 100
                    ),

                    today_consume_count: integerFormat(
                        res.uq_today_consume_count
                    ),
                    yesterday_consume_count: integerFormat(
                        res.uq_yesterday_consume_count
                    ),
                    seven_days_consume_count: integerFormat(
                        res.uq_seven_days_consume_count
                    ),
                    thirty_days_consume_count: integerFormat(
                        res.uq_thirty_days_consume_count
                    ),
                    today_refund_count: integerFormat(
                        res.uq_today_refund_count
                    ),
                    yesterday_refund_count: integerFormat(
                        res.uq_yesterday_refund_count
                    ),
                    seven_days_refund_count: integerFormat(
                        res.uq_seven_days_refund_count
                    ),
                    thirty_days_refund_count: integerFormat(
                        res.uq_thirty_days_refund_count
                    )
                },

                {
                    pay_type: '华为钱包',
                    carrier:'H5',
                    today_pay_amount: floatFormat(
                        res.hw_today_pay_amount / 100
                    ),
                    yesterday_pay_amount: floatFormat(
                        res.hw_yesterday_pay_amount / 100
                    ),
                    seven_days_pay_amount: floatFormat(
                        res.hw_seven_days_pay_amount / 100
                    ),
                    thirty_days_pay_amount: floatFormat(
                        res.hw_thirty_days_pay_amount / 100
                    ),

                    today_consume_count: integerFormat(
                        res.hw_today_consume_count
                    ),
                    yesterday_consume_count: integerFormat(
                        res.hw_yesterday_consume_count
                    ),
                    seven_days_consume_count: integerFormat(
                        res.hw_seven_days_consume_count
                    ),
                    thirty_days_consume_count: integerFormat(
                        res.hw_thirty_days_consume_count
                    ),
                    today_refund_count: integerFormat(
                        res.hw_today_refund_count
                    ),
                    yesterday_refund_count: integerFormat(
                        res.hw_yesterday_refund_count
                    ),
                    seven_days_refund_count: integerFormat(
                        res.hw_seven_days_refund_count
                    ),
                    thirty_days_refund_count: integerFormat(
                        res.hw_thirty_days_refund_count
                    )
                }
            ];
            this.loading = false;
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取统计数据失败, 请联系技术人员!');
            }
        }
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
}

export default AmountPage;
