
import { Component, Inject, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { mapState } from 'vuex';
import { deleteEmpty, isTrueEmpty } from '@/utils';
import qs from 'querystringify';
import { DatePicker } from '@/components/custom-form-components';
import PriceRange from '@/components/custom-form-components/PriceRange.vue';

interface SearchForm {
    form: any;
}
interface QueryParams {
    name: string;
    platActivity: string;
    startTime: string;
    endTime: string;
}
@Component({
    components: {
        DatePicker,
        PriceRange
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(
                    state.activityDetailSearchPageUnion.query
                );
            },
            loading: (state: any) => state.activityDetailSearchPageUnion.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach(key => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        return {
            name: values.name,
            platActivity: values.platActivity,
            activityId: values.activityId,
            billType: values.billType,
            discountType: values.discountType,
            settleStatus: values.settleStatus,
            merNo: values.merNo,
            offlineTerminalId: values.offlineTerminalId,
            orderId: values.orderId,
            unionOrder: values.unionOrder,
            minBillAmount: isTrueEmpty(
                values.billAmount && values.billAmount[0]
            )
                ? null
                : Math.round(values.billAmount[0] * 100),
            maxBillAmount: isTrueEmpty(
                values.billAmount && values.billAmount[1]
            )
                ? null
                : Math.round(values.billAmount[1] * 100),

            minActulPayAmount: isTrueEmpty(
                values.actulPayAmount && values.actulPayAmount[0]
            )
                ? null
                : Math.round(values.actulPayAmount[0] * 100),
            maxActulPayAmount: isTrueEmpty(
                values.actulPayAmount && values.actulPayAmount[1]
            )
                ? null
                : Math.round(values.actulPayAmount[1] * 100),

            minActulReceiptsAmount: isTrueEmpty(
                values.actulReceiptsAmount && values.actulReceiptsAmount[0]
            )
                ? null
                : Math.round(values.actulReceiptsAmount[0] * 100),
            maxActulReceiptsAmount: isTrueEmpty(
                values.actulReceiptsAmount && values.actulReceiptsAmount[1]
            )
                ? null
                : Math.round(values.actulReceiptsAmount[1] * 100),
            startTime:
                values.queryTime &&
                values.queryTime[0] &&
                values.queryTime[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime:
                values.queryTime &&
                values.queryTime[1] &&
                values.queryTime[1].format('YYYY-MM-DD HH:mm:ss')
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            billAmount: [
                query.minBillAmount && parseInt(query.minBillAmount, 10) / 100,
                query.maxBillAmount && parseInt(query.maxBillAmount, 10) / 100
            ],
            actulPayAmount: [
                query.minActulPayAmount &&
                    parseInt(query.minActulPayAmount, 10) / 100,
                query.maxActulPayAmount &&
                    parseInt(query.maxActulPayAmount, 10) / 100
            ],
            actulReceiptsAmount: [
                query.minActulReceiptsAmount &&
                    parseInt(query.minActulReceiptsAmount, 10) / 100,
                query.maxActulReceiptsAmount &&
                    parseInt(query.maxActulReceiptsAmount, 10) / 100
            ],
            queryTime: [
                query.startTime && moment(query.startTime),
                query.startTime && moment(query.startTime)
            ]
        };
    }
}

export default SearchForm;
