var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.baseForm },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动名称不能为空",
                                  },
                                ],
                                initialValue: _vm.formData.activityName,
                              },
                            ],
                            expression:
                              "[\n                            'name',\n                            {\n                                rules: [\n                                    {required: true, message: '活动名称不能为空'}\n                                    \n                                ],\n                                initialValue: formData.activityName\n                            }\n                        ]",
                          },
                        ],
                        attrs: {
                          disabled: true,
                          placeholder: "请填写活动名称",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "主题活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "gwActivityId",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    max: 32,
                                    message: "长度超限，请检查后重新输入!",
                                  },
                                ],
                                initialValue: _vm.formData.gwActivityId,
                              },
                            ],
                            expression:
                              "[\n                            'gwActivityId',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 32, message: '长度超限，请检查后重新输入!'}\n                                ],\n                                initialValue: formData.gwActivityId\n                            }\n                        ]",
                          },
                        ],
                        attrs: {
                          type: "number",
                          maxLength: "32",
                          placeholder: "请输入主题活动ID",
                          disabled: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "机构号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orgId",
                              {
                                initialValue: _vm.formData.orgId,
                              },
                            ],
                            expression:
                              "[\n                            'orgId',\n                            {\n                                initialValue: formData.orgId\n                            }\n                        ]",
                          },
                        ],
                        attrs: {
                          type: "number",
                          maxLength: "32",
                          placeholder: "请输入机构号",
                          disabled: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "银联活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "unionActivityId",
                              {
                                rules: [
                                  { required: true, message: "ID不能为空" },
                                ],
                                initialValue: _vm.formData.platActivity,
                              },
                            ],
                            expression:
                              "[\n                            'unionActivityId',\n                            {rules: [{required: true, message: 'ID不能为空'}\n                            ],\n                                initialValue: formData.platActivity}\n                        ]",
                          },
                        ],
                        attrs: {
                          disabled: true,
                          placeholder: "请填写已创建的银联活动ID",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动有效期" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "startTime",
                              {
                                rules: [
                                  { required: true, message: "ID不能为空" },
                                ],
                                initialValue:
                                  _vm.formData.startTime +
                                  "—" +
                                  _vm.formData.endTime,
                              },
                            ],
                            expression:
                              "[\n                            'startTime',\n                            {\n                                rules: [{required: true, message: 'ID不能为空'}],\n                               initialValue: formData.startTime +'—'+formData.endTime\n                            }\n                        ]",
                          },
                        ],
                        attrs: { disabled: true, placeholder: "-" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动总金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "activityTotalAmount",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动总金额不能为空",
                                  },
                                  {
                                    pattern:
                                      /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                    message: "只能填数字",
                                  },
                                ],
                                initialValue: _vm.formData.budgetAmount / 100,
                              },
                            ],
                            expression:
                              "[\n                            'activityTotalAmount',\n                            {\n                                rules: [\n                                    {required: true, message: '活动总金额不能为空'},\n                                    {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}\n                                ],\n                                initialValue: formData.budgetAmount/100\n                               \n                                \n                            }\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          disabled: true,
                          placeholder: "请输入活动总金额",
                        },
                      }),
                      _vm._v(" 元\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "出资方名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "sponsorName",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "出资方名称不能为空",
                                  },
                                ],
                                initialValue: _vm.formData.sponsorName,
                              },
                            ],
                            expression:
                              "[\n                            'sponsorName',\n                            {\n                                rules: [\n                                    {required: true, message: '出资方名称不能为空'}\n                                ],\n                                initialValue: formData.sponsorName\n                                \n                            }\n                        ]",
                          },
                        ],
                        attrs: {
                          disabled: true,
                          placeholder: "请填写出资方名称",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "预警阈值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "warnRatio",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "预警阈值不能为空",
                                  },
                                  {
                                    pattern: /^[1-9]\d?$/,
                                    message: "请输入整数且不能为0或100",
                                  },
                                ],
                                initialValue: _vm.formData.warnRatio,
                              },
                            ],
                            expression:
                              "[\n                            'warnRatio',\n                            {\n                                rules: [\n                                    {required: true, message: '预警阈值不能为空'},\n                                    {pattern: /^[1-9]\\d?$/, message: '请输入整数且不能为0或100'}\n                                    \n                                ],\n                                initialValue: formData.warnRatio\n                            }\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { disabled: true, placeholder: "请输入整数" },
                      }),
                      _vm._v(" %\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "短信推送号码" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "warnMobile",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "手机号码不能为空",
                                  },
                                  {
                                    pattern: /^1\d{10}?$/,
                                    message: "请输入正确的手机号码",
                                  },
                                ],
                                initialValue: _vm.formData.warnMobile,
                              },
                            ],
                            expression:
                              "[\n                            'warnMobile',\n                            {\n                                rules: [\n                                    {required: true, message: '手机号码不能为空'},\n                                    {pattern: /^1\\d{10}?$/, message: '请输入正确的手机号码'}\n                                ],\n                                initialValue: formData.warnMobile\n                            }\n                        ]",
                          },
                        ],
                        staticStyle: { width: "58%" },
                        attrs: {
                          disabled: true,
                          placeholder: "请输入手机号码",
                        },
                      }),
                      _vm._v(" \n                "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "优惠规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.discountForm },
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "订单最小金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orderMinAmount",
                              {
                                rules: [{ required: false, message: "" }],
                                initialValue: _vm.formData.payLine
                                  ? _vm.formData.payLine / 100
                                  : "",
                              },
                            ],
                            expression:
                              "[\n                            'orderMinAmount',\n                            {rules: [{required: false, message: ''}],\n                                initialValue: (formData.payLine?formData.payLine/100:'')}\n                        ]",
                          },
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          disabled: true,
                          placeholder: "请输入订单最小金额",
                        },
                      }),
                      _vm._v(" 元\n                "),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "优惠类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "discountType",
                                {
                                  initialValue: _vm.discountType,
                                },
                              ],
                              expression:
                                "['discountType', {\n                    initialValue: discountType}]",
                            },
                          ],
                          staticStyle: { marginTop: "10px" },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.discountType == "0",
                                  expression: "discountType == '0'",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "0" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "76.5px",
                                  },
                                },
                                [_vm._v("减")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue0",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "0",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字且不能为0",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.firstValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'firstValue0',\n                            {rules: [{required: (discountType == '0'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字且不能为0'}]\n                             ,initialValue:formData.firstValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.discountType == "1",
                                  expression: "discountType == '1'",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "1" },
                            },
                            [
                              _vm._v(
                                "\n                            固定立折\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "78px",
                                  },
                                },
                                [_vm._v("打")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue1",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "1",
                                                message: "打折费率不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.firstValue / 10,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'firstValue1',\n                            {rules: [{required: (discountType == '1'), message: '打折费率不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                            ,initialValue:formData.firstValue/10}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 折\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px",
                                  },
                                },
                                [_vm._v("封顶")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue1",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "1",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.secValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'secValue1',\n                            {rules: [{required: (discountType == '1'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                             ,initialValue:formData.secValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.discountType == "2",
                                  expression: "discountType == '2'",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "2" },
                            },
                            [
                              _vm._v(
                                "\n                            随机立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "36.5px",
                                  },
                                },
                                [_vm._v("金额下限")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue2",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "2",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.firstValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'firstValue2',\n                            {rules: [{required: (discountType == '2'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                            ,initialValue:formData.firstValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px",
                                  },
                                },
                                [_vm._v("金额上限")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue2",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "2",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.secValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'secValue2',\n                            {rules: [{required: (discountType == '2'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                             ,initialValue:formData.secValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.discountType == "3",
                                  expression: "discountType == '3'",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "3" },
                            },
                            [
                              _vm._v(
                                "\n                            折后一口价\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px",
                                  },
                                },
                                [_vm._v("折后金额")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue3",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "3",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.firstValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'firstValue3',\n                            {rules: [{required: (discountType == '3'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                            ,initialValue:formData.firstValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px",
                                  },
                                },
                                [_vm._v("封顶")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "secValue3",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "3",
                                                message: "金额不能为空",
                                              },
                                              {
                                                pattern:
                                                  /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                                                message: "只能填数字",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.secValue / 100,
                                          },
                                        ],
                                        expression:
                                          "[\n                            'secValue3',\n                            {rules: [{required: (discountType == '3'), message: '金额不能为空'}, {pattern: /^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$/, message: '只能填数字'}]\n                            ,initialValue:formData.secValue/100}\n                        ]",
                                      },
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" },
                                  }),
                                  _vm._v(" 元\n                            "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.discountType == "4",
                                  expression: "discountType == '4'",
                                },
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "4" },
                            },
                            [
                              _vm._v(
                                "\n                            单品营销\n                            \n                            "
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "firstValue4",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.discountType == "4",
                                                message: "单品描述不能为空",
                                              },
                                            ],
                                            initialValue:
                                              _vm.formData.firstValue,
                                          },
                                        ],
                                        expression:
                                          "[\n                                        'firstValue4',\n                                        {rules: [{required: (discountType == '4'), message: '单品描述不能为空'}],initialValue:formData.firstValue}\n                                    ]",
                                      },
                                    ],
                                    staticStyle: { width: "400px" },
                                    attrs: { placeholder: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "商户规则" } },
            [
              _c("a-form-item", { attrs: { label: "活动店铺" } }),
              _vm.formData.merchantList.length > 0
                ? _c(
                    "a-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.columns,
                          dataSource: _vm.formData.merchantList,
                          size: "small",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }