var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        {
          staticStyle: { overflow: "visible" },
          attrs: { defaultActiveKey: "activityDetail", animated: false },
          on: { change: _vm.handleChange },
        },
        [
          _c(
            "a-tab-pane",
            {
              key: "activityDetail",
              staticStyle: { padding: "20px" },
              attrs: { tab: "活动明细" },
            },
            [
              _c("Show", {
                attrs: { requires: _vm.roleName },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("ActivityDetailSearchPage", {
                          attrs: { active: _vm.activeKey === "activityDetail" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }