import {request, downloadRequest} from '@/utils';
import qs from 'querystringify';
export async function search(params: any) {

    try {
        return await request({
            api: '/console/white/list/merchant/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取白名单列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}


export async function downloadXls(params: any) {
    return await downloadRequest({
        data: params,
        prefix: 'whitelist',
        api: '/console/white/list/merchant/excel'
    });
}


export async function updateStatus(params: any) {

    try {
        return await request({
            method: 'POST',
            api: '/console/white/list/merchant/update',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function openStatus(params: any) {

    try {
        return await request({
            method: 'POST',
            api: '/console/white/list/merchant/ready/update',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}

export async function joinStatus(params: any) {

    try {
        return await request({
            method: 'POST',
            api: '/console/white/list/merchant/cache/update',
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}


