import * as DICTS from '@/common/dicts';
import blankCell from '@/common/blankCell';

const customRender = (v: any) => v || '-';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center',
    },
    {
        title: '商户名称',
        dataIndex: 'name',
        fixed: 'left',
        width: 150,
        align: 'center',
        sorter: true,
        customRender
    },
    {
        title: '商户别名',
        dataIndex: 'alias',
        width: 150,
        align: 'center',
        customRender
    },
    {
        title: 'LKL商户号',
        dataIndex: 'merchant_no',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    {
        title: '惠码SN号',
        dataIndex: 'sn',
        align: 'center',
        width: 260,
        sorter: true,
        scopedSlots: {customRender: 'sn'},
    },
    {
        title: '线上终端号',
        dataIndex: 'online_terminal_id',
        align: 'center',
        width: 120,
        sorter: true,
        customRender
    },
    {
        title: '线下终端号',
        dataIndex: 'offline_terminal_id',
        align: 'center',
        width: 120,
        sorter: true,
        customRender
    },
    {
        title: '商户类型',
        dataIndex: 'mallcoo.mall_belong', // MALLCOO
        align: 'center',
        width: 200,
        sorter: true,
        customRender: (belong: string) => {
            return DICTS.SERVICE_PLATFORM[belong] || '-';
        }
    },
    {
        title: '猫酷ShopID',
        dataIndex: 'mallcoo_shop_id',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    {
        title: '归属商场',
        dataIndex: 'mallcoo.mallcoo_mall_name',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    {
        title: '归属商圈',
        dataIndex: 'biz_district.name',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    {
        title: '行业大类',
        dataIndex: 'mcc_max_name',
        align: 'center',
        width: 200,
        // sorter: true,
        customRender
    },
    {
        title: '行业中类',
        dataIndex: 'mcc_middle_name',
        align: 'center',
        width: 200,
        // sorter: true,
        customRender
    },
    {
        title: '行业小类',
        dataIndex: 'mcc_min_name',
        align: 'center',
        width: 200,
        // sorter: true,
        customRender
    },
    {
        title: '短信状态',
        dataIndex: 'sms_switch',
        align: 'center',
        width: 100,
        customRender: (value: boolean) => {
            return value ? 'ON' : 'OFF';
        }
    },
    {
        title: '短信控制',
        dataIndex: 'daily_limit',
        align: 'center',
        width: 100,
        customRender
    },
    // {
    //     title: '开通退款',
    //     dataIndex: 'refund_open',
    //     align: 'center',
    //     width: 100,
    //     customRender: (value: boolean) => {
    //         return value ? '是' : '否';
    //     }
    // },
    {
        title: '省',
        dataIndex: 'store_province',
        align: 'center',
        width: 100,
        // sorter: true,
        customRender
    },
    {
        title: '市',
        dataIndex: 'store_city',
        align: 'center',
        sorter: true,
        width: 100,
        customRender
    },
    {
        title: '区',
        dataIndex: 'store_district',
        align: 'center',
        width: 100,
        // sorter: true,
        customRender
    },
    {
        title: '商户地址',
        dataIndex: 'store_address',
        align: 'center',
        width: 300,
        sorter: true,
        scopedSlots: {customRender: 'store_address'},
    },
    {
        title: '进件电话',
        dataIndex: 'mobile',
        align: 'center',
        width: 200,
        sorter: true,
        scopedSlots: {customRender: 'mobile'},
    },
    {
        title: '店铺联系电话',
        dataIndex: 'phone',
        align: 'center',
        width: 200,
        sorter: true,
        scopedSlots: {customRender: 'phone'},
    },
    {
        title: '创建时间',
        dataIndex: 'created_time',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    {
        title: '最后修改时间',
        dataIndex: 'updated_time',
        align: 'center',
        width: 200,
        sorter: true,
        customRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 150,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
