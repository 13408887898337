var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "role" },
    [
      _c("SearchCommonLayout", {
        attrs: { humanReadableQuery: _vm.humanReadableQuery },
        scopedSlots: _vm._u([
          {
            key: "search-form",
            fn: function () {
              return [_c("SearchForm")]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [_c("Actions")]
            },
            proxy: true,
          },
          {
            key: "fields-filter-btn",
            fn: function () {
              return [
                _c("FieldsFilter", {
                  attrs: {
                    fields: _vm.fields,
                    excludeFields: ["idx"],
                    columns: _vm.columns,
                    sortArr: _vm.sortArr,
                  },
                  on: {
                    change: _vm.handleFieldChange,
                    sort: _vm.handleFieldSort,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "table",
            fn: function () {
              return [_c("SearchTable")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }