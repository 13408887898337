var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      attrs: {
        visible: _vm.visible,
        title: "报名详情",
        width: "800",
        placement: "right",
        closable: "",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.showKeys, function (value, key) {
          return _c("div", { key: key, staticClass: "cell" }, [
            _c("div", { staticClass: "label" }, [_vm._v(_vm._s(value) + "：")]),
            _c("div", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm.detailData[key] || "/")),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }