
    import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
    import check from './check';

    @Component<Show>({
        computed: {
            needList() {
                return check(this.requires, this.privileges);
            }
        }
    })
    class Show extends Vue {

        @Prop() // 需要的权限列表
        public requires!: any;

        @Prop()
        public message!: any;

        @Inject()
        public privileges!: any;
    }

    export default Show;
