import { request_yx } from '@/utils';
export async function merBind(params: {
    templetId: string
}) {
    try {
        return await request_yx({
            api: '/console/activity/bankOrgTem/merBind',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        e.message = '提交失败, 请联系技术人员!';
        const error: any = new Error(e.message);
        error.silent = e.is403;
        throw error;
    }
}


