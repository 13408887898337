import { request_yx, request } from '@/utils';
import md5 from 'md5';
import config from '@/config';
import * as Constant from '@/common/constant';

export async function search(params: any) {
    try { 
       
        var api = '/console/activity/query'
        if (params.payType !== Constant.WX_TYPE) {
            api = '/console/activity/unionpay/query'
        }
        console.info("api ", api)
        return await request_yx({
            api,
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        const error: any = new Error('获取营销活动列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function updateStatus(params: any) {

    
    var api = '/console/activity/updateStatus'
    // if (params.payType != Constant.WX_TYPE) {
    //     api = '/console/activity/unionpay/updateStatus'
    // }
    try {
        return await request_yx({
            method: 'POST',
            api,
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}





