import blankCell from '@/common/blankCell';
import {defaultCustomRender,bankActivityMerStatus} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '额度组ID',
        dataIndex: 'merGroupId',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '额度组名称',
        dataIndex: 'merGroupName',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '政策ID',
        dataIndex: 'templateId',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '业主方',
        dataIndex: 'ownerId',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '开始时间',
        dataIndex: 'startTime',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '结束时间',
        dataIndex: 'endTime',
        width: 150,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '共享补贴额度',
        dataIndex: 'limit',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴金额',
        dataIndex: 'totalSubsidyAmt',
        width: 150,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '共享补贴交易总限额',
        dataIndex: 'payLimit',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴交易总金额',
        dataIndex: 'totalPaySubsidyAmt',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '共享补贴交易封顶笔数',
        dataIndex: 'subsidyMaxNum',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴笔数',
        dataIndex: 'totalSubsidyNum',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        customRender: (status: any) => {
            return bankActivityMerStatus(status);
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '更新人',
        dataIndex: 'modifiedBy',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '更新时间',
        dataIndex: 'gmtModified',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
    
];
