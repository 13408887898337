import { message } from 'ant-design-vue';
import { request_yx } from '@/utils';

export async function search(
    params: any
): Promise<{ list: any[]; total: number }> | never {
    const { id, ...rest } = params;
    try {
        return await request_yx({
            api: '/console/activity/userEmp/getActEmpList',
            method: 'POST',
            data: {
                ...rest,
                userId: id
            }
        });
    } catch (e) {
        const error: any = new Error('获取配置列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function empInsert(params: any) {
    try {
        return await request_yx({
            api: '/console/activity/userEmp/empActInsert',
            method: 'POST',
            data: params
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('绑定活动失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function empDel(params: any) {
    try {
        return await request_yx({
            api: '/console/activity/userEmp/empActDel',
            method: 'POST',
            data: params
        });
    } catch (e) {
        message.destroy();
        message.error(e.message);
        const error: any = new Error('解绑活动失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}