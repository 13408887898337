
import { Modal } from 'ant-design-vue';
import { Component, Prop,Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import config from '@/config';
interface DetailForm {
    form: any;
}

@Component<DetailForm>({
    components: {
    },
    computed: {
       ...mapState({
            })
    }
})
class DetailForm extends Vue {
    public remark='';
    @Prop()
    public name!: string;
    @Prop()
    public ownerId!: string;
    @Prop()
    public expenseAccount!: string;
    @Prop()
    public description!: string;
    public created() {
    }

    public data() {
        return {
         form: this.$form.createForm(this),
        };
    }


    private formatFormData(rawData: any) {
        return {
            ...rawData
        }
    }

}

export default DetailForm;
