
    import {mapState} from 'vuex';
    import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
    import {Click} from '@/components/Auth';
    import UploadDrawer from './UploadDrawer/index.vue';
    import PRIVILEGE from '@/common/privileges';
    import {Modal} from 'ant-design-vue';

    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }

    @Component({
        components: {
            Click,  
            UploadDrawer
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.qWhitelistSearchPage.loading,
                downloading: (state: any) => state.qWhitelistSearchPage.downloading,
                selectedRows: (state: any) => state.qWhitelistSearchPage.selectedRows,
            })
        }
    })
    class Actions extends Vue {
        @Inject()
        public dispatch!: any;

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
            this.$router.push({
                path: '/console/q_whitelist/search' + this.$store.getters['qWhitelistSearchPage/queryString']()
            });
        }
        public handleOpenClick() {
            Modal.confirm({
                title: '跳转ON',
                content: '是否开启跳转?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('updateStatus', {
                        ids: this.selectedRows.join(','),
                        status: true
                    });
                }
            });
        } 
        public handleCloseClick() {
            Modal.confirm({
                title: '跳转OFF',
                content: '是否关闭跳转?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('updateStatus', {
                        ids: this.selectedRows.join(','),
                        status: false
                    });
                }
            });
        }

        public handleOpenStatus() {
            Modal.confirm({
                title: '报备ON',
                content: '是否将选择商户的报备状态变更为已报备(仅标识)?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('openStatus', {
                        ids: this.selectedRows.join(','),
                        status: true
                    });
                }
            });
        }

         public handleJoinClick() {
            Modal.confirm({
                title: '机具被扫白名单',
                content: '是否加入机具被扫白名单?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('joinStatus', {
                        ids: this.selectedRows.join(','),
                        status: true
                    });
                }
            });
        }

        @Watch('$store.state.qWhitelistSearchPage.downloading')
        public watchDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.qWhitelistSearchPage.downloadError')
        public watchDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.qWhitelistSearchPage.lastDownloadTime')
        public watchLastDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('qWhitelistSearchPage/download');
        }
    }

    export default Actions;
