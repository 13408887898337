import {request} from '@/utils';

export async function search(params: {
    pageSize: number;
    page: number;
    query: any;
}) {
    try {
        return await request({
            api: '/console/riskcontrolblacklist/list',
            data: params
        });
    } catch (e) {
        const error: any = new Error('获取黑名单列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}

export async function createBlacklist(params: any) {
    try {
        return await request({
            method: 'POST',
            api: '/console/riskcontrolblacklist/create',
            data: {
                ...params
            }
        });
    } catch (e) {
        const error: any = new Error(e.message);
        error.silent = e.is403;
        throw error;
    }
}

export async function deleteBlacklist(params: any) {
    const formData = new URLSearchParams();
    formData.append('batch_id', params.ids);
    try {
        return await request({
            api: '/console/riskcontrolblacklist/delete',
            method: 'POST',
            data: formData
        });
    } catch (e) {
        const error: any = new Error('删除失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
