
import { Component, Vue } from 'vue-property-decorator';
import MerSubsidyRuleSearchPage from './merSubsidyRule/SearchPage/index.vue';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        MerSubsidyRuleSearchPage,
        Show
    },
    computed: {}
})
class Index extends Vue {
    public activeKey = 'merSubsidyRule';

    public PRIVILEGE = PRIVILEGE;

    public created() {
        if (this.$route.path === '/console/bankRule/merSubsidyRule/search') {
            this.activeKey = 'merSubsidyRule';
        }
    }
    
    public handleChange(key: string) {
        this.activeKey = key;
        if (key === 'merSubsidyRule') {
            this.$router.push({
                path: '/console/bankRule/merSubsidyRule/search?'
                    + this.$store.getters['merSubsidyRuleSearchPage/queryString'](),
            });
        }
    }
}

export default Index;
