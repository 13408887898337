
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import * as services from '@/common/services';
    import {check} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';

    interface ResetPasswordModal {
        form: any;
    }

    @Component({
        components: {}
    })
    class ResetPasswordModal extends Vue {

        public visible = false;

        public loading = false;

        public mobile = '';

        @Inject()
        public privileges!: any;

        public data() {
            return {
                form: this.$form.createForm(this)
            };
        }

        public handleClose() {
            if (this.loading) {
                return;
            }
            this.visible = false;
        }

        public onClick() {
            const list = check([PRIVILEGE.USER_UPDATE_PASSWORD], this.privileges);
            if (list.length > 0) {
                return this.$message.error(
                    '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
                );
            } else {
                this.visible = true;
            }
        }

        public handleSendCode() {
            const mobile = this.form.getFieldValue('mobile');
            this.mobile = mobile + '_' + Date.now();
        }

        public handleOk(e: any) {
            e.preventDefault();
            this.form.validateFields(async (error: any, values: any) => {
                if (!error) {

                    if (values.new_password !== values.new_password_confirm) {
                        return this.$message.error('新密码与确认密码不同，请重新填写');
                    }

                    try {
                        this.loading = true;
                        await services.updatePassword(values);
                        this.visible = false;
                        this.$message.success('重置密码成功');
                    } catch (e) {
                        this.$message.error(e.message);
                    } finally {
                        this.loading = false;
                    }
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
    }

    export default ResetPasswordModal;
