
    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import columns from './columns';
    import {queryToHumanReadable} from '@/utils';

    const humanReadableQuerySchema = {
        mobile: '手机号',
        first_name: '姓名',
        role_id: {
            name: '角色',
            callback(value: any, ret: any) {
                const item = ret.list.find((i: any) => i.id == value);
                if (item) {
                    return item.name;
                }
            },
            options: {
                method: 'GET',
                api: '/console/role/simple_list'
            },
            getParams(value: any) {
                return {};
            }
        }
    };
    @Component({
        components: {
            Actions,
            SearchForm,
            SearchTable,
            SearchCommonLayout
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.userSearchPage.fields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.userSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class UserPage extends Vue {

        @Prop()
        public active!: boolean;

        public storeName = 'userSearchPage';

        @Provide()
        public state() {
            return this.$store.state[this.storeName];
        }

        @Provide()
        public getters(name: string) {
            return this.$store.getters[this.storeName + '/' + name];
        }

        @Provide()
        public commit(mutation: string, payload?: any) {
            this.$store.commit(
                this.storeName + '/' + mutation,
                payload
            );
        }

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        @Provide()
        public routerPush() {
            this.$router.push({
                path: '/console/account/user/search' + this.getters('queryString')()
            });
        }

        public data() {
            return {
                columns
            };
        }

        public created() {
            // this.$store.dispatch('userSearchPage/initQuery', {
            //     ...this.$route.query
            // });
        }

        @Watch('$store.state.userSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        // @Watch('$route')
        // public routeListener(route: any) {
        //     if (!this.active) {
        //         return;
        //     }
        //     this.dispatch('initQuery', this.$route.query);
        //     // const {
        //     //     page,
        //     //     page_size,
        //     //     ...rest
        //     // }: any =  this.$route.query;
        //     // this.$store.commit('userSearchPage/setPagination', {
        //     //     page: (page && parseInt(page, 10)) || 1,
        //     //     pageSize: (page_size && parseInt(page_size, 10)) || 20
        //     // });
        //     // this.$store.commit(
        //     //     'userSearchPage/resetQuery',
        //     //     rest
        //     // );

        //     // this.$store.dispatch('userSearchPage/search');
        // }

        private handleFieldChange(e: any) {
            this.$store.commit('userSearchPage/setFields', e);
        }
    }
